import React, { useState, createContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  encodeEventRegistration,
  encodeEventStatus,
  encodeEventSettings,
  encodeEventMode,
  encodeEventPower,
  encodeEventPeripheral,
  decodeStream,
  encodeEventPointDiagnostics,
  encodeEventBlockDiagnostics,
  encodeEventSatDiagnostics,
  encodeEventCellDiagnostics,
  encodeEventOta,
  encodeEventQueue,
  encodeResetOrientation,
  encodeSetManual,
  encodeDeleteManual,
} from '../lib/protocol';
import { createStreamDecoder } from 'ucobs';

export const BLEContext = createContext();

const useProvideBLE = () => {
  const [device, setDevice] = useState();
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [check, setCheck] = useState(false);
  const [logRead, setLogRead] = useState();
  const [logWrite, setLogWrite] = useState();
  const [eventRead, seteventRead] = useState();
  const [eventWrite, setEventWrite] = useState();
  const [spider, setSpider] = useState({});
  const [error, setError] = useState(undefined);
  const [warning, setWarning] = useState(undefined);
  const [info, setInfo] = useState(undefined);
  const [summaries, setSummaries] = useState(60);
  const [blockSummaries, setBlockSummaries] = useState(12);

  let queue = useRef();
  let timer = useRef();

  function promiseForEvent(eventTarget, eventType) {
    return new Promise((resolve) => {
      let eventHandler = (evt) => {
        resolve(evt);
        eventTarget.removeEventListener(eventTarget, eventHandler);
      };
      eventTarget.addEventListener(eventType, eventHandler);
    });
  }

  useEffect(() => {
    if (
      spider.dataBlock1?.diagnosticResponse?.numSummaries > 1 &&
      spider.dataBlock2?.diagnosticResponse?.numSummaries > 1 &&
      spider.dataBlock3?.diagnosticResponse?.numSummaries > 1 &&
      spider.dataBlock4?.diagnosticResponse?.numSummaries > 1
    ) {
      setBlockSummaries(1);
    }
    if (
      spider.pointConstellations?.diagnosticResponse?.numSummaries > 1 &&
      spider.pointFixes?.diagnosticResponse?.numSummaries > 1 &&
      spider.satelliteNetworkSignalQuality?.diagnosticResponse?.numSummaries > 1 &&
      spider.satelliteNetworkTransmissions?.diagnosticResponse?.numSummaries > 1 &&
      spider.cellularNetworkSignalQuality?.diagnosticResponse?.numSummaries > 1 &&
      spider.cellularNetworkTraffic?.diagnosticResponse?.numSummaries > 1
    ) {
      setSummaries(1);
    }
  }, [spider]);

  const connect = async () => {
    setError(undefined);
    setWarning(undefined);

    // Clear device if disconnected
    promiseForEvent(navigator.bluetooth, 'disconnect').then(() => {
      console.log('ble disconnected');
      setConnected(false);
      setEventWrite(undefined);
      setWarning('Bluetooth disconnected');
    });

    if (connected) return;

    setConnecting(true);
    setInfo('Connecting...');
    setSpider({});
    setSummaries(60);
    setBlockSummaries(12);
    queue.current = [];

    try {
      const requestedDevice = await navigator.bluetooth.requestDevice({
        filters: [{ services: ['0bd51666-e7cb-469b-8e4d-2742f1ba77cc'] }], // Spidertxt Service (primary service advertised by the device)
        optionalServices: [
          '6e400001-b5a3-f393-e0a9-e50e24dcca9e', // Logging Service
          '1c174652-d530-491b-b181-de1730966fc2', // Protobuf Event Service
        ],
      });

      // Clear device if server disconnected
      promiseForEvent(requestedDevice, 'gattserverdisconnected').then(() => {
        console.log('ble disconnected');
        setConnected(false);
        setEventWrite(undefined);
        setWarning('Device disconnected');
      });

      const server = await requestedDevice.gatt.connect();

      const logService = await server.getPrimaryService('6e400001-b5a3-f393-e0a9-e50e24dcca9e');
      const logReadCharacteristic = await logService.getCharacteristic(
        '6e400003-b5a3-f393-e0a9-e50e24dcca9e'
      );
      const logWriteCharacteristic = await logService.getCharacteristic(
        '6e400002-b5a3-f393-e0a9-e50e24dcca9e'
      );

      const eventService = await server.getPrimaryService('1c174652-d530-491b-b181-de1730966fc2');
      const eventReadCharacteristic = await eventService.getCharacteristic(
        'ec5aec02-5c84-4375-87b7-b18cddf9c66b'
      );
      const eventWriteCharacteristic = await eventService.getCharacteristic(
        '87c886ab-9757-4cd8-870f-533d371e27c6'
      );

      //logReadCharacteristic.addEventListener('characteristicvaluechanged', (event) => {
      //  console.log(new TextDecoder().decode(event.target.value));
      //});
      //logReadCharacteristic.startNotifications();

      const handleStream = (chunk, isEnd) => {
        if (!isEnd) {
          console.error('stream too long');
          return;
        }
        decodeStream(chunk, setSpider);
      };

      let stream = createStreamDecoder(handleStream);

      eventReadCharacteristic.addEventListener('characteristicvaluechanged', (event) => {
        stream(new Uint8Array(event.target.value.buffer));
      });
      eventReadCharacteristic.startNotifications();

      console.log('Registering...');
      await sendData(eventWriteCharacteristic, encodeEventRegistration());
      console.log('Registered...');

      setDevice(requestedDevice);
      setLogRead(logReadCharacteristic);
      setLogWrite(logWriteCharacteristic);
      seteventRead(eventReadCharacteristic);
      setEventWrite(eventWriteCharacteristic);
      setConnected(true);
      setCheck(true);
    } catch (e) {
      setError(e.message);
    } finally {
      setConnecting(false);
      setInfo(undefined);
    }
  };

  useEffect(() => {
    if (check) {
      setCheck(false);
      if (timer.current) clearTimeout(timer.current);
      checkEvents();
      timer.current = setTimeout(() => setCheck(true), 1000);
    }
  }, [eventWrite, check]);

  const checkEvents = async () => {
    if (eventWrite) {
      try {
        console.info('requesting...');
        await sendData(eventWrite, encodeEventStatus());
        await sendData(eventWrite, encodeEventSettings());
        await sendData(eventWrite, encodeEventMode());
        await sendData(eventWrite, encodeEventPower());
        await sendData(eventWrite, encodeEventPeripheral());
        await sendData(eventWrite, encodeEventPointDiagnostics(summaries));
        await sendData(eventWrite, encodeEventBlockDiagnostics(1, blockSummaries));
        await sendData(eventWrite, encodeEventBlockDiagnostics(2, blockSummaries));
        await sendData(eventWrite, encodeEventBlockDiagnostics(3, blockSummaries));
        await sendData(eventWrite, encodeEventBlockDiagnostics(4, blockSummaries));
        await sendData(eventWrite, encodeEventSatDiagnostics(summaries));
        await sendData(eventWrite, encodeEventCellDiagnostics(summaries));
        await sendData(eventWrite, encodeEventOta());
        await sendData(eventWrite, encodeEventQueue('kSatellite'));
        await sendData(eventWrite, encodeEventQueue('kCellular'));
        while (queue.current.length > 0) {
          await sendData(eventWrite, queue.current.pop());
        }
      } catch (e) {
        console.log('checkEvents error: ', e);
      }
    }
  };

  const sendData = async (characteristic, data) => {
    let offset = 0;
    while (offset < data.length) {
      const chunk = data.slice(offset, offset + 20);
      await characteristic.writeValueWithResponse(chunk.buffer);
      offset += 20;
    }
  };

  const disconnect = async () => {
    console.log('disconnecting device');

    try {
      await device.gatt.disconnect();
    } catch (e) {
      console.log('disconnect error: ', e);
    }
    setConnected(false);
    setEventWrite(undefined);
    setError(undefined);
    setWarning(undefined);
    setInfo(undefined);
  };

  const setManual = async (z, x, y) => {
    console.info(`setting manual orientation ${z} ${x} ${y}...`);
    queue.current.push(encodeSetManual(z, x, y));
  };

  const deleteManual = async () => {
    console.info('deleting manual orientation...');
    queue.current.push(encodeDeleteManual());
  };

  const resetOrientation = async () => {
    console.info('resetting orientation...');
    queue.current.push(encodeResetOrientation());
  };

  return {
    connected,
    connect,
    disconnect,
    connecting,
    checkEvents,
    spider,
    error,
    warning,
    info,
    setManual,
    deleteManual,
    resetOrientation,
  };
};

export const Provider = ({ children }) => {
  const ble = useProvideBLE();
  return <BLEContext.Provider value={ble}>{children}</BLEContext.Provider>;
};
Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Consumer = BLEContext.Consumer;
