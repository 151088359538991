import { Alert, Button, Card, InputNumber, PageHeader, Space, Tabs } from 'antd';
import React, { useEffect, useState, useRef } from 'react';

import useAuth from '../../hooks/useAuth';
import useBLE from '../../hooks/useBLE';

const layout = {
  layout: 'vertical',
};
const tailLayout = {
  wrapperCol: {
    span: 16,
  },
};

const Status = () => {
  const ble = useBLE();
  const [signingOut, setSigningOut] = useState(false);
  const auth = useAuth();
  const roll = useRef(null);
  const pitch = useRef(null);

  const logOut = async () => {
    try {
      setSigningOut(true);
      await auth.signOut();
      window.location.reload();
    } catch (e) {
      console.log('error signing out: ', e);
    }
  };

  const connect = async () => {
    try {
      console.log('connect');
      await ble.connect();
    } catch (e) {
      console.log(e);
    }
  };

  const disconnect = async () => {
    try {
      console.log('disconnect');
      await ble.disconnect();
    } catch (e) {
      console.log(e);
    }
  };

  const setManual = async () => {
    try {
      let rollAngle = parseInt(roll.current.value);
      if (isNaN(rollAngle)) rollAngle = 0;
      let pitchAngle = parseInt(pitch.current.value);
      if (isNaN(pitchAngle)) pitchAngle = 0;
      await ble.setManual(0, rollAngle, pitchAngle);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteManual = async () => {
    try {
      await ble.deleteManual();
    } catch (e) {
      console.log(e);
    }
  };

  const resetOrientation = async () => {
    try {
      await ble.resetOrientation();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (auth.user === null) {
      setSigningOut(true);
      window.location.reload();
    }
  }, [auth.user]);

  const signout = signingOut ? <Alert message="Signing out..." type="warning" showIcon /> : null;

  const info = ble.error ? (
    <Alert message={ble.error} type="error" showIcon />
  ) : ble.warning ? (
    <Alert message={ble.warning} type="warning" showIcon />
  ) : ble.info ? (
    <Alert message={ble.info} type="info" showIcon />
  ) : null;

  const statusItems = [
    {
      label: 'Angles',
      key: 'item-angles',
      children: (
        <>
          <Space direction="vertical">
            <pre>{JSON.stringify(ble.spider.angles, null, 2) ?? 'Unknown'}</pre>
            <Space wrap>
              <InputNumber placeholder="roll" min={-180} max={180} ref={roll}></InputNumber>
              <InputNumber placeholder="pitch" min={-180} max={180} ref={pitch}></InputNumber>
            </Space>
            <Space wrap>
              <Button
                onClick={setManual}
                type="primary"
                htmlType="submit"
                disabled={!ble.connected}
              >
                Set Manual Correction
              </Button>
              <Button
                onClick={deleteManual}
                type="primary"
                htmlType="submit"
                disabled={!ble.connected}
              >
                Delete Manual Correction
              </Button>
              <Button
                onClick={resetOrientation}
                type="primary"
                htmlType="submit"
                disabled={!ble.connected}
              >
                Reset Install Orientation
              </Button>
            </Space>
          </Space>
        </>
      ),
    },
    {
      label: 'Operating Mode',
      key: 'item-mode',
      children: <pre>{JSON.stringify(ble.spider.operatingMode, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Point',
      key: 'item-point',
      children: <pre>{JSON.stringify(ble.spider.point, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Coprocessor',
      key: 'item-coprocessor',
      children: <pre>{JSON.stringify(ble.spider.ins, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Power',
      key: 'item-power',
      children: <pre>{JSON.stringify(ble.spider.power, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'OTA',
      key: 'item-ota',
      children: <pre>{JSON.stringify(ble.spider.otaStatus, null, 2) ?? 'Unknown'}</pre>,
    },
  ];

  const diagnosticsItems = [
    {
      label: 'Fixes',
      key: 'item-diag-fixes',
      children: <pre>{JSON.stringify(ble.spider.fixes, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Sats',
      key: 'item-diag-const',
      children: <pre>{JSON.stringify(ble.spider.gnss, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Sat TX',
      key: 'item-diag-sat-tx',
      children: <pre>{JSON.stringify(ble.spider.satTransmissions, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Sat CSQ',
      key: 'item-diag-sat-csq',
      children: <pre>{JSON.stringify(ble.spider.satCsq, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Cell TX',
      key: 'item-diag-cell-tx',
      children: <pre>{JSON.stringify(ble.spider.cellTraffic, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Cell CSQ',
      key: 'item-diag-cell-csq',
      children: <pre>{JSON.stringify(ble.spider.cellCsq, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Logs',
      key: 'item-diag-logs',
      children: <pre>{JSON.stringify(ble.spider.block1, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'IMU',
      key: 'item-diag-imu',
      children: <pre>{JSON.stringify(ble.spider.block2, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Serial',
      key: 'item-diag-serial',
      children: <pre>{JSON.stringify(ble.spider.block3, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Flight',
      key: 'item-diag-flight',
      children: <pre>{JSON.stringify(ble.spider.block4, null, 2) ?? 'Unknown'}</pre>,
    },
  ];

  const connectionItems = [
    {
      label: 'Device',
      key: 'item-device',
      children: <pre>{JSON.stringify(ble.spider.externalDeviceState, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Peripheral',
      key: 'item-peripheral',
      children: <pre>{JSON.stringify(ble.spider.peripheral, null, 2) ?? 'Unknown'}</pre>,
    },
  ];

  const settingsItems = [
    {
      label: 'Spider',
      key: 'item-spider-settings',
      children: <pre>{JSON.stringify(ble.spider.settingsMain, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'GPI',
      key: 'item-gpi-settings',
      children: <pre>{JSON.stringify(ble.spider.settingsGpi, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Keypad',
      key: 'item-keypad-settings',
      children: <pre>{JSON.stringify(ble.spider.settingsKeypad, null, 2) ?? 'Unknown'}</pre>,
    },
  ];

  const blockItems = [
    {
      label: 'Logs',
      key: 'item-log-blocks',
      children: (
        <pre>{JSON.stringify(ble.spider.dataBlock1?.diagnosticResponse, null, 2) ?? 'Unknown'}</pre>
      ),
    },
    {
      label: 'IMU',
      key: 'item-imu-blocks',
      children: (
        <pre>{JSON.stringify(ble.spider.dataBlock2?.diagnosticResponse, null, 2) ?? 'Unknown'}</pre>
      ),
    },
    {
      label: 'Serial',
      key: 'item-serial-blocks',
      children: (
        <pre>{JSON.stringify(ble.spider.dataBlock3?.diagnosticResponse, null, 2) ?? 'Unknown'}</pre>
      ),
    },
    {
      label: 'Flight',
      key: 'item-flight-blocks',
      children: (
        <pre>{JSON.stringify(ble.spider.dataBlock4?.diagnosticResponse, null, 2) ?? 'Unknown'}</pre>
      ),
    },
  ];

  const queueItems = [
    {
      label: 'Satellite',
      key: 'item-satellite-queue',
      children: <pre>{JSON.stringify(ble.spider.queue1, null, 2) ?? 'Unknown'}</pre>,
    },
    {
      label: 'Cellular',
      key: 'item-cellular-queue',
      children: <pre>{JSON.stringify(ble.spider.queue2, null, 2) ?? 'Unknown'}</pre>,
    },
  ];

  const signalItems = [
    {
      label: 'GNSS Fix',
      key: 'item-gnss-fix',
      children: (
        <pre>{JSON.stringify(ble.spider.pointFixes?.diagnosticResponse, null, 2) ?? 'Unknown'}</pre>
      ),
    },
    {
      label: 'GNSS Satellites',
      key: 'item-gnss-sats',
      children: (
        <pre>
          {JSON.stringify(ble.spider.pointConstellations?.diagnosticResponse, null, 2) ?? 'Unknown'}
        </pre>
      ),
    },
    {
      label: 'Iridium Signal',
      key: 'item-sat-signal',
      children: (
        <pre>
          {JSON.stringify(ble.spider.satelliteNetworkSignalQuality?.diagnosticResponse, null, 2) ??
            'Unknown'}
        </pre>
      ),
    },
    {
      label: 'Iridium TX',
      key: 'item-sat-tx',
      children: (
        <pre>
          {JSON.stringify(ble.spider.satelliteNetworkTransmissions?.diagnosticResponse, null, 2) ??
            'Unknown'}
        </pre>
      ),
    },
    {
      label: 'Cell Signal',
      key: 'item-cell-signal',
      children: (
        <pre>
          {JSON.stringify(ble.spider.cellularNetworkSignalQuality?.diagnosticResponse, null, 2) ??
            'Unknown'}
        </pre>
      ),
    },
    {
      label: 'Cell TX',
      key: 'item-cell-tx',
      children: (
        <pre>
          {JSON.stringify(ble.spider.cellularNetworkTraffic?.diagnosticResponse, null, 2) ??
            'Unknown'}
        </pre>
      ),
    },
  ];

  const rootItems = [
    {
      label: 'Status',
      key: 'item-status',
      children: (
        <Card>
          <Tabs items={statusItems} />
        </Card>
      ),
    },
    {
      label: 'Diagnostics',
      key: 'item-diagnostics',
      children: (
        <Card>
          <Tabs items={diagnosticsItems} />
        </Card>
      ),
    },
    {
      label: 'Connections',
      key: 'item-connections',
      children: (
        <Card>
          <Tabs items={connectionItems} />
        </Card>
      ),
    },
    {
      label: 'Settings',
      key: 'item-settings',
      children: (
        <Card>
          <Tabs items={settingsItems} />
        </Card>
      ),
    },
    {
      label: 'Data Blocks',
      key: 'item-blocks',
      children: (
        <Card>
          <Tabs items={blockItems} />
        </Card>
      ),
    },
    {
      label: 'Queues',
      key: 'item-queues',
      children: (
        <Card>
          <Tabs items={queueItems} />
        </Card>
      ),
    },
    {
      label: 'Signals',
      key: 'item-signals',
      children: (
        <Card>
          <Tabs items={signalItems} />
        </Card>
      ),
    },
    {
      label: 'Raw',
      key: 'item-raw',
      children: <pre>{JSON.stringify(ble.spider, null, 2)}</pre>,
    },
  ];

  return (
    <PageHeader ghost={false} title="Spider X Status">
      <Space direction="vertical">
        <Card>
          <p>Turn on Spider X to connect via Bluetooth.</p>
          <Space direction="vertical">
            <Space wrap>
              <Button
                onClick={connect}
                type="primary"
                htmlType="submit"
                disabled={ble.connecting || ble.connected}
              >
                Connect
              </Button>
              <Button
                onClick={disconnect}
                type="primary"
                htmlType="submit"
                disabled={!ble.connected}
              >
                Disconnect
              </Button>
            </Space>
            {info}
          </Space>
        </Card>
        <Card>
          <Tabs items={rootItems} />
        </Card>
        <br />
        <Button disabled={signingOut} type="primary" onClick={logOut}>
          Log Out
        </Button>
        <p />
      </Space>
      {signout}
    </PageHeader>
  );
};

export default Status;
