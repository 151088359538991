/* eslint-disable */
/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.ProtobufSettingsDeviceV1 = (function() {

    /**
     * Properties of a ProtobufSettingsDeviceV1.
     * @exports IProtobufSettingsDeviceV1
     * @interface IProtobufSettingsDeviceV1
     * @property {number|null} [bootCount] ProtobufSettingsDeviceV1 bootCount
     * @property {number|null} [bootAttempt] ProtobufSettingsDeviceV1 bootAttempt
     * @property {number|null} [runtimeS] ProtobufSettingsDeviceV1 runtimeS
     * @property {number|null} [currentVersionMajorMinorPatchTweak] ProtobufSettingsDeviceV1 currentVersionMajorMinorPatchTweak
     * @property {number|null} [currentVersionBootCount] ProtobufSettingsDeviceV1 currentVersionBootCount
     * @property {number|null} [currentVersionRuntimeS] ProtobufSettingsDeviceV1 currentVersionRuntimeS
     */

    /**
     * Constructs a new ProtobufSettingsDeviceV1.
     * @exports ProtobufSettingsDeviceV1
     * @classdesc Represents a ProtobufSettingsDeviceV1.
     * @implements IProtobufSettingsDeviceV1
     * @constructor
     * @param {IProtobufSettingsDeviceV1=} [properties] Properties to set
     */
    function ProtobufSettingsDeviceV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSettingsDeviceV1 bootCount.
     * @member {number} bootCount
     * @memberof ProtobufSettingsDeviceV1
     * @instance
     */
    ProtobufSettingsDeviceV1.prototype.bootCount = 0;

    /**
     * ProtobufSettingsDeviceV1 bootAttempt.
     * @member {number} bootAttempt
     * @memberof ProtobufSettingsDeviceV1
     * @instance
     */
    ProtobufSettingsDeviceV1.prototype.bootAttempt = 0;

    /**
     * ProtobufSettingsDeviceV1 runtimeS.
     * @member {number} runtimeS
     * @memberof ProtobufSettingsDeviceV1
     * @instance
     */
    ProtobufSettingsDeviceV1.prototype.runtimeS = 0;

    /**
     * ProtobufSettingsDeviceV1 currentVersionMajorMinorPatchTweak.
     * @member {number} currentVersionMajorMinorPatchTweak
     * @memberof ProtobufSettingsDeviceV1
     * @instance
     */
    ProtobufSettingsDeviceV1.prototype.currentVersionMajorMinorPatchTweak = 0;

    /**
     * ProtobufSettingsDeviceV1 currentVersionBootCount.
     * @member {number} currentVersionBootCount
     * @memberof ProtobufSettingsDeviceV1
     * @instance
     */
    ProtobufSettingsDeviceV1.prototype.currentVersionBootCount = 0;

    /**
     * ProtobufSettingsDeviceV1 currentVersionRuntimeS.
     * @member {number} currentVersionRuntimeS
     * @memberof ProtobufSettingsDeviceV1
     * @instance
     */
    ProtobufSettingsDeviceV1.prototype.currentVersionRuntimeS = 0;

    /**
     * Creates a new ProtobufSettingsDeviceV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {IProtobufSettingsDeviceV1=} [properties] Properties to set
     * @returns {ProtobufSettingsDeviceV1} ProtobufSettingsDeviceV1 instance
     */
    ProtobufSettingsDeviceV1.create = function create(properties) {
        return new ProtobufSettingsDeviceV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsDeviceV1 message. Does not implicitly {@link ProtobufSettingsDeviceV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {IProtobufSettingsDeviceV1} message ProtobufSettingsDeviceV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsDeviceV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.bootCount != null && Object.hasOwnProperty.call(message, "bootCount"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.bootCount);
        if (message.runtimeS != null && Object.hasOwnProperty.call(message, "runtimeS"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.runtimeS);
        if (message.bootAttempt != null && Object.hasOwnProperty.call(message, "bootAttempt"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.bootAttempt);
        if (message.currentVersionMajorMinorPatchTweak != null && Object.hasOwnProperty.call(message, "currentVersionMajorMinorPatchTweak"))
            writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.currentVersionMajorMinorPatchTweak);
        if (message.currentVersionBootCount != null && Object.hasOwnProperty.call(message, "currentVersionBootCount"))
            writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.currentVersionBootCount);
        if (message.currentVersionRuntimeS != null && Object.hasOwnProperty.call(message, "currentVersionRuntimeS"))
            writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.currentVersionRuntimeS);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsDeviceV1 message, length delimited. Does not implicitly {@link ProtobufSettingsDeviceV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {IProtobufSettingsDeviceV1} message ProtobufSettingsDeviceV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsDeviceV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsDeviceV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsDeviceV1} ProtobufSettingsDeviceV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsDeviceV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsDeviceV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.bootCount = reader.uint32();
                    break;
                }
            case 3: {
                    message.bootAttempt = reader.uint32();
                    break;
                }
            case 2: {
                    message.runtimeS = reader.uint32();
                    break;
                }
            case 10: {
                    message.currentVersionMajorMinorPatchTweak = reader.uint32();
                    break;
                }
            case 11: {
                    message.currentVersionBootCount = reader.uint32();
                    break;
                }
            case 12: {
                    message.currentVersionRuntimeS = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsDeviceV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsDeviceV1} ProtobufSettingsDeviceV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsDeviceV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsDeviceV1 message.
     * @function verify
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsDeviceV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.bootCount != null && message.hasOwnProperty("bootCount"))
            if (!$util.isInteger(message.bootCount))
                return "bootCount: integer expected";
        if (message.bootAttempt != null && message.hasOwnProperty("bootAttempt"))
            if (!$util.isInteger(message.bootAttempt))
                return "bootAttempt: integer expected";
        if (message.runtimeS != null && message.hasOwnProperty("runtimeS"))
            if (!$util.isInteger(message.runtimeS))
                return "runtimeS: integer expected";
        if (message.currentVersionMajorMinorPatchTweak != null && message.hasOwnProperty("currentVersionMajorMinorPatchTweak"))
            if (!$util.isInteger(message.currentVersionMajorMinorPatchTweak))
                return "currentVersionMajorMinorPatchTweak: integer expected";
        if (message.currentVersionBootCount != null && message.hasOwnProperty("currentVersionBootCount"))
            if (!$util.isInteger(message.currentVersionBootCount))
                return "currentVersionBootCount: integer expected";
        if (message.currentVersionRuntimeS != null && message.hasOwnProperty("currentVersionRuntimeS"))
            if (!$util.isInteger(message.currentVersionRuntimeS))
                return "currentVersionRuntimeS: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsDeviceV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsDeviceV1} ProtobufSettingsDeviceV1
     */
    ProtobufSettingsDeviceV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsDeviceV1)
            return object;
        var message = new $root.ProtobufSettingsDeviceV1();
        if (object.bootCount != null)
            message.bootCount = object.bootCount >>> 0;
        if (object.bootAttempt != null)
            message.bootAttempt = object.bootAttempt >>> 0;
        if (object.runtimeS != null)
            message.runtimeS = object.runtimeS >>> 0;
        if (object.currentVersionMajorMinorPatchTweak != null)
            message.currentVersionMajorMinorPatchTweak = object.currentVersionMajorMinorPatchTweak >>> 0;
        if (object.currentVersionBootCount != null)
            message.currentVersionBootCount = object.currentVersionBootCount >>> 0;
        if (object.currentVersionRuntimeS != null)
            message.currentVersionRuntimeS = object.currentVersionRuntimeS >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSettingsDeviceV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {ProtobufSettingsDeviceV1} message ProtobufSettingsDeviceV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsDeviceV1.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.bootCount = 0;
            object.runtimeS = 0;
            object.bootAttempt = 0;
            object.currentVersionMajorMinorPatchTweak = 0;
            object.currentVersionBootCount = 0;
            object.currentVersionRuntimeS = 0;
        }
        if (message.bootCount != null && message.hasOwnProperty("bootCount"))
            object.bootCount = message.bootCount;
        if (message.runtimeS != null && message.hasOwnProperty("runtimeS"))
            object.runtimeS = message.runtimeS;
        if (message.bootAttempt != null && message.hasOwnProperty("bootAttempt"))
            object.bootAttempt = message.bootAttempt;
        if (message.currentVersionMajorMinorPatchTweak != null && message.hasOwnProperty("currentVersionMajorMinorPatchTweak"))
            object.currentVersionMajorMinorPatchTweak = message.currentVersionMajorMinorPatchTweak;
        if (message.currentVersionBootCount != null && message.hasOwnProperty("currentVersionBootCount"))
            object.currentVersionBootCount = message.currentVersionBootCount;
        if (message.currentVersionRuntimeS != null && message.hasOwnProperty("currentVersionRuntimeS"))
            object.currentVersionRuntimeS = message.currentVersionRuntimeS;
        return object;
    };

    /**
     * Converts this ProtobufSettingsDeviceV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsDeviceV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsDeviceV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsDeviceV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsDeviceV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsDeviceV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsDeviceV1";
    };

    return ProtobufSettingsDeviceV1;
})();

$root.ProtobufSettingsServerMainV1 = (function() {

    /**
     * Properties of a ProtobufSettingsServerMainV1.
     * @exports IProtobufSettingsServerMainV1
     * @interface IProtobufSettingsServerMainV1
     * @property {boolean|null} [bluetoothEnabled] ProtobufSettingsServerMainV1 bluetoothEnabled
     * @property {boolean|null} [wifiEnabled] ProtobufSettingsServerMainV1 wifiEnabled
     * @property {ProtobufSettingsServerMainV1.WifiLocale|null} [wifiLocale] ProtobufSettingsServerMainV1 wifiLocale
     * @property {boolean|null} [otaEnabled] ProtobufSettingsServerMainV1 otaEnabled
     * @property {boolean|null} [logUploadEnabled] ProtobufSettingsServerMainV1 logUploadEnabled
     * @property {number|null} [logCapturePeriodS] ProtobufSettingsServerMainV1 logCapturePeriodS
     * @property {ProtobufSettingsServerMainV1.LogLevel|null} [logStorageLevel] ProtobufSettingsServerMainV1 logStorageLevel
     * @property {boolean|null} [serialUploadEnabled] ProtobufSettingsServerMainV1 serialUploadEnabled
     * @property {ProtobufSettingsServerMainV1.Channels|null} [trackingChannels] ProtobufSettingsServerMainV1 trackingChannels
     * @property {ProtobufSettingsServerMainV1.Channels|null} [sessionChannels] ProtobufSettingsServerMainV1 sessionChannels
     * @property {number|null} [gnssPdopThresholdE1] ProtobufSettingsServerMainV1 gnssPdopThresholdE1
     * @property {number|null} [gnssPositionThresholdM] ProtobufSettingsServerMainV1 gnssPositionThresholdM
     * @property {number|null} [gnssStaticHoldVelocityThresholdMpsE2] ProtobufSettingsServerMainV1 gnssStaticHoldVelocityThresholdMpsE2
     * @property {number|null} [gnssStaticHoldPositionThresholdM] ProtobufSettingsServerMainV1 gnssStaticHoldPositionThresholdM
     * @property {number|null} [horizontalSpeed_0EntryTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_0EntryTriggerMpsE1
     * @property {number|null} [horizontalSpeed_0ExitTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_0ExitTriggerMpsE1
     * @property {number|null} [horizontalSpeed_0ExitDelayS] ProtobufSettingsServerMainV1 horizontalSpeed_0ExitDelayS
     * @property {number|null} [horizontalSpeed_1EntryTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_1EntryTriggerMpsE1
     * @property {number|null} [horizontalSpeed_1ExitTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_1ExitTriggerMpsE1
     * @property {number|null} [horizontalSpeed_1ExitDelayS] ProtobufSettingsServerMainV1 horizontalSpeed_1ExitDelayS
     * @property {number|null} [horizontalSpeed_2EntryTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_2EntryTriggerMpsE1
     * @property {number|null} [horizontalSpeed_2ExitTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_2ExitTriggerMpsE1
     * @property {number|null} [horizontalSpeed_2ExitDelayS] ProtobufSettingsServerMainV1 horizontalSpeed_2ExitDelayS
     * @property {number|null} [horizontalSpeed_3EntryTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_3EntryTriggerMpsE1
     * @property {number|null} [horizontalSpeed_3ExitTriggerMpsE1] ProtobufSettingsServerMainV1 horizontalSpeed_3ExitTriggerMpsE1
     * @property {number|null} [horizontalSpeed_3ExitDelayS] ProtobufSettingsServerMainV1 horizontalSpeed_3ExitDelayS
     * @property {number|null} [verticalSpeedClimbEntryTriggerMpsE1] ProtobufSettingsServerMainV1 verticalSpeedClimbEntryTriggerMpsE1
     * @property {number|null} [verticalSpeedClimbExitTriggerMpsE1] ProtobufSettingsServerMainV1 verticalSpeedClimbExitTriggerMpsE1
     * @property {number|null} [verticalSpeedDescentEntryTriggerMpsE1] ProtobufSettingsServerMainV1 verticalSpeedDescentEntryTriggerMpsE1
     * @property {number|null} [verticalSpeedDescentExitTriggerMpsE1] ProtobufSettingsServerMainV1 verticalSpeedDescentExitTriggerMpsE1
     * @property {number|null} [headingTriggerDegrees] ProtobufSettingsServerMainV1 headingTriggerDegrees
     * @property {number|null} [timeTriggerPrimaryS] ProtobufSettingsServerMainV1 timeTriggerPrimaryS
     * @property {number|null} [timeTriggerSecondaryS] ProtobufSettingsServerMainV1 timeTriggerSecondaryS
     * @property {number|null} [rateLimitMax] ProtobufSettingsServerMainV1 rateLimitMax
     * @property {number|null} [rateLimitDecayS] ProtobufSettingsServerMainV1 rateLimitDecayS
     * @property {number|null} [autoWatchTriggerMpsE1] ProtobufSettingsServerMainV1 autoWatchTriggerMpsE1
     * @property {boolean|null} [buttonSosEnabled] ProtobufSettingsServerMainV1 buttonSosEnabled
     * @property {ProtobufSettingsServerMainV1.VehicleType|null} [vehicleType] ProtobufSettingsServerMainV1 vehicleType
     * @property {ProtobufSettingsServerMainV1.VehicleEngine|null} [vehicleEngine] ProtobufSettingsServerMainV1 vehicleEngine
     * @property {string|null} [vehicleRegistration] ProtobufSettingsServerMainV1 vehicleRegistration
     * @property {ProtobufSettingsServerMainV1.FlightPhaseModelSelection|null} [flightPhaseModelSelection] ProtobufSettingsServerMainV1 flightPhaseModelSelection
     * @property {boolean|null} [flightPhaseIndirectTakeoffRequireEngineOn] ProtobufSettingsServerMainV1 flightPhaseIndirectTakeoffRequireEngineOn
     * @property {boolean|null} [flightPhaseIndirectEngineOnOffPermitted] ProtobufSettingsServerMainV1 flightPhaseIndirectEngineOnOffPermitted
     * @property {boolean|null} [flightPhaseIndirectTaxiStartStopPermitted] ProtobufSettingsServerMainV1 flightPhaseIndirectTaxiStartStopPermitted
     * @property {boolean|null} [flightPhaseIndirectTakeoffLandingPermitted] ProtobufSettingsServerMainV1 flightPhaseIndirectTakeoffLandingPermitted
     * @property {boolean|null} [flightPhaseIndirectHoverStartStopPermitted] ProtobufSettingsServerMainV1 flightPhaseIndirectHoverStartStopPermitted
     * @property {boolean|null} [imuUploadEnabled] ProtobufSettingsServerMainV1 imuUploadEnabled
     * @property {number|null} [imuCapturePeriodS] ProtobufSettingsServerMainV1 imuCapturePeriodS
     * @property {ProtobufSettingsServerMainV1.AhrsModel|null} [imuAhrsModel] ProtobufSettingsServerMainV1 imuAhrsModel
     * @property {boolean|null} [imuForceCaptureInertialAccelerometerRaw] ProtobufSettingsServerMainV1 imuForceCaptureInertialAccelerometerRaw
     * @property {boolean|null} [imuForceCaptureInertialGyroscopeRaw] ProtobufSettingsServerMainV1 imuForceCaptureInertialGyroscopeRaw
     * @property {boolean|null} [imuForceCaptureEcompassAccelerometerRaw] ProtobufSettingsServerMainV1 imuForceCaptureEcompassAccelerometerRaw
     * @property {boolean|null} [imuForceCaptureEcompassMagnetometerRaw] ProtobufSettingsServerMainV1 imuForceCaptureEcompassMagnetometerRaw
     * @property {boolean|null} [imuForceCaptureBarometerPressure] ProtobufSettingsServerMainV1 imuForceCaptureBarometerPressure
     * @property {boolean|null} [imuForceCaptureBarometerTemperature] ProtobufSettingsServerMainV1 imuForceCaptureBarometerTemperature
     * @property {boolean|null} [imuForceCaptureGnssPosition] ProtobufSettingsServerMainV1 imuForceCaptureGnssPosition
     * @property {boolean|null} [imuForceCaptureAhrs] ProtobufSettingsServerMainV1 imuForceCaptureAhrs
     * @property {boolean|null} [imuForceCaptureInertialAccelerometerMagnitudeAcRms] ProtobufSettingsServerMainV1 imuForceCaptureInertialAccelerometerMagnitudeAcRms
     * @property {boolean|null} [imuForceCaptureSoundPressureLevel] ProtobufSettingsServerMainV1 imuForceCaptureSoundPressureLevel
     * @property {number|null} [manifestTimeoutS] ProtobufSettingsServerMainV1 manifestTimeoutS
     * @property {number|null} [manifestTagLimit] ProtobufSettingsServerMainV1 manifestTagLimit
     * @property {number|null} [ftpMaxFileSizeBytes] ProtobufSettingsServerMainV1 ftpMaxFileSizeBytes
     * @property {number|null} [flightPhaseGpiTransitionTimeoutS] ProtobufSettingsServerMainV1 flightPhaseGpiTransitionTimeoutS
     * @property {number|null} [flightPhaseGpiStationaryWindowS] ProtobufSettingsServerMainV1 flightPhaseGpiStationaryWindowS
     * @property {number|null} [flightPhaseGpiStationaryVerticalMpsE1] ProtobufSettingsServerMainV1 flightPhaseGpiStationaryVerticalMpsE1
     * @property {number|null} [flightPhaseGpiStationaryHorizontalMpsE1] ProtobufSettingsServerMainV1 flightPhaseGpiStationaryHorizontalMpsE1
     * @property {number|null} [flightPhaseEngineOnAccelerationWindowSDeprecated] ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationWindowSDeprecated
     * @property {number|null} [flightPhaseEngineOffAccelerationWindowSDeprecated] ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationWindowSDeprecated
     * @property {number|null} [flightPhaseEngineOnAccelerationTriggerGE3] ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationTriggerGE3
     * @property {number|null} [flightPhaseEngineOnAccelerationWindowS] ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationWindowS
     * @property {number|null} [flightPhaseEngineOnAccelerationRatioPct] ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationRatioPct
     * @property {number|null} [flightPhaseEngineOffAccelerationTriggerGE3] ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationTriggerGE3
     * @property {number|null} [flightPhaseEngineOffAccelerationWindowS] ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationWindowS
     * @property {number|null} [flightPhaseEngineOffAccelerationRatioPct] ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationRatioPct
     * @property {number|null} [flightPhaseTaxiStartWindowS] ProtobufSettingsServerMainV1 flightPhaseTaxiStartWindowS
     * @property {number|null} [flightPhaseTaxiStartRatioPct] ProtobufSettingsServerMainV1 flightPhaseTaxiStartRatioPct
     * @property {number|null} [flightPhaseTaxiStartSpeedMinTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseTaxiStartSpeedMinTriggerMpsE1
     * @property {number|null} [flightPhaseTaxiStopWindowS] ProtobufSettingsServerMainV1 flightPhaseTaxiStopWindowS
     * @property {number|null} [flightPhaseTaxiStopRatioPct] ProtobufSettingsServerMainV1 flightPhaseTaxiStopRatioPct
     * @property {number|null} [flightPhaseTaxiStopSpeedMaxTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseTaxiStopSpeedMaxTriggerMpsE1
     * @property {number|null} [flightPhaseTakeoffStandaloneWindowS] ProtobufSettingsServerMainV1 flightPhaseTakeoffStandaloneWindowS
     * @property {number|null} [flightPhaseTakeoffStandaloneRatioPct] ProtobufSettingsServerMainV1 flightPhaseTakeoffStandaloneRatioPct
     * @property {number|null} [flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1
     * @property {boolean|null} [flightPhaseTakeoffUseCompositeRule] ProtobufSettingsServerMainV1 flightPhaseTakeoffUseCompositeRule
     * @property {number|null} [flightPhaseTakeoffCompositeWindowS] ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeWindowS
     * @property {number|null} [flightPhaseTakeoffCompositeRatioPct] ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeRatioPct
     * @property {number|null} [flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1
     * @property {number|null} [flightPhaseTakeoffCompositeClimbMinTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeClimbMinTriggerMpsE1
     * @property {number|null} [flightPhaseLandingStandaloneWindowS] ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneWindowS
     * @property {number|null} [flightPhaseLandingStandaloneRatioPct] ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneRatioPct
     * @property {number|null} [flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1
     * @property {number|null} [flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3] ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3
     * @property {boolean|null} [flightPhaseLandingUseCompositeRule] ProtobufSettingsServerMainV1 flightPhaseLandingUseCompositeRule
     * @property {number|null} [flightPhaseLandingCompositeWindowS] ProtobufSettingsServerMainV1 flightPhaseLandingCompositeWindowS
     * @property {number|null} [flightPhaseLandingCompositeRatioPct] ProtobufSettingsServerMainV1 flightPhaseLandingCompositeRatioPct
     * @property {number|null} [flightPhaseLandingCompositeSpeedMaxTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseLandingCompositeSpeedMaxTriggerMpsE1
     * @property {number|null} [flightPhaseLandingCompositeClimbMaxTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseLandingCompositeClimbMaxTriggerMpsE1
     * @property {number|null} [flightPhaseLandingCompositeDescentMaxTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseLandingCompositeDescentMaxTriggerMpsE1
     * @property {number|null} [flightPhaseQualifyLandingWindowS] ProtobufSettingsServerMainV1 flightPhaseQualifyLandingWindowS
     * @property {number|null} [flightPhaseQualifyLandingRatioPct] ProtobufSettingsServerMainV1 flightPhaseQualifyLandingRatioPct
     * @property {number|null} [flightPhaseQualifyLandingSpeedMaxTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseQualifyLandingSpeedMaxTriggerMpsE1
     * @property {number|null} [flightPhaseHoverStartWindowS] ProtobufSettingsServerMainV1 flightPhaseHoverStartWindowS
     * @property {number|null} [flightPhaseHoverStartRatioPct] ProtobufSettingsServerMainV1 flightPhaseHoverStartRatioPct
     * @property {number|null} [flightPhaseHoverStartSpeedMaxTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseHoverStartSpeedMaxTriggerMpsE1
     * @property {number|null} [flightPhaseHoverStopWindowS] ProtobufSettingsServerMainV1 flightPhaseHoverStopWindowS
     * @property {number|null} [flightPhaseHoverStopRatioPct] ProtobufSettingsServerMainV1 flightPhaseHoverStopRatioPct
     * @property {number|null} [flightPhaseHoverStopSpeedMinTriggerMpsE1] ProtobufSettingsServerMainV1 flightPhaseHoverStopSpeedMinTriggerMpsE1
     */

    /**
     * Constructs a new ProtobufSettingsServerMainV1.
     * @exports ProtobufSettingsServerMainV1
     * @classdesc Represents a ProtobufSettingsServerMainV1.
     * @implements IProtobufSettingsServerMainV1
     * @constructor
     * @param {IProtobufSettingsServerMainV1=} [properties] Properties to set
     */
    function ProtobufSettingsServerMainV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSettingsServerMainV1 bluetoothEnabled.
     * @member {boolean} bluetoothEnabled
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.bluetoothEnabled = true;

    /**
     * ProtobufSettingsServerMainV1 wifiEnabled.
     * @member {boolean} wifiEnabled
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.wifiEnabled = false;

    /**
     * ProtobufSettingsServerMainV1 wifiLocale.
     * @member {ProtobufSettingsServerMainV1.WifiLocale} wifiLocale
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.wifiLocale = 0;

    /**
     * ProtobufSettingsServerMainV1 otaEnabled.
     * @member {boolean} otaEnabled
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.otaEnabled = true;

    /**
     * ProtobufSettingsServerMainV1 logUploadEnabled.
     * @member {boolean} logUploadEnabled
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.logUploadEnabled = true;

    /**
     * ProtobufSettingsServerMainV1 logCapturePeriodS.
     * @member {number} logCapturePeriodS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.logCapturePeriodS = 120;

    /**
     * ProtobufSettingsServerMainV1 logStorageLevel.
     * @member {ProtobufSettingsServerMainV1.LogLevel} logStorageLevel
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.logStorageLevel = 4;

    /**
     * ProtobufSettingsServerMainV1 serialUploadEnabled.
     * @member {boolean} serialUploadEnabled
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.serialUploadEnabled = true;

    /**
     * ProtobufSettingsServerMainV1 trackingChannels.
     * @member {ProtobufSettingsServerMainV1.Channels} trackingChannels
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.trackingChannels = 0;

    /**
     * ProtobufSettingsServerMainV1 sessionChannels.
     * @member {ProtobufSettingsServerMainV1.Channels} sessionChannels
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.sessionChannels = 1;

    /**
     * ProtobufSettingsServerMainV1 gnssPdopThresholdE1.
     * @member {number} gnssPdopThresholdE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.gnssPdopThresholdE1 = 100;

    /**
     * ProtobufSettingsServerMainV1 gnssPositionThresholdM.
     * @member {number} gnssPositionThresholdM
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.gnssPositionThresholdM = 100;

    /**
     * ProtobufSettingsServerMainV1 gnssStaticHoldVelocityThresholdMpsE2.
     * @member {number} gnssStaticHoldVelocityThresholdMpsE2
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.gnssStaticHoldVelocityThresholdMpsE2 = 0;

    /**
     * ProtobufSettingsServerMainV1 gnssStaticHoldPositionThresholdM.
     * @member {number} gnssStaticHoldPositionThresholdM
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.gnssStaticHoldPositionThresholdM = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_0EntryTriggerMpsE1.
     * @member {number} horizontalSpeed_0EntryTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_0EntryTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_0ExitTriggerMpsE1.
     * @member {number} horizontalSpeed_0ExitTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_0ExitTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_0ExitDelayS.
     * @member {number} horizontalSpeed_0ExitDelayS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_0ExitDelayS = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_1EntryTriggerMpsE1.
     * @member {number} horizontalSpeed_1EntryTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_1EntryTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_1ExitTriggerMpsE1.
     * @member {number} horizontalSpeed_1ExitTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_1ExitTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_1ExitDelayS.
     * @member {number} horizontalSpeed_1ExitDelayS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_1ExitDelayS = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_2EntryTriggerMpsE1.
     * @member {number} horizontalSpeed_2EntryTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_2EntryTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_2ExitTriggerMpsE1.
     * @member {number} horizontalSpeed_2ExitTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_2ExitTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_2ExitDelayS.
     * @member {number} horizontalSpeed_2ExitDelayS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_2ExitDelayS = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_3EntryTriggerMpsE1.
     * @member {number} horizontalSpeed_3EntryTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_3EntryTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_3ExitTriggerMpsE1.
     * @member {number} horizontalSpeed_3ExitTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_3ExitTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 horizontalSpeed_3ExitDelayS.
     * @member {number} horizontalSpeed_3ExitDelayS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.horizontalSpeed_3ExitDelayS = 0;

    /**
     * ProtobufSettingsServerMainV1 verticalSpeedClimbEntryTriggerMpsE1.
     * @member {number} verticalSpeedClimbEntryTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.verticalSpeedClimbEntryTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 verticalSpeedClimbExitTriggerMpsE1.
     * @member {number} verticalSpeedClimbExitTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.verticalSpeedClimbExitTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 verticalSpeedDescentEntryTriggerMpsE1.
     * @member {number} verticalSpeedDescentEntryTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.verticalSpeedDescentEntryTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 verticalSpeedDescentExitTriggerMpsE1.
     * @member {number} verticalSpeedDescentExitTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.verticalSpeedDescentExitTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 headingTriggerDegrees.
     * @member {number} headingTriggerDegrees
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.headingTriggerDegrees = 30;

    /**
     * ProtobufSettingsServerMainV1 timeTriggerPrimaryS.
     * @member {number} timeTriggerPrimaryS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.timeTriggerPrimaryS = 60;

    /**
     * ProtobufSettingsServerMainV1 timeTriggerSecondaryS.
     * @member {number} timeTriggerSecondaryS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.timeTriggerSecondaryS = 15;

    /**
     * ProtobufSettingsServerMainV1 rateLimitMax.
     * @member {number} rateLimitMax
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.rateLimitMax = 20;

    /**
     * ProtobufSettingsServerMainV1 rateLimitDecayS.
     * @member {number} rateLimitDecayS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.rateLimitDecayS = 10;

    /**
     * ProtobufSettingsServerMainV1 autoWatchTriggerMpsE1.
     * @member {number} autoWatchTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.autoWatchTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 buttonSosEnabled.
     * @member {boolean} buttonSosEnabled
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.buttonSosEnabled = true;

    /**
     * ProtobufSettingsServerMainV1 vehicleType.
     * @member {ProtobufSettingsServerMainV1.VehicleType} vehicleType
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.vehicleType = 0;

    /**
     * ProtobufSettingsServerMainV1 vehicleEngine.
     * @member {ProtobufSettingsServerMainV1.VehicleEngine} vehicleEngine
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.vehicleEngine = 0;

    /**
     * ProtobufSettingsServerMainV1 vehicleRegistration.
     * @member {string} vehicleRegistration
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.vehicleRegistration = "";

    /**
     * ProtobufSettingsServerMainV1 flightPhaseModelSelection.
     * @member {ProtobufSettingsServerMainV1.FlightPhaseModelSelection} flightPhaseModelSelection
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseModelSelection = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseIndirectTakeoffRequireEngineOn.
     * @member {boolean} flightPhaseIndirectTakeoffRequireEngineOn
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseIndirectTakeoffRequireEngineOn = false;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseIndirectEngineOnOffPermitted.
     * @member {boolean} flightPhaseIndirectEngineOnOffPermitted
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseIndirectEngineOnOffPermitted = true;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseIndirectTaxiStartStopPermitted.
     * @member {boolean} flightPhaseIndirectTaxiStartStopPermitted
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseIndirectTaxiStartStopPermitted = true;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseIndirectTakeoffLandingPermitted.
     * @member {boolean} flightPhaseIndirectTakeoffLandingPermitted
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseIndirectTakeoffLandingPermitted = true;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseIndirectHoverStartStopPermitted.
     * @member {boolean} flightPhaseIndirectHoverStartStopPermitted
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseIndirectHoverStartStopPermitted = true;

    /**
     * ProtobufSettingsServerMainV1 imuUploadEnabled.
     * @member {boolean} imuUploadEnabled
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuUploadEnabled = true;

    /**
     * ProtobufSettingsServerMainV1 imuCapturePeriodS.
     * @member {number} imuCapturePeriodS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuCapturePeriodS = 60;

    /**
     * ProtobufSettingsServerMainV1 imuAhrsModel.
     * @member {ProtobufSettingsServerMainV1.AhrsModel} imuAhrsModel
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuAhrsModel = 0;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureInertialAccelerometerRaw.
     * @member {boolean} imuForceCaptureInertialAccelerometerRaw
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureInertialAccelerometerRaw = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureInertialGyroscopeRaw.
     * @member {boolean} imuForceCaptureInertialGyroscopeRaw
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureInertialGyroscopeRaw = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureEcompassAccelerometerRaw.
     * @member {boolean} imuForceCaptureEcompassAccelerometerRaw
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureEcompassAccelerometerRaw = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureEcompassMagnetometerRaw.
     * @member {boolean} imuForceCaptureEcompassMagnetometerRaw
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureEcompassMagnetometerRaw = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureBarometerPressure.
     * @member {boolean} imuForceCaptureBarometerPressure
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureBarometerPressure = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureBarometerTemperature.
     * @member {boolean} imuForceCaptureBarometerTemperature
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureBarometerTemperature = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureGnssPosition.
     * @member {boolean} imuForceCaptureGnssPosition
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureGnssPosition = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureAhrs.
     * @member {boolean} imuForceCaptureAhrs
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureAhrs = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureInertialAccelerometerMagnitudeAcRms.
     * @member {boolean} imuForceCaptureInertialAccelerometerMagnitudeAcRms
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureInertialAccelerometerMagnitudeAcRms = false;

    /**
     * ProtobufSettingsServerMainV1 imuForceCaptureSoundPressureLevel.
     * @member {boolean} imuForceCaptureSoundPressureLevel
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.imuForceCaptureSoundPressureLevel = false;

    /**
     * ProtobufSettingsServerMainV1 manifestTimeoutS.
     * @member {number} manifestTimeoutS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.manifestTimeoutS = 60;

    /**
     * ProtobufSettingsServerMainV1 manifestTagLimit.
     * @member {number} manifestTagLimit
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.manifestTagLimit = 5;

    /**
     * ProtobufSettingsServerMainV1 ftpMaxFileSizeBytes.
     * @member {number} ftpMaxFileSizeBytes
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.ftpMaxFileSizeBytes = 102400;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseGpiTransitionTimeoutS.
     * @member {number} flightPhaseGpiTransitionTimeoutS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseGpiTransitionTimeoutS = 60;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseGpiStationaryWindowS.
     * @member {number} flightPhaseGpiStationaryWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseGpiStationaryWindowS = 2;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseGpiStationaryVerticalMpsE1.
     * @member {number} flightPhaseGpiStationaryVerticalMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseGpiStationaryVerticalMpsE1 = 10;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseGpiStationaryHorizontalMpsE1.
     * @member {number} flightPhaseGpiStationaryHorizontalMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseGpiStationaryHorizontalMpsE1 = 5;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationWindowSDeprecated.
     * @member {number} flightPhaseEngineOnAccelerationWindowSDeprecated
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOnAccelerationWindowSDeprecated = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationWindowSDeprecated.
     * @member {number} flightPhaseEngineOffAccelerationWindowSDeprecated
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOffAccelerationWindowSDeprecated = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationTriggerGE3.
     * @member {number} flightPhaseEngineOnAccelerationTriggerGE3
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOnAccelerationTriggerGE3 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationWindowS.
     * @member {number} flightPhaseEngineOnAccelerationWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOnAccelerationWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOnAccelerationRatioPct.
     * @member {number} flightPhaseEngineOnAccelerationRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOnAccelerationRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationTriggerGE3.
     * @member {number} flightPhaseEngineOffAccelerationTriggerGE3
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOffAccelerationTriggerGE3 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationWindowS.
     * @member {number} flightPhaseEngineOffAccelerationWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOffAccelerationWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseEngineOffAccelerationRatioPct.
     * @member {number} flightPhaseEngineOffAccelerationRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseEngineOffAccelerationRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTaxiStartWindowS.
     * @member {number} flightPhaseTaxiStartWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTaxiStartWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTaxiStartRatioPct.
     * @member {number} flightPhaseTaxiStartRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTaxiStartRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTaxiStartSpeedMinTriggerMpsE1.
     * @member {number} flightPhaseTaxiStartSpeedMinTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTaxiStartSpeedMinTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTaxiStopWindowS.
     * @member {number} flightPhaseTaxiStopWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTaxiStopWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTaxiStopRatioPct.
     * @member {number} flightPhaseTaxiStopRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTaxiStopRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTaxiStopSpeedMaxTriggerMpsE1.
     * @member {number} flightPhaseTaxiStopSpeedMaxTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffStandaloneWindowS.
     * @member {number} flightPhaseTakeoffStandaloneWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffStandaloneWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffStandaloneRatioPct.
     * @member {number} flightPhaseTakeoffStandaloneRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffStandaloneRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1.
     * @member {number} flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffUseCompositeRule.
     * @member {boolean} flightPhaseTakeoffUseCompositeRule
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffUseCompositeRule = false;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeWindowS.
     * @member {number} flightPhaseTakeoffCompositeWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffCompositeWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeRatioPct.
     * @member {number} flightPhaseTakeoffCompositeRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffCompositeRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1.
     * @member {number} flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseTakeoffCompositeClimbMinTriggerMpsE1.
     * @member {number} flightPhaseTakeoffCompositeClimbMinTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneWindowS.
     * @member {number} flightPhaseLandingStandaloneWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingStandaloneWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneRatioPct.
     * @member {number} flightPhaseLandingStandaloneRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingStandaloneRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1.
     * @member {number} flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3.
     * @member {number} flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingUseCompositeRule.
     * @member {boolean} flightPhaseLandingUseCompositeRule
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingUseCompositeRule = false;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingCompositeWindowS.
     * @member {number} flightPhaseLandingCompositeWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingCompositeWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingCompositeRatioPct.
     * @member {number} flightPhaseLandingCompositeRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingCompositeRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingCompositeSpeedMaxTriggerMpsE1.
     * @member {number} flightPhaseLandingCompositeSpeedMaxTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingCompositeClimbMaxTriggerMpsE1.
     * @member {number} flightPhaseLandingCompositeClimbMaxTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseLandingCompositeDescentMaxTriggerMpsE1.
     * @member {number} flightPhaseLandingCompositeDescentMaxTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseQualifyLandingWindowS.
     * @member {number} flightPhaseQualifyLandingWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseQualifyLandingWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseQualifyLandingRatioPct.
     * @member {number} flightPhaseQualifyLandingRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseQualifyLandingRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseQualifyLandingSpeedMaxTriggerMpsE1.
     * @member {number} flightPhaseQualifyLandingSpeedMaxTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseHoverStartWindowS.
     * @member {number} flightPhaseHoverStartWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseHoverStartWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseHoverStartRatioPct.
     * @member {number} flightPhaseHoverStartRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseHoverStartRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseHoverStartSpeedMaxTriggerMpsE1.
     * @member {number} flightPhaseHoverStartSpeedMaxTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseHoverStartSpeedMaxTriggerMpsE1 = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseHoverStopWindowS.
     * @member {number} flightPhaseHoverStopWindowS
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseHoverStopWindowS = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseHoverStopRatioPct.
     * @member {number} flightPhaseHoverStopRatioPct
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseHoverStopRatioPct = 0;

    /**
     * ProtobufSettingsServerMainV1 flightPhaseHoverStopSpeedMinTriggerMpsE1.
     * @member {number} flightPhaseHoverStopSpeedMinTriggerMpsE1
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     */
    ProtobufSettingsServerMainV1.prototype.flightPhaseHoverStopSpeedMinTriggerMpsE1 = 0;

    /**
     * Creates a new ProtobufSettingsServerMainV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {IProtobufSettingsServerMainV1=} [properties] Properties to set
     * @returns {ProtobufSettingsServerMainV1} ProtobufSettingsServerMainV1 instance
     */
    ProtobufSettingsServerMainV1.create = function create(properties) {
        return new ProtobufSettingsServerMainV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsServerMainV1 message. Does not implicitly {@link ProtobufSettingsServerMainV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {IProtobufSettingsServerMainV1} message ProtobufSettingsServerMainV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsServerMainV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.bluetoothEnabled != null && Object.hasOwnProperty.call(message, "bluetoothEnabled"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.bluetoothEnabled);
        if (message.wifiEnabled != null && Object.hasOwnProperty.call(message, "wifiEnabled"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.wifiEnabled);
        if (message.wifiLocale != null && Object.hasOwnProperty.call(message, "wifiLocale"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.wifiLocale);
        if (message.otaEnabled != null && Object.hasOwnProperty.call(message, "otaEnabled"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.otaEnabled);
        if (message.ftpMaxFileSizeBytes != null && Object.hasOwnProperty.call(message, "ftpMaxFileSizeBytes"))
            writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.ftpMaxFileSizeBytes);
        if (message.logUploadEnabled != null && Object.hasOwnProperty.call(message, "logUploadEnabled"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.logUploadEnabled);
        if (message.logStorageLevel != null && Object.hasOwnProperty.call(message, "logStorageLevel"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.logStorageLevel);
        if (message.logCapturePeriodS != null && Object.hasOwnProperty.call(message, "logCapturePeriodS"))
            writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.logCapturePeriodS);
        if (message.imuUploadEnabled != null && Object.hasOwnProperty.call(message, "imuUploadEnabled"))
            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.imuUploadEnabled);
        if (message.trackingChannels != null && Object.hasOwnProperty.call(message, "trackingChannels"))
            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.trackingChannels);
        if (message.serialUploadEnabled != null && Object.hasOwnProperty.call(message, "serialUploadEnabled"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.serialUploadEnabled);
        if (message.sessionChannels != null && Object.hasOwnProperty.call(message, "sessionChannels"))
            writer.uint32(/* id 17, wireType 0 =*/136).int32(message.sessionChannels);
        if (message.horizontalSpeed_0EntryTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_0EntryTriggerMpsE1"))
            writer.uint32(/* id 20, wireType 0 =*/160).uint32(message.horizontalSpeed_0EntryTriggerMpsE1);
        if (message.horizontalSpeed_0ExitTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_0ExitTriggerMpsE1"))
            writer.uint32(/* id 22, wireType 0 =*/176).uint32(message.horizontalSpeed_0ExitTriggerMpsE1);
        if (message.horizontalSpeed_0ExitDelayS != null && Object.hasOwnProperty.call(message, "horizontalSpeed_0ExitDelayS"))
            writer.uint32(/* id 23, wireType 0 =*/184).uint32(message.horizontalSpeed_0ExitDelayS);
        if (message.horizontalSpeed_1EntryTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_1EntryTriggerMpsE1"))
            writer.uint32(/* id 24, wireType 0 =*/192).uint32(message.horizontalSpeed_1EntryTriggerMpsE1);
        if (message.horizontalSpeed_1ExitTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_1ExitTriggerMpsE1"))
            writer.uint32(/* id 26, wireType 0 =*/208).uint32(message.horizontalSpeed_1ExitTriggerMpsE1);
        if (message.horizontalSpeed_1ExitDelayS != null && Object.hasOwnProperty.call(message, "horizontalSpeed_1ExitDelayS"))
            writer.uint32(/* id 27, wireType 0 =*/216).uint32(message.horizontalSpeed_1ExitDelayS);
        if (message.horizontalSpeed_2EntryTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_2EntryTriggerMpsE1"))
            writer.uint32(/* id 28, wireType 0 =*/224).uint32(message.horizontalSpeed_2EntryTriggerMpsE1);
        if (message.horizontalSpeed_2ExitTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_2ExitTriggerMpsE1"))
            writer.uint32(/* id 30, wireType 0 =*/240).uint32(message.horizontalSpeed_2ExitTriggerMpsE1);
        if (message.horizontalSpeed_2ExitDelayS != null && Object.hasOwnProperty.call(message, "horizontalSpeed_2ExitDelayS"))
            writer.uint32(/* id 31, wireType 0 =*/248).uint32(message.horizontalSpeed_2ExitDelayS);
        if (message.horizontalSpeed_3EntryTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_3EntryTriggerMpsE1"))
            writer.uint32(/* id 32, wireType 0 =*/256).uint32(message.horizontalSpeed_3EntryTriggerMpsE1);
        if (message.horizontalSpeed_3ExitTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "horizontalSpeed_3ExitTriggerMpsE1"))
            writer.uint32(/* id 34, wireType 0 =*/272).uint32(message.horizontalSpeed_3ExitTriggerMpsE1);
        if (message.horizontalSpeed_3ExitDelayS != null && Object.hasOwnProperty.call(message, "horizontalSpeed_3ExitDelayS"))
            writer.uint32(/* id 35, wireType 0 =*/280).uint32(message.horizontalSpeed_3ExitDelayS);
        if (message.verticalSpeedClimbEntryTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "verticalSpeedClimbEntryTriggerMpsE1"))
            writer.uint32(/* id 40, wireType 0 =*/320).uint32(message.verticalSpeedClimbEntryTriggerMpsE1);
        if (message.verticalSpeedClimbExitTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "verticalSpeedClimbExitTriggerMpsE1"))
            writer.uint32(/* id 41, wireType 0 =*/328).uint32(message.verticalSpeedClimbExitTriggerMpsE1);
        if (message.verticalSpeedDescentEntryTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "verticalSpeedDescentEntryTriggerMpsE1"))
            writer.uint32(/* id 42, wireType 0 =*/336).uint32(message.verticalSpeedDescentEntryTriggerMpsE1);
        if (message.verticalSpeedDescentExitTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "verticalSpeedDescentExitTriggerMpsE1"))
            writer.uint32(/* id 43, wireType 0 =*/344).uint32(message.verticalSpeedDescentExitTriggerMpsE1);
        if (message.gnssPdopThresholdE1 != null && Object.hasOwnProperty.call(message, "gnssPdopThresholdE1"))
            writer.uint32(/* id 45, wireType 0 =*/360).uint32(message.gnssPdopThresholdE1);
        if (message.gnssPositionThresholdM != null && Object.hasOwnProperty.call(message, "gnssPositionThresholdM"))
            writer.uint32(/* id 46, wireType 0 =*/368).uint32(message.gnssPositionThresholdM);
        if (message.gnssStaticHoldVelocityThresholdMpsE2 != null && Object.hasOwnProperty.call(message, "gnssStaticHoldVelocityThresholdMpsE2"))
            writer.uint32(/* id 47, wireType 0 =*/376).uint32(message.gnssStaticHoldVelocityThresholdMpsE2);
        if (message.gnssStaticHoldPositionThresholdM != null && Object.hasOwnProperty.call(message, "gnssStaticHoldPositionThresholdM"))
            writer.uint32(/* id 48, wireType 0 =*/384).uint32(message.gnssStaticHoldPositionThresholdM);
        if (message.headingTriggerDegrees != null && Object.hasOwnProperty.call(message, "headingTriggerDegrees"))
            writer.uint32(/* id 50, wireType 0 =*/400).uint32(message.headingTriggerDegrees);
        if (message.timeTriggerPrimaryS != null && Object.hasOwnProperty.call(message, "timeTriggerPrimaryS"))
            writer.uint32(/* id 51, wireType 0 =*/408).uint32(message.timeTriggerPrimaryS);
        if (message.timeTriggerSecondaryS != null && Object.hasOwnProperty.call(message, "timeTriggerSecondaryS"))
            writer.uint32(/* id 52, wireType 0 =*/416).uint32(message.timeTriggerSecondaryS);
        if (message.rateLimitMax != null && Object.hasOwnProperty.call(message, "rateLimitMax"))
            writer.uint32(/* id 53, wireType 0 =*/424).uint32(message.rateLimitMax);
        if (message.rateLimitDecayS != null && Object.hasOwnProperty.call(message, "rateLimitDecayS"))
            writer.uint32(/* id 54, wireType 0 =*/432).uint32(message.rateLimitDecayS);
        if (message.autoWatchTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "autoWatchTriggerMpsE1"))
            writer.uint32(/* id 60, wireType 0 =*/480).uint32(message.autoWatchTriggerMpsE1);
        if (message.buttonSosEnabled != null && Object.hasOwnProperty.call(message, "buttonSosEnabled"))
            writer.uint32(/* id 70, wireType 0 =*/560).bool(message.buttonSosEnabled);
        if (message.vehicleType != null && Object.hasOwnProperty.call(message, "vehicleType"))
            writer.uint32(/* id 80, wireType 0 =*/640).int32(message.vehicleType);
        if (message.vehicleEngine != null && Object.hasOwnProperty.call(message, "vehicleEngine"))
            writer.uint32(/* id 81, wireType 0 =*/648).int32(message.vehicleEngine);
        if (message.vehicleRegistration != null && Object.hasOwnProperty.call(message, "vehicleRegistration"))
            writer.uint32(/* id 82, wireType 2 =*/658).string(message.vehicleRegistration);
        if (message.flightPhaseModelSelection != null && Object.hasOwnProperty.call(message, "flightPhaseModelSelection"))
            writer.uint32(/* id 89, wireType 0 =*/712).int32(message.flightPhaseModelSelection);
        if (message.flightPhaseGpiTransitionTimeoutS != null && Object.hasOwnProperty.call(message, "flightPhaseGpiTransitionTimeoutS"))
            writer.uint32(/* id 90, wireType 0 =*/720).uint32(message.flightPhaseGpiTransitionTimeoutS);
        if (message.flightPhaseGpiStationaryWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseGpiStationaryWindowS"))
            writer.uint32(/* id 91, wireType 0 =*/728).uint32(message.flightPhaseGpiStationaryWindowS);
        if (message.flightPhaseGpiStationaryVerticalMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseGpiStationaryVerticalMpsE1"))
            writer.uint32(/* id 92, wireType 0 =*/736).uint32(message.flightPhaseGpiStationaryVerticalMpsE1);
        if (message.flightPhaseGpiStationaryHorizontalMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseGpiStationaryHorizontalMpsE1"))
            writer.uint32(/* id 93, wireType 0 =*/744).uint32(message.flightPhaseGpiStationaryHorizontalMpsE1);
        if (message.flightPhaseTaxiStartSpeedMinTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseTaxiStartSpeedMinTriggerMpsE1"))
            writer.uint32(/* id 100, wireType 0 =*/800).uint32(message.flightPhaseTaxiStartSpeedMinTriggerMpsE1);
        if (message.flightPhaseTaxiStartWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseTaxiStartWindowS"))
            writer.uint32(/* id 101, wireType 0 =*/808).uint32(message.flightPhaseTaxiStartWindowS);
        if (message.flightPhaseTaxiStartRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseTaxiStartRatioPct"))
            writer.uint32(/* id 102, wireType 0 =*/816).uint32(message.flightPhaseTaxiStartRatioPct);
        if (message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseLandingCompositeClimbMaxTriggerMpsE1"))
            writer.uint32(/* id 103, wireType 0 =*/824).uint32(message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1);
        if (message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseLandingCompositeDescentMaxTriggerMpsE1"))
            writer.uint32(/* id 104, wireType 0 =*/832).uint32(message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1);
        if (message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseTaxiStopSpeedMaxTriggerMpsE1"))
            writer.uint32(/* id 105, wireType 0 =*/840).uint32(message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1);
        if (message.flightPhaseTaxiStopWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseTaxiStopWindowS"))
            writer.uint32(/* id 106, wireType 0 =*/848).uint32(message.flightPhaseTaxiStopWindowS);
        if (message.flightPhaseTaxiStopRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseTaxiStopRatioPct"))
            writer.uint32(/* id 107, wireType 0 =*/856).uint32(message.flightPhaseTaxiStopRatioPct);
        if (message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1"))
            writer.uint32(/* id 108, wireType 0 =*/864).uint32(message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1);
        if (message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffCompositeClimbMinTriggerMpsE1"))
            writer.uint32(/* id 109, wireType 0 =*/872).uint32(message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1);
        if (message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1"))
            writer.uint32(/* id 110, wireType 0 =*/880).uint32(message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1);
        if (message.flightPhaseTakeoffStandaloneWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffStandaloneWindowS"))
            writer.uint32(/* id 111, wireType 0 =*/888).uint32(message.flightPhaseTakeoffStandaloneWindowS);
        if (message.flightPhaseTakeoffStandaloneRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffStandaloneRatioPct"))
            writer.uint32(/* id 112, wireType 0 =*/896).uint32(message.flightPhaseTakeoffStandaloneRatioPct);
        if (message.flightPhaseTakeoffUseCompositeRule != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffUseCompositeRule"))
            writer.uint32(/* id 113, wireType 0 =*/904).bool(message.flightPhaseTakeoffUseCompositeRule);
        if (message.flightPhaseLandingUseCompositeRule != null && Object.hasOwnProperty.call(message, "flightPhaseLandingUseCompositeRule"))
            writer.uint32(/* id 114, wireType 0 =*/912).bool(message.flightPhaseLandingUseCompositeRule);
        if (message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1"))
            writer.uint32(/* id 115, wireType 0 =*/920).uint32(message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1);
        if (message.flightPhaseLandingStandaloneWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseLandingStandaloneWindowS"))
            writer.uint32(/* id 116, wireType 0 =*/928).uint32(message.flightPhaseLandingStandaloneWindowS);
        if (message.flightPhaseLandingStandaloneRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseLandingStandaloneRatioPct"))
            writer.uint32(/* id 117, wireType 0 =*/936).uint32(message.flightPhaseLandingStandaloneRatioPct);
        if (message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 != null && Object.hasOwnProperty.call(message, "flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3"))
            writer.uint32(/* id 118, wireType 0 =*/944).uint32(message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3);
        if (message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseLandingCompositeSpeedMaxTriggerMpsE1"))
            writer.uint32(/* id 119, wireType 0 =*/952).uint32(message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1);
        if (message.imuForceCaptureInertialAccelerometerRaw != null && Object.hasOwnProperty.call(message, "imuForceCaptureInertialAccelerometerRaw"))
            writer.uint32(/* id 120, wireType 0 =*/960).bool(message.imuForceCaptureInertialAccelerometerRaw);
        if (message.imuForceCaptureInertialGyroscopeRaw != null && Object.hasOwnProperty.call(message, "imuForceCaptureInertialGyroscopeRaw"))
            writer.uint32(/* id 121, wireType 0 =*/968).bool(message.imuForceCaptureInertialGyroscopeRaw);
        if (message.imuForceCaptureEcompassAccelerometerRaw != null && Object.hasOwnProperty.call(message, "imuForceCaptureEcompassAccelerometerRaw"))
            writer.uint32(/* id 122, wireType 0 =*/976).bool(message.imuForceCaptureEcompassAccelerometerRaw);
        if (message.imuForceCaptureEcompassMagnetometerRaw != null && Object.hasOwnProperty.call(message, "imuForceCaptureEcompassMagnetometerRaw"))
            writer.uint32(/* id 123, wireType 0 =*/984).bool(message.imuForceCaptureEcompassMagnetometerRaw);
        if (message.imuForceCaptureBarometerPressure != null && Object.hasOwnProperty.call(message, "imuForceCaptureBarometerPressure"))
            writer.uint32(/* id 124, wireType 0 =*/992).bool(message.imuForceCaptureBarometerPressure);
        if (message.imuForceCaptureBarometerTemperature != null && Object.hasOwnProperty.call(message, "imuForceCaptureBarometerTemperature"))
            writer.uint32(/* id 125, wireType 0 =*/1000).bool(message.imuForceCaptureBarometerTemperature);
        if (message.imuForceCaptureGnssPosition != null && Object.hasOwnProperty.call(message, "imuForceCaptureGnssPosition"))
            writer.uint32(/* id 126, wireType 0 =*/1008).bool(message.imuForceCaptureGnssPosition);
        if (message.imuForceCaptureAhrs != null && Object.hasOwnProperty.call(message, "imuForceCaptureAhrs"))
            writer.uint32(/* id 127, wireType 0 =*/1016).bool(message.imuForceCaptureAhrs);
        if (message.imuForceCaptureInertialAccelerometerMagnitudeAcRms != null && Object.hasOwnProperty.call(message, "imuForceCaptureInertialAccelerometerMagnitudeAcRms"))
            writer.uint32(/* id 128, wireType 0 =*/1024).bool(message.imuForceCaptureInertialAccelerometerMagnitudeAcRms);
        if (message.imuForceCaptureSoundPressureLevel != null && Object.hasOwnProperty.call(message, "imuForceCaptureSoundPressureLevel"))
            writer.uint32(/* id 129, wireType 0 =*/1032).bool(message.imuForceCaptureSoundPressureLevel);
        if (message.imuAhrsModel != null && Object.hasOwnProperty.call(message, "imuAhrsModel"))
            writer.uint32(/* id 130, wireType 0 =*/1040).int32(message.imuAhrsModel);
        if (message.imuCapturePeriodS != null && Object.hasOwnProperty.call(message, "imuCapturePeriodS"))
            writer.uint32(/* id 132, wireType 0 =*/1056).uint32(message.imuCapturePeriodS);
        if (message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseQualifyLandingSpeedMaxTriggerMpsE1"))
            writer.uint32(/* id 139, wireType 0 =*/1112).uint32(message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1);
        if (message.flightPhaseHoverStartSpeedMaxTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseHoverStartSpeedMaxTriggerMpsE1"))
            writer.uint32(/* id 140, wireType 0 =*/1120).uint32(message.flightPhaseHoverStartSpeedMaxTriggerMpsE1);
        if (message.flightPhaseHoverStartWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseHoverStartWindowS"))
            writer.uint32(/* id 141, wireType 0 =*/1128).uint32(message.flightPhaseHoverStartWindowS);
        if (message.flightPhaseHoverStartRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseHoverStartRatioPct"))
            writer.uint32(/* id 142, wireType 0 =*/1136).uint32(message.flightPhaseHoverStartRatioPct);
        if (message.flightPhaseHoverStopSpeedMinTriggerMpsE1 != null && Object.hasOwnProperty.call(message, "flightPhaseHoverStopSpeedMinTriggerMpsE1"))
            writer.uint32(/* id 143, wireType 0 =*/1144).uint32(message.flightPhaseHoverStopSpeedMinTriggerMpsE1);
        if (message.flightPhaseHoverStopWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseHoverStopWindowS"))
            writer.uint32(/* id 144, wireType 0 =*/1152).uint32(message.flightPhaseHoverStopWindowS);
        if (message.flightPhaseHoverStopRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseHoverStopRatioPct"))
            writer.uint32(/* id 145, wireType 0 =*/1160).uint32(message.flightPhaseHoverStopRatioPct);
        if (message.flightPhaseTakeoffCompositeWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffCompositeWindowS"))
            writer.uint32(/* id 146, wireType 0 =*/1168).uint32(message.flightPhaseTakeoffCompositeWindowS);
        if (message.flightPhaseTakeoffCompositeRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseTakeoffCompositeRatioPct"))
            writer.uint32(/* id 147, wireType 0 =*/1176).uint32(message.flightPhaseTakeoffCompositeRatioPct);
        if (message.flightPhaseLandingCompositeWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseLandingCompositeWindowS"))
            writer.uint32(/* id 148, wireType 0 =*/1184).uint32(message.flightPhaseLandingCompositeWindowS);
        if (message.flightPhaseLandingCompositeRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseLandingCompositeRatioPct"))
            writer.uint32(/* id 149, wireType 0 =*/1192).uint32(message.flightPhaseLandingCompositeRatioPct);
        if (message.flightPhaseEngineOnAccelerationTriggerGE3 != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOnAccelerationTriggerGE3"))
            writer.uint32(/* id 150, wireType 0 =*/1200).uint32(message.flightPhaseEngineOnAccelerationTriggerGE3);
        if (message.flightPhaseEngineOnAccelerationWindowSDeprecated != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOnAccelerationWindowSDeprecated"))
            writer.uint32(/* id 151, wireType 0 =*/1208).uint32(message.flightPhaseEngineOnAccelerationWindowSDeprecated);
        if (message.flightPhaseEngineOnAccelerationRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOnAccelerationRatioPct"))
            writer.uint32(/* id 152, wireType 0 =*/1216).uint32(message.flightPhaseEngineOnAccelerationRatioPct);
        if (message.flightPhaseEngineOffAccelerationTriggerGE3 != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOffAccelerationTriggerGE3"))
            writer.uint32(/* id 153, wireType 0 =*/1224).uint32(message.flightPhaseEngineOffAccelerationTriggerGE3);
        if (message.flightPhaseEngineOffAccelerationWindowSDeprecated != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOffAccelerationWindowSDeprecated"))
            writer.uint32(/* id 154, wireType 0 =*/1232).uint32(message.flightPhaseEngineOffAccelerationWindowSDeprecated);
        if (message.flightPhaseEngineOffAccelerationRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOffAccelerationRatioPct"))
            writer.uint32(/* id 155, wireType 0 =*/1240).uint32(message.flightPhaseEngineOffAccelerationRatioPct);
        if (message.flightPhaseEngineOnAccelerationWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOnAccelerationWindowS"))
            writer.uint32(/* id 156, wireType 0 =*/1248).uint32(message.flightPhaseEngineOnAccelerationWindowS);
        if (message.flightPhaseEngineOffAccelerationWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseEngineOffAccelerationWindowS"))
            writer.uint32(/* id 157, wireType 0 =*/1256).uint32(message.flightPhaseEngineOffAccelerationWindowS);
        if (message.flightPhaseQualifyLandingWindowS != null && Object.hasOwnProperty.call(message, "flightPhaseQualifyLandingWindowS"))
            writer.uint32(/* id 158, wireType 0 =*/1264).uint32(message.flightPhaseQualifyLandingWindowS);
        if (message.flightPhaseQualifyLandingRatioPct != null && Object.hasOwnProperty.call(message, "flightPhaseQualifyLandingRatioPct"))
            writer.uint32(/* id 159, wireType 0 =*/1272).uint32(message.flightPhaseQualifyLandingRatioPct);
        if (message.flightPhaseIndirectEngineOnOffPermitted != null && Object.hasOwnProperty.call(message, "flightPhaseIndirectEngineOnOffPermitted"))
            writer.uint32(/* id 160, wireType 0 =*/1280).bool(message.flightPhaseIndirectEngineOnOffPermitted);
        if (message.flightPhaseIndirectTaxiStartStopPermitted != null && Object.hasOwnProperty.call(message, "flightPhaseIndirectTaxiStartStopPermitted"))
            writer.uint32(/* id 161, wireType 0 =*/1288).bool(message.flightPhaseIndirectTaxiStartStopPermitted);
        if (message.flightPhaseIndirectTakeoffLandingPermitted != null && Object.hasOwnProperty.call(message, "flightPhaseIndirectTakeoffLandingPermitted"))
            writer.uint32(/* id 162, wireType 0 =*/1296).bool(message.flightPhaseIndirectTakeoffLandingPermitted);
        if (message.flightPhaseIndirectHoverStartStopPermitted != null && Object.hasOwnProperty.call(message, "flightPhaseIndirectHoverStartStopPermitted"))
            writer.uint32(/* id 163, wireType 0 =*/1304).bool(message.flightPhaseIndirectHoverStartStopPermitted);
        if (message.flightPhaseIndirectTakeoffRequireEngineOn != null && Object.hasOwnProperty.call(message, "flightPhaseIndirectTakeoffRequireEngineOn"))
            writer.uint32(/* id 164, wireType 0 =*/1312).bool(message.flightPhaseIndirectTakeoffRequireEngineOn);
        if (message.manifestTimeoutS != null && Object.hasOwnProperty.call(message, "manifestTimeoutS"))
            writer.uint32(/* id 170, wireType 0 =*/1360).uint32(message.manifestTimeoutS);
        if (message.manifestTagLimit != null && Object.hasOwnProperty.call(message, "manifestTagLimit"))
            writer.uint32(/* id 171, wireType 0 =*/1368).uint32(message.manifestTagLimit);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsServerMainV1 message, length delimited. Does not implicitly {@link ProtobufSettingsServerMainV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {IProtobufSettingsServerMainV1} message ProtobufSettingsServerMainV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsServerMainV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsServerMainV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsServerMainV1} ProtobufSettingsServerMainV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsServerMainV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsServerMainV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.bluetoothEnabled = reader.bool();
                    break;
                }
            case 2: {
                    message.wifiEnabled = reader.bool();
                    break;
                }
            case 3: {
                    message.wifiLocale = reader.int32();
                    break;
                }
            case 5: {
                    message.otaEnabled = reader.bool();
                    break;
                }
            case 11: {
                    message.logUploadEnabled = reader.bool();
                    break;
                }
            case 13: {
                    message.logCapturePeriodS = reader.uint32();
                    break;
                }
            case 12: {
                    message.logStorageLevel = reader.int32();
                    break;
                }
            case 16: {
                    message.serialUploadEnabled = reader.bool();
                    break;
                }
            case 15: {
                    message.trackingChannels = reader.int32();
                    break;
                }
            case 17: {
                    message.sessionChannels = reader.int32();
                    break;
                }
            case 45: {
                    message.gnssPdopThresholdE1 = reader.uint32();
                    break;
                }
            case 46: {
                    message.gnssPositionThresholdM = reader.uint32();
                    break;
                }
            case 47: {
                    message.gnssStaticHoldVelocityThresholdMpsE2 = reader.uint32();
                    break;
                }
            case 48: {
                    message.gnssStaticHoldPositionThresholdM = reader.uint32();
                    break;
                }
            case 20: {
                    message.horizontalSpeed_0EntryTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 22: {
                    message.horizontalSpeed_0ExitTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 23: {
                    message.horizontalSpeed_0ExitDelayS = reader.uint32();
                    break;
                }
            case 24: {
                    message.horizontalSpeed_1EntryTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 26: {
                    message.horizontalSpeed_1ExitTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 27: {
                    message.horizontalSpeed_1ExitDelayS = reader.uint32();
                    break;
                }
            case 28: {
                    message.horizontalSpeed_2EntryTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 30: {
                    message.horizontalSpeed_2ExitTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 31: {
                    message.horizontalSpeed_2ExitDelayS = reader.uint32();
                    break;
                }
            case 32: {
                    message.horizontalSpeed_3EntryTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 34: {
                    message.horizontalSpeed_3ExitTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 35: {
                    message.horizontalSpeed_3ExitDelayS = reader.uint32();
                    break;
                }
            case 40: {
                    message.verticalSpeedClimbEntryTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 41: {
                    message.verticalSpeedClimbExitTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 42: {
                    message.verticalSpeedDescentEntryTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 43: {
                    message.verticalSpeedDescentExitTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 50: {
                    message.headingTriggerDegrees = reader.uint32();
                    break;
                }
            case 51: {
                    message.timeTriggerPrimaryS = reader.uint32();
                    break;
                }
            case 52: {
                    message.timeTriggerSecondaryS = reader.uint32();
                    break;
                }
            case 53: {
                    message.rateLimitMax = reader.uint32();
                    break;
                }
            case 54: {
                    message.rateLimitDecayS = reader.uint32();
                    break;
                }
            case 60: {
                    message.autoWatchTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 70: {
                    message.buttonSosEnabled = reader.bool();
                    break;
                }
            case 80: {
                    message.vehicleType = reader.int32();
                    break;
                }
            case 81: {
                    message.vehicleEngine = reader.int32();
                    break;
                }
            case 82: {
                    message.vehicleRegistration = reader.string();
                    break;
                }
            case 89: {
                    message.flightPhaseModelSelection = reader.int32();
                    break;
                }
            case 164: {
                    message.flightPhaseIndirectTakeoffRequireEngineOn = reader.bool();
                    break;
                }
            case 160: {
                    message.flightPhaseIndirectEngineOnOffPermitted = reader.bool();
                    break;
                }
            case 161: {
                    message.flightPhaseIndirectTaxiStartStopPermitted = reader.bool();
                    break;
                }
            case 162: {
                    message.flightPhaseIndirectTakeoffLandingPermitted = reader.bool();
                    break;
                }
            case 163: {
                    message.flightPhaseIndirectHoverStartStopPermitted = reader.bool();
                    break;
                }
            case 14: {
                    message.imuUploadEnabled = reader.bool();
                    break;
                }
            case 132: {
                    message.imuCapturePeriodS = reader.uint32();
                    break;
                }
            case 130: {
                    message.imuAhrsModel = reader.int32();
                    break;
                }
            case 120: {
                    message.imuForceCaptureInertialAccelerometerRaw = reader.bool();
                    break;
                }
            case 121: {
                    message.imuForceCaptureInertialGyroscopeRaw = reader.bool();
                    break;
                }
            case 122: {
                    message.imuForceCaptureEcompassAccelerometerRaw = reader.bool();
                    break;
                }
            case 123: {
                    message.imuForceCaptureEcompassMagnetometerRaw = reader.bool();
                    break;
                }
            case 124: {
                    message.imuForceCaptureBarometerPressure = reader.bool();
                    break;
                }
            case 125: {
                    message.imuForceCaptureBarometerTemperature = reader.bool();
                    break;
                }
            case 126: {
                    message.imuForceCaptureGnssPosition = reader.bool();
                    break;
                }
            case 127: {
                    message.imuForceCaptureAhrs = reader.bool();
                    break;
                }
            case 128: {
                    message.imuForceCaptureInertialAccelerometerMagnitudeAcRms = reader.bool();
                    break;
                }
            case 129: {
                    message.imuForceCaptureSoundPressureLevel = reader.bool();
                    break;
                }
            case 170: {
                    message.manifestTimeoutS = reader.uint32();
                    break;
                }
            case 171: {
                    message.manifestTagLimit = reader.uint32();
                    break;
                }
            case 10: {
                    message.ftpMaxFileSizeBytes = reader.uint32();
                    break;
                }
            case 90: {
                    message.flightPhaseGpiTransitionTimeoutS = reader.uint32();
                    break;
                }
            case 91: {
                    message.flightPhaseGpiStationaryWindowS = reader.uint32();
                    break;
                }
            case 92: {
                    message.flightPhaseGpiStationaryVerticalMpsE1 = reader.uint32();
                    break;
                }
            case 93: {
                    message.flightPhaseGpiStationaryHorizontalMpsE1 = reader.uint32();
                    break;
                }
            case 151: {
                    message.flightPhaseEngineOnAccelerationWindowSDeprecated = reader.uint32();
                    break;
                }
            case 154: {
                    message.flightPhaseEngineOffAccelerationWindowSDeprecated = reader.uint32();
                    break;
                }
            case 150: {
                    message.flightPhaseEngineOnAccelerationTriggerGE3 = reader.uint32();
                    break;
                }
            case 156: {
                    message.flightPhaseEngineOnAccelerationWindowS = reader.uint32();
                    break;
                }
            case 152: {
                    message.flightPhaseEngineOnAccelerationRatioPct = reader.uint32();
                    break;
                }
            case 153: {
                    message.flightPhaseEngineOffAccelerationTriggerGE3 = reader.uint32();
                    break;
                }
            case 157: {
                    message.flightPhaseEngineOffAccelerationWindowS = reader.uint32();
                    break;
                }
            case 155: {
                    message.flightPhaseEngineOffAccelerationRatioPct = reader.uint32();
                    break;
                }
            case 101: {
                    message.flightPhaseTaxiStartWindowS = reader.uint32();
                    break;
                }
            case 102: {
                    message.flightPhaseTaxiStartRatioPct = reader.uint32();
                    break;
                }
            case 100: {
                    message.flightPhaseTaxiStartSpeedMinTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 106: {
                    message.flightPhaseTaxiStopWindowS = reader.uint32();
                    break;
                }
            case 107: {
                    message.flightPhaseTaxiStopRatioPct = reader.uint32();
                    break;
                }
            case 105: {
                    message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 111: {
                    message.flightPhaseTakeoffStandaloneWindowS = reader.uint32();
                    break;
                }
            case 112: {
                    message.flightPhaseTakeoffStandaloneRatioPct = reader.uint32();
                    break;
                }
            case 110: {
                    message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 113: {
                    message.flightPhaseTakeoffUseCompositeRule = reader.bool();
                    break;
                }
            case 146: {
                    message.flightPhaseTakeoffCompositeWindowS = reader.uint32();
                    break;
                }
            case 147: {
                    message.flightPhaseTakeoffCompositeRatioPct = reader.uint32();
                    break;
                }
            case 108: {
                    message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 109: {
                    message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 116: {
                    message.flightPhaseLandingStandaloneWindowS = reader.uint32();
                    break;
                }
            case 117: {
                    message.flightPhaseLandingStandaloneRatioPct = reader.uint32();
                    break;
                }
            case 115: {
                    message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 118: {
                    message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 = reader.uint32();
                    break;
                }
            case 114: {
                    message.flightPhaseLandingUseCompositeRule = reader.bool();
                    break;
                }
            case 148: {
                    message.flightPhaseLandingCompositeWindowS = reader.uint32();
                    break;
                }
            case 149: {
                    message.flightPhaseLandingCompositeRatioPct = reader.uint32();
                    break;
                }
            case 119: {
                    message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 103: {
                    message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 104: {
                    message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 158: {
                    message.flightPhaseQualifyLandingWindowS = reader.uint32();
                    break;
                }
            case 159: {
                    message.flightPhaseQualifyLandingRatioPct = reader.uint32();
                    break;
                }
            case 139: {
                    message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 141: {
                    message.flightPhaseHoverStartWindowS = reader.uint32();
                    break;
                }
            case 142: {
                    message.flightPhaseHoverStartRatioPct = reader.uint32();
                    break;
                }
            case 140: {
                    message.flightPhaseHoverStartSpeedMaxTriggerMpsE1 = reader.uint32();
                    break;
                }
            case 144: {
                    message.flightPhaseHoverStopWindowS = reader.uint32();
                    break;
                }
            case 145: {
                    message.flightPhaseHoverStopRatioPct = reader.uint32();
                    break;
                }
            case 143: {
                    message.flightPhaseHoverStopSpeedMinTriggerMpsE1 = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsServerMainV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsServerMainV1} ProtobufSettingsServerMainV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsServerMainV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsServerMainV1 message.
     * @function verify
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsServerMainV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.bluetoothEnabled != null && message.hasOwnProperty("bluetoothEnabled"))
            if (typeof message.bluetoothEnabled !== "boolean")
                return "bluetoothEnabled: boolean expected";
        if (message.wifiEnabled != null && message.hasOwnProperty("wifiEnabled"))
            if (typeof message.wifiEnabled !== "boolean")
                return "wifiEnabled: boolean expected";
        if (message.wifiLocale != null && message.hasOwnProperty("wifiLocale"))
            switch (message.wifiLocale) {
            default:
                return "wifiLocale: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.otaEnabled != null && message.hasOwnProperty("otaEnabled"))
            if (typeof message.otaEnabled !== "boolean")
                return "otaEnabled: boolean expected";
        if (message.logUploadEnabled != null && message.hasOwnProperty("logUploadEnabled"))
            if (typeof message.logUploadEnabled !== "boolean")
                return "logUploadEnabled: boolean expected";
        if (message.logCapturePeriodS != null && message.hasOwnProperty("logCapturePeriodS"))
            if (!$util.isInteger(message.logCapturePeriodS))
                return "logCapturePeriodS: integer expected";
        if (message.logStorageLevel != null && message.hasOwnProperty("logStorageLevel"))
            switch (message.logStorageLevel) {
            default:
                return "logStorageLevel: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.serialUploadEnabled != null && message.hasOwnProperty("serialUploadEnabled"))
            if (typeof message.serialUploadEnabled !== "boolean")
                return "serialUploadEnabled: boolean expected";
        if (message.trackingChannels != null && message.hasOwnProperty("trackingChannels"))
            switch (message.trackingChannels) {
            default:
                return "trackingChannels: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.sessionChannels != null && message.hasOwnProperty("sessionChannels"))
            switch (message.sessionChannels) {
            default:
                return "sessionChannels: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.gnssPdopThresholdE1 != null && message.hasOwnProperty("gnssPdopThresholdE1"))
            if (!$util.isInteger(message.gnssPdopThresholdE1))
                return "gnssPdopThresholdE1: integer expected";
        if (message.gnssPositionThresholdM != null && message.hasOwnProperty("gnssPositionThresholdM"))
            if (!$util.isInteger(message.gnssPositionThresholdM))
                return "gnssPositionThresholdM: integer expected";
        if (message.gnssStaticHoldVelocityThresholdMpsE2 != null && message.hasOwnProperty("gnssStaticHoldVelocityThresholdMpsE2"))
            if (!$util.isInteger(message.gnssStaticHoldVelocityThresholdMpsE2))
                return "gnssStaticHoldVelocityThresholdMpsE2: integer expected";
        if (message.gnssStaticHoldPositionThresholdM != null && message.hasOwnProperty("gnssStaticHoldPositionThresholdM"))
            if (!$util.isInteger(message.gnssStaticHoldPositionThresholdM))
                return "gnssStaticHoldPositionThresholdM: integer expected";
        if (message.horizontalSpeed_0EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_0EntryTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_0EntryTriggerMpsE1))
                return "horizontalSpeed_0EntryTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_0ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_0ExitTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_0ExitTriggerMpsE1))
                return "horizontalSpeed_0ExitTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_0ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_0ExitDelayS"))
            if (!$util.isInteger(message.horizontalSpeed_0ExitDelayS))
                return "horizontalSpeed_0ExitDelayS: integer expected";
        if (message.horizontalSpeed_1EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_1EntryTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_1EntryTriggerMpsE1))
                return "horizontalSpeed_1EntryTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_1ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_1ExitTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_1ExitTriggerMpsE1))
                return "horizontalSpeed_1ExitTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_1ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_1ExitDelayS"))
            if (!$util.isInteger(message.horizontalSpeed_1ExitDelayS))
                return "horizontalSpeed_1ExitDelayS: integer expected";
        if (message.horizontalSpeed_2EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_2EntryTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_2EntryTriggerMpsE1))
                return "horizontalSpeed_2EntryTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_2ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_2ExitTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_2ExitTriggerMpsE1))
                return "horizontalSpeed_2ExitTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_2ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_2ExitDelayS"))
            if (!$util.isInteger(message.horizontalSpeed_2ExitDelayS))
                return "horizontalSpeed_2ExitDelayS: integer expected";
        if (message.horizontalSpeed_3EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_3EntryTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_3EntryTriggerMpsE1))
                return "horizontalSpeed_3EntryTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_3ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_3ExitTriggerMpsE1"))
            if (!$util.isInteger(message.horizontalSpeed_3ExitTriggerMpsE1))
                return "horizontalSpeed_3ExitTriggerMpsE1: integer expected";
        if (message.horizontalSpeed_3ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_3ExitDelayS"))
            if (!$util.isInteger(message.horizontalSpeed_3ExitDelayS))
                return "horizontalSpeed_3ExitDelayS: integer expected";
        if (message.verticalSpeedClimbEntryTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedClimbEntryTriggerMpsE1"))
            if (!$util.isInteger(message.verticalSpeedClimbEntryTriggerMpsE1))
                return "verticalSpeedClimbEntryTriggerMpsE1: integer expected";
        if (message.verticalSpeedClimbExitTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedClimbExitTriggerMpsE1"))
            if (!$util.isInteger(message.verticalSpeedClimbExitTriggerMpsE1))
                return "verticalSpeedClimbExitTriggerMpsE1: integer expected";
        if (message.verticalSpeedDescentEntryTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedDescentEntryTriggerMpsE1"))
            if (!$util.isInteger(message.verticalSpeedDescentEntryTriggerMpsE1))
                return "verticalSpeedDescentEntryTriggerMpsE1: integer expected";
        if (message.verticalSpeedDescentExitTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedDescentExitTriggerMpsE1"))
            if (!$util.isInteger(message.verticalSpeedDescentExitTriggerMpsE1))
                return "verticalSpeedDescentExitTriggerMpsE1: integer expected";
        if (message.headingTriggerDegrees != null && message.hasOwnProperty("headingTriggerDegrees"))
            if (!$util.isInteger(message.headingTriggerDegrees))
                return "headingTriggerDegrees: integer expected";
        if (message.timeTriggerPrimaryS != null && message.hasOwnProperty("timeTriggerPrimaryS"))
            if (!$util.isInteger(message.timeTriggerPrimaryS))
                return "timeTriggerPrimaryS: integer expected";
        if (message.timeTriggerSecondaryS != null && message.hasOwnProperty("timeTriggerSecondaryS"))
            if (!$util.isInteger(message.timeTriggerSecondaryS))
                return "timeTriggerSecondaryS: integer expected";
        if (message.rateLimitMax != null && message.hasOwnProperty("rateLimitMax"))
            if (!$util.isInteger(message.rateLimitMax))
                return "rateLimitMax: integer expected";
        if (message.rateLimitDecayS != null && message.hasOwnProperty("rateLimitDecayS"))
            if (!$util.isInteger(message.rateLimitDecayS))
                return "rateLimitDecayS: integer expected";
        if (message.autoWatchTriggerMpsE1 != null && message.hasOwnProperty("autoWatchTriggerMpsE1"))
            if (!$util.isInteger(message.autoWatchTriggerMpsE1))
                return "autoWatchTriggerMpsE1: integer expected";
        if (message.buttonSosEnabled != null && message.hasOwnProperty("buttonSosEnabled"))
            if (typeof message.buttonSosEnabled !== "boolean")
                return "buttonSosEnabled: boolean expected";
        if (message.vehicleType != null && message.hasOwnProperty("vehicleType"))
            switch (message.vehicleType) {
            default:
                return "vehicleType: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.vehicleEngine != null && message.hasOwnProperty("vehicleEngine"))
            switch (message.vehicleEngine) {
            default:
                return "vehicleEngine: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.vehicleRegistration != null && message.hasOwnProperty("vehicleRegistration"))
            if (!$util.isString(message.vehicleRegistration))
                return "vehicleRegistration: string expected";
        if (message.flightPhaseModelSelection != null && message.hasOwnProperty("flightPhaseModelSelection"))
            switch (message.flightPhaseModelSelection) {
            default:
                return "flightPhaseModelSelection: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.flightPhaseIndirectTakeoffRequireEngineOn != null && message.hasOwnProperty("flightPhaseIndirectTakeoffRequireEngineOn"))
            if (typeof message.flightPhaseIndirectTakeoffRequireEngineOn !== "boolean")
                return "flightPhaseIndirectTakeoffRequireEngineOn: boolean expected";
        if (message.flightPhaseIndirectEngineOnOffPermitted != null && message.hasOwnProperty("flightPhaseIndirectEngineOnOffPermitted"))
            if (typeof message.flightPhaseIndirectEngineOnOffPermitted !== "boolean")
                return "flightPhaseIndirectEngineOnOffPermitted: boolean expected";
        if (message.flightPhaseIndirectTaxiStartStopPermitted != null && message.hasOwnProperty("flightPhaseIndirectTaxiStartStopPermitted"))
            if (typeof message.flightPhaseIndirectTaxiStartStopPermitted !== "boolean")
                return "flightPhaseIndirectTaxiStartStopPermitted: boolean expected";
        if (message.flightPhaseIndirectTakeoffLandingPermitted != null && message.hasOwnProperty("flightPhaseIndirectTakeoffLandingPermitted"))
            if (typeof message.flightPhaseIndirectTakeoffLandingPermitted !== "boolean")
                return "flightPhaseIndirectTakeoffLandingPermitted: boolean expected";
        if (message.flightPhaseIndirectHoverStartStopPermitted != null && message.hasOwnProperty("flightPhaseIndirectHoverStartStopPermitted"))
            if (typeof message.flightPhaseIndirectHoverStartStopPermitted !== "boolean")
                return "flightPhaseIndirectHoverStartStopPermitted: boolean expected";
        if (message.imuUploadEnabled != null && message.hasOwnProperty("imuUploadEnabled"))
            if (typeof message.imuUploadEnabled !== "boolean")
                return "imuUploadEnabled: boolean expected";
        if (message.imuCapturePeriodS != null && message.hasOwnProperty("imuCapturePeriodS"))
            if (!$util.isInteger(message.imuCapturePeriodS))
                return "imuCapturePeriodS: integer expected";
        if (message.imuAhrsModel != null && message.hasOwnProperty("imuAhrsModel"))
            switch (message.imuAhrsModel) {
            default:
                return "imuAhrsModel: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.imuForceCaptureInertialAccelerometerRaw != null && message.hasOwnProperty("imuForceCaptureInertialAccelerometerRaw"))
            if (typeof message.imuForceCaptureInertialAccelerometerRaw !== "boolean")
                return "imuForceCaptureInertialAccelerometerRaw: boolean expected";
        if (message.imuForceCaptureInertialGyroscopeRaw != null && message.hasOwnProperty("imuForceCaptureInertialGyroscopeRaw"))
            if (typeof message.imuForceCaptureInertialGyroscopeRaw !== "boolean")
                return "imuForceCaptureInertialGyroscopeRaw: boolean expected";
        if (message.imuForceCaptureEcompassAccelerometerRaw != null && message.hasOwnProperty("imuForceCaptureEcompassAccelerometerRaw"))
            if (typeof message.imuForceCaptureEcompassAccelerometerRaw !== "boolean")
                return "imuForceCaptureEcompassAccelerometerRaw: boolean expected";
        if (message.imuForceCaptureEcompassMagnetometerRaw != null && message.hasOwnProperty("imuForceCaptureEcompassMagnetometerRaw"))
            if (typeof message.imuForceCaptureEcompassMagnetometerRaw !== "boolean")
                return "imuForceCaptureEcompassMagnetometerRaw: boolean expected";
        if (message.imuForceCaptureBarometerPressure != null && message.hasOwnProperty("imuForceCaptureBarometerPressure"))
            if (typeof message.imuForceCaptureBarometerPressure !== "boolean")
                return "imuForceCaptureBarometerPressure: boolean expected";
        if (message.imuForceCaptureBarometerTemperature != null && message.hasOwnProperty("imuForceCaptureBarometerTemperature"))
            if (typeof message.imuForceCaptureBarometerTemperature !== "boolean")
                return "imuForceCaptureBarometerTemperature: boolean expected";
        if (message.imuForceCaptureGnssPosition != null && message.hasOwnProperty("imuForceCaptureGnssPosition"))
            if (typeof message.imuForceCaptureGnssPosition !== "boolean")
                return "imuForceCaptureGnssPosition: boolean expected";
        if (message.imuForceCaptureAhrs != null && message.hasOwnProperty("imuForceCaptureAhrs"))
            if (typeof message.imuForceCaptureAhrs !== "boolean")
                return "imuForceCaptureAhrs: boolean expected";
        if (message.imuForceCaptureInertialAccelerometerMagnitudeAcRms != null && message.hasOwnProperty("imuForceCaptureInertialAccelerometerMagnitudeAcRms"))
            if (typeof message.imuForceCaptureInertialAccelerometerMagnitudeAcRms !== "boolean")
                return "imuForceCaptureInertialAccelerometerMagnitudeAcRms: boolean expected";
        if (message.imuForceCaptureSoundPressureLevel != null && message.hasOwnProperty("imuForceCaptureSoundPressureLevel"))
            if (typeof message.imuForceCaptureSoundPressureLevel !== "boolean")
                return "imuForceCaptureSoundPressureLevel: boolean expected";
        if (message.manifestTimeoutS != null && message.hasOwnProperty("manifestTimeoutS"))
            if (!$util.isInteger(message.manifestTimeoutS))
                return "manifestTimeoutS: integer expected";
        if (message.manifestTagLimit != null && message.hasOwnProperty("manifestTagLimit"))
            if (!$util.isInteger(message.manifestTagLimit))
                return "manifestTagLimit: integer expected";
        if (message.ftpMaxFileSizeBytes != null && message.hasOwnProperty("ftpMaxFileSizeBytes"))
            if (!$util.isInteger(message.ftpMaxFileSizeBytes))
                return "ftpMaxFileSizeBytes: integer expected";
        if (message.flightPhaseGpiTransitionTimeoutS != null && message.hasOwnProperty("flightPhaseGpiTransitionTimeoutS"))
            if (!$util.isInteger(message.flightPhaseGpiTransitionTimeoutS))
                return "flightPhaseGpiTransitionTimeoutS: integer expected";
        if (message.flightPhaseGpiStationaryWindowS != null && message.hasOwnProperty("flightPhaseGpiStationaryWindowS"))
            if (!$util.isInteger(message.flightPhaseGpiStationaryWindowS))
                return "flightPhaseGpiStationaryWindowS: integer expected";
        if (message.flightPhaseGpiStationaryVerticalMpsE1 != null && message.hasOwnProperty("flightPhaseGpiStationaryVerticalMpsE1"))
            if (!$util.isInteger(message.flightPhaseGpiStationaryVerticalMpsE1))
                return "flightPhaseGpiStationaryVerticalMpsE1: integer expected";
        if (message.flightPhaseGpiStationaryHorizontalMpsE1 != null && message.hasOwnProperty("flightPhaseGpiStationaryHorizontalMpsE1"))
            if (!$util.isInteger(message.flightPhaseGpiStationaryHorizontalMpsE1))
                return "flightPhaseGpiStationaryHorizontalMpsE1: integer expected";
        if (message.flightPhaseEngineOnAccelerationWindowSDeprecated != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationWindowSDeprecated"))
            if (!$util.isInteger(message.flightPhaseEngineOnAccelerationWindowSDeprecated))
                return "flightPhaseEngineOnAccelerationWindowSDeprecated: integer expected";
        if (message.flightPhaseEngineOffAccelerationWindowSDeprecated != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationWindowSDeprecated"))
            if (!$util.isInteger(message.flightPhaseEngineOffAccelerationWindowSDeprecated))
                return "flightPhaseEngineOffAccelerationWindowSDeprecated: integer expected";
        if (message.flightPhaseEngineOnAccelerationTriggerGE3 != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationTriggerGE3"))
            if (!$util.isInteger(message.flightPhaseEngineOnAccelerationTriggerGE3))
                return "flightPhaseEngineOnAccelerationTriggerGE3: integer expected";
        if (message.flightPhaseEngineOnAccelerationWindowS != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationWindowS"))
            if (!$util.isInteger(message.flightPhaseEngineOnAccelerationWindowS))
                return "flightPhaseEngineOnAccelerationWindowS: integer expected";
        if (message.flightPhaseEngineOnAccelerationRatioPct != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationRatioPct"))
            if (!$util.isInteger(message.flightPhaseEngineOnAccelerationRatioPct))
                return "flightPhaseEngineOnAccelerationRatioPct: integer expected";
        if (message.flightPhaseEngineOffAccelerationTriggerGE3 != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationTriggerGE3"))
            if (!$util.isInteger(message.flightPhaseEngineOffAccelerationTriggerGE3))
                return "flightPhaseEngineOffAccelerationTriggerGE3: integer expected";
        if (message.flightPhaseEngineOffAccelerationWindowS != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationWindowS"))
            if (!$util.isInteger(message.flightPhaseEngineOffAccelerationWindowS))
                return "flightPhaseEngineOffAccelerationWindowS: integer expected";
        if (message.flightPhaseEngineOffAccelerationRatioPct != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationRatioPct"))
            if (!$util.isInteger(message.flightPhaseEngineOffAccelerationRatioPct))
                return "flightPhaseEngineOffAccelerationRatioPct: integer expected";
        if (message.flightPhaseTaxiStartWindowS != null && message.hasOwnProperty("flightPhaseTaxiStartWindowS"))
            if (!$util.isInteger(message.flightPhaseTaxiStartWindowS))
                return "flightPhaseTaxiStartWindowS: integer expected";
        if (message.flightPhaseTaxiStartRatioPct != null && message.hasOwnProperty("flightPhaseTaxiStartRatioPct"))
            if (!$util.isInteger(message.flightPhaseTaxiStartRatioPct))
                return "flightPhaseTaxiStartRatioPct: integer expected";
        if (message.flightPhaseTaxiStartSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTaxiStartSpeedMinTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseTaxiStartSpeedMinTriggerMpsE1))
                return "flightPhaseTaxiStartSpeedMinTriggerMpsE1: integer expected";
        if (message.flightPhaseTaxiStopWindowS != null && message.hasOwnProperty("flightPhaseTaxiStopWindowS"))
            if (!$util.isInteger(message.flightPhaseTaxiStopWindowS))
                return "flightPhaseTaxiStopWindowS: integer expected";
        if (message.flightPhaseTaxiStopRatioPct != null && message.hasOwnProperty("flightPhaseTaxiStopRatioPct"))
            if (!$util.isInteger(message.flightPhaseTaxiStopRatioPct))
                return "flightPhaseTaxiStopRatioPct: integer expected";
        if (message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTaxiStopSpeedMaxTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1))
                return "flightPhaseTaxiStopSpeedMaxTriggerMpsE1: integer expected";
        if (message.flightPhaseTakeoffStandaloneWindowS != null && message.hasOwnProperty("flightPhaseTakeoffStandaloneWindowS"))
            if (!$util.isInteger(message.flightPhaseTakeoffStandaloneWindowS))
                return "flightPhaseTakeoffStandaloneWindowS: integer expected";
        if (message.flightPhaseTakeoffStandaloneRatioPct != null && message.hasOwnProperty("flightPhaseTakeoffStandaloneRatioPct"))
            if (!$util.isInteger(message.flightPhaseTakeoffStandaloneRatioPct))
                return "flightPhaseTakeoffStandaloneRatioPct: integer expected";
        if (message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1))
                return "flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1: integer expected";
        if (message.flightPhaseTakeoffUseCompositeRule != null && message.hasOwnProperty("flightPhaseTakeoffUseCompositeRule"))
            if (typeof message.flightPhaseTakeoffUseCompositeRule !== "boolean")
                return "flightPhaseTakeoffUseCompositeRule: boolean expected";
        if (message.flightPhaseTakeoffCompositeWindowS != null && message.hasOwnProperty("flightPhaseTakeoffCompositeWindowS"))
            if (!$util.isInteger(message.flightPhaseTakeoffCompositeWindowS))
                return "flightPhaseTakeoffCompositeWindowS: integer expected";
        if (message.flightPhaseTakeoffCompositeRatioPct != null && message.hasOwnProperty("flightPhaseTakeoffCompositeRatioPct"))
            if (!$util.isInteger(message.flightPhaseTakeoffCompositeRatioPct))
                return "flightPhaseTakeoffCompositeRatioPct: integer expected";
        if (message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1))
                return "flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1: integer expected";
        if (message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTakeoffCompositeClimbMinTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1))
                return "flightPhaseTakeoffCompositeClimbMinTriggerMpsE1: integer expected";
        if (message.flightPhaseLandingStandaloneWindowS != null && message.hasOwnProperty("flightPhaseLandingStandaloneWindowS"))
            if (!$util.isInteger(message.flightPhaseLandingStandaloneWindowS))
                return "flightPhaseLandingStandaloneWindowS: integer expected";
        if (message.flightPhaseLandingStandaloneRatioPct != null && message.hasOwnProperty("flightPhaseLandingStandaloneRatioPct"))
            if (!$util.isInteger(message.flightPhaseLandingStandaloneRatioPct))
                return "flightPhaseLandingStandaloneRatioPct: integer expected";
        if (message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1))
                return "flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1: integer expected";
        if (message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 != null && message.hasOwnProperty("flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3"))
            if (!$util.isInteger(message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3))
                return "flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3: integer expected";
        if (message.flightPhaseLandingUseCompositeRule != null && message.hasOwnProperty("flightPhaseLandingUseCompositeRule"))
            if (typeof message.flightPhaseLandingUseCompositeRule !== "boolean")
                return "flightPhaseLandingUseCompositeRule: boolean expected";
        if (message.flightPhaseLandingCompositeWindowS != null && message.hasOwnProperty("flightPhaseLandingCompositeWindowS"))
            if (!$util.isInteger(message.flightPhaseLandingCompositeWindowS))
                return "flightPhaseLandingCompositeWindowS: integer expected";
        if (message.flightPhaseLandingCompositeRatioPct != null && message.hasOwnProperty("flightPhaseLandingCompositeRatioPct"))
            if (!$util.isInteger(message.flightPhaseLandingCompositeRatioPct))
                return "flightPhaseLandingCompositeRatioPct: integer expected";
        if (message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingCompositeSpeedMaxTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1))
                return "flightPhaseLandingCompositeSpeedMaxTriggerMpsE1: integer expected";
        if (message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingCompositeClimbMaxTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1))
                return "flightPhaseLandingCompositeClimbMaxTriggerMpsE1: integer expected";
        if (message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingCompositeDescentMaxTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1))
                return "flightPhaseLandingCompositeDescentMaxTriggerMpsE1: integer expected";
        if (message.flightPhaseQualifyLandingWindowS != null && message.hasOwnProperty("flightPhaseQualifyLandingWindowS"))
            if (!$util.isInteger(message.flightPhaseQualifyLandingWindowS))
                return "flightPhaseQualifyLandingWindowS: integer expected";
        if (message.flightPhaseQualifyLandingRatioPct != null && message.hasOwnProperty("flightPhaseQualifyLandingRatioPct"))
            if (!$util.isInteger(message.flightPhaseQualifyLandingRatioPct))
                return "flightPhaseQualifyLandingRatioPct: integer expected";
        if (message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseQualifyLandingSpeedMaxTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1))
                return "flightPhaseQualifyLandingSpeedMaxTriggerMpsE1: integer expected";
        if (message.flightPhaseHoverStartWindowS != null && message.hasOwnProperty("flightPhaseHoverStartWindowS"))
            if (!$util.isInteger(message.flightPhaseHoverStartWindowS))
                return "flightPhaseHoverStartWindowS: integer expected";
        if (message.flightPhaseHoverStartRatioPct != null && message.hasOwnProperty("flightPhaseHoverStartRatioPct"))
            if (!$util.isInteger(message.flightPhaseHoverStartRatioPct))
                return "flightPhaseHoverStartRatioPct: integer expected";
        if (message.flightPhaseHoverStartSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseHoverStartSpeedMaxTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseHoverStartSpeedMaxTriggerMpsE1))
                return "flightPhaseHoverStartSpeedMaxTriggerMpsE1: integer expected";
        if (message.flightPhaseHoverStopWindowS != null && message.hasOwnProperty("flightPhaseHoverStopWindowS"))
            if (!$util.isInteger(message.flightPhaseHoverStopWindowS))
                return "flightPhaseHoverStopWindowS: integer expected";
        if (message.flightPhaseHoverStopRatioPct != null && message.hasOwnProperty("flightPhaseHoverStopRatioPct"))
            if (!$util.isInteger(message.flightPhaseHoverStopRatioPct))
                return "flightPhaseHoverStopRatioPct: integer expected";
        if (message.flightPhaseHoverStopSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseHoverStopSpeedMinTriggerMpsE1"))
            if (!$util.isInteger(message.flightPhaseHoverStopSpeedMinTriggerMpsE1))
                return "flightPhaseHoverStopSpeedMinTriggerMpsE1: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsServerMainV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsServerMainV1} ProtobufSettingsServerMainV1
     */
    ProtobufSettingsServerMainV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsServerMainV1)
            return object;
        var message = new $root.ProtobufSettingsServerMainV1();
        if (object.bluetoothEnabled != null)
            message.bluetoothEnabled = Boolean(object.bluetoothEnabled);
        if (object.wifiEnabled != null)
            message.wifiEnabled = Boolean(object.wifiEnabled);
        switch (object.wifiLocale) {
        default:
            if (typeof object.wifiLocale === "number") {
                message.wifiLocale = object.wifiLocale;
                break;
            }
            break;
        case "kUsa":
        case 0:
            message.wifiLocale = 0;
            break;
        case "kEurope":
        case 1:
            message.wifiLocale = 1;
            break;
        case "kJapan":
        case 2:
            message.wifiLocale = 2;
            break;
        case "kChina":
        case 3:
            message.wifiLocale = 3;
            break;
        }
        if (object.otaEnabled != null)
            message.otaEnabled = Boolean(object.otaEnabled);
        if (object.logUploadEnabled != null)
            message.logUploadEnabled = Boolean(object.logUploadEnabled);
        if (object.logCapturePeriodS != null)
            message.logCapturePeriodS = object.logCapturePeriodS >>> 0;
        switch (object.logStorageLevel) {
        case "kCritical":
        case 0:
            message.logStorageLevel = 0;
            break;
        case "kError":
        case 1:
            message.logStorageLevel = 1;
            break;
        case "kWarn":
        case 2:
            message.logStorageLevel = 2;
            break;
        case "kNotice":
        case 3:
            message.logStorageLevel = 3;
            break;
        default:
            if (typeof object.logStorageLevel === "number") {
                message.logStorageLevel = object.logStorageLevel;
                break;
            }
            break;
        case "kInfo":
        case 4:
            message.logStorageLevel = 4;
            break;
        case "kLog":
        case 5:
            message.logStorageLevel = 5;
            break;
        case "kDebug":
        case 6:
            message.logStorageLevel = 6;
            break;
        case "kVerbose":
        case 7:
            message.logStorageLevel = 7;
            break;
        }
        if (object.serialUploadEnabled != null)
            message.serialUploadEnabled = Boolean(object.serialUploadEnabled);
        switch (object.trackingChannels) {
        default:
            if (typeof object.trackingChannels === "number") {
                message.trackingChannels = object.trackingChannels;
                break;
            }
            break;
        case "kSatelliteOrCellular":
        case 0:
            message.trackingChannels = 0;
            break;
        case "kSatellite":
        case 1:
            message.trackingChannels = 1;
            break;
        case "kCellular":
        case 2:
            message.trackingChannels = 2;
            break;
        case "kSatelliteAndCellular":
        case 3:
            message.trackingChannels = 3;
            break;
        }
        switch (object.sessionChannels) {
        case "kSatelliteOrCellular":
        case 0:
            message.sessionChannels = 0;
            break;
        default:
            if (typeof object.sessionChannels === "number") {
                message.sessionChannels = object.sessionChannels;
                break;
            }
            break;
        case "kSatellite":
        case 1:
            message.sessionChannels = 1;
            break;
        case "kCellular":
        case 2:
            message.sessionChannels = 2;
            break;
        case "kSatelliteAndCellular":
        case 3:
            message.sessionChannels = 3;
            break;
        }
        if (object.gnssPdopThresholdE1 != null)
            message.gnssPdopThresholdE1 = object.gnssPdopThresholdE1 >>> 0;
        if (object.gnssPositionThresholdM != null)
            message.gnssPositionThresholdM = object.gnssPositionThresholdM >>> 0;
        if (object.gnssStaticHoldVelocityThresholdMpsE2 != null)
            message.gnssStaticHoldVelocityThresholdMpsE2 = object.gnssStaticHoldVelocityThresholdMpsE2 >>> 0;
        if (object.gnssStaticHoldPositionThresholdM != null)
            message.gnssStaticHoldPositionThresholdM = object.gnssStaticHoldPositionThresholdM >>> 0;
        if (object.horizontalSpeed_0EntryTriggerMpsE1 != null)
            message.horizontalSpeed_0EntryTriggerMpsE1 = object.horizontalSpeed_0EntryTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_0ExitTriggerMpsE1 != null)
            message.horizontalSpeed_0ExitTriggerMpsE1 = object.horizontalSpeed_0ExitTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_0ExitDelayS != null)
            message.horizontalSpeed_0ExitDelayS = object.horizontalSpeed_0ExitDelayS >>> 0;
        if (object.horizontalSpeed_1EntryTriggerMpsE1 != null)
            message.horizontalSpeed_1EntryTriggerMpsE1 = object.horizontalSpeed_1EntryTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_1ExitTriggerMpsE1 != null)
            message.horizontalSpeed_1ExitTriggerMpsE1 = object.horizontalSpeed_1ExitTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_1ExitDelayS != null)
            message.horizontalSpeed_1ExitDelayS = object.horizontalSpeed_1ExitDelayS >>> 0;
        if (object.horizontalSpeed_2EntryTriggerMpsE1 != null)
            message.horizontalSpeed_2EntryTriggerMpsE1 = object.horizontalSpeed_2EntryTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_2ExitTriggerMpsE1 != null)
            message.horizontalSpeed_2ExitTriggerMpsE1 = object.horizontalSpeed_2ExitTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_2ExitDelayS != null)
            message.horizontalSpeed_2ExitDelayS = object.horizontalSpeed_2ExitDelayS >>> 0;
        if (object.horizontalSpeed_3EntryTriggerMpsE1 != null)
            message.horizontalSpeed_3EntryTriggerMpsE1 = object.horizontalSpeed_3EntryTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_3ExitTriggerMpsE1 != null)
            message.horizontalSpeed_3ExitTriggerMpsE1 = object.horizontalSpeed_3ExitTriggerMpsE1 >>> 0;
        if (object.horizontalSpeed_3ExitDelayS != null)
            message.horizontalSpeed_3ExitDelayS = object.horizontalSpeed_3ExitDelayS >>> 0;
        if (object.verticalSpeedClimbEntryTriggerMpsE1 != null)
            message.verticalSpeedClimbEntryTriggerMpsE1 = object.verticalSpeedClimbEntryTriggerMpsE1 >>> 0;
        if (object.verticalSpeedClimbExitTriggerMpsE1 != null)
            message.verticalSpeedClimbExitTriggerMpsE1 = object.verticalSpeedClimbExitTriggerMpsE1 >>> 0;
        if (object.verticalSpeedDescentEntryTriggerMpsE1 != null)
            message.verticalSpeedDescentEntryTriggerMpsE1 = object.verticalSpeedDescentEntryTriggerMpsE1 >>> 0;
        if (object.verticalSpeedDescentExitTriggerMpsE1 != null)
            message.verticalSpeedDescentExitTriggerMpsE1 = object.verticalSpeedDescentExitTriggerMpsE1 >>> 0;
        if (object.headingTriggerDegrees != null)
            message.headingTriggerDegrees = object.headingTriggerDegrees >>> 0;
        if (object.timeTriggerPrimaryS != null)
            message.timeTriggerPrimaryS = object.timeTriggerPrimaryS >>> 0;
        if (object.timeTriggerSecondaryS != null)
            message.timeTriggerSecondaryS = object.timeTriggerSecondaryS >>> 0;
        if (object.rateLimitMax != null)
            message.rateLimitMax = object.rateLimitMax >>> 0;
        if (object.rateLimitDecayS != null)
            message.rateLimitDecayS = object.rateLimitDecayS >>> 0;
        if (object.autoWatchTriggerMpsE1 != null)
            message.autoWatchTriggerMpsE1 = object.autoWatchTriggerMpsE1 >>> 0;
        if (object.buttonSosEnabled != null)
            message.buttonSosEnabled = Boolean(object.buttonSosEnabled);
        switch (object.vehicleType) {
        default:
            if (typeof object.vehicleType === "number") {
                message.vehicleType = object.vehicleType;
                break;
            }
            break;
        case "kUnknown":
        case 0:
            message.vehicleType = 0;
            break;
        case "kAircraftFixedWing":
        case 1:
            message.vehicleType = 1;
            break;
        case "kAircraftRotaryWing":
        case 2:
            message.vehicleType = 2;
            break;
        }
        switch (object.vehicleEngine) {
        default:
            if (typeof object.vehicleEngine === "number") {
                message.vehicleEngine = object.vehicleEngine;
                break;
            }
            break;
        case "kSingle":
        case 0:
            message.vehicleEngine = 0;
            break;
        case "kDual":
        case 1:
            message.vehicleEngine = 1;
            break;
        }
        if (object.vehicleRegistration != null)
            message.vehicleRegistration = String(object.vehicleRegistration);
        switch (object.flightPhaseModelSelection) {
        default:
            if (typeof object.flightPhaseModelSelection === "number") {
                message.flightPhaseModelSelection = object.flightPhaseModelSelection;
                break;
            }
            break;
        case "kHybrid":
        case 0:
            message.flightPhaseModelSelection = 0;
            break;
        case "kDirectOnly":
        case 1:
            message.flightPhaseModelSelection = 1;
            break;
        case "kIndirectOnly":
        case 2:
            message.flightPhaseModelSelection = 2;
            break;
        case "kExternalOnly":
        case 3:
            message.flightPhaseModelSelection = 3;
            break;
        }
        if (object.flightPhaseIndirectTakeoffRequireEngineOn != null)
            message.flightPhaseIndirectTakeoffRequireEngineOn = Boolean(object.flightPhaseIndirectTakeoffRequireEngineOn);
        if (object.flightPhaseIndirectEngineOnOffPermitted != null)
            message.flightPhaseIndirectEngineOnOffPermitted = Boolean(object.flightPhaseIndirectEngineOnOffPermitted);
        if (object.flightPhaseIndirectTaxiStartStopPermitted != null)
            message.flightPhaseIndirectTaxiStartStopPermitted = Boolean(object.flightPhaseIndirectTaxiStartStopPermitted);
        if (object.flightPhaseIndirectTakeoffLandingPermitted != null)
            message.flightPhaseIndirectTakeoffLandingPermitted = Boolean(object.flightPhaseIndirectTakeoffLandingPermitted);
        if (object.flightPhaseIndirectHoverStartStopPermitted != null)
            message.flightPhaseIndirectHoverStartStopPermitted = Boolean(object.flightPhaseIndirectHoverStartStopPermitted);
        if (object.imuUploadEnabled != null)
            message.imuUploadEnabled = Boolean(object.imuUploadEnabled);
        if (object.imuCapturePeriodS != null)
            message.imuCapturePeriodS = object.imuCapturePeriodS >>> 0;
        switch (object.imuAhrsModel) {
        default:
            if (typeof object.imuAhrsModel === "number") {
                message.imuAhrsModel = object.imuAhrsModel;
                break;
            }
            break;
        case "kEdgeImu":
        case 0:
            message.imuAhrsModel = 0;
            break;
        case "kPlatformImu":
        case 1:
            message.imuAhrsModel = 1;
            break;
        case "kPlatformGps":
        case 2:
            message.imuAhrsModel = 2;
            break;
        }
        if (object.imuForceCaptureInertialAccelerometerRaw != null)
            message.imuForceCaptureInertialAccelerometerRaw = Boolean(object.imuForceCaptureInertialAccelerometerRaw);
        if (object.imuForceCaptureInertialGyroscopeRaw != null)
            message.imuForceCaptureInertialGyroscopeRaw = Boolean(object.imuForceCaptureInertialGyroscopeRaw);
        if (object.imuForceCaptureEcompassAccelerometerRaw != null)
            message.imuForceCaptureEcompassAccelerometerRaw = Boolean(object.imuForceCaptureEcompassAccelerometerRaw);
        if (object.imuForceCaptureEcompassMagnetometerRaw != null)
            message.imuForceCaptureEcompassMagnetometerRaw = Boolean(object.imuForceCaptureEcompassMagnetometerRaw);
        if (object.imuForceCaptureBarometerPressure != null)
            message.imuForceCaptureBarometerPressure = Boolean(object.imuForceCaptureBarometerPressure);
        if (object.imuForceCaptureBarometerTemperature != null)
            message.imuForceCaptureBarometerTemperature = Boolean(object.imuForceCaptureBarometerTemperature);
        if (object.imuForceCaptureGnssPosition != null)
            message.imuForceCaptureGnssPosition = Boolean(object.imuForceCaptureGnssPosition);
        if (object.imuForceCaptureAhrs != null)
            message.imuForceCaptureAhrs = Boolean(object.imuForceCaptureAhrs);
        if (object.imuForceCaptureInertialAccelerometerMagnitudeAcRms != null)
            message.imuForceCaptureInertialAccelerometerMagnitudeAcRms = Boolean(object.imuForceCaptureInertialAccelerometerMagnitudeAcRms);
        if (object.imuForceCaptureSoundPressureLevel != null)
            message.imuForceCaptureSoundPressureLevel = Boolean(object.imuForceCaptureSoundPressureLevel);
        if (object.manifestTimeoutS != null)
            message.manifestTimeoutS = object.manifestTimeoutS >>> 0;
        if (object.manifestTagLimit != null)
            message.manifestTagLimit = object.manifestTagLimit >>> 0;
        if (object.ftpMaxFileSizeBytes != null)
            message.ftpMaxFileSizeBytes = object.ftpMaxFileSizeBytes >>> 0;
        if (object.flightPhaseGpiTransitionTimeoutS != null)
            message.flightPhaseGpiTransitionTimeoutS = object.flightPhaseGpiTransitionTimeoutS >>> 0;
        if (object.flightPhaseGpiStationaryWindowS != null)
            message.flightPhaseGpiStationaryWindowS = object.flightPhaseGpiStationaryWindowS >>> 0;
        if (object.flightPhaseGpiStationaryVerticalMpsE1 != null)
            message.flightPhaseGpiStationaryVerticalMpsE1 = object.flightPhaseGpiStationaryVerticalMpsE1 >>> 0;
        if (object.flightPhaseGpiStationaryHorizontalMpsE1 != null)
            message.flightPhaseGpiStationaryHorizontalMpsE1 = object.flightPhaseGpiStationaryHorizontalMpsE1 >>> 0;
        if (object.flightPhaseEngineOnAccelerationWindowSDeprecated != null)
            message.flightPhaseEngineOnAccelerationWindowSDeprecated = object.flightPhaseEngineOnAccelerationWindowSDeprecated >>> 0;
        if (object.flightPhaseEngineOffAccelerationWindowSDeprecated != null)
            message.flightPhaseEngineOffAccelerationWindowSDeprecated = object.flightPhaseEngineOffAccelerationWindowSDeprecated >>> 0;
        if (object.flightPhaseEngineOnAccelerationTriggerGE3 != null)
            message.flightPhaseEngineOnAccelerationTriggerGE3 = object.flightPhaseEngineOnAccelerationTriggerGE3 >>> 0;
        if (object.flightPhaseEngineOnAccelerationWindowS != null)
            message.flightPhaseEngineOnAccelerationWindowS = object.flightPhaseEngineOnAccelerationWindowS >>> 0;
        if (object.flightPhaseEngineOnAccelerationRatioPct != null)
            message.flightPhaseEngineOnAccelerationRatioPct = object.flightPhaseEngineOnAccelerationRatioPct >>> 0;
        if (object.flightPhaseEngineOffAccelerationTriggerGE3 != null)
            message.flightPhaseEngineOffAccelerationTriggerGE3 = object.flightPhaseEngineOffAccelerationTriggerGE3 >>> 0;
        if (object.flightPhaseEngineOffAccelerationWindowS != null)
            message.flightPhaseEngineOffAccelerationWindowS = object.flightPhaseEngineOffAccelerationWindowS >>> 0;
        if (object.flightPhaseEngineOffAccelerationRatioPct != null)
            message.flightPhaseEngineOffAccelerationRatioPct = object.flightPhaseEngineOffAccelerationRatioPct >>> 0;
        if (object.flightPhaseTaxiStartWindowS != null)
            message.flightPhaseTaxiStartWindowS = object.flightPhaseTaxiStartWindowS >>> 0;
        if (object.flightPhaseTaxiStartRatioPct != null)
            message.flightPhaseTaxiStartRatioPct = object.flightPhaseTaxiStartRatioPct >>> 0;
        if (object.flightPhaseTaxiStartSpeedMinTriggerMpsE1 != null)
            message.flightPhaseTaxiStartSpeedMinTriggerMpsE1 = object.flightPhaseTaxiStartSpeedMinTriggerMpsE1 >>> 0;
        if (object.flightPhaseTaxiStopWindowS != null)
            message.flightPhaseTaxiStopWindowS = object.flightPhaseTaxiStopWindowS >>> 0;
        if (object.flightPhaseTaxiStopRatioPct != null)
            message.flightPhaseTaxiStopRatioPct = object.flightPhaseTaxiStopRatioPct >>> 0;
        if (object.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 != null)
            message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 = object.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 >>> 0;
        if (object.flightPhaseTakeoffStandaloneWindowS != null)
            message.flightPhaseTakeoffStandaloneWindowS = object.flightPhaseTakeoffStandaloneWindowS >>> 0;
        if (object.flightPhaseTakeoffStandaloneRatioPct != null)
            message.flightPhaseTakeoffStandaloneRatioPct = object.flightPhaseTakeoffStandaloneRatioPct >>> 0;
        if (object.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 != null)
            message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 = object.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 >>> 0;
        if (object.flightPhaseTakeoffUseCompositeRule != null)
            message.flightPhaseTakeoffUseCompositeRule = Boolean(object.flightPhaseTakeoffUseCompositeRule);
        if (object.flightPhaseTakeoffCompositeWindowS != null)
            message.flightPhaseTakeoffCompositeWindowS = object.flightPhaseTakeoffCompositeWindowS >>> 0;
        if (object.flightPhaseTakeoffCompositeRatioPct != null)
            message.flightPhaseTakeoffCompositeRatioPct = object.flightPhaseTakeoffCompositeRatioPct >>> 0;
        if (object.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 != null)
            message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 = object.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 >>> 0;
        if (object.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 != null)
            message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 = object.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 >>> 0;
        if (object.flightPhaseLandingStandaloneWindowS != null)
            message.flightPhaseLandingStandaloneWindowS = object.flightPhaseLandingStandaloneWindowS >>> 0;
        if (object.flightPhaseLandingStandaloneRatioPct != null)
            message.flightPhaseLandingStandaloneRatioPct = object.flightPhaseLandingStandaloneRatioPct >>> 0;
        if (object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 != null)
            message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 = object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 >>> 0;
        if (object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 != null)
            message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 = object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 >>> 0;
        if (object.flightPhaseLandingUseCompositeRule != null)
            message.flightPhaseLandingUseCompositeRule = Boolean(object.flightPhaseLandingUseCompositeRule);
        if (object.flightPhaseLandingCompositeWindowS != null)
            message.flightPhaseLandingCompositeWindowS = object.flightPhaseLandingCompositeWindowS >>> 0;
        if (object.flightPhaseLandingCompositeRatioPct != null)
            message.flightPhaseLandingCompositeRatioPct = object.flightPhaseLandingCompositeRatioPct >>> 0;
        if (object.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 != null)
            message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 = object.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 >>> 0;
        if (object.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 != null)
            message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 = object.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 >>> 0;
        if (object.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 != null)
            message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 = object.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 >>> 0;
        if (object.flightPhaseQualifyLandingWindowS != null)
            message.flightPhaseQualifyLandingWindowS = object.flightPhaseQualifyLandingWindowS >>> 0;
        if (object.flightPhaseQualifyLandingRatioPct != null)
            message.flightPhaseQualifyLandingRatioPct = object.flightPhaseQualifyLandingRatioPct >>> 0;
        if (object.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 != null)
            message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 = object.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 >>> 0;
        if (object.flightPhaseHoverStartWindowS != null)
            message.flightPhaseHoverStartWindowS = object.flightPhaseHoverStartWindowS >>> 0;
        if (object.flightPhaseHoverStartRatioPct != null)
            message.flightPhaseHoverStartRatioPct = object.flightPhaseHoverStartRatioPct >>> 0;
        if (object.flightPhaseHoverStartSpeedMaxTriggerMpsE1 != null)
            message.flightPhaseHoverStartSpeedMaxTriggerMpsE1 = object.flightPhaseHoverStartSpeedMaxTriggerMpsE1 >>> 0;
        if (object.flightPhaseHoverStopWindowS != null)
            message.flightPhaseHoverStopWindowS = object.flightPhaseHoverStopWindowS >>> 0;
        if (object.flightPhaseHoverStopRatioPct != null)
            message.flightPhaseHoverStopRatioPct = object.flightPhaseHoverStopRatioPct >>> 0;
        if (object.flightPhaseHoverStopSpeedMinTriggerMpsE1 != null)
            message.flightPhaseHoverStopSpeedMinTriggerMpsE1 = object.flightPhaseHoverStopSpeedMinTriggerMpsE1 >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSettingsServerMainV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {ProtobufSettingsServerMainV1} message ProtobufSettingsServerMainV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsServerMainV1.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.bluetoothEnabled = true;
            object.wifiEnabled = false;
            object.wifiLocale = options.enums === String ? "kUsa" : 0;
            object.otaEnabled = true;
            object.ftpMaxFileSizeBytes = 102400;
            object.logUploadEnabled = true;
            object.logStorageLevel = options.enums === String ? "kInfo" : 4;
            object.logCapturePeriodS = 120;
            object.imuUploadEnabled = true;
            object.trackingChannels = options.enums === String ? "kSatelliteOrCellular" : 0;
            object.serialUploadEnabled = true;
            object.sessionChannels = options.enums === String ? "kSatellite" : 1;
            object.horizontalSpeed_0EntryTriggerMpsE1 = 0;
            object.horizontalSpeed_0ExitTriggerMpsE1 = 0;
            object.horizontalSpeed_0ExitDelayS = 0;
            object.horizontalSpeed_1EntryTriggerMpsE1 = 0;
            object.horizontalSpeed_1ExitTriggerMpsE1 = 0;
            object.horizontalSpeed_1ExitDelayS = 0;
            object.horizontalSpeed_2EntryTriggerMpsE1 = 0;
            object.horizontalSpeed_2ExitTriggerMpsE1 = 0;
            object.horizontalSpeed_2ExitDelayS = 0;
            object.horizontalSpeed_3EntryTriggerMpsE1 = 0;
            object.horizontalSpeed_3ExitTriggerMpsE1 = 0;
            object.horizontalSpeed_3ExitDelayS = 0;
            object.verticalSpeedClimbEntryTriggerMpsE1 = 0;
            object.verticalSpeedClimbExitTriggerMpsE1 = 0;
            object.verticalSpeedDescentEntryTriggerMpsE1 = 0;
            object.verticalSpeedDescentExitTriggerMpsE1 = 0;
            object.gnssPdopThresholdE1 = 100;
            object.gnssPositionThresholdM = 100;
            object.gnssStaticHoldVelocityThresholdMpsE2 = 0;
            object.gnssStaticHoldPositionThresholdM = 0;
            object.headingTriggerDegrees = 30;
            object.timeTriggerPrimaryS = 60;
            object.timeTriggerSecondaryS = 15;
            object.rateLimitMax = 20;
            object.rateLimitDecayS = 10;
            object.autoWatchTriggerMpsE1 = 0;
            object.buttonSosEnabled = true;
            object.vehicleType = options.enums === String ? "kUnknown" : 0;
            object.vehicleEngine = options.enums === String ? "kSingle" : 0;
            object.vehicleRegistration = "";
            object.flightPhaseModelSelection = options.enums === String ? "kHybrid" : 0;
            object.flightPhaseGpiTransitionTimeoutS = 60;
            object.flightPhaseGpiStationaryWindowS = 2;
            object.flightPhaseGpiStationaryVerticalMpsE1 = 10;
            object.flightPhaseGpiStationaryHorizontalMpsE1 = 5;
            object.flightPhaseTaxiStartSpeedMinTriggerMpsE1 = 0;
            object.flightPhaseTaxiStartWindowS = 0;
            object.flightPhaseTaxiStartRatioPct = 0;
            object.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 = 0;
            object.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 = 0;
            object.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 = 0;
            object.flightPhaseTaxiStopWindowS = 0;
            object.flightPhaseTaxiStopRatioPct = 0;
            object.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 = 0;
            object.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 = 0;
            object.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 = 0;
            object.flightPhaseTakeoffStandaloneWindowS = 0;
            object.flightPhaseTakeoffStandaloneRatioPct = 0;
            object.flightPhaseTakeoffUseCompositeRule = false;
            object.flightPhaseLandingUseCompositeRule = false;
            object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 = 0;
            object.flightPhaseLandingStandaloneWindowS = 0;
            object.flightPhaseLandingStandaloneRatioPct = 0;
            object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 = 0;
            object.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 = 0;
            object.imuForceCaptureInertialAccelerometerRaw = false;
            object.imuForceCaptureInertialGyroscopeRaw = false;
            object.imuForceCaptureEcompassAccelerometerRaw = false;
            object.imuForceCaptureEcompassMagnetometerRaw = false;
            object.imuForceCaptureBarometerPressure = false;
            object.imuForceCaptureBarometerTemperature = false;
            object.imuForceCaptureGnssPosition = false;
            object.imuForceCaptureAhrs = false;
            object.imuForceCaptureInertialAccelerometerMagnitudeAcRms = false;
            object.imuForceCaptureSoundPressureLevel = false;
            object.imuAhrsModel = options.enums === String ? "kEdgeImu" : 0;
            object.imuCapturePeriodS = 60;
            object.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 = 0;
            object.flightPhaseHoverStartSpeedMaxTriggerMpsE1 = 0;
            object.flightPhaseHoverStartWindowS = 0;
            object.flightPhaseHoverStartRatioPct = 0;
            object.flightPhaseHoverStopSpeedMinTriggerMpsE1 = 0;
            object.flightPhaseHoverStopWindowS = 0;
            object.flightPhaseHoverStopRatioPct = 0;
            object.flightPhaseTakeoffCompositeWindowS = 0;
            object.flightPhaseTakeoffCompositeRatioPct = 0;
            object.flightPhaseLandingCompositeWindowS = 0;
            object.flightPhaseLandingCompositeRatioPct = 0;
            object.flightPhaseEngineOnAccelerationTriggerGE3 = 0;
            object.flightPhaseEngineOnAccelerationWindowSDeprecated = 0;
            object.flightPhaseEngineOnAccelerationRatioPct = 0;
            object.flightPhaseEngineOffAccelerationTriggerGE3 = 0;
            object.flightPhaseEngineOffAccelerationWindowSDeprecated = 0;
            object.flightPhaseEngineOffAccelerationRatioPct = 0;
            object.flightPhaseEngineOnAccelerationWindowS = 0;
            object.flightPhaseEngineOffAccelerationWindowS = 0;
            object.flightPhaseQualifyLandingWindowS = 0;
            object.flightPhaseQualifyLandingRatioPct = 0;
            object.flightPhaseIndirectEngineOnOffPermitted = true;
            object.flightPhaseIndirectTaxiStartStopPermitted = true;
            object.flightPhaseIndirectTakeoffLandingPermitted = true;
            object.flightPhaseIndirectHoverStartStopPermitted = true;
            object.flightPhaseIndirectTakeoffRequireEngineOn = false;
            object.manifestTimeoutS = 60;
            object.manifestTagLimit = 5;
        }
        if (message.bluetoothEnabled != null && message.hasOwnProperty("bluetoothEnabled"))
            object.bluetoothEnabled = message.bluetoothEnabled;
        if (message.wifiEnabled != null && message.hasOwnProperty("wifiEnabled"))
            object.wifiEnabled = message.wifiEnabled;
        if (message.wifiLocale != null && message.hasOwnProperty("wifiLocale"))
            object.wifiLocale = options.enums === String ? $root.ProtobufSettingsServerMainV1.WifiLocale[message.wifiLocale] === undefined ? message.wifiLocale : $root.ProtobufSettingsServerMainV1.WifiLocale[message.wifiLocale] : message.wifiLocale;
        if (message.otaEnabled != null && message.hasOwnProperty("otaEnabled"))
            object.otaEnabled = message.otaEnabled;
        if (message.ftpMaxFileSizeBytes != null && message.hasOwnProperty("ftpMaxFileSizeBytes"))
            object.ftpMaxFileSizeBytes = message.ftpMaxFileSizeBytes;
        if (message.logUploadEnabled != null && message.hasOwnProperty("logUploadEnabled"))
            object.logUploadEnabled = message.logUploadEnabled;
        if (message.logStorageLevel != null && message.hasOwnProperty("logStorageLevel"))
            object.logStorageLevel = options.enums === String ? $root.ProtobufSettingsServerMainV1.LogLevel[message.logStorageLevel] === undefined ? message.logStorageLevel : $root.ProtobufSettingsServerMainV1.LogLevel[message.logStorageLevel] : message.logStorageLevel;
        if (message.logCapturePeriodS != null && message.hasOwnProperty("logCapturePeriodS"))
            object.logCapturePeriodS = message.logCapturePeriodS;
        if (message.imuUploadEnabled != null && message.hasOwnProperty("imuUploadEnabled"))
            object.imuUploadEnabled = message.imuUploadEnabled;
        if (message.trackingChannels != null && message.hasOwnProperty("trackingChannels"))
            object.trackingChannels = options.enums === String ? $root.ProtobufSettingsServerMainV1.Channels[message.trackingChannels] === undefined ? message.trackingChannels : $root.ProtobufSettingsServerMainV1.Channels[message.trackingChannels] : message.trackingChannels;
        if (message.serialUploadEnabled != null && message.hasOwnProperty("serialUploadEnabled"))
            object.serialUploadEnabled = message.serialUploadEnabled;
        if (message.sessionChannels != null && message.hasOwnProperty("sessionChannels"))
            object.sessionChannels = options.enums === String ? $root.ProtobufSettingsServerMainV1.Channels[message.sessionChannels] === undefined ? message.sessionChannels : $root.ProtobufSettingsServerMainV1.Channels[message.sessionChannels] : message.sessionChannels;
        if (message.horizontalSpeed_0EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_0EntryTriggerMpsE1"))
            object.horizontalSpeed_0EntryTriggerMpsE1 = message.horizontalSpeed_0EntryTriggerMpsE1;
        if (message.horizontalSpeed_0ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_0ExitTriggerMpsE1"))
            object.horizontalSpeed_0ExitTriggerMpsE1 = message.horizontalSpeed_0ExitTriggerMpsE1;
        if (message.horizontalSpeed_0ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_0ExitDelayS"))
            object.horizontalSpeed_0ExitDelayS = message.horizontalSpeed_0ExitDelayS;
        if (message.horizontalSpeed_1EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_1EntryTriggerMpsE1"))
            object.horizontalSpeed_1EntryTriggerMpsE1 = message.horizontalSpeed_1EntryTriggerMpsE1;
        if (message.horizontalSpeed_1ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_1ExitTriggerMpsE1"))
            object.horizontalSpeed_1ExitTriggerMpsE1 = message.horizontalSpeed_1ExitTriggerMpsE1;
        if (message.horizontalSpeed_1ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_1ExitDelayS"))
            object.horizontalSpeed_1ExitDelayS = message.horizontalSpeed_1ExitDelayS;
        if (message.horizontalSpeed_2EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_2EntryTriggerMpsE1"))
            object.horizontalSpeed_2EntryTriggerMpsE1 = message.horizontalSpeed_2EntryTriggerMpsE1;
        if (message.horizontalSpeed_2ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_2ExitTriggerMpsE1"))
            object.horizontalSpeed_2ExitTriggerMpsE1 = message.horizontalSpeed_2ExitTriggerMpsE1;
        if (message.horizontalSpeed_2ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_2ExitDelayS"))
            object.horizontalSpeed_2ExitDelayS = message.horizontalSpeed_2ExitDelayS;
        if (message.horizontalSpeed_3EntryTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_3EntryTriggerMpsE1"))
            object.horizontalSpeed_3EntryTriggerMpsE1 = message.horizontalSpeed_3EntryTriggerMpsE1;
        if (message.horizontalSpeed_3ExitTriggerMpsE1 != null && message.hasOwnProperty("horizontalSpeed_3ExitTriggerMpsE1"))
            object.horizontalSpeed_3ExitTriggerMpsE1 = message.horizontalSpeed_3ExitTriggerMpsE1;
        if (message.horizontalSpeed_3ExitDelayS != null && message.hasOwnProperty("horizontalSpeed_3ExitDelayS"))
            object.horizontalSpeed_3ExitDelayS = message.horizontalSpeed_3ExitDelayS;
        if (message.verticalSpeedClimbEntryTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedClimbEntryTriggerMpsE1"))
            object.verticalSpeedClimbEntryTriggerMpsE1 = message.verticalSpeedClimbEntryTriggerMpsE1;
        if (message.verticalSpeedClimbExitTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedClimbExitTriggerMpsE1"))
            object.verticalSpeedClimbExitTriggerMpsE1 = message.verticalSpeedClimbExitTriggerMpsE1;
        if (message.verticalSpeedDescentEntryTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedDescentEntryTriggerMpsE1"))
            object.verticalSpeedDescentEntryTriggerMpsE1 = message.verticalSpeedDescentEntryTriggerMpsE1;
        if (message.verticalSpeedDescentExitTriggerMpsE1 != null && message.hasOwnProperty("verticalSpeedDescentExitTriggerMpsE1"))
            object.verticalSpeedDescentExitTriggerMpsE1 = message.verticalSpeedDescentExitTriggerMpsE1;
        if (message.gnssPdopThresholdE1 != null && message.hasOwnProperty("gnssPdopThresholdE1"))
            object.gnssPdopThresholdE1 = message.gnssPdopThresholdE1;
        if (message.gnssPositionThresholdM != null && message.hasOwnProperty("gnssPositionThresholdM"))
            object.gnssPositionThresholdM = message.gnssPositionThresholdM;
        if (message.gnssStaticHoldVelocityThresholdMpsE2 != null && message.hasOwnProperty("gnssStaticHoldVelocityThresholdMpsE2"))
            object.gnssStaticHoldVelocityThresholdMpsE2 = message.gnssStaticHoldVelocityThresholdMpsE2;
        if (message.gnssStaticHoldPositionThresholdM != null && message.hasOwnProperty("gnssStaticHoldPositionThresholdM"))
            object.gnssStaticHoldPositionThresholdM = message.gnssStaticHoldPositionThresholdM;
        if (message.headingTriggerDegrees != null && message.hasOwnProperty("headingTriggerDegrees"))
            object.headingTriggerDegrees = message.headingTriggerDegrees;
        if (message.timeTriggerPrimaryS != null && message.hasOwnProperty("timeTriggerPrimaryS"))
            object.timeTriggerPrimaryS = message.timeTriggerPrimaryS;
        if (message.timeTriggerSecondaryS != null && message.hasOwnProperty("timeTriggerSecondaryS"))
            object.timeTriggerSecondaryS = message.timeTriggerSecondaryS;
        if (message.rateLimitMax != null && message.hasOwnProperty("rateLimitMax"))
            object.rateLimitMax = message.rateLimitMax;
        if (message.rateLimitDecayS != null && message.hasOwnProperty("rateLimitDecayS"))
            object.rateLimitDecayS = message.rateLimitDecayS;
        if (message.autoWatchTriggerMpsE1 != null && message.hasOwnProperty("autoWatchTriggerMpsE1"))
            object.autoWatchTriggerMpsE1 = message.autoWatchTriggerMpsE1;
        if (message.buttonSosEnabled != null && message.hasOwnProperty("buttonSosEnabled"))
            object.buttonSosEnabled = message.buttonSosEnabled;
        if (message.vehicleType != null && message.hasOwnProperty("vehicleType"))
            object.vehicleType = options.enums === String ? $root.ProtobufSettingsServerMainV1.VehicleType[message.vehicleType] === undefined ? message.vehicleType : $root.ProtobufSettingsServerMainV1.VehicleType[message.vehicleType] : message.vehicleType;
        if (message.vehicleEngine != null && message.hasOwnProperty("vehicleEngine"))
            object.vehicleEngine = options.enums === String ? $root.ProtobufSettingsServerMainV1.VehicleEngine[message.vehicleEngine] === undefined ? message.vehicleEngine : $root.ProtobufSettingsServerMainV1.VehicleEngine[message.vehicleEngine] : message.vehicleEngine;
        if (message.vehicleRegistration != null && message.hasOwnProperty("vehicleRegistration"))
            object.vehicleRegistration = message.vehicleRegistration;
        if (message.flightPhaseModelSelection != null && message.hasOwnProperty("flightPhaseModelSelection"))
            object.flightPhaseModelSelection = options.enums === String ? $root.ProtobufSettingsServerMainV1.FlightPhaseModelSelection[message.flightPhaseModelSelection] === undefined ? message.flightPhaseModelSelection : $root.ProtobufSettingsServerMainV1.FlightPhaseModelSelection[message.flightPhaseModelSelection] : message.flightPhaseModelSelection;
        if (message.flightPhaseGpiTransitionTimeoutS != null && message.hasOwnProperty("flightPhaseGpiTransitionTimeoutS"))
            object.flightPhaseGpiTransitionTimeoutS = message.flightPhaseGpiTransitionTimeoutS;
        if (message.flightPhaseGpiStationaryWindowS != null && message.hasOwnProperty("flightPhaseGpiStationaryWindowS"))
            object.flightPhaseGpiStationaryWindowS = message.flightPhaseGpiStationaryWindowS;
        if (message.flightPhaseGpiStationaryVerticalMpsE1 != null && message.hasOwnProperty("flightPhaseGpiStationaryVerticalMpsE1"))
            object.flightPhaseGpiStationaryVerticalMpsE1 = message.flightPhaseGpiStationaryVerticalMpsE1;
        if (message.flightPhaseGpiStationaryHorizontalMpsE1 != null && message.hasOwnProperty("flightPhaseGpiStationaryHorizontalMpsE1"))
            object.flightPhaseGpiStationaryHorizontalMpsE1 = message.flightPhaseGpiStationaryHorizontalMpsE1;
        if (message.flightPhaseTaxiStartSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTaxiStartSpeedMinTriggerMpsE1"))
            object.flightPhaseTaxiStartSpeedMinTriggerMpsE1 = message.flightPhaseTaxiStartSpeedMinTriggerMpsE1;
        if (message.flightPhaseTaxiStartWindowS != null && message.hasOwnProperty("flightPhaseTaxiStartWindowS"))
            object.flightPhaseTaxiStartWindowS = message.flightPhaseTaxiStartWindowS;
        if (message.flightPhaseTaxiStartRatioPct != null && message.hasOwnProperty("flightPhaseTaxiStartRatioPct"))
            object.flightPhaseTaxiStartRatioPct = message.flightPhaseTaxiStartRatioPct;
        if (message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingCompositeClimbMaxTriggerMpsE1"))
            object.flightPhaseLandingCompositeClimbMaxTriggerMpsE1 = message.flightPhaseLandingCompositeClimbMaxTriggerMpsE1;
        if (message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingCompositeDescentMaxTriggerMpsE1"))
            object.flightPhaseLandingCompositeDescentMaxTriggerMpsE1 = message.flightPhaseLandingCompositeDescentMaxTriggerMpsE1;
        if (message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTaxiStopSpeedMaxTriggerMpsE1"))
            object.flightPhaseTaxiStopSpeedMaxTriggerMpsE1 = message.flightPhaseTaxiStopSpeedMaxTriggerMpsE1;
        if (message.flightPhaseTaxiStopWindowS != null && message.hasOwnProperty("flightPhaseTaxiStopWindowS"))
            object.flightPhaseTaxiStopWindowS = message.flightPhaseTaxiStopWindowS;
        if (message.flightPhaseTaxiStopRatioPct != null && message.hasOwnProperty("flightPhaseTaxiStopRatioPct"))
            object.flightPhaseTaxiStopRatioPct = message.flightPhaseTaxiStopRatioPct;
        if (message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1"))
            object.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1 = message.flightPhaseTakeoffCompositeSpeedMinTriggerMpsE1;
        if (message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTakeoffCompositeClimbMinTriggerMpsE1"))
            object.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1 = message.flightPhaseTakeoffCompositeClimbMinTriggerMpsE1;
        if (message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1"))
            object.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1 = message.flightPhaseTakeoffStandaloneSpeedMinTriggerMpsE1;
        if (message.flightPhaseTakeoffStandaloneWindowS != null && message.hasOwnProperty("flightPhaseTakeoffStandaloneWindowS"))
            object.flightPhaseTakeoffStandaloneWindowS = message.flightPhaseTakeoffStandaloneWindowS;
        if (message.flightPhaseTakeoffStandaloneRatioPct != null && message.hasOwnProperty("flightPhaseTakeoffStandaloneRatioPct"))
            object.flightPhaseTakeoffStandaloneRatioPct = message.flightPhaseTakeoffStandaloneRatioPct;
        if (message.flightPhaseTakeoffUseCompositeRule != null && message.hasOwnProperty("flightPhaseTakeoffUseCompositeRule"))
            object.flightPhaseTakeoffUseCompositeRule = message.flightPhaseTakeoffUseCompositeRule;
        if (message.flightPhaseLandingUseCompositeRule != null && message.hasOwnProperty("flightPhaseLandingUseCompositeRule"))
            object.flightPhaseLandingUseCompositeRule = message.flightPhaseLandingUseCompositeRule;
        if (message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1"))
            object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1 = message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE1;
        if (message.flightPhaseLandingStandaloneWindowS != null && message.hasOwnProperty("flightPhaseLandingStandaloneWindowS"))
            object.flightPhaseLandingStandaloneWindowS = message.flightPhaseLandingStandaloneWindowS;
        if (message.flightPhaseLandingStandaloneRatioPct != null && message.hasOwnProperty("flightPhaseLandingStandaloneRatioPct"))
            object.flightPhaseLandingStandaloneRatioPct = message.flightPhaseLandingStandaloneRatioPct;
        if (message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 != null && message.hasOwnProperty("flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3"))
            object.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3 = message.flightPhaseLandingStandaloneSpeedMaxTriggerMpsE3;
        if (message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseLandingCompositeSpeedMaxTriggerMpsE1"))
            object.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1 = message.flightPhaseLandingCompositeSpeedMaxTriggerMpsE1;
        if (message.imuForceCaptureInertialAccelerometerRaw != null && message.hasOwnProperty("imuForceCaptureInertialAccelerometerRaw"))
            object.imuForceCaptureInertialAccelerometerRaw = message.imuForceCaptureInertialAccelerometerRaw;
        if (message.imuForceCaptureInertialGyroscopeRaw != null && message.hasOwnProperty("imuForceCaptureInertialGyroscopeRaw"))
            object.imuForceCaptureInertialGyroscopeRaw = message.imuForceCaptureInertialGyroscopeRaw;
        if (message.imuForceCaptureEcompassAccelerometerRaw != null && message.hasOwnProperty("imuForceCaptureEcompassAccelerometerRaw"))
            object.imuForceCaptureEcompassAccelerometerRaw = message.imuForceCaptureEcompassAccelerometerRaw;
        if (message.imuForceCaptureEcompassMagnetometerRaw != null && message.hasOwnProperty("imuForceCaptureEcompassMagnetometerRaw"))
            object.imuForceCaptureEcompassMagnetometerRaw = message.imuForceCaptureEcompassMagnetometerRaw;
        if (message.imuForceCaptureBarometerPressure != null && message.hasOwnProperty("imuForceCaptureBarometerPressure"))
            object.imuForceCaptureBarometerPressure = message.imuForceCaptureBarometerPressure;
        if (message.imuForceCaptureBarometerTemperature != null && message.hasOwnProperty("imuForceCaptureBarometerTemperature"))
            object.imuForceCaptureBarometerTemperature = message.imuForceCaptureBarometerTemperature;
        if (message.imuForceCaptureGnssPosition != null && message.hasOwnProperty("imuForceCaptureGnssPosition"))
            object.imuForceCaptureGnssPosition = message.imuForceCaptureGnssPosition;
        if (message.imuForceCaptureAhrs != null && message.hasOwnProperty("imuForceCaptureAhrs"))
            object.imuForceCaptureAhrs = message.imuForceCaptureAhrs;
        if (message.imuForceCaptureInertialAccelerometerMagnitudeAcRms != null && message.hasOwnProperty("imuForceCaptureInertialAccelerometerMagnitudeAcRms"))
            object.imuForceCaptureInertialAccelerometerMagnitudeAcRms = message.imuForceCaptureInertialAccelerometerMagnitudeAcRms;
        if (message.imuForceCaptureSoundPressureLevel != null && message.hasOwnProperty("imuForceCaptureSoundPressureLevel"))
            object.imuForceCaptureSoundPressureLevel = message.imuForceCaptureSoundPressureLevel;
        if (message.imuAhrsModel != null && message.hasOwnProperty("imuAhrsModel"))
            object.imuAhrsModel = options.enums === String ? $root.ProtobufSettingsServerMainV1.AhrsModel[message.imuAhrsModel] === undefined ? message.imuAhrsModel : $root.ProtobufSettingsServerMainV1.AhrsModel[message.imuAhrsModel] : message.imuAhrsModel;
        if (message.imuCapturePeriodS != null && message.hasOwnProperty("imuCapturePeriodS"))
            object.imuCapturePeriodS = message.imuCapturePeriodS;
        if (message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseQualifyLandingSpeedMaxTriggerMpsE1"))
            object.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1 = message.flightPhaseQualifyLandingSpeedMaxTriggerMpsE1;
        if (message.flightPhaseHoverStartSpeedMaxTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseHoverStartSpeedMaxTriggerMpsE1"))
            object.flightPhaseHoverStartSpeedMaxTriggerMpsE1 = message.flightPhaseHoverStartSpeedMaxTriggerMpsE1;
        if (message.flightPhaseHoverStartWindowS != null && message.hasOwnProperty("flightPhaseHoverStartWindowS"))
            object.flightPhaseHoverStartWindowS = message.flightPhaseHoverStartWindowS;
        if (message.flightPhaseHoverStartRatioPct != null && message.hasOwnProperty("flightPhaseHoverStartRatioPct"))
            object.flightPhaseHoverStartRatioPct = message.flightPhaseHoverStartRatioPct;
        if (message.flightPhaseHoverStopSpeedMinTriggerMpsE1 != null && message.hasOwnProperty("flightPhaseHoverStopSpeedMinTriggerMpsE1"))
            object.flightPhaseHoverStopSpeedMinTriggerMpsE1 = message.flightPhaseHoverStopSpeedMinTriggerMpsE1;
        if (message.flightPhaseHoverStopWindowS != null && message.hasOwnProperty("flightPhaseHoverStopWindowS"))
            object.flightPhaseHoverStopWindowS = message.flightPhaseHoverStopWindowS;
        if (message.flightPhaseHoverStopRatioPct != null && message.hasOwnProperty("flightPhaseHoverStopRatioPct"))
            object.flightPhaseHoverStopRatioPct = message.flightPhaseHoverStopRatioPct;
        if (message.flightPhaseTakeoffCompositeWindowS != null && message.hasOwnProperty("flightPhaseTakeoffCompositeWindowS"))
            object.flightPhaseTakeoffCompositeWindowS = message.flightPhaseTakeoffCompositeWindowS;
        if (message.flightPhaseTakeoffCompositeRatioPct != null && message.hasOwnProperty("flightPhaseTakeoffCompositeRatioPct"))
            object.flightPhaseTakeoffCompositeRatioPct = message.flightPhaseTakeoffCompositeRatioPct;
        if (message.flightPhaseLandingCompositeWindowS != null && message.hasOwnProperty("flightPhaseLandingCompositeWindowS"))
            object.flightPhaseLandingCompositeWindowS = message.flightPhaseLandingCompositeWindowS;
        if (message.flightPhaseLandingCompositeRatioPct != null && message.hasOwnProperty("flightPhaseLandingCompositeRatioPct"))
            object.flightPhaseLandingCompositeRatioPct = message.flightPhaseLandingCompositeRatioPct;
        if (message.flightPhaseEngineOnAccelerationTriggerGE3 != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationTriggerGE3"))
            object.flightPhaseEngineOnAccelerationTriggerGE3 = message.flightPhaseEngineOnAccelerationTriggerGE3;
        if (message.flightPhaseEngineOnAccelerationWindowSDeprecated != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationWindowSDeprecated"))
            object.flightPhaseEngineOnAccelerationWindowSDeprecated = message.flightPhaseEngineOnAccelerationWindowSDeprecated;
        if (message.flightPhaseEngineOnAccelerationRatioPct != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationRatioPct"))
            object.flightPhaseEngineOnAccelerationRatioPct = message.flightPhaseEngineOnAccelerationRatioPct;
        if (message.flightPhaseEngineOffAccelerationTriggerGE3 != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationTriggerGE3"))
            object.flightPhaseEngineOffAccelerationTriggerGE3 = message.flightPhaseEngineOffAccelerationTriggerGE3;
        if (message.flightPhaseEngineOffAccelerationWindowSDeprecated != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationWindowSDeprecated"))
            object.flightPhaseEngineOffAccelerationWindowSDeprecated = message.flightPhaseEngineOffAccelerationWindowSDeprecated;
        if (message.flightPhaseEngineOffAccelerationRatioPct != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationRatioPct"))
            object.flightPhaseEngineOffAccelerationRatioPct = message.flightPhaseEngineOffAccelerationRatioPct;
        if (message.flightPhaseEngineOnAccelerationWindowS != null && message.hasOwnProperty("flightPhaseEngineOnAccelerationWindowS"))
            object.flightPhaseEngineOnAccelerationWindowS = message.flightPhaseEngineOnAccelerationWindowS;
        if (message.flightPhaseEngineOffAccelerationWindowS != null && message.hasOwnProperty("flightPhaseEngineOffAccelerationWindowS"))
            object.flightPhaseEngineOffAccelerationWindowS = message.flightPhaseEngineOffAccelerationWindowS;
        if (message.flightPhaseQualifyLandingWindowS != null && message.hasOwnProperty("flightPhaseQualifyLandingWindowS"))
            object.flightPhaseQualifyLandingWindowS = message.flightPhaseQualifyLandingWindowS;
        if (message.flightPhaseQualifyLandingRatioPct != null && message.hasOwnProperty("flightPhaseQualifyLandingRatioPct"))
            object.flightPhaseQualifyLandingRatioPct = message.flightPhaseQualifyLandingRatioPct;
        if (message.flightPhaseIndirectEngineOnOffPermitted != null && message.hasOwnProperty("flightPhaseIndirectEngineOnOffPermitted"))
            object.flightPhaseIndirectEngineOnOffPermitted = message.flightPhaseIndirectEngineOnOffPermitted;
        if (message.flightPhaseIndirectTaxiStartStopPermitted != null && message.hasOwnProperty("flightPhaseIndirectTaxiStartStopPermitted"))
            object.flightPhaseIndirectTaxiStartStopPermitted = message.flightPhaseIndirectTaxiStartStopPermitted;
        if (message.flightPhaseIndirectTakeoffLandingPermitted != null && message.hasOwnProperty("flightPhaseIndirectTakeoffLandingPermitted"))
            object.flightPhaseIndirectTakeoffLandingPermitted = message.flightPhaseIndirectTakeoffLandingPermitted;
        if (message.flightPhaseIndirectHoverStartStopPermitted != null && message.hasOwnProperty("flightPhaseIndirectHoverStartStopPermitted"))
            object.flightPhaseIndirectHoverStartStopPermitted = message.flightPhaseIndirectHoverStartStopPermitted;
        if (message.flightPhaseIndirectTakeoffRequireEngineOn != null && message.hasOwnProperty("flightPhaseIndirectTakeoffRequireEngineOn"))
            object.flightPhaseIndirectTakeoffRequireEngineOn = message.flightPhaseIndirectTakeoffRequireEngineOn;
        if (message.manifestTimeoutS != null && message.hasOwnProperty("manifestTimeoutS"))
            object.manifestTimeoutS = message.manifestTimeoutS;
        if (message.manifestTagLimit != null && message.hasOwnProperty("manifestTagLimit"))
            object.manifestTagLimit = message.manifestTagLimit;
        return object;
    };

    /**
     * Converts this ProtobufSettingsServerMainV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsServerMainV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsServerMainV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsServerMainV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsServerMainV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsServerMainV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsServerMainV1";
    };

    /**
     * LogLevel enum.
     * @name ProtobufSettingsServerMainV1.LogLevel
     * @enum {number}
     * @property {number} kCritical=0 kCritical value
     * @property {number} kError=1 kError value
     * @property {number} kWarn=2 kWarn value
     * @property {number} kNotice=3 kNotice value
     * @property {number} kInfo=4 kInfo value
     * @property {number} kLog=5 kLog value
     * @property {number} kDebug=6 kDebug value
     * @property {number} kVerbose=7 kVerbose value
     */
    ProtobufSettingsServerMainV1.LogLevel = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kCritical"] = 0;
        values[valuesById[1] = "kError"] = 1;
        values[valuesById[2] = "kWarn"] = 2;
        values[valuesById[3] = "kNotice"] = 3;
        values[valuesById[4] = "kInfo"] = 4;
        values[valuesById[5] = "kLog"] = 5;
        values[valuesById[6] = "kDebug"] = 6;
        values[valuesById[7] = "kVerbose"] = 7;
        return values;
    })();

    /**
     * WifiLocale enum.
     * @name ProtobufSettingsServerMainV1.WifiLocale
     * @enum {number}
     * @property {number} kUsa=0 kUsa value
     * @property {number} kEurope=1 kEurope value
     * @property {number} kJapan=2 kJapan value
     * @property {number} kChina=3 kChina value
     */
    ProtobufSettingsServerMainV1.WifiLocale = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kUsa"] = 0;
        values[valuesById[1] = "kEurope"] = 1;
        values[valuesById[2] = "kJapan"] = 2;
        values[valuesById[3] = "kChina"] = 3;
        return values;
    })();

    /**
     * Channels enum.
     * @name ProtobufSettingsServerMainV1.Channels
     * @enum {number}
     * @property {number} kSatelliteOrCellular=0 kSatelliteOrCellular value
     * @property {number} kSatellite=1 kSatellite value
     * @property {number} kCellular=2 kCellular value
     * @property {number} kSatelliteAndCellular=3 kSatelliteAndCellular value
     */
    ProtobufSettingsServerMainV1.Channels = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kSatelliteOrCellular"] = 0;
        values[valuesById[1] = "kSatellite"] = 1;
        values[valuesById[2] = "kCellular"] = 2;
        values[valuesById[3] = "kSatelliteAndCellular"] = 3;
        return values;
    })();

    /**
     * VehicleType enum.
     * @name ProtobufSettingsServerMainV1.VehicleType
     * @enum {number}
     * @property {number} kUnknown=0 kUnknown value
     * @property {number} kAircraftFixedWing=1 kAircraftFixedWing value
     * @property {number} kAircraftRotaryWing=2 kAircraftRotaryWing value
     */
    ProtobufSettingsServerMainV1.VehicleType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kUnknown"] = 0;
        values[valuesById[1] = "kAircraftFixedWing"] = 1;
        values[valuesById[2] = "kAircraftRotaryWing"] = 2;
        return values;
    })();

    /**
     * VehicleEngine enum.
     * @name ProtobufSettingsServerMainV1.VehicleEngine
     * @enum {number}
     * @property {number} kSingle=0 kSingle value
     * @property {number} kDual=1 kDual value
     */
    ProtobufSettingsServerMainV1.VehicleEngine = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kSingle"] = 0;
        values[valuesById[1] = "kDual"] = 1;
        return values;
    })();

    /**
     * FlightPhaseModelSelection enum.
     * @name ProtobufSettingsServerMainV1.FlightPhaseModelSelection
     * @enum {number}
     * @property {number} kHybrid=0 kHybrid value
     * @property {number} kDirectOnly=1 kDirectOnly value
     * @property {number} kIndirectOnly=2 kIndirectOnly value
     * @property {number} kExternalOnly=3 kExternalOnly value
     */
    ProtobufSettingsServerMainV1.FlightPhaseModelSelection = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kHybrid"] = 0;
        values[valuesById[1] = "kDirectOnly"] = 1;
        values[valuesById[2] = "kIndirectOnly"] = 2;
        values[valuesById[3] = "kExternalOnly"] = 3;
        return values;
    })();

    /**
     * AhrsModel enum.
     * @name ProtobufSettingsServerMainV1.AhrsModel
     * @enum {number}
     * @property {number} kEdgeImu=0 kEdgeImu value
     * @property {number} kPlatformImu=1 kPlatformImu value
     * @property {number} kPlatformGps=2 kPlatformGps value
     */
    ProtobufSettingsServerMainV1.AhrsModel = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kEdgeImu"] = 0;
        values[valuesById[1] = "kPlatformImu"] = 1;
        values[valuesById[2] = "kPlatformGps"] = 2;
        return values;
    })();

    return ProtobufSettingsServerMainV1;
})();

$root.ProtobufSettingsUserMainV1 = (function() {

    /**
     * Properties of a ProtobufSettingsUserMainV1.
     * @exports IProtobufSettingsUserMainV1
     * @interface IProtobufSettingsUserMainV1
     * @property {boolean|null} [antennaSatelliteExternal] ProtobufSettingsUserMainV1 antennaSatelliteExternal
     * @property {boolean|null} [antennaCellularExternal] ProtobufSettingsUserMainV1 antennaCellularExternal
     * @property {ProtobufSettingsUserMainV1.Brightness|null} [ledBrightnessStandard] ProtobufSettingsUserMainV1 ledBrightnessStandard
     * @property {ProtobufSettingsUserMainV1.Brightness|null} [ledBrightnessOverride] ProtobufSettingsUserMainV1 ledBrightnessOverride
     * @property {number|null} [ledBrightnessMaxPct] ProtobufSettingsUserMainV1 ledBrightnessMaxPct
     * @property {number|null} [ledBrightnessMinPct] ProtobufSettingsUserMainV1 ledBrightnessMinPct
     * @property {boolean|null} [buzzerEnabledWhenWatchActiveOnBackup] ProtobufSettingsUserMainV1 buzzerEnabledWhenWatchActiveOnBackup
     * @property {boolean|null} [buzzerEnabledWhenSerialDeviceMissing] ProtobufSettingsUserMainV1 buzzerEnabledWhenSerialDeviceMissing
     * @property {boolean|null} [serialDeviceRequiredActiveTracking] ProtobufSettingsUserMainV1 serialDeviceRequiredActiveTracking
     * @property {ProtobufSettingsUserMainV1.Mode|null} [serialMode] ProtobufSettingsUserMainV1 serialMode
     * @property {ProtobufSettingsUserMainV1.Protocol|null} [serialProtocol] ProtobufSettingsUserMainV1 serialProtocol
     * @property {boolean|null} [serialRs485Interface] ProtobufSettingsUserMainV1 serialRs485Interface
     * @property {number|null} [serialBaudRate] ProtobufSettingsUserMainV1 serialBaudRate
     * @property {ProtobufSettingsUserMainV1.Parity|null} [serialParity] ProtobufSettingsUserMainV1 serialParity
     * @property {ProtobufSettingsUserMainV1.Device|null} [serialDevice] ProtobufSettingsUserMainV1 serialDevice
     * @property {boolean|null} [serialDeviceRequiredForActiveTracking] ProtobufSettingsUserMainV1 serialDeviceRequiredForActiveTracking
     * @property {ProtobufSettingsServerMainV1.LogLevel|null} [serialDebugProtocolLogLevel] ProtobufSettingsUserMainV1 serialDebugProtocolLogLevel
     * @property {ProtobufSettingsUserMainV1.Delimiter|null} [serialAsciiPacketDelimiter] ProtobufSettingsUserMainV1 serialAsciiPacketDelimiter
     * @property {boolean|null} [serialOpaqueCodecTransmitEnabled] ProtobufSettingsUserMainV1 serialOpaqueCodecTransmitEnabled
     * @property {ProtobufSettingsServerMainV1.Channels|null} [serialOpaqueCodecDataChannels] ProtobufSettingsUserMainV1 serialOpaqueCodecDataChannels
     * @property {ProtobufSettingsUserMainV1.Priority|null} [serialOpaqueCodecDataPriority] ProtobufSettingsUserMainV1 serialOpaqueCodecDataPriority
     * @property {ProtobufSettingsUserMainV1.Urgency|null} [serialOpaqueCodecDataUrgency] ProtobufSettingsUserMainV1 serialOpaqueCodecDataUrgency
     * @property {number|null} [serialCapturePeriodS] ProtobufSettingsUserMainV1 serialCapturePeriodS
     * @property {number|null} [serialCaptureDataTerminator] ProtobufSettingsUserMainV1 serialCaptureDataTerminator
     * @property {boolean|null} [externalInputEngineOilPressure_1Present] ProtobufSettingsUserMainV1 externalInputEngineOilPressure_1Present
     * @property {boolean|null} [externalInputEngineOilPressure_2Present] ProtobufSettingsUserMainV1 externalInputEngineOilPressure_2Present
     * @property {boolean|null} [externalInputMainGearboxOilPressurePresent] ProtobufSettingsUserMainV1 externalInputMainGearboxOilPressurePresent
     * @property {boolean|null} [externalInputCollectiveRaisedPresent] ProtobufSettingsUserMainV1 externalInputCollectiveRaisedPresent
     * @property {boolean|null} [externalInputWeightOnWheelsPresent] ProtobufSettingsUserMainV1 externalInputWeightOnWheelsPresent
     * @property {boolean|null} [flightPhaseExternalIndividualModelsEnabled] ProtobufSettingsUserMainV1 flightPhaseExternalIndividualModelsEnabled
     * @property {boolean|null} [flightPhaseExternalEngineModelPresent] ProtobufSettingsUserMainV1 flightPhaseExternalEngineModelPresent
     * @property {boolean|null} [flightPhaseExternalTaxiModelPresent] ProtobufSettingsUserMainV1 flightPhaseExternalTaxiModelPresent
     * @property {boolean|null} [flightPhaseExternalAirborneModelPresent] ProtobufSettingsUserMainV1 flightPhaseExternalAirborneModelPresent
     * @property {boolean|null} [flightPhaseExternalHoverModelPresent] ProtobufSettingsUserMainV1 flightPhaseExternalHoverModelPresent
     * @property {ProtobufSettingsUserMainV1.InstallOrientation|null} [manualInstallCorrection] ProtobufSettingsUserMainV1 manualInstallCorrection
     * @property {number|null} [manualInstallCorrectionW] ProtobufSettingsUserMainV1 manualInstallCorrectionW
     * @property {number|null} [manualInstallCorrectionX] ProtobufSettingsUserMainV1 manualInstallCorrectionX
     * @property {number|null} [manualInstallCorrectionY] ProtobufSettingsUserMainV1 manualInstallCorrectionY
     * @property {number|null} [manualInstallCorrectionZ] ProtobufSettingsUserMainV1 manualInstallCorrectionZ
     * @property {ProtobufSettingsUserMainV1.Mode|null} [usbMode] ProtobufSettingsUserMainV1 usbMode
     * @property {ProtobufSettingsUserMainV1.Protocol|null} [usbProtocol] ProtobufSettingsUserMainV1 usbProtocol
     * @property {ProtobufSettingsServerMainV1.LogLevel|null} [usbLogLevel] ProtobufSettingsUserMainV1 usbLogLevel
     * @property {boolean|null} [usbExternalBypass] ProtobufSettingsUserMainV1 usbExternalBypass
     * @property {ProtobufSettingsUserMainV1.Mode|null} [gnssMode] ProtobufSettingsUserMainV1 gnssMode
     * @property {ProtobufSettingsUserMainV1.Mode|null} [satelliteMode] ProtobufSettingsUserMainV1 satelliteMode
     * @property {ProtobufSettingsUserMainV1.Mode|null} [cellularMode] ProtobufSettingsUserMainV1 cellularMode
     * @property {ProtobufSettingsUserMainV1.Mode|null} [wirelessMode] ProtobufSettingsUserMainV1 wirelessMode
     * @property {ProtobufSettingsUserMainV1.Endpoint|null} [forwarderEndpointA] ProtobufSettingsUserMainV1 forwarderEndpointA
     * @property {ProtobufSettingsUserMainV1.Endpoint|null} [forwarderEndpointB] ProtobufSettingsUserMainV1 forwarderEndpointB
     * @property {boolean|null} [earlyAccessEnabled] ProtobufSettingsUserMainV1 earlyAccessEnabled
     */

    /**
     * Constructs a new ProtobufSettingsUserMainV1.
     * @exports ProtobufSettingsUserMainV1
     * @classdesc Represents a ProtobufSettingsUserMainV1.
     * @implements IProtobufSettingsUserMainV1
     * @constructor
     * @param {IProtobufSettingsUserMainV1=} [properties] Properties to set
     */
    function ProtobufSettingsUserMainV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSettingsUserMainV1 antennaSatelliteExternal.
     * @member {boolean} antennaSatelliteExternal
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.antennaSatelliteExternal = false;

    /**
     * ProtobufSettingsUserMainV1 antennaCellularExternal.
     * @member {boolean} antennaCellularExternal
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.antennaCellularExternal = false;

    /**
     * ProtobufSettingsUserMainV1 ledBrightnessStandard.
     * @member {ProtobufSettingsUserMainV1.Brightness} ledBrightnessStandard
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.ledBrightnessStandard = 0;

    /**
     * ProtobufSettingsUserMainV1 ledBrightnessOverride.
     * @member {ProtobufSettingsUserMainV1.Brightness} ledBrightnessOverride
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.ledBrightnessOverride = 0;

    /**
     * ProtobufSettingsUserMainV1 ledBrightnessMaxPct.
     * @member {number} ledBrightnessMaxPct
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.ledBrightnessMaxPct = 100;

    /**
     * ProtobufSettingsUserMainV1 ledBrightnessMinPct.
     * @member {number} ledBrightnessMinPct
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.ledBrightnessMinPct = 0;

    /**
     * ProtobufSettingsUserMainV1 buzzerEnabledWhenWatchActiveOnBackup.
     * @member {boolean} buzzerEnabledWhenWatchActiveOnBackup
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.buzzerEnabledWhenWatchActiveOnBackup = true;

    /**
     * ProtobufSettingsUserMainV1 buzzerEnabledWhenSerialDeviceMissing.
     * @member {boolean} buzzerEnabledWhenSerialDeviceMissing
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.buzzerEnabledWhenSerialDeviceMissing = false;

    /**
     * ProtobufSettingsUserMainV1 serialDeviceRequiredActiveTracking.
     * @member {boolean} serialDeviceRequiredActiveTracking
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialDeviceRequiredActiveTracking = false;

    /**
     * ProtobufSettingsUserMainV1 serialMode.
     * @member {ProtobufSettingsUserMainV1.Mode} serialMode
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialMode = 0;

    /**
     * ProtobufSettingsUserMainV1 serialProtocol.
     * @member {ProtobufSettingsUserMainV1.Protocol} serialProtocol
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialProtocol = 2;

    /**
     * ProtobufSettingsUserMainV1 serialRs485Interface.
     * @member {boolean} serialRs485Interface
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialRs485Interface = false;

    /**
     * ProtobufSettingsUserMainV1 serialBaudRate.
     * @member {number} serialBaudRate
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialBaudRate = 9600;

    /**
     * ProtobufSettingsUserMainV1 serialParity.
     * @member {ProtobufSettingsUserMainV1.Parity} serialParity
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialParity = 0;

    /**
     * ProtobufSettingsUserMainV1 serialDevice.
     * @member {ProtobufSettingsUserMainV1.Device} serialDevice
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialDevice = 0;

    /**
     * ProtobufSettingsUserMainV1 serialDeviceRequiredForActiveTracking.
     * @member {boolean} serialDeviceRequiredForActiveTracking
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialDeviceRequiredForActiveTracking = false;

    /**
     * ProtobufSettingsUserMainV1 serialDebugProtocolLogLevel.
     * @member {ProtobufSettingsServerMainV1.LogLevel} serialDebugProtocolLogLevel
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialDebugProtocolLogLevel = 5;

    /**
     * ProtobufSettingsUserMainV1 serialAsciiPacketDelimiter.
     * @member {ProtobufSettingsUserMainV1.Delimiter} serialAsciiPacketDelimiter
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialAsciiPacketDelimiter = 0;

    /**
     * ProtobufSettingsUserMainV1 serialOpaqueCodecTransmitEnabled.
     * @member {boolean} serialOpaqueCodecTransmitEnabled
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialOpaqueCodecTransmitEnabled = false;

    /**
     * ProtobufSettingsUserMainV1 serialOpaqueCodecDataChannels.
     * @member {ProtobufSettingsServerMainV1.Channels} serialOpaqueCodecDataChannels
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialOpaqueCodecDataChannels = 0;

    /**
     * ProtobufSettingsUserMainV1 serialOpaqueCodecDataPriority.
     * @member {ProtobufSettingsUserMainV1.Priority} serialOpaqueCodecDataPriority
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialOpaqueCodecDataPriority = 0;

    /**
     * ProtobufSettingsUserMainV1 serialOpaqueCodecDataUrgency.
     * @member {ProtobufSettingsUserMainV1.Urgency} serialOpaqueCodecDataUrgency
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialOpaqueCodecDataUrgency = 0;

    /**
     * ProtobufSettingsUserMainV1 serialCapturePeriodS.
     * @member {number} serialCapturePeriodS
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialCapturePeriodS = 0;

    /**
     * ProtobufSettingsUserMainV1 serialCaptureDataTerminator.
     * @member {number} serialCaptureDataTerminator
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.serialCaptureDataTerminator = 0;

    /**
     * ProtobufSettingsUserMainV1 externalInputEngineOilPressure_1Present.
     * @member {boolean} externalInputEngineOilPressure_1Present
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.externalInputEngineOilPressure_1Present = false;

    /**
     * ProtobufSettingsUserMainV1 externalInputEngineOilPressure_2Present.
     * @member {boolean} externalInputEngineOilPressure_2Present
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.externalInputEngineOilPressure_2Present = false;

    /**
     * ProtobufSettingsUserMainV1 externalInputMainGearboxOilPressurePresent.
     * @member {boolean} externalInputMainGearboxOilPressurePresent
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.externalInputMainGearboxOilPressurePresent = false;

    /**
     * ProtobufSettingsUserMainV1 externalInputCollectiveRaisedPresent.
     * @member {boolean} externalInputCollectiveRaisedPresent
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.externalInputCollectiveRaisedPresent = false;

    /**
     * ProtobufSettingsUserMainV1 externalInputWeightOnWheelsPresent.
     * @member {boolean} externalInputWeightOnWheelsPresent
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.externalInputWeightOnWheelsPresent = false;

    /**
     * ProtobufSettingsUserMainV1 flightPhaseExternalIndividualModelsEnabled.
     * @member {boolean} flightPhaseExternalIndividualModelsEnabled
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.flightPhaseExternalIndividualModelsEnabled = false;

    /**
     * ProtobufSettingsUserMainV1 flightPhaseExternalEngineModelPresent.
     * @member {boolean} flightPhaseExternalEngineModelPresent
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.flightPhaseExternalEngineModelPresent = false;

    /**
     * ProtobufSettingsUserMainV1 flightPhaseExternalTaxiModelPresent.
     * @member {boolean} flightPhaseExternalTaxiModelPresent
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.flightPhaseExternalTaxiModelPresent = false;

    /**
     * ProtobufSettingsUserMainV1 flightPhaseExternalAirborneModelPresent.
     * @member {boolean} flightPhaseExternalAirborneModelPresent
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.flightPhaseExternalAirborneModelPresent = false;

    /**
     * ProtobufSettingsUserMainV1 flightPhaseExternalHoverModelPresent.
     * @member {boolean} flightPhaseExternalHoverModelPresent
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.flightPhaseExternalHoverModelPresent = false;

    /**
     * ProtobufSettingsUserMainV1 manualInstallCorrection.
     * @member {ProtobufSettingsUserMainV1.InstallOrientation} manualInstallCorrection
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.manualInstallCorrection = 0;

    /**
     * ProtobufSettingsUserMainV1 manualInstallCorrectionW.
     * @member {number} manualInstallCorrectionW
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.manualInstallCorrectionW = 0;

    /**
     * ProtobufSettingsUserMainV1 manualInstallCorrectionX.
     * @member {number} manualInstallCorrectionX
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.manualInstallCorrectionX = 0;

    /**
     * ProtobufSettingsUserMainV1 manualInstallCorrectionY.
     * @member {number} manualInstallCorrectionY
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.manualInstallCorrectionY = 0;

    /**
     * ProtobufSettingsUserMainV1 manualInstallCorrectionZ.
     * @member {number} manualInstallCorrectionZ
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.manualInstallCorrectionZ = 0;

    /**
     * ProtobufSettingsUserMainV1 usbMode.
     * @member {ProtobufSettingsUserMainV1.Mode} usbMode
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.usbMode = 0;

    /**
     * ProtobufSettingsUserMainV1 usbProtocol.
     * @member {ProtobufSettingsUserMainV1.Protocol} usbProtocol
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.usbProtocol = 2;

    /**
     * ProtobufSettingsUserMainV1 usbLogLevel.
     * @member {ProtobufSettingsServerMainV1.LogLevel} usbLogLevel
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.usbLogLevel = 4;

    /**
     * ProtobufSettingsUserMainV1 usbExternalBypass.
     * @member {boolean} usbExternalBypass
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.usbExternalBypass = false;

    /**
     * ProtobufSettingsUserMainV1 gnssMode.
     * @member {ProtobufSettingsUserMainV1.Mode} gnssMode
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.gnssMode = 0;

    /**
     * ProtobufSettingsUserMainV1 satelliteMode.
     * @member {ProtobufSettingsUserMainV1.Mode} satelliteMode
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.satelliteMode = 0;

    /**
     * ProtobufSettingsUserMainV1 cellularMode.
     * @member {ProtobufSettingsUserMainV1.Mode} cellularMode
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.cellularMode = 0;

    /**
     * ProtobufSettingsUserMainV1 wirelessMode.
     * @member {ProtobufSettingsUserMainV1.Mode} wirelessMode
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.wirelessMode = 0;

    /**
     * ProtobufSettingsUserMainV1 forwarderEndpointA.
     * @member {ProtobufSettingsUserMainV1.Endpoint} forwarderEndpointA
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.forwarderEndpointA = 0;

    /**
     * ProtobufSettingsUserMainV1 forwarderEndpointB.
     * @member {ProtobufSettingsUserMainV1.Endpoint} forwarderEndpointB
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.forwarderEndpointB = 0;

    /**
     * ProtobufSettingsUserMainV1 earlyAccessEnabled.
     * @member {boolean} earlyAccessEnabled
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     */
    ProtobufSettingsUserMainV1.prototype.earlyAccessEnabled = false;

    /**
     * Creates a new ProtobufSettingsUserMainV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {IProtobufSettingsUserMainV1=} [properties] Properties to set
     * @returns {ProtobufSettingsUserMainV1} ProtobufSettingsUserMainV1 instance
     */
    ProtobufSettingsUserMainV1.create = function create(properties) {
        return new ProtobufSettingsUserMainV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsUserMainV1 message. Does not implicitly {@link ProtobufSettingsUserMainV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {IProtobufSettingsUserMainV1} message ProtobufSettingsUserMainV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsUserMainV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.antennaSatelliteExternal != null && Object.hasOwnProperty.call(message, "antennaSatelliteExternal"))
            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.antennaSatelliteExternal);
        if (message.antennaCellularExternal != null && Object.hasOwnProperty.call(message, "antennaCellularExternal"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.antennaCellularExternal);
        if (message.ledBrightnessStandard != null && Object.hasOwnProperty.call(message, "ledBrightnessStandard"))
            writer.uint32(/* id 30, wireType 0 =*/240).int32(message.ledBrightnessStandard);
        if (message.ledBrightnessMaxPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMaxPct"))
            writer.uint32(/* id 31, wireType 0 =*/248).uint32(message.ledBrightnessMaxPct);
        if (message.ledBrightnessMinPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMinPct"))
            writer.uint32(/* id 32, wireType 0 =*/256).uint32(message.ledBrightnessMinPct);
        if (message.ledBrightnessOverride != null && Object.hasOwnProperty.call(message, "ledBrightnessOverride"))
            writer.uint32(/* id 34, wireType 0 =*/272).int32(message.ledBrightnessOverride);
        if (message.buzzerEnabledWhenWatchActiveOnBackup != null && Object.hasOwnProperty.call(message, "buzzerEnabledWhenWatchActiveOnBackup"))
            writer.uint32(/* id 35, wireType 0 =*/280).bool(message.buzzerEnabledWhenWatchActiveOnBackup);
        if (message.buzzerEnabledWhenSerialDeviceMissing != null && Object.hasOwnProperty.call(message, "buzzerEnabledWhenSerialDeviceMissing"))
            writer.uint32(/* id 36, wireType 0 =*/288).bool(message.buzzerEnabledWhenSerialDeviceMissing);
        if (message.serialDeviceRequiredActiveTracking != null && Object.hasOwnProperty.call(message, "serialDeviceRequiredActiveTracking"))
            writer.uint32(/* id 37, wireType 0 =*/296).bool(message.serialDeviceRequiredActiveTracking);
        if (message.serialDeviceRequiredForActiveTracking != null && Object.hasOwnProperty.call(message, "serialDeviceRequiredForActiveTracking"))
            writer.uint32(/* id 39, wireType 0 =*/312).bool(message.serialDeviceRequiredForActiveTracking);
        if (message.serialMode != null && Object.hasOwnProperty.call(message, "serialMode"))
            writer.uint32(/* id 40, wireType 0 =*/320).int32(message.serialMode);
        if (message.serialProtocol != null && Object.hasOwnProperty.call(message, "serialProtocol"))
            writer.uint32(/* id 41, wireType 0 =*/328).int32(message.serialProtocol);
        if (message.serialDebugProtocolLogLevel != null && Object.hasOwnProperty.call(message, "serialDebugProtocolLogLevel"))
            writer.uint32(/* id 42, wireType 0 =*/336).int32(message.serialDebugProtocolLogLevel);
        if (message.serialCapturePeriodS != null && Object.hasOwnProperty.call(message, "serialCapturePeriodS"))
            writer.uint32(/* id 43, wireType 0 =*/344).uint32(message.serialCapturePeriodS);
        if (message.serialCaptureDataTerminator != null && Object.hasOwnProperty.call(message, "serialCaptureDataTerminator"))
            writer.uint32(/* id 44, wireType 0 =*/352).uint32(message.serialCaptureDataTerminator);
        if (message.serialDevice != null && Object.hasOwnProperty.call(message, "serialDevice"))
            writer.uint32(/* id 49, wireType 0 =*/392).int32(message.serialDevice);
        if (message.usbMode != null && Object.hasOwnProperty.call(message, "usbMode"))
            writer.uint32(/* id 50, wireType 0 =*/400).int32(message.usbMode);
        if (message.usbProtocol != null && Object.hasOwnProperty.call(message, "usbProtocol"))
            writer.uint32(/* id 51, wireType 0 =*/408).int32(message.usbProtocol);
        if (message.usbLogLevel != null && Object.hasOwnProperty.call(message, "usbLogLevel"))
            writer.uint32(/* id 52, wireType 0 =*/416).int32(message.usbLogLevel);
        if (message.usbExternalBypass != null && Object.hasOwnProperty.call(message, "usbExternalBypass"))
            writer.uint32(/* id 53, wireType 0 =*/424).bool(message.usbExternalBypass);
        if (message.gnssMode != null && Object.hasOwnProperty.call(message, "gnssMode"))
            writer.uint32(/* id 60, wireType 0 =*/480).int32(message.gnssMode);
        if (message.satelliteMode != null && Object.hasOwnProperty.call(message, "satelliteMode"))
            writer.uint32(/* id 70, wireType 0 =*/560).int32(message.satelliteMode);
        if (message.cellularMode != null && Object.hasOwnProperty.call(message, "cellularMode"))
            writer.uint32(/* id 80, wireType 0 =*/640).int32(message.cellularMode);
        if (message.wirelessMode != null && Object.hasOwnProperty.call(message, "wirelessMode"))
            writer.uint32(/* id 90, wireType 0 =*/720).int32(message.wirelessMode);
        if (message.forwarderEndpointA != null && Object.hasOwnProperty.call(message, "forwarderEndpointA"))
            writer.uint32(/* id 100, wireType 0 =*/800).int32(message.forwarderEndpointA);
        if (message.forwarderEndpointB != null && Object.hasOwnProperty.call(message, "forwarderEndpointB"))
            writer.uint32(/* id 101, wireType 0 =*/808).int32(message.forwarderEndpointB);
        if (message.serialRs485Interface != null && Object.hasOwnProperty.call(message, "serialRs485Interface"))
            writer.uint32(/* id 110, wireType 0 =*/880).bool(message.serialRs485Interface);
        if (message.serialBaudRate != null && Object.hasOwnProperty.call(message, "serialBaudRate"))
            writer.uint32(/* id 111, wireType 0 =*/888).uint32(message.serialBaudRate);
        if (message.serialParity != null && Object.hasOwnProperty.call(message, "serialParity"))
            writer.uint32(/* id 112, wireType 0 =*/896).int32(message.serialParity);
        if (message.serialAsciiPacketDelimiter != null && Object.hasOwnProperty.call(message, "serialAsciiPacketDelimiter"))
            writer.uint32(/* id 113, wireType 0 =*/904).int32(message.serialAsciiPacketDelimiter);
        if (message.serialOpaqueCodecTransmitEnabled != null && Object.hasOwnProperty.call(message, "serialOpaqueCodecTransmitEnabled"))
            writer.uint32(/* id 114, wireType 0 =*/912).bool(message.serialOpaqueCodecTransmitEnabled);
        if (message.serialOpaqueCodecDataChannels != null && Object.hasOwnProperty.call(message, "serialOpaqueCodecDataChannels"))
            writer.uint32(/* id 115, wireType 0 =*/920).int32(message.serialOpaqueCodecDataChannels);
        if (message.serialOpaqueCodecDataPriority != null && Object.hasOwnProperty.call(message, "serialOpaqueCodecDataPriority"))
            writer.uint32(/* id 116, wireType 0 =*/928).int32(message.serialOpaqueCodecDataPriority);
        if (message.serialOpaqueCodecDataUrgency != null && Object.hasOwnProperty.call(message, "serialOpaqueCodecDataUrgency"))
            writer.uint32(/* id 117, wireType 0 =*/936).int32(message.serialOpaqueCodecDataUrgency);
        if (message.manualInstallCorrection != null && Object.hasOwnProperty.call(message, "manualInstallCorrection"))
            writer.uint32(/* id 120, wireType 0 =*/960).int32(message.manualInstallCorrection);
        if (message.manualInstallCorrectionW != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionW"))
            writer.uint32(/* id 121, wireType 5 =*/973).float(message.manualInstallCorrectionW);
        if (message.manualInstallCorrectionX != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionX"))
            writer.uint32(/* id 122, wireType 5 =*/981).float(message.manualInstallCorrectionX);
        if (message.manualInstallCorrectionY != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionY"))
            writer.uint32(/* id 123, wireType 5 =*/989).float(message.manualInstallCorrectionY);
        if (message.manualInstallCorrectionZ != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionZ"))
            writer.uint32(/* id 124, wireType 5 =*/997).float(message.manualInstallCorrectionZ);
        if (message.flightPhaseExternalIndividualModelsEnabled != null && Object.hasOwnProperty.call(message, "flightPhaseExternalIndividualModelsEnabled"))
            writer.uint32(/* id 130, wireType 0 =*/1040).bool(message.flightPhaseExternalIndividualModelsEnabled);
        if (message.flightPhaseExternalEngineModelPresent != null && Object.hasOwnProperty.call(message, "flightPhaseExternalEngineModelPresent"))
            writer.uint32(/* id 131, wireType 0 =*/1048).bool(message.flightPhaseExternalEngineModelPresent);
        if (message.flightPhaseExternalTaxiModelPresent != null && Object.hasOwnProperty.call(message, "flightPhaseExternalTaxiModelPresent"))
            writer.uint32(/* id 132, wireType 0 =*/1056).bool(message.flightPhaseExternalTaxiModelPresent);
        if (message.flightPhaseExternalAirborneModelPresent != null && Object.hasOwnProperty.call(message, "flightPhaseExternalAirborneModelPresent"))
            writer.uint32(/* id 133, wireType 0 =*/1064).bool(message.flightPhaseExternalAirborneModelPresent);
        if (message.flightPhaseExternalHoverModelPresent != null && Object.hasOwnProperty.call(message, "flightPhaseExternalHoverModelPresent"))
            writer.uint32(/* id 134, wireType 0 =*/1072).bool(message.flightPhaseExternalHoverModelPresent);
        if (message.externalInputEngineOilPressure_1Present != null && Object.hasOwnProperty.call(message, "externalInputEngineOilPressure_1Present"))
            writer.uint32(/* id 140, wireType 0 =*/1120).bool(message.externalInputEngineOilPressure_1Present);
        if (message.externalInputEngineOilPressure_2Present != null && Object.hasOwnProperty.call(message, "externalInputEngineOilPressure_2Present"))
            writer.uint32(/* id 141, wireType 0 =*/1128).bool(message.externalInputEngineOilPressure_2Present);
        if (message.externalInputMainGearboxOilPressurePresent != null && Object.hasOwnProperty.call(message, "externalInputMainGearboxOilPressurePresent"))
            writer.uint32(/* id 142, wireType 0 =*/1136).bool(message.externalInputMainGearboxOilPressurePresent);
        if (message.externalInputCollectiveRaisedPresent != null && Object.hasOwnProperty.call(message, "externalInputCollectiveRaisedPresent"))
            writer.uint32(/* id 143, wireType 0 =*/1144).bool(message.externalInputCollectiveRaisedPresent);
        if (message.externalInputWeightOnWheelsPresent != null && Object.hasOwnProperty.call(message, "externalInputWeightOnWheelsPresent"))
            writer.uint32(/* id 144, wireType 0 =*/1152).bool(message.externalInputWeightOnWheelsPresent);
        if (message.earlyAccessEnabled != null && Object.hasOwnProperty.call(message, "earlyAccessEnabled"))
            writer.uint32(/* id 255, wireType 0 =*/2040).bool(message.earlyAccessEnabled);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsUserMainV1 message, length delimited. Does not implicitly {@link ProtobufSettingsUserMainV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {IProtobufSettingsUserMainV1} message ProtobufSettingsUserMainV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsUserMainV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsUserMainV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsUserMainV1} ProtobufSettingsUserMainV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsUserMainV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsUserMainV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 20: {
                    message.antennaSatelliteExternal = reader.bool();
                    break;
                }
            case 21: {
                    message.antennaCellularExternal = reader.bool();
                    break;
                }
            case 30: {
                    message.ledBrightnessStandard = reader.int32();
                    break;
                }
            case 34: {
                    message.ledBrightnessOverride = reader.int32();
                    break;
                }
            case 31: {
                    message.ledBrightnessMaxPct = reader.uint32();
                    break;
                }
            case 32: {
                    message.ledBrightnessMinPct = reader.uint32();
                    break;
                }
            case 35: {
                    message.buzzerEnabledWhenWatchActiveOnBackup = reader.bool();
                    break;
                }
            case 36: {
                    message.buzzerEnabledWhenSerialDeviceMissing = reader.bool();
                    break;
                }
            case 37: {
                    message.serialDeviceRequiredActiveTracking = reader.bool();
                    break;
                }
            case 40: {
                    message.serialMode = reader.int32();
                    break;
                }
            case 41: {
                    message.serialProtocol = reader.int32();
                    break;
                }
            case 110: {
                    message.serialRs485Interface = reader.bool();
                    break;
                }
            case 111: {
                    message.serialBaudRate = reader.uint32();
                    break;
                }
            case 112: {
                    message.serialParity = reader.int32();
                    break;
                }
            case 49: {
                    message.serialDevice = reader.int32();
                    break;
                }
            case 39: {
                    message.serialDeviceRequiredForActiveTracking = reader.bool();
                    break;
                }
            case 42: {
                    message.serialDebugProtocolLogLevel = reader.int32();
                    break;
                }
            case 113: {
                    message.serialAsciiPacketDelimiter = reader.int32();
                    break;
                }
            case 114: {
                    message.serialOpaqueCodecTransmitEnabled = reader.bool();
                    break;
                }
            case 115: {
                    message.serialOpaqueCodecDataChannels = reader.int32();
                    break;
                }
            case 116: {
                    message.serialOpaqueCodecDataPriority = reader.int32();
                    break;
                }
            case 117: {
                    message.serialOpaqueCodecDataUrgency = reader.int32();
                    break;
                }
            case 43: {
                    message.serialCapturePeriodS = reader.uint32();
                    break;
                }
            case 44: {
                    message.serialCaptureDataTerminator = reader.uint32();
                    break;
                }
            case 140: {
                    message.externalInputEngineOilPressure_1Present = reader.bool();
                    break;
                }
            case 141: {
                    message.externalInputEngineOilPressure_2Present = reader.bool();
                    break;
                }
            case 142: {
                    message.externalInputMainGearboxOilPressurePresent = reader.bool();
                    break;
                }
            case 143: {
                    message.externalInputCollectiveRaisedPresent = reader.bool();
                    break;
                }
            case 144: {
                    message.externalInputWeightOnWheelsPresent = reader.bool();
                    break;
                }
            case 130: {
                    message.flightPhaseExternalIndividualModelsEnabled = reader.bool();
                    break;
                }
            case 131: {
                    message.flightPhaseExternalEngineModelPresent = reader.bool();
                    break;
                }
            case 132: {
                    message.flightPhaseExternalTaxiModelPresent = reader.bool();
                    break;
                }
            case 133: {
                    message.flightPhaseExternalAirborneModelPresent = reader.bool();
                    break;
                }
            case 134: {
                    message.flightPhaseExternalHoverModelPresent = reader.bool();
                    break;
                }
            case 120: {
                    message.manualInstallCorrection = reader.int32();
                    break;
                }
            case 121: {
                    message.manualInstallCorrectionW = reader.float();
                    break;
                }
            case 122: {
                    message.manualInstallCorrectionX = reader.float();
                    break;
                }
            case 123: {
                    message.manualInstallCorrectionY = reader.float();
                    break;
                }
            case 124: {
                    message.manualInstallCorrectionZ = reader.float();
                    break;
                }
            case 50: {
                    message.usbMode = reader.int32();
                    break;
                }
            case 51: {
                    message.usbProtocol = reader.int32();
                    break;
                }
            case 52: {
                    message.usbLogLevel = reader.int32();
                    break;
                }
            case 53: {
                    message.usbExternalBypass = reader.bool();
                    break;
                }
            case 60: {
                    message.gnssMode = reader.int32();
                    break;
                }
            case 70: {
                    message.satelliteMode = reader.int32();
                    break;
                }
            case 80: {
                    message.cellularMode = reader.int32();
                    break;
                }
            case 90: {
                    message.wirelessMode = reader.int32();
                    break;
                }
            case 100: {
                    message.forwarderEndpointA = reader.int32();
                    break;
                }
            case 101: {
                    message.forwarderEndpointB = reader.int32();
                    break;
                }
            case 255: {
                    message.earlyAccessEnabled = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsUserMainV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsUserMainV1} ProtobufSettingsUserMainV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsUserMainV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsUserMainV1 message.
     * @function verify
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsUserMainV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.antennaSatelliteExternal != null && message.hasOwnProperty("antennaSatelliteExternal"))
            if (typeof message.antennaSatelliteExternal !== "boolean")
                return "antennaSatelliteExternal: boolean expected";
        if (message.antennaCellularExternal != null && message.hasOwnProperty("antennaCellularExternal"))
            if (typeof message.antennaCellularExternal !== "boolean")
                return "antennaCellularExternal: boolean expected";
        if (message.ledBrightnessStandard != null && message.hasOwnProperty("ledBrightnessStandard"))
            switch (message.ledBrightnessStandard) {
            default:
                return "ledBrightnessStandard: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.ledBrightnessOverride != null && message.hasOwnProperty("ledBrightnessOverride"))
            switch (message.ledBrightnessOverride) {
            default:
                return "ledBrightnessOverride: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            if (!$util.isInteger(message.ledBrightnessMaxPct))
                return "ledBrightnessMaxPct: integer expected";
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            if (!$util.isInteger(message.ledBrightnessMinPct))
                return "ledBrightnessMinPct: integer expected";
        if (message.buzzerEnabledWhenWatchActiveOnBackup != null && message.hasOwnProperty("buzzerEnabledWhenWatchActiveOnBackup"))
            if (typeof message.buzzerEnabledWhenWatchActiveOnBackup !== "boolean")
                return "buzzerEnabledWhenWatchActiveOnBackup: boolean expected";
        if (message.buzzerEnabledWhenSerialDeviceMissing != null && message.hasOwnProperty("buzzerEnabledWhenSerialDeviceMissing"))
            if (typeof message.buzzerEnabledWhenSerialDeviceMissing !== "boolean")
                return "buzzerEnabledWhenSerialDeviceMissing: boolean expected";
        if (message.serialDeviceRequiredActiveTracking != null && message.hasOwnProperty("serialDeviceRequiredActiveTracking"))
            if (typeof message.serialDeviceRequiredActiveTracking !== "boolean")
                return "serialDeviceRequiredActiveTracking: boolean expected";
        if (message.serialMode != null && message.hasOwnProperty("serialMode"))
            switch (message.serialMode) {
            default:
                return "serialMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialProtocol != null && message.hasOwnProperty("serialProtocol"))
            switch (message.serialProtocol) {
            default:
                return "serialProtocol: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                break;
            }
        if (message.serialRs485Interface != null && message.hasOwnProperty("serialRs485Interface"))
            if (typeof message.serialRs485Interface !== "boolean")
                return "serialRs485Interface: boolean expected";
        if (message.serialBaudRate != null && message.hasOwnProperty("serialBaudRate"))
            if (!$util.isInteger(message.serialBaudRate))
                return "serialBaudRate: integer expected";
        if (message.serialParity != null && message.hasOwnProperty("serialParity"))
            switch (message.serialParity) {
            default:
                return "serialParity: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialDevice != null && message.hasOwnProperty("serialDevice"))
            switch (message.serialDevice) {
            default:
                return "serialDevice: enum value expected";
            case 0:
            case 1:
            case 10:
            case 11:
            case 20:
            case 30:
            case 40:
            case 50:
            case 60:
            case 100:
                break;
            }
        if (message.serialDeviceRequiredForActiveTracking != null && message.hasOwnProperty("serialDeviceRequiredForActiveTracking"))
            if (typeof message.serialDeviceRequiredForActiveTracking !== "boolean")
                return "serialDeviceRequiredForActiveTracking: boolean expected";
        if (message.serialDebugProtocolLogLevel != null && message.hasOwnProperty("serialDebugProtocolLogLevel"))
            switch (message.serialDebugProtocolLogLevel) {
            default:
                return "serialDebugProtocolLogLevel: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.serialAsciiPacketDelimiter != null && message.hasOwnProperty("serialAsciiPacketDelimiter"))
            switch (message.serialAsciiPacketDelimiter) {
            default:
                return "serialAsciiPacketDelimiter: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialOpaqueCodecTransmitEnabled != null && message.hasOwnProperty("serialOpaqueCodecTransmitEnabled"))
            if (typeof message.serialOpaqueCodecTransmitEnabled !== "boolean")
                return "serialOpaqueCodecTransmitEnabled: boolean expected";
        if (message.serialOpaqueCodecDataChannels != null && message.hasOwnProperty("serialOpaqueCodecDataChannels"))
            switch (message.serialOpaqueCodecDataChannels) {
            default:
                return "serialOpaqueCodecDataChannels: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.serialOpaqueCodecDataPriority != null && message.hasOwnProperty("serialOpaqueCodecDataPriority"))
            switch (message.serialOpaqueCodecDataPriority) {
            default:
                return "serialOpaqueCodecDataPriority: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.serialOpaqueCodecDataUrgency != null && message.hasOwnProperty("serialOpaqueCodecDataUrgency"))
            switch (message.serialOpaqueCodecDataUrgency) {
            default:
                return "serialOpaqueCodecDataUrgency: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialCapturePeriodS != null && message.hasOwnProperty("serialCapturePeriodS"))
            if (!$util.isInteger(message.serialCapturePeriodS))
                return "serialCapturePeriodS: integer expected";
        if (message.serialCaptureDataTerminator != null && message.hasOwnProperty("serialCaptureDataTerminator"))
            if (!$util.isInteger(message.serialCaptureDataTerminator))
                return "serialCaptureDataTerminator: integer expected";
        if (message.externalInputEngineOilPressure_1Present != null && message.hasOwnProperty("externalInputEngineOilPressure_1Present"))
            if (typeof message.externalInputEngineOilPressure_1Present !== "boolean")
                return "externalInputEngineOilPressure_1Present: boolean expected";
        if (message.externalInputEngineOilPressure_2Present != null && message.hasOwnProperty("externalInputEngineOilPressure_2Present"))
            if (typeof message.externalInputEngineOilPressure_2Present !== "boolean")
                return "externalInputEngineOilPressure_2Present: boolean expected";
        if (message.externalInputMainGearboxOilPressurePresent != null && message.hasOwnProperty("externalInputMainGearboxOilPressurePresent"))
            if (typeof message.externalInputMainGearboxOilPressurePresent !== "boolean")
                return "externalInputMainGearboxOilPressurePresent: boolean expected";
        if (message.externalInputCollectiveRaisedPresent != null && message.hasOwnProperty("externalInputCollectiveRaisedPresent"))
            if (typeof message.externalInputCollectiveRaisedPresent !== "boolean")
                return "externalInputCollectiveRaisedPresent: boolean expected";
        if (message.externalInputWeightOnWheelsPresent != null && message.hasOwnProperty("externalInputWeightOnWheelsPresent"))
            if (typeof message.externalInputWeightOnWheelsPresent !== "boolean")
                return "externalInputWeightOnWheelsPresent: boolean expected";
        if (message.flightPhaseExternalIndividualModelsEnabled != null && message.hasOwnProperty("flightPhaseExternalIndividualModelsEnabled"))
            if (typeof message.flightPhaseExternalIndividualModelsEnabled !== "boolean")
                return "flightPhaseExternalIndividualModelsEnabled: boolean expected";
        if (message.flightPhaseExternalEngineModelPresent != null && message.hasOwnProperty("flightPhaseExternalEngineModelPresent"))
            if (typeof message.flightPhaseExternalEngineModelPresent !== "boolean")
                return "flightPhaseExternalEngineModelPresent: boolean expected";
        if (message.flightPhaseExternalTaxiModelPresent != null && message.hasOwnProperty("flightPhaseExternalTaxiModelPresent"))
            if (typeof message.flightPhaseExternalTaxiModelPresent !== "boolean")
                return "flightPhaseExternalTaxiModelPresent: boolean expected";
        if (message.flightPhaseExternalAirborneModelPresent != null && message.hasOwnProperty("flightPhaseExternalAirborneModelPresent"))
            if (typeof message.flightPhaseExternalAirborneModelPresent !== "boolean")
                return "flightPhaseExternalAirborneModelPresent: boolean expected";
        if (message.flightPhaseExternalHoverModelPresent != null && message.hasOwnProperty("flightPhaseExternalHoverModelPresent"))
            if (typeof message.flightPhaseExternalHoverModelPresent !== "boolean")
                return "flightPhaseExternalHoverModelPresent: boolean expected";
        if (message.manualInstallCorrection != null && message.hasOwnProperty("manualInstallCorrection"))
            switch (message.manualInstallCorrection) {
            default:
                return "manualInstallCorrection: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.manualInstallCorrectionW != null && message.hasOwnProperty("manualInstallCorrectionW"))
            if (typeof message.manualInstallCorrectionW !== "number")
                return "manualInstallCorrectionW: number expected";
        if (message.manualInstallCorrectionX != null && message.hasOwnProperty("manualInstallCorrectionX"))
            if (typeof message.manualInstallCorrectionX !== "number")
                return "manualInstallCorrectionX: number expected";
        if (message.manualInstallCorrectionY != null && message.hasOwnProperty("manualInstallCorrectionY"))
            if (typeof message.manualInstallCorrectionY !== "number")
                return "manualInstallCorrectionY: number expected";
        if (message.manualInstallCorrectionZ != null && message.hasOwnProperty("manualInstallCorrectionZ"))
            if (typeof message.manualInstallCorrectionZ !== "number")
                return "manualInstallCorrectionZ: number expected";
        if (message.usbMode != null && message.hasOwnProperty("usbMode"))
            switch (message.usbMode) {
            default:
                return "usbMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.usbProtocol != null && message.hasOwnProperty("usbProtocol"))
            switch (message.usbProtocol) {
            default:
                return "usbProtocol: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                break;
            }
        if (message.usbLogLevel != null && message.hasOwnProperty("usbLogLevel"))
            switch (message.usbLogLevel) {
            default:
                return "usbLogLevel: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.usbExternalBypass != null && message.hasOwnProperty("usbExternalBypass"))
            if (typeof message.usbExternalBypass !== "boolean")
                return "usbExternalBypass: boolean expected";
        if (message.gnssMode != null && message.hasOwnProperty("gnssMode"))
            switch (message.gnssMode) {
            default:
                return "gnssMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.satelliteMode != null && message.hasOwnProperty("satelliteMode"))
            switch (message.satelliteMode) {
            default:
                return "satelliteMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.cellularMode != null && message.hasOwnProperty("cellularMode"))
            switch (message.cellularMode) {
            default:
                return "cellularMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.wirelessMode != null && message.hasOwnProperty("wirelessMode"))
            switch (message.wirelessMode) {
            default:
                return "wirelessMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.forwarderEndpointA != null && message.hasOwnProperty("forwarderEndpointA"))
            switch (message.forwarderEndpointA) {
            default:
                return "forwarderEndpointA: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.forwarderEndpointB != null && message.hasOwnProperty("forwarderEndpointB"))
            switch (message.forwarderEndpointB) {
            default:
                return "forwarderEndpointB: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.earlyAccessEnabled != null && message.hasOwnProperty("earlyAccessEnabled"))
            if (typeof message.earlyAccessEnabled !== "boolean")
                return "earlyAccessEnabled: boolean expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsUserMainV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsUserMainV1} ProtobufSettingsUserMainV1
     */
    ProtobufSettingsUserMainV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsUserMainV1)
            return object;
        var message = new $root.ProtobufSettingsUserMainV1();
        if (object.antennaSatelliteExternal != null)
            message.antennaSatelliteExternal = Boolean(object.antennaSatelliteExternal);
        if (object.antennaCellularExternal != null)
            message.antennaCellularExternal = Boolean(object.antennaCellularExternal);
        switch (object.ledBrightnessStandard) {
        default:
            if (typeof object.ledBrightnessStandard === "number") {
                message.ledBrightnessStandard = object.ledBrightnessStandard;
                break;
            }
            break;
        case "kAuto":
        case 0:
            message.ledBrightnessStandard = 0;
            break;
        case "kFixed":
        case 1:
            message.ledBrightnessStandard = 1;
            break;
        case "kOff":
        case 2:
            message.ledBrightnessStandard = 2;
            break;
        }
        switch (object.ledBrightnessOverride) {
        default:
            if (typeof object.ledBrightnessOverride === "number") {
                message.ledBrightnessOverride = object.ledBrightnessOverride;
                break;
            }
            break;
        case "kAuto":
        case 0:
            message.ledBrightnessOverride = 0;
            break;
        case "kFixed":
        case 1:
            message.ledBrightnessOverride = 1;
            break;
        case "kOff":
        case 2:
            message.ledBrightnessOverride = 2;
            break;
        }
        if (object.ledBrightnessMaxPct != null)
            message.ledBrightnessMaxPct = object.ledBrightnessMaxPct >>> 0;
        if (object.ledBrightnessMinPct != null)
            message.ledBrightnessMinPct = object.ledBrightnessMinPct >>> 0;
        if (object.buzzerEnabledWhenWatchActiveOnBackup != null)
            message.buzzerEnabledWhenWatchActiveOnBackup = Boolean(object.buzzerEnabledWhenWatchActiveOnBackup);
        if (object.buzzerEnabledWhenSerialDeviceMissing != null)
            message.buzzerEnabledWhenSerialDeviceMissing = Boolean(object.buzzerEnabledWhenSerialDeviceMissing);
        if (object.serialDeviceRequiredActiveTracking != null)
            message.serialDeviceRequiredActiveTracking = Boolean(object.serialDeviceRequiredActiveTracking);
        switch (object.serialMode) {
        default:
            if (typeof object.serialMode === "number") {
                message.serialMode = object.serialMode;
                break;
            }
            break;
        case "kEnabled":
        case 0:
            message.serialMode = 0;
            break;
        case "kDisabled":
        case 1:
            message.serialMode = 1;
            break;
        case "kPassthrough":
        case 2:
            message.serialMode = 2;
            break;
        }
        switch (object.serialProtocol) {
        case "kProtocolNone":
        case 0:
            message.serialProtocol = 0;
            break;
        case "kProtocolDebug":
        case 1:
            message.serialProtocol = 1;
            break;
        default:
            if (typeof object.serialProtocol === "number") {
                message.serialProtocol = object.serialProtocol;
                break;
            }
            break;
        case "kProtocolStlProtobuf":
        case 2:
            message.serialProtocol = 2;
            break;
        case "kProtocolAscii":
        case 3:
            message.serialProtocol = 3;
            break;
        case "kProtocolTrotter":
        case 4:
            message.serialProtocol = 4;
            break;
        case "kProtocolKopter":
        case 5:
            message.serialProtocol = 5;
            break;
        case "kProtocolAms":
        case 6:
            message.serialProtocol = 6;
            break;
        case "kProtocolStlThirdParty":
        case 7:
            message.serialProtocol = 7;
            break;
        case "kProtocolAkv":
        case 8:
            message.serialProtocol = 8;
            break;
        }
        if (object.serialRs485Interface != null)
            message.serialRs485Interface = Boolean(object.serialRs485Interface);
        if (object.serialBaudRate != null)
            message.serialBaudRate = object.serialBaudRate >>> 0;
        switch (object.serialParity) {
        default:
            if (typeof object.serialParity === "number") {
                message.serialParity = object.serialParity;
                break;
            }
            break;
        case "kNoParity":
        case 0:
            message.serialParity = 0;
            break;
        case "kEven":
        case 1:
            message.serialParity = 1;
            break;
        case "kOdd":
        case 2:
            message.serialParity = 2;
            break;
        }
        switch (object.serialDevice) {
        default:
            if (typeof object.serialDevice === "number") {
                message.serialDevice = object.serialDevice;
                break;
            }
            break;
        case "kGenericProtobuf":
        case 0:
            message.serialDevice = 0;
            break;
        case "kGenericThirdParty":
        case 1:
            message.serialDevice = 1;
            break;
        case "kSpidertracksKeypad":
        case 10:
            message.serialDevice = 10;
            break;
        case "kSpidertracksGpi":
        case 11:
            message.serialDevice = 11;
            break;
        case "kTrotterDatavault":
        case 20:
            message.serialDevice = 20;
            break;
        case "kKopterGarminIntegratedAvionics":
        case 30:
            message.serialDevice = 30;
            break;
        case "kAmsAfdau":
        case 40:
            message.serialDevice = 40;
            break;
        case "kOlympicAeroETL":
        case 50:
            message.serialDevice = 50;
            break;
        case "kAkvEtm":
        case 60:
            message.serialDevice = 60;
            break;
        case "kVectorNav":
        case 100:
            message.serialDevice = 100;
            break;
        }
        if (object.serialDeviceRequiredForActiveTracking != null)
            message.serialDeviceRequiredForActiveTracking = Boolean(object.serialDeviceRequiredForActiveTracking);
        switch (object.serialDebugProtocolLogLevel) {
        case "kCritical":
        case 0:
            message.serialDebugProtocolLogLevel = 0;
            break;
        case "kError":
        case 1:
            message.serialDebugProtocolLogLevel = 1;
            break;
        case "kWarn":
        case 2:
            message.serialDebugProtocolLogLevel = 2;
            break;
        case "kNotice":
        case 3:
            message.serialDebugProtocolLogLevel = 3;
            break;
        case "kInfo":
        case 4:
            message.serialDebugProtocolLogLevel = 4;
            break;
        default:
            if (typeof object.serialDebugProtocolLogLevel === "number") {
                message.serialDebugProtocolLogLevel = object.serialDebugProtocolLogLevel;
                break;
            }
            break;
        case "kLog":
        case 5:
            message.serialDebugProtocolLogLevel = 5;
            break;
        case "kDebug":
        case 6:
            message.serialDebugProtocolLogLevel = 6;
            break;
        case "kVerbose":
        case 7:
            message.serialDebugProtocolLogLevel = 7;
            break;
        }
        switch (object.serialAsciiPacketDelimiter) {
        default:
            if (typeof object.serialAsciiPacketDelimiter === "number") {
                message.serialAsciiPacketDelimiter = object.serialAsciiPacketDelimiter;
                break;
            }
            break;
        case "kCRLF":
        case 0:
            message.serialAsciiPacketDelimiter = 0;
            break;
        case "kLF":
        case 1:
            message.serialAsciiPacketDelimiter = 1;
            break;
        case "kCR":
        case 2:
            message.serialAsciiPacketDelimiter = 2;
            break;
        }
        if (object.serialOpaqueCodecTransmitEnabled != null)
            message.serialOpaqueCodecTransmitEnabled = Boolean(object.serialOpaqueCodecTransmitEnabled);
        switch (object.serialOpaqueCodecDataChannels) {
        default:
            if (typeof object.serialOpaqueCodecDataChannels === "number") {
                message.serialOpaqueCodecDataChannels = object.serialOpaqueCodecDataChannels;
                break;
            }
            break;
        case "kSatelliteOrCellular":
        case 0:
            message.serialOpaqueCodecDataChannels = 0;
            break;
        case "kSatellite":
        case 1:
            message.serialOpaqueCodecDataChannels = 1;
            break;
        case "kCellular":
        case 2:
            message.serialOpaqueCodecDataChannels = 2;
            break;
        case "kSatelliteAndCellular":
        case 3:
            message.serialOpaqueCodecDataChannels = 3;
            break;
        }
        switch (object.serialOpaqueCodecDataPriority) {
        default:
            if (typeof object.serialOpaqueCodecDataPriority === "number") {
                message.serialOpaqueCodecDataPriority = object.serialOpaqueCodecDataPriority;
                break;
            }
            break;
        case "kPriorityNone":
        case 0:
            message.serialOpaqueCodecDataPriority = 0;
            break;
        case "kPriorityLow":
        case 1:
            message.serialOpaqueCodecDataPriority = 1;
            break;
        case "kPriorityMedium":
        case 2:
            message.serialOpaqueCodecDataPriority = 2;
            break;
        case "kPriorityHigh":
        case 3:
            message.serialOpaqueCodecDataPriority = 3;
            break;
        case "kPrioritySession":
        case 4:
            message.serialOpaqueCodecDataPriority = 4;
            break;
        case "kPriorityCritical":
        case 5:
            message.serialOpaqueCodecDataPriority = 5;
            break;
        }
        switch (object.serialOpaqueCodecDataUrgency) {
        default:
            if (typeof object.serialOpaqueCodecDataUrgency === "number") {
                message.serialOpaqueCodecDataUrgency = object.serialOpaqueCodecDataUrgency;
                break;
            }
            break;
        case "kUrgencyNextOpportunity":
        case 0:
            message.serialOpaqueCodecDataUrgency = 0;
            break;
        case "kUrgencyWithin10s":
        case 1:
            message.serialOpaqueCodecDataUrgency = 1;
            break;
        case "kUrgencyNow":
        case 2:
            message.serialOpaqueCodecDataUrgency = 2;
            break;
        }
        if (object.serialCapturePeriodS != null)
            message.serialCapturePeriodS = object.serialCapturePeriodS >>> 0;
        if (object.serialCaptureDataTerminator != null)
            message.serialCaptureDataTerminator = object.serialCaptureDataTerminator >>> 0;
        if (object.externalInputEngineOilPressure_1Present != null)
            message.externalInputEngineOilPressure_1Present = Boolean(object.externalInputEngineOilPressure_1Present);
        if (object.externalInputEngineOilPressure_2Present != null)
            message.externalInputEngineOilPressure_2Present = Boolean(object.externalInputEngineOilPressure_2Present);
        if (object.externalInputMainGearboxOilPressurePresent != null)
            message.externalInputMainGearboxOilPressurePresent = Boolean(object.externalInputMainGearboxOilPressurePresent);
        if (object.externalInputCollectiveRaisedPresent != null)
            message.externalInputCollectiveRaisedPresent = Boolean(object.externalInputCollectiveRaisedPresent);
        if (object.externalInputWeightOnWheelsPresent != null)
            message.externalInputWeightOnWheelsPresent = Boolean(object.externalInputWeightOnWheelsPresent);
        if (object.flightPhaseExternalIndividualModelsEnabled != null)
            message.flightPhaseExternalIndividualModelsEnabled = Boolean(object.flightPhaseExternalIndividualModelsEnabled);
        if (object.flightPhaseExternalEngineModelPresent != null)
            message.flightPhaseExternalEngineModelPresent = Boolean(object.flightPhaseExternalEngineModelPresent);
        if (object.flightPhaseExternalTaxiModelPresent != null)
            message.flightPhaseExternalTaxiModelPresent = Boolean(object.flightPhaseExternalTaxiModelPresent);
        if (object.flightPhaseExternalAirborneModelPresent != null)
            message.flightPhaseExternalAirborneModelPresent = Boolean(object.flightPhaseExternalAirborneModelPresent);
        if (object.flightPhaseExternalHoverModelPresent != null)
            message.flightPhaseExternalHoverModelPresent = Boolean(object.flightPhaseExternalHoverModelPresent);
        switch (object.manualInstallCorrection) {
        default:
            if (typeof object.manualInstallCorrection === "number") {
                message.manualInstallCorrection = object.manualInstallCorrection;
                break;
            }
            break;
        case "kInstallOrientationNone":
        case 0:
            message.manualInstallCorrection = 0;
            break;
        case "kInstallOrientationManual":
        case 1:
            message.manualInstallCorrection = 1;
            break;
        case "kInstallOrientationAutoLevel":
        case 2:
            message.manualInstallCorrection = 2;
            break;
        case "kInstallOrientationAutoLevelAndYaw":
        case 3:
            message.manualInstallCorrection = 3;
            break;
        }
        if (object.manualInstallCorrectionW != null)
            message.manualInstallCorrectionW = Number(object.manualInstallCorrectionW);
        if (object.manualInstallCorrectionX != null)
            message.manualInstallCorrectionX = Number(object.manualInstallCorrectionX);
        if (object.manualInstallCorrectionY != null)
            message.manualInstallCorrectionY = Number(object.manualInstallCorrectionY);
        if (object.manualInstallCorrectionZ != null)
            message.manualInstallCorrectionZ = Number(object.manualInstallCorrectionZ);
        switch (object.usbMode) {
        default:
            if (typeof object.usbMode === "number") {
                message.usbMode = object.usbMode;
                break;
            }
            break;
        case "kEnabled":
        case 0:
            message.usbMode = 0;
            break;
        case "kDisabled":
        case 1:
            message.usbMode = 1;
            break;
        case "kPassthrough":
        case 2:
            message.usbMode = 2;
            break;
        }
        switch (object.usbProtocol) {
        case "kProtocolNone":
        case 0:
            message.usbProtocol = 0;
            break;
        case "kProtocolDebug":
        case 1:
            message.usbProtocol = 1;
            break;
        default:
            if (typeof object.usbProtocol === "number") {
                message.usbProtocol = object.usbProtocol;
                break;
            }
            break;
        case "kProtocolStlProtobuf":
        case 2:
            message.usbProtocol = 2;
            break;
        case "kProtocolAscii":
        case 3:
            message.usbProtocol = 3;
            break;
        case "kProtocolTrotter":
        case 4:
            message.usbProtocol = 4;
            break;
        case "kProtocolKopter":
        case 5:
            message.usbProtocol = 5;
            break;
        case "kProtocolAms":
        case 6:
            message.usbProtocol = 6;
            break;
        case "kProtocolStlThirdParty":
        case 7:
            message.usbProtocol = 7;
            break;
        case "kProtocolAkv":
        case 8:
            message.usbProtocol = 8;
            break;
        }
        switch (object.usbLogLevel) {
        case "kCritical":
        case 0:
            message.usbLogLevel = 0;
            break;
        case "kError":
        case 1:
            message.usbLogLevel = 1;
            break;
        case "kWarn":
        case 2:
            message.usbLogLevel = 2;
            break;
        case "kNotice":
        case 3:
            message.usbLogLevel = 3;
            break;
        default:
            if (typeof object.usbLogLevel === "number") {
                message.usbLogLevel = object.usbLogLevel;
                break;
            }
            break;
        case "kInfo":
        case 4:
            message.usbLogLevel = 4;
            break;
        case "kLog":
        case 5:
            message.usbLogLevel = 5;
            break;
        case "kDebug":
        case 6:
            message.usbLogLevel = 6;
            break;
        case "kVerbose":
        case 7:
            message.usbLogLevel = 7;
            break;
        }
        if (object.usbExternalBypass != null)
            message.usbExternalBypass = Boolean(object.usbExternalBypass);
        switch (object.gnssMode) {
        default:
            if (typeof object.gnssMode === "number") {
                message.gnssMode = object.gnssMode;
                break;
            }
            break;
        case "kEnabled":
        case 0:
            message.gnssMode = 0;
            break;
        case "kDisabled":
        case 1:
            message.gnssMode = 1;
            break;
        case "kPassthrough":
        case 2:
            message.gnssMode = 2;
            break;
        }
        switch (object.satelliteMode) {
        default:
            if (typeof object.satelliteMode === "number") {
                message.satelliteMode = object.satelliteMode;
                break;
            }
            break;
        case "kEnabled":
        case 0:
            message.satelliteMode = 0;
            break;
        case "kDisabled":
        case 1:
            message.satelliteMode = 1;
            break;
        case "kPassthrough":
        case 2:
            message.satelliteMode = 2;
            break;
        }
        switch (object.cellularMode) {
        default:
            if (typeof object.cellularMode === "number") {
                message.cellularMode = object.cellularMode;
                break;
            }
            break;
        case "kEnabled":
        case 0:
            message.cellularMode = 0;
            break;
        case "kDisabled":
        case 1:
            message.cellularMode = 1;
            break;
        case "kPassthrough":
        case 2:
            message.cellularMode = 2;
            break;
        }
        switch (object.wirelessMode) {
        default:
            if (typeof object.wirelessMode === "number") {
                message.wirelessMode = object.wirelessMode;
                break;
            }
            break;
        case "kEnabled":
        case 0:
            message.wirelessMode = 0;
            break;
        case "kDisabled":
        case 1:
            message.wirelessMode = 1;
            break;
        case "kPassthrough":
        case 2:
            message.wirelessMode = 2;
            break;
        }
        switch (object.forwarderEndpointA) {
        default:
            if (typeof object.forwarderEndpointA === "number") {
                message.forwarderEndpointA = object.forwarderEndpointA;
                break;
            }
            break;
        case "kEndpointNone":
        case 0:
            message.forwarderEndpointA = 0;
            break;
        case "kEndpointUsb":
        case 1:
            message.forwarderEndpointA = 1;
            break;
        case "kEndpointSerial":
        case 2:
            message.forwarderEndpointA = 2;
            break;
        case "kEndpointGnss":
        case 3:
            message.forwarderEndpointA = 3;
            break;
        case "kEndpointSatellite":
        case 4:
            message.forwarderEndpointA = 4;
            break;
        case "kEndpointWireless":
        case 5:
            message.forwarderEndpointA = 5;
            break;
        case "kEndpointCellular":
        case 6:
            message.forwarderEndpointA = 6;
            break;
        }
        switch (object.forwarderEndpointB) {
        default:
            if (typeof object.forwarderEndpointB === "number") {
                message.forwarderEndpointB = object.forwarderEndpointB;
                break;
            }
            break;
        case "kEndpointNone":
        case 0:
            message.forwarderEndpointB = 0;
            break;
        case "kEndpointUsb":
        case 1:
            message.forwarderEndpointB = 1;
            break;
        case "kEndpointSerial":
        case 2:
            message.forwarderEndpointB = 2;
            break;
        case "kEndpointGnss":
        case 3:
            message.forwarderEndpointB = 3;
            break;
        case "kEndpointSatellite":
        case 4:
            message.forwarderEndpointB = 4;
            break;
        case "kEndpointWireless":
        case 5:
            message.forwarderEndpointB = 5;
            break;
        case "kEndpointCellular":
        case 6:
            message.forwarderEndpointB = 6;
            break;
        }
        if (object.earlyAccessEnabled != null)
            message.earlyAccessEnabled = Boolean(object.earlyAccessEnabled);
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSettingsUserMainV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {ProtobufSettingsUserMainV1} message ProtobufSettingsUserMainV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsUserMainV1.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.antennaSatelliteExternal = false;
            object.antennaCellularExternal = false;
            object.ledBrightnessStandard = options.enums === String ? "kAuto" : 0;
            object.ledBrightnessMaxPct = 100;
            object.ledBrightnessMinPct = 0;
            object.ledBrightnessOverride = options.enums === String ? "kAuto" : 0;
            object.buzzerEnabledWhenWatchActiveOnBackup = true;
            object.buzzerEnabledWhenSerialDeviceMissing = false;
            object.serialDeviceRequiredActiveTracking = false;
            object.serialDeviceRequiredForActiveTracking = false;
            object.serialMode = options.enums === String ? "kEnabled" : 0;
            object.serialProtocol = options.enums === String ? "kProtocolStlProtobuf" : 2;
            object.serialDebugProtocolLogLevel = options.enums === String ? "kLog" : 5;
            object.serialCapturePeriodS = 0;
            object.serialCaptureDataTerminator = 0;
            object.serialDevice = options.enums === String ? "kGenericProtobuf" : 0;
            object.usbMode = options.enums === String ? "kEnabled" : 0;
            object.usbProtocol = options.enums === String ? "kProtocolStlProtobuf" : 2;
            object.usbLogLevel = options.enums === String ? "kInfo" : 4;
            object.usbExternalBypass = false;
            object.gnssMode = options.enums === String ? "kEnabled" : 0;
            object.satelliteMode = options.enums === String ? "kEnabled" : 0;
            object.cellularMode = options.enums === String ? "kEnabled" : 0;
            object.wirelessMode = options.enums === String ? "kEnabled" : 0;
            object.forwarderEndpointA = options.enums === String ? "kEndpointNone" : 0;
            object.forwarderEndpointB = options.enums === String ? "kEndpointNone" : 0;
            object.serialRs485Interface = false;
            object.serialBaudRate = 9600;
            object.serialParity = options.enums === String ? "kNoParity" : 0;
            object.serialAsciiPacketDelimiter = options.enums === String ? "kCRLF" : 0;
            object.serialOpaqueCodecTransmitEnabled = false;
            object.serialOpaqueCodecDataChannels = options.enums === String ? "kSatelliteOrCellular" : 0;
            object.serialOpaqueCodecDataPriority = options.enums === String ? "kPriorityNone" : 0;
            object.serialOpaqueCodecDataUrgency = options.enums === String ? "kUrgencyNextOpportunity" : 0;
            object.manualInstallCorrection = options.enums === String ? "kInstallOrientationNone" : 0;
            object.manualInstallCorrectionW = 0;
            object.manualInstallCorrectionX = 0;
            object.manualInstallCorrectionY = 0;
            object.manualInstallCorrectionZ = 0;
            object.flightPhaseExternalIndividualModelsEnabled = false;
            object.flightPhaseExternalEngineModelPresent = false;
            object.flightPhaseExternalTaxiModelPresent = false;
            object.flightPhaseExternalAirborneModelPresent = false;
            object.flightPhaseExternalHoverModelPresent = false;
            object.externalInputEngineOilPressure_1Present = false;
            object.externalInputEngineOilPressure_2Present = false;
            object.externalInputMainGearboxOilPressurePresent = false;
            object.externalInputCollectiveRaisedPresent = false;
            object.externalInputWeightOnWheelsPresent = false;
            object.earlyAccessEnabled = false;
        }
        if (message.antennaSatelliteExternal != null && message.hasOwnProperty("antennaSatelliteExternal"))
            object.antennaSatelliteExternal = message.antennaSatelliteExternal;
        if (message.antennaCellularExternal != null && message.hasOwnProperty("antennaCellularExternal"))
            object.antennaCellularExternal = message.antennaCellularExternal;
        if (message.ledBrightnessStandard != null && message.hasOwnProperty("ledBrightnessStandard"))
            object.ledBrightnessStandard = options.enums === String ? $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessStandard] === undefined ? message.ledBrightnessStandard : $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessStandard] : message.ledBrightnessStandard;
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            object.ledBrightnessMaxPct = message.ledBrightnessMaxPct;
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            object.ledBrightnessMinPct = message.ledBrightnessMinPct;
        if (message.ledBrightnessOverride != null && message.hasOwnProperty("ledBrightnessOverride"))
            object.ledBrightnessOverride = options.enums === String ? $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessOverride] === undefined ? message.ledBrightnessOverride : $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessOverride] : message.ledBrightnessOverride;
        if (message.buzzerEnabledWhenWatchActiveOnBackup != null && message.hasOwnProperty("buzzerEnabledWhenWatchActiveOnBackup"))
            object.buzzerEnabledWhenWatchActiveOnBackup = message.buzzerEnabledWhenWatchActiveOnBackup;
        if (message.buzzerEnabledWhenSerialDeviceMissing != null && message.hasOwnProperty("buzzerEnabledWhenSerialDeviceMissing"))
            object.buzzerEnabledWhenSerialDeviceMissing = message.buzzerEnabledWhenSerialDeviceMissing;
        if (message.serialDeviceRequiredActiveTracking != null && message.hasOwnProperty("serialDeviceRequiredActiveTracking"))
            object.serialDeviceRequiredActiveTracking = message.serialDeviceRequiredActiveTracking;
        if (message.serialDeviceRequiredForActiveTracking != null && message.hasOwnProperty("serialDeviceRequiredForActiveTracking"))
            object.serialDeviceRequiredForActiveTracking = message.serialDeviceRequiredForActiveTracking;
        if (message.serialMode != null && message.hasOwnProperty("serialMode"))
            object.serialMode = options.enums === String ? $root.ProtobufSettingsUserMainV1.Mode[message.serialMode] === undefined ? message.serialMode : $root.ProtobufSettingsUserMainV1.Mode[message.serialMode] : message.serialMode;
        if (message.serialProtocol != null && message.hasOwnProperty("serialProtocol"))
            object.serialProtocol = options.enums === String ? $root.ProtobufSettingsUserMainV1.Protocol[message.serialProtocol] === undefined ? message.serialProtocol : $root.ProtobufSettingsUserMainV1.Protocol[message.serialProtocol] : message.serialProtocol;
        if (message.serialDebugProtocolLogLevel != null && message.hasOwnProperty("serialDebugProtocolLogLevel"))
            object.serialDebugProtocolLogLevel = options.enums === String ? $root.ProtobufSettingsServerMainV1.LogLevel[message.serialDebugProtocolLogLevel] === undefined ? message.serialDebugProtocolLogLevel : $root.ProtobufSettingsServerMainV1.LogLevel[message.serialDebugProtocolLogLevel] : message.serialDebugProtocolLogLevel;
        if (message.serialCapturePeriodS != null && message.hasOwnProperty("serialCapturePeriodS"))
            object.serialCapturePeriodS = message.serialCapturePeriodS;
        if (message.serialCaptureDataTerminator != null && message.hasOwnProperty("serialCaptureDataTerminator"))
            object.serialCaptureDataTerminator = message.serialCaptureDataTerminator;
        if (message.serialDevice != null && message.hasOwnProperty("serialDevice"))
            object.serialDevice = options.enums === String ? $root.ProtobufSettingsUserMainV1.Device[message.serialDevice] === undefined ? message.serialDevice : $root.ProtobufSettingsUserMainV1.Device[message.serialDevice] : message.serialDevice;
        if (message.usbMode != null && message.hasOwnProperty("usbMode"))
            object.usbMode = options.enums === String ? $root.ProtobufSettingsUserMainV1.Mode[message.usbMode] === undefined ? message.usbMode : $root.ProtobufSettingsUserMainV1.Mode[message.usbMode] : message.usbMode;
        if (message.usbProtocol != null && message.hasOwnProperty("usbProtocol"))
            object.usbProtocol = options.enums === String ? $root.ProtobufSettingsUserMainV1.Protocol[message.usbProtocol] === undefined ? message.usbProtocol : $root.ProtobufSettingsUserMainV1.Protocol[message.usbProtocol] : message.usbProtocol;
        if (message.usbLogLevel != null && message.hasOwnProperty("usbLogLevel"))
            object.usbLogLevel = options.enums === String ? $root.ProtobufSettingsServerMainV1.LogLevel[message.usbLogLevel] === undefined ? message.usbLogLevel : $root.ProtobufSettingsServerMainV1.LogLevel[message.usbLogLevel] : message.usbLogLevel;
        if (message.usbExternalBypass != null && message.hasOwnProperty("usbExternalBypass"))
            object.usbExternalBypass = message.usbExternalBypass;
        if (message.gnssMode != null && message.hasOwnProperty("gnssMode"))
            object.gnssMode = options.enums === String ? $root.ProtobufSettingsUserMainV1.Mode[message.gnssMode] === undefined ? message.gnssMode : $root.ProtobufSettingsUserMainV1.Mode[message.gnssMode] : message.gnssMode;
        if (message.satelliteMode != null && message.hasOwnProperty("satelliteMode"))
            object.satelliteMode = options.enums === String ? $root.ProtobufSettingsUserMainV1.Mode[message.satelliteMode] === undefined ? message.satelliteMode : $root.ProtobufSettingsUserMainV1.Mode[message.satelliteMode] : message.satelliteMode;
        if (message.cellularMode != null && message.hasOwnProperty("cellularMode"))
            object.cellularMode = options.enums === String ? $root.ProtobufSettingsUserMainV1.Mode[message.cellularMode] === undefined ? message.cellularMode : $root.ProtobufSettingsUserMainV1.Mode[message.cellularMode] : message.cellularMode;
        if (message.wirelessMode != null && message.hasOwnProperty("wirelessMode"))
            object.wirelessMode = options.enums === String ? $root.ProtobufSettingsUserMainV1.Mode[message.wirelessMode] === undefined ? message.wirelessMode : $root.ProtobufSettingsUserMainV1.Mode[message.wirelessMode] : message.wirelessMode;
        if (message.forwarderEndpointA != null && message.hasOwnProperty("forwarderEndpointA"))
            object.forwarderEndpointA = options.enums === String ? $root.ProtobufSettingsUserMainV1.Endpoint[message.forwarderEndpointA] === undefined ? message.forwarderEndpointA : $root.ProtobufSettingsUserMainV1.Endpoint[message.forwarderEndpointA] : message.forwarderEndpointA;
        if (message.forwarderEndpointB != null && message.hasOwnProperty("forwarderEndpointB"))
            object.forwarderEndpointB = options.enums === String ? $root.ProtobufSettingsUserMainV1.Endpoint[message.forwarderEndpointB] === undefined ? message.forwarderEndpointB : $root.ProtobufSettingsUserMainV1.Endpoint[message.forwarderEndpointB] : message.forwarderEndpointB;
        if (message.serialRs485Interface != null && message.hasOwnProperty("serialRs485Interface"))
            object.serialRs485Interface = message.serialRs485Interface;
        if (message.serialBaudRate != null && message.hasOwnProperty("serialBaudRate"))
            object.serialBaudRate = message.serialBaudRate;
        if (message.serialParity != null && message.hasOwnProperty("serialParity"))
            object.serialParity = options.enums === String ? $root.ProtobufSettingsUserMainV1.Parity[message.serialParity] === undefined ? message.serialParity : $root.ProtobufSettingsUserMainV1.Parity[message.serialParity] : message.serialParity;
        if (message.serialAsciiPacketDelimiter != null && message.hasOwnProperty("serialAsciiPacketDelimiter"))
            object.serialAsciiPacketDelimiter = options.enums === String ? $root.ProtobufSettingsUserMainV1.Delimiter[message.serialAsciiPacketDelimiter] === undefined ? message.serialAsciiPacketDelimiter : $root.ProtobufSettingsUserMainV1.Delimiter[message.serialAsciiPacketDelimiter] : message.serialAsciiPacketDelimiter;
        if (message.serialOpaqueCodecTransmitEnabled != null && message.hasOwnProperty("serialOpaqueCodecTransmitEnabled"))
            object.serialOpaqueCodecTransmitEnabled = message.serialOpaqueCodecTransmitEnabled;
        if (message.serialOpaqueCodecDataChannels != null && message.hasOwnProperty("serialOpaqueCodecDataChannels"))
            object.serialOpaqueCodecDataChannels = options.enums === String ? $root.ProtobufSettingsServerMainV1.Channels[message.serialOpaqueCodecDataChannels] === undefined ? message.serialOpaqueCodecDataChannels : $root.ProtobufSettingsServerMainV1.Channels[message.serialOpaqueCodecDataChannels] : message.serialOpaqueCodecDataChannels;
        if (message.serialOpaqueCodecDataPriority != null && message.hasOwnProperty("serialOpaqueCodecDataPriority"))
            object.serialOpaqueCodecDataPriority = options.enums === String ? $root.ProtobufSettingsUserMainV1.Priority[message.serialOpaqueCodecDataPriority] === undefined ? message.serialOpaqueCodecDataPriority : $root.ProtobufSettingsUserMainV1.Priority[message.serialOpaqueCodecDataPriority] : message.serialOpaqueCodecDataPriority;
        if (message.serialOpaqueCodecDataUrgency != null && message.hasOwnProperty("serialOpaqueCodecDataUrgency"))
            object.serialOpaqueCodecDataUrgency = options.enums === String ? $root.ProtobufSettingsUserMainV1.Urgency[message.serialOpaqueCodecDataUrgency] === undefined ? message.serialOpaqueCodecDataUrgency : $root.ProtobufSettingsUserMainV1.Urgency[message.serialOpaqueCodecDataUrgency] : message.serialOpaqueCodecDataUrgency;
        if (message.manualInstallCorrection != null && message.hasOwnProperty("manualInstallCorrection"))
            object.manualInstallCorrection = options.enums === String ? $root.ProtobufSettingsUserMainV1.InstallOrientation[message.manualInstallCorrection] === undefined ? message.manualInstallCorrection : $root.ProtobufSettingsUserMainV1.InstallOrientation[message.manualInstallCorrection] : message.manualInstallCorrection;
        if (message.manualInstallCorrectionW != null && message.hasOwnProperty("manualInstallCorrectionW"))
            object.manualInstallCorrectionW = options.json && !isFinite(message.manualInstallCorrectionW) ? String(message.manualInstallCorrectionW) : message.manualInstallCorrectionW;
        if (message.manualInstallCorrectionX != null && message.hasOwnProperty("manualInstallCorrectionX"))
            object.manualInstallCorrectionX = options.json && !isFinite(message.manualInstallCorrectionX) ? String(message.manualInstallCorrectionX) : message.manualInstallCorrectionX;
        if (message.manualInstallCorrectionY != null && message.hasOwnProperty("manualInstallCorrectionY"))
            object.manualInstallCorrectionY = options.json && !isFinite(message.manualInstallCorrectionY) ? String(message.manualInstallCorrectionY) : message.manualInstallCorrectionY;
        if (message.manualInstallCorrectionZ != null && message.hasOwnProperty("manualInstallCorrectionZ"))
            object.manualInstallCorrectionZ = options.json && !isFinite(message.manualInstallCorrectionZ) ? String(message.manualInstallCorrectionZ) : message.manualInstallCorrectionZ;
        if (message.flightPhaseExternalIndividualModelsEnabled != null && message.hasOwnProperty("flightPhaseExternalIndividualModelsEnabled"))
            object.flightPhaseExternalIndividualModelsEnabled = message.flightPhaseExternalIndividualModelsEnabled;
        if (message.flightPhaseExternalEngineModelPresent != null && message.hasOwnProperty("flightPhaseExternalEngineModelPresent"))
            object.flightPhaseExternalEngineModelPresent = message.flightPhaseExternalEngineModelPresent;
        if (message.flightPhaseExternalTaxiModelPresent != null && message.hasOwnProperty("flightPhaseExternalTaxiModelPresent"))
            object.flightPhaseExternalTaxiModelPresent = message.flightPhaseExternalTaxiModelPresent;
        if (message.flightPhaseExternalAirborneModelPresent != null && message.hasOwnProperty("flightPhaseExternalAirborneModelPresent"))
            object.flightPhaseExternalAirborneModelPresent = message.flightPhaseExternalAirborneModelPresent;
        if (message.flightPhaseExternalHoverModelPresent != null && message.hasOwnProperty("flightPhaseExternalHoverModelPresent"))
            object.flightPhaseExternalHoverModelPresent = message.flightPhaseExternalHoverModelPresent;
        if (message.externalInputEngineOilPressure_1Present != null && message.hasOwnProperty("externalInputEngineOilPressure_1Present"))
            object.externalInputEngineOilPressure_1Present = message.externalInputEngineOilPressure_1Present;
        if (message.externalInputEngineOilPressure_2Present != null && message.hasOwnProperty("externalInputEngineOilPressure_2Present"))
            object.externalInputEngineOilPressure_2Present = message.externalInputEngineOilPressure_2Present;
        if (message.externalInputMainGearboxOilPressurePresent != null && message.hasOwnProperty("externalInputMainGearboxOilPressurePresent"))
            object.externalInputMainGearboxOilPressurePresent = message.externalInputMainGearboxOilPressurePresent;
        if (message.externalInputCollectiveRaisedPresent != null && message.hasOwnProperty("externalInputCollectiveRaisedPresent"))
            object.externalInputCollectiveRaisedPresent = message.externalInputCollectiveRaisedPresent;
        if (message.externalInputWeightOnWheelsPresent != null && message.hasOwnProperty("externalInputWeightOnWheelsPresent"))
            object.externalInputWeightOnWheelsPresent = message.externalInputWeightOnWheelsPresent;
        if (message.earlyAccessEnabled != null && message.hasOwnProperty("earlyAccessEnabled"))
            object.earlyAccessEnabled = message.earlyAccessEnabled;
        return object;
    };

    /**
     * Converts this ProtobufSettingsUserMainV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsUserMainV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsUserMainV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsUserMainV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsUserMainV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsUserMainV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsUserMainV1";
    };

    /**
     * Brightness enum.
     * @name ProtobufSettingsUserMainV1.Brightness
     * @enum {number}
     * @property {number} kAuto=0 kAuto value
     * @property {number} kFixed=1 kFixed value
     * @property {number} kOff=2 kOff value
     */
    ProtobufSettingsUserMainV1.Brightness = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kAuto"] = 0;
        values[valuesById[1] = "kFixed"] = 1;
        values[valuesById[2] = "kOff"] = 2;
        return values;
    })();

    /**
     * Mode enum.
     * @name ProtobufSettingsUserMainV1.Mode
     * @enum {number}
     * @property {number} kEnabled=0 kEnabled value
     * @property {number} kDisabled=1 kDisabled value
     * @property {number} kPassthrough=2 kPassthrough value
     */
    ProtobufSettingsUserMainV1.Mode = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kEnabled"] = 0;
        values[valuesById[1] = "kDisabled"] = 1;
        values[valuesById[2] = "kPassthrough"] = 2;
        return values;
    })();

    /**
     * Protocol enum.
     * @name ProtobufSettingsUserMainV1.Protocol
     * @enum {number}
     * @property {number} kProtocolNone=0 kProtocolNone value
     * @property {number} kProtocolDebug=1 kProtocolDebug value
     * @property {number} kProtocolStlProtobuf=2 kProtocolStlProtobuf value
     * @property {number} kProtocolAscii=3 kProtocolAscii value
     * @property {number} kProtocolTrotter=4 kProtocolTrotter value
     * @property {number} kProtocolKopter=5 kProtocolKopter value
     * @property {number} kProtocolAms=6 kProtocolAms value
     * @property {number} kProtocolStlThirdParty=7 kProtocolStlThirdParty value
     * @property {number} kProtocolAkv=8 kProtocolAkv value
     */
    ProtobufSettingsUserMainV1.Protocol = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kProtocolNone"] = 0;
        values[valuesById[1] = "kProtocolDebug"] = 1;
        values[valuesById[2] = "kProtocolStlProtobuf"] = 2;
        values[valuesById[3] = "kProtocolAscii"] = 3;
        values[valuesById[4] = "kProtocolTrotter"] = 4;
        values[valuesById[5] = "kProtocolKopter"] = 5;
        values[valuesById[6] = "kProtocolAms"] = 6;
        values[valuesById[7] = "kProtocolStlThirdParty"] = 7;
        values[valuesById[8] = "kProtocolAkv"] = 8;
        return values;
    })();

    /**
     * Delimiter enum.
     * @name ProtobufSettingsUserMainV1.Delimiter
     * @enum {number}
     * @property {number} kCRLF=0 kCRLF value
     * @property {number} kLF=1 kLF value
     * @property {number} kCR=2 kCR value
     */
    ProtobufSettingsUserMainV1.Delimiter = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kCRLF"] = 0;
        values[valuesById[1] = "kLF"] = 1;
        values[valuesById[2] = "kCR"] = 2;
        return values;
    })();

    /**
     * Device enum.
     * @name ProtobufSettingsUserMainV1.Device
     * @enum {number}
     * @property {number} kGenericProtobuf=0 kGenericProtobuf value
     * @property {number} kGenericThirdParty=1 kGenericThirdParty value
     * @property {number} kSpidertracksKeypad=10 kSpidertracksKeypad value
     * @property {number} kSpidertracksGpi=11 kSpidertracksGpi value
     * @property {number} kTrotterDatavault=20 kTrotterDatavault value
     * @property {number} kKopterGarminIntegratedAvionics=30 kKopterGarminIntegratedAvionics value
     * @property {number} kAmsAfdau=40 kAmsAfdau value
     * @property {number} kOlympicAeroETL=50 kOlympicAeroETL value
     * @property {number} kAkvEtm=60 kAkvEtm value
     * @property {number} kVectorNav=100 kVectorNav value
     */
    ProtobufSettingsUserMainV1.Device = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kGenericProtobuf"] = 0;
        values[valuesById[1] = "kGenericThirdParty"] = 1;
        values[valuesById[10] = "kSpidertracksKeypad"] = 10;
        values[valuesById[11] = "kSpidertracksGpi"] = 11;
        values[valuesById[20] = "kTrotterDatavault"] = 20;
        values[valuesById[30] = "kKopterGarminIntegratedAvionics"] = 30;
        values[valuesById[40] = "kAmsAfdau"] = 40;
        values[valuesById[50] = "kOlympicAeroETL"] = 50;
        values[valuesById[60] = "kAkvEtm"] = 60;
        values[valuesById[100] = "kVectorNav"] = 100;
        return values;
    })();

    /**
     * Parity enum.
     * @name ProtobufSettingsUserMainV1.Parity
     * @enum {number}
     * @property {number} kNoParity=0 kNoParity value
     * @property {number} kEven=1 kEven value
     * @property {number} kOdd=2 kOdd value
     */
    ProtobufSettingsUserMainV1.Parity = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoParity"] = 0;
        values[valuesById[1] = "kEven"] = 1;
        values[valuesById[2] = "kOdd"] = 2;
        return values;
    })();

    /**
     * FtpDefaultServer enum.
     * @name ProtobufSettingsUserMainV1.FtpDefaultServer
     * @enum {number}
     * @property {number} kFTP1=0 kFTP1 value
     * @property {number} kFTP2=1 kFTP2 value
     * @property {number} kFtpDisabled=2 kFtpDisabled value
     */
    ProtobufSettingsUserMainV1.FtpDefaultServer = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kFTP1"] = 0;
        values[valuesById[1] = "kFTP2"] = 1;
        values[valuesById[2] = "kFtpDisabled"] = 2;
        return values;
    })();

    /**
     * Priority enum.
     * @name ProtobufSettingsUserMainV1.Priority
     * @enum {number}
     * @property {number} kPriorityNone=0 kPriorityNone value
     * @property {number} kPriorityLow=1 kPriorityLow value
     * @property {number} kPriorityMedium=2 kPriorityMedium value
     * @property {number} kPriorityHigh=3 kPriorityHigh value
     * @property {number} kPrioritySession=4 kPrioritySession value
     * @property {number} kPriorityCritical=5 kPriorityCritical value
     */
    ProtobufSettingsUserMainV1.Priority = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kPriorityNone"] = 0;
        values[valuesById[1] = "kPriorityLow"] = 1;
        values[valuesById[2] = "kPriorityMedium"] = 2;
        values[valuesById[3] = "kPriorityHigh"] = 3;
        values[valuesById[4] = "kPrioritySession"] = 4;
        values[valuesById[5] = "kPriorityCritical"] = 5;
        return values;
    })();

    /**
     * Urgency enum.
     * @name ProtobufSettingsUserMainV1.Urgency
     * @enum {number}
     * @property {number} kUrgencyNextOpportunity=0 kUrgencyNextOpportunity value
     * @property {number} kUrgencyWithin10s=1 kUrgencyWithin10s value
     * @property {number} kUrgencyNow=2 kUrgencyNow value
     */
    ProtobufSettingsUserMainV1.Urgency = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kUrgencyNextOpportunity"] = 0;
        values[valuesById[1] = "kUrgencyWithin10s"] = 1;
        values[valuesById[2] = "kUrgencyNow"] = 2;
        return values;
    })();

    /**
     * InstallOrientation enum.
     * @name ProtobufSettingsUserMainV1.InstallOrientation
     * @enum {number}
     * @property {number} kInstallOrientationNone=0 kInstallOrientationNone value
     * @property {number} kInstallOrientationManual=1 kInstallOrientationManual value
     * @property {number} kInstallOrientationAutoLevel=2 kInstallOrientationAutoLevel value
     * @property {number} kInstallOrientationAutoLevelAndYaw=3 kInstallOrientationAutoLevelAndYaw value
     */
    ProtobufSettingsUserMainV1.InstallOrientation = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kInstallOrientationNone"] = 0;
        values[valuesById[1] = "kInstallOrientationManual"] = 1;
        values[valuesById[2] = "kInstallOrientationAutoLevel"] = 2;
        values[valuesById[3] = "kInstallOrientationAutoLevelAndYaw"] = 3;
        return values;
    })();

    /**
     * Endpoint enum.
     * @name ProtobufSettingsUserMainV1.Endpoint
     * @enum {number}
     * @property {number} kEndpointNone=0 kEndpointNone value
     * @property {number} kEndpointUsb=1 kEndpointUsb value
     * @property {number} kEndpointSerial=2 kEndpointSerial value
     * @property {number} kEndpointGnss=3 kEndpointGnss value
     * @property {number} kEndpointSatellite=4 kEndpointSatellite value
     * @property {number} kEndpointWireless=5 kEndpointWireless value
     * @property {number} kEndpointCellular=6 kEndpointCellular value
     */
    ProtobufSettingsUserMainV1.Endpoint = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kEndpointNone"] = 0;
        values[valuesById[1] = "kEndpointUsb"] = 1;
        values[valuesById[2] = "kEndpointSerial"] = 2;
        values[valuesById[3] = "kEndpointGnss"] = 3;
        values[valuesById[4] = "kEndpointSatellite"] = 4;
        values[valuesById[5] = "kEndpointWireless"] = 5;
        values[valuesById[6] = "kEndpointCellular"] = 6;
        return values;
    })();

    return ProtobufSettingsUserMainV1;
})();

$root.ProtobufSettingsInternalMainV1 = (function() {

    /**
     * Properties of a ProtobufSettingsInternalMainV1.
     * @exports IProtobufSettingsInternalMainV1
     * @interface IProtobufSettingsInternalMainV1
     * @property {ProtobufSettingsUserMainV1.InstallOrientation|null} [installOrientation] ProtobufSettingsInternalMainV1 installOrientation
     * @property {number|null} [installPitchOffsetRad] ProtobufSettingsInternalMainV1 installPitchOffsetRad
     * @property {number|null} [installRollOffsetRad] ProtobufSettingsInternalMainV1 installRollOffsetRad
     * @property {number|null} [installYawOffsetRad] ProtobufSettingsInternalMainV1 installYawOffsetRad
     * @property {number|null} [estimatedYawOffsetRad] ProtobufSettingsInternalMainV1 estimatedYawOffsetRad
     * @property {number|null} [magneticBiasXUt] ProtobufSettingsInternalMainV1 magneticBiasXUt
     * @property {number|null} [magneticBiasYUt] ProtobufSettingsInternalMainV1 magneticBiasYUt
     * @property {number|null} [magneticBiasZUt] ProtobufSettingsInternalMainV1 magneticBiasZUt
     * @property {number|null} [gyroBiasXRad] ProtobufSettingsInternalMainV1 gyroBiasXRad
     * @property {number|null} [gyroBiasYRad] ProtobufSettingsInternalMainV1 gyroBiasYRad
     * @property {number|null} [gyroBiasZRad] ProtobufSettingsInternalMainV1 gyroBiasZRad
     * @property {number|null} [accelBiasXMps2] ProtobufSettingsInternalMainV1 accelBiasXMps2
     * @property {number|null} [accelBiasYMps2] ProtobufSettingsInternalMainV1 accelBiasYMps2
     * @property {number|null} [accelBiasZMps2] ProtobufSettingsInternalMainV1 accelBiasZMps2
     */

    /**
     * Constructs a new ProtobufSettingsInternalMainV1.
     * @exports ProtobufSettingsInternalMainV1
     * @classdesc Represents a ProtobufSettingsInternalMainV1.
     * @implements IProtobufSettingsInternalMainV1
     * @constructor
     * @param {IProtobufSettingsInternalMainV1=} [properties] Properties to set
     */
    function ProtobufSettingsInternalMainV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSettingsInternalMainV1 installOrientation.
     * @member {ProtobufSettingsUserMainV1.InstallOrientation} installOrientation
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.installOrientation = 0;

    /**
     * ProtobufSettingsInternalMainV1 installPitchOffsetRad.
     * @member {number} installPitchOffsetRad
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.installPitchOffsetRad = 0;

    /**
     * ProtobufSettingsInternalMainV1 installRollOffsetRad.
     * @member {number} installRollOffsetRad
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.installRollOffsetRad = 0;

    /**
     * ProtobufSettingsInternalMainV1 installYawOffsetRad.
     * @member {number} installYawOffsetRad
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.installYawOffsetRad = 0;

    /**
     * ProtobufSettingsInternalMainV1 estimatedYawOffsetRad.
     * @member {number} estimatedYawOffsetRad
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.estimatedYawOffsetRad = 0;

    /**
     * ProtobufSettingsInternalMainV1 magneticBiasXUt.
     * @member {number} magneticBiasXUt
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.magneticBiasXUt = 0;

    /**
     * ProtobufSettingsInternalMainV1 magneticBiasYUt.
     * @member {number} magneticBiasYUt
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.magneticBiasYUt = 0;

    /**
     * ProtobufSettingsInternalMainV1 magneticBiasZUt.
     * @member {number} magneticBiasZUt
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.magneticBiasZUt = 0;

    /**
     * ProtobufSettingsInternalMainV1 gyroBiasXRad.
     * @member {number} gyroBiasXRad
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.gyroBiasXRad = 0;

    /**
     * ProtobufSettingsInternalMainV1 gyroBiasYRad.
     * @member {number} gyroBiasYRad
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.gyroBiasYRad = 0;

    /**
     * ProtobufSettingsInternalMainV1 gyroBiasZRad.
     * @member {number} gyroBiasZRad
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.gyroBiasZRad = 0;

    /**
     * ProtobufSettingsInternalMainV1 accelBiasXMps2.
     * @member {number} accelBiasXMps2
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.accelBiasXMps2 = 0;

    /**
     * ProtobufSettingsInternalMainV1 accelBiasYMps2.
     * @member {number} accelBiasYMps2
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.accelBiasYMps2 = 0;

    /**
     * ProtobufSettingsInternalMainV1 accelBiasZMps2.
     * @member {number} accelBiasZMps2
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     */
    ProtobufSettingsInternalMainV1.prototype.accelBiasZMps2 = 0;

    /**
     * Creates a new ProtobufSettingsInternalMainV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {IProtobufSettingsInternalMainV1=} [properties] Properties to set
     * @returns {ProtobufSettingsInternalMainV1} ProtobufSettingsInternalMainV1 instance
     */
    ProtobufSettingsInternalMainV1.create = function create(properties) {
        return new ProtobufSettingsInternalMainV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsInternalMainV1 message. Does not implicitly {@link ProtobufSettingsInternalMainV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {IProtobufSettingsInternalMainV1} message ProtobufSettingsInternalMainV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsInternalMainV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.installOrientation != null && Object.hasOwnProperty.call(message, "installOrientation"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.installOrientation);
        if (message.installPitchOffsetRad != null && Object.hasOwnProperty.call(message, "installPitchOffsetRad"))
            writer.uint32(/* id 21, wireType 5 =*/173).float(message.installPitchOffsetRad);
        if (message.installRollOffsetRad != null && Object.hasOwnProperty.call(message, "installRollOffsetRad"))
            writer.uint32(/* id 22, wireType 5 =*/181).float(message.installRollOffsetRad);
        if (message.installYawOffsetRad != null && Object.hasOwnProperty.call(message, "installYawOffsetRad"))
            writer.uint32(/* id 23, wireType 5 =*/189).float(message.installYawOffsetRad);
        if (message.estimatedYawOffsetRad != null && Object.hasOwnProperty.call(message, "estimatedYawOffsetRad"))
            writer.uint32(/* id 24, wireType 5 =*/197).float(message.estimatedYawOffsetRad);
        if (message.magneticBiasXUt != null && Object.hasOwnProperty.call(message, "magneticBiasXUt"))
            writer.uint32(/* id 30, wireType 5 =*/245).float(message.magneticBiasXUt);
        if (message.magneticBiasYUt != null && Object.hasOwnProperty.call(message, "magneticBiasYUt"))
            writer.uint32(/* id 31, wireType 5 =*/253).float(message.magneticBiasYUt);
        if (message.magneticBiasZUt != null && Object.hasOwnProperty.call(message, "magneticBiasZUt"))
            writer.uint32(/* id 32, wireType 5 =*/261).float(message.magneticBiasZUt);
        if (message.gyroBiasXRad != null && Object.hasOwnProperty.call(message, "gyroBiasXRad"))
            writer.uint32(/* id 33, wireType 5 =*/269).float(message.gyroBiasXRad);
        if (message.gyroBiasYRad != null && Object.hasOwnProperty.call(message, "gyroBiasYRad"))
            writer.uint32(/* id 34, wireType 5 =*/277).float(message.gyroBiasYRad);
        if (message.gyroBiasZRad != null && Object.hasOwnProperty.call(message, "gyroBiasZRad"))
            writer.uint32(/* id 35, wireType 5 =*/285).float(message.gyroBiasZRad);
        if (message.accelBiasXMps2 != null && Object.hasOwnProperty.call(message, "accelBiasXMps2"))
            writer.uint32(/* id 36, wireType 5 =*/293).float(message.accelBiasXMps2);
        if (message.accelBiasYMps2 != null && Object.hasOwnProperty.call(message, "accelBiasYMps2"))
            writer.uint32(/* id 37, wireType 5 =*/301).float(message.accelBiasYMps2);
        if (message.accelBiasZMps2 != null && Object.hasOwnProperty.call(message, "accelBiasZMps2"))
            writer.uint32(/* id 38, wireType 5 =*/309).float(message.accelBiasZMps2);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsInternalMainV1 message, length delimited. Does not implicitly {@link ProtobufSettingsInternalMainV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {IProtobufSettingsInternalMainV1} message ProtobufSettingsInternalMainV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsInternalMainV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsInternalMainV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsInternalMainV1} ProtobufSettingsInternalMainV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsInternalMainV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsInternalMainV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 20: {
                    message.installOrientation = reader.int32();
                    break;
                }
            case 21: {
                    message.installPitchOffsetRad = reader.float();
                    break;
                }
            case 22: {
                    message.installRollOffsetRad = reader.float();
                    break;
                }
            case 23: {
                    message.installYawOffsetRad = reader.float();
                    break;
                }
            case 24: {
                    message.estimatedYawOffsetRad = reader.float();
                    break;
                }
            case 30: {
                    message.magneticBiasXUt = reader.float();
                    break;
                }
            case 31: {
                    message.magneticBiasYUt = reader.float();
                    break;
                }
            case 32: {
                    message.magneticBiasZUt = reader.float();
                    break;
                }
            case 33: {
                    message.gyroBiasXRad = reader.float();
                    break;
                }
            case 34: {
                    message.gyroBiasYRad = reader.float();
                    break;
                }
            case 35: {
                    message.gyroBiasZRad = reader.float();
                    break;
                }
            case 36: {
                    message.accelBiasXMps2 = reader.float();
                    break;
                }
            case 37: {
                    message.accelBiasYMps2 = reader.float();
                    break;
                }
            case 38: {
                    message.accelBiasZMps2 = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsInternalMainV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsInternalMainV1} ProtobufSettingsInternalMainV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsInternalMainV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsInternalMainV1 message.
     * @function verify
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsInternalMainV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.installOrientation != null && message.hasOwnProperty("installOrientation"))
            switch (message.installOrientation) {
            default:
                return "installOrientation: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.installPitchOffsetRad != null && message.hasOwnProperty("installPitchOffsetRad"))
            if (typeof message.installPitchOffsetRad !== "number")
                return "installPitchOffsetRad: number expected";
        if (message.installRollOffsetRad != null && message.hasOwnProperty("installRollOffsetRad"))
            if (typeof message.installRollOffsetRad !== "number")
                return "installRollOffsetRad: number expected";
        if (message.installYawOffsetRad != null && message.hasOwnProperty("installYawOffsetRad"))
            if (typeof message.installYawOffsetRad !== "number")
                return "installYawOffsetRad: number expected";
        if (message.estimatedYawOffsetRad != null && message.hasOwnProperty("estimatedYawOffsetRad"))
            if (typeof message.estimatedYawOffsetRad !== "number")
                return "estimatedYawOffsetRad: number expected";
        if (message.magneticBiasXUt != null && message.hasOwnProperty("magneticBiasXUt"))
            if (typeof message.magneticBiasXUt !== "number")
                return "magneticBiasXUt: number expected";
        if (message.magneticBiasYUt != null && message.hasOwnProperty("magneticBiasYUt"))
            if (typeof message.magneticBiasYUt !== "number")
                return "magneticBiasYUt: number expected";
        if (message.magneticBiasZUt != null && message.hasOwnProperty("magneticBiasZUt"))
            if (typeof message.magneticBiasZUt !== "number")
                return "magneticBiasZUt: number expected";
        if (message.gyroBiasXRad != null && message.hasOwnProperty("gyroBiasXRad"))
            if (typeof message.gyroBiasXRad !== "number")
                return "gyroBiasXRad: number expected";
        if (message.gyroBiasYRad != null && message.hasOwnProperty("gyroBiasYRad"))
            if (typeof message.gyroBiasYRad !== "number")
                return "gyroBiasYRad: number expected";
        if (message.gyroBiasZRad != null && message.hasOwnProperty("gyroBiasZRad"))
            if (typeof message.gyroBiasZRad !== "number")
                return "gyroBiasZRad: number expected";
        if (message.accelBiasXMps2 != null && message.hasOwnProperty("accelBiasXMps2"))
            if (typeof message.accelBiasXMps2 !== "number")
                return "accelBiasXMps2: number expected";
        if (message.accelBiasYMps2 != null && message.hasOwnProperty("accelBiasYMps2"))
            if (typeof message.accelBiasYMps2 !== "number")
                return "accelBiasYMps2: number expected";
        if (message.accelBiasZMps2 != null && message.hasOwnProperty("accelBiasZMps2"))
            if (typeof message.accelBiasZMps2 !== "number")
                return "accelBiasZMps2: number expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsInternalMainV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsInternalMainV1} ProtobufSettingsInternalMainV1
     */
    ProtobufSettingsInternalMainV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsInternalMainV1)
            return object;
        var message = new $root.ProtobufSettingsInternalMainV1();
        switch (object.installOrientation) {
        default:
            if (typeof object.installOrientation === "number") {
                message.installOrientation = object.installOrientation;
                break;
            }
            break;
        case "kInstallOrientationNone":
        case 0:
            message.installOrientation = 0;
            break;
        case "kInstallOrientationManual":
        case 1:
            message.installOrientation = 1;
            break;
        case "kInstallOrientationAutoLevel":
        case 2:
            message.installOrientation = 2;
            break;
        case "kInstallOrientationAutoLevelAndYaw":
        case 3:
            message.installOrientation = 3;
            break;
        }
        if (object.installPitchOffsetRad != null)
            message.installPitchOffsetRad = Number(object.installPitchOffsetRad);
        if (object.installRollOffsetRad != null)
            message.installRollOffsetRad = Number(object.installRollOffsetRad);
        if (object.installYawOffsetRad != null)
            message.installYawOffsetRad = Number(object.installYawOffsetRad);
        if (object.estimatedYawOffsetRad != null)
            message.estimatedYawOffsetRad = Number(object.estimatedYawOffsetRad);
        if (object.magneticBiasXUt != null)
            message.magneticBiasXUt = Number(object.magneticBiasXUt);
        if (object.magneticBiasYUt != null)
            message.magneticBiasYUt = Number(object.magneticBiasYUt);
        if (object.magneticBiasZUt != null)
            message.magneticBiasZUt = Number(object.magneticBiasZUt);
        if (object.gyroBiasXRad != null)
            message.gyroBiasXRad = Number(object.gyroBiasXRad);
        if (object.gyroBiasYRad != null)
            message.gyroBiasYRad = Number(object.gyroBiasYRad);
        if (object.gyroBiasZRad != null)
            message.gyroBiasZRad = Number(object.gyroBiasZRad);
        if (object.accelBiasXMps2 != null)
            message.accelBiasXMps2 = Number(object.accelBiasXMps2);
        if (object.accelBiasYMps2 != null)
            message.accelBiasYMps2 = Number(object.accelBiasYMps2);
        if (object.accelBiasZMps2 != null)
            message.accelBiasZMps2 = Number(object.accelBiasZMps2);
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSettingsInternalMainV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {ProtobufSettingsInternalMainV1} message ProtobufSettingsInternalMainV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsInternalMainV1.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.installOrientation = options.enums === String ? "kInstallOrientationNone" : 0;
            object.installPitchOffsetRad = 0;
            object.installRollOffsetRad = 0;
            object.installYawOffsetRad = 0;
            object.estimatedYawOffsetRad = 0;
            object.magneticBiasXUt = 0;
            object.magneticBiasYUt = 0;
            object.magneticBiasZUt = 0;
            object.gyroBiasXRad = 0;
            object.gyroBiasYRad = 0;
            object.gyroBiasZRad = 0;
            object.accelBiasXMps2 = 0;
            object.accelBiasYMps2 = 0;
            object.accelBiasZMps2 = 0;
        }
        if (message.installOrientation != null && message.hasOwnProperty("installOrientation"))
            object.installOrientation = options.enums === String ? $root.ProtobufSettingsUserMainV1.InstallOrientation[message.installOrientation] === undefined ? message.installOrientation : $root.ProtobufSettingsUserMainV1.InstallOrientation[message.installOrientation] : message.installOrientation;
        if (message.installPitchOffsetRad != null && message.hasOwnProperty("installPitchOffsetRad"))
            object.installPitchOffsetRad = options.json && !isFinite(message.installPitchOffsetRad) ? String(message.installPitchOffsetRad) : message.installPitchOffsetRad;
        if (message.installRollOffsetRad != null && message.hasOwnProperty("installRollOffsetRad"))
            object.installRollOffsetRad = options.json && !isFinite(message.installRollOffsetRad) ? String(message.installRollOffsetRad) : message.installRollOffsetRad;
        if (message.installYawOffsetRad != null && message.hasOwnProperty("installYawOffsetRad"))
            object.installYawOffsetRad = options.json && !isFinite(message.installYawOffsetRad) ? String(message.installYawOffsetRad) : message.installYawOffsetRad;
        if (message.estimatedYawOffsetRad != null && message.hasOwnProperty("estimatedYawOffsetRad"))
            object.estimatedYawOffsetRad = options.json && !isFinite(message.estimatedYawOffsetRad) ? String(message.estimatedYawOffsetRad) : message.estimatedYawOffsetRad;
        if (message.magneticBiasXUt != null && message.hasOwnProperty("magneticBiasXUt"))
            object.magneticBiasXUt = options.json && !isFinite(message.magneticBiasXUt) ? String(message.magneticBiasXUt) : message.magneticBiasXUt;
        if (message.magneticBiasYUt != null && message.hasOwnProperty("magneticBiasYUt"))
            object.magneticBiasYUt = options.json && !isFinite(message.magneticBiasYUt) ? String(message.magneticBiasYUt) : message.magneticBiasYUt;
        if (message.magneticBiasZUt != null && message.hasOwnProperty("magneticBiasZUt"))
            object.magneticBiasZUt = options.json && !isFinite(message.magneticBiasZUt) ? String(message.magneticBiasZUt) : message.magneticBiasZUt;
        if (message.gyroBiasXRad != null && message.hasOwnProperty("gyroBiasXRad"))
            object.gyroBiasXRad = options.json && !isFinite(message.gyroBiasXRad) ? String(message.gyroBiasXRad) : message.gyroBiasXRad;
        if (message.gyroBiasYRad != null && message.hasOwnProperty("gyroBiasYRad"))
            object.gyroBiasYRad = options.json && !isFinite(message.gyroBiasYRad) ? String(message.gyroBiasYRad) : message.gyroBiasYRad;
        if (message.gyroBiasZRad != null && message.hasOwnProperty("gyroBiasZRad"))
            object.gyroBiasZRad = options.json && !isFinite(message.gyroBiasZRad) ? String(message.gyroBiasZRad) : message.gyroBiasZRad;
        if (message.accelBiasXMps2 != null && message.hasOwnProperty("accelBiasXMps2"))
            object.accelBiasXMps2 = options.json && !isFinite(message.accelBiasXMps2) ? String(message.accelBiasXMps2) : message.accelBiasXMps2;
        if (message.accelBiasYMps2 != null && message.hasOwnProperty("accelBiasYMps2"))
            object.accelBiasYMps2 = options.json && !isFinite(message.accelBiasYMps2) ? String(message.accelBiasYMps2) : message.accelBiasYMps2;
        if (message.accelBiasZMps2 != null && message.hasOwnProperty("accelBiasZMps2"))
            object.accelBiasZMps2 = options.json && !isFinite(message.accelBiasZMps2) ? String(message.accelBiasZMps2) : message.accelBiasZMps2;
        return object;
    };

    /**
     * Converts this ProtobufSettingsInternalMainV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsInternalMainV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsInternalMainV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsInternalMainV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsInternalMainV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsInternalMainV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsInternalMainV1";
    };

    return ProtobufSettingsInternalMainV1;
})();

$root.ProtobufSettingsServerKeypadV1 = (function() {

    /**
     * Properties of a ProtobufSettingsServerKeypadV1.
     * @exports IProtobufSettingsServerKeypadV1
     * @interface IProtobufSettingsServerKeypadV1
     */

    /**
     * Constructs a new ProtobufSettingsServerKeypadV1.
     * @exports ProtobufSettingsServerKeypadV1
     * @classdesc Represents a ProtobufSettingsServerKeypadV1.
     * @implements IProtobufSettingsServerKeypadV1
     * @constructor
     * @param {IProtobufSettingsServerKeypadV1=} [properties] Properties to set
     */
    function ProtobufSettingsServerKeypadV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new ProtobufSettingsServerKeypadV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {IProtobufSettingsServerKeypadV1=} [properties] Properties to set
     * @returns {ProtobufSettingsServerKeypadV1} ProtobufSettingsServerKeypadV1 instance
     */
    ProtobufSettingsServerKeypadV1.create = function create(properties) {
        return new ProtobufSettingsServerKeypadV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsServerKeypadV1 message. Does not implicitly {@link ProtobufSettingsServerKeypadV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {IProtobufSettingsServerKeypadV1} message ProtobufSettingsServerKeypadV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsServerKeypadV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsServerKeypadV1 message, length delimited. Does not implicitly {@link ProtobufSettingsServerKeypadV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {IProtobufSettingsServerKeypadV1} message ProtobufSettingsServerKeypadV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsServerKeypadV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsServerKeypadV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsServerKeypadV1} ProtobufSettingsServerKeypadV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsServerKeypadV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsServerKeypadV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsServerKeypadV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsServerKeypadV1} ProtobufSettingsServerKeypadV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsServerKeypadV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsServerKeypadV1 message.
     * @function verify
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsServerKeypadV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsServerKeypadV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsServerKeypadV1} ProtobufSettingsServerKeypadV1
     */
    ProtobufSettingsServerKeypadV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsServerKeypadV1)
            return object;
        return new $root.ProtobufSettingsServerKeypadV1();
    };

    /**
     * Creates a plain object from a ProtobufSettingsServerKeypadV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {ProtobufSettingsServerKeypadV1} message ProtobufSettingsServerKeypadV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsServerKeypadV1.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this ProtobufSettingsServerKeypadV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsServerKeypadV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsServerKeypadV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsServerKeypadV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsServerKeypadV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsServerKeypadV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsServerKeypadV1";
    };

    return ProtobufSettingsServerKeypadV1;
})();

$root.ProtobufSettingsUserKeypadV1 = (function() {

    /**
     * Properties of a ProtobufSettingsUserKeypadV1.
     * @exports IProtobufSettingsUserKeypadV1
     * @interface IProtobufSettingsUserKeypadV1
     * @property {ProtobufSettingsUserMainV1.Brightness|null} [ledBrightness] ProtobufSettingsUserKeypadV1 ledBrightness
     * @property {number|null} [ledBrightnessMaxPct] ProtobufSettingsUserKeypadV1 ledBrightnessMaxPct
     * @property {number|null} [ledBrightnessMinPct] ProtobufSettingsUserKeypadV1 ledBrightnessMinPct
     */

    /**
     * Constructs a new ProtobufSettingsUserKeypadV1.
     * @exports ProtobufSettingsUserKeypadV1
     * @classdesc Represents a ProtobufSettingsUserKeypadV1.
     * @implements IProtobufSettingsUserKeypadV1
     * @constructor
     * @param {IProtobufSettingsUserKeypadV1=} [properties] Properties to set
     */
    function ProtobufSettingsUserKeypadV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSettingsUserKeypadV1 ledBrightness.
     * @member {ProtobufSettingsUserMainV1.Brightness} ledBrightness
     * @memberof ProtobufSettingsUserKeypadV1
     * @instance
     */
    ProtobufSettingsUserKeypadV1.prototype.ledBrightness = 0;

    /**
     * ProtobufSettingsUserKeypadV1 ledBrightnessMaxPct.
     * @member {number} ledBrightnessMaxPct
     * @memberof ProtobufSettingsUserKeypadV1
     * @instance
     */
    ProtobufSettingsUserKeypadV1.prototype.ledBrightnessMaxPct = 100;

    /**
     * ProtobufSettingsUserKeypadV1 ledBrightnessMinPct.
     * @member {number} ledBrightnessMinPct
     * @memberof ProtobufSettingsUserKeypadV1
     * @instance
     */
    ProtobufSettingsUserKeypadV1.prototype.ledBrightnessMinPct = 0;

    /**
     * Creates a new ProtobufSettingsUserKeypadV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {IProtobufSettingsUserKeypadV1=} [properties] Properties to set
     * @returns {ProtobufSettingsUserKeypadV1} ProtobufSettingsUserKeypadV1 instance
     */
    ProtobufSettingsUserKeypadV1.create = function create(properties) {
        return new ProtobufSettingsUserKeypadV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsUserKeypadV1 message. Does not implicitly {@link ProtobufSettingsUserKeypadV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {IProtobufSettingsUserKeypadV1} message ProtobufSettingsUserKeypadV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsUserKeypadV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ledBrightness != null && Object.hasOwnProperty.call(message, "ledBrightness"))
            writer.uint32(/* id 30, wireType 0 =*/240).int32(message.ledBrightness);
        if (message.ledBrightnessMaxPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMaxPct"))
            writer.uint32(/* id 31, wireType 0 =*/248).uint32(message.ledBrightnessMaxPct);
        if (message.ledBrightnessMinPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMinPct"))
            writer.uint32(/* id 32, wireType 0 =*/256).uint32(message.ledBrightnessMinPct);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsUserKeypadV1 message, length delimited. Does not implicitly {@link ProtobufSettingsUserKeypadV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {IProtobufSettingsUserKeypadV1} message ProtobufSettingsUserKeypadV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsUserKeypadV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsUserKeypadV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsUserKeypadV1} ProtobufSettingsUserKeypadV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsUserKeypadV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsUserKeypadV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 30: {
                    message.ledBrightness = reader.int32();
                    break;
                }
            case 31: {
                    message.ledBrightnessMaxPct = reader.uint32();
                    break;
                }
            case 32: {
                    message.ledBrightnessMinPct = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsUserKeypadV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsUserKeypadV1} ProtobufSettingsUserKeypadV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsUserKeypadV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsUserKeypadV1 message.
     * @function verify
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsUserKeypadV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.ledBrightness != null && message.hasOwnProperty("ledBrightness"))
            switch (message.ledBrightness) {
            default:
                return "ledBrightness: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            if (!$util.isInteger(message.ledBrightnessMaxPct))
                return "ledBrightnessMaxPct: integer expected";
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            if (!$util.isInteger(message.ledBrightnessMinPct))
                return "ledBrightnessMinPct: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsUserKeypadV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsUserKeypadV1} ProtobufSettingsUserKeypadV1
     */
    ProtobufSettingsUserKeypadV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsUserKeypadV1)
            return object;
        var message = new $root.ProtobufSettingsUserKeypadV1();
        switch (object.ledBrightness) {
        default:
            if (typeof object.ledBrightness === "number") {
                message.ledBrightness = object.ledBrightness;
                break;
            }
            break;
        case "kAuto":
        case 0:
            message.ledBrightness = 0;
            break;
        case "kFixed":
        case 1:
            message.ledBrightness = 1;
            break;
        case "kOff":
        case 2:
            message.ledBrightness = 2;
            break;
        }
        if (object.ledBrightnessMaxPct != null)
            message.ledBrightnessMaxPct = object.ledBrightnessMaxPct >>> 0;
        if (object.ledBrightnessMinPct != null)
            message.ledBrightnessMinPct = object.ledBrightnessMinPct >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSettingsUserKeypadV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {ProtobufSettingsUserKeypadV1} message ProtobufSettingsUserKeypadV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsUserKeypadV1.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.ledBrightness = options.enums === String ? "kAuto" : 0;
            object.ledBrightnessMaxPct = 100;
            object.ledBrightnessMinPct = 0;
        }
        if (message.ledBrightness != null && message.hasOwnProperty("ledBrightness"))
            object.ledBrightness = options.enums === String ? $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightness] === undefined ? message.ledBrightness : $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightness] : message.ledBrightness;
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            object.ledBrightnessMaxPct = message.ledBrightnessMaxPct;
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            object.ledBrightnessMinPct = message.ledBrightnessMinPct;
        return object;
    };

    /**
     * Converts this ProtobufSettingsUserKeypadV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsUserKeypadV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsUserKeypadV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsUserKeypadV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsUserKeypadV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsUserKeypadV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsUserKeypadV1";
    };

    return ProtobufSettingsUserKeypadV1;
})();

$root.ProtobufSettingsInternalKeypadV1 = (function() {

    /**
     * Properties of a ProtobufSettingsInternalKeypadV1.
     * @exports IProtobufSettingsInternalKeypadV1
     * @interface IProtobufSettingsInternalKeypadV1
     */

    /**
     * Constructs a new ProtobufSettingsInternalKeypadV1.
     * @exports ProtobufSettingsInternalKeypadV1
     * @classdesc Represents a ProtobufSettingsInternalKeypadV1.
     * @implements IProtobufSettingsInternalKeypadV1
     * @constructor
     * @param {IProtobufSettingsInternalKeypadV1=} [properties] Properties to set
     */
    function ProtobufSettingsInternalKeypadV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new ProtobufSettingsInternalKeypadV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {IProtobufSettingsInternalKeypadV1=} [properties] Properties to set
     * @returns {ProtobufSettingsInternalKeypadV1} ProtobufSettingsInternalKeypadV1 instance
     */
    ProtobufSettingsInternalKeypadV1.create = function create(properties) {
        return new ProtobufSettingsInternalKeypadV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsInternalKeypadV1 message. Does not implicitly {@link ProtobufSettingsInternalKeypadV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {IProtobufSettingsInternalKeypadV1} message ProtobufSettingsInternalKeypadV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsInternalKeypadV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsInternalKeypadV1 message, length delimited. Does not implicitly {@link ProtobufSettingsInternalKeypadV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {IProtobufSettingsInternalKeypadV1} message ProtobufSettingsInternalKeypadV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsInternalKeypadV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsInternalKeypadV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsInternalKeypadV1} ProtobufSettingsInternalKeypadV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsInternalKeypadV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsInternalKeypadV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsInternalKeypadV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsInternalKeypadV1} ProtobufSettingsInternalKeypadV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsInternalKeypadV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsInternalKeypadV1 message.
     * @function verify
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsInternalKeypadV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsInternalKeypadV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsInternalKeypadV1} ProtobufSettingsInternalKeypadV1
     */
    ProtobufSettingsInternalKeypadV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsInternalKeypadV1)
            return object;
        return new $root.ProtobufSettingsInternalKeypadV1();
    };

    /**
     * Creates a plain object from a ProtobufSettingsInternalKeypadV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {ProtobufSettingsInternalKeypadV1} message ProtobufSettingsInternalKeypadV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsInternalKeypadV1.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this ProtobufSettingsInternalKeypadV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsInternalKeypadV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsInternalKeypadV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsInternalKeypadV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsInternalKeypadV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsInternalKeypadV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsInternalKeypadV1";
    };

    return ProtobufSettingsInternalKeypadV1;
})();

$root.ProtobufSettingsServerGpiV1 = (function() {

    /**
     * Properties of a ProtobufSettingsServerGpiV1.
     * @exports IProtobufSettingsServerGpiV1
     * @interface IProtobufSettingsServerGpiV1
     */

    /**
     * Constructs a new ProtobufSettingsServerGpiV1.
     * @exports ProtobufSettingsServerGpiV1
     * @classdesc Represents a ProtobufSettingsServerGpiV1.
     * @implements IProtobufSettingsServerGpiV1
     * @constructor
     * @param {IProtobufSettingsServerGpiV1=} [properties] Properties to set
     */
    function ProtobufSettingsServerGpiV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new ProtobufSettingsServerGpiV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {IProtobufSettingsServerGpiV1=} [properties] Properties to set
     * @returns {ProtobufSettingsServerGpiV1} ProtobufSettingsServerGpiV1 instance
     */
    ProtobufSettingsServerGpiV1.create = function create(properties) {
        return new ProtobufSettingsServerGpiV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsServerGpiV1 message. Does not implicitly {@link ProtobufSettingsServerGpiV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {IProtobufSettingsServerGpiV1} message ProtobufSettingsServerGpiV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsServerGpiV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsServerGpiV1 message, length delimited. Does not implicitly {@link ProtobufSettingsServerGpiV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {IProtobufSettingsServerGpiV1} message ProtobufSettingsServerGpiV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsServerGpiV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsServerGpiV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsServerGpiV1} ProtobufSettingsServerGpiV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsServerGpiV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsServerGpiV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsServerGpiV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsServerGpiV1} ProtobufSettingsServerGpiV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsServerGpiV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsServerGpiV1 message.
     * @function verify
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsServerGpiV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsServerGpiV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsServerGpiV1} ProtobufSettingsServerGpiV1
     */
    ProtobufSettingsServerGpiV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsServerGpiV1)
            return object;
        return new $root.ProtobufSettingsServerGpiV1();
    };

    /**
     * Creates a plain object from a ProtobufSettingsServerGpiV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {ProtobufSettingsServerGpiV1} message ProtobufSettingsServerGpiV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsServerGpiV1.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this ProtobufSettingsServerGpiV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsServerGpiV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsServerGpiV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsServerGpiV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsServerGpiV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsServerGpiV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsServerGpiV1";
    };

    return ProtobufSettingsServerGpiV1;
})();

$root.ProtobufSettingsUserGpiV1 = (function() {

    /**
     * Properties of a ProtobufSettingsUserGpiV1.
     * @exports IProtobufSettingsUserGpiV1
     * @interface IProtobufSettingsUserGpiV1
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_0ActiveState] ProtobufSettingsUserGpiV1 input_0ActiveState
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_1ActiveState] ProtobufSettingsUserGpiV1 input_1ActiveState
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_2ActiveState] ProtobufSettingsUserGpiV1 input_2ActiveState
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_3ActiveState] ProtobufSettingsUserGpiV1 input_3ActiveState
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_0Type] ProtobufSettingsUserGpiV1 input_0Type
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_1Type] ProtobufSettingsUserGpiV1 input_1Type
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_2Type] ProtobufSettingsUserGpiV1 input_2Type
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_3Type] ProtobufSettingsUserGpiV1 input_3Type
     */

    /**
     * Constructs a new ProtobufSettingsUserGpiV1.
     * @exports ProtobufSettingsUserGpiV1
     * @classdesc Represents a ProtobufSettingsUserGpiV1.
     * @implements IProtobufSettingsUserGpiV1
     * @constructor
     * @param {IProtobufSettingsUserGpiV1=} [properties] Properties to set
     */
    function ProtobufSettingsUserGpiV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSettingsUserGpiV1 input_0ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_0ActiveState
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_0ActiveState = 1;

    /**
     * ProtobufSettingsUserGpiV1 input_1ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_1ActiveState
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_1ActiveState = 1;

    /**
     * ProtobufSettingsUserGpiV1 input_2ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_2ActiveState
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_2ActiveState = 1;

    /**
     * ProtobufSettingsUserGpiV1 input_3ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_3ActiveState
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_3ActiveState = 1;

    /**
     * ProtobufSettingsUserGpiV1 input_0Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_0Type
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_0Type = 0;

    /**
     * ProtobufSettingsUserGpiV1 input_1Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_1Type
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_1Type = 0;

    /**
     * ProtobufSettingsUserGpiV1 input_2Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_2Type
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_2Type = 0;

    /**
     * ProtobufSettingsUserGpiV1 input_3Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_3Type
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     */
    ProtobufSettingsUserGpiV1.prototype.input_3Type = 0;

    /**
     * Creates a new ProtobufSettingsUserGpiV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {IProtobufSettingsUserGpiV1=} [properties] Properties to set
     * @returns {ProtobufSettingsUserGpiV1} ProtobufSettingsUserGpiV1 instance
     */
    ProtobufSettingsUserGpiV1.create = function create(properties) {
        return new ProtobufSettingsUserGpiV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsUserGpiV1 message. Does not implicitly {@link ProtobufSettingsUserGpiV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {IProtobufSettingsUserGpiV1} message ProtobufSettingsUserGpiV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsUserGpiV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.input_0ActiveState != null && Object.hasOwnProperty.call(message, "input_0ActiveState"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.input_0ActiveState);
        if (message.input_1ActiveState != null && Object.hasOwnProperty.call(message, "input_1ActiveState"))
            writer.uint32(/* id 21, wireType 0 =*/168).int32(message.input_1ActiveState);
        if (message.input_2ActiveState != null && Object.hasOwnProperty.call(message, "input_2ActiveState"))
            writer.uint32(/* id 22, wireType 0 =*/176).int32(message.input_2ActiveState);
        if (message.input_3ActiveState != null && Object.hasOwnProperty.call(message, "input_3ActiveState"))
            writer.uint32(/* id 23, wireType 0 =*/184).int32(message.input_3ActiveState);
        if (message.input_0Type != null && Object.hasOwnProperty.call(message, "input_0Type"))
            writer.uint32(/* id 30, wireType 0 =*/240).int32(message.input_0Type);
        if (message.input_1Type != null && Object.hasOwnProperty.call(message, "input_1Type"))
            writer.uint32(/* id 31, wireType 0 =*/248).int32(message.input_1Type);
        if (message.input_2Type != null && Object.hasOwnProperty.call(message, "input_2Type"))
            writer.uint32(/* id 32, wireType 0 =*/256).int32(message.input_2Type);
        if (message.input_3Type != null && Object.hasOwnProperty.call(message, "input_3Type"))
            writer.uint32(/* id 33, wireType 0 =*/264).int32(message.input_3Type);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsUserGpiV1 message, length delimited. Does not implicitly {@link ProtobufSettingsUserGpiV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {IProtobufSettingsUserGpiV1} message ProtobufSettingsUserGpiV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsUserGpiV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsUserGpiV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsUserGpiV1} ProtobufSettingsUserGpiV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsUserGpiV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsUserGpiV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 20: {
                    message.input_0ActiveState = reader.int32();
                    break;
                }
            case 21: {
                    message.input_1ActiveState = reader.int32();
                    break;
                }
            case 22: {
                    message.input_2ActiveState = reader.int32();
                    break;
                }
            case 23: {
                    message.input_3ActiveState = reader.int32();
                    break;
                }
            case 30: {
                    message.input_0Type = reader.int32();
                    break;
                }
            case 31: {
                    message.input_1Type = reader.int32();
                    break;
                }
            case 32: {
                    message.input_2Type = reader.int32();
                    break;
                }
            case 33: {
                    message.input_3Type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsUserGpiV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsUserGpiV1} ProtobufSettingsUserGpiV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsUserGpiV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsUserGpiV1 message.
     * @function verify
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsUserGpiV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.input_0ActiveState != null && message.hasOwnProperty("input_0ActiveState"))
            switch (message.input_0ActiveState) {
            default:
                return "input_0ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_1ActiveState != null && message.hasOwnProperty("input_1ActiveState"))
            switch (message.input_1ActiveState) {
            default:
                return "input_1ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_2ActiveState != null && message.hasOwnProperty("input_2ActiveState"))
            switch (message.input_2ActiveState) {
            default:
                return "input_2ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_3ActiveState != null && message.hasOwnProperty("input_3ActiveState"))
            switch (message.input_3ActiveState) {
            default:
                return "input_3ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_0Type != null && message.hasOwnProperty("input_0Type"))
            switch (message.input_0Type) {
            default:
                return "input_0Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.input_1Type != null && message.hasOwnProperty("input_1Type"))
            switch (message.input_1Type) {
            default:
                return "input_1Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.input_2Type != null && message.hasOwnProperty("input_2Type"))
            switch (message.input_2Type) {
            default:
                return "input_2Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.input_3Type != null && message.hasOwnProperty("input_3Type"))
            switch (message.input_3Type) {
            default:
                return "input_3Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        return null;
    };

    /**
     * Creates a ProtobufSettingsUserGpiV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsUserGpiV1} ProtobufSettingsUserGpiV1
     */
    ProtobufSettingsUserGpiV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsUserGpiV1)
            return object;
        var message = new $root.ProtobufSettingsUserGpiV1();
        switch (object.input_0ActiveState) {
        case "kActiveStateDefault":
        case 0:
            message.input_0ActiveState = 0;
            break;
        default:
            if (typeof object.input_0ActiveState === "number") {
                message.input_0ActiveState = object.input_0ActiveState;
                break;
            }
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_0ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_0ActiveState = 2;
            break;
        }
        switch (object.input_1ActiveState) {
        case "kActiveStateDefault":
        case 0:
            message.input_1ActiveState = 0;
            break;
        default:
            if (typeof object.input_1ActiveState === "number") {
                message.input_1ActiveState = object.input_1ActiveState;
                break;
            }
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_1ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_1ActiveState = 2;
            break;
        }
        switch (object.input_2ActiveState) {
        case "kActiveStateDefault":
        case 0:
            message.input_2ActiveState = 0;
            break;
        default:
            if (typeof object.input_2ActiveState === "number") {
                message.input_2ActiveState = object.input_2ActiveState;
                break;
            }
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_2ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_2ActiveState = 2;
            break;
        }
        switch (object.input_3ActiveState) {
        case "kActiveStateDefault":
        case 0:
            message.input_3ActiveState = 0;
            break;
        default:
            if (typeof object.input_3ActiveState === "number") {
                message.input_3ActiveState = object.input_3ActiveState;
                break;
            }
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_3ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_3ActiveState = 2;
            break;
        }
        switch (object.input_0Type) {
        default:
            if (typeof object.input_0Type === "number") {
                message.input_0Type = object.input_0Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_0Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_0Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_0Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_0Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_0Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_0Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_0Type = 6;
            break;
        }
        switch (object.input_1Type) {
        default:
            if (typeof object.input_1Type === "number") {
                message.input_1Type = object.input_1Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_1Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_1Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_1Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_1Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_1Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_1Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_1Type = 6;
            break;
        }
        switch (object.input_2Type) {
        default:
            if (typeof object.input_2Type === "number") {
                message.input_2Type = object.input_2Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_2Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_2Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_2Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_2Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_2Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_2Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_2Type = 6;
            break;
        }
        switch (object.input_3Type) {
        default:
            if (typeof object.input_3Type === "number") {
                message.input_3Type = object.input_3Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_3Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_3Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_3Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_3Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_3Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_3Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_3Type = 6;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSettingsUserGpiV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {ProtobufSettingsUserGpiV1} message ProtobufSettingsUserGpiV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsUserGpiV1.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.input_0ActiveState = options.enums === String ? "kActiveStateHigh" : 1;
            object.input_1ActiveState = options.enums === String ? "kActiveStateHigh" : 1;
            object.input_2ActiveState = options.enums === String ? "kActiveStateHigh" : 1;
            object.input_3ActiveState = options.enums === String ? "kActiveStateHigh" : 1;
            object.input_0Type = options.enums === String ? "kInputTypeUnused" : 0;
            object.input_1Type = options.enums === String ? "kInputTypeUnused" : 0;
            object.input_2Type = options.enums === String ? "kInputTypeUnused" : 0;
            object.input_3Type = options.enums === String ? "kInputTypeUnused" : 0;
        }
        if (message.input_0ActiveState != null && message.hasOwnProperty("input_0ActiveState"))
            object.input_0ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_0ActiveState] === undefined ? message.input_0ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_0ActiveState] : message.input_0ActiveState;
        if (message.input_1ActiveState != null && message.hasOwnProperty("input_1ActiveState"))
            object.input_1ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_1ActiveState] === undefined ? message.input_1ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_1ActiveState] : message.input_1ActiveState;
        if (message.input_2ActiveState != null && message.hasOwnProperty("input_2ActiveState"))
            object.input_2ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_2ActiveState] === undefined ? message.input_2ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_2ActiveState] : message.input_2ActiveState;
        if (message.input_3ActiveState != null && message.hasOwnProperty("input_3ActiveState"))
            object.input_3ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_3ActiveState] === undefined ? message.input_3ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_3ActiveState] : message.input_3ActiveState;
        if (message.input_0Type != null && message.hasOwnProperty("input_0Type"))
            object.input_0Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_0Type] === undefined ? message.input_0Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_0Type] : message.input_0Type;
        if (message.input_1Type != null && message.hasOwnProperty("input_1Type"))
            object.input_1Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_1Type] === undefined ? message.input_1Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_1Type] : message.input_1Type;
        if (message.input_2Type != null && message.hasOwnProperty("input_2Type"))
            object.input_2Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_2Type] === undefined ? message.input_2Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_2Type] : message.input_2Type;
        if (message.input_3Type != null && message.hasOwnProperty("input_3Type"))
            object.input_3Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_3Type] === undefined ? message.input_3Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_3Type] : message.input_3Type;
        return object;
    };

    /**
     * Converts this ProtobufSettingsUserGpiV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsUserGpiV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsUserGpiV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsUserGpiV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsUserGpiV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsUserGpiV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsUserGpiV1";
    };

    /**
     * ActiveState enum.
     * @name ProtobufSettingsUserGpiV1.ActiveState
     * @enum {number}
     * @property {number} kActiveStateDefault=0 kActiveStateDefault value
     * @property {number} kActiveStateHigh=1 kActiveStateHigh value
     * @property {number} kActiveStateLow=2 kActiveStateLow value
     */
    ProtobufSettingsUserGpiV1.ActiveState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kActiveStateDefault"] = 0;
        values[valuesById[1] = "kActiveStateHigh"] = 1;
        values[valuesById[2] = "kActiveStateLow"] = 2;
        return values;
    })();

    /**
     * InputType enum.
     * @name ProtobufSettingsUserGpiV1.InputType
     * @enum {number}
     * @property {number} kInputTypeUnused=0 kInputTypeUnused value
     * @property {number} kInputTypeEngineOilPressure1=1 kInputTypeEngineOilPressure1 value
     * @property {number} kInputTypeEngineOilPressure2=2 kInputTypeEngineOilPressure2 value
     * @property {number} kInputTypeMainGearboxOilPressure=3 kInputTypeMainGearboxOilPressure value
     * @property {number} kInputTypeCollectiveRaised=4 kInputTypeCollectiveRaised value
     * @property {number} kInputTypeWeightOnWheels=5 kInputTypeWeightOnWheels value
     * @property {number} kInputTypeLandingGearRetracted=6 kInputTypeLandingGearRetracted value
     */
    ProtobufSettingsUserGpiV1.InputType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kInputTypeUnused"] = 0;
        values[valuesById[1] = "kInputTypeEngineOilPressure1"] = 1;
        values[valuesById[2] = "kInputTypeEngineOilPressure2"] = 2;
        values[valuesById[3] = "kInputTypeMainGearboxOilPressure"] = 3;
        values[valuesById[4] = "kInputTypeCollectiveRaised"] = 4;
        values[valuesById[5] = "kInputTypeWeightOnWheels"] = 5;
        values[valuesById[6] = "kInputTypeLandingGearRetracted"] = 6;
        return values;
    })();

    return ProtobufSettingsUserGpiV1;
})();

$root.ProtobufSettingsInternalGpiV1 = (function() {

    /**
     * Properties of a ProtobufSettingsInternalGpiV1.
     * @exports IProtobufSettingsInternalGpiV1
     * @interface IProtobufSettingsInternalGpiV1
     */

    /**
     * Constructs a new ProtobufSettingsInternalGpiV1.
     * @exports ProtobufSettingsInternalGpiV1
     * @classdesc Represents a ProtobufSettingsInternalGpiV1.
     * @implements IProtobufSettingsInternalGpiV1
     * @constructor
     * @param {IProtobufSettingsInternalGpiV1=} [properties] Properties to set
     */
    function ProtobufSettingsInternalGpiV1(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new ProtobufSettingsInternalGpiV1 instance using the specified properties.
     * @function create
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {IProtobufSettingsInternalGpiV1=} [properties] Properties to set
     * @returns {ProtobufSettingsInternalGpiV1} ProtobufSettingsInternalGpiV1 instance
     */
    ProtobufSettingsInternalGpiV1.create = function create(properties) {
        return new ProtobufSettingsInternalGpiV1(properties);
    };

    /**
     * Encodes the specified ProtobufSettingsInternalGpiV1 message. Does not implicitly {@link ProtobufSettingsInternalGpiV1.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {IProtobufSettingsInternalGpiV1} message ProtobufSettingsInternalGpiV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsInternalGpiV1.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified ProtobufSettingsInternalGpiV1 message, length delimited. Does not implicitly {@link ProtobufSettingsInternalGpiV1.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {IProtobufSettingsInternalGpiV1} message ProtobufSettingsInternalGpiV1 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSettingsInternalGpiV1.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSettingsInternalGpiV1 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSettingsInternalGpiV1} ProtobufSettingsInternalGpiV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsInternalGpiV1.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSettingsInternalGpiV1();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSettingsInternalGpiV1 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSettingsInternalGpiV1} ProtobufSettingsInternalGpiV1
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSettingsInternalGpiV1.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSettingsInternalGpiV1 message.
     * @function verify
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSettingsInternalGpiV1.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a ProtobufSettingsInternalGpiV1 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSettingsInternalGpiV1} ProtobufSettingsInternalGpiV1
     */
    ProtobufSettingsInternalGpiV1.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSettingsInternalGpiV1)
            return object;
        return new $root.ProtobufSettingsInternalGpiV1();
    };

    /**
     * Creates a plain object from a ProtobufSettingsInternalGpiV1 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {ProtobufSettingsInternalGpiV1} message ProtobufSettingsInternalGpiV1
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSettingsInternalGpiV1.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this ProtobufSettingsInternalGpiV1 to JSON.
     * @function toJSON
     * @memberof ProtobufSettingsInternalGpiV1
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSettingsInternalGpiV1.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSettingsInternalGpiV1
     * @function getTypeUrl
     * @memberof ProtobufSettingsInternalGpiV1
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSettingsInternalGpiV1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSettingsInternalGpiV1";
    };

    return ProtobufSettingsInternalGpiV1;
})();

/**
 * FieldType enum.
 * @exports FieldType
 * @enum {number}
 * @property {number} FT_DEFAULT=0 FT_DEFAULT value
 * @property {number} FT_CALLBACK=1 FT_CALLBACK value
 * @property {number} FT_POINTER=4 FT_POINTER value
 * @property {number} FT_STATIC=2 FT_STATIC value
 * @property {number} FT_IGNORE=3 FT_IGNORE value
 * @property {number} FT_INLINE=5 FT_INLINE value
 */
$root.FieldType = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "FT_DEFAULT"] = 0;
    values[valuesById[1] = "FT_CALLBACK"] = 1;
    values[valuesById[4] = "FT_POINTER"] = 4;
    values[valuesById[2] = "FT_STATIC"] = 2;
    values[valuesById[3] = "FT_IGNORE"] = 3;
    values[valuesById[5] = "FT_INLINE"] = 5;
    return values;
})();

/**
 * IntSize enum.
 * @exports IntSize
 * @enum {number}
 * @property {number} IS_DEFAULT=0 IS_DEFAULT value
 * @property {number} IS_8=8 IS_8 value
 * @property {number} IS_16=16 IS_16 value
 * @property {number} IS_32=32 IS_32 value
 * @property {number} IS_64=64 IS_64 value
 */
$root.IntSize = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "IS_DEFAULT"] = 0;
    values[valuesById[8] = "IS_8"] = 8;
    values[valuesById[16] = "IS_16"] = 16;
    values[valuesById[32] = "IS_32"] = 32;
    values[valuesById[64] = "IS_64"] = 64;
    return values;
})();

/**
 * TypenameMangling enum.
 * @exports TypenameMangling
 * @enum {number}
 * @property {number} M_NONE=0 M_NONE value
 * @property {number} M_STRIP_PACKAGE=1 M_STRIP_PACKAGE value
 * @property {number} M_FLATTEN=2 M_FLATTEN value
 * @property {number} M_PACKAGE_INITIALS=3 M_PACKAGE_INITIALS value
 */
$root.TypenameMangling = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "M_NONE"] = 0;
    values[valuesById[1] = "M_STRIP_PACKAGE"] = 1;
    values[valuesById[2] = "M_FLATTEN"] = 2;
    values[valuesById[3] = "M_PACKAGE_INITIALS"] = 3;
    return values;
})();

/**
 * DescriptorSize enum.
 * @exports DescriptorSize
 * @enum {number}
 * @property {number} DS_AUTO=0 DS_AUTO value
 * @property {number} DS_1=1 DS_1 value
 * @property {number} DS_2=2 DS_2 value
 * @property {number} DS_4=4 DS_4 value
 * @property {number} DS_8=8 DS_8 value
 */
$root.DescriptorSize = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "DS_AUTO"] = 0;
    values[valuesById[1] = "DS_1"] = 1;
    values[valuesById[2] = "DS_2"] = 2;
    values[valuesById[4] = "DS_4"] = 4;
    values[valuesById[8] = "DS_8"] = 8;
    return values;
})();

$root.NanoPBOptions = (function() {

    /**
     * Properties of a NanoPBOptions.
     * @exports INanoPBOptions
     * @interface INanoPBOptions
     * @property {number|null} [maxSize] NanoPBOptions maxSize
     * @property {number|null} [maxLength] NanoPBOptions maxLength
     * @property {number|null} [maxCount] NanoPBOptions maxCount
     * @property {IntSize|null} [intSize] NanoPBOptions intSize
     * @property {FieldType|null} [type] NanoPBOptions type
     * @property {boolean|null} [longNames] NanoPBOptions longNames
     * @property {boolean|null} [packedStruct] NanoPBOptions packedStruct
     * @property {boolean|null} [packedEnum] NanoPBOptions packedEnum
     * @property {boolean|null} [skipMessage] NanoPBOptions skipMessage
     * @property {boolean|null} [noUnions] NanoPBOptions noUnions
     * @property {number|null} [msgid] NanoPBOptions msgid
     * @property {boolean|null} [anonymousOneof] NanoPBOptions anonymousOneof
     * @property {boolean|null} [proto3] NanoPBOptions proto3
     * @property {boolean|null} [proto3SingularMsgs] NanoPBOptions proto3SingularMsgs
     * @property {boolean|null} [enumToString] NanoPBOptions enumToString
     * @property {boolean|null} [fixedLength] NanoPBOptions fixedLength
     * @property {boolean|null} [fixedCount] NanoPBOptions fixedCount
     * @property {boolean|null} [submsgCallback] NanoPBOptions submsgCallback
     * @property {TypenameMangling|null} [mangleNames] NanoPBOptions mangleNames
     * @property {string|null} [callbackDatatype] NanoPBOptions callbackDatatype
     * @property {string|null} [callbackFunction] NanoPBOptions callbackFunction
     * @property {DescriptorSize|null} [descriptorsize] NanoPBOptions descriptorsize
     * @property {boolean|null} [defaultHas] NanoPBOptions defaultHas
     * @property {Array.<string>|null} [include] NanoPBOptions include
     * @property {Array.<string>|null} [exclude] NanoPBOptions exclude
     * @property {string|null} ["package"] NanoPBOptions package
     * @property {google.protobuf.FieldDescriptorProto.Type|null} [typeOverride] NanoPBOptions typeOverride
     * @property {boolean|null} [sortByTag] NanoPBOptions sortByTag
     * @property {FieldType|null} [fallbackType] NanoPBOptions fallbackType
     * @property {string|null} [initializer] NanoPBOptions initializer
     */

    /**
     * Constructs a new NanoPBOptions.
     * @exports NanoPBOptions
     * @classdesc Represents a NanoPBOptions.
     * @implements INanoPBOptions
     * @constructor
     * @param {INanoPBOptions=} [properties] Properties to set
     */
    function NanoPBOptions(properties) {
        this.include = [];
        this.exclude = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NanoPBOptions maxSize.
     * @member {number} maxSize
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.maxSize = 0;

    /**
     * NanoPBOptions maxLength.
     * @member {number} maxLength
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.maxLength = 0;

    /**
     * NanoPBOptions maxCount.
     * @member {number} maxCount
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.maxCount = 0;

    /**
     * NanoPBOptions intSize.
     * @member {IntSize} intSize
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.intSize = 0;

    /**
     * NanoPBOptions type.
     * @member {FieldType} type
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.type = 0;

    /**
     * NanoPBOptions longNames.
     * @member {boolean} longNames
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.longNames = true;

    /**
     * NanoPBOptions packedStruct.
     * @member {boolean} packedStruct
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.packedStruct = false;

    /**
     * NanoPBOptions packedEnum.
     * @member {boolean} packedEnum
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.packedEnum = false;

    /**
     * NanoPBOptions skipMessage.
     * @member {boolean} skipMessage
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.skipMessage = false;

    /**
     * NanoPBOptions noUnions.
     * @member {boolean} noUnions
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.noUnions = false;

    /**
     * NanoPBOptions msgid.
     * @member {number} msgid
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.msgid = 0;

    /**
     * NanoPBOptions anonymousOneof.
     * @member {boolean} anonymousOneof
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.anonymousOneof = false;

    /**
     * NanoPBOptions proto3.
     * @member {boolean} proto3
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.proto3 = false;

    /**
     * NanoPBOptions proto3SingularMsgs.
     * @member {boolean} proto3SingularMsgs
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.proto3SingularMsgs = false;

    /**
     * NanoPBOptions enumToString.
     * @member {boolean} enumToString
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.enumToString = false;

    /**
     * NanoPBOptions fixedLength.
     * @member {boolean} fixedLength
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.fixedLength = false;

    /**
     * NanoPBOptions fixedCount.
     * @member {boolean} fixedCount
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.fixedCount = false;

    /**
     * NanoPBOptions submsgCallback.
     * @member {boolean} submsgCallback
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.submsgCallback = false;

    /**
     * NanoPBOptions mangleNames.
     * @member {TypenameMangling} mangleNames
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.mangleNames = 0;

    /**
     * NanoPBOptions callbackDatatype.
     * @member {string} callbackDatatype
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.callbackDatatype = "pb_callback_t";

    /**
     * NanoPBOptions callbackFunction.
     * @member {string} callbackFunction
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.callbackFunction = "pb_default_field_callback";

    /**
     * NanoPBOptions descriptorsize.
     * @member {DescriptorSize} descriptorsize
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.descriptorsize = 0;

    /**
     * NanoPBOptions defaultHas.
     * @member {boolean} defaultHas
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.defaultHas = false;

    /**
     * NanoPBOptions include.
     * @member {Array.<string>} include
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.include = $util.emptyArray;

    /**
     * NanoPBOptions exclude.
     * @member {Array.<string>} exclude
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.exclude = $util.emptyArray;

    /**
     * NanoPBOptions package.
     * @member {string} package
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype["package"] = "";

    /**
     * NanoPBOptions typeOverride.
     * @member {google.protobuf.FieldDescriptorProto.Type} typeOverride
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.typeOverride = 1;

    /**
     * NanoPBOptions sortByTag.
     * @member {boolean} sortByTag
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.sortByTag = true;

    /**
     * NanoPBOptions fallbackType.
     * @member {FieldType} fallbackType
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.fallbackType = 1;

    /**
     * NanoPBOptions initializer.
     * @member {string} initializer
     * @memberof NanoPBOptions
     * @instance
     */
    NanoPBOptions.prototype.initializer = "";

    /**
     * Creates a new NanoPBOptions instance using the specified properties.
     * @function create
     * @memberof NanoPBOptions
     * @static
     * @param {INanoPBOptions=} [properties] Properties to set
     * @returns {NanoPBOptions} NanoPBOptions instance
     */
    NanoPBOptions.create = function create(properties) {
        return new NanoPBOptions(properties);
    };

    /**
     * Encodes the specified NanoPBOptions message. Does not implicitly {@link NanoPBOptions.verify|verify} messages.
     * @function encode
     * @memberof NanoPBOptions
     * @static
     * @param {INanoPBOptions} message NanoPBOptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NanoPBOptions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.maxSize != null && Object.hasOwnProperty.call(message, "maxSize"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxSize);
        if (message.maxCount != null && Object.hasOwnProperty.call(message, "maxCount"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxCount);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
        if (message.longNames != null && Object.hasOwnProperty.call(message, "longNames"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.longNames);
        if (message.packedStruct != null && Object.hasOwnProperty.call(message, "packedStruct"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.packedStruct);
        if (message.skipMessage != null && Object.hasOwnProperty.call(message, "skipMessage"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.skipMessage);
        if (message.intSize != null && Object.hasOwnProperty.call(message, "intSize"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.intSize);
        if (message.noUnions != null && Object.hasOwnProperty.call(message, "noUnions"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.noUnions);
        if (message.msgid != null && Object.hasOwnProperty.call(message, "msgid"))
            writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.msgid);
        if (message.packedEnum != null && Object.hasOwnProperty.call(message, "packedEnum"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.packedEnum);
        if (message.anonymousOneof != null && Object.hasOwnProperty.call(message, "anonymousOneof"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.anonymousOneof);
        if (message.proto3 != null && Object.hasOwnProperty.call(message, "proto3"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.proto3);
        if (message.enumToString != null && Object.hasOwnProperty.call(message, "enumToString"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.enumToString);
        if (message.maxLength != null && Object.hasOwnProperty.call(message, "maxLength"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.maxLength);
        if (message.fixedLength != null && Object.hasOwnProperty.call(message, "fixedLength"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.fixedLength);
        if (message.fixedCount != null && Object.hasOwnProperty.call(message, "fixedCount"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.fixedCount);
        if (message.mangleNames != null && Object.hasOwnProperty.call(message, "mangleNames"))
            writer.uint32(/* id 17, wireType 0 =*/136).int32(message.mangleNames);
        if (message.callbackDatatype != null && Object.hasOwnProperty.call(message, "callbackDatatype"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.callbackDatatype);
        if (message.callbackFunction != null && Object.hasOwnProperty.call(message, "callbackFunction"))
            writer.uint32(/* id 19, wireType 2 =*/154).string(message.callbackFunction);
        if (message.descriptorsize != null && Object.hasOwnProperty.call(message, "descriptorsize"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.descriptorsize);
        if (message.proto3SingularMsgs != null && Object.hasOwnProperty.call(message, "proto3SingularMsgs"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.proto3SingularMsgs);
        if (message.submsgCallback != null && Object.hasOwnProperty.call(message, "submsgCallback"))
            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.submsgCallback);
        if (message.defaultHas != null && Object.hasOwnProperty.call(message, "defaultHas"))
            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.defaultHas);
        if (message.include != null && message.include.length)
            for (var i = 0; i < message.include.length; ++i)
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.include[i]);
        if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message["package"]);
        if (message.exclude != null && message.exclude.length)
            for (var i = 0; i < message.exclude.length; ++i)
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.exclude[i]);
        if (message.typeOverride != null && Object.hasOwnProperty.call(message, "typeOverride"))
            writer.uint32(/* id 27, wireType 0 =*/216).int32(message.typeOverride);
        if (message.sortByTag != null && Object.hasOwnProperty.call(message, "sortByTag"))
            writer.uint32(/* id 28, wireType 0 =*/224).bool(message.sortByTag);
        if (message.fallbackType != null && Object.hasOwnProperty.call(message, "fallbackType"))
            writer.uint32(/* id 29, wireType 0 =*/232).int32(message.fallbackType);
        if (message.initializer != null && Object.hasOwnProperty.call(message, "initializer"))
            writer.uint32(/* id 30, wireType 2 =*/242).string(message.initializer);
        return writer;
    };

    /**
     * Encodes the specified NanoPBOptions message, length delimited. Does not implicitly {@link NanoPBOptions.verify|verify} messages.
     * @function encodeDelimited
     * @memberof NanoPBOptions
     * @static
     * @param {INanoPBOptions} message NanoPBOptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NanoPBOptions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NanoPBOptions message from the specified reader or buffer.
     * @function decode
     * @memberof NanoPBOptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NanoPBOptions} NanoPBOptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NanoPBOptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.NanoPBOptions();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.maxSize = reader.int32();
                    break;
                }
            case 14: {
                    message.maxLength = reader.int32();
                    break;
                }
            case 2: {
                    message.maxCount = reader.int32();
                    break;
                }
            case 7: {
                    message.intSize = reader.int32();
                    break;
                }
            case 3: {
                    message.type = reader.int32();
                    break;
                }
            case 4: {
                    message.longNames = reader.bool();
                    break;
                }
            case 5: {
                    message.packedStruct = reader.bool();
                    break;
                }
            case 10: {
                    message.packedEnum = reader.bool();
                    break;
                }
            case 6: {
                    message.skipMessage = reader.bool();
                    break;
                }
            case 8: {
                    message.noUnions = reader.bool();
                    break;
                }
            case 9: {
                    message.msgid = reader.uint32();
                    break;
                }
            case 11: {
                    message.anonymousOneof = reader.bool();
                    break;
                }
            case 12: {
                    message.proto3 = reader.bool();
                    break;
                }
            case 21: {
                    message.proto3SingularMsgs = reader.bool();
                    break;
                }
            case 13: {
                    message.enumToString = reader.bool();
                    break;
                }
            case 15: {
                    message.fixedLength = reader.bool();
                    break;
                }
            case 16: {
                    message.fixedCount = reader.bool();
                    break;
                }
            case 22: {
                    message.submsgCallback = reader.bool();
                    break;
                }
            case 17: {
                    message.mangleNames = reader.int32();
                    break;
                }
            case 18: {
                    message.callbackDatatype = reader.string();
                    break;
                }
            case 19: {
                    message.callbackFunction = reader.string();
                    break;
                }
            case 20: {
                    message.descriptorsize = reader.int32();
                    break;
                }
            case 23: {
                    message.defaultHas = reader.bool();
                    break;
                }
            case 24: {
                    if (!(message.include && message.include.length))
                        message.include = [];
                    message.include.push(reader.string());
                    break;
                }
            case 26: {
                    if (!(message.exclude && message.exclude.length))
                        message.exclude = [];
                    message.exclude.push(reader.string());
                    break;
                }
            case 25: {
                    message["package"] = reader.string();
                    break;
                }
            case 27: {
                    message.typeOverride = reader.int32();
                    break;
                }
            case 28: {
                    message.sortByTag = reader.bool();
                    break;
                }
            case 29: {
                    message.fallbackType = reader.int32();
                    break;
                }
            case 30: {
                    message.initializer = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a NanoPBOptions message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof NanoPBOptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {NanoPBOptions} NanoPBOptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NanoPBOptions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NanoPBOptions message.
     * @function verify
     * @memberof NanoPBOptions
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NanoPBOptions.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.maxSize != null && message.hasOwnProperty("maxSize"))
            if (!$util.isInteger(message.maxSize))
                return "maxSize: integer expected";
        if (message.maxLength != null && message.hasOwnProperty("maxLength"))
            if (!$util.isInteger(message.maxLength))
                return "maxLength: integer expected";
        if (message.maxCount != null && message.hasOwnProperty("maxCount"))
            if (!$util.isInteger(message.maxCount))
                return "maxCount: integer expected";
        if (message.intSize != null && message.hasOwnProperty("intSize"))
            switch (message.intSize) {
            default:
                return "intSize: enum value expected";
            case 0:
            case 8:
            case 16:
            case 32:
            case 64:
                break;
            }
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 4:
            case 2:
            case 3:
            case 5:
                break;
            }
        if (message.longNames != null && message.hasOwnProperty("longNames"))
            if (typeof message.longNames !== "boolean")
                return "longNames: boolean expected";
        if (message.packedStruct != null && message.hasOwnProperty("packedStruct"))
            if (typeof message.packedStruct !== "boolean")
                return "packedStruct: boolean expected";
        if (message.packedEnum != null && message.hasOwnProperty("packedEnum"))
            if (typeof message.packedEnum !== "boolean")
                return "packedEnum: boolean expected";
        if (message.skipMessage != null && message.hasOwnProperty("skipMessage"))
            if (typeof message.skipMessage !== "boolean")
                return "skipMessage: boolean expected";
        if (message.noUnions != null && message.hasOwnProperty("noUnions"))
            if (typeof message.noUnions !== "boolean")
                return "noUnions: boolean expected";
        if (message.msgid != null && message.hasOwnProperty("msgid"))
            if (!$util.isInteger(message.msgid))
                return "msgid: integer expected";
        if (message.anonymousOneof != null && message.hasOwnProperty("anonymousOneof"))
            if (typeof message.anonymousOneof !== "boolean")
                return "anonymousOneof: boolean expected";
        if (message.proto3 != null && message.hasOwnProperty("proto3"))
            if (typeof message.proto3 !== "boolean")
                return "proto3: boolean expected";
        if (message.proto3SingularMsgs != null && message.hasOwnProperty("proto3SingularMsgs"))
            if (typeof message.proto3SingularMsgs !== "boolean")
                return "proto3SingularMsgs: boolean expected";
        if (message.enumToString != null && message.hasOwnProperty("enumToString"))
            if (typeof message.enumToString !== "boolean")
                return "enumToString: boolean expected";
        if (message.fixedLength != null && message.hasOwnProperty("fixedLength"))
            if (typeof message.fixedLength !== "boolean")
                return "fixedLength: boolean expected";
        if (message.fixedCount != null && message.hasOwnProperty("fixedCount"))
            if (typeof message.fixedCount !== "boolean")
                return "fixedCount: boolean expected";
        if (message.submsgCallback != null && message.hasOwnProperty("submsgCallback"))
            if (typeof message.submsgCallback !== "boolean")
                return "submsgCallback: boolean expected";
        if (message.mangleNames != null && message.hasOwnProperty("mangleNames"))
            switch (message.mangleNames) {
            default:
                return "mangleNames: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.callbackDatatype != null && message.hasOwnProperty("callbackDatatype"))
            if (!$util.isString(message.callbackDatatype))
                return "callbackDatatype: string expected";
        if (message.callbackFunction != null && message.hasOwnProperty("callbackFunction"))
            if (!$util.isString(message.callbackFunction))
                return "callbackFunction: string expected";
        if (message.descriptorsize != null && message.hasOwnProperty("descriptorsize"))
            switch (message.descriptorsize) {
            default:
                return "descriptorsize: enum value expected";
            case 0:
            case 1:
            case 2:
            case 4:
            case 8:
                break;
            }
        if (message.defaultHas != null && message.hasOwnProperty("defaultHas"))
            if (typeof message.defaultHas !== "boolean")
                return "defaultHas: boolean expected";
        if (message.include != null && message.hasOwnProperty("include")) {
            if (!Array.isArray(message.include))
                return "include: array expected";
            for (var i = 0; i < message.include.length; ++i)
                if (!$util.isString(message.include[i]))
                    return "include: string[] expected";
        }
        if (message.exclude != null && message.hasOwnProperty("exclude")) {
            if (!Array.isArray(message.exclude))
                return "exclude: array expected";
            for (var i = 0; i < message.exclude.length; ++i)
                if (!$util.isString(message.exclude[i]))
                    return "exclude: string[] expected";
        }
        if (message["package"] != null && message.hasOwnProperty("package"))
            if (!$util.isString(message["package"]))
                return "package: string expected";
        if (message.typeOverride != null && message.hasOwnProperty("typeOverride"))
            switch (message.typeOverride) {
            default:
                return "typeOverride: enum value expected";
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
                break;
            }
        if (message.sortByTag != null && message.hasOwnProperty("sortByTag"))
            if (typeof message.sortByTag !== "boolean")
                return "sortByTag: boolean expected";
        if (message.fallbackType != null && message.hasOwnProperty("fallbackType"))
            switch (message.fallbackType) {
            default:
                return "fallbackType: enum value expected";
            case 0:
            case 1:
            case 4:
            case 2:
            case 3:
            case 5:
                break;
            }
        if (message.initializer != null && message.hasOwnProperty("initializer"))
            if (!$util.isString(message.initializer))
                return "initializer: string expected";
        return null;
    };

    /**
     * Creates a NanoPBOptions message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof NanoPBOptions
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {NanoPBOptions} NanoPBOptions
     */
    NanoPBOptions.fromObject = function fromObject(object) {
        if (object instanceof $root.NanoPBOptions)
            return object;
        var message = new $root.NanoPBOptions();
        if (object.maxSize != null)
            message.maxSize = object.maxSize | 0;
        if (object.maxLength != null)
            message.maxLength = object.maxLength | 0;
        if (object.maxCount != null)
            message.maxCount = object.maxCount | 0;
        switch (object.intSize) {
        default:
            if (typeof object.intSize === "number") {
                message.intSize = object.intSize;
                break;
            }
            break;
        case "IS_DEFAULT":
        case 0:
            message.intSize = 0;
            break;
        case "IS_8":
        case 8:
            message.intSize = 8;
            break;
        case "IS_16":
        case 16:
            message.intSize = 16;
            break;
        case "IS_32":
        case 32:
            message.intSize = 32;
            break;
        case "IS_64":
        case 64:
            message.intSize = 64;
            break;
        }
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "FT_DEFAULT":
        case 0:
            message.type = 0;
            break;
        case "FT_CALLBACK":
        case 1:
            message.type = 1;
            break;
        case "FT_POINTER":
        case 4:
            message.type = 4;
            break;
        case "FT_STATIC":
        case 2:
            message.type = 2;
            break;
        case "FT_IGNORE":
        case 3:
            message.type = 3;
            break;
        case "FT_INLINE":
        case 5:
            message.type = 5;
            break;
        }
        if (object.longNames != null)
            message.longNames = Boolean(object.longNames);
        if (object.packedStruct != null)
            message.packedStruct = Boolean(object.packedStruct);
        if (object.packedEnum != null)
            message.packedEnum = Boolean(object.packedEnum);
        if (object.skipMessage != null)
            message.skipMessage = Boolean(object.skipMessage);
        if (object.noUnions != null)
            message.noUnions = Boolean(object.noUnions);
        if (object.msgid != null)
            message.msgid = object.msgid >>> 0;
        if (object.anonymousOneof != null)
            message.anonymousOneof = Boolean(object.anonymousOneof);
        if (object.proto3 != null)
            message.proto3 = Boolean(object.proto3);
        if (object.proto3SingularMsgs != null)
            message.proto3SingularMsgs = Boolean(object.proto3SingularMsgs);
        if (object.enumToString != null)
            message.enumToString = Boolean(object.enumToString);
        if (object.fixedLength != null)
            message.fixedLength = Boolean(object.fixedLength);
        if (object.fixedCount != null)
            message.fixedCount = Boolean(object.fixedCount);
        if (object.submsgCallback != null)
            message.submsgCallback = Boolean(object.submsgCallback);
        switch (object.mangleNames) {
        default:
            if (typeof object.mangleNames === "number") {
                message.mangleNames = object.mangleNames;
                break;
            }
            break;
        case "M_NONE":
        case 0:
            message.mangleNames = 0;
            break;
        case "M_STRIP_PACKAGE":
        case 1:
            message.mangleNames = 1;
            break;
        case "M_FLATTEN":
        case 2:
            message.mangleNames = 2;
            break;
        case "M_PACKAGE_INITIALS":
        case 3:
            message.mangleNames = 3;
            break;
        }
        if (object.callbackDatatype != null)
            message.callbackDatatype = String(object.callbackDatatype);
        if (object.callbackFunction != null)
            message.callbackFunction = String(object.callbackFunction);
        switch (object.descriptorsize) {
        default:
            if (typeof object.descriptorsize === "number") {
                message.descriptorsize = object.descriptorsize;
                break;
            }
            break;
        case "DS_AUTO":
        case 0:
            message.descriptorsize = 0;
            break;
        case "DS_1":
        case 1:
            message.descriptorsize = 1;
            break;
        case "DS_2":
        case 2:
            message.descriptorsize = 2;
            break;
        case "DS_4":
        case 4:
            message.descriptorsize = 4;
            break;
        case "DS_8":
        case 8:
            message.descriptorsize = 8;
            break;
        }
        if (object.defaultHas != null)
            message.defaultHas = Boolean(object.defaultHas);
        if (object.include) {
            if (!Array.isArray(object.include))
                throw TypeError(".NanoPBOptions.include: array expected");
            message.include = [];
            for (var i = 0; i < object.include.length; ++i)
                message.include[i] = String(object.include[i]);
        }
        if (object.exclude) {
            if (!Array.isArray(object.exclude))
                throw TypeError(".NanoPBOptions.exclude: array expected");
            message.exclude = [];
            for (var i = 0; i < object.exclude.length; ++i)
                message.exclude[i] = String(object.exclude[i]);
        }
        if (object["package"] != null)
            message["package"] = String(object["package"]);
        switch (object.typeOverride) {
        default:
            if (typeof object.typeOverride === "number") {
                message.typeOverride = object.typeOverride;
                break;
            }
            break;
        case "TYPE_DOUBLE":
        case 1:
            message.typeOverride = 1;
            break;
        case "TYPE_FLOAT":
        case 2:
            message.typeOverride = 2;
            break;
        case "TYPE_INT64":
        case 3:
            message.typeOverride = 3;
            break;
        case "TYPE_UINT64":
        case 4:
            message.typeOverride = 4;
            break;
        case "TYPE_INT32":
        case 5:
            message.typeOverride = 5;
            break;
        case "TYPE_FIXED64":
        case 6:
            message.typeOverride = 6;
            break;
        case "TYPE_FIXED32":
        case 7:
            message.typeOverride = 7;
            break;
        case "TYPE_BOOL":
        case 8:
            message.typeOverride = 8;
            break;
        case "TYPE_STRING":
        case 9:
            message.typeOverride = 9;
            break;
        case "TYPE_GROUP":
        case 10:
            message.typeOverride = 10;
            break;
        case "TYPE_MESSAGE":
        case 11:
            message.typeOverride = 11;
            break;
        case "TYPE_BYTES":
        case 12:
            message.typeOverride = 12;
            break;
        case "TYPE_UINT32":
        case 13:
            message.typeOverride = 13;
            break;
        case "TYPE_ENUM":
        case 14:
            message.typeOverride = 14;
            break;
        case "TYPE_SFIXED32":
        case 15:
            message.typeOverride = 15;
            break;
        case "TYPE_SFIXED64":
        case 16:
            message.typeOverride = 16;
            break;
        case "TYPE_SINT32":
        case 17:
            message.typeOverride = 17;
            break;
        case "TYPE_SINT64":
        case 18:
            message.typeOverride = 18;
            break;
        }
        if (object.sortByTag != null)
            message.sortByTag = Boolean(object.sortByTag);
        switch (object.fallbackType) {
        case "FT_DEFAULT":
        case 0:
            message.fallbackType = 0;
            break;
        default:
            if (typeof object.fallbackType === "number") {
                message.fallbackType = object.fallbackType;
                break;
            }
            break;
        case "FT_CALLBACK":
        case 1:
            message.fallbackType = 1;
            break;
        case "FT_POINTER":
        case 4:
            message.fallbackType = 4;
            break;
        case "FT_STATIC":
        case 2:
            message.fallbackType = 2;
            break;
        case "FT_IGNORE":
        case 3:
            message.fallbackType = 3;
            break;
        case "FT_INLINE":
        case 5:
            message.fallbackType = 5;
            break;
        }
        if (object.initializer != null)
            message.initializer = String(object.initializer);
        return message;
    };

    /**
     * Creates a plain object from a NanoPBOptions message. Also converts values to other types if specified.
     * @function toObject
     * @memberof NanoPBOptions
     * @static
     * @param {NanoPBOptions} message NanoPBOptions
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NanoPBOptions.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults) {
            object.include = [];
            object.exclude = [];
        }
        if (options.defaults) {
            object.maxSize = 0;
            object.maxCount = 0;
            object.type = options.enums === String ? "FT_DEFAULT" : 0;
            object.longNames = true;
            object.packedStruct = false;
            object.skipMessage = false;
            object.intSize = options.enums === String ? "IS_DEFAULT" : 0;
            object.noUnions = false;
            object.msgid = 0;
            object.packedEnum = false;
            object.anonymousOneof = false;
            object.proto3 = false;
            object.enumToString = false;
            object.maxLength = 0;
            object.fixedLength = false;
            object.fixedCount = false;
            object.mangleNames = options.enums === String ? "M_NONE" : 0;
            object.callbackDatatype = "pb_callback_t";
            object.callbackFunction = "pb_default_field_callback";
            object.descriptorsize = options.enums === String ? "DS_AUTO" : 0;
            object.proto3SingularMsgs = false;
            object.submsgCallback = false;
            object.defaultHas = false;
            object["package"] = "";
            object.typeOverride = options.enums === String ? "TYPE_DOUBLE" : 1;
            object.sortByTag = true;
            object.fallbackType = options.enums === String ? "FT_CALLBACK" : 1;
            object.initializer = "";
        }
        if (message.maxSize != null && message.hasOwnProperty("maxSize"))
            object.maxSize = message.maxSize;
        if (message.maxCount != null && message.hasOwnProperty("maxCount"))
            object.maxCount = message.maxCount;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.FieldType[message.type] === undefined ? message.type : $root.FieldType[message.type] : message.type;
        if (message.longNames != null && message.hasOwnProperty("longNames"))
            object.longNames = message.longNames;
        if (message.packedStruct != null && message.hasOwnProperty("packedStruct"))
            object.packedStruct = message.packedStruct;
        if (message.skipMessage != null && message.hasOwnProperty("skipMessage"))
            object.skipMessage = message.skipMessage;
        if (message.intSize != null && message.hasOwnProperty("intSize"))
            object.intSize = options.enums === String ? $root.IntSize[message.intSize] === undefined ? message.intSize : $root.IntSize[message.intSize] : message.intSize;
        if (message.noUnions != null && message.hasOwnProperty("noUnions"))
            object.noUnions = message.noUnions;
        if (message.msgid != null && message.hasOwnProperty("msgid"))
            object.msgid = message.msgid;
        if (message.packedEnum != null && message.hasOwnProperty("packedEnum"))
            object.packedEnum = message.packedEnum;
        if (message.anonymousOneof != null && message.hasOwnProperty("anonymousOneof"))
            object.anonymousOneof = message.anonymousOneof;
        if (message.proto3 != null && message.hasOwnProperty("proto3"))
            object.proto3 = message.proto3;
        if (message.enumToString != null && message.hasOwnProperty("enumToString"))
            object.enumToString = message.enumToString;
        if (message.maxLength != null && message.hasOwnProperty("maxLength"))
            object.maxLength = message.maxLength;
        if (message.fixedLength != null && message.hasOwnProperty("fixedLength"))
            object.fixedLength = message.fixedLength;
        if (message.fixedCount != null && message.hasOwnProperty("fixedCount"))
            object.fixedCount = message.fixedCount;
        if (message.mangleNames != null && message.hasOwnProperty("mangleNames"))
            object.mangleNames = options.enums === String ? $root.TypenameMangling[message.mangleNames] === undefined ? message.mangleNames : $root.TypenameMangling[message.mangleNames] : message.mangleNames;
        if (message.callbackDatatype != null && message.hasOwnProperty("callbackDatatype"))
            object.callbackDatatype = message.callbackDatatype;
        if (message.callbackFunction != null && message.hasOwnProperty("callbackFunction"))
            object.callbackFunction = message.callbackFunction;
        if (message.descriptorsize != null && message.hasOwnProperty("descriptorsize"))
            object.descriptorsize = options.enums === String ? $root.DescriptorSize[message.descriptorsize] === undefined ? message.descriptorsize : $root.DescriptorSize[message.descriptorsize] : message.descriptorsize;
        if (message.proto3SingularMsgs != null && message.hasOwnProperty("proto3SingularMsgs"))
            object.proto3SingularMsgs = message.proto3SingularMsgs;
        if (message.submsgCallback != null && message.hasOwnProperty("submsgCallback"))
            object.submsgCallback = message.submsgCallback;
        if (message.defaultHas != null && message.hasOwnProperty("defaultHas"))
            object.defaultHas = message.defaultHas;
        if (message.include && message.include.length) {
            object.include = [];
            for (var j = 0; j < message.include.length; ++j)
                object.include[j] = message.include[j];
        }
        if (message["package"] != null && message.hasOwnProperty("package"))
            object["package"] = message["package"];
        if (message.exclude && message.exclude.length) {
            object.exclude = [];
            for (var j = 0; j < message.exclude.length; ++j)
                object.exclude[j] = message.exclude[j];
        }
        if (message.typeOverride != null && message.hasOwnProperty("typeOverride"))
            object.typeOverride = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.typeOverride] === undefined ? message.typeOverride : $root.google.protobuf.FieldDescriptorProto.Type[message.typeOverride] : message.typeOverride;
        if (message.sortByTag != null && message.hasOwnProperty("sortByTag"))
            object.sortByTag = message.sortByTag;
        if (message.fallbackType != null && message.hasOwnProperty("fallbackType"))
            object.fallbackType = options.enums === String ? $root.FieldType[message.fallbackType] === undefined ? message.fallbackType : $root.FieldType[message.fallbackType] : message.fallbackType;
        if (message.initializer != null && message.hasOwnProperty("initializer"))
            object.initializer = message.initializer;
        return object;
    };

    /**
     * Converts this NanoPBOptions to JSON.
     * @function toJSON
     * @memberof NanoPBOptions
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NanoPBOptions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NanoPBOptions
     * @function getTypeUrl
     * @memberof NanoPBOptions
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NanoPBOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/NanoPBOptions";
    };

    return NanoPBOptions;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file != null && message.file.length)
                    for (var i = 0; i < message.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.file && message.file.length))
                                message.file = [];
                            message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (var i = 0; i < message.file.length; ++i) {
                        var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (var i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (var j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorSet
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorSet";
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                if (message.dependency != null && message.dependency.length)
                    for (var i = 0; i < message.dependency.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                if (message.messageType != null && message.messageType.length)
                    for (var i = 0; i < message.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.service != null && message.service.length)
                    for (var i = 0; i < message.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.publicDependency != null && message.publicDependency.length)
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                if (message.weakDependency != null && message.weakDependency.length)
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message["package"] = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.dependency && message.dependency.length))
                                message.dependency = [];
                            message.dependency.push(reader.string());
                            break;
                        }
                    case 10: {
                            if (!(message.publicDependency && message.publicDependency.length))
                                message.publicDependency = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.publicDependency.push(reader.int32());
                            } else
                                message.publicDependency.push(reader.int32());
                            break;
                        }
                    case 11: {
                            if (!(message.weakDependency && message.weakDependency.length))
                                message.weakDependency = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.weakDependency.push(reader.int32());
                            } else
                                message.weakDependency.push(reader.int32());
                            break;
                        }
                    case 4: {
                            if (!(message.messageType && message.messageType.length))
                                message.messageType = [];
                            message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.service && message.service.length))
                                message.service = [];
                            message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.syntax = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (var i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (var i = 0; i < message.messageType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (var i = 0; i < message.service.length; ++i) {
                        var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (var i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (var i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (var i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (var i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (var i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (var j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (var j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (var j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (var j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (var j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorProto";
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.field != null && message.field.length)
                    for (var i = 0; i < message.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.nestedType != null && message.nestedType.length)
                    for (var i = 0; i < message.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.extensionRange != null && message.extensionRange.length)
                    for (var i = 0; i < message.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.oneofDecl != null && message.oneofDecl.length)
                    for (var i = 0; i < message.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (var i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (var i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.field && message.field.length))
                                message.field = [];
                            message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.nestedType && message.nestedType.length))
                                message.nestedType = [];
                            message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.extensionRange && message.extensionRange.length))
                                message.extensionRange = [];
                            message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            if (!(message.oneofDecl && message.oneofDecl.length))
                                message.oneofDecl = [];
                            message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            if (!(message.reservedRange && message.reservedRange.length))
                                message.reservedRange = [];
                            message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            if (!(message.reservedName && message.reservedName.length))
                                message.reservedName = [];
                            message.reservedName.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (var i = 0; i < message.field.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (var i = 0; i < message.nestedType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (var i = 0; i < message.extensionRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (var i = 0; i < message.oneofDecl.length; ++i) {
                        var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (var i = 0; i < message.reservedRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (var i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                var message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (var i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (var i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (var i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (var i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (var i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (var i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (var j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (var j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (var j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (var j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (var j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (var j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DescriptorProto";
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ExtensionRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExtensionRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ExtensionRange";
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ReservedRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReservedRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ReservedRange";
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                return writer;
            };

            /**
             * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.number = reader.int32();
                            break;
                        }
                    case 4: {
                            message.label = reader.int32();
                            break;
                        }
                    case 5: {
                            message.type = reader.int32();
                            break;
                        }
                    case 6: {
                            message.typeName = reader.string();
                            break;
                        }
                    case 2: {
                            message.extendee = reader.string();
                            break;
                        }
                    case 7: {
                            message.defaultValue = reader.string();
                            break;
                        }
                    case 9: {
                            message.oneofIndex = reader.int32();
                            break;
                        }
                    case 10: {
                            message.jsonName = reader.string();
                            break;
                        }
                    case 8: {
                            message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                default:
                    if (typeof object.label === "number") {
                        message.label = object.label;
                        break;
                    }
                    break;
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] === undefined ? message.label : $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] === undefined ? message.type : $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldDescriptorProto";
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofDescriptorProto";
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && message.value.length)
                    for (var i = 0; i < message.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.value && message.value.length))
                                message.value = [];
                            message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (var i = 0; i < message.value.length; ++i) {
                        var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (var i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (var j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumDescriptorProto";
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.number = reader.int32();
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueDescriptorProto";
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.method != null && message.method.length)
                    for (var i = 0; i < message.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.method && message.method.length))
                                message.method = [];
                            message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (var i = 0; i < message.method.length; ++i) {
                        var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (var i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (var j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceDescriptorProto";
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                return writer;
            };

            /**
             * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.inputType = reader.string();
                            break;
                        }
                    case 3: {
                            message.outputType = reader.string();
                            break;
                        }
                    case 4: {
                            message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.clientStreaming = reader.bool();
                            break;
                        }
                    case 6: {
                            message.serverStreaming = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodDescriptorProto";
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopbFileopt"] FileOptions .nanopbFileopt
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FileOptions .nanopbFileopt.
             * @member {INanoPBOptions|null|undefined} .nanopbFileopt
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype[".nanopbFileopt"] = null;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                    writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopbFileopt"] != null && Object.hasOwnProperty.call(message, ".nanopbFileopt"))
                    $root.NanoPBOptions.encode(message[".nanopbFileopt"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.javaPackage = reader.string();
                            break;
                        }
                    case 8: {
                            message.javaOuterClassname = reader.string();
                            break;
                        }
                    case 10: {
                            message.javaMultipleFiles = reader.bool();
                            break;
                        }
                    case 20: {
                            message.javaGenerateEqualsAndHash = reader.bool();
                            break;
                        }
                    case 27: {
                            message.javaStringCheckUtf8 = reader.bool();
                            break;
                        }
                    case 9: {
                            message.optimizeFor = reader.int32();
                            break;
                        }
                    case 11: {
                            message.goPackage = reader.string();
                            break;
                        }
                    case 16: {
                            message.ccGenericServices = reader.bool();
                            break;
                        }
                    case 17: {
                            message.javaGenericServices = reader.bool();
                            break;
                        }
                    case 18: {
                            message.pyGenericServices = reader.bool();
                            break;
                        }
                    case 23: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 31: {
                            message.ccEnableArenas = reader.bool();
                            break;
                        }
                    case 36: {
                            message.objcClassPrefix = reader.string();
                            break;
                        }
                    case 37: {
                            message.csharpNamespace = reader.string();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopbFileopt"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopbFileopt"] != null && message.hasOwnProperty(".nanopbFileopt")) {
                    var error = $root.NanoPBOptions.verify(message[".nanopbFileopt"]);
                    if (error)
                        return ".nanopbFileopt." + error;
                }
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                var message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                default:
                    if (typeof object.optimizeFor === "number") {
                        message.optimizeFor = object.optimizeFor;
                        break;
                    }
                    break;
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopbFileopt"] != null) {
                    if (typeof object[".nanopbFileopt"] !== "object")
                        throw TypeError(".google.protobuf.FileOptions..nanopbFileopt: object expected");
                    message[".nanopbFileopt"] = $root.NanoPBOptions.fromObject(object[".nanopbFileopt"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = false;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                    object[".nanopbFileopt"] = null;
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] === undefined ? message.optimizeFor : $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopbFileopt"] != null && message.hasOwnProperty(".nanopbFileopt"))
                    object[".nanopbFileopt"] = $root.NanoPBOptions.toObject(message[".nanopbFileopt"], options);
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileOptions";
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopbMsgopt"] MessageOptions .nanopbMsgopt
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MessageOptions .nanopbMsgopt.
             * @member {INanoPBOptions|null|undefined} .nanopbMsgopt
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype[".nanopbMsgopt"] = null;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopbMsgopt"] != null && Object.hasOwnProperty.call(message, ".nanopbMsgopt"))
                    $root.NanoPBOptions.encode(message[".nanopbMsgopt"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.messageSetWireFormat = reader.bool();
                            break;
                        }
                    case 2: {
                            message.noStandardDescriptorAccessor = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 7: {
                            message.mapEntry = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopbMsgopt"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopbMsgopt"] != null && message.hasOwnProperty(".nanopbMsgopt")) {
                    var error = $root.NanoPBOptions.verify(message[".nanopbMsgopt"]);
                    if (error)
                        return ".nanopbMsgopt." + error;
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                var message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopbMsgopt"] != null) {
                    if (typeof object[".nanopbMsgopt"] !== "object")
                        throw TypeError(".google.protobuf.MessageOptions..nanopbMsgopt: object expected");
                    message[".nanopbMsgopt"] = $root.NanoPBOptions.fromObject(object[".nanopbMsgopt"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                    object[".nanopbMsgopt"] = null;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopbMsgopt"] != null && message.hasOwnProperty(".nanopbMsgopt"))
                    object[".nanopbMsgopt"] = $root.NanoPBOptions.toObject(message[".nanopbMsgopt"], options);
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MessageOptions";
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopb"] FieldOptions .nanopb
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .nanopb.
             * @member {INanoPBOptions|null|undefined} .nanopb
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".nanopb"] = null;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopb"] != null && Object.hasOwnProperty.call(message, ".nanopb"))
                    $root.NanoPBOptions.encode(message[".nanopb"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.ctype = reader.int32();
                            break;
                        }
                    case 2: {
                            message.packed = reader.bool();
                            break;
                        }
                    case 6: {
                            message.jstype = reader.int32();
                            break;
                        }
                    case 5: {
                            message.lazy = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 10: {
                            message.weak = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopb"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopb"] != null && message.hasOwnProperty(".nanopb")) {
                    var error = $root.NanoPBOptions.verify(message[".nanopb"]);
                    if (error)
                        return ".nanopb." + error;
                }
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                var message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                default:
                    if (typeof object.ctype === "number") {
                        message.ctype = object.ctype;
                        break;
                    }
                    break;
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                default:
                    if (typeof object.jstype === "number") {
                        message.jstype = object.jstype;
                        break;
                    }
                    break;
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopb"] != null) {
                    if (typeof object[".nanopb"] !== "object")
                        throw TypeError(".google.protobuf.FieldOptions..nanopb: object expected");
                    message[".nanopb"] = $root.NanoPBOptions.fromObject(object[".nanopb"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object[".nanopb"] = null;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] === undefined ? message.ctype : $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] === undefined ? message.jstype : $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopb"] != null && message.hasOwnProperty(".nanopb"))
                    object[".nanopb"] = $root.NanoPBOptions.toObject(message[".nanopb"], options);
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldOptions";
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                var message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofOptions
             * @function getTypeUrl
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofOptions";
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             * @property {INanoPBOptions|null} [".nanopbEnumopt"] EnumOptions .nanopbEnumopt
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * EnumOptions .nanopbEnumopt.
             * @member {INanoPBOptions|null|undefined} .nanopbEnumopt
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype[".nanopbEnumopt"] = null;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".nanopbEnumopt"] != null && Object.hasOwnProperty.call(message, ".nanopbEnumopt"))
                    $root.NanoPBOptions.encode(message[".nanopbEnumopt"], writer.uint32(/* id 1010, wireType 2 =*/8082).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.allowAlias = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1010: {
                            message[".nanopbEnumopt"] = $root.NanoPBOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".nanopbEnumopt"] != null && message.hasOwnProperty(".nanopbEnumopt")) {
                    var error = $root.NanoPBOptions.verify(message[".nanopbEnumopt"]);
                    if (error)
                        return ".nanopbEnumopt." + error;
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                var message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".nanopbEnumopt"] != null) {
                    if (typeof object[".nanopbEnumopt"] !== "object")
                        throw TypeError(".google.protobuf.EnumOptions..nanopbEnumopt: object expected");
                    message[".nanopbEnumopt"] = $root.NanoPBOptions.fromObject(object[".nanopbEnumopt"]);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                    object[".nanopbEnumopt"] = null;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".nanopbEnumopt"] != null && message.hasOwnProperty(".nanopbEnumopt"))
                    object[".nanopbEnumopt"] = $root.NanoPBOptions.toObject(message[".nanopbEnumopt"], options);
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumOptions";
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                var message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueOptions";
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                var message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceOptions
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceOptions";
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                var message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodOptions";
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number|Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number|Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.name.length)
                    for (var i = 0; i < message.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                return writer;
            };

            /**
             * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.name && message.name.length))
                                message.name = [];
                            message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.identifierValue = reader.string();
                            break;
                        }
                    case 4: {
                            message.positiveIntValue = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.negativeIntValue = reader.int64();
                            break;
                        }
                    case 6: {
                            message.doubleValue = reader.double();
                            break;
                        }
                    case 7: {
                            message.stringValue = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.aggregateValue = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (var i = 0; i < message.name.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                var message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (var i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length >= 0)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (var j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UninterpretedOption
             * @function getTypeUrl
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UninterpretedOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UninterpretedOption";
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                    return writer;
                };

                /**
                 * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.namePart = reader.string();
                                break;
                            }
                        case 2: {
                                message.isExtension = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    if (!message.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                    if (!message.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                    return message;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NamePart
                 * @function getTypeUrl
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NamePart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UninterpretedOption.NamePart";
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (var i = 0; i < message.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.location && message.location.length))
                                message.location = [];
                            message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (var i = 0; i < message.location.length; ++i) {
                        var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                var message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (var i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (var j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SourceCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SourceCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.SourceCodeInfo";
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.span != null && message.span.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.span.length; ++i)
                            writer.int32(message.span[i]);
                        writer.ldelim();
                    }
                    if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                    if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                    if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                    return writer;
                };

                /**
                 * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            }
                        case 2: {
                                if (!(message.span && message.span.length))
                                    message.span = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.span.push(reader.int32());
                                } else
                                    message.span.push(reader.int32());
                                break;
                            }
                        case 3: {
                                message.leadingComments = reader.string();
                                break;
                            }
                        case 4: {
                                message.trailingComments = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                    message.leadingDetachedComments = [];
                                message.leadingDetachedComments.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (var i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (var i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (var j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Location
                 * @function getTypeUrl
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.SourceCodeInfo.Location";
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annotation != null && message.annotation.length)
                    for (var i = 0; i < message.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.annotation && message.annotation.length))
                                message.annotation = [];
                            message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (var i = 0; i < message.annotation.length; ++i) {
                        var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                var message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (var i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (var j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GeneratedCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GeneratedCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo";
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            }
                        case 2: {
                                message.sourceFile = reader.string();
                                break;
                            }
                        case 3: {
                                message.begin = reader.int32();
                                break;
                            }
                        case 4: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Annotation
                 * @function getTypeUrl
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Annotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo.Annotation";
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

$root.ProtobufEventHeader = (function() {

    /**
     * Properties of a ProtobufEventHeader.
     * @exports IProtobufEventHeader
     * @interface IProtobufEventHeader
     * @property {ProtobufEventHeader.Vendor|null} [vendorId] ProtobufEventHeader vendorId
     * @property {number|null} [vendorEventType] ProtobufEventHeader vendorEventType
     * @property {ProtobufEventHeader.EventType|null} [eventType] ProtobufEventHeader eventType
     * @property {ProtobufEventHeader.EventLocationTime|null} [eventLocationTime] ProtobufEventHeader eventLocationTime
     * @property {ProtobufEventHeader.TransmittablePriority|null} [transmittablePriority] ProtobufEventHeader transmittablePriority
     * @property {ProtobufEventHeader.TransmittableUrgency|null} [transmittableUrgency] ProtobufEventHeader transmittableUrgency
     * @property {boolean|null} [transmittableChannelSatellite] ProtobufEventHeader transmittableChannelSatellite
     * @property {boolean|null} [transmittableChannelCellular] ProtobufEventHeader transmittableChannelCellular
     * @property {boolean|null} [transmittableChannelSatelliteOrCellular] ProtobufEventHeader transmittableChannelSatelliteOrCellular
     * @property {boolean|null} [transmittableChannelBluetooth] ProtobufEventHeader transmittableChannelBluetooth
     * @property {boolean|null} [transmittableChannelUsb] ProtobufEventHeader transmittableChannelUsb
     * @property {boolean|null} [transmittableChannelSerial] ProtobufEventHeader transmittableChannelSerial
     * @property {number|null} [payloadLength] ProtobufEventHeader payloadLength
     * @property {number|null} [payloadCrc] ProtobufEventHeader payloadCrc
     */

    /**
     * Constructs a new ProtobufEventHeader.
     * @exports ProtobufEventHeader
     * @classdesc Represents a ProtobufEventHeader.
     * @implements IProtobufEventHeader
     * @constructor
     * @param {IProtobufEventHeader=} [properties] Properties to set
     */
    function ProtobufEventHeader(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventHeader vendorId.
     * @member {ProtobufEventHeader.Vendor} vendorId
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.vendorId = 0;

    /**
     * ProtobufEventHeader vendorEventType.
     * @member {number} vendorEventType
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.vendorEventType = 0;

    /**
     * ProtobufEventHeader eventType.
     * @member {ProtobufEventHeader.EventType} eventType
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.eventType = 0;

    /**
     * ProtobufEventHeader eventLocationTime.
     * @member {ProtobufEventHeader.EventLocationTime} eventLocationTime
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.eventLocationTime = 0;

    /**
     * ProtobufEventHeader transmittablePriority.
     * @member {ProtobufEventHeader.TransmittablePriority} transmittablePriority
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittablePriority = 0;

    /**
     * ProtobufEventHeader transmittableUrgency.
     * @member {ProtobufEventHeader.TransmittableUrgency} transmittableUrgency
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittableUrgency = 0;

    /**
     * ProtobufEventHeader transmittableChannelSatellite.
     * @member {boolean} transmittableChannelSatellite
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittableChannelSatellite = false;

    /**
     * ProtobufEventHeader transmittableChannelCellular.
     * @member {boolean} transmittableChannelCellular
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittableChannelCellular = false;

    /**
     * ProtobufEventHeader transmittableChannelSatelliteOrCellular.
     * @member {boolean} transmittableChannelSatelliteOrCellular
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittableChannelSatelliteOrCellular = false;

    /**
     * ProtobufEventHeader transmittableChannelBluetooth.
     * @member {boolean} transmittableChannelBluetooth
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittableChannelBluetooth = false;

    /**
     * ProtobufEventHeader transmittableChannelUsb.
     * @member {boolean} transmittableChannelUsb
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittableChannelUsb = false;

    /**
     * ProtobufEventHeader transmittableChannelSerial.
     * @member {boolean} transmittableChannelSerial
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.transmittableChannelSerial = false;

    /**
     * ProtobufEventHeader payloadLength.
     * @member {number} payloadLength
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.payloadLength = 0;

    /**
     * ProtobufEventHeader payloadCrc.
     * @member {number} payloadCrc
     * @memberof ProtobufEventHeader
     * @instance
     */
    ProtobufEventHeader.prototype.payloadCrc = 0;

    /**
     * Creates a new ProtobufEventHeader instance using the specified properties.
     * @function create
     * @memberof ProtobufEventHeader
     * @static
     * @param {IProtobufEventHeader=} [properties] Properties to set
     * @returns {ProtobufEventHeader} ProtobufEventHeader instance
     */
    ProtobufEventHeader.create = function create(properties) {
        return new ProtobufEventHeader(properties);
    };

    /**
     * Encodes the specified ProtobufEventHeader message. Does not implicitly {@link ProtobufEventHeader.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventHeader
     * @static
     * @param {IProtobufEventHeader} message ProtobufEventHeader message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventHeader.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.vendorId != null && Object.hasOwnProperty.call(message, "vendorId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.vendorId);
        if (message.vendorEventType != null && Object.hasOwnProperty.call(message, "vendorEventType"))
            writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.vendorEventType);
        if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.eventType);
        if (message.eventLocationTime != null && Object.hasOwnProperty.call(message, "eventLocationTime"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.eventLocationTime);
        if (message.transmittablePriority != null && Object.hasOwnProperty.call(message, "transmittablePriority"))
            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.transmittablePriority);
        if (message.transmittableUrgency != null && Object.hasOwnProperty.call(message, "transmittableUrgency"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.transmittableUrgency);
        if (message.transmittableChannelSatellite != null && Object.hasOwnProperty.call(message, "transmittableChannelSatellite"))
            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.transmittableChannelSatellite);
        if (message.transmittableChannelCellular != null && Object.hasOwnProperty.call(message, "transmittableChannelCellular"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.transmittableChannelCellular);
        if (message.transmittableChannelSatelliteOrCellular != null && Object.hasOwnProperty.call(message, "transmittableChannelSatelliteOrCellular"))
            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.transmittableChannelSatelliteOrCellular);
        if (message.transmittableChannelBluetooth != null && Object.hasOwnProperty.call(message, "transmittableChannelBluetooth"))
            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.transmittableChannelBluetooth);
        if (message.transmittableChannelUsb != null && Object.hasOwnProperty.call(message, "transmittableChannelUsb"))
            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.transmittableChannelUsb);
        if (message.transmittableChannelSerial != null && Object.hasOwnProperty.call(message, "transmittableChannelSerial"))
            writer.uint32(/* id 25, wireType 0 =*/200).bool(message.transmittableChannelSerial);
        if (message.payloadLength != null && Object.hasOwnProperty.call(message, "payloadLength"))
            writer.uint32(/* id 40, wireType 0 =*/320).uint32(message.payloadLength);
        if (message.payloadCrc != null && Object.hasOwnProperty.call(message, "payloadCrc"))
            writer.uint32(/* id 41, wireType 0 =*/328).uint32(message.payloadCrc);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventHeader message, length delimited. Does not implicitly {@link ProtobufEventHeader.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventHeader
     * @static
     * @param {IProtobufEventHeader} message ProtobufEventHeader message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventHeader.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventHeader message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventHeader
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventHeader} ProtobufEventHeader
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventHeader.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventHeader();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.vendorId = reader.int32();
                    break;
                }
            case 10: {
                    message.vendorEventType = reader.uint32();
                    break;
                }
            case 11: {
                    message.eventType = reader.int32();
                    break;
                }
            case 12: {
                    message.eventLocationTime = reader.int32();
                    break;
                }
            case 13: {
                    message.transmittablePriority = reader.int32();
                    break;
                }
            case 14: {
                    message.transmittableUrgency = reader.int32();
                    break;
                }
            case 20: {
                    message.transmittableChannelSatellite = reader.bool();
                    break;
                }
            case 21: {
                    message.transmittableChannelCellular = reader.bool();
                    break;
                }
            case 22: {
                    message.transmittableChannelSatelliteOrCellular = reader.bool();
                    break;
                }
            case 23: {
                    message.transmittableChannelBluetooth = reader.bool();
                    break;
                }
            case 24: {
                    message.transmittableChannelUsb = reader.bool();
                    break;
                }
            case 25: {
                    message.transmittableChannelSerial = reader.bool();
                    break;
                }
            case 40: {
                    message.payloadLength = reader.uint32();
                    break;
                }
            case 41: {
                    message.payloadCrc = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventHeader message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventHeader
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventHeader} ProtobufEventHeader
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventHeader.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventHeader message.
     * @function verify
     * @memberof ProtobufEventHeader
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventHeader.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.vendorId != null && message.hasOwnProperty("vendorId"))
            switch (message.vendorId) {
            default:
                return "vendorId: enum value expected";
            case 0:
                break;
            }
        if (message.vendorEventType != null && message.hasOwnProperty("vendorEventType"))
            if (!$util.isInteger(message.vendorEventType))
                return "vendorEventType: integer expected";
        if (message.eventType != null && message.hasOwnProperty("eventType"))
            switch (message.eventType) {
            default:
                return "eventType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 10:
            case 11:
            case 12:
            case 9:
            case 13:
            case 14:
            case 8:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 25:
            case 30:
            case 31:
            case 32:
            case 40:
            case 41:
            case 42:
            case 45:
            case 46:
            case 47:
            case 50:
            case 71:
            case 72:
            case 73:
            case 74:
            case 75:
            case 77:
            case 78:
            case 79:
            case 141:
            case 142:
            case 143:
            case 144:
            case 145:
            case 147:
            case 148:
            case 149:
            case 80:
            case 81:
            case 82:
            case 83:
            case 84:
            case 85:
            case 86:
            case 87:
            case 90:
            case 91:
            case 92:
            case 93:
            case 94:
            case 95:
            case 96:
            case 97:
            case 98:
            case 99:
            case 100:
            case 101:
            case 102:
            case 103:
            case 104:
            case 110:
            case 112:
            case 113:
            case 111:
            case 160:
            case 120:
            case 130:
            case 131:
            case 132:
            case 150:
            case 151:
            case 152:
            case 170:
            case 171:
            case 180:
            case 181:
            case 190:
            case 191:
                break;
            }
        if (message.eventLocationTime != null && message.hasOwnProperty("eventLocationTime"))
            switch (message.eventLocationTime) {
            default:
                return "eventLocationTime: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.transmittablePriority != null && message.hasOwnProperty("transmittablePriority"))
            switch (message.transmittablePriority) {
            default:
                return "transmittablePriority: enum value expected";
            case 0:
                break;
            }
        if (message.transmittableUrgency != null && message.hasOwnProperty("transmittableUrgency"))
            switch (message.transmittableUrgency) {
            default:
                return "transmittableUrgency: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.transmittableChannelSatellite != null && message.hasOwnProperty("transmittableChannelSatellite"))
            if (typeof message.transmittableChannelSatellite !== "boolean")
                return "transmittableChannelSatellite: boolean expected";
        if (message.transmittableChannelCellular != null && message.hasOwnProperty("transmittableChannelCellular"))
            if (typeof message.transmittableChannelCellular !== "boolean")
                return "transmittableChannelCellular: boolean expected";
        if (message.transmittableChannelSatelliteOrCellular != null && message.hasOwnProperty("transmittableChannelSatelliteOrCellular"))
            if (typeof message.transmittableChannelSatelliteOrCellular !== "boolean")
                return "transmittableChannelSatelliteOrCellular: boolean expected";
        if (message.transmittableChannelBluetooth != null && message.hasOwnProperty("transmittableChannelBluetooth"))
            if (typeof message.transmittableChannelBluetooth !== "boolean")
                return "transmittableChannelBluetooth: boolean expected";
        if (message.transmittableChannelUsb != null && message.hasOwnProperty("transmittableChannelUsb"))
            if (typeof message.transmittableChannelUsb !== "boolean")
                return "transmittableChannelUsb: boolean expected";
        if (message.transmittableChannelSerial != null && message.hasOwnProperty("transmittableChannelSerial"))
            if (typeof message.transmittableChannelSerial !== "boolean")
                return "transmittableChannelSerial: boolean expected";
        if (message.payloadLength != null && message.hasOwnProperty("payloadLength"))
            if (!$util.isInteger(message.payloadLength))
                return "payloadLength: integer expected";
        if (message.payloadCrc != null && message.hasOwnProperty("payloadCrc"))
            if (!$util.isInteger(message.payloadCrc))
                return "payloadCrc: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventHeader message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventHeader
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventHeader} ProtobufEventHeader
     */
    ProtobufEventHeader.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventHeader)
            return object;
        var message = new $root.ProtobufEventHeader();
        switch (object.vendorId) {
        default:
            if (typeof object.vendorId === "number") {
                message.vendorId = object.vendorId;
                break;
            }
            break;
        case "kSpidertracks":
        case 0:
            message.vendorId = 0;
            break;
        }
        if (object.vendorEventType != null)
            message.vendorEventType = object.vendorEventType >>> 0;
        switch (object.eventType) {
        default:
            if (typeof object.eventType === "number") {
                message.eventType = object.eventType;
                break;
            }
            break;
        case "kNoType":
        case 0:
            message.eventType = 0;
            break;
        case "kEventRegistrationGetRequest":
        case 1:
            message.eventType = 1;
            break;
        case "kEventRegistrationGetResponse":
        case 2:
            message.eventType = 2;
            break;
        case "kEventRegistrationRequest":
        case 3:
            message.eventType = 3;
            break;
        case "kEventRegistrationResponse":
        case 4:
            message.eventType = 4;
            break;
        case "kEventBridgeQueueStatusGetRequest":
        case 5:
            message.eventType = 5;
            break;
        case "kEventBridgeQueueStatusGetResponse":
        case 6:
            message.eventType = 6;
            break;
        case "kSettingsGetRequest":
        case 10:
            message.eventType = 10;
            break;
        case "kSettingsGetResponseMain":
        case 11:
            message.eventType = 11;
            break;
        case "kSettingsIdentificationMainWrite":
        case 12:
            message.eventType = 12;
            break;
        case "kSettingsDeviceMainWrite":
        case 9:
            message.eventType = 9;
            break;
        case "kSettingsServerMainWrite":
        case 13:
            message.eventType = 13;
            break;
        case "kSettingsUserMainWrite":
        case 14:
            message.eventType = 14;
            break;
        case "kSettingsInternalMainWrite":
        case 8:
            message.eventType = 8;
            break;
        case "kSettingsMetadataMainUpdate":
        case 15:
            message.eventType = 15;
            break;
        case "kSettingsUpdateMain":
        case 16:
            message.eventType = 16;
            break;
        case "kSettingsIdentificationMainRead":
        case 17:
            message.eventType = 17;
            break;
        case "kSettingsServerMainRead":
        case 18:
            message.eventType = 18;
            break;
        case "kSettingsUserMainRead":
        case 19:
            message.eventType = 19;
            break;
        case "kPowerGetRequest":
        case 20:
            message.eventType = 20;
            break;
        case "kPowerGetResponse":
        case 21:
            message.eventType = 21;
            break;
        case "kPowerUpdate":
        case 22:
            message.eventType = 22;
            break;
        case "kReset":
        case 25:
            message.eventType = 25;
            break;
        case "kSessionGetRequest":
        case 30:
            message.eventType = 30;
            break;
        case "kSessionGetResponse":
        case 31:
            message.eventType = 31;
            break;
        case "kSessionUpdate":
        case 32:
            message.eventType = 32;
            break;
        case "kOperatingModeGetRequest":
        case 40:
            message.eventType = 40;
            break;
        case "kOperatingModeGetResponse":
        case 41:
            message.eventType = 41;
            break;
        case "kOperatingModeUpdate":
        case 42:
            message.eventType = 42;
            break;
        case "kOtaGetRequest":
        case 45:
            message.eventType = 45;
            break;
        case "kOtaGetResponse":
        case 46:
            message.eventType = 46;
            break;
        case "kOtaUpdate":
        case 47:
            message.eventType = 47;
            break;
        case "kButtonPress":
        case 50:
            message.eventType = 50;
            break;
        case "kSettingsGetResponseKeypad":
        case 71:
            message.eventType = 71;
            break;
        case "kSettingsIdentificationKeypadWrite":
        case 72:
            message.eventType = 72;
            break;
        case "kSettingsServerKeypadWrite":
        case 73:
            message.eventType = 73;
            break;
        case "kSettingsUserKeypadWrite":
        case 74:
            message.eventType = 74;
            break;
        case "kSettingsMetadataKeypadUpdate":
        case 75:
            message.eventType = 75;
            break;
        case "kSettingsIdentificationKeypadRead":
        case 77:
            message.eventType = 77;
            break;
        case "kSettingsServerKeypadRead":
        case 78:
            message.eventType = 78;
            break;
        case "kSettingsUserKeypadRead":
        case 79:
            message.eventType = 79;
            break;
        case "kSettingsGetResponseGpi":
        case 141:
            message.eventType = 141;
            break;
        case "kSettingsIdentificationGpiWrite":
        case 142:
            message.eventType = 142;
            break;
        case "kSettingsServerGpiWrite":
        case 143:
            message.eventType = 143;
            break;
        case "kSettingsUserGpiWrite":
        case 144:
            message.eventType = 144;
            break;
        case "kSettingsMetadataGpiUpdate":
        case 145:
            message.eventType = 145;
            break;
        case "kSettingsIdentificationGpiRead":
        case 147:
            message.eventType = 147;
            break;
        case "kSettingsServerGpiRead":
        case 148:
            message.eventType = 148;
            break;
        case "kSettingsUserGpiRead":
        case 149:
            message.eventType = 149;
            break;
        case "kPeripheralJoinRequest":
        case 80:
            message.eventType = 80;
            break;
        case "kPeripheralJoinResponse":
        case 81:
            message.eventType = 81;
            break;
        case "kPeripheralHostGetRequest":
        case 82:
            message.eventType = 82;
            break;
        case "kPeripheralHostGetResponse":
        case 83:
            message.eventType = 83;
            break;
        case "kPeripheralSlaveGetRequest":
        case 84:
            message.eventType = 84;
            break;
        case "kPeripheralSlaveGetResponse":
        case 85:
            message.eventType = 85;
            break;
        case "kPeripheralHostConnected":
        case 86:
            message.eventType = 86;
            break;
        case "kPeripheralHostDisconnected":
        case 87:
            message.eventType = 87;
            break;
        case "kImuConfigurationRequest":
        case 90:
            message.eventType = 90;
            break;
        case "kImuConfigurationResponse":
        case 91:
            message.eventType = 91;
            break;
        case "kImuConfigurationReport":
        case 92:
            message.eventType = 92;
            break;
        case "kImuTestRequest":
        case 93:
            message.eventType = 93;
            break;
        case "kImuTestResponse":
        case 94:
            message.eventType = 94;
            break;
        case "kImuMeasurementRawAccelerometer":
        case 95:
            message.eventType = 95;
            break;
        case "kImuMeasurementRawGyroscope":
        case 96:
            message.eventType = 96;
            break;
        case "kImuMeasurementRawMagnetometer":
        case 97:
            message.eventType = 97;
            break;
        case "kImuMeasurementPressure":
        case 98:
            message.eventType = 98;
            break;
        case "kImuMeasurementTemperature":
        case 99:
            message.eventType = 99;
            break;
        case "kImuMeasurementPositionVelocityTime":
        case 100:
            message.eventType = 100;
            break;
        case "kImuMeasurementSoundPressure":
        case 101:
            message.eventType = 101;
            break;
        case "kImuMeasurementAccelerometer":
        case 102:
            message.eventType = 102;
            break;
        case "kImuMeasurementGyroscope":
        case 103:
            message.eventType = 103;
            break;
        case "kImuMeasurementMagnetometer":
        case 104:
            message.eventType = 104;
            break;
        case "kNavigationPvt":
        case 110:
            message.eventType = 110;
            break;
        case "kNavigationDiagnosticGetRequest":
        case 112:
            message.eventType = 112;
            break;
        case "kNavigationDiagnosticGetResponse":
        case 113:
            message.eventType = 113;
            break;
        case "kInertialNavigationSystem":
        case 111:
            message.eventType = 111;
            break;
        case "kInertialNavigationDiagnostics":
        case 160:
            message.eventType = 160;
            break;
        case "kSnifferData":
        case 120:
            message.eventType = 120;
            break;
        case "kExternalDeviceStateGetRequest":
        case 130:
            message.eventType = 130;
            break;
        case "kExternalDeviceStateGetResponse":
        case 131:
            message.eventType = 131;
            break;
        case "kExternalDeviceStateUpdate":
        case 132:
            message.eventType = 132;
            break;
        case "kGpiGetRequest":
        case 150:
            message.eventType = 150;
            break;
        case "kGpiGetResponse":
        case 151:
            message.eventType = 151;
            break;
        case "kGpiUpdate":
        case 152:
            message.eventType = 152;
            break;
        case "kDataBlockDiagnosticGetRequest":
        case 170:
            message.eventType = 170;
            break;
        case "kDataBlockDiagnosticGetResponse":
        case 171:
            message.eventType = 171;
            break;
        case "kSatelliteNetworkDiagnosticGetRequest":
        case 180:
            message.eventType = 180;
            break;
        case "kSatelliteNetworkDiagnosticGetResponse":
        case 181:
            message.eventType = 181;
            break;
        case "kCellularNetworkDiagnosticGetRequest":
        case 190:
            message.eventType = 190;
            break;
        case "kCellularNetworkDiagnosticGetResponse":
        case 191:
            message.eventType = 191;
            break;
        }
        switch (object.eventLocationTime) {
        default:
            if (typeof object.eventLocationTime === "number") {
                message.eventLocationTime = object.eventLocationTime;
                break;
            }
            break;
        case "kNoLocation":
        case 0:
            message.eventLocationTime = 0;
            break;
        case "kAtCreation":
        case 1:
            message.eventLocationTime = 1;
            break;
        case "kAtTransmission":
        case 2:
            message.eventLocationTime = 2;
            break;
        }
        switch (object.transmittablePriority) {
        default:
            if (typeof object.transmittablePriority === "number") {
                message.transmittablePriority = object.transmittablePriority;
                break;
            }
            break;
        case "kNoPriority":
        case 0:
            message.transmittablePriority = 0;
            break;
        }
        switch (object.transmittableUrgency) {
        default:
            if (typeof object.transmittableUrgency === "number") {
                message.transmittableUrgency = object.transmittableUrgency;
                break;
            }
            break;
        case "kNextOpportunity":
        case 0:
            message.transmittableUrgency = 0;
            break;
        case "kNow":
        case 1:
            message.transmittableUrgency = 1;
            break;
        case "kWithin10s":
        case 2:
            message.transmittableUrgency = 2;
            break;
        }
        if (object.transmittableChannelSatellite != null)
            message.transmittableChannelSatellite = Boolean(object.transmittableChannelSatellite);
        if (object.transmittableChannelCellular != null)
            message.transmittableChannelCellular = Boolean(object.transmittableChannelCellular);
        if (object.transmittableChannelSatelliteOrCellular != null)
            message.transmittableChannelSatelliteOrCellular = Boolean(object.transmittableChannelSatelliteOrCellular);
        if (object.transmittableChannelBluetooth != null)
            message.transmittableChannelBluetooth = Boolean(object.transmittableChannelBluetooth);
        if (object.transmittableChannelUsb != null)
            message.transmittableChannelUsb = Boolean(object.transmittableChannelUsb);
        if (object.transmittableChannelSerial != null)
            message.transmittableChannelSerial = Boolean(object.transmittableChannelSerial);
        if (object.payloadLength != null)
            message.payloadLength = object.payloadLength >>> 0;
        if (object.payloadCrc != null)
            message.payloadCrc = object.payloadCrc >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventHeader message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventHeader
     * @static
     * @param {ProtobufEventHeader} message ProtobufEventHeader
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventHeader.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.vendorId = options.enums === String ? "kSpidertracks" : 0;
            object.vendorEventType = 0;
            object.eventType = options.enums === String ? "kNoType" : 0;
            object.eventLocationTime = options.enums === String ? "kNoLocation" : 0;
            object.transmittablePriority = options.enums === String ? "kNoPriority" : 0;
            object.transmittableUrgency = options.enums === String ? "kNextOpportunity" : 0;
            object.transmittableChannelSatellite = false;
            object.transmittableChannelCellular = false;
            object.transmittableChannelSatelliteOrCellular = false;
            object.transmittableChannelBluetooth = false;
            object.transmittableChannelUsb = false;
            object.transmittableChannelSerial = false;
            object.payloadLength = 0;
            object.payloadCrc = 0;
        }
        if (message.vendorId != null && message.hasOwnProperty("vendorId"))
            object.vendorId = options.enums === String ? $root.ProtobufEventHeader.Vendor[message.vendorId] === undefined ? message.vendorId : $root.ProtobufEventHeader.Vendor[message.vendorId] : message.vendorId;
        if (message.vendorEventType != null && message.hasOwnProperty("vendorEventType"))
            object.vendorEventType = message.vendorEventType;
        if (message.eventType != null && message.hasOwnProperty("eventType"))
            object.eventType = options.enums === String ? $root.ProtobufEventHeader.EventType[message.eventType] === undefined ? message.eventType : $root.ProtobufEventHeader.EventType[message.eventType] : message.eventType;
        if (message.eventLocationTime != null && message.hasOwnProperty("eventLocationTime"))
            object.eventLocationTime = options.enums === String ? $root.ProtobufEventHeader.EventLocationTime[message.eventLocationTime] === undefined ? message.eventLocationTime : $root.ProtobufEventHeader.EventLocationTime[message.eventLocationTime] : message.eventLocationTime;
        if (message.transmittablePriority != null && message.hasOwnProperty("transmittablePriority"))
            object.transmittablePriority = options.enums === String ? $root.ProtobufEventHeader.TransmittablePriority[message.transmittablePriority] === undefined ? message.transmittablePriority : $root.ProtobufEventHeader.TransmittablePriority[message.transmittablePriority] : message.transmittablePriority;
        if (message.transmittableUrgency != null && message.hasOwnProperty("transmittableUrgency"))
            object.transmittableUrgency = options.enums === String ? $root.ProtobufEventHeader.TransmittableUrgency[message.transmittableUrgency] === undefined ? message.transmittableUrgency : $root.ProtobufEventHeader.TransmittableUrgency[message.transmittableUrgency] : message.transmittableUrgency;
        if (message.transmittableChannelSatellite != null && message.hasOwnProperty("transmittableChannelSatellite"))
            object.transmittableChannelSatellite = message.transmittableChannelSatellite;
        if (message.transmittableChannelCellular != null && message.hasOwnProperty("transmittableChannelCellular"))
            object.transmittableChannelCellular = message.transmittableChannelCellular;
        if (message.transmittableChannelSatelliteOrCellular != null && message.hasOwnProperty("transmittableChannelSatelliteOrCellular"))
            object.transmittableChannelSatelliteOrCellular = message.transmittableChannelSatelliteOrCellular;
        if (message.transmittableChannelBluetooth != null && message.hasOwnProperty("transmittableChannelBluetooth"))
            object.transmittableChannelBluetooth = message.transmittableChannelBluetooth;
        if (message.transmittableChannelUsb != null && message.hasOwnProperty("transmittableChannelUsb"))
            object.transmittableChannelUsb = message.transmittableChannelUsb;
        if (message.transmittableChannelSerial != null && message.hasOwnProperty("transmittableChannelSerial"))
            object.transmittableChannelSerial = message.transmittableChannelSerial;
        if (message.payloadLength != null && message.hasOwnProperty("payloadLength"))
            object.payloadLength = message.payloadLength;
        if (message.payloadCrc != null && message.hasOwnProperty("payloadCrc"))
            object.payloadCrc = message.payloadCrc;
        return object;
    };

    /**
     * Converts this ProtobufEventHeader to JSON.
     * @function toJSON
     * @memberof ProtobufEventHeader
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventHeader.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventHeader
     * @function getTypeUrl
     * @memberof ProtobufEventHeader
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventHeader";
    };

    /**
     * Vendor enum.
     * @name ProtobufEventHeader.Vendor
     * @enum {number}
     * @property {number} kSpidertracks=0 kSpidertracks value
     */
    ProtobufEventHeader.Vendor = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kSpidertracks"] = 0;
        return values;
    })();

    /**
     * EventType enum.
     * @name ProtobufEventHeader.EventType
     * @enum {number}
     * @property {number} kNoType=0 kNoType value
     * @property {number} kEventRegistrationGetRequest=1 kEventRegistrationGetRequest value
     * @property {number} kEventRegistrationGetResponse=2 kEventRegistrationGetResponse value
     * @property {number} kEventRegistrationRequest=3 kEventRegistrationRequest value
     * @property {number} kEventRegistrationResponse=4 kEventRegistrationResponse value
     * @property {number} kEventBridgeQueueStatusGetRequest=5 kEventBridgeQueueStatusGetRequest value
     * @property {number} kEventBridgeQueueStatusGetResponse=6 kEventBridgeQueueStatusGetResponse value
     * @property {number} kSettingsGetRequest=10 kSettingsGetRequest value
     * @property {number} kSettingsGetResponseMain=11 kSettingsGetResponseMain value
     * @property {number} kSettingsIdentificationMainWrite=12 kSettingsIdentificationMainWrite value
     * @property {number} kSettingsDeviceMainWrite=9 kSettingsDeviceMainWrite value
     * @property {number} kSettingsServerMainWrite=13 kSettingsServerMainWrite value
     * @property {number} kSettingsUserMainWrite=14 kSettingsUserMainWrite value
     * @property {number} kSettingsInternalMainWrite=8 kSettingsInternalMainWrite value
     * @property {number} kSettingsMetadataMainUpdate=15 kSettingsMetadataMainUpdate value
     * @property {number} kSettingsUpdateMain=16 kSettingsUpdateMain value
     * @property {number} kSettingsIdentificationMainRead=17 kSettingsIdentificationMainRead value
     * @property {number} kSettingsServerMainRead=18 kSettingsServerMainRead value
     * @property {number} kSettingsUserMainRead=19 kSettingsUserMainRead value
     * @property {number} kPowerGetRequest=20 kPowerGetRequest value
     * @property {number} kPowerGetResponse=21 kPowerGetResponse value
     * @property {number} kPowerUpdate=22 kPowerUpdate value
     * @property {number} kReset=25 kReset value
     * @property {number} kSessionGetRequest=30 kSessionGetRequest value
     * @property {number} kSessionGetResponse=31 kSessionGetResponse value
     * @property {number} kSessionUpdate=32 kSessionUpdate value
     * @property {number} kOperatingModeGetRequest=40 kOperatingModeGetRequest value
     * @property {number} kOperatingModeGetResponse=41 kOperatingModeGetResponse value
     * @property {number} kOperatingModeUpdate=42 kOperatingModeUpdate value
     * @property {number} kOtaGetRequest=45 kOtaGetRequest value
     * @property {number} kOtaGetResponse=46 kOtaGetResponse value
     * @property {number} kOtaUpdate=47 kOtaUpdate value
     * @property {number} kButtonPress=50 kButtonPress value
     * @property {number} kSettingsGetResponseKeypad=71 kSettingsGetResponseKeypad value
     * @property {number} kSettingsIdentificationKeypadWrite=72 kSettingsIdentificationKeypadWrite value
     * @property {number} kSettingsServerKeypadWrite=73 kSettingsServerKeypadWrite value
     * @property {number} kSettingsUserKeypadWrite=74 kSettingsUserKeypadWrite value
     * @property {number} kSettingsMetadataKeypadUpdate=75 kSettingsMetadataKeypadUpdate value
     * @property {number} kSettingsIdentificationKeypadRead=77 kSettingsIdentificationKeypadRead value
     * @property {number} kSettingsServerKeypadRead=78 kSettingsServerKeypadRead value
     * @property {number} kSettingsUserKeypadRead=79 kSettingsUserKeypadRead value
     * @property {number} kSettingsGetResponseGpi=141 kSettingsGetResponseGpi value
     * @property {number} kSettingsIdentificationGpiWrite=142 kSettingsIdentificationGpiWrite value
     * @property {number} kSettingsServerGpiWrite=143 kSettingsServerGpiWrite value
     * @property {number} kSettingsUserGpiWrite=144 kSettingsUserGpiWrite value
     * @property {number} kSettingsMetadataGpiUpdate=145 kSettingsMetadataGpiUpdate value
     * @property {number} kSettingsIdentificationGpiRead=147 kSettingsIdentificationGpiRead value
     * @property {number} kSettingsServerGpiRead=148 kSettingsServerGpiRead value
     * @property {number} kSettingsUserGpiRead=149 kSettingsUserGpiRead value
     * @property {number} kPeripheralJoinRequest=80 kPeripheralJoinRequest value
     * @property {number} kPeripheralJoinResponse=81 kPeripheralJoinResponse value
     * @property {number} kPeripheralHostGetRequest=82 kPeripheralHostGetRequest value
     * @property {number} kPeripheralHostGetResponse=83 kPeripheralHostGetResponse value
     * @property {number} kPeripheralSlaveGetRequest=84 kPeripheralSlaveGetRequest value
     * @property {number} kPeripheralSlaveGetResponse=85 kPeripheralSlaveGetResponse value
     * @property {number} kPeripheralHostConnected=86 kPeripheralHostConnected value
     * @property {number} kPeripheralHostDisconnected=87 kPeripheralHostDisconnected value
     * @property {number} kImuConfigurationRequest=90 kImuConfigurationRequest value
     * @property {number} kImuConfigurationResponse=91 kImuConfigurationResponse value
     * @property {number} kImuConfigurationReport=92 kImuConfigurationReport value
     * @property {number} kImuTestRequest=93 kImuTestRequest value
     * @property {number} kImuTestResponse=94 kImuTestResponse value
     * @property {number} kImuMeasurementRawAccelerometer=95 kImuMeasurementRawAccelerometer value
     * @property {number} kImuMeasurementRawGyroscope=96 kImuMeasurementRawGyroscope value
     * @property {number} kImuMeasurementRawMagnetometer=97 kImuMeasurementRawMagnetometer value
     * @property {number} kImuMeasurementPressure=98 kImuMeasurementPressure value
     * @property {number} kImuMeasurementTemperature=99 kImuMeasurementTemperature value
     * @property {number} kImuMeasurementPositionVelocityTime=100 kImuMeasurementPositionVelocityTime value
     * @property {number} kImuMeasurementSoundPressure=101 kImuMeasurementSoundPressure value
     * @property {number} kImuMeasurementAccelerometer=102 kImuMeasurementAccelerometer value
     * @property {number} kImuMeasurementGyroscope=103 kImuMeasurementGyroscope value
     * @property {number} kImuMeasurementMagnetometer=104 kImuMeasurementMagnetometer value
     * @property {number} kNavigationPvt=110 kNavigationPvt value
     * @property {number} kNavigationDiagnosticGetRequest=112 kNavigationDiagnosticGetRequest value
     * @property {number} kNavigationDiagnosticGetResponse=113 kNavigationDiagnosticGetResponse value
     * @property {number} kInertialNavigationSystem=111 kInertialNavigationSystem value
     * @property {number} kInertialNavigationDiagnostics=160 kInertialNavigationDiagnostics value
     * @property {number} kSnifferData=120 kSnifferData value
     * @property {number} kExternalDeviceStateGetRequest=130 kExternalDeviceStateGetRequest value
     * @property {number} kExternalDeviceStateGetResponse=131 kExternalDeviceStateGetResponse value
     * @property {number} kExternalDeviceStateUpdate=132 kExternalDeviceStateUpdate value
     * @property {number} kGpiGetRequest=150 kGpiGetRequest value
     * @property {number} kGpiGetResponse=151 kGpiGetResponse value
     * @property {number} kGpiUpdate=152 kGpiUpdate value
     * @property {number} kDataBlockDiagnosticGetRequest=170 kDataBlockDiagnosticGetRequest value
     * @property {number} kDataBlockDiagnosticGetResponse=171 kDataBlockDiagnosticGetResponse value
     * @property {number} kSatelliteNetworkDiagnosticGetRequest=180 kSatelliteNetworkDiagnosticGetRequest value
     * @property {number} kSatelliteNetworkDiagnosticGetResponse=181 kSatelliteNetworkDiagnosticGetResponse value
     * @property {number} kCellularNetworkDiagnosticGetRequest=190 kCellularNetworkDiagnosticGetRequest value
     * @property {number} kCellularNetworkDiagnosticGetResponse=191 kCellularNetworkDiagnosticGetResponse value
     */
    ProtobufEventHeader.EventType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoType"] = 0;
        values[valuesById[1] = "kEventRegistrationGetRequest"] = 1;
        values[valuesById[2] = "kEventRegistrationGetResponse"] = 2;
        values[valuesById[3] = "kEventRegistrationRequest"] = 3;
        values[valuesById[4] = "kEventRegistrationResponse"] = 4;
        values[valuesById[5] = "kEventBridgeQueueStatusGetRequest"] = 5;
        values[valuesById[6] = "kEventBridgeQueueStatusGetResponse"] = 6;
        values[valuesById[10] = "kSettingsGetRequest"] = 10;
        values[valuesById[11] = "kSettingsGetResponseMain"] = 11;
        values[valuesById[12] = "kSettingsIdentificationMainWrite"] = 12;
        values[valuesById[9] = "kSettingsDeviceMainWrite"] = 9;
        values[valuesById[13] = "kSettingsServerMainWrite"] = 13;
        values[valuesById[14] = "kSettingsUserMainWrite"] = 14;
        values[valuesById[8] = "kSettingsInternalMainWrite"] = 8;
        values[valuesById[15] = "kSettingsMetadataMainUpdate"] = 15;
        values[valuesById[16] = "kSettingsUpdateMain"] = 16;
        values[valuesById[17] = "kSettingsIdentificationMainRead"] = 17;
        values[valuesById[18] = "kSettingsServerMainRead"] = 18;
        values[valuesById[19] = "kSettingsUserMainRead"] = 19;
        values[valuesById[20] = "kPowerGetRequest"] = 20;
        values[valuesById[21] = "kPowerGetResponse"] = 21;
        values[valuesById[22] = "kPowerUpdate"] = 22;
        values[valuesById[25] = "kReset"] = 25;
        values[valuesById[30] = "kSessionGetRequest"] = 30;
        values[valuesById[31] = "kSessionGetResponse"] = 31;
        values[valuesById[32] = "kSessionUpdate"] = 32;
        values[valuesById[40] = "kOperatingModeGetRequest"] = 40;
        values[valuesById[41] = "kOperatingModeGetResponse"] = 41;
        values[valuesById[42] = "kOperatingModeUpdate"] = 42;
        values[valuesById[45] = "kOtaGetRequest"] = 45;
        values[valuesById[46] = "kOtaGetResponse"] = 46;
        values[valuesById[47] = "kOtaUpdate"] = 47;
        values[valuesById[50] = "kButtonPress"] = 50;
        values[valuesById[71] = "kSettingsGetResponseKeypad"] = 71;
        values[valuesById[72] = "kSettingsIdentificationKeypadWrite"] = 72;
        values[valuesById[73] = "kSettingsServerKeypadWrite"] = 73;
        values[valuesById[74] = "kSettingsUserKeypadWrite"] = 74;
        values[valuesById[75] = "kSettingsMetadataKeypadUpdate"] = 75;
        values[valuesById[77] = "kSettingsIdentificationKeypadRead"] = 77;
        values[valuesById[78] = "kSettingsServerKeypadRead"] = 78;
        values[valuesById[79] = "kSettingsUserKeypadRead"] = 79;
        values[valuesById[141] = "kSettingsGetResponseGpi"] = 141;
        values[valuesById[142] = "kSettingsIdentificationGpiWrite"] = 142;
        values[valuesById[143] = "kSettingsServerGpiWrite"] = 143;
        values[valuesById[144] = "kSettingsUserGpiWrite"] = 144;
        values[valuesById[145] = "kSettingsMetadataGpiUpdate"] = 145;
        values[valuesById[147] = "kSettingsIdentificationGpiRead"] = 147;
        values[valuesById[148] = "kSettingsServerGpiRead"] = 148;
        values[valuesById[149] = "kSettingsUserGpiRead"] = 149;
        values[valuesById[80] = "kPeripheralJoinRequest"] = 80;
        values[valuesById[81] = "kPeripheralJoinResponse"] = 81;
        values[valuesById[82] = "kPeripheralHostGetRequest"] = 82;
        values[valuesById[83] = "kPeripheralHostGetResponse"] = 83;
        values[valuesById[84] = "kPeripheralSlaveGetRequest"] = 84;
        values[valuesById[85] = "kPeripheralSlaveGetResponse"] = 85;
        values[valuesById[86] = "kPeripheralHostConnected"] = 86;
        values[valuesById[87] = "kPeripheralHostDisconnected"] = 87;
        values[valuesById[90] = "kImuConfigurationRequest"] = 90;
        values[valuesById[91] = "kImuConfigurationResponse"] = 91;
        values[valuesById[92] = "kImuConfigurationReport"] = 92;
        values[valuesById[93] = "kImuTestRequest"] = 93;
        values[valuesById[94] = "kImuTestResponse"] = 94;
        values[valuesById[95] = "kImuMeasurementRawAccelerometer"] = 95;
        values[valuesById[96] = "kImuMeasurementRawGyroscope"] = 96;
        values[valuesById[97] = "kImuMeasurementRawMagnetometer"] = 97;
        values[valuesById[98] = "kImuMeasurementPressure"] = 98;
        values[valuesById[99] = "kImuMeasurementTemperature"] = 99;
        values[valuesById[100] = "kImuMeasurementPositionVelocityTime"] = 100;
        values[valuesById[101] = "kImuMeasurementSoundPressure"] = 101;
        values[valuesById[102] = "kImuMeasurementAccelerometer"] = 102;
        values[valuesById[103] = "kImuMeasurementGyroscope"] = 103;
        values[valuesById[104] = "kImuMeasurementMagnetometer"] = 104;
        values[valuesById[110] = "kNavigationPvt"] = 110;
        values[valuesById[112] = "kNavigationDiagnosticGetRequest"] = 112;
        values[valuesById[113] = "kNavigationDiagnosticGetResponse"] = 113;
        values[valuesById[111] = "kInertialNavigationSystem"] = 111;
        values[valuesById[160] = "kInertialNavigationDiagnostics"] = 160;
        values[valuesById[120] = "kSnifferData"] = 120;
        values[valuesById[130] = "kExternalDeviceStateGetRequest"] = 130;
        values[valuesById[131] = "kExternalDeviceStateGetResponse"] = 131;
        values[valuesById[132] = "kExternalDeviceStateUpdate"] = 132;
        values[valuesById[150] = "kGpiGetRequest"] = 150;
        values[valuesById[151] = "kGpiGetResponse"] = 151;
        values[valuesById[152] = "kGpiUpdate"] = 152;
        values[valuesById[170] = "kDataBlockDiagnosticGetRequest"] = 170;
        values[valuesById[171] = "kDataBlockDiagnosticGetResponse"] = 171;
        values[valuesById[180] = "kSatelliteNetworkDiagnosticGetRequest"] = 180;
        values[valuesById[181] = "kSatelliteNetworkDiagnosticGetResponse"] = 181;
        values[valuesById[190] = "kCellularNetworkDiagnosticGetRequest"] = 190;
        values[valuesById[191] = "kCellularNetworkDiagnosticGetResponse"] = 191;
        return values;
    })();

    /**
     * EventLocationTime enum.
     * @name ProtobufEventHeader.EventLocationTime
     * @enum {number}
     * @property {number} kNoLocation=0 kNoLocation value
     * @property {number} kAtCreation=1 kAtCreation value
     * @property {number} kAtTransmission=2 kAtTransmission value
     */
    ProtobufEventHeader.EventLocationTime = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoLocation"] = 0;
        values[valuesById[1] = "kAtCreation"] = 1;
        values[valuesById[2] = "kAtTransmission"] = 2;
        return values;
    })();

    /**
     * TransmittablePriority enum.
     * @name ProtobufEventHeader.TransmittablePriority
     * @enum {number}
     * @property {number} kNoPriority=0 kNoPriority value
     */
    ProtobufEventHeader.TransmittablePriority = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoPriority"] = 0;
        return values;
    })();

    /**
     * TransmittableUrgency enum.
     * @name ProtobufEventHeader.TransmittableUrgency
     * @enum {number}
     * @property {number} kNextOpportunity=0 kNextOpportunity value
     * @property {number} kNow=1 kNow value
     * @property {number} kWithin10s=2 kWithin10s value
     */
    ProtobufEventHeader.TransmittableUrgency = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNextOpportunity"] = 0;
        values[valuesById[1] = "kNow"] = 1;
        values[valuesById[2] = "kWithin10s"] = 2;
        return values;
    })();

    return ProtobufEventHeader;
})();

$root.ProtobufEventPayloadEventRegistration = (function() {

    /**
     * Properties of a ProtobufEventPayloadEventRegistration.
     * @exports IProtobufEventPayloadEventRegistration
     * @interface IProtobufEventPayloadEventRegistration
     * @property {boolean|null} [add] ProtobufEventPayloadEventRegistration add
     * @property {Array.<ProtobufEventPayloadEventRegistration.EventType>|null} [eventType] ProtobufEventPayloadEventRegistration eventType
     */

    /**
     * Constructs a new ProtobufEventPayloadEventRegistration.
     * @exports ProtobufEventPayloadEventRegistration
     * @classdesc Represents a ProtobufEventPayloadEventRegistration.
     * @implements IProtobufEventPayloadEventRegistration
     * @constructor
     * @param {IProtobufEventPayloadEventRegistration=} [properties] Properties to set
     */
    function ProtobufEventPayloadEventRegistration(properties) {
        this.eventType = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadEventRegistration add.
     * @member {boolean} add
     * @memberof ProtobufEventPayloadEventRegistration
     * @instance
     */
    ProtobufEventPayloadEventRegistration.prototype.add = false;

    /**
     * ProtobufEventPayloadEventRegistration eventType.
     * @member {Array.<ProtobufEventPayloadEventRegistration.EventType>} eventType
     * @memberof ProtobufEventPayloadEventRegistration
     * @instance
     */
    ProtobufEventPayloadEventRegistration.prototype.eventType = $util.emptyArray;

    /**
     * Creates a new ProtobufEventPayloadEventRegistration instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {IProtobufEventPayloadEventRegistration=} [properties] Properties to set
     * @returns {ProtobufEventPayloadEventRegistration} ProtobufEventPayloadEventRegistration instance
     */
    ProtobufEventPayloadEventRegistration.create = function create(properties) {
        return new ProtobufEventPayloadEventRegistration(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadEventRegistration message. Does not implicitly {@link ProtobufEventPayloadEventRegistration.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {IProtobufEventPayloadEventRegistration} message ProtobufEventPayloadEventRegistration message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadEventRegistration.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.add != null && Object.hasOwnProperty.call(message, "add"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.add);
        if (message.eventType != null && message.eventType.length) {
            writer.uint32(/* id 2, wireType 2 =*/18).fork();
            for (var i = 0; i < message.eventType.length; ++i)
                writer.int32(message.eventType[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadEventRegistration message, length delimited. Does not implicitly {@link ProtobufEventPayloadEventRegistration.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {IProtobufEventPayloadEventRegistration} message ProtobufEventPayloadEventRegistration message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadEventRegistration.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadEventRegistration message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadEventRegistration} ProtobufEventPayloadEventRegistration
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadEventRegistration.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadEventRegistration();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.add = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.eventType && message.eventType.length))
                        message.eventType = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.eventType.push(reader.int32());
                    } else
                        message.eventType.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadEventRegistration message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadEventRegistration} ProtobufEventPayloadEventRegistration
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadEventRegistration.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadEventRegistration message.
     * @function verify
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadEventRegistration.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.add != null && message.hasOwnProperty("add"))
            if (typeof message.add !== "boolean")
                return "add: boolean expected";
        if (message.eventType != null && message.hasOwnProperty("eventType")) {
            if (!Array.isArray(message.eventType))
                return "eventType: array expected";
            for (var i = 0; i < message.eventType.length; ++i)
                switch (message.eventType[i]) {
                default:
                    return "eventType: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 5:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 7:
                case 15:
                case 16:
                case 8:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 30:
                case 31:
                case 32:
                case 33:
                case 34:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 58:
                case 59:
                case 60:
                case 61:
                case 62:
                case 63:
                case 64:
                case 65:
                case 66:
                case 67:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 76:
                case 77:
                case 78:
                case 79:
                case 140:
                case 141:
                case 142:
                case 143:
                case 144:
                case 146:
                case 147:
                case 148:
                case 149:
                case 80:
                case 81:
                case 82:
                case 83:
                case 84:
                case 85:
                case 86:
                case 87:
                case 90:
                case 91:
                case 92:
                case 93:
                case 94:
                case 95:
                case 96:
                case 97:
                case 98:
                case 99:
                case 100:
                case 110:
                case 114:
                case 112:
                case 113:
                case 111:
                case 120:
                case 121:
                case 130:
                case 131:
                case 150:
                case 151:
                case 152:
                case 160:
                case 170:
                case 171:
                case 180:
                case 181:
                    break;
                }
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadEventRegistration message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadEventRegistration} ProtobufEventPayloadEventRegistration
     */
    ProtobufEventPayloadEventRegistration.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadEventRegistration)
            return object;
        var message = new $root.ProtobufEventPayloadEventRegistration();
        if (object.add != null)
            message.add = Boolean(object.add);
        if (object.eventType) {
            if (!Array.isArray(object.eventType))
                throw TypeError(".ProtobufEventPayloadEventRegistration.eventType: array expected");
            message.eventType = [];
            for (var i = 0; i < object.eventType.length; ++i)
                switch (object.eventType[i]) {
                default:
                    if (typeof object.eventType[i] === "number") {
                        message.eventType[i] = object.eventType[i];
                        break;
                    }
                case "kNoType":
                case 0:
                    message.eventType[i] = 0;
                    break;
                case "kEventRegistrationGetResponse":
                case 1:
                    message.eventType[i] = 1;
                    break;
                case "kEventRegistrationResponse":
                case 2:
                    message.eventType[i] = 2;
                    break;
                case "kEventBridgeQueueStatusGetResponse":
                case 5:
                    message.eventType[i] = 5;
                    break;
                case "kSettingsGetRequest":
                case 9:
                    message.eventType[i] = 9;
                    break;
                case "kSettingsGetResponseMain":
                case 10:
                    message.eventType[i] = 10;
                    break;
                case "kSettingsIdentificationMainMetadata":
                case 11:
                    message.eventType[i] = 11;
                    break;
                case "kSettingsDeviceMainMetadata":
                case 12:
                    message.eventType[i] = 12;
                    break;
                case "kSettingsServerMainMetadata":
                case 13:
                    message.eventType[i] = 13;
                    break;
                case "kSettingsUserMainMetadata":
                case 14:
                    message.eventType[i] = 14;
                    break;
                case "kSettingsInternalMainMetadata":
                case 7:
                    message.eventType[i] = 7;
                    break;
                case "kSettingsUpdateMain":
                case 15:
                    message.eventType[i] = 15;
                    break;
                case "kSettingsIdentificationMainWrite":
                case 16:
                    message.eventType[i] = 16;
                    break;
                case "kSettingsDeviceMainWrite":
                case 8:
                    message.eventType[i] = 8;
                    break;
                case "kSettingsServerMainWrite":
                case 17:
                    message.eventType[i] = 17;
                    break;
                case "kSettingsUserMainWrite":
                case 18:
                    message.eventType[i] = 18;
                    break;
                case "kSettingsIdentificationMainRead":
                case 19:
                    message.eventType[i] = 19;
                    break;
                case "kPowerGetResponse":
                case 20:
                    message.eventType[i] = 20;
                    break;
                case "kPowerGood":
                case 21:
                    message.eventType[i] = 21;
                    break;
                case "kPowerBackup":
                case 22:
                    message.eventType[i] = 22;
                    break;
                case "kPowerCritical":
                case 23:
                    message.eventType[i] = 23;
                    break;
                case "kSessionGetResponse":
                case 30:
                    message.eventType[i] = 30;
                    break;
                case "kSessionRequest":
                case 31:
                    message.eventType[i] = 31;
                    break;
                case "kSessionRequestSent":
                case 32:
                    message.eventType[i] = 32;
                    break;
                case "kSessionResponse":
                case 33:
                    message.eventType[i] = 33;
                    break;
                case "kSessionEstablished":
                case 34:
                    message.eventType[i] = 34;
                    break;
                case "kOperatingModeGetResponse":
                case 40:
                    message.eventType[i] = 40;
                    break;
                case "kOperatingModeTrackingActive":
                case 41:
                    message.eventType[i] = 41;
                    break;
                case "kOperatingModeTrackingInactive":
                case 42:
                    message.eventType[i] = 42;
                    break;
                case "kOperatingModeSosActivated":
                case 43:
                    message.eventType[i] = 43;
                    break;
                case "kOperatingModeSosActive":
                case 44:
                    message.eventType[i] = 44;
                    break;
                case "kOperatingModeSosDeactivated":
                case 45:
                    message.eventType[i] = 45;
                    break;
                case "kOperatingModeSosInactive":
                case 46:
                    message.eventType[i] = 46;
                    break;
                case "kOperatingModeWatchActivated":
                case 47:
                    message.eventType[i] = 47;
                    break;
                case "kOperatingModeWatchActive":
                case 48:
                    message.eventType[i] = 48;
                    break;
                case "kOperatingModeWatchDeactivated":
                case 49:
                    message.eventType[i] = 49;
                    break;
                case "kOperatingModeWatchInactive":
                case 50:
                    message.eventType[i] = 50;
                    break;
                case "kOperatingModeMarkActivated":
                case 51:
                    message.eventType[i] = 51;
                    break;
                case "kOperatingModeMarkActive":
                case 52:
                    message.eventType[i] = 52;
                    break;
                case "kOtaGetResponse":
                case 53:
                    message.eventType[i] = 53;
                    break;
                case "kOtaBootedVersions":
                case 54:
                    message.eventType[i] = 54;
                    break;
                case "kOtaAwaitingNetwork":
                case 55:
                    message.eventType[i] = 55;
                    break;
                case "kOtaAwaitingJob":
                case 56:
                    message.eventType[i] = 56;
                    break;
                case "kOtaDownloading":
                case 57:
                    message.eventType[i] = 57;
                    break;
                case "kOtaIdle":
                case 58:
                    message.eventType[i] = 58;
                    break;
                case "kOtaError":
                case 59:
                    message.eventType[i] = 59;
                    break;
                case "kSpiderTxtTxLogonStatus":
                case 60:
                    message.eventType[i] = 60;
                    break;
                case "kSpiderTxtTxAcknowledgementToPhone":
                case 61:
                    message.eventType[i] = 61;
                    break;
                case "kSpiderTxtTxIncomingTxt":
                case 62:
                    message.eventType[i] = 62;
                    break;
                case "kSpiderTxtTxAckToPhone":
                case 63:
                    message.eventType[i] = 63;
                    break;
                case "kSpiderTxtTxNakToPhone":
                case 64:
                    message.eventType[i] = 64;
                    break;
                case "kSpiderTxtTxBusyToPhone":
                case 65:
                    message.eventType[i] = 65;
                    break;
                case "kSpiderTxtTxOfflineToPhone":
                case 66:
                    message.eventType[i] = 66;
                    break;
                case "kSpiderTxtTxNoIdToPhone":
                case 67:
                    message.eventType[i] = 67;
                    break;
                case "kSettingsGetResponseKeypad":
                case 70:
                    message.eventType[i] = 70;
                    break;
                case "kSettingsIdentificationKeypadMetadata":
                case 71:
                    message.eventType[i] = 71;
                    break;
                case "kSettingsDeviceKeypadMetadata":
                case 72:
                    message.eventType[i] = 72;
                    break;
                case "kSettingsServerKeypadMetadata":
                case 73:
                    message.eventType[i] = 73;
                    break;
                case "kSettingsUserKeypadMetadata":
                case 74:
                    message.eventType[i] = 74;
                    break;
                case "kSettingsIdentificationKeypadWrite":
                case 76:
                    message.eventType[i] = 76;
                    break;
                case "kSettingsServerKeypadWrite":
                case 77:
                    message.eventType[i] = 77;
                    break;
                case "kSettingsUserKeypadWrite":
                case 78:
                    message.eventType[i] = 78;
                    break;
                case "kSettingsIdentificationKeypadRead":
                case 79:
                    message.eventType[i] = 79;
                    break;
                case "kSettingsGetResponseGpi":
                case 140:
                    message.eventType[i] = 140;
                    break;
                case "kSettingsIdentificationGpiMetadata":
                case 141:
                    message.eventType[i] = 141;
                    break;
                case "kSettingsDeviceGpiMetadata":
                case 142:
                    message.eventType[i] = 142;
                    break;
                case "kSettingsServerGpiMetadata":
                case 143:
                    message.eventType[i] = 143;
                    break;
                case "kSettingsUserGpiMetadata":
                case 144:
                    message.eventType[i] = 144;
                    break;
                case "kSettingsIdentificationGpiWrite":
                case 146:
                    message.eventType[i] = 146;
                    break;
                case "kSettingsServerGpiWrite":
                case 147:
                    message.eventType[i] = 147;
                    break;
                case "kSettingsUserGpiWrite":
                case 148:
                    message.eventType[i] = 148;
                    break;
                case "kSettingsIdentificationGpiRead":
                case 149:
                    message.eventType[i] = 149;
                    break;
                case "kPeripheralJoinRequest":
                case 80:
                    message.eventType[i] = 80;
                    break;
                case "kPeripheralJoinResponse":
                case 81:
                    message.eventType[i] = 81;
                    break;
                case "kPeripheralHostGetRequest":
                case 82:
                    message.eventType[i] = 82;
                    break;
                case "kPeripheralHostGetResponse":
                case 83:
                    message.eventType[i] = 83;
                    break;
                case "kPeripheralSlaveGetRequest":
                case 84:
                    message.eventType[i] = 84;
                    break;
                case "kPeripheralSlaveGetResponse":
                case 85:
                    message.eventType[i] = 85;
                    break;
                case "kPeripheralHostConnected":
                case 86:
                    message.eventType[i] = 86;
                    break;
                case "kPeripheralHostDisconnected":
                case 87:
                    message.eventType[i] = 87;
                    break;
                case "kImuConfigurationRequest":
                case 90:
                    message.eventType[i] = 90;
                    break;
                case "kImuConfigurationResponse":
                case 91:
                    message.eventType[i] = 91;
                    break;
                case "kImuConfigurationReport":
                case 92:
                    message.eventType[i] = 92;
                    break;
                case "kImuTestRequest":
                case 93:
                    message.eventType[i] = 93;
                    break;
                case "kImuTestResponse":
                case 94:
                    message.eventType[i] = 94;
                    break;
                case "kImuMeasurementRawAccelerometer":
                case 95:
                    message.eventType[i] = 95;
                    break;
                case "kImuMeasurementRawGyroscope":
                case 96:
                    message.eventType[i] = 96;
                    break;
                case "kImuMeasurementRawMagnetometer":
                case 97:
                    message.eventType[i] = 97;
                    break;
                case "kImuMeasurementPressure":
                case 98:
                    message.eventType[i] = 98;
                    break;
                case "kImuMeasurementTemperature":
                case 99:
                    message.eventType[i] = 99;
                    break;
                case "kImuMeasurementPositionVelocityTime":
                case 100:
                    message.eventType[i] = 100;
                    break;
                case "kNavigationPvtValid":
                case 110:
                    message.eventType[i] = 110;
                    break;
                case "kNavigationPvtInvalid":
                case 114:
                    message.eventType[i] = 114;
                    break;
                case "kNavigationDiagnosticGetResponseFixes":
                case 112:
                    message.eventType[i] = 112;
                    break;
                case "kNavigationDiagnosticGetResponseConstellations":
                case 113:
                    message.eventType[i] = 113;
                    break;
                case "kInertialNavigationSystem":
                case 111:
                    message.eventType[i] = 111;
                    break;
                case "kSnifferDataSerialInbound":
                case 120:
                    message.eventType[i] = 120;
                    break;
                case "kSnifferDataSerialOutbound":
                case 121:
                    message.eventType[i] = 121;
                    break;
                case "kExternalDeviceStateGetResponse":
                case 130:
                    message.eventType[i] = 130;
                    break;
                case "kExternalDeviceStateUpdate":
                case 131:
                    message.eventType[i] = 131;
                    break;
                case "kGpiGetRequest":
                case 150:
                    message.eventType[i] = 150;
                    break;
                case "kGpiGetResponse":
                case 151:
                    message.eventType[i] = 151;
                    break;
                case "kGpiUpdate":
                case 152:
                    message.eventType[i] = 152;
                    break;
                case "kDataBlockDiagnosticGetResponseStorage":
                case 160:
                    message.eventType[i] = 160;
                    break;
                case "kSatelliteNetworkDiagnosticGetResponseSignalQuality":
                case 170:
                    message.eventType[i] = 170;
                    break;
                case "kSatelliteNetworkDiagnosticGetResponseTransmissions":
                case 171:
                    message.eventType[i] = 171;
                    break;
                case "kCellularNetworkDiagnosticGetResponseSignalQuality":
                case 180:
                    message.eventType[i] = 180;
                    break;
                case "kCellularNetworkDiagnosticGetResponseTraffic":
                case 181:
                    message.eventType[i] = 181;
                    break;
                }
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadEventRegistration message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {ProtobufEventPayloadEventRegistration} message ProtobufEventPayloadEventRegistration
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadEventRegistration.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.eventType = [];
        if (options.defaults)
            object.add = false;
        if (message.add != null && message.hasOwnProperty("add"))
            object.add = message.add;
        if (message.eventType && message.eventType.length) {
            object.eventType = [];
            for (var j = 0; j < message.eventType.length; ++j)
                object.eventType[j] = options.enums === String ? $root.ProtobufEventPayloadEventRegistration.EventType[message.eventType[j]] === undefined ? message.eventType[j] : $root.ProtobufEventPayloadEventRegistration.EventType[message.eventType[j]] : message.eventType[j];
        }
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadEventRegistration to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadEventRegistration
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadEventRegistration.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadEventRegistration
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadEventRegistration
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadEventRegistration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadEventRegistration";
    };

    /**
     * EventType enum.
     * @name ProtobufEventPayloadEventRegistration.EventType
     * @enum {number}
     * @property {number} kNoType=0 kNoType value
     * @property {number} kEventRegistrationGetResponse=1 kEventRegistrationGetResponse value
     * @property {number} kEventRegistrationResponse=2 kEventRegistrationResponse value
     * @property {number} kEventBridgeQueueStatusGetResponse=5 kEventBridgeQueueStatusGetResponse value
     * @property {number} kSettingsGetRequest=9 kSettingsGetRequest value
     * @property {number} kSettingsGetResponseMain=10 kSettingsGetResponseMain value
     * @property {number} kSettingsIdentificationMainMetadata=11 kSettingsIdentificationMainMetadata value
     * @property {number} kSettingsDeviceMainMetadata=12 kSettingsDeviceMainMetadata value
     * @property {number} kSettingsServerMainMetadata=13 kSettingsServerMainMetadata value
     * @property {number} kSettingsUserMainMetadata=14 kSettingsUserMainMetadata value
     * @property {number} kSettingsInternalMainMetadata=7 kSettingsInternalMainMetadata value
     * @property {number} kSettingsUpdateMain=15 kSettingsUpdateMain value
     * @property {number} kSettingsIdentificationMainWrite=16 kSettingsIdentificationMainWrite value
     * @property {number} kSettingsDeviceMainWrite=8 kSettingsDeviceMainWrite value
     * @property {number} kSettingsServerMainWrite=17 kSettingsServerMainWrite value
     * @property {number} kSettingsUserMainWrite=18 kSettingsUserMainWrite value
     * @property {number} kSettingsIdentificationMainRead=19 kSettingsIdentificationMainRead value
     * @property {number} kPowerGetResponse=20 kPowerGetResponse value
     * @property {number} kPowerGood=21 kPowerGood value
     * @property {number} kPowerBackup=22 kPowerBackup value
     * @property {number} kPowerCritical=23 kPowerCritical value
     * @property {number} kSessionGetResponse=30 kSessionGetResponse value
     * @property {number} kSessionRequest=31 kSessionRequest value
     * @property {number} kSessionRequestSent=32 kSessionRequestSent value
     * @property {number} kSessionResponse=33 kSessionResponse value
     * @property {number} kSessionEstablished=34 kSessionEstablished value
     * @property {number} kOperatingModeGetResponse=40 kOperatingModeGetResponse value
     * @property {number} kOperatingModeTrackingActive=41 kOperatingModeTrackingActive value
     * @property {number} kOperatingModeTrackingInactive=42 kOperatingModeTrackingInactive value
     * @property {number} kOperatingModeSosActivated=43 kOperatingModeSosActivated value
     * @property {number} kOperatingModeSosActive=44 kOperatingModeSosActive value
     * @property {number} kOperatingModeSosDeactivated=45 kOperatingModeSosDeactivated value
     * @property {number} kOperatingModeSosInactive=46 kOperatingModeSosInactive value
     * @property {number} kOperatingModeWatchActivated=47 kOperatingModeWatchActivated value
     * @property {number} kOperatingModeWatchActive=48 kOperatingModeWatchActive value
     * @property {number} kOperatingModeWatchDeactivated=49 kOperatingModeWatchDeactivated value
     * @property {number} kOperatingModeWatchInactive=50 kOperatingModeWatchInactive value
     * @property {number} kOperatingModeMarkActivated=51 kOperatingModeMarkActivated value
     * @property {number} kOperatingModeMarkActive=52 kOperatingModeMarkActive value
     * @property {number} kOtaGetResponse=53 kOtaGetResponse value
     * @property {number} kOtaBootedVersions=54 kOtaBootedVersions value
     * @property {number} kOtaAwaitingNetwork=55 kOtaAwaitingNetwork value
     * @property {number} kOtaAwaitingJob=56 kOtaAwaitingJob value
     * @property {number} kOtaDownloading=57 kOtaDownloading value
     * @property {number} kOtaIdle=58 kOtaIdle value
     * @property {number} kOtaError=59 kOtaError value
     * @property {number} kSpiderTxtTxLogonStatus=60 kSpiderTxtTxLogonStatus value
     * @property {number} kSpiderTxtTxAcknowledgementToPhone=61 kSpiderTxtTxAcknowledgementToPhone value
     * @property {number} kSpiderTxtTxIncomingTxt=62 kSpiderTxtTxIncomingTxt value
     * @property {number} kSpiderTxtTxAckToPhone=63 kSpiderTxtTxAckToPhone value
     * @property {number} kSpiderTxtTxNakToPhone=64 kSpiderTxtTxNakToPhone value
     * @property {number} kSpiderTxtTxBusyToPhone=65 kSpiderTxtTxBusyToPhone value
     * @property {number} kSpiderTxtTxOfflineToPhone=66 kSpiderTxtTxOfflineToPhone value
     * @property {number} kSpiderTxtTxNoIdToPhone=67 kSpiderTxtTxNoIdToPhone value
     * @property {number} kSettingsGetResponseKeypad=70 kSettingsGetResponseKeypad value
     * @property {number} kSettingsIdentificationKeypadMetadata=71 kSettingsIdentificationKeypadMetadata value
     * @property {number} kSettingsDeviceKeypadMetadata=72 kSettingsDeviceKeypadMetadata value
     * @property {number} kSettingsServerKeypadMetadata=73 kSettingsServerKeypadMetadata value
     * @property {number} kSettingsUserKeypadMetadata=74 kSettingsUserKeypadMetadata value
     * @property {number} kSettingsIdentificationKeypadWrite=76 kSettingsIdentificationKeypadWrite value
     * @property {number} kSettingsServerKeypadWrite=77 kSettingsServerKeypadWrite value
     * @property {number} kSettingsUserKeypadWrite=78 kSettingsUserKeypadWrite value
     * @property {number} kSettingsIdentificationKeypadRead=79 kSettingsIdentificationKeypadRead value
     * @property {number} kSettingsGetResponseGpi=140 kSettingsGetResponseGpi value
     * @property {number} kSettingsIdentificationGpiMetadata=141 kSettingsIdentificationGpiMetadata value
     * @property {number} kSettingsDeviceGpiMetadata=142 kSettingsDeviceGpiMetadata value
     * @property {number} kSettingsServerGpiMetadata=143 kSettingsServerGpiMetadata value
     * @property {number} kSettingsUserGpiMetadata=144 kSettingsUserGpiMetadata value
     * @property {number} kSettingsIdentificationGpiWrite=146 kSettingsIdentificationGpiWrite value
     * @property {number} kSettingsServerGpiWrite=147 kSettingsServerGpiWrite value
     * @property {number} kSettingsUserGpiWrite=148 kSettingsUserGpiWrite value
     * @property {number} kSettingsIdentificationGpiRead=149 kSettingsIdentificationGpiRead value
     * @property {number} kPeripheralJoinRequest=80 kPeripheralJoinRequest value
     * @property {number} kPeripheralJoinResponse=81 kPeripheralJoinResponse value
     * @property {number} kPeripheralHostGetRequest=82 kPeripheralHostGetRequest value
     * @property {number} kPeripheralHostGetResponse=83 kPeripheralHostGetResponse value
     * @property {number} kPeripheralSlaveGetRequest=84 kPeripheralSlaveGetRequest value
     * @property {number} kPeripheralSlaveGetResponse=85 kPeripheralSlaveGetResponse value
     * @property {number} kPeripheralHostConnected=86 kPeripheralHostConnected value
     * @property {number} kPeripheralHostDisconnected=87 kPeripheralHostDisconnected value
     * @property {number} kImuConfigurationRequest=90 kImuConfigurationRequest value
     * @property {number} kImuConfigurationResponse=91 kImuConfigurationResponse value
     * @property {number} kImuConfigurationReport=92 kImuConfigurationReport value
     * @property {number} kImuTestRequest=93 kImuTestRequest value
     * @property {number} kImuTestResponse=94 kImuTestResponse value
     * @property {number} kImuMeasurementRawAccelerometer=95 kImuMeasurementRawAccelerometer value
     * @property {number} kImuMeasurementRawGyroscope=96 kImuMeasurementRawGyroscope value
     * @property {number} kImuMeasurementRawMagnetometer=97 kImuMeasurementRawMagnetometer value
     * @property {number} kImuMeasurementPressure=98 kImuMeasurementPressure value
     * @property {number} kImuMeasurementTemperature=99 kImuMeasurementTemperature value
     * @property {number} kImuMeasurementPositionVelocityTime=100 kImuMeasurementPositionVelocityTime value
     * @property {number} kNavigationPvtValid=110 kNavigationPvtValid value
     * @property {number} kNavigationPvtInvalid=114 kNavigationPvtInvalid value
     * @property {number} kNavigationDiagnosticGetResponseFixes=112 kNavigationDiagnosticGetResponseFixes value
     * @property {number} kNavigationDiagnosticGetResponseConstellations=113 kNavigationDiagnosticGetResponseConstellations value
     * @property {number} kInertialNavigationSystem=111 kInertialNavigationSystem value
     * @property {number} kSnifferDataSerialInbound=120 kSnifferDataSerialInbound value
     * @property {number} kSnifferDataSerialOutbound=121 kSnifferDataSerialOutbound value
     * @property {number} kExternalDeviceStateGetResponse=130 kExternalDeviceStateGetResponse value
     * @property {number} kExternalDeviceStateUpdate=131 kExternalDeviceStateUpdate value
     * @property {number} kGpiGetRequest=150 kGpiGetRequest value
     * @property {number} kGpiGetResponse=151 kGpiGetResponse value
     * @property {number} kGpiUpdate=152 kGpiUpdate value
     * @property {number} kDataBlockDiagnosticGetResponseStorage=160 kDataBlockDiagnosticGetResponseStorage value
     * @property {number} kSatelliteNetworkDiagnosticGetResponseSignalQuality=170 kSatelliteNetworkDiagnosticGetResponseSignalQuality value
     * @property {number} kSatelliteNetworkDiagnosticGetResponseTransmissions=171 kSatelliteNetworkDiagnosticGetResponseTransmissions value
     * @property {number} kCellularNetworkDiagnosticGetResponseSignalQuality=180 kCellularNetworkDiagnosticGetResponseSignalQuality value
     * @property {number} kCellularNetworkDiagnosticGetResponseTraffic=181 kCellularNetworkDiagnosticGetResponseTraffic value
     */
    ProtobufEventPayloadEventRegistration.EventType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoType"] = 0;
        values[valuesById[1] = "kEventRegistrationGetResponse"] = 1;
        values[valuesById[2] = "kEventRegistrationResponse"] = 2;
        values[valuesById[5] = "kEventBridgeQueueStatusGetResponse"] = 5;
        values[valuesById[9] = "kSettingsGetRequest"] = 9;
        values[valuesById[10] = "kSettingsGetResponseMain"] = 10;
        values[valuesById[11] = "kSettingsIdentificationMainMetadata"] = 11;
        values[valuesById[12] = "kSettingsDeviceMainMetadata"] = 12;
        values[valuesById[13] = "kSettingsServerMainMetadata"] = 13;
        values[valuesById[14] = "kSettingsUserMainMetadata"] = 14;
        values[valuesById[7] = "kSettingsInternalMainMetadata"] = 7;
        values[valuesById[15] = "kSettingsUpdateMain"] = 15;
        values[valuesById[16] = "kSettingsIdentificationMainWrite"] = 16;
        values[valuesById[8] = "kSettingsDeviceMainWrite"] = 8;
        values[valuesById[17] = "kSettingsServerMainWrite"] = 17;
        values[valuesById[18] = "kSettingsUserMainWrite"] = 18;
        values[valuesById[19] = "kSettingsIdentificationMainRead"] = 19;
        values[valuesById[20] = "kPowerGetResponse"] = 20;
        values[valuesById[21] = "kPowerGood"] = 21;
        values[valuesById[22] = "kPowerBackup"] = 22;
        values[valuesById[23] = "kPowerCritical"] = 23;
        values[valuesById[30] = "kSessionGetResponse"] = 30;
        values[valuesById[31] = "kSessionRequest"] = 31;
        values[valuesById[32] = "kSessionRequestSent"] = 32;
        values[valuesById[33] = "kSessionResponse"] = 33;
        values[valuesById[34] = "kSessionEstablished"] = 34;
        values[valuesById[40] = "kOperatingModeGetResponse"] = 40;
        values[valuesById[41] = "kOperatingModeTrackingActive"] = 41;
        values[valuesById[42] = "kOperatingModeTrackingInactive"] = 42;
        values[valuesById[43] = "kOperatingModeSosActivated"] = 43;
        values[valuesById[44] = "kOperatingModeSosActive"] = 44;
        values[valuesById[45] = "kOperatingModeSosDeactivated"] = 45;
        values[valuesById[46] = "kOperatingModeSosInactive"] = 46;
        values[valuesById[47] = "kOperatingModeWatchActivated"] = 47;
        values[valuesById[48] = "kOperatingModeWatchActive"] = 48;
        values[valuesById[49] = "kOperatingModeWatchDeactivated"] = 49;
        values[valuesById[50] = "kOperatingModeWatchInactive"] = 50;
        values[valuesById[51] = "kOperatingModeMarkActivated"] = 51;
        values[valuesById[52] = "kOperatingModeMarkActive"] = 52;
        values[valuesById[53] = "kOtaGetResponse"] = 53;
        values[valuesById[54] = "kOtaBootedVersions"] = 54;
        values[valuesById[55] = "kOtaAwaitingNetwork"] = 55;
        values[valuesById[56] = "kOtaAwaitingJob"] = 56;
        values[valuesById[57] = "kOtaDownloading"] = 57;
        values[valuesById[58] = "kOtaIdle"] = 58;
        values[valuesById[59] = "kOtaError"] = 59;
        values[valuesById[60] = "kSpiderTxtTxLogonStatus"] = 60;
        values[valuesById[61] = "kSpiderTxtTxAcknowledgementToPhone"] = 61;
        values[valuesById[62] = "kSpiderTxtTxIncomingTxt"] = 62;
        values[valuesById[63] = "kSpiderTxtTxAckToPhone"] = 63;
        values[valuesById[64] = "kSpiderTxtTxNakToPhone"] = 64;
        values[valuesById[65] = "kSpiderTxtTxBusyToPhone"] = 65;
        values[valuesById[66] = "kSpiderTxtTxOfflineToPhone"] = 66;
        values[valuesById[67] = "kSpiderTxtTxNoIdToPhone"] = 67;
        values[valuesById[70] = "kSettingsGetResponseKeypad"] = 70;
        values[valuesById[71] = "kSettingsIdentificationKeypadMetadata"] = 71;
        values[valuesById[72] = "kSettingsDeviceKeypadMetadata"] = 72;
        values[valuesById[73] = "kSettingsServerKeypadMetadata"] = 73;
        values[valuesById[74] = "kSettingsUserKeypadMetadata"] = 74;
        values[valuesById[76] = "kSettingsIdentificationKeypadWrite"] = 76;
        values[valuesById[77] = "kSettingsServerKeypadWrite"] = 77;
        values[valuesById[78] = "kSettingsUserKeypadWrite"] = 78;
        values[valuesById[79] = "kSettingsIdentificationKeypadRead"] = 79;
        values[valuesById[140] = "kSettingsGetResponseGpi"] = 140;
        values[valuesById[141] = "kSettingsIdentificationGpiMetadata"] = 141;
        values[valuesById[142] = "kSettingsDeviceGpiMetadata"] = 142;
        values[valuesById[143] = "kSettingsServerGpiMetadata"] = 143;
        values[valuesById[144] = "kSettingsUserGpiMetadata"] = 144;
        values[valuesById[146] = "kSettingsIdentificationGpiWrite"] = 146;
        values[valuesById[147] = "kSettingsServerGpiWrite"] = 147;
        values[valuesById[148] = "kSettingsUserGpiWrite"] = 148;
        values[valuesById[149] = "kSettingsIdentificationGpiRead"] = 149;
        values[valuesById[80] = "kPeripheralJoinRequest"] = 80;
        values[valuesById[81] = "kPeripheralJoinResponse"] = 81;
        values[valuesById[82] = "kPeripheralHostGetRequest"] = 82;
        values[valuesById[83] = "kPeripheralHostGetResponse"] = 83;
        values[valuesById[84] = "kPeripheralSlaveGetRequest"] = 84;
        values[valuesById[85] = "kPeripheralSlaveGetResponse"] = 85;
        values[valuesById[86] = "kPeripheralHostConnected"] = 86;
        values[valuesById[87] = "kPeripheralHostDisconnected"] = 87;
        values[valuesById[90] = "kImuConfigurationRequest"] = 90;
        values[valuesById[91] = "kImuConfigurationResponse"] = 91;
        values[valuesById[92] = "kImuConfigurationReport"] = 92;
        values[valuesById[93] = "kImuTestRequest"] = 93;
        values[valuesById[94] = "kImuTestResponse"] = 94;
        values[valuesById[95] = "kImuMeasurementRawAccelerometer"] = 95;
        values[valuesById[96] = "kImuMeasurementRawGyroscope"] = 96;
        values[valuesById[97] = "kImuMeasurementRawMagnetometer"] = 97;
        values[valuesById[98] = "kImuMeasurementPressure"] = 98;
        values[valuesById[99] = "kImuMeasurementTemperature"] = 99;
        values[valuesById[100] = "kImuMeasurementPositionVelocityTime"] = 100;
        values[valuesById[110] = "kNavigationPvtValid"] = 110;
        values[valuesById[114] = "kNavigationPvtInvalid"] = 114;
        values[valuesById[112] = "kNavigationDiagnosticGetResponseFixes"] = 112;
        values[valuesById[113] = "kNavigationDiagnosticGetResponseConstellations"] = 113;
        values[valuesById[111] = "kInertialNavigationSystem"] = 111;
        values[valuesById[120] = "kSnifferDataSerialInbound"] = 120;
        values[valuesById[121] = "kSnifferDataSerialOutbound"] = 121;
        values[valuesById[130] = "kExternalDeviceStateGetResponse"] = 130;
        values[valuesById[131] = "kExternalDeviceStateUpdate"] = 131;
        values[valuesById[150] = "kGpiGetRequest"] = 150;
        values[valuesById[151] = "kGpiGetResponse"] = 151;
        values[valuesById[152] = "kGpiUpdate"] = 152;
        values[valuesById[160] = "kDataBlockDiagnosticGetResponseStorage"] = 160;
        values[valuesById[170] = "kSatelliteNetworkDiagnosticGetResponseSignalQuality"] = 170;
        values[valuesById[171] = "kSatelliteNetworkDiagnosticGetResponseTransmissions"] = 171;
        values[valuesById[180] = "kCellularNetworkDiagnosticGetResponseSignalQuality"] = 180;
        values[valuesById[181] = "kCellularNetworkDiagnosticGetResponseTraffic"] = 181;
        return values;
    })();

    return ProtobufEventPayloadEventRegistration;
})();

$root.ProtobufEventPayloadEventBridge = (function() {

    /**
     * Properties of a ProtobufEventPayloadEventBridge.
     * @exports IProtobufEventPayloadEventBridge
     * @interface IProtobufEventPayloadEventBridge
     * @property {ProtobufEventPayloadEventBridge.Channel|null} [channel] ProtobufEventPayloadEventBridge channel
     * @property {number|null} [pendingTransmittablesCount] ProtobufEventPayloadEventBridge pendingTransmittablesCount
     * @property {number|null} [sentTransmittablesCount] ProtobufEventPayloadEventBridge sentTransmittablesCount
     * @property {number|null} [oldestPendingTransmittableCreatedUptimeMs] ProtobufEventPayloadEventBridge oldestPendingTransmittableCreatedUptimeMs
     * @property {number|null} [currentUptimeMs] ProtobufEventPayloadEventBridge currentUptimeMs
     */

    /**
     * Constructs a new ProtobufEventPayloadEventBridge.
     * @exports ProtobufEventPayloadEventBridge
     * @classdesc Represents a ProtobufEventPayloadEventBridge.
     * @implements IProtobufEventPayloadEventBridge
     * @constructor
     * @param {IProtobufEventPayloadEventBridge=} [properties] Properties to set
     */
    function ProtobufEventPayloadEventBridge(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadEventBridge channel.
     * @member {ProtobufEventPayloadEventBridge.Channel} channel
     * @memberof ProtobufEventPayloadEventBridge
     * @instance
     */
    ProtobufEventPayloadEventBridge.prototype.channel = 0;

    /**
     * ProtobufEventPayloadEventBridge pendingTransmittablesCount.
     * @member {number} pendingTransmittablesCount
     * @memberof ProtobufEventPayloadEventBridge
     * @instance
     */
    ProtobufEventPayloadEventBridge.prototype.pendingTransmittablesCount = 0;

    /**
     * ProtobufEventPayloadEventBridge sentTransmittablesCount.
     * @member {number} sentTransmittablesCount
     * @memberof ProtobufEventPayloadEventBridge
     * @instance
     */
    ProtobufEventPayloadEventBridge.prototype.sentTransmittablesCount = 0;

    /**
     * ProtobufEventPayloadEventBridge oldestPendingTransmittableCreatedUptimeMs.
     * @member {number} oldestPendingTransmittableCreatedUptimeMs
     * @memberof ProtobufEventPayloadEventBridge
     * @instance
     */
    ProtobufEventPayloadEventBridge.prototype.oldestPendingTransmittableCreatedUptimeMs = 0;

    /**
     * ProtobufEventPayloadEventBridge currentUptimeMs.
     * @member {number} currentUptimeMs
     * @memberof ProtobufEventPayloadEventBridge
     * @instance
     */
    ProtobufEventPayloadEventBridge.prototype.currentUptimeMs = 0;

    /**
     * Creates a new ProtobufEventPayloadEventBridge instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {IProtobufEventPayloadEventBridge=} [properties] Properties to set
     * @returns {ProtobufEventPayloadEventBridge} ProtobufEventPayloadEventBridge instance
     */
    ProtobufEventPayloadEventBridge.create = function create(properties) {
        return new ProtobufEventPayloadEventBridge(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadEventBridge message. Does not implicitly {@link ProtobufEventPayloadEventBridge.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {IProtobufEventPayloadEventBridge} message ProtobufEventPayloadEventBridge message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadEventBridge.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.channel);
        if (message.pendingTransmittablesCount != null && Object.hasOwnProperty.call(message, "pendingTransmittablesCount"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.pendingTransmittablesCount);
        if (message.sentTransmittablesCount != null && Object.hasOwnProperty.call(message, "sentTransmittablesCount"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.sentTransmittablesCount);
        if (message.oldestPendingTransmittableCreatedUptimeMs != null && Object.hasOwnProperty.call(message, "oldestPendingTransmittableCreatedUptimeMs"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.oldestPendingTransmittableCreatedUptimeMs);
        if (message.currentUptimeMs != null && Object.hasOwnProperty.call(message, "currentUptimeMs"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.currentUptimeMs);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadEventBridge message, length delimited. Does not implicitly {@link ProtobufEventPayloadEventBridge.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {IProtobufEventPayloadEventBridge} message ProtobufEventPayloadEventBridge message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadEventBridge.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadEventBridge message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadEventBridge} ProtobufEventPayloadEventBridge
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadEventBridge.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadEventBridge();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.channel = reader.int32();
                    break;
                }
            case 2: {
                    message.pendingTransmittablesCount = reader.uint32();
                    break;
                }
            case 3: {
                    message.sentTransmittablesCount = reader.uint32();
                    break;
                }
            case 4: {
                    message.oldestPendingTransmittableCreatedUptimeMs = reader.uint32();
                    break;
                }
            case 5: {
                    message.currentUptimeMs = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadEventBridge message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadEventBridge} ProtobufEventPayloadEventBridge
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadEventBridge.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadEventBridge message.
     * @function verify
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadEventBridge.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.channel != null && message.hasOwnProperty("channel"))
            switch (message.channel) {
            default:
                return "channel: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.pendingTransmittablesCount != null && message.hasOwnProperty("pendingTransmittablesCount"))
            if (!$util.isInteger(message.pendingTransmittablesCount))
                return "pendingTransmittablesCount: integer expected";
        if (message.sentTransmittablesCount != null && message.hasOwnProperty("sentTransmittablesCount"))
            if (!$util.isInteger(message.sentTransmittablesCount))
                return "sentTransmittablesCount: integer expected";
        if (message.oldestPendingTransmittableCreatedUptimeMs != null && message.hasOwnProperty("oldestPendingTransmittableCreatedUptimeMs"))
            if (!$util.isInteger(message.oldestPendingTransmittableCreatedUptimeMs))
                return "oldestPendingTransmittableCreatedUptimeMs: integer expected";
        if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
            if (!$util.isInteger(message.currentUptimeMs))
                return "currentUptimeMs: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadEventBridge message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadEventBridge} ProtobufEventPayloadEventBridge
     */
    ProtobufEventPayloadEventBridge.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadEventBridge)
            return object;
        var message = new $root.ProtobufEventPayloadEventBridge();
        switch (object.channel) {
        default:
            if (typeof object.channel === "number") {
                message.channel = object.channel;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.channel = 0;
            break;
        case "kSatellite":
        case 1:
            message.channel = 1;
            break;
        case "kCellular":
        case 2:
            message.channel = 2;
            break;
        }
        if (object.pendingTransmittablesCount != null)
            message.pendingTransmittablesCount = object.pendingTransmittablesCount >>> 0;
        if (object.sentTransmittablesCount != null)
            message.sentTransmittablesCount = object.sentTransmittablesCount >>> 0;
        if (object.oldestPendingTransmittableCreatedUptimeMs != null)
            message.oldestPendingTransmittableCreatedUptimeMs = object.oldestPendingTransmittableCreatedUptimeMs >>> 0;
        if (object.currentUptimeMs != null)
            message.currentUptimeMs = object.currentUptimeMs >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadEventBridge message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {ProtobufEventPayloadEventBridge} message ProtobufEventPayloadEventBridge
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadEventBridge.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.channel = options.enums === String ? "kNone" : 0;
            object.pendingTransmittablesCount = 0;
            object.sentTransmittablesCount = 0;
            object.oldestPendingTransmittableCreatedUptimeMs = 0;
            object.currentUptimeMs = 0;
        }
        if (message.channel != null && message.hasOwnProperty("channel"))
            object.channel = options.enums === String ? $root.ProtobufEventPayloadEventBridge.Channel[message.channel] === undefined ? message.channel : $root.ProtobufEventPayloadEventBridge.Channel[message.channel] : message.channel;
        if (message.pendingTransmittablesCount != null && message.hasOwnProperty("pendingTransmittablesCount"))
            object.pendingTransmittablesCount = message.pendingTransmittablesCount;
        if (message.sentTransmittablesCount != null && message.hasOwnProperty("sentTransmittablesCount"))
            object.sentTransmittablesCount = message.sentTransmittablesCount;
        if (message.oldestPendingTransmittableCreatedUptimeMs != null && message.hasOwnProperty("oldestPendingTransmittableCreatedUptimeMs"))
            object.oldestPendingTransmittableCreatedUptimeMs = message.oldestPendingTransmittableCreatedUptimeMs;
        if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
            object.currentUptimeMs = message.currentUptimeMs;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadEventBridge to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadEventBridge
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadEventBridge.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadEventBridge
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadEventBridge
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadEventBridge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadEventBridge";
    };

    /**
     * Channel enum.
     * @name ProtobufEventPayloadEventBridge.Channel
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kSatellite=1 kSatellite value
     * @property {number} kCellular=2 kCellular value
     */
    ProtobufEventPayloadEventBridge.Channel = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kSatellite"] = 1;
        values[valuesById[2] = "kCellular"] = 2;
        return values;
    })();

    return ProtobufEventPayloadEventBridge;
})();

$root.ProtobufEventPayloadSettingsMetadata = (function() {

    /**
     * Properties of a ProtobufEventPayloadSettingsMetadata.
     * @exports IProtobufEventPayloadSettingsMetadata
     * @interface IProtobufEventPayloadSettingsMetadata
     * @property {ProtobufEventPayloadSettingsMetadata.Type|null} [type] ProtobufEventPayloadSettingsMetadata type
     * @property {number|null} [version] ProtobufEventPayloadSettingsMetadata version
     * @property {ProtobufEventPayloadSettingsMetadata.State|null} [readState] ProtobufEventPayloadSettingsMetadata readState
     * @property {ProtobufEventPayloadSettingsMetadata.State|null} [updateState] ProtobufEventPayloadSettingsMetadata updateState
     * @property {number|null} [crc] ProtobufEventPayloadSettingsMetadata crc
     */

    /**
     * Constructs a new ProtobufEventPayloadSettingsMetadata.
     * @exports ProtobufEventPayloadSettingsMetadata
     * @classdesc Represents a ProtobufEventPayloadSettingsMetadata.
     * @implements IProtobufEventPayloadSettingsMetadata
     * @constructor
     * @param {IProtobufEventPayloadSettingsMetadata=} [properties] Properties to set
     */
    function ProtobufEventPayloadSettingsMetadata(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadSettingsMetadata type.
     * @member {ProtobufEventPayloadSettingsMetadata.Type} type
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @instance
     */
    ProtobufEventPayloadSettingsMetadata.prototype.type = 0;

    /**
     * ProtobufEventPayloadSettingsMetadata version.
     * @member {number} version
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @instance
     */
    ProtobufEventPayloadSettingsMetadata.prototype.version = 0;

    /**
     * ProtobufEventPayloadSettingsMetadata readState.
     * @member {ProtobufEventPayloadSettingsMetadata.State} readState
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @instance
     */
    ProtobufEventPayloadSettingsMetadata.prototype.readState = 0;

    /**
     * ProtobufEventPayloadSettingsMetadata updateState.
     * @member {ProtobufEventPayloadSettingsMetadata.State} updateState
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @instance
     */
    ProtobufEventPayloadSettingsMetadata.prototype.updateState = 0;

    /**
     * ProtobufEventPayloadSettingsMetadata crc.
     * @member {number} crc
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @instance
     */
    ProtobufEventPayloadSettingsMetadata.prototype.crc = 0;

    /**
     * Creates a new ProtobufEventPayloadSettingsMetadata instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {IProtobufEventPayloadSettingsMetadata=} [properties] Properties to set
     * @returns {ProtobufEventPayloadSettingsMetadata} ProtobufEventPayloadSettingsMetadata instance
     */
    ProtobufEventPayloadSettingsMetadata.create = function create(properties) {
        return new ProtobufEventPayloadSettingsMetadata(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsMetadata message. Does not implicitly {@link ProtobufEventPayloadSettingsMetadata.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {IProtobufEventPayloadSettingsMetadata} message ProtobufEventPayloadSettingsMetadata message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsMetadata.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.version);
        if (message.readState != null && Object.hasOwnProperty.call(message, "readState"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.readState);
        if (message.updateState != null && Object.hasOwnProperty.call(message, "updateState"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.updateState);
        if (message.crc != null && Object.hasOwnProperty.call(message, "crc"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.crc);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsMetadata message, length delimited. Does not implicitly {@link ProtobufEventPayloadSettingsMetadata.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {IProtobufEventPayloadSettingsMetadata} message ProtobufEventPayloadSettingsMetadata message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsMetadata.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsMetadata message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadSettingsMetadata} ProtobufEventPayloadSettingsMetadata
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsMetadata.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSettingsMetadata();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.version = reader.uint32();
                    break;
                }
            case 3: {
                    message.readState = reader.int32();
                    break;
                }
            case 4: {
                    message.updateState = reader.int32();
                    break;
                }
            case 5: {
                    message.crc = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsMetadata message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadSettingsMetadata} ProtobufEventPayloadSettingsMetadata
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsMetadata.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadSettingsMetadata message.
     * @function verify
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadSettingsMetadata.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isInteger(message.version))
                return "version: integer expected";
        if (message.readState != null && message.hasOwnProperty("readState"))
            switch (message.readState) {
            default:
                return "readState: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
                break;
            }
        if (message.updateState != null && message.hasOwnProperty("updateState"))
            switch (message.updateState) {
            default:
                return "updateState: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
                break;
            }
        if (message.crc != null && message.hasOwnProperty("crc"))
            if (!$util.isInteger(message.crc))
                return "crc: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadSettingsMetadata message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadSettingsMetadata} ProtobufEventPayloadSettingsMetadata
     */
    ProtobufEventPayloadSettingsMetadata.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadSettingsMetadata)
            return object;
        var message = new $root.ProtobufEventPayloadSettingsMetadata();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "kNoType":
        case 0:
            message.type = 0;
            break;
        case "kIdentification":
        case 1:
            message.type = 1;
            break;
        case "kDevice":
        case 2:
            message.type = 2;
            break;
        case "kServer":
        case 3:
            message.type = 3;
            break;
        case "kUser":
        case 4:
            message.type = 4;
            break;
        case "kInternal":
        case 5:
            message.type = 5;
            break;
        }
        if (object.version != null)
            message.version = object.version >>> 0;
        switch (object.readState) {
        default:
            if (typeof object.readState === "number") {
                message.readState = object.readState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.readState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.readState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.readState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.readState = 3;
            break;
        case "kDecoded":
        case 4:
            message.readState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.readState = 5;
            break;
        case "kEncoded":
        case 6:
            message.readState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.readState = 7;
            break;
        case "kWritten":
        case 8:
            message.readState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.readState = 9;
            break;
        case "kLocked":
        case 10:
            message.readState = 10;
            break;
        }
        switch (object.updateState) {
        default:
            if (typeof object.updateState === "number") {
                message.updateState = object.updateState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.updateState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.updateState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.updateState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.updateState = 3;
            break;
        case "kDecoded":
        case 4:
            message.updateState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.updateState = 5;
            break;
        case "kEncoded":
        case 6:
            message.updateState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.updateState = 7;
            break;
        case "kWritten":
        case 8:
            message.updateState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.updateState = 9;
            break;
        case "kLocked":
        case 10:
            message.updateState = 10;
            break;
        }
        if (object.crc != null)
            message.crc = object.crc >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadSettingsMetadata message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {ProtobufEventPayloadSettingsMetadata} message ProtobufEventPayloadSettingsMetadata
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadSettingsMetadata.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "kNoType" : 0;
            object.version = 0;
            object.readState = options.enums === String ? "kNone" : 0;
            object.updateState = options.enums === String ? "kNone" : 0;
            object.crc = 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ProtobufEventPayloadSettingsMetadata.Type[message.type] === undefined ? message.type : $root.ProtobufEventPayloadSettingsMetadata.Type[message.type] : message.type;
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        if (message.readState != null && message.hasOwnProperty("readState"))
            object.readState = options.enums === String ? $root.ProtobufEventPayloadSettingsMetadata.State[message.readState] === undefined ? message.readState : $root.ProtobufEventPayloadSettingsMetadata.State[message.readState] : message.readState;
        if (message.updateState != null && message.hasOwnProperty("updateState"))
            object.updateState = options.enums === String ? $root.ProtobufEventPayloadSettingsMetadata.State[message.updateState] === undefined ? message.updateState : $root.ProtobufEventPayloadSettingsMetadata.State[message.updateState] : message.updateState;
        if (message.crc != null && message.hasOwnProperty("crc"))
            object.crc = message.crc;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadSettingsMetadata to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadSettingsMetadata.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadSettingsMetadata
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadSettingsMetadata
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadSettingsMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadSettingsMetadata";
    };

    /**
     * Type enum.
     * @name ProtobufEventPayloadSettingsMetadata.Type
     * @enum {number}
     * @property {number} kNoType=0 kNoType value
     * @property {number} kIdentification=1 kIdentification value
     * @property {number} kDevice=2 kDevice value
     * @property {number} kServer=3 kServer value
     * @property {number} kUser=4 kUser value
     * @property {number} kInternal=5 kInternal value
     */
    ProtobufEventPayloadSettingsMetadata.Type = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoType"] = 0;
        values[valuesById[1] = "kIdentification"] = 1;
        values[valuesById[2] = "kDevice"] = 2;
        values[valuesById[3] = "kServer"] = 3;
        values[valuesById[4] = "kUser"] = 4;
        values[valuesById[5] = "kInternal"] = 5;
        return values;
    })();

    /**
     * State enum.
     * @name ProtobufEventPayloadSettingsMetadata.State
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kReadFailed=1 kReadFailed value
     * @property {number} kLengthInvalid=2 kLengthInvalid value
     * @property {number} kCrcInvalid=3 kCrcInvalid value
     * @property {number} kDecoded=4 kDecoded value
     * @property {number} kDecodeFailed=5 kDecodeFailed value
     * @property {number} kEncoded=6 kEncoded value
     * @property {number} kEncodeFailed=7 kEncodeFailed value
     * @property {number} kWritten=8 kWritten value
     * @property {number} kWriteFailed=9 kWriteFailed value
     * @property {number} kLocked=10 kLocked value
     */
    ProtobufEventPayloadSettingsMetadata.State = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kReadFailed"] = 1;
        values[valuesById[2] = "kLengthInvalid"] = 2;
        values[valuesById[3] = "kCrcInvalid"] = 3;
        values[valuesById[4] = "kDecoded"] = 4;
        values[valuesById[5] = "kDecodeFailed"] = 5;
        values[valuesById[6] = "kEncoded"] = 6;
        values[valuesById[7] = "kEncodeFailed"] = 7;
        values[valuesById[8] = "kWritten"] = 8;
        values[valuesById[9] = "kWriteFailed"] = 9;
        values[valuesById[10] = "kLocked"] = 10;
        return values;
    })();

    return ProtobufEventPayloadSettingsMetadata;
})();

$root.ProtobufEventPayloadSettingsMain = (function() {

    /**
     * Properties of a ProtobufEventPayloadSettingsMain.
     * @exports IProtobufEventPayloadSettingsMain
     * @interface IProtobufEventPayloadSettingsMain
     * @property {number|null} [pcbVersion] ProtobufEventPayloadSettingsMain pcbVersion
     * @property {number|null} [pcaVariant] ProtobufEventPayloadSettingsMain pcaVariant
     * @property {number|null} [firmwareVersion] ProtobufEventPayloadSettingsMain firmwareVersion
     * @property {boolean|null} [antennaSatelliteExternal] ProtobufEventPayloadSettingsMain antennaSatelliteExternal
     * @property {boolean|null} [antennaCellularExternal] ProtobufEventPayloadSettingsMain antennaCellularExternal
     * @property {ProtobufSettingsUserMainV1.Brightness|null} [ledBrightnessStandard] ProtobufEventPayloadSettingsMain ledBrightnessStandard
     * @property {ProtobufSettingsUserMainV1.Brightness|null} [ledBrightnessOverride] ProtobufEventPayloadSettingsMain ledBrightnessOverride
     * @property {number|null} [ledBrightnessMaxPct] ProtobufEventPayloadSettingsMain ledBrightnessMaxPct
     * @property {number|null} [ledBrightnessMinPct] ProtobufEventPayloadSettingsMain ledBrightnessMinPct
     * @property {boolean|null} [buzzerEnabledWhenWatchActiveOnBackup] ProtobufEventPayloadSettingsMain buzzerEnabledWhenWatchActiveOnBackup
     * @property {boolean|null} [buzzerEnabledWhenSerialDeviceMissing] ProtobufEventPayloadSettingsMain buzzerEnabledWhenSerialDeviceMissing
     * @property {boolean|null} [serialDeviceRequiredForActiveTracking] ProtobufEventPayloadSettingsMain serialDeviceRequiredForActiveTracking
     * @property {ProtobufSettingsUserMainV1.Mode|null} [serialMode] ProtobufEventPayloadSettingsMain serialMode
     * @property {boolean|null} [serialRs485Interface] ProtobufEventPayloadSettingsMain serialRs485Interface
     * @property {number|null} [serialBaudRate] ProtobufEventPayloadSettingsMain serialBaudRate
     * @property {ProtobufSettingsUserMainV1.Parity|null} [serialParity] ProtobufEventPayloadSettingsMain serialParity
     * @property {ProtobufSettingsUserMainV1.Device|null} [serialDevice] ProtobufEventPayloadSettingsMain serialDevice
     * @property {ProtobufSettingsUserMainV1.Protocol|null} [serialProtocol] ProtobufEventPayloadSettingsMain serialProtocol
     * @property {ProtobufSettingsServerMainV1.LogLevel|null} [serialDebugProtocolLogLevel] ProtobufEventPayloadSettingsMain serialDebugProtocolLogLevel
     * @property {ProtobufSettingsUserMainV1.Delimiter|null} [serialAsciiPacketDelimiter] ProtobufEventPayloadSettingsMain serialAsciiPacketDelimiter
     * @property {ProtobufSettingsServerMainV1.Channels|null} [serialOpaqueCodecDataChannels] ProtobufEventPayloadSettingsMain serialOpaqueCodecDataChannels
     * @property {ProtobufSettingsUserMainV1.Priority|null} [serialOpaqueCodecDataPriority] ProtobufEventPayloadSettingsMain serialOpaqueCodecDataPriority
     * @property {ProtobufSettingsUserMainV1.Urgency|null} [serialOpaqueCodecDataUrgency] ProtobufEventPayloadSettingsMain serialOpaqueCodecDataUrgency
     * @property {number|null} [serialCapturePeriodS] ProtobufEventPayloadSettingsMain serialCapturePeriodS
     * @property {number|null} [serialCaptureDataTerminator] ProtobufEventPayloadSettingsMain serialCaptureDataTerminator
     * @property {ProtobufSettingsUserMainV1.InstallOrientation|null} [installOrientation] ProtobufEventPayloadSettingsMain installOrientation
     * @property {number|null} [autoInstallOrientationW] ProtobufEventPayloadSettingsMain autoInstallOrientationW
     * @property {number|null} [autoInstallOrientationX] ProtobufEventPayloadSettingsMain autoInstallOrientationX
     * @property {number|null} [autoInstallOrientationY] ProtobufEventPayloadSettingsMain autoInstallOrientationY
     * @property {number|null} [autoInstallOrientationZ] ProtobufEventPayloadSettingsMain autoInstallOrientationZ
     * @property {number|null} [manualInstallCorrectionW] ProtobufEventPayloadSettingsMain manualInstallCorrectionW
     * @property {number|null} [manualInstallCorrectionX] ProtobufEventPayloadSettingsMain manualInstallCorrectionX
     * @property {number|null} [manualInstallCorrectionY] ProtobufEventPayloadSettingsMain manualInstallCorrectionY
     * @property {number|null} [manualInstallCorrectionZ] ProtobufEventPayloadSettingsMain manualInstallCorrectionZ
     * @property {ProtobufSettingsServerMainV1.FlightPhaseModelSelection|null} [flightPhaseModelSelection] ProtobufEventPayloadSettingsMain flightPhaseModelSelection
     * @property {boolean|null} [externalFlightPhaseModelEngineAvailable] ProtobufEventPayloadSettingsMain externalFlightPhaseModelEngineAvailable
     * @property {boolean|null} [externalFlightPhaseModelTaxiAvailable] ProtobufEventPayloadSettingsMain externalFlightPhaseModelTaxiAvailable
     * @property {boolean|null} [externalFlightPhaseModelAirborneAvailable] ProtobufEventPayloadSettingsMain externalFlightPhaseModelAirborneAvailable
     * @property {boolean|null} [externalFlightPhaseModelHoverAvailable] ProtobufEventPayloadSettingsMain externalFlightPhaseModelHoverAvailable
     * @property {boolean|null} [indirectFlightPhaseModelEngineAvailable] ProtobufEventPayloadSettingsMain indirectFlightPhaseModelEngineAvailable
     * @property {boolean|null} [indirectFlightPhaseModelTaxiAvailable] ProtobufEventPayloadSettingsMain indirectFlightPhaseModelTaxiAvailable
     * @property {boolean|null} [indirectFlightPhaseModelAirborneAvailable] ProtobufEventPayloadSettingsMain indirectFlightPhaseModelAirborneAvailable
     * @property {boolean|null} [indirectFlightPhaseModelHoverAvailable] ProtobufEventPayloadSettingsMain indirectFlightPhaseModelHoverAvailable
     * @property {boolean|null} [externalInputEngineOilPressure_1Available] ProtobufEventPayloadSettingsMain externalInputEngineOilPressure_1Available
     * @property {boolean|null} [externalInputEngineOilPressure_2Available] ProtobufEventPayloadSettingsMain externalInputEngineOilPressure_2Available
     * @property {boolean|null} [externalInputMainGearboxOilPressureAvailable] ProtobufEventPayloadSettingsMain externalInputMainGearboxOilPressureAvailable
     * @property {boolean|null} [externalInputCollectiveRaisedAvailable] ProtobufEventPayloadSettingsMain externalInputCollectiveRaisedAvailable
     * @property {boolean|null} [externalInputWeightOnWheelsAvailable] ProtobufEventPayloadSettingsMain externalInputWeightOnWheelsAvailable
     */

    /**
     * Constructs a new ProtobufEventPayloadSettingsMain.
     * @exports ProtobufEventPayloadSettingsMain
     * @classdesc Represents a ProtobufEventPayloadSettingsMain.
     * @implements IProtobufEventPayloadSettingsMain
     * @constructor
     * @param {IProtobufEventPayloadSettingsMain=} [properties] Properties to set
     */
    function ProtobufEventPayloadSettingsMain(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadSettingsMain pcbVersion.
     * @member {number} pcbVersion
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.pcbVersion = 0;

    /**
     * ProtobufEventPayloadSettingsMain pcaVariant.
     * @member {number} pcaVariant
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.pcaVariant = 0;

    /**
     * ProtobufEventPayloadSettingsMain firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.firmwareVersion = 0;

    /**
     * ProtobufEventPayloadSettingsMain antennaSatelliteExternal.
     * @member {boolean} antennaSatelliteExternal
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.antennaSatelliteExternal = false;

    /**
     * ProtobufEventPayloadSettingsMain antennaCellularExternal.
     * @member {boolean} antennaCellularExternal
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.antennaCellularExternal = false;

    /**
     * ProtobufEventPayloadSettingsMain ledBrightnessStandard.
     * @member {ProtobufSettingsUserMainV1.Brightness} ledBrightnessStandard
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.ledBrightnessStandard = 0;

    /**
     * ProtobufEventPayloadSettingsMain ledBrightnessOverride.
     * @member {ProtobufSettingsUserMainV1.Brightness} ledBrightnessOverride
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.ledBrightnessOverride = 0;

    /**
     * ProtobufEventPayloadSettingsMain ledBrightnessMaxPct.
     * @member {number} ledBrightnessMaxPct
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.ledBrightnessMaxPct = 0;

    /**
     * ProtobufEventPayloadSettingsMain ledBrightnessMinPct.
     * @member {number} ledBrightnessMinPct
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.ledBrightnessMinPct = 0;

    /**
     * ProtobufEventPayloadSettingsMain buzzerEnabledWhenWatchActiveOnBackup.
     * @member {boolean} buzzerEnabledWhenWatchActiveOnBackup
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.buzzerEnabledWhenWatchActiveOnBackup = false;

    /**
     * ProtobufEventPayloadSettingsMain buzzerEnabledWhenSerialDeviceMissing.
     * @member {boolean} buzzerEnabledWhenSerialDeviceMissing
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.buzzerEnabledWhenSerialDeviceMissing = false;

    /**
     * ProtobufEventPayloadSettingsMain serialDeviceRequiredForActiveTracking.
     * @member {boolean} serialDeviceRequiredForActiveTracking
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialDeviceRequiredForActiveTracking = false;

    /**
     * ProtobufEventPayloadSettingsMain serialMode.
     * @member {ProtobufSettingsUserMainV1.Mode} serialMode
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialMode = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialRs485Interface.
     * @member {boolean} serialRs485Interface
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialRs485Interface = false;

    /**
     * ProtobufEventPayloadSettingsMain serialBaudRate.
     * @member {number} serialBaudRate
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialBaudRate = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialParity.
     * @member {ProtobufSettingsUserMainV1.Parity} serialParity
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialParity = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialDevice.
     * @member {ProtobufSettingsUserMainV1.Device} serialDevice
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialDevice = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialProtocol.
     * @member {ProtobufSettingsUserMainV1.Protocol} serialProtocol
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialProtocol = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialDebugProtocolLogLevel.
     * @member {ProtobufSettingsServerMainV1.LogLevel} serialDebugProtocolLogLevel
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialDebugProtocolLogLevel = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialAsciiPacketDelimiter.
     * @member {ProtobufSettingsUserMainV1.Delimiter} serialAsciiPacketDelimiter
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialAsciiPacketDelimiter = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialOpaqueCodecDataChannels.
     * @member {ProtobufSettingsServerMainV1.Channels} serialOpaqueCodecDataChannels
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialOpaqueCodecDataChannels = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialOpaqueCodecDataPriority.
     * @member {ProtobufSettingsUserMainV1.Priority} serialOpaqueCodecDataPriority
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialOpaqueCodecDataPriority = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialOpaqueCodecDataUrgency.
     * @member {ProtobufSettingsUserMainV1.Urgency} serialOpaqueCodecDataUrgency
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialOpaqueCodecDataUrgency = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialCapturePeriodS.
     * @member {number} serialCapturePeriodS
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialCapturePeriodS = 0;

    /**
     * ProtobufEventPayloadSettingsMain serialCaptureDataTerminator.
     * @member {number} serialCaptureDataTerminator
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.serialCaptureDataTerminator = 0;

    /**
     * ProtobufEventPayloadSettingsMain installOrientation.
     * @member {ProtobufSettingsUserMainV1.InstallOrientation} installOrientation
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.installOrientation = 0;

    /**
     * ProtobufEventPayloadSettingsMain autoInstallOrientationW.
     * @member {number} autoInstallOrientationW
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.autoInstallOrientationW = 0;

    /**
     * ProtobufEventPayloadSettingsMain autoInstallOrientationX.
     * @member {number} autoInstallOrientationX
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.autoInstallOrientationX = 0;

    /**
     * ProtobufEventPayloadSettingsMain autoInstallOrientationY.
     * @member {number} autoInstallOrientationY
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.autoInstallOrientationY = 0;

    /**
     * ProtobufEventPayloadSettingsMain autoInstallOrientationZ.
     * @member {number} autoInstallOrientationZ
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.autoInstallOrientationZ = 0;

    /**
     * ProtobufEventPayloadSettingsMain manualInstallCorrectionW.
     * @member {number} manualInstallCorrectionW
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.manualInstallCorrectionW = 0;

    /**
     * ProtobufEventPayloadSettingsMain manualInstallCorrectionX.
     * @member {number} manualInstallCorrectionX
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.manualInstallCorrectionX = 0;

    /**
     * ProtobufEventPayloadSettingsMain manualInstallCorrectionY.
     * @member {number} manualInstallCorrectionY
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.manualInstallCorrectionY = 0;

    /**
     * ProtobufEventPayloadSettingsMain manualInstallCorrectionZ.
     * @member {number} manualInstallCorrectionZ
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.manualInstallCorrectionZ = 0;

    /**
     * ProtobufEventPayloadSettingsMain flightPhaseModelSelection.
     * @member {ProtobufSettingsServerMainV1.FlightPhaseModelSelection} flightPhaseModelSelection
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.flightPhaseModelSelection = 0;

    /**
     * ProtobufEventPayloadSettingsMain externalFlightPhaseModelEngineAvailable.
     * @member {boolean} externalFlightPhaseModelEngineAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalFlightPhaseModelEngineAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain externalFlightPhaseModelTaxiAvailable.
     * @member {boolean} externalFlightPhaseModelTaxiAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalFlightPhaseModelTaxiAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain externalFlightPhaseModelAirborneAvailable.
     * @member {boolean} externalFlightPhaseModelAirborneAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalFlightPhaseModelAirborneAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain externalFlightPhaseModelHoverAvailable.
     * @member {boolean} externalFlightPhaseModelHoverAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalFlightPhaseModelHoverAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain indirectFlightPhaseModelEngineAvailable.
     * @member {boolean} indirectFlightPhaseModelEngineAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.indirectFlightPhaseModelEngineAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain indirectFlightPhaseModelTaxiAvailable.
     * @member {boolean} indirectFlightPhaseModelTaxiAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.indirectFlightPhaseModelTaxiAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain indirectFlightPhaseModelAirborneAvailable.
     * @member {boolean} indirectFlightPhaseModelAirborneAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.indirectFlightPhaseModelAirborneAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain indirectFlightPhaseModelHoverAvailable.
     * @member {boolean} indirectFlightPhaseModelHoverAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.indirectFlightPhaseModelHoverAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain externalInputEngineOilPressure_1Available.
     * @member {boolean} externalInputEngineOilPressure_1Available
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalInputEngineOilPressure_1Available = false;

    /**
     * ProtobufEventPayloadSettingsMain externalInputEngineOilPressure_2Available.
     * @member {boolean} externalInputEngineOilPressure_2Available
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalInputEngineOilPressure_2Available = false;

    /**
     * ProtobufEventPayloadSettingsMain externalInputMainGearboxOilPressureAvailable.
     * @member {boolean} externalInputMainGearboxOilPressureAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalInputMainGearboxOilPressureAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain externalInputCollectiveRaisedAvailable.
     * @member {boolean} externalInputCollectiveRaisedAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalInputCollectiveRaisedAvailable = false;

    /**
     * ProtobufEventPayloadSettingsMain externalInputWeightOnWheelsAvailable.
     * @member {boolean} externalInputWeightOnWheelsAvailable
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     */
    ProtobufEventPayloadSettingsMain.prototype.externalInputWeightOnWheelsAvailable = false;

    /**
     * Creates a new ProtobufEventPayloadSettingsMain instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {IProtobufEventPayloadSettingsMain=} [properties] Properties to set
     * @returns {ProtobufEventPayloadSettingsMain} ProtobufEventPayloadSettingsMain instance
     */
    ProtobufEventPayloadSettingsMain.create = function create(properties) {
        return new ProtobufEventPayloadSettingsMain(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsMain message. Does not implicitly {@link ProtobufEventPayloadSettingsMain.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {IProtobufEventPayloadSettingsMain} message ProtobufEventPayloadSettingsMain message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsMain.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pcbVersion != null && Object.hasOwnProperty.call(message, "pcbVersion"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.pcbVersion);
        if (message.pcaVariant != null && Object.hasOwnProperty.call(message, "pcaVariant"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.pcaVariant);
        if (message.firmwareVersion != null && Object.hasOwnProperty.call(message, "firmwareVersion"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.firmwareVersion);
        if (message.antennaSatelliteExternal != null && Object.hasOwnProperty.call(message, "antennaSatelliteExternal"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.antennaSatelliteExternal);
        if (message.antennaCellularExternal != null && Object.hasOwnProperty.call(message, "antennaCellularExternal"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.antennaCellularExternal);
        if (message.ledBrightnessStandard != null && Object.hasOwnProperty.call(message, "ledBrightnessStandard"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.ledBrightnessStandard);
        if (message.ledBrightnessMaxPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMaxPct"))
            writer.uint32(/* id 21, wireType 0 =*/168).uint32(message.ledBrightnessMaxPct);
        if (message.ledBrightnessMinPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMinPct"))
            writer.uint32(/* id 22, wireType 0 =*/176).uint32(message.ledBrightnessMinPct);
        if (message.ledBrightnessOverride != null && Object.hasOwnProperty.call(message, "ledBrightnessOverride"))
            writer.uint32(/* id 24, wireType 0 =*/192).int32(message.ledBrightnessOverride);
        if (message.buzzerEnabledWhenWatchActiveOnBackup != null && Object.hasOwnProperty.call(message, "buzzerEnabledWhenWatchActiveOnBackup"))
            writer.uint32(/* id 25, wireType 0 =*/200).bool(message.buzzerEnabledWhenWatchActiveOnBackup);
        if (message.buzzerEnabledWhenSerialDeviceMissing != null && Object.hasOwnProperty.call(message, "buzzerEnabledWhenSerialDeviceMissing"))
            writer.uint32(/* id 26, wireType 0 =*/208).bool(message.buzzerEnabledWhenSerialDeviceMissing);
        if (message.serialDeviceRequiredForActiveTracking != null && Object.hasOwnProperty.call(message, "serialDeviceRequiredForActiveTracking"))
            writer.uint32(/* id 29, wireType 0 =*/232).bool(message.serialDeviceRequiredForActiveTracking);
        if (message.serialMode != null && Object.hasOwnProperty.call(message, "serialMode"))
            writer.uint32(/* id 30, wireType 0 =*/240).int32(message.serialMode);
        if (message.serialRs485Interface != null && Object.hasOwnProperty.call(message, "serialRs485Interface"))
            writer.uint32(/* id 31, wireType 0 =*/248).bool(message.serialRs485Interface);
        if (message.serialBaudRate != null && Object.hasOwnProperty.call(message, "serialBaudRate"))
            writer.uint32(/* id 32, wireType 0 =*/256).uint32(message.serialBaudRate);
        if (message.serialParity != null && Object.hasOwnProperty.call(message, "serialParity"))
            writer.uint32(/* id 33, wireType 0 =*/264).int32(message.serialParity);
        if (message.serialDevice != null && Object.hasOwnProperty.call(message, "serialDevice"))
            writer.uint32(/* id 34, wireType 0 =*/272).int32(message.serialDevice);
        if (message.serialProtocol != null && Object.hasOwnProperty.call(message, "serialProtocol"))
            writer.uint32(/* id 35, wireType 0 =*/280).int32(message.serialProtocol);
        if (message.serialDebugProtocolLogLevel != null && Object.hasOwnProperty.call(message, "serialDebugProtocolLogLevel"))
            writer.uint32(/* id 40, wireType 0 =*/320).int32(message.serialDebugProtocolLogLevel);
        if (message.serialAsciiPacketDelimiter != null && Object.hasOwnProperty.call(message, "serialAsciiPacketDelimiter"))
            writer.uint32(/* id 41, wireType 0 =*/328).int32(message.serialAsciiPacketDelimiter);
        if (message.serialOpaqueCodecDataChannels != null && Object.hasOwnProperty.call(message, "serialOpaqueCodecDataChannels"))
            writer.uint32(/* id 42, wireType 0 =*/336).int32(message.serialOpaqueCodecDataChannels);
        if (message.serialOpaqueCodecDataPriority != null && Object.hasOwnProperty.call(message, "serialOpaqueCodecDataPriority"))
            writer.uint32(/* id 43, wireType 0 =*/344).int32(message.serialOpaqueCodecDataPriority);
        if (message.serialOpaqueCodecDataUrgency != null && Object.hasOwnProperty.call(message, "serialOpaqueCodecDataUrgency"))
            writer.uint32(/* id 44, wireType 0 =*/352).int32(message.serialOpaqueCodecDataUrgency);
        if (message.serialCapturePeriodS != null && Object.hasOwnProperty.call(message, "serialCapturePeriodS"))
            writer.uint32(/* id 50, wireType 0 =*/400).uint32(message.serialCapturePeriodS);
        if (message.serialCaptureDataTerminator != null && Object.hasOwnProperty.call(message, "serialCaptureDataTerminator"))
            writer.uint32(/* id 51, wireType 0 =*/408).uint32(message.serialCaptureDataTerminator);
        if (message.installOrientation != null && Object.hasOwnProperty.call(message, "installOrientation"))
            writer.uint32(/* id 60, wireType 0 =*/480).int32(message.installOrientation);
        if (message.autoInstallOrientationW != null && Object.hasOwnProperty.call(message, "autoInstallOrientationW"))
            writer.uint32(/* id 61, wireType 5 =*/493).float(message.autoInstallOrientationW);
        if (message.autoInstallOrientationX != null && Object.hasOwnProperty.call(message, "autoInstallOrientationX"))
            writer.uint32(/* id 62, wireType 5 =*/501).float(message.autoInstallOrientationX);
        if (message.autoInstallOrientationY != null && Object.hasOwnProperty.call(message, "autoInstallOrientationY"))
            writer.uint32(/* id 63, wireType 5 =*/509).float(message.autoInstallOrientationY);
        if (message.autoInstallOrientationZ != null && Object.hasOwnProperty.call(message, "autoInstallOrientationZ"))
            writer.uint32(/* id 64, wireType 5 =*/517).float(message.autoInstallOrientationZ);
        if (message.manualInstallCorrectionW != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionW"))
            writer.uint32(/* id 65, wireType 5 =*/525).float(message.manualInstallCorrectionW);
        if (message.manualInstallCorrectionX != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionX"))
            writer.uint32(/* id 66, wireType 5 =*/533).float(message.manualInstallCorrectionX);
        if (message.manualInstallCorrectionY != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionY"))
            writer.uint32(/* id 67, wireType 5 =*/541).float(message.manualInstallCorrectionY);
        if (message.manualInstallCorrectionZ != null && Object.hasOwnProperty.call(message, "manualInstallCorrectionZ"))
            writer.uint32(/* id 68, wireType 5 =*/549).float(message.manualInstallCorrectionZ);
        if (message.flightPhaseModelSelection != null && Object.hasOwnProperty.call(message, "flightPhaseModelSelection"))
            writer.uint32(/* id 70, wireType 0 =*/560).int32(message.flightPhaseModelSelection);
        if (message.externalFlightPhaseModelEngineAvailable != null && Object.hasOwnProperty.call(message, "externalFlightPhaseModelEngineAvailable"))
            writer.uint32(/* id 71, wireType 0 =*/568).bool(message.externalFlightPhaseModelEngineAvailable);
        if (message.externalFlightPhaseModelTaxiAvailable != null && Object.hasOwnProperty.call(message, "externalFlightPhaseModelTaxiAvailable"))
            writer.uint32(/* id 72, wireType 0 =*/576).bool(message.externalFlightPhaseModelTaxiAvailable);
        if (message.externalFlightPhaseModelAirborneAvailable != null && Object.hasOwnProperty.call(message, "externalFlightPhaseModelAirborneAvailable"))
            writer.uint32(/* id 73, wireType 0 =*/584).bool(message.externalFlightPhaseModelAirborneAvailable);
        if (message.externalFlightPhaseModelHoverAvailable != null && Object.hasOwnProperty.call(message, "externalFlightPhaseModelHoverAvailable"))
            writer.uint32(/* id 74, wireType 0 =*/592).bool(message.externalFlightPhaseModelHoverAvailable);
        if (message.indirectFlightPhaseModelEngineAvailable != null && Object.hasOwnProperty.call(message, "indirectFlightPhaseModelEngineAvailable"))
            writer.uint32(/* id 75, wireType 0 =*/600).bool(message.indirectFlightPhaseModelEngineAvailable);
        if (message.indirectFlightPhaseModelTaxiAvailable != null && Object.hasOwnProperty.call(message, "indirectFlightPhaseModelTaxiAvailable"))
            writer.uint32(/* id 76, wireType 0 =*/608).bool(message.indirectFlightPhaseModelTaxiAvailable);
        if (message.indirectFlightPhaseModelAirborneAvailable != null && Object.hasOwnProperty.call(message, "indirectFlightPhaseModelAirborneAvailable"))
            writer.uint32(/* id 77, wireType 0 =*/616).bool(message.indirectFlightPhaseModelAirborneAvailable);
        if (message.indirectFlightPhaseModelHoverAvailable != null && Object.hasOwnProperty.call(message, "indirectFlightPhaseModelHoverAvailable"))
            writer.uint32(/* id 78, wireType 0 =*/624).bool(message.indirectFlightPhaseModelHoverAvailable);
        if (message.externalInputEngineOilPressure_1Available != null && Object.hasOwnProperty.call(message, "externalInputEngineOilPressure_1Available"))
            writer.uint32(/* id 80, wireType 0 =*/640).bool(message.externalInputEngineOilPressure_1Available);
        if (message.externalInputEngineOilPressure_2Available != null && Object.hasOwnProperty.call(message, "externalInputEngineOilPressure_2Available"))
            writer.uint32(/* id 81, wireType 0 =*/648).bool(message.externalInputEngineOilPressure_2Available);
        if (message.externalInputMainGearboxOilPressureAvailable != null && Object.hasOwnProperty.call(message, "externalInputMainGearboxOilPressureAvailable"))
            writer.uint32(/* id 82, wireType 0 =*/656).bool(message.externalInputMainGearboxOilPressureAvailable);
        if (message.externalInputCollectiveRaisedAvailable != null && Object.hasOwnProperty.call(message, "externalInputCollectiveRaisedAvailable"))
            writer.uint32(/* id 83, wireType 0 =*/664).bool(message.externalInputCollectiveRaisedAvailable);
        if (message.externalInputWeightOnWheelsAvailable != null && Object.hasOwnProperty.call(message, "externalInputWeightOnWheelsAvailable"))
            writer.uint32(/* id 84, wireType 0 =*/672).bool(message.externalInputWeightOnWheelsAvailable);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsMain message, length delimited. Does not implicitly {@link ProtobufEventPayloadSettingsMain.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {IProtobufEventPayloadSettingsMain} message ProtobufEventPayloadSettingsMain message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsMain.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsMain message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadSettingsMain} ProtobufEventPayloadSettingsMain
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsMain.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSettingsMain();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.pcbVersion = reader.uint32();
                    break;
                }
            case 2: {
                    message.pcaVariant = reader.uint32();
                    break;
                }
            case 3: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 10: {
                    message.antennaSatelliteExternal = reader.bool();
                    break;
                }
            case 11: {
                    message.antennaCellularExternal = reader.bool();
                    break;
                }
            case 20: {
                    message.ledBrightnessStandard = reader.int32();
                    break;
                }
            case 24: {
                    message.ledBrightnessOverride = reader.int32();
                    break;
                }
            case 21: {
                    message.ledBrightnessMaxPct = reader.uint32();
                    break;
                }
            case 22: {
                    message.ledBrightnessMinPct = reader.uint32();
                    break;
                }
            case 25: {
                    message.buzzerEnabledWhenWatchActiveOnBackup = reader.bool();
                    break;
                }
            case 26: {
                    message.buzzerEnabledWhenSerialDeviceMissing = reader.bool();
                    break;
                }
            case 29: {
                    message.serialDeviceRequiredForActiveTracking = reader.bool();
                    break;
                }
            case 30: {
                    message.serialMode = reader.int32();
                    break;
                }
            case 31: {
                    message.serialRs485Interface = reader.bool();
                    break;
                }
            case 32: {
                    message.serialBaudRate = reader.uint32();
                    break;
                }
            case 33: {
                    message.serialParity = reader.int32();
                    break;
                }
            case 34: {
                    message.serialDevice = reader.int32();
                    break;
                }
            case 35: {
                    message.serialProtocol = reader.int32();
                    break;
                }
            case 40: {
                    message.serialDebugProtocolLogLevel = reader.int32();
                    break;
                }
            case 41: {
                    message.serialAsciiPacketDelimiter = reader.int32();
                    break;
                }
            case 42: {
                    message.serialOpaqueCodecDataChannels = reader.int32();
                    break;
                }
            case 43: {
                    message.serialOpaqueCodecDataPriority = reader.int32();
                    break;
                }
            case 44: {
                    message.serialOpaqueCodecDataUrgency = reader.int32();
                    break;
                }
            case 50: {
                    message.serialCapturePeriodS = reader.uint32();
                    break;
                }
            case 51: {
                    message.serialCaptureDataTerminator = reader.uint32();
                    break;
                }
            case 60: {
                    message.installOrientation = reader.int32();
                    break;
                }
            case 61: {
                    message.autoInstallOrientationW = reader.float();
                    break;
                }
            case 62: {
                    message.autoInstallOrientationX = reader.float();
                    break;
                }
            case 63: {
                    message.autoInstallOrientationY = reader.float();
                    break;
                }
            case 64: {
                    message.autoInstallOrientationZ = reader.float();
                    break;
                }
            case 65: {
                    message.manualInstallCorrectionW = reader.float();
                    break;
                }
            case 66: {
                    message.manualInstallCorrectionX = reader.float();
                    break;
                }
            case 67: {
                    message.manualInstallCorrectionY = reader.float();
                    break;
                }
            case 68: {
                    message.manualInstallCorrectionZ = reader.float();
                    break;
                }
            case 70: {
                    message.flightPhaseModelSelection = reader.int32();
                    break;
                }
            case 71: {
                    message.externalFlightPhaseModelEngineAvailable = reader.bool();
                    break;
                }
            case 72: {
                    message.externalFlightPhaseModelTaxiAvailable = reader.bool();
                    break;
                }
            case 73: {
                    message.externalFlightPhaseModelAirborneAvailable = reader.bool();
                    break;
                }
            case 74: {
                    message.externalFlightPhaseModelHoverAvailable = reader.bool();
                    break;
                }
            case 75: {
                    message.indirectFlightPhaseModelEngineAvailable = reader.bool();
                    break;
                }
            case 76: {
                    message.indirectFlightPhaseModelTaxiAvailable = reader.bool();
                    break;
                }
            case 77: {
                    message.indirectFlightPhaseModelAirborneAvailable = reader.bool();
                    break;
                }
            case 78: {
                    message.indirectFlightPhaseModelHoverAvailable = reader.bool();
                    break;
                }
            case 80: {
                    message.externalInputEngineOilPressure_1Available = reader.bool();
                    break;
                }
            case 81: {
                    message.externalInputEngineOilPressure_2Available = reader.bool();
                    break;
                }
            case 82: {
                    message.externalInputMainGearboxOilPressureAvailable = reader.bool();
                    break;
                }
            case 83: {
                    message.externalInputCollectiveRaisedAvailable = reader.bool();
                    break;
                }
            case 84: {
                    message.externalInputWeightOnWheelsAvailable = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsMain message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadSettingsMain} ProtobufEventPayloadSettingsMain
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsMain.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadSettingsMain message.
     * @function verify
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadSettingsMain.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            if (!$util.isInteger(message.pcbVersion))
                return "pcbVersion: integer expected";
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            if (!$util.isInteger(message.pcaVariant))
                return "pcaVariant: integer expected";
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            if (!$util.isInteger(message.firmwareVersion))
                return "firmwareVersion: integer expected";
        if (message.antennaSatelliteExternal != null && message.hasOwnProperty("antennaSatelliteExternal"))
            if (typeof message.antennaSatelliteExternal !== "boolean")
                return "antennaSatelliteExternal: boolean expected";
        if (message.antennaCellularExternal != null && message.hasOwnProperty("antennaCellularExternal"))
            if (typeof message.antennaCellularExternal !== "boolean")
                return "antennaCellularExternal: boolean expected";
        if (message.ledBrightnessStandard != null && message.hasOwnProperty("ledBrightnessStandard"))
            switch (message.ledBrightnessStandard) {
            default:
                return "ledBrightnessStandard: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.ledBrightnessOverride != null && message.hasOwnProperty("ledBrightnessOverride"))
            switch (message.ledBrightnessOverride) {
            default:
                return "ledBrightnessOverride: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            if (!$util.isInteger(message.ledBrightnessMaxPct))
                return "ledBrightnessMaxPct: integer expected";
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            if (!$util.isInteger(message.ledBrightnessMinPct))
                return "ledBrightnessMinPct: integer expected";
        if (message.buzzerEnabledWhenWatchActiveOnBackup != null && message.hasOwnProperty("buzzerEnabledWhenWatchActiveOnBackup"))
            if (typeof message.buzzerEnabledWhenWatchActiveOnBackup !== "boolean")
                return "buzzerEnabledWhenWatchActiveOnBackup: boolean expected";
        if (message.buzzerEnabledWhenSerialDeviceMissing != null && message.hasOwnProperty("buzzerEnabledWhenSerialDeviceMissing"))
            if (typeof message.buzzerEnabledWhenSerialDeviceMissing !== "boolean")
                return "buzzerEnabledWhenSerialDeviceMissing: boolean expected";
        if (message.serialDeviceRequiredForActiveTracking != null && message.hasOwnProperty("serialDeviceRequiredForActiveTracking"))
            if (typeof message.serialDeviceRequiredForActiveTracking !== "boolean")
                return "serialDeviceRequiredForActiveTracking: boolean expected";
        if (message.serialMode != null && message.hasOwnProperty("serialMode"))
            switch (message.serialMode) {
            default:
                return "serialMode: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialRs485Interface != null && message.hasOwnProperty("serialRs485Interface"))
            if (typeof message.serialRs485Interface !== "boolean")
                return "serialRs485Interface: boolean expected";
        if (message.serialBaudRate != null && message.hasOwnProperty("serialBaudRate"))
            if (!$util.isInteger(message.serialBaudRate))
                return "serialBaudRate: integer expected";
        if (message.serialParity != null && message.hasOwnProperty("serialParity"))
            switch (message.serialParity) {
            default:
                return "serialParity: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialDevice != null && message.hasOwnProperty("serialDevice"))
            switch (message.serialDevice) {
            default:
                return "serialDevice: enum value expected";
            case 0:
            case 1:
            case 10:
            case 11:
            case 20:
            case 30:
            case 40:
            case 50:
            case 60:
            case 100:
                break;
            }
        if (message.serialProtocol != null && message.hasOwnProperty("serialProtocol"))
            switch (message.serialProtocol) {
            default:
                return "serialProtocol: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                break;
            }
        if (message.serialDebugProtocolLogLevel != null && message.hasOwnProperty("serialDebugProtocolLogLevel"))
            switch (message.serialDebugProtocolLogLevel) {
            default:
                return "serialDebugProtocolLogLevel: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.serialAsciiPacketDelimiter != null && message.hasOwnProperty("serialAsciiPacketDelimiter"))
            switch (message.serialAsciiPacketDelimiter) {
            default:
                return "serialAsciiPacketDelimiter: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialOpaqueCodecDataChannels != null && message.hasOwnProperty("serialOpaqueCodecDataChannels"))
            switch (message.serialOpaqueCodecDataChannels) {
            default:
                return "serialOpaqueCodecDataChannels: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.serialOpaqueCodecDataPriority != null && message.hasOwnProperty("serialOpaqueCodecDataPriority"))
            switch (message.serialOpaqueCodecDataPriority) {
            default:
                return "serialOpaqueCodecDataPriority: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.serialOpaqueCodecDataUrgency != null && message.hasOwnProperty("serialOpaqueCodecDataUrgency"))
            switch (message.serialOpaqueCodecDataUrgency) {
            default:
                return "serialOpaqueCodecDataUrgency: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialCapturePeriodS != null && message.hasOwnProperty("serialCapturePeriodS"))
            if (!$util.isInteger(message.serialCapturePeriodS))
                return "serialCapturePeriodS: integer expected";
        if (message.serialCaptureDataTerminator != null && message.hasOwnProperty("serialCaptureDataTerminator"))
            if (!$util.isInteger(message.serialCaptureDataTerminator))
                return "serialCaptureDataTerminator: integer expected";
        if (message.installOrientation != null && message.hasOwnProperty("installOrientation"))
            switch (message.installOrientation) {
            default:
                return "installOrientation: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.autoInstallOrientationW != null && message.hasOwnProperty("autoInstallOrientationW"))
            if (typeof message.autoInstallOrientationW !== "number")
                return "autoInstallOrientationW: number expected";
        if (message.autoInstallOrientationX != null && message.hasOwnProperty("autoInstallOrientationX"))
            if (typeof message.autoInstallOrientationX !== "number")
                return "autoInstallOrientationX: number expected";
        if (message.autoInstallOrientationY != null && message.hasOwnProperty("autoInstallOrientationY"))
            if (typeof message.autoInstallOrientationY !== "number")
                return "autoInstallOrientationY: number expected";
        if (message.autoInstallOrientationZ != null && message.hasOwnProperty("autoInstallOrientationZ"))
            if (typeof message.autoInstallOrientationZ !== "number")
                return "autoInstallOrientationZ: number expected";
        if (message.manualInstallCorrectionW != null && message.hasOwnProperty("manualInstallCorrectionW"))
            if (typeof message.manualInstallCorrectionW !== "number")
                return "manualInstallCorrectionW: number expected";
        if (message.manualInstallCorrectionX != null && message.hasOwnProperty("manualInstallCorrectionX"))
            if (typeof message.manualInstallCorrectionX !== "number")
                return "manualInstallCorrectionX: number expected";
        if (message.manualInstallCorrectionY != null && message.hasOwnProperty("manualInstallCorrectionY"))
            if (typeof message.manualInstallCorrectionY !== "number")
                return "manualInstallCorrectionY: number expected";
        if (message.manualInstallCorrectionZ != null && message.hasOwnProperty("manualInstallCorrectionZ"))
            if (typeof message.manualInstallCorrectionZ !== "number")
                return "manualInstallCorrectionZ: number expected";
        if (message.flightPhaseModelSelection != null && message.hasOwnProperty("flightPhaseModelSelection"))
            switch (message.flightPhaseModelSelection) {
            default:
                return "flightPhaseModelSelection: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.externalFlightPhaseModelEngineAvailable != null && message.hasOwnProperty("externalFlightPhaseModelEngineAvailable"))
            if (typeof message.externalFlightPhaseModelEngineAvailable !== "boolean")
                return "externalFlightPhaseModelEngineAvailable: boolean expected";
        if (message.externalFlightPhaseModelTaxiAvailable != null && message.hasOwnProperty("externalFlightPhaseModelTaxiAvailable"))
            if (typeof message.externalFlightPhaseModelTaxiAvailable !== "boolean")
                return "externalFlightPhaseModelTaxiAvailable: boolean expected";
        if (message.externalFlightPhaseModelAirborneAvailable != null && message.hasOwnProperty("externalFlightPhaseModelAirborneAvailable"))
            if (typeof message.externalFlightPhaseModelAirborneAvailable !== "boolean")
                return "externalFlightPhaseModelAirborneAvailable: boolean expected";
        if (message.externalFlightPhaseModelHoverAvailable != null && message.hasOwnProperty("externalFlightPhaseModelHoverAvailable"))
            if (typeof message.externalFlightPhaseModelHoverAvailable !== "boolean")
                return "externalFlightPhaseModelHoverAvailable: boolean expected";
        if (message.indirectFlightPhaseModelEngineAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelEngineAvailable"))
            if (typeof message.indirectFlightPhaseModelEngineAvailable !== "boolean")
                return "indirectFlightPhaseModelEngineAvailable: boolean expected";
        if (message.indirectFlightPhaseModelTaxiAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelTaxiAvailable"))
            if (typeof message.indirectFlightPhaseModelTaxiAvailable !== "boolean")
                return "indirectFlightPhaseModelTaxiAvailable: boolean expected";
        if (message.indirectFlightPhaseModelAirborneAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelAirborneAvailable"))
            if (typeof message.indirectFlightPhaseModelAirborneAvailable !== "boolean")
                return "indirectFlightPhaseModelAirborneAvailable: boolean expected";
        if (message.indirectFlightPhaseModelHoverAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelHoverAvailable"))
            if (typeof message.indirectFlightPhaseModelHoverAvailable !== "boolean")
                return "indirectFlightPhaseModelHoverAvailable: boolean expected";
        if (message.externalInputEngineOilPressure_1Available != null && message.hasOwnProperty("externalInputEngineOilPressure_1Available"))
            if (typeof message.externalInputEngineOilPressure_1Available !== "boolean")
                return "externalInputEngineOilPressure_1Available: boolean expected";
        if (message.externalInputEngineOilPressure_2Available != null && message.hasOwnProperty("externalInputEngineOilPressure_2Available"))
            if (typeof message.externalInputEngineOilPressure_2Available !== "boolean")
                return "externalInputEngineOilPressure_2Available: boolean expected";
        if (message.externalInputMainGearboxOilPressureAvailable != null && message.hasOwnProperty("externalInputMainGearboxOilPressureAvailable"))
            if (typeof message.externalInputMainGearboxOilPressureAvailable !== "boolean")
                return "externalInputMainGearboxOilPressureAvailable: boolean expected";
        if (message.externalInputCollectiveRaisedAvailable != null && message.hasOwnProperty("externalInputCollectiveRaisedAvailable"))
            if (typeof message.externalInputCollectiveRaisedAvailable !== "boolean")
                return "externalInputCollectiveRaisedAvailable: boolean expected";
        if (message.externalInputWeightOnWheelsAvailable != null && message.hasOwnProperty("externalInputWeightOnWheelsAvailable"))
            if (typeof message.externalInputWeightOnWheelsAvailable !== "boolean")
                return "externalInputWeightOnWheelsAvailable: boolean expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadSettingsMain message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadSettingsMain} ProtobufEventPayloadSettingsMain
     */
    ProtobufEventPayloadSettingsMain.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadSettingsMain)
            return object;
        var message = new $root.ProtobufEventPayloadSettingsMain();
        if (object.pcbVersion != null)
            message.pcbVersion = object.pcbVersion >>> 0;
        if (object.pcaVariant != null)
            message.pcaVariant = object.pcaVariant >>> 0;
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        if (object.antennaSatelliteExternal != null)
            message.antennaSatelliteExternal = Boolean(object.antennaSatelliteExternal);
        if (object.antennaCellularExternal != null)
            message.antennaCellularExternal = Boolean(object.antennaCellularExternal);
        switch (object.ledBrightnessStandard) {
        default:
            if (typeof object.ledBrightnessStandard === "number") {
                message.ledBrightnessStandard = object.ledBrightnessStandard;
                break;
            }
            break;
        case "kAuto":
        case 0:
            message.ledBrightnessStandard = 0;
            break;
        case "kFixed":
        case 1:
            message.ledBrightnessStandard = 1;
            break;
        case "kOff":
        case 2:
            message.ledBrightnessStandard = 2;
            break;
        }
        switch (object.ledBrightnessOverride) {
        default:
            if (typeof object.ledBrightnessOverride === "number") {
                message.ledBrightnessOverride = object.ledBrightnessOverride;
                break;
            }
            break;
        case "kAuto":
        case 0:
            message.ledBrightnessOverride = 0;
            break;
        case "kFixed":
        case 1:
            message.ledBrightnessOverride = 1;
            break;
        case "kOff":
        case 2:
            message.ledBrightnessOverride = 2;
            break;
        }
        if (object.ledBrightnessMaxPct != null)
            message.ledBrightnessMaxPct = object.ledBrightnessMaxPct >>> 0;
        if (object.ledBrightnessMinPct != null)
            message.ledBrightnessMinPct = object.ledBrightnessMinPct >>> 0;
        if (object.buzzerEnabledWhenWatchActiveOnBackup != null)
            message.buzzerEnabledWhenWatchActiveOnBackup = Boolean(object.buzzerEnabledWhenWatchActiveOnBackup);
        if (object.buzzerEnabledWhenSerialDeviceMissing != null)
            message.buzzerEnabledWhenSerialDeviceMissing = Boolean(object.buzzerEnabledWhenSerialDeviceMissing);
        if (object.serialDeviceRequiredForActiveTracking != null)
            message.serialDeviceRequiredForActiveTracking = Boolean(object.serialDeviceRequiredForActiveTracking);
        switch (object.serialMode) {
        default:
            if (typeof object.serialMode === "number") {
                message.serialMode = object.serialMode;
                break;
            }
            break;
        case "kEnabled":
        case 0:
            message.serialMode = 0;
            break;
        case "kDisabled":
        case 1:
            message.serialMode = 1;
            break;
        case "kPassthrough":
        case 2:
            message.serialMode = 2;
            break;
        }
        if (object.serialRs485Interface != null)
            message.serialRs485Interface = Boolean(object.serialRs485Interface);
        if (object.serialBaudRate != null)
            message.serialBaudRate = object.serialBaudRate >>> 0;
        switch (object.serialParity) {
        default:
            if (typeof object.serialParity === "number") {
                message.serialParity = object.serialParity;
                break;
            }
            break;
        case "kNoParity":
        case 0:
            message.serialParity = 0;
            break;
        case "kEven":
        case 1:
            message.serialParity = 1;
            break;
        case "kOdd":
        case 2:
            message.serialParity = 2;
            break;
        }
        switch (object.serialDevice) {
        default:
            if (typeof object.serialDevice === "number") {
                message.serialDevice = object.serialDevice;
                break;
            }
            break;
        case "kGenericProtobuf":
        case 0:
            message.serialDevice = 0;
            break;
        case "kGenericThirdParty":
        case 1:
            message.serialDevice = 1;
            break;
        case "kSpidertracksKeypad":
        case 10:
            message.serialDevice = 10;
            break;
        case "kSpidertracksGpi":
        case 11:
            message.serialDevice = 11;
            break;
        case "kTrotterDatavault":
        case 20:
            message.serialDevice = 20;
            break;
        case "kKopterGarminIntegratedAvionics":
        case 30:
            message.serialDevice = 30;
            break;
        case "kAmsAfdau":
        case 40:
            message.serialDevice = 40;
            break;
        case "kOlympicAeroETL":
        case 50:
            message.serialDevice = 50;
            break;
        case "kAkvEtm":
        case 60:
            message.serialDevice = 60;
            break;
        case "kVectorNav":
        case 100:
            message.serialDevice = 100;
            break;
        }
        switch (object.serialProtocol) {
        default:
            if (typeof object.serialProtocol === "number") {
                message.serialProtocol = object.serialProtocol;
                break;
            }
            break;
        case "kProtocolNone":
        case 0:
            message.serialProtocol = 0;
            break;
        case "kProtocolDebug":
        case 1:
            message.serialProtocol = 1;
            break;
        case "kProtocolStlProtobuf":
        case 2:
            message.serialProtocol = 2;
            break;
        case "kProtocolAscii":
        case 3:
            message.serialProtocol = 3;
            break;
        case "kProtocolTrotter":
        case 4:
            message.serialProtocol = 4;
            break;
        case "kProtocolKopter":
        case 5:
            message.serialProtocol = 5;
            break;
        case "kProtocolAms":
        case 6:
            message.serialProtocol = 6;
            break;
        case "kProtocolStlThirdParty":
        case 7:
            message.serialProtocol = 7;
            break;
        case "kProtocolAkv":
        case 8:
            message.serialProtocol = 8;
            break;
        }
        switch (object.serialDebugProtocolLogLevel) {
        default:
            if (typeof object.serialDebugProtocolLogLevel === "number") {
                message.serialDebugProtocolLogLevel = object.serialDebugProtocolLogLevel;
                break;
            }
            break;
        case "kCritical":
        case 0:
            message.serialDebugProtocolLogLevel = 0;
            break;
        case "kError":
        case 1:
            message.serialDebugProtocolLogLevel = 1;
            break;
        case "kWarn":
        case 2:
            message.serialDebugProtocolLogLevel = 2;
            break;
        case "kNotice":
        case 3:
            message.serialDebugProtocolLogLevel = 3;
            break;
        case "kInfo":
        case 4:
            message.serialDebugProtocolLogLevel = 4;
            break;
        case "kLog":
        case 5:
            message.serialDebugProtocolLogLevel = 5;
            break;
        case "kDebug":
        case 6:
            message.serialDebugProtocolLogLevel = 6;
            break;
        case "kVerbose":
        case 7:
            message.serialDebugProtocolLogLevel = 7;
            break;
        }
        switch (object.serialAsciiPacketDelimiter) {
        default:
            if (typeof object.serialAsciiPacketDelimiter === "number") {
                message.serialAsciiPacketDelimiter = object.serialAsciiPacketDelimiter;
                break;
            }
            break;
        case "kCRLF":
        case 0:
            message.serialAsciiPacketDelimiter = 0;
            break;
        case "kLF":
        case 1:
            message.serialAsciiPacketDelimiter = 1;
            break;
        case "kCR":
        case 2:
            message.serialAsciiPacketDelimiter = 2;
            break;
        }
        switch (object.serialOpaqueCodecDataChannels) {
        default:
            if (typeof object.serialOpaqueCodecDataChannels === "number") {
                message.serialOpaqueCodecDataChannels = object.serialOpaqueCodecDataChannels;
                break;
            }
            break;
        case "kSatelliteOrCellular":
        case 0:
            message.serialOpaqueCodecDataChannels = 0;
            break;
        case "kSatellite":
        case 1:
            message.serialOpaqueCodecDataChannels = 1;
            break;
        case "kCellular":
        case 2:
            message.serialOpaqueCodecDataChannels = 2;
            break;
        case "kSatelliteAndCellular":
        case 3:
            message.serialOpaqueCodecDataChannels = 3;
            break;
        }
        switch (object.serialOpaqueCodecDataPriority) {
        default:
            if (typeof object.serialOpaqueCodecDataPriority === "number") {
                message.serialOpaqueCodecDataPriority = object.serialOpaqueCodecDataPriority;
                break;
            }
            break;
        case "kPriorityNone":
        case 0:
            message.serialOpaqueCodecDataPriority = 0;
            break;
        case "kPriorityLow":
        case 1:
            message.serialOpaqueCodecDataPriority = 1;
            break;
        case "kPriorityMedium":
        case 2:
            message.serialOpaqueCodecDataPriority = 2;
            break;
        case "kPriorityHigh":
        case 3:
            message.serialOpaqueCodecDataPriority = 3;
            break;
        case "kPrioritySession":
        case 4:
            message.serialOpaqueCodecDataPriority = 4;
            break;
        case "kPriorityCritical":
        case 5:
            message.serialOpaqueCodecDataPriority = 5;
            break;
        }
        switch (object.serialOpaqueCodecDataUrgency) {
        default:
            if (typeof object.serialOpaqueCodecDataUrgency === "number") {
                message.serialOpaqueCodecDataUrgency = object.serialOpaqueCodecDataUrgency;
                break;
            }
            break;
        case "kUrgencyNextOpportunity":
        case 0:
            message.serialOpaqueCodecDataUrgency = 0;
            break;
        case "kUrgencyWithin10s":
        case 1:
            message.serialOpaqueCodecDataUrgency = 1;
            break;
        case "kUrgencyNow":
        case 2:
            message.serialOpaqueCodecDataUrgency = 2;
            break;
        }
        if (object.serialCapturePeriodS != null)
            message.serialCapturePeriodS = object.serialCapturePeriodS >>> 0;
        if (object.serialCaptureDataTerminator != null)
            message.serialCaptureDataTerminator = object.serialCaptureDataTerminator >>> 0;
        switch (object.installOrientation) {
        default:
            if (typeof object.installOrientation === "number") {
                message.installOrientation = object.installOrientation;
                break;
            }
            break;
        case "kInstallOrientationNone":
        case 0:
            message.installOrientation = 0;
            break;
        case "kInstallOrientationManual":
        case 1:
            message.installOrientation = 1;
            break;
        case "kInstallOrientationAutoLevel":
        case 2:
            message.installOrientation = 2;
            break;
        case "kInstallOrientationAutoLevelAndYaw":
        case 3:
            message.installOrientation = 3;
            break;
        }
        if (object.autoInstallOrientationW != null)
            message.autoInstallOrientationW = Number(object.autoInstallOrientationW);
        if (object.autoInstallOrientationX != null)
            message.autoInstallOrientationX = Number(object.autoInstallOrientationX);
        if (object.autoInstallOrientationY != null)
            message.autoInstallOrientationY = Number(object.autoInstallOrientationY);
        if (object.autoInstallOrientationZ != null)
            message.autoInstallOrientationZ = Number(object.autoInstallOrientationZ);
        if (object.manualInstallCorrectionW != null)
            message.manualInstallCorrectionW = Number(object.manualInstallCorrectionW);
        if (object.manualInstallCorrectionX != null)
            message.manualInstallCorrectionX = Number(object.manualInstallCorrectionX);
        if (object.manualInstallCorrectionY != null)
            message.manualInstallCorrectionY = Number(object.manualInstallCorrectionY);
        if (object.manualInstallCorrectionZ != null)
            message.manualInstallCorrectionZ = Number(object.manualInstallCorrectionZ);
        switch (object.flightPhaseModelSelection) {
        default:
            if (typeof object.flightPhaseModelSelection === "number") {
                message.flightPhaseModelSelection = object.flightPhaseModelSelection;
                break;
            }
            break;
        case "kHybrid":
        case 0:
            message.flightPhaseModelSelection = 0;
            break;
        case "kDirectOnly":
        case 1:
            message.flightPhaseModelSelection = 1;
            break;
        case "kIndirectOnly":
        case 2:
            message.flightPhaseModelSelection = 2;
            break;
        case "kExternalOnly":
        case 3:
            message.flightPhaseModelSelection = 3;
            break;
        }
        if (object.externalFlightPhaseModelEngineAvailable != null)
            message.externalFlightPhaseModelEngineAvailable = Boolean(object.externalFlightPhaseModelEngineAvailable);
        if (object.externalFlightPhaseModelTaxiAvailable != null)
            message.externalFlightPhaseModelTaxiAvailable = Boolean(object.externalFlightPhaseModelTaxiAvailable);
        if (object.externalFlightPhaseModelAirborneAvailable != null)
            message.externalFlightPhaseModelAirborneAvailable = Boolean(object.externalFlightPhaseModelAirborneAvailable);
        if (object.externalFlightPhaseModelHoverAvailable != null)
            message.externalFlightPhaseModelHoverAvailable = Boolean(object.externalFlightPhaseModelHoverAvailable);
        if (object.indirectFlightPhaseModelEngineAvailable != null)
            message.indirectFlightPhaseModelEngineAvailable = Boolean(object.indirectFlightPhaseModelEngineAvailable);
        if (object.indirectFlightPhaseModelTaxiAvailable != null)
            message.indirectFlightPhaseModelTaxiAvailable = Boolean(object.indirectFlightPhaseModelTaxiAvailable);
        if (object.indirectFlightPhaseModelAirborneAvailable != null)
            message.indirectFlightPhaseModelAirborneAvailable = Boolean(object.indirectFlightPhaseModelAirborneAvailable);
        if (object.indirectFlightPhaseModelHoverAvailable != null)
            message.indirectFlightPhaseModelHoverAvailable = Boolean(object.indirectFlightPhaseModelHoverAvailable);
        if (object.externalInputEngineOilPressure_1Available != null)
            message.externalInputEngineOilPressure_1Available = Boolean(object.externalInputEngineOilPressure_1Available);
        if (object.externalInputEngineOilPressure_2Available != null)
            message.externalInputEngineOilPressure_2Available = Boolean(object.externalInputEngineOilPressure_2Available);
        if (object.externalInputMainGearboxOilPressureAvailable != null)
            message.externalInputMainGearboxOilPressureAvailable = Boolean(object.externalInputMainGearboxOilPressureAvailable);
        if (object.externalInputCollectiveRaisedAvailable != null)
            message.externalInputCollectiveRaisedAvailable = Boolean(object.externalInputCollectiveRaisedAvailable);
        if (object.externalInputWeightOnWheelsAvailable != null)
            message.externalInputWeightOnWheelsAvailable = Boolean(object.externalInputWeightOnWheelsAvailable);
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadSettingsMain message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {ProtobufEventPayloadSettingsMain} message ProtobufEventPayloadSettingsMain
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadSettingsMain.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.pcbVersion = 0;
            object.pcaVariant = 0;
            object.firmwareVersion = 0;
            object.antennaSatelliteExternal = false;
            object.antennaCellularExternal = false;
            object.ledBrightnessStandard = options.enums === String ? "kAuto" : 0;
            object.ledBrightnessMaxPct = 0;
            object.ledBrightnessMinPct = 0;
            object.ledBrightnessOverride = options.enums === String ? "kAuto" : 0;
            object.buzzerEnabledWhenWatchActiveOnBackup = false;
            object.buzzerEnabledWhenSerialDeviceMissing = false;
            object.serialDeviceRequiredForActiveTracking = false;
            object.serialMode = options.enums === String ? "kEnabled" : 0;
            object.serialRs485Interface = false;
            object.serialBaudRate = 0;
            object.serialParity = options.enums === String ? "kNoParity" : 0;
            object.serialDevice = options.enums === String ? "kGenericProtobuf" : 0;
            object.serialProtocol = options.enums === String ? "kProtocolNone" : 0;
            object.serialDebugProtocolLogLevel = options.enums === String ? "kCritical" : 0;
            object.serialAsciiPacketDelimiter = options.enums === String ? "kCRLF" : 0;
            object.serialOpaqueCodecDataChannels = options.enums === String ? "kSatelliteOrCellular" : 0;
            object.serialOpaqueCodecDataPriority = options.enums === String ? "kPriorityNone" : 0;
            object.serialOpaqueCodecDataUrgency = options.enums === String ? "kUrgencyNextOpportunity" : 0;
            object.serialCapturePeriodS = 0;
            object.serialCaptureDataTerminator = 0;
            object.installOrientation = options.enums === String ? "kInstallOrientationNone" : 0;
            object.autoInstallOrientationW = 0;
            object.autoInstallOrientationX = 0;
            object.autoInstallOrientationY = 0;
            object.autoInstallOrientationZ = 0;
            object.manualInstallCorrectionW = 0;
            object.manualInstallCorrectionX = 0;
            object.manualInstallCorrectionY = 0;
            object.manualInstallCorrectionZ = 0;
            object.flightPhaseModelSelection = options.enums === String ? "kHybrid" : 0;
            object.externalFlightPhaseModelEngineAvailable = false;
            object.externalFlightPhaseModelTaxiAvailable = false;
            object.externalFlightPhaseModelAirborneAvailable = false;
            object.externalFlightPhaseModelHoverAvailable = false;
            object.indirectFlightPhaseModelEngineAvailable = false;
            object.indirectFlightPhaseModelTaxiAvailable = false;
            object.indirectFlightPhaseModelAirborneAvailable = false;
            object.indirectFlightPhaseModelHoverAvailable = false;
            object.externalInputEngineOilPressure_1Available = false;
            object.externalInputEngineOilPressure_2Available = false;
            object.externalInputMainGearboxOilPressureAvailable = false;
            object.externalInputCollectiveRaisedAvailable = false;
            object.externalInputWeightOnWheelsAvailable = false;
        }
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            object.pcbVersion = message.pcbVersion;
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            object.pcaVariant = message.pcaVariant;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.antennaSatelliteExternal != null && message.hasOwnProperty("antennaSatelliteExternal"))
            object.antennaSatelliteExternal = message.antennaSatelliteExternal;
        if (message.antennaCellularExternal != null && message.hasOwnProperty("antennaCellularExternal"))
            object.antennaCellularExternal = message.antennaCellularExternal;
        if (message.ledBrightnessStandard != null && message.hasOwnProperty("ledBrightnessStandard"))
            object.ledBrightnessStandard = options.enums === String ? $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessStandard] === undefined ? message.ledBrightnessStandard : $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessStandard] : message.ledBrightnessStandard;
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            object.ledBrightnessMaxPct = message.ledBrightnessMaxPct;
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            object.ledBrightnessMinPct = message.ledBrightnessMinPct;
        if (message.ledBrightnessOverride != null && message.hasOwnProperty("ledBrightnessOverride"))
            object.ledBrightnessOverride = options.enums === String ? $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessOverride] === undefined ? message.ledBrightnessOverride : $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightnessOverride] : message.ledBrightnessOverride;
        if (message.buzzerEnabledWhenWatchActiveOnBackup != null && message.hasOwnProperty("buzzerEnabledWhenWatchActiveOnBackup"))
            object.buzzerEnabledWhenWatchActiveOnBackup = message.buzzerEnabledWhenWatchActiveOnBackup;
        if (message.buzzerEnabledWhenSerialDeviceMissing != null && message.hasOwnProperty("buzzerEnabledWhenSerialDeviceMissing"))
            object.buzzerEnabledWhenSerialDeviceMissing = message.buzzerEnabledWhenSerialDeviceMissing;
        if (message.serialDeviceRequiredForActiveTracking != null && message.hasOwnProperty("serialDeviceRequiredForActiveTracking"))
            object.serialDeviceRequiredForActiveTracking = message.serialDeviceRequiredForActiveTracking;
        if (message.serialMode != null && message.hasOwnProperty("serialMode"))
            object.serialMode = options.enums === String ? $root.ProtobufSettingsUserMainV1.Mode[message.serialMode] === undefined ? message.serialMode : $root.ProtobufSettingsUserMainV1.Mode[message.serialMode] : message.serialMode;
        if (message.serialRs485Interface != null && message.hasOwnProperty("serialRs485Interface"))
            object.serialRs485Interface = message.serialRs485Interface;
        if (message.serialBaudRate != null && message.hasOwnProperty("serialBaudRate"))
            object.serialBaudRate = message.serialBaudRate;
        if (message.serialParity != null && message.hasOwnProperty("serialParity"))
            object.serialParity = options.enums === String ? $root.ProtobufSettingsUserMainV1.Parity[message.serialParity] === undefined ? message.serialParity : $root.ProtobufSettingsUserMainV1.Parity[message.serialParity] : message.serialParity;
        if (message.serialDevice != null && message.hasOwnProperty("serialDevice"))
            object.serialDevice = options.enums === String ? $root.ProtobufSettingsUserMainV1.Device[message.serialDevice] === undefined ? message.serialDevice : $root.ProtobufSettingsUserMainV1.Device[message.serialDevice] : message.serialDevice;
        if (message.serialProtocol != null && message.hasOwnProperty("serialProtocol"))
            object.serialProtocol = options.enums === String ? $root.ProtobufSettingsUserMainV1.Protocol[message.serialProtocol] === undefined ? message.serialProtocol : $root.ProtobufSettingsUserMainV1.Protocol[message.serialProtocol] : message.serialProtocol;
        if (message.serialDebugProtocolLogLevel != null && message.hasOwnProperty("serialDebugProtocolLogLevel"))
            object.serialDebugProtocolLogLevel = options.enums === String ? $root.ProtobufSettingsServerMainV1.LogLevel[message.serialDebugProtocolLogLevel] === undefined ? message.serialDebugProtocolLogLevel : $root.ProtobufSettingsServerMainV1.LogLevel[message.serialDebugProtocolLogLevel] : message.serialDebugProtocolLogLevel;
        if (message.serialAsciiPacketDelimiter != null && message.hasOwnProperty("serialAsciiPacketDelimiter"))
            object.serialAsciiPacketDelimiter = options.enums === String ? $root.ProtobufSettingsUserMainV1.Delimiter[message.serialAsciiPacketDelimiter] === undefined ? message.serialAsciiPacketDelimiter : $root.ProtobufSettingsUserMainV1.Delimiter[message.serialAsciiPacketDelimiter] : message.serialAsciiPacketDelimiter;
        if (message.serialOpaqueCodecDataChannels != null && message.hasOwnProperty("serialOpaqueCodecDataChannels"))
            object.serialOpaqueCodecDataChannels = options.enums === String ? $root.ProtobufSettingsServerMainV1.Channels[message.serialOpaqueCodecDataChannels] === undefined ? message.serialOpaqueCodecDataChannels : $root.ProtobufSettingsServerMainV1.Channels[message.serialOpaqueCodecDataChannels] : message.serialOpaqueCodecDataChannels;
        if (message.serialOpaqueCodecDataPriority != null && message.hasOwnProperty("serialOpaqueCodecDataPriority"))
            object.serialOpaqueCodecDataPriority = options.enums === String ? $root.ProtobufSettingsUserMainV1.Priority[message.serialOpaqueCodecDataPriority] === undefined ? message.serialOpaqueCodecDataPriority : $root.ProtobufSettingsUserMainV1.Priority[message.serialOpaqueCodecDataPriority] : message.serialOpaqueCodecDataPriority;
        if (message.serialOpaqueCodecDataUrgency != null && message.hasOwnProperty("serialOpaqueCodecDataUrgency"))
            object.serialOpaqueCodecDataUrgency = options.enums === String ? $root.ProtobufSettingsUserMainV1.Urgency[message.serialOpaqueCodecDataUrgency] === undefined ? message.serialOpaqueCodecDataUrgency : $root.ProtobufSettingsUserMainV1.Urgency[message.serialOpaqueCodecDataUrgency] : message.serialOpaqueCodecDataUrgency;
        if (message.serialCapturePeriodS != null && message.hasOwnProperty("serialCapturePeriodS"))
            object.serialCapturePeriodS = message.serialCapturePeriodS;
        if (message.serialCaptureDataTerminator != null && message.hasOwnProperty("serialCaptureDataTerminator"))
            object.serialCaptureDataTerminator = message.serialCaptureDataTerminator;
        if (message.installOrientation != null && message.hasOwnProperty("installOrientation"))
            object.installOrientation = options.enums === String ? $root.ProtobufSettingsUserMainV1.InstallOrientation[message.installOrientation] === undefined ? message.installOrientation : $root.ProtobufSettingsUserMainV1.InstallOrientation[message.installOrientation] : message.installOrientation;
        if (message.autoInstallOrientationW != null && message.hasOwnProperty("autoInstallOrientationW"))
            object.autoInstallOrientationW = options.json && !isFinite(message.autoInstallOrientationW) ? String(message.autoInstallOrientationW) : message.autoInstallOrientationW;
        if (message.autoInstallOrientationX != null && message.hasOwnProperty("autoInstallOrientationX"))
            object.autoInstallOrientationX = options.json && !isFinite(message.autoInstallOrientationX) ? String(message.autoInstallOrientationX) : message.autoInstallOrientationX;
        if (message.autoInstallOrientationY != null && message.hasOwnProperty("autoInstallOrientationY"))
            object.autoInstallOrientationY = options.json && !isFinite(message.autoInstallOrientationY) ? String(message.autoInstallOrientationY) : message.autoInstallOrientationY;
        if (message.autoInstallOrientationZ != null && message.hasOwnProperty("autoInstallOrientationZ"))
            object.autoInstallOrientationZ = options.json && !isFinite(message.autoInstallOrientationZ) ? String(message.autoInstallOrientationZ) : message.autoInstallOrientationZ;
        if (message.manualInstallCorrectionW != null && message.hasOwnProperty("manualInstallCorrectionW"))
            object.manualInstallCorrectionW = options.json && !isFinite(message.manualInstallCorrectionW) ? String(message.manualInstallCorrectionW) : message.manualInstallCorrectionW;
        if (message.manualInstallCorrectionX != null && message.hasOwnProperty("manualInstallCorrectionX"))
            object.manualInstallCorrectionX = options.json && !isFinite(message.manualInstallCorrectionX) ? String(message.manualInstallCorrectionX) : message.manualInstallCorrectionX;
        if (message.manualInstallCorrectionY != null && message.hasOwnProperty("manualInstallCorrectionY"))
            object.manualInstallCorrectionY = options.json && !isFinite(message.manualInstallCorrectionY) ? String(message.manualInstallCorrectionY) : message.manualInstallCorrectionY;
        if (message.manualInstallCorrectionZ != null && message.hasOwnProperty("manualInstallCorrectionZ"))
            object.manualInstallCorrectionZ = options.json && !isFinite(message.manualInstallCorrectionZ) ? String(message.manualInstallCorrectionZ) : message.manualInstallCorrectionZ;
        if (message.flightPhaseModelSelection != null && message.hasOwnProperty("flightPhaseModelSelection"))
            object.flightPhaseModelSelection = options.enums === String ? $root.ProtobufSettingsServerMainV1.FlightPhaseModelSelection[message.flightPhaseModelSelection] === undefined ? message.flightPhaseModelSelection : $root.ProtobufSettingsServerMainV1.FlightPhaseModelSelection[message.flightPhaseModelSelection] : message.flightPhaseModelSelection;
        if (message.externalFlightPhaseModelEngineAvailable != null && message.hasOwnProperty("externalFlightPhaseModelEngineAvailable"))
            object.externalFlightPhaseModelEngineAvailable = message.externalFlightPhaseModelEngineAvailable;
        if (message.externalFlightPhaseModelTaxiAvailable != null && message.hasOwnProperty("externalFlightPhaseModelTaxiAvailable"))
            object.externalFlightPhaseModelTaxiAvailable = message.externalFlightPhaseModelTaxiAvailable;
        if (message.externalFlightPhaseModelAirborneAvailable != null && message.hasOwnProperty("externalFlightPhaseModelAirborneAvailable"))
            object.externalFlightPhaseModelAirborneAvailable = message.externalFlightPhaseModelAirborneAvailable;
        if (message.externalFlightPhaseModelHoverAvailable != null && message.hasOwnProperty("externalFlightPhaseModelHoverAvailable"))
            object.externalFlightPhaseModelHoverAvailable = message.externalFlightPhaseModelHoverAvailable;
        if (message.indirectFlightPhaseModelEngineAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelEngineAvailable"))
            object.indirectFlightPhaseModelEngineAvailable = message.indirectFlightPhaseModelEngineAvailable;
        if (message.indirectFlightPhaseModelTaxiAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelTaxiAvailable"))
            object.indirectFlightPhaseModelTaxiAvailable = message.indirectFlightPhaseModelTaxiAvailable;
        if (message.indirectFlightPhaseModelAirborneAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelAirborneAvailable"))
            object.indirectFlightPhaseModelAirborneAvailable = message.indirectFlightPhaseModelAirborneAvailable;
        if (message.indirectFlightPhaseModelHoverAvailable != null && message.hasOwnProperty("indirectFlightPhaseModelHoverAvailable"))
            object.indirectFlightPhaseModelHoverAvailable = message.indirectFlightPhaseModelHoverAvailable;
        if (message.externalInputEngineOilPressure_1Available != null && message.hasOwnProperty("externalInputEngineOilPressure_1Available"))
            object.externalInputEngineOilPressure_1Available = message.externalInputEngineOilPressure_1Available;
        if (message.externalInputEngineOilPressure_2Available != null && message.hasOwnProperty("externalInputEngineOilPressure_2Available"))
            object.externalInputEngineOilPressure_2Available = message.externalInputEngineOilPressure_2Available;
        if (message.externalInputMainGearboxOilPressureAvailable != null && message.hasOwnProperty("externalInputMainGearboxOilPressureAvailable"))
            object.externalInputMainGearboxOilPressureAvailable = message.externalInputMainGearboxOilPressureAvailable;
        if (message.externalInputCollectiveRaisedAvailable != null && message.hasOwnProperty("externalInputCollectiveRaisedAvailable"))
            object.externalInputCollectiveRaisedAvailable = message.externalInputCollectiveRaisedAvailable;
        if (message.externalInputWeightOnWheelsAvailable != null && message.hasOwnProperty("externalInputWeightOnWheelsAvailable"))
            object.externalInputWeightOnWheelsAvailable = message.externalInputWeightOnWheelsAvailable;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadSettingsMain to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadSettingsMain
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadSettingsMain.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadSettingsMain
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadSettingsMain
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadSettingsMain.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadSettingsMain";
    };

    return ProtobufEventPayloadSettingsMain;
})();

$root.ProtobufEventPayloadSettingsKeypad = (function() {

    /**
     * Properties of a ProtobufEventPayloadSettingsKeypad.
     * @exports IProtobufEventPayloadSettingsKeypad
     * @interface IProtobufEventPayloadSettingsKeypad
     * @property {number|null} [pcbVersion] ProtobufEventPayloadSettingsKeypad pcbVersion
     * @property {number|null} [pcaVariant] ProtobufEventPayloadSettingsKeypad pcaVariant
     * @property {number|null} [firmwareVersion] ProtobufEventPayloadSettingsKeypad firmwareVersion
     * @property {ProtobufSettingsUserMainV1.Brightness|null} [ledBrightness] ProtobufEventPayloadSettingsKeypad ledBrightness
     * @property {number|null} [ledBrightnessMaxPct] ProtobufEventPayloadSettingsKeypad ledBrightnessMaxPct
     * @property {number|null} [ledBrightnessMinPct] ProtobufEventPayloadSettingsKeypad ledBrightnessMinPct
     */

    /**
     * Constructs a new ProtobufEventPayloadSettingsKeypad.
     * @exports ProtobufEventPayloadSettingsKeypad
     * @classdesc Represents a ProtobufEventPayloadSettingsKeypad.
     * @implements IProtobufEventPayloadSettingsKeypad
     * @constructor
     * @param {IProtobufEventPayloadSettingsKeypad=} [properties] Properties to set
     */
    function ProtobufEventPayloadSettingsKeypad(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadSettingsKeypad pcbVersion.
     * @member {number} pcbVersion
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @instance
     */
    ProtobufEventPayloadSettingsKeypad.prototype.pcbVersion = 0;

    /**
     * ProtobufEventPayloadSettingsKeypad pcaVariant.
     * @member {number} pcaVariant
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @instance
     */
    ProtobufEventPayloadSettingsKeypad.prototype.pcaVariant = 0;

    /**
     * ProtobufEventPayloadSettingsKeypad firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @instance
     */
    ProtobufEventPayloadSettingsKeypad.prototype.firmwareVersion = 0;

    /**
     * ProtobufEventPayloadSettingsKeypad ledBrightness.
     * @member {ProtobufSettingsUserMainV1.Brightness} ledBrightness
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @instance
     */
    ProtobufEventPayloadSettingsKeypad.prototype.ledBrightness = 0;

    /**
     * ProtobufEventPayloadSettingsKeypad ledBrightnessMaxPct.
     * @member {number} ledBrightnessMaxPct
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @instance
     */
    ProtobufEventPayloadSettingsKeypad.prototype.ledBrightnessMaxPct = 0;

    /**
     * ProtobufEventPayloadSettingsKeypad ledBrightnessMinPct.
     * @member {number} ledBrightnessMinPct
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @instance
     */
    ProtobufEventPayloadSettingsKeypad.prototype.ledBrightnessMinPct = 0;

    /**
     * Creates a new ProtobufEventPayloadSettingsKeypad instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {IProtobufEventPayloadSettingsKeypad=} [properties] Properties to set
     * @returns {ProtobufEventPayloadSettingsKeypad} ProtobufEventPayloadSettingsKeypad instance
     */
    ProtobufEventPayloadSettingsKeypad.create = function create(properties) {
        return new ProtobufEventPayloadSettingsKeypad(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsKeypad message. Does not implicitly {@link ProtobufEventPayloadSettingsKeypad.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {IProtobufEventPayloadSettingsKeypad} message ProtobufEventPayloadSettingsKeypad message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsKeypad.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pcbVersion != null && Object.hasOwnProperty.call(message, "pcbVersion"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.pcbVersion);
        if (message.pcaVariant != null && Object.hasOwnProperty.call(message, "pcaVariant"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.pcaVariant);
        if (message.firmwareVersion != null && Object.hasOwnProperty.call(message, "firmwareVersion"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.firmwareVersion);
        if (message.ledBrightness != null && Object.hasOwnProperty.call(message, "ledBrightness"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.ledBrightness);
        if (message.ledBrightnessMaxPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMaxPct"))
            writer.uint32(/* id 21, wireType 0 =*/168).uint32(message.ledBrightnessMaxPct);
        if (message.ledBrightnessMinPct != null && Object.hasOwnProperty.call(message, "ledBrightnessMinPct"))
            writer.uint32(/* id 22, wireType 0 =*/176).uint32(message.ledBrightnessMinPct);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsKeypad message, length delimited. Does not implicitly {@link ProtobufEventPayloadSettingsKeypad.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {IProtobufEventPayloadSettingsKeypad} message ProtobufEventPayloadSettingsKeypad message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsKeypad.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsKeypad message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadSettingsKeypad} ProtobufEventPayloadSettingsKeypad
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsKeypad.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSettingsKeypad();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.pcbVersion = reader.uint32();
                    break;
                }
            case 2: {
                    message.pcaVariant = reader.uint32();
                    break;
                }
            case 3: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 20: {
                    message.ledBrightness = reader.int32();
                    break;
                }
            case 21: {
                    message.ledBrightnessMaxPct = reader.uint32();
                    break;
                }
            case 22: {
                    message.ledBrightnessMinPct = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsKeypad message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadSettingsKeypad} ProtobufEventPayloadSettingsKeypad
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsKeypad.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadSettingsKeypad message.
     * @function verify
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadSettingsKeypad.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            if (!$util.isInteger(message.pcbVersion))
                return "pcbVersion: integer expected";
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            if (!$util.isInteger(message.pcaVariant))
                return "pcaVariant: integer expected";
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            if (!$util.isInteger(message.firmwareVersion))
                return "firmwareVersion: integer expected";
        if (message.ledBrightness != null && message.hasOwnProperty("ledBrightness"))
            switch (message.ledBrightness) {
            default:
                return "ledBrightness: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            if (!$util.isInteger(message.ledBrightnessMaxPct))
                return "ledBrightnessMaxPct: integer expected";
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            if (!$util.isInteger(message.ledBrightnessMinPct))
                return "ledBrightnessMinPct: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadSettingsKeypad message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadSettingsKeypad} ProtobufEventPayloadSettingsKeypad
     */
    ProtobufEventPayloadSettingsKeypad.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadSettingsKeypad)
            return object;
        var message = new $root.ProtobufEventPayloadSettingsKeypad();
        if (object.pcbVersion != null)
            message.pcbVersion = object.pcbVersion >>> 0;
        if (object.pcaVariant != null)
            message.pcaVariant = object.pcaVariant >>> 0;
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        switch (object.ledBrightness) {
        default:
            if (typeof object.ledBrightness === "number") {
                message.ledBrightness = object.ledBrightness;
                break;
            }
            break;
        case "kAuto":
        case 0:
            message.ledBrightness = 0;
            break;
        case "kFixed":
        case 1:
            message.ledBrightness = 1;
            break;
        case "kOff":
        case 2:
            message.ledBrightness = 2;
            break;
        }
        if (object.ledBrightnessMaxPct != null)
            message.ledBrightnessMaxPct = object.ledBrightnessMaxPct >>> 0;
        if (object.ledBrightnessMinPct != null)
            message.ledBrightnessMinPct = object.ledBrightnessMinPct >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadSettingsKeypad message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {ProtobufEventPayloadSettingsKeypad} message ProtobufEventPayloadSettingsKeypad
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadSettingsKeypad.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.pcbVersion = 0;
            object.pcaVariant = 0;
            object.firmwareVersion = 0;
            object.ledBrightness = options.enums === String ? "kAuto" : 0;
            object.ledBrightnessMaxPct = 0;
            object.ledBrightnessMinPct = 0;
        }
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            object.pcbVersion = message.pcbVersion;
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            object.pcaVariant = message.pcaVariant;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.ledBrightness != null && message.hasOwnProperty("ledBrightness"))
            object.ledBrightness = options.enums === String ? $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightness] === undefined ? message.ledBrightness : $root.ProtobufSettingsUserMainV1.Brightness[message.ledBrightness] : message.ledBrightness;
        if (message.ledBrightnessMaxPct != null && message.hasOwnProperty("ledBrightnessMaxPct"))
            object.ledBrightnessMaxPct = message.ledBrightnessMaxPct;
        if (message.ledBrightnessMinPct != null && message.hasOwnProperty("ledBrightnessMinPct"))
            object.ledBrightnessMinPct = message.ledBrightnessMinPct;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadSettingsKeypad to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadSettingsKeypad.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadSettingsKeypad
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadSettingsKeypad
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadSettingsKeypad.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadSettingsKeypad";
    };

    return ProtobufEventPayloadSettingsKeypad;
})();

$root.ProtobufEventPayloadSettingsGpi = (function() {

    /**
     * Properties of a ProtobufEventPayloadSettingsGpi.
     * @exports IProtobufEventPayloadSettingsGpi
     * @interface IProtobufEventPayloadSettingsGpi
     * @property {number|null} [pcbVersion] ProtobufEventPayloadSettingsGpi pcbVersion
     * @property {number|null} [pcaVariant] ProtobufEventPayloadSettingsGpi pcaVariant
     * @property {number|null} [firmwareVersion] ProtobufEventPayloadSettingsGpi firmwareVersion
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_0ActiveState] ProtobufEventPayloadSettingsGpi input_0ActiveState
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_1ActiveState] ProtobufEventPayloadSettingsGpi input_1ActiveState
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_2ActiveState] ProtobufEventPayloadSettingsGpi input_2ActiveState
     * @property {ProtobufSettingsUserGpiV1.ActiveState|null} [input_3ActiveState] ProtobufEventPayloadSettingsGpi input_3ActiveState
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_0Type] ProtobufEventPayloadSettingsGpi input_0Type
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_1Type] ProtobufEventPayloadSettingsGpi input_1Type
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_2Type] ProtobufEventPayloadSettingsGpi input_2Type
     * @property {ProtobufSettingsUserGpiV1.InputType|null} [input_3Type] ProtobufEventPayloadSettingsGpi input_3Type
     */

    /**
     * Constructs a new ProtobufEventPayloadSettingsGpi.
     * @exports ProtobufEventPayloadSettingsGpi
     * @classdesc Represents a ProtobufEventPayloadSettingsGpi.
     * @implements IProtobufEventPayloadSettingsGpi
     * @constructor
     * @param {IProtobufEventPayloadSettingsGpi=} [properties] Properties to set
     */
    function ProtobufEventPayloadSettingsGpi(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadSettingsGpi pcbVersion.
     * @member {number} pcbVersion
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.pcbVersion = 0;

    /**
     * ProtobufEventPayloadSettingsGpi pcaVariant.
     * @member {number} pcaVariant
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.pcaVariant = 0;

    /**
     * ProtobufEventPayloadSettingsGpi firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.firmwareVersion = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_0ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_0ActiveState
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_0ActiveState = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_1ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_1ActiveState
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_1ActiveState = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_2ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_2ActiveState
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_2ActiveState = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_3ActiveState.
     * @member {ProtobufSettingsUserGpiV1.ActiveState} input_3ActiveState
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_3ActiveState = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_0Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_0Type
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_0Type = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_1Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_1Type
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_1Type = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_2Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_2Type
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_2Type = 0;

    /**
     * ProtobufEventPayloadSettingsGpi input_3Type.
     * @member {ProtobufSettingsUserGpiV1.InputType} input_3Type
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     */
    ProtobufEventPayloadSettingsGpi.prototype.input_3Type = 0;

    /**
     * Creates a new ProtobufEventPayloadSettingsGpi instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {IProtobufEventPayloadSettingsGpi=} [properties] Properties to set
     * @returns {ProtobufEventPayloadSettingsGpi} ProtobufEventPayloadSettingsGpi instance
     */
    ProtobufEventPayloadSettingsGpi.create = function create(properties) {
        return new ProtobufEventPayloadSettingsGpi(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsGpi message. Does not implicitly {@link ProtobufEventPayloadSettingsGpi.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {IProtobufEventPayloadSettingsGpi} message ProtobufEventPayloadSettingsGpi message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsGpi.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pcbVersion != null && Object.hasOwnProperty.call(message, "pcbVersion"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.pcbVersion);
        if (message.pcaVariant != null && Object.hasOwnProperty.call(message, "pcaVariant"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.pcaVariant);
        if (message.firmwareVersion != null && Object.hasOwnProperty.call(message, "firmwareVersion"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.firmwareVersion);
        if (message.input_0ActiveState != null && Object.hasOwnProperty.call(message, "input_0ActiveState"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.input_0ActiveState);
        if (message.input_1ActiveState != null && Object.hasOwnProperty.call(message, "input_1ActiveState"))
            writer.uint32(/* id 21, wireType 0 =*/168).int32(message.input_1ActiveState);
        if (message.input_2ActiveState != null && Object.hasOwnProperty.call(message, "input_2ActiveState"))
            writer.uint32(/* id 22, wireType 0 =*/176).int32(message.input_2ActiveState);
        if (message.input_3ActiveState != null && Object.hasOwnProperty.call(message, "input_3ActiveState"))
            writer.uint32(/* id 23, wireType 0 =*/184).int32(message.input_3ActiveState);
        if (message.input_0Type != null && Object.hasOwnProperty.call(message, "input_0Type"))
            writer.uint32(/* id 30, wireType 0 =*/240).int32(message.input_0Type);
        if (message.input_1Type != null && Object.hasOwnProperty.call(message, "input_1Type"))
            writer.uint32(/* id 31, wireType 0 =*/248).int32(message.input_1Type);
        if (message.input_2Type != null && Object.hasOwnProperty.call(message, "input_2Type"))
            writer.uint32(/* id 32, wireType 0 =*/256).int32(message.input_2Type);
        if (message.input_3Type != null && Object.hasOwnProperty.call(message, "input_3Type"))
            writer.uint32(/* id 33, wireType 0 =*/264).int32(message.input_3Type);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadSettingsGpi message, length delimited. Does not implicitly {@link ProtobufEventPayloadSettingsGpi.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {IProtobufEventPayloadSettingsGpi} message ProtobufEventPayloadSettingsGpi message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSettingsGpi.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsGpi message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadSettingsGpi} ProtobufEventPayloadSettingsGpi
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsGpi.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSettingsGpi();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.pcbVersion = reader.uint32();
                    break;
                }
            case 2: {
                    message.pcaVariant = reader.uint32();
                    break;
                }
            case 3: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 20: {
                    message.input_0ActiveState = reader.int32();
                    break;
                }
            case 21: {
                    message.input_1ActiveState = reader.int32();
                    break;
                }
            case 22: {
                    message.input_2ActiveState = reader.int32();
                    break;
                }
            case 23: {
                    message.input_3ActiveState = reader.int32();
                    break;
                }
            case 30: {
                    message.input_0Type = reader.int32();
                    break;
                }
            case 31: {
                    message.input_1Type = reader.int32();
                    break;
                }
            case 32: {
                    message.input_2Type = reader.int32();
                    break;
                }
            case 33: {
                    message.input_3Type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadSettingsGpi message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadSettingsGpi} ProtobufEventPayloadSettingsGpi
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSettingsGpi.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadSettingsGpi message.
     * @function verify
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadSettingsGpi.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            if (!$util.isInteger(message.pcbVersion))
                return "pcbVersion: integer expected";
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            if (!$util.isInteger(message.pcaVariant))
                return "pcaVariant: integer expected";
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            if (!$util.isInteger(message.firmwareVersion))
                return "firmwareVersion: integer expected";
        if (message.input_0ActiveState != null && message.hasOwnProperty("input_0ActiveState"))
            switch (message.input_0ActiveState) {
            default:
                return "input_0ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_1ActiveState != null && message.hasOwnProperty("input_1ActiveState"))
            switch (message.input_1ActiveState) {
            default:
                return "input_1ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_2ActiveState != null && message.hasOwnProperty("input_2ActiveState"))
            switch (message.input_2ActiveState) {
            default:
                return "input_2ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_3ActiveState != null && message.hasOwnProperty("input_3ActiveState"))
            switch (message.input_3ActiveState) {
            default:
                return "input_3ActiveState: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.input_0Type != null && message.hasOwnProperty("input_0Type"))
            switch (message.input_0Type) {
            default:
                return "input_0Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.input_1Type != null && message.hasOwnProperty("input_1Type"))
            switch (message.input_1Type) {
            default:
                return "input_1Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.input_2Type != null && message.hasOwnProperty("input_2Type"))
            switch (message.input_2Type) {
            default:
                return "input_2Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        if (message.input_3Type != null && message.hasOwnProperty("input_3Type"))
            switch (message.input_3Type) {
            default:
                return "input_3Type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                break;
            }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadSettingsGpi message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadSettingsGpi} ProtobufEventPayloadSettingsGpi
     */
    ProtobufEventPayloadSettingsGpi.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadSettingsGpi)
            return object;
        var message = new $root.ProtobufEventPayloadSettingsGpi();
        if (object.pcbVersion != null)
            message.pcbVersion = object.pcbVersion >>> 0;
        if (object.pcaVariant != null)
            message.pcaVariant = object.pcaVariant >>> 0;
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        switch (object.input_0ActiveState) {
        default:
            if (typeof object.input_0ActiveState === "number") {
                message.input_0ActiveState = object.input_0ActiveState;
                break;
            }
            break;
        case "kActiveStateDefault":
        case 0:
            message.input_0ActiveState = 0;
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_0ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_0ActiveState = 2;
            break;
        }
        switch (object.input_1ActiveState) {
        default:
            if (typeof object.input_1ActiveState === "number") {
                message.input_1ActiveState = object.input_1ActiveState;
                break;
            }
            break;
        case "kActiveStateDefault":
        case 0:
            message.input_1ActiveState = 0;
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_1ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_1ActiveState = 2;
            break;
        }
        switch (object.input_2ActiveState) {
        default:
            if (typeof object.input_2ActiveState === "number") {
                message.input_2ActiveState = object.input_2ActiveState;
                break;
            }
            break;
        case "kActiveStateDefault":
        case 0:
            message.input_2ActiveState = 0;
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_2ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_2ActiveState = 2;
            break;
        }
        switch (object.input_3ActiveState) {
        default:
            if (typeof object.input_3ActiveState === "number") {
                message.input_3ActiveState = object.input_3ActiveState;
                break;
            }
            break;
        case "kActiveStateDefault":
        case 0:
            message.input_3ActiveState = 0;
            break;
        case "kActiveStateHigh":
        case 1:
            message.input_3ActiveState = 1;
            break;
        case "kActiveStateLow":
        case 2:
            message.input_3ActiveState = 2;
            break;
        }
        switch (object.input_0Type) {
        default:
            if (typeof object.input_0Type === "number") {
                message.input_0Type = object.input_0Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_0Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_0Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_0Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_0Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_0Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_0Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_0Type = 6;
            break;
        }
        switch (object.input_1Type) {
        default:
            if (typeof object.input_1Type === "number") {
                message.input_1Type = object.input_1Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_1Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_1Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_1Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_1Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_1Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_1Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_1Type = 6;
            break;
        }
        switch (object.input_2Type) {
        default:
            if (typeof object.input_2Type === "number") {
                message.input_2Type = object.input_2Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_2Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_2Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_2Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_2Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_2Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_2Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_2Type = 6;
            break;
        }
        switch (object.input_3Type) {
        default:
            if (typeof object.input_3Type === "number") {
                message.input_3Type = object.input_3Type;
                break;
            }
            break;
        case "kInputTypeUnused":
        case 0:
            message.input_3Type = 0;
            break;
        case "kInputTypeEngineOilPressure1":
        case 1:
            message.input_3Type = 1;
            break;
        case "kInputTypeEngineOilPressure2":
        case 2:
            message.input_3Type = 2;
            break;
        case "kInputTypeMainGearboxOilPressure":
        case 3:
            message.input_3Type = 3;
            break;
        case "kInputTypeCollectiveRaised":
        case 4:
            message.input_3Type = 4;
            break;
        case "kInputTypeWeightOnWheels":
        case 5:
            message.input_3Type = 5;
            break;
        case "kInputTypeLandingGearRetracted":
        case 6:
            message.input_3Type = 6;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadSettingsGpi message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {ProtobufEventPayloadSettingsGpi} message ProtobufEventPayloadSettingsGpi
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadSettingsGpi.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.pcbVersion = 0;
            object.pcaVariant = 0;
            object.firmwareVersion = 0;
            object.input_0ActiveState = options.enums === String ? "kActiveStateDefault" : 0;
            object.input_1ActiveState = options.enums === String ? "kActiveStateDefault" : 0;
            object.input_2ActiveState = options.enums === String ? "kActiveStateDefault" : 0;
            object.input_3ActiveState = options.enums === String ? "kActiveStateDefault" : 0;
            object.input_0Type = options.enums === String ? "kInputTypeUnused" : 0;
            object.input_1Type = options.enums === String ? "kInputTypeUnused" : 0;
            object.input_2Type = options.enums === String ? "kInputTypeUnused" : 0;
            object.input_3Type = options.enums === String ? "kInputTypeUnused" : 0;
        }
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            object.pcbVersion = message.pcbVersion;
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            object.pcaVariant = message.pcaVariant;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.input_0ActiveState != null && message.hasOwnProperty("input_0ActiveState"))
            object.input_0ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_0ActiveState] === undefined ? message.input_0ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_0ActiveState] : message.input_0ActiveState;
        if (message.input_1ActiveState != null && message.hasOwnProperty("input_1ActiveState"))
            object.input_1ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_1ActiveState] === undefined ? message.input_1ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_1ActiveState] : message.input_1ActiveState;
        if (message.input_2ActiveState != null && message.hasOwnProperty("input_2ActiveState"))
            object.input_2ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_2ActiveState] === undefined ? message.input_2ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_2ActiveState] : message.input_2ActiveState;
        if (message.input_3ActiveState != null && message.hasOwnProperty("input_3ActiveState"))
            object.input_3ActiveState = options.enums === String ? $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_3ActiveState] === undefined ? message.input_3ActiveState : $root.ProtobufSettingsUserGpiV1.ActiveState[message.input_3ActiveState] : message.input_3ActiveState;
        if (message.input_0Type != null && message.hasOwnProperty("input_0Type"))
            object.input_0Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_0Type] === undefined ? message.input_0Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_0Type] : message.input_0Type;
        if (message.input_1Type != null && message.hasOwnProperty("input_1Type"))
            object.input_1Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_1Type] === undefined ? message.input_1Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_1Type] : message.input_1Type;
        if (message.input_2Type != null && message.hasOwnProperty("input_2Type"))
            object.input_2Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_2Type] === undefined ? message.input_2Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_2Type] : message.input_2Type;
        if (message.input_3Type != null && message.hasOwnProperty("input_3Type"))
            object.input_3Type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.input_3Type] === undefined ? message.input_3Type : $root.ProtobufSettingsUserGpiV1.InputType[message.input_3Type] : message.input_3Type;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadSettingsGpi to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadSettingsGpi
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadSettingsGpi.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadSettingsGpi
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadSettingsGpi
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadSettingsGpi.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadSettingsGpi";
    };

    return ProtobufEventPayloadSettingsGpi;
})();

$root.ProtobufEventPayloadPower = (function() {

    /**
     * Properties of a ProtobufEventPayloadPower.
     * @exports IProtobufEventPayloadPower
     * @interface IProtobufEventPayloadPower
     * @property {ProtobufEventPayloadPower.State|null} [state] ProtobufEventPayloadPower state
     */

    /**
     * Constructs a new ProtobufEventPayloadPower.
     * @exports ProtobufEventPayloadPower
     * @classdesc Represents a ProtobufEventPayloadPower.
     * @implements IProtobufEventPayloadPower
     * @constructor
     * @param {IProtobufEventPayloadPower=} [properties] Properties to set
     */
    function ProtobufEventPayloadPower(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadPower state.
     * @member {ProtobufEventPayloadPower.State} state
     * @memberof ProtobufEventPayloadPower
     * @instance
     */
    ProtobufEventPayloadPower.prototype.state = 0;

    /**
     * Creates a new ProtobufEventPayloadPower instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {IProtobufEventPayloadPower=} [properties] Properties to set
     * @returns {ProtobufEventPayloadPower} ProtobufEventPayloadPower instance
     */
    ProtobufEventPayloadPower.create = function create(properties) {
        return new ProtobufEventPayloadPower(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadPower message. Does not implicitly {@link ProtobufEventPayloadPower.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {IProtobufEventPayloadPower} message ProtobufEventPayloadPower message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadPower.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadPower message, length delimited. Does not implicitly {@link ProtobufEventPayloadPower.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {IProtobufEventPayloadPower} message ProtobufEventPayloadPower message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadPower.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadPower message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadPower} ProtobufEventPayloadPower
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadPower.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadPower();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadPower message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadPower} ProtobufEventPayloadPower
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadPower.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadPower message.
     * @function verify
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadPower.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadPower message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadPower} ProtobufEventPayloadPower
     */
    ProtobufEventPayloadPower.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadPower)
            return object;
        var message = new $root.ProtobufEventPayloadPower();
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.state = 0;
            break;
        case "kGood":
        case 1:
            message.state = 1;
            break;
        case "kBackup":
        case 2:
            message.state = 2;
            break;
        case "kCritical":
        case 3:
            message.state = 3;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadPower message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {ProtobufEventPayloadPower} message ProtobufEventPayloadPower
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadPower.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.state = options.enums === String ? "kNone" : 0;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.ProtobufEventPayloadPower.State[message.state] === undefined ? message.state : $root.ProtobufEventPayloadPower.State[message.state] : message.state;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadPower to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadPower
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadPower.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadPower
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadPower
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadPower.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadPower";
    };

    /**
     * State enum.
     * @name ProtobufEventPayloadPower.State
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kGood=1 kGood value
     * @property {number} kBackup=2 kBackup value
     * @property {number} kCritical=3 kCritical value
     */
    ProtobufEventPayloadPower.State = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kGood"] = 1;
        values[valuesById[2] = "kBackup"] = 2;
        values[valuesById[3] = "kCritical"] = 3;
        return values;
    })();

    return ProtobufEventPayloadPower;
})();

$root.ProtobufEventPayloadReset = (function() {

    /**
     * Properties of a ProtobufEventPayloadReset.
     * @exports IProtobufEventPayloadReset
     * @interface IProtobufEventPayloadReset
     * @property {boolean|null} [bootMode] ProtobufEventPayloadReset bootMode
     */

    /**
     * Constructs a new ProtobufEventPayloadReset.
     * @exports ProtobufEventPayloadReset
     * @classdesc Represents a ProtobufEventPayloadReset.
     * @implements IProtobufEventPayloadReset
     * @constructor
     * @param {IProtobufEventPayloadReset=} [properties] Properties to set
     */
    function ProtobufEventPayloadReset(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadReset bootMode.
     * @member {boolean} bootMode
     * @memberof ProtobufEventPayloadReset
     * @instance
     */
    ProtobufEventPayloadReset.prototype.bootMode = false;

    /**
     * Creates a new ProtobufEventPayloadReset instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {IProtobufEventPayloadReset=} [properties] Properties to set
     * @returns {ProtobufEventPayloadReset} ProtobufEventPayloadReset instance
     */
    ProtobufEventPayloadReset.create = function create(properties) {
        return new ProtobufEventPayloadReset(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadReset message. Does not implicitly {@link ProtobufEventPayloadReset.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {IProtobufEventPayloadReset} message ProtobufEventPayloadReset message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadReset.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.bootMode != null && Object.hasOwnProperty.call(message, "bootMode"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.bootMode);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadReset message, length delimited. Does not implicitly {@link ProtobufEventPayloadReset.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {IProtobufEventPayloadReset} message ProtobufEventPayloadReset message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadReset.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadReset message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadReset} ProtobufEventPayloadReset
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadReset.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadReset();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.bootMode = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadReset message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadReset} ProtobufEventPayloadReset
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadReset.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadReset message.
     * @function verify
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadReset.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.bootMode != null && message.hasOwnProperty("bootMode"))
            if (typeof message.bootMode !== "boolean")
                return "bootMode: boolean expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadReset message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadReset} ProtobufEventPayloadReset
     */
    ProtobufEventPayloadReset.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadReset)
            return object;
        var message = new $root.ProtobufEventPayloadReset();
        if (object.bootMode != null)
            message.bootMode = Boolean(object.bootMode);
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadReset message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {ProtobufEventPayloadReset} message ProtobufEventPayloadReset
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadReset.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.bootMode = false;
        if (message.bootMode != null && message.hasOwnProperty("bootMode"))
            object.bootMode = message.bootMode;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadReset to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadReset
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadReset.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadReset
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadReset
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadReset.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadReset";
    };

    return ProtobufEventPayloadReset;
})();

$root.ProtobufEventPayloadSession = (function() {

    /**
     * Properties of a ProtobufEventPayloadSession.
     * @exports IProtobufEventPayloadSession
     * @interface IProtobufEventPayloadSession
     * @property {ProtobufEventPayloadSession.State|null} [state] ProtobufEventPayloadSession state
     * @property {number|null} [requestsSent] ProtobufEventPayloadSession requestsSent
     */

    /**
     * Constructs a new ProtobufEventPayloadSession.
     * @exports ProtobufEventPayloadSession
     * @classdesc Represents a ProtobufEventPayloadSession.
     * @implements IProtobufEventPayloadSession
     * @constructor
     * @param {IProtobufEventPayloadSession=} [properties] Properties to set
     */
    function ProtobufEventPayloadSession(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadSession state.
     * @member {ProtobufEventPayloadSession.State} state
     * @memberof ProtobufEventPayloadSession
     * @instance
     */
    ProtobufEventPayloadSession.prototype.state = 0;

    /**
     * ProtobufEventPayloadSession requestsSent.
     * @member {number} requestsSent
     * @memberof ProtobufEventPayloadSession
     * @instance
     */
    ProtobufEventPayloadSession.prototype.requestsSent = 0;

    /**
     * Creates a new ProtobufEventPayloadSession instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {IProtobufEventPayloadSession=} [properties] Properties to set
     * @returns {ProtobufEventPayloadSession} ProtobufEventPayloadSession instance
     */
    ProtobufEventPayloadSession.create = function create(properties) {
        return new ProtobufEventPayloadSession(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadSession message. Does not implicitly {@link ProtobufEventPayloadSession.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {IProtobufEventPayloadSession} message ProtobufEventPayloadSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSession.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
        if (message.requestsSent != null && Object.hasOwnProperty.call(message, "requestsSent"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.requestsSent);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadSession message, length delimited. Does not implicitly {@link ProtobufEventPayloadSession.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {IProtobufEventPayloadSession} message ProtobufEventPayloadSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSession.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadSession message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadSession} ProtobufEventPayloadSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSession.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSession();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.state = reader.int32();
                    break;
                }
            case 2: {
                    message.requestsSent = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadSession message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadSession} ProtobufEventPayloadSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSession.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadSession message.
     * @function verify
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadSession.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.requestsSent != null && message.hasOwnProperty("requestsSent"))
            if (!$util.isInteger(message.requestsSent))
                return "requestsSent: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadSession message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadSession} ProtobufEventPayloadSession
     */
    ProtobufEventPayloadSession.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadSession)
            return object;
        var message = new $root.ProtobufEventPayloadSession();
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.state = 0;
            break;
        case "kRequest":
        case 1:
            message.state = 1;
            break;
        case "kRequestSent":
        case 2:
            message.state = 2;
            break;
        case "kResponse":
        case 3:
            message.state = 3;
            break;
        case "kEstablished":
        case 4:
            message.state = 4;
            break;
        }
        if (object.requestsSent != null)
            message.requestsSent = object.requestsSent >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadSession message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {ProtobufEventPayloadSession} message ProtobufEventPayloadSession
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadSession.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.state = options.enums === String ? "kNone" : 0;
            object.requestsSent = 0;
        }
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.ProtobufEventPayloadSession.State[message.state] === undefined ? message.state : $root.ProtobufEventPayloadSession.State[message.state] : message.state;
        if (message.requestsSent != null && message.hasOwnProperty("requestsSent"))
            object.requestsSent = message.requestsSent;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadSession to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadSession
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadSession.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadSession
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadSession
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadSession";
    };

    /**
     * State enum.
     * @name ProtobufEventPayloadSession.State
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kRequest=1 kRequest value
     * @property {number} kRequestSent=2 kRequestSent value
     * @property {number} kResponse=3 kResponse value
     * @property {number} kEstablished=4 kEstablished value
     */
    ProtobufEventPayloadSession.State = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kRequest"] = 1;
        values[valuesById[2] = "kRequestSent"] = 2;
        values[valuesById[3] = "kResponse"] = 3;
        values[valuesById[4] = "kEstablished"] = 4;
        return values;
    })();

    return ProtobufEventPayloadSession;
})();

$root.ProtobufEventPayloadOperatingMode = (function() {

    /**
     * Properties of a ProtobufEventPayloadOperatingMode.
     * @exports IProtobufEventPayloadOperatingMode
     * @interface IProtobufEventPayloadOperatingMode
     * @property {ProtobufEventPayloadOperatingMode.State|null} [trackingState] ProtobufEventPayloadOperatingMode trackingState
     * @property {ProtobufEventPayloadOperatingMode.Source|null} [trackingSource] ProtobufEventPayloadOperatingMode trackingSource
     * @property {ProtobufEventPayloadOperatingMode.ITrackingCriteria|null} [trackingCriteria] ProtobufEventPayloadOperatingMode trackingCriteria
     * @property {ProtobufEventPayloadOperatingMode.State|null} [sosState] ProtobufEventPayloadOperatingMode sosState
     * @property {ProtobufEventPayloadOperatingMode.Source|null} [sosSource] ProtobufEventPayloadOperatingMode sosSource
     * @property {ProtobufEventPayloadOperatingMode.State|null} [watchState] ProtobufEventPayloadOperatingMode watchState
     * @property {ProtobufEventPayloadOperatingMode.Source|null} [watchSource] ProtobufEventPayloadOperatingMode watchSource
     * @property {ProtobufEventPayloadOperatingMode.State|null} [markState] ProtobufEventPayloadOperatingMode markState
     * @property {ProtobufEventPayloadOperatingMode.Source|null} [markSource] ProtobufEventPayloadOperatingMode markSource
     * @property {number|null} [markIndex] ProtobufEventPayloadOperatingMode markIndex
     */

    /**
     * Constructs a new ProtobufEventPayloadOperatingMode.
     * @exports ProtobufEventPayloadOperatingMode
     * @classdesc Represents a ProtobufEventPayloadOperatingMode.
     * @implements IProtobufEventPayloadOperatingMode
     * @constructor
     * @param {IProtobufEventPayloadOperatingMode=} [properties] Properties to set
     */
    function ProtobufEventPayloadOperatingMode(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadOperatingMode trackingState.
     * @member {ProtobufEventPayloadOperatingMode.State} trackingState
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.trackingState = 0;

    /**
     * ProtobufEventPayloadOperatingMode trackingSource.
     * @member {ProtobufEventPayloadOperatingMode.Source} trackingSource
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.trackingSource = 0;

    /**
     * ProtobufEventPayloadOperatingMode trackingCriteria.
     * @member {ProtobufEventPayloadOperatingMode.ITrackingCriteria|null|undefined} trackingCriteria
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.trackingCriteria = null;

    /**
     * ProtobufEventPayloadOperatingMode sosState.
     * @member {ProtobufEventPayloadOperatingMode.State} sosState
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.sosState = 0;

    /**
     * ProtobufEventPayloadOperatingMode sosSource.
     * @member {ProtobufEventPayloadOperatingMode.Source} sosSource
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.sosSource = 0;

    /**
     * ProtobufEventPayloadOperatingMode watchState.
     * @member {ProtobufEventPayloadOperatingMode.State} watchState
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.watchState = 0;

    /**
     * ProtobufEventPayloadOperatingMode watchSource.
     * @member {ProtobufEventPayloadOperatingMode.Source} watchSource
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.watchSource = 0;

    /**
     * ProtobufEventPayloadOperatingMode markState.
     * @member {ProtobufEventPayloadOperatingMode.State} markState
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.markState = 0;

    /**
     * ProtobufEventPayloadOperatingMode markSource.
     * @member {ProtobufEventPayloadOperatingMode.Source} markSource
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.markSource = 0;

    /**
     * ProtobufEventPayloadOperatingMode markIndex.
     * @member {number} markIndex
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     */
    ProtobufEventPayloadOperatingMode.prototype.markIndex = 0;

    /**
     * Creates a new ProtobufEventPayloadOperatingMode instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {IProtobufEventPayloadOperatingMode=} [properties] Properties to set
     * @returns {ProtobufEventPayloadOperatingMode} ProtobufEventPayloadOperatingMode instance
     */
    ProtobufEventPayloadOperatingMode.create = function create(properties) {
        return new ProtobufEventPayloadOperatingMode(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadOperatingMode message. Does not implicitly {@link ProtobufEventPayloadOperatingMode.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {IProtobufEventPayloadOperatingMode} message ProtobufEventPayloadOperatingMode message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadOperatingMode.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.trackingState != null && Object.hasOwnProperty.call(message, "trackingState"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.trackingState);
        if (message.trackingSource != null && Object.hasOwnProperty.call(message, "trackingSource"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.trackingSource);
        if (message.trackingCriteria != null && Object.hasOwnProperty.call(message, "trackingCriteria"))
            $root.ProtobufEventPayloadOperatingMode.TrackingCriteria.encode(message.trackingCriteria, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.sosState != null && Object.hasOwnProperty.call(message, "sosState"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.sosState);
        if (message.sosSource != null && Object.hasOwnProperty.call(message, "sosSource"))
            writer.uint32(/* id 21, wireType 0 =*/168).int32(message.sosSource);
        if (message.watchState != null && Object.hasOwnProperty.call(message, "watchState"))
            writer.uint32(/* id 30, wireType 0 =*/240).int32(message.watchState);
        if (message.watchSource != null && Object.hasOwnProperty.call(message, "watchSource"))
            writer.uint32(/* id 31, wireType 0 =*/248).int32(message.watchSource);
        if (message.markState != null && Object.hasOwnProperty.call(message, "markState"))
            writer.uint32(/* id 40, wireType 0 =*/320).int32(message.markState);
        if (message.markSource != null && Object.hasOwnProperty.call(message, "markSource"))
            writer.uint32(/* id 41, wireType 0 =*/328).int32(message.markSource);
        if (message.markIndex != null && Object.hasOwnProperty.call(message, "markIndex"))
            writer.uint32(/* id 42, wireType 0 =*/336).uint32(message.markIndex);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadOperatingMode message, length delimited. Does not implicitly {@link ProtobufEventPayloadOperatingMode.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {IProtobufEventPayloadOperatingMode} message ProtobufEventPayloadOperatingMode message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadOperatingMode.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadOperatingMode message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadOperatingMode} ProtobufEventPayloadOperatingMode
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadOperatingMode.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadOperatingMode();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 10: {
                    message.trackingState = reader.int32();
                    break;
                }
            case 11: {
                    message.trackingSource = reader.int32();
                    break;
                }
            case 12: {
                    message.trackingCriteria = $root.ProtobufEventPayloadOperatingMode.TrackingCriteria.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.sosState = reader.int32();
                    break;
                }
            case 21: {
                    message.sosSource = reader.int32();
                    break;
                }
            case 30: {
                    message.watchState = reader.int32();
                    break;
                }
            case 31: {
                    message.watchSource = reader.int32();
                    break;
                }
            case 40: {
                    message.markState = reader.int32();
                    break;
                }
            case 41: {
                    message.markSource = reader.int32();
                    break;
                }
            case 42: {
                    message.markIndex = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadOperatingMode message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadOperatingMode} ProtobufEventPayloadOperatingMode
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadOperatingMode.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadOperatingMode message.
     * @function verify
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadOperatingMode.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.trackingState != null && message.hasOwnProperty("trackingState"))
            switch (message.trackingState) {
            default:
                return "trackingState: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.trackingSource != null && message.hasOwnProperty("trackingSource"))
            switch (message.trackingSource) {
            default:
                return "trackingSource: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.trackingCriteria != null && message.hasOwnProperty("trackingCriteria")) {
            var error = $root.ProtobufEventPayloadOperatingMode.TrackingCriteria.verify(message.trackingCriteria);
            if (error)
                return "trackingCriteria." + error;
        }
        if (message.sosState != null && message.hasOwnProperty("sosState"))
            switch (message.sosState) {
            default:
                return "sosState: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.sosSource != null && message.hasOwnProperty("sosSource"))
            switch (message.sosSource) {
            default:
                return "sosSource: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.watchState != null && message.hasOwnProperty("watchState"))
            switch (message.watchState) {
            default:
                return "watchState: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.watchSource != null && message.hasOwnProperty("watchSource"))
            switch (message.watchSource) {
            default:
                return "watchSource: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.markState != null && message.hasOwnProperty("markState"))
            switch (message.markState) {
            default:
                return "markState: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.markSource != null && message.hasOwnProperty("markSource"))
            switch (message.markSource) {
            default:
                return "markSource: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.markIndex != null && message.hasOwnProperty("markIndex"))
            if (!$util.isInteger(message.markIndex))
                return "markIndex: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadOperatingMode message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadOperatingMode} ProtobufEventPayloadOperatingMode
     */
    ProtobufEventPayloadOperatingMode.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadOperatingMode)
            return object;
        var message = new $root.ProtobufEventPayloadOperatingMode();
        switch (object.trackingState) {
        default:
            if (typeof object.trackingState === "number") {
                message.trackingState = object.trackingState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.trackingState = 0;
            break;
        case "kInactive":
        case 1:
            message.trackingState = 1;
            break;
        case "kActivated":
        case 2:
            message.trackingState = 2;
            break;
        case "kActive":
        case 3:
            message.trackingState = 3;
            break;
        case "kDeactivated":
        case 4:
            message.trackingState = 4;
            break;
        }
        switch (object.trackingSource) {
        default:
            if (typeof object.trackingSource === "number") {
                message.trackingSource = object.trackingSource;
                break;
            }
            break;
        case "kNoSource":
        case 0:
            message.trackingSource = 0;
            break;
        case "kError":
        case 1:
            message.trackingSource = 1;
            break;
        case "kTimeout":
        case 2:
            message.trackingSource = 2;
            break;
        case "kServer":
        case 3:
            message.trackingSource = 3;
            break;
        case "kButton":
        case 4:
            message.trackingSource = 4;
            break;
        case "kPoint":
        case 5:
            message.trackingSource = 5;
            break;
        case "kSpeedTrigger":
        case 6:
            message.trackingSource = 6;
            break;
        case "kTimeTrigger":
        case 7:
            message.trackingSource = 7;
            break;
        }
        if (object.trackingCriteria != null) {
            if (typeof object.trackingCriteria !== "object")
                throw TypeError(".ProtobufEventPayloadOperatingMode.trackingCriteria: object expected");
            message.trackingCriteria = $root.ProtobufEventPayloadOperatingMode.TrackingCriteria.fromObject(object.trackingCriteria);
        }
        switch (object.sosState) {
        default:
            if (typeof object.sosState === "number") {
                message.sosState = object.sosState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.sosState = 0;
            break;
        case "kInactive":
        case 1:
            message.sosState = 1;
            break;
        case "kActivated":
        case 2:
            message.sosState = 2;
            break;
        case "kActive":
        case 3:
            message.sosState = 3;
            break;
        case "kDeactivated":
        case 4:
            message.sosState = 4;
            break;
        }
        switch (object.sosSource) {
        default:
            if (typeof object.sosSource === "number") {
                message.sosSource = object.sosSource;
                break;
            }
            break;
        case "kNoSource":
        case 0:
            message.sosSource = 0;
            break;
        case "kError":
        case 1:
            message.sosSource = 1;
            break;
        case "kTimeout":
        case 2:
            message.sosSource = 2;
            break;
        case "kServer":
        case 3:
            message.sosSource = 3;
            break;
        case "kButton":
        case 4:
            message.sosSource = 4;
            break;
        case "kPoint":
        case 5:
            message.sosSource = 5;
            break;
        case "kSpeedTrigger":
        case 6:
            message.sosSource = 6;
            break;
        case "kTimeTrigger":
        case 7:
            message.sosSource = 7;
            break;
        }
        switch (object.watchState) {
        default:
            if (typeof object.watchState === "number") {
                message.watchState = object.watchState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.watchState = 0;
            break;
        case "kInactive":
        case 1:
            message.watchState = 1;
            break;
        case "kActivated":
        case 2:
            message.watchState = 2;
            break;
        case "kActive":
        case 3:
            message.watchState = 3;
            break;
        case "kDeactivated":
        case 4:
            message.watchState = 4;
            break;
        }
        switch (object.watchSource) {
        default:
            if (typeof object.watchSource === "number") {
                message.watchSource = object.watchSource;
                break;
            }
            break;
        case "kNoSource":
        case 0:
            message.watchSource = 0;
            break;
        case "kError":
        case 1:
            message.watchSource = 1;
            break;
        case "kTimeout":
        case 2:
            message.watchSource = 2;
            break;
        case "kServer":
        case 3:
            message.watchSource = 3;
            break;
        case "kButton":
        case 4:
            message.watchSource = 4;
            break;
        case "kPoint":
        case 5:
            message.watchSource = 5;
            break;
        case "kSpeedTrigger":
        case 6:
            message.watchSource = 6;
            break;
        case "kTimeTrigger":
        case 7:
            message.watchSource = 7;
            break;
        }
        switch (object.markState) {
        default:
            if (typeof object.markState === "number") {
                message.markState = object.markState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.markState = 0;
            break;
        case "kInactive":
        case 1:
            message.markState = 1;
            break;
        case "kActivated":
        case 2:
            message.markState = 2;
            break;
        case "kActive":
        case 3:
            message.markState = 3;
            break;
        case "kDeactivated":
        case 4:
            message.markState = 4;
            break;
        }
        switch (object.markSource) {
        default:
            if (typeof object.markSource === "number") {
                message.markSource = object.markSource;
                break;
            }
            break;
        case "kNoSource":
        case 0:
            message.markSource = 0;
            break;
        case "kError":
        case 1:
            message.markSource = 1;
            break;
        case "kTimeout":
        case 2:
            message.markSource = 2;
            break;
        case "kServer":
        case 3:
            message.markSource = 3;
            break;
        case "kButton":
        case 4:
            message.markSource = 4;
            break;
        case "kPoint":
        case 5:
            message.markSource = 5;
            break;
        case "kSpeedTrigger":
        case 6:
            message.markSource = 6;
            break;
        case "kTimeTrigger":
        case 7:
            message.markSource = 7;
            break;
        }
        if (object.markIndex != null)
            message.markIndex = object.markIndex >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadOperatingMode message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {ProtobufEventPayloadOperatingMode} message ProtobufEventPayloadOperatingMode
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadOperatingMode.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.trackingState = options.enums === String ? "kNone" : 0;
            object.trackingSource = options.enums === String ? "kNoSource" : 0;
            object.trackingCriteria = null;
            object.sosState = options.enums === String ? "kNone" : 0;
            object.sosSource = options.enums === String ? "kNoSource" : 0;
            object.watchState = options.enums === String ? "kNone" : 0;
            object.watchSource = options.enums === String ? "kNoSource" : 0;
            object.markState = options.enums === String ? "kNone" : 0;
            object.markSource = options.enums === String ? "kNoSource" : 0;
            object.markIndex = 0;
        }
        if (message.trackingState != null && message.hasOwnProperty("trackingState"))
            object.trackingState = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.State[message.trackingState] === undefined ? message.trackingState : $root.ProtobufEventPayloadOperatingMode.State[message.trackingState] : message.trackingState;
        if (message.trackingSource != null && message.hasOwnProperty("trackingSource"))
            object.trackingSource = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.Source[message.trackingSource] === undefined ? message.trackingSource : $root.ProtobufEventPayloadOperatingMode.Source[message.trackingSource] : message.trackingSource;
        if (message.trackingCriteria != null && message.hasOwnProperty("trackingCriteria"))
            object.trackingCriteria = $root.ProtobufEventPayloadOperatingMode.TrackingCriteria.toObject(message.trackingCriteria, options);
        if (message.sosState != null && message.hasOwnProperty("sosState"))
            object.sosState = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.State[message.sosState] === undefined ? message.sosState : $root.ProtobufEventPayloadOperatingMode.State[message.sosState] : message.sosState;
        if (message.sosSource != null && message.hasOwnProperty("sosSource"))
            object.sosSource = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.Source[message.sosSource] === undefined ? message.sosSource : $root.ProtobufEventPayloadOperatingMode.Source[message.sosSource] : message.sosSource;
        if (message.watchState != null && message.hasOwnProperty("watchState"))
            object.watchState = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.State[message.watchState] === undefined ? message.watchState : $root.ProtobufEventPayloadOperatingMode.State[message.watchState] : message.watchState;
        if (message.watchSource != null && message.hasOwnProperty("watchSource"))
            object.watchSource = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.Source[message.watchSource] === undefined ? message.watchSource : $root.ProtobufEventPayloadOperatingMode.Source[message.watchSource] : message.watchSource;
        if (message.markState != null && message.hasOwnProperty("markState"))
            object.markState = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.State[message.markState] === undefined ? message.markState : $root.ProtobufEventPayloadOperatingMode.State[message.markState] : message.markState;
        if (message.markSource != null && message.hasOwnProperty("markSource"))
            object.markSource = options.enums === String ? $root.ProtobufEventPayloadOperatingMode.Source[message.markSource] === undefined ? message.markSource : $root.ProtobufEventPayloadOperatingMode.Source[message.markSource] : message.markSource;
        if (message.markIndex != null && message.hasOwnProperty("markIndex"))
            object.markIndex = message.markIndex;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadOperatingMode to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadOperatingMode
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadOperatingMode.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadOperatingMode
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadOperatingMode
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadOperatingMode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadOperatingMode";
    };

    /**
     * State enum.
     * @name ProtobufEventPayloadOperatingMode.State
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kInactive=1 kInactive value
     * @property {number} kActivated=2 kActivated value
     * @property {number} kActive=3 kActive value
     * @property {number} kDeactivated=4 kDeactivated value
     */
    ProtobufEventPayloadOperatingMode.State = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kInactive"] = 1;
        values[valuesById[2] = "kActivated"] = 2;
        values[valuesById[3] = "kActive"] = 3;
        values[valuesById[4] = "kDeactivated"] = 4;
        return values;
    })();

    /**
     * Source enum.
     * @name ProtobufEventPayloadOperatingMode.Source
     * @enum {number}
     * @property {number} kNoSource=0 kNoSource value
     * @property {number} kError=1 kError value
     * @property {number} kTimeout=2 kTimeout value
     * @property {number} kServer=3 kServer value
     * @property {number} kButton=4 kButton value
     * @property {number} kPoint=5 kPoint value
     * @property {number} kSpeedTrigger=6 kSpeedTrigger value
     * @property {number} kTimeTrigger=7 kTimeTrigger value
     */
    ProtobufEventPayloadOperatingMode.Source = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoSource"] = 0;
        values[valuesById[1] = "kError"] = 1;
        values[valuesById[2] = "kTimeout"] = 2;
        values[valuesById[3] = "kServer"] = 3;
        values[valuesById[4] = "kButton"] = 4;
        values[valuesById[5] = "kPoint"] = 5;
        values[valuesById[6] = "kSpeedTrigger"] = 6;
        values[valuesById[7] = "kTimeTrigger"] = 7;
        return values;
    })();

    ProtobufEventPayloadOperatingMode.TrackingCriteria = (function() {

        /**
         * Properties of a TrackingCriteria.
         * @memberof ProtobufEventPayloadOperatingMode
         * @interface ITrackingCriteria
         * @property {boolean|null} [sessionEstablished] TrackingCriteria sessionEstablished
         * @property {boolean|null} [initialLocationEstablished] TrackingCriteria initialLocationEstablished
         * @property {boolean|null} [locationSentCurrent] TrackingCriteria locationSentCurrent
         * @property {boolean|null} [eventQueueCurrent] TrackingCriteria eventQueueCurrent
         * @property {boolean|null} [serialDeviceCommunicationEstablished] TrackingCriteria serialDeviceCommunicationEstablished
         */

        /**
         * Constructs a new TrackingCriteria.
         * @memberof ProtobufEventPayloadOperatingMode
         * @classdesc Represents a TrackingCriteria.
         * @implements ITrackingCriteria
         * @constructor
         * @param {ProtobufEventPayloadOperatingMode.ITrackingCriteria=} [properties] Properties to set
         */
        function TrackingCriteria(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TrackingCriteria sessionEstablished.
         * @member {boolean} sessionEstablished
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @instance
         */
        TrackingCriteria.prototype.sessionEstablished = false;

        /**
         * TrackingCriteria initialLocationEstablished.
         * @member {boolean} initialLocationEstablished
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @instance
         */
        TrackingCriteria.prototype.initialLocationEstablished = false;

        /**
         * TrackingCriteria locationSentCurrent.
         * @member {boolean} locationSentCurrent
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @instance
         */
        TrackingCriteria.prototype.locationSentCurrent = false;

        /**
         * TrackingCriteria eventQueueCurrent.
         * @member {boolean} eventQueueCurrent
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @instance
         */
        TrackingCriteria.prototype.eventQueueCurrent = false;

        /**
         * TrackingCriteria serialDeviceCommunicationEstablished.
         * @member {boolean} serialDeviceCommunicationEstablished
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @instance
         */
        TrackingCriteria.prototype.serialDeviceCommunicationEstablished = false;

        /**
         * Creates a new TrackingCriteria instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {ProtobufEventPayloadOperatingMode.ITrackingCriteria=} [properties] Properties to set
         * @returns {ProtobufEventPayloadOperatingMode.TrackingCriteria} TrackingCriteria instance
         */
        TrackingCriteria.create = function create(properties) {
            return new TrackingCriteria(properties);
        };

        /**
         * Encodes the specified TrackingCriteria message. Does not implicitly {@link ProtobufEventPayloadOperatingMode.TrackingCriteria.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {ProtobufEventPayloadOperatingMode.ITrackingCriteria} message TrackingCriteria message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrackingCriteria.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionEstablished != null && Object.hasOwnProperty.call(message, "sessionEstablished"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.sessionEstablished);
            if (message.initialLocationEstablished != null && Object.hasOwnProperty.call(message, "initialLocationEstablished"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.initialLocationEstablished);
            if (message.locationSentCurrent != null && Object.hasOwnProperty.call(message, "locationSentCurrent"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.locationSentCurrent);
            if (message.eventQueueCurrent != null && Object.hasOwnProperty.call(message, "eventQueueCurrent"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.eventQueueCurrent);
            if (message.serialDeviceCommunicationEstablished != null && Object.hasOwnProperty.call(message, "serialDeviceCommunicationEstablished"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.serialDeviceCommunicationEstablished);
            return writer;
        };

        /**
         * Encodes the specified TrackingCriteria message, length delimited. Does not implicitly {@link ProtobufEventPayloadOperatingMode.TrackingCriteria.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {ProtobufEventPayloadOperatingMode.ITrackingCriteria} message TrackingCriteria message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrackingCriteria.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TrackingCriteria message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadOperatingMode.TrackingCriteria} TrackingCriteria
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrackingCriteria.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadOperatingMode.TrackingCriteria();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionEstablished = reader.bool();
                        break;
                    }
                case 2: {
                        message.initialLocationEstablished = reader.bool();
                        break;
                    }
                case 3: {
                        message.locationSentCurrent = reader.bool();
                        break;
                    }
                case 4: {
                        message.eventQueueCurrent = reader.bool();
                        break;
                    }
                case 5: {
                        message.serialDeviceCommunicationEstablished = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TrackingCriteria message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadOperatingMode.TrackingCriteria} TrackingCriteria
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrackingCriteria.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TrackingCriteria message.
         * @function verify
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TrackingCriteria.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionEstablished != null && message.hasOwnProperty("sessionEstablished"))
                if (typeof message.sessionEstablished !== "boolean")
                    return "sessionEstablished: boolean expected";
            if (message.initialLocationEstablished != null && message.hasOwnProperty("initialLocationEstablished"))
                if (typeof message.initialLocationEstablished !== "boolean")
                    return "initialLocationEstablished: boolean expected";
            if (message.locationSentCurrent != null && message.hasOwnProperty("locationSentCurrent"))
                if (typeof message.locationSentCurrent !== "boolean")
                    return "locationSentCurrent: boolean expected";
            if (message.eventQueueCurrent != null && message.hasOwnProperty("eventQueueCurrent"))
                if (typeof message.eventQueueCurrent !== "boolean")
                    return "eventQueueCurrent: boolean expected";
            if (message.serialDeviceCommunicationEstablished != null && message.hasOwnProperty("serialDeviceCommunicationEstablished"))
                if (typeof message.serialDeviceCommunicationEstablished !== "boolean")
                    return "serialDeviceCommunicationEstablished: boolean expected";
            return null;
        };

        /**
         * Creates a TrackingCriteria message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadOperatingMode.TrackingCriteria} TrackingCriteria
         */
        TrackingCriteria.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadOperatingMode.TrackingCriteria)
                return object;
            var message = new $root.ProtobufEventPayloadOperatingMode.TrackingCriteria();
            if (object.sessionEstablished != null)
                message.sessionEstablished = Boolean(object.sessionEstablished);
            if (object.initialLocationEstablished != null)
                message.initialLocationEstablished = Boolean(object.initialLocationEstablished);
            if (object.locationSentCurrent != null)
                message.locationSentCurrent = Boolean(object.locationSentCurrent);
            if (object.eventQueueCurrent != null)
                message.eventQueueCurrent = Boolean(object.eventQueueCurrent);
            if (object.serialDeviceCommunicationEstablished != null)
                message.serialDeviceCommunicationEstablished = Boolean(object.serialDeviceCommunicationEstablished);
            return message;
        };

        /**
         * Creates a plain object from a TrackingCriteria message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {ProtobufEventPayloadOperatingMode.TrackingCriteria} message TrackingCriteria
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TrackingCriteria.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.sessionEstablished = false;
                object.initialLocationEstablished = false;
                object.locationSentCurrent = false;
                object.eventQueueCurrent = false;
                object.serialDeviceCommunicationEstablished = false;
            }
            if (message.sessionEstablished != null && message.hasOwnProperty("sessionEstablished"))
                object.sessionEstablished = message.sessionEstablished;
            if (message.initialLocationEstablished != null && message.hasOwnProperty("initialLocationEstablished"))
                object.initialLocationEstablished = message.initialLocationEstablished;
            if (message.locationSentCurrent != null && message.hasOwnProperty("locationSentCurrent"))
                object.locationSentCurrent = message.locationSentCurrent;
            if (message.eventQueueCurrent != null && message.hasOwnProperty("eventQueueCurrent"))
                object.eventQueueCurrent = message.eventQueueCurrent;
            if (message.serialDeviceCommunicationEstablished != null && message.hasOwnProperty("serialDeviceCommunicationEstablished"))
                object.serialDeviceCommunicationEstablished = message.serialDeviceCommunicationEstablished;
            return object;
        };

        /**
         * Converts this TrackingCriteria to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TrackingCriteria.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TrackingCriteria
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadOperatingMode.TrackingCriteria
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TrackingCriteria.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadOperatingMode.TrackingCriteria";
        };

        return TrackingCriteria;
    })();

    return ProtobufEventPayloadOperatingMode;
})();

$root.ProtobufEventPayloadOta = (function() {

    /**
     * Properties of a ProtobufEventPayloadOta.
     * @exports IProtobufEventPayloadOta
     * @interface IProtobufEventPayloadOta
     * @property {ProtobufEventPayloadOta.State|null} [state] ProtobufEventPayloadOta state
     * @property {number|null} [bootCount] ProtobufEventPayloadOta bootCount
     * @property {ProtobufEventPayloadOta.IVersion|null} [mainVersion] ProtobufEventPayloadOta mainVersion
     * @property {ProtobufEventPayloadOta.IVersion|null} [bootloaderVersion] ProtobufEventPayloadOta bootloaderVersion
     * @property {ProtobufEventPayloadOta.IVersion|null} [coprocessorVersion] ProtobufEventPayloadOta coprocessorVersion
     * @property {ProtobufEventPayloadOta.IVersion|null} [downloadedVersion] ProtobufEventPayloadOta downloadedVersion
     */

    /**
     * Constructs a new ProtobufEventPayloadOta.
     * @exports ProtobufEventPayloadOta
     * @classdesc Represents a ProtobufEventPayloadOta.
     * @implements IProtobufEventPayloadOta
     * @constructor
     * @param {IProtobufEventPayloadOta=} [properties] Properties to set
     */
    function ProtobufEventPayloadOta(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadOta state.
     * @member {ProtobufEventPayloadOta.State} state
     * @memberof ProtobufEventPayloadOta
     * @instance
     */
    ProtobufEventPayloadOta.prototype.state = 0;

    /**
     * ProtobufEventPayloadOta bootCount.
     * @member {number} bootCount
     * @memberof ProtobufEventPayloadOta
     * @instance
     */
    ProtobufEventPayloadOta.prototype.bootCount = 0;

    /**
     * ProtobufEventPayloadOta mainVersion.
     * @member {ProtobufEventPayloadOta.IVersion|null|undefined} mainVersion
     * @memberof ProtobufEventPayloadOta
     * @instance
     */
    ProtobufEventPayloadOta.prototype.mainVersion = null;

    /**
     * ProtobufEventPayloadOta bootloaderVersion.
     * @member {ProtobufEventPayloadOta.IVersion|null|undefined} bootloaderVersion
     * @memberof ProtobufEventPayloadOta
     * @instance
     */
    ProtobufEventPayloadOta.prototype.bootloaderVersion = null;

    /**
     * ProtobufEventPayloadOta coprocessorVersion.
     * @member {ProtobufEventPayloadOta.IVersion|null|undefined} coprocessorVersion
     * @memberof ProtobufEventPayloadOta
     * @instance
     */
    ProtobufEventPayloadOta.prototype.coprocessorVersion = null;

    /**
     * ProtobufEventPayloadOta downloadedVersion.
     * @member {ProtobufEventPayloadOta.IVersion|null|undefined} downloadedVersion
     * @memberof ProtobufEventPayloadOta
     * @instance
     */
    ProtobufEventPayloadOta.prototype.downloadedVersion = null;

    /**
     * Creates a new ProtobufEventPayloadOta instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {IProtobufEventPayloadOta=} [properties] Properties to set
     * @returns {ProtobufEventPayloadOta} ProtobufEventPayloadOta instance
     */
    ProtobufEventPayloadOta.create = function create(properties) {
        return new ProtobufEventPayloadOta(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadOta message. Does not implicitly {@link ProtobufEventPayloadOta.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {IProtobufEventPayloadOta} message ProtobufEventPayloadOta message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadOta.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
        if (message.bootCount != null && Object.hasOwnProperty.call(message, "bootCount"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.bootCount);
        if (message.mainVersion != null && Object.hasOwnProperty.call(message, "mainVersion"))
            $root.ProtobufEventPayloadOta.Version.encode(message.mainVersion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.bootloaderVersion != null && Object.hasOwnProperty.call(message, "bootloaderVersion"))
            $root.ProtobufEventPayloadOta.Version.encode(message.bootloaderVersion, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.coprocessorVersion != null && Object.hasOwnProperty.call(message, "coprocessorVersion"))
            $root.ProtobufEventPayloadOta.Version.encode(message.coprocessorVersion, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.downloadedVersion != null && Object.hasOwnProperty.call(message, "downloadedVersion"))
            $root.ProtobufEventPayloadOta.Version.encode(message.downloadedVersion, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadOta message, length delimited. Does not implicitly {@link ProtobufEventPayloadOta.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {IProtobufEventPayloadOta} message ProtobufEventPayloadOta message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadOta.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadOta message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadOta} ProtobufEventPayloadOta
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadOta.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadOta();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.state = reader.int32();
                    break;
                }
            case 2: {
                    message.bootCount = reader.uint32();
                    break;
                }
            case 3: {
                    message.mainVersion = $root.ProtobufEventPayloadOta.Version.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.bootloaderVersion = $root.ProtobufEventPayloadOta.Version.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.coprocessorVersion = $root.ProtobufEventPayloadOta.Version.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.downloadedVersion = $root.ProtobufEventPayloadOta.Version.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadOta message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadOta} ProtobufEventPayloadOta
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadOta.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadOta message.
     * @function verify
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadOta.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.bootCount != null && message.hasOwnProperty("bootCount"))
            if (!$util.isInteger(message.bootCount))
                return "bootCount: integer expected";
        if (message.mainVersion != null && message.hasOwnProperty("mainVersion")) {
            var error = $root.ProtobufEventPayloadOta.Version.verify(message.mainVersion);
            if (error)
                return "mainVersion." + error;
        }
        if (message.bootloaderVersion != null && message.hasOwnProperty("bootloaderVersion")) {
            var error = $root.ProtobufEventPayloadOta.Version.verify(message.bootloaderVersion);
            if (error)
                return "bootloaderVersion." + error;
        }
        if (message.coprocessorVersion != null && message.hasOwnProperty("coprocessorVersion")) {
            var error = $root.ProtobufEventPayloadOta.Version.verify(message.coprocessorVersion);
            if (error)
                return "coprocessorVersion." + error;
        }
        if (message.downloadedVersion != null && message.hasOwnProperty("downloadedVersion")) {
            var error = $root.ProtobufEventPayloadOta.Version.verify(message.downloadedVersion);
            if (error)
                return "downloadedVersion." + error;
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadOta message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadOta} ProtobufEventPayloadOta
     */
    ProtobufEventPayloadOta.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadOta)
            return object;
        var message = new $root.ProtobufEventPayloadOta();
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "kBootingImages":
        case 0:
            message.state = 0;
            break;
        case "kAwaitingNetwork":
        case 1:
            message.state = 1;
            break;
        case "kAwaitingJob":
        case 2:
            message.state = 2;
            break;
        case "kDownloadingImage":
        case 3:
            message.state = 3;
            break;
        case "kIdle":
        case 4:
            message.state = 4;
            break;
        case "kError":
        case 5:
            message.state = 5;
            break;
        }
        if (object.bootCount != null)
            message.bootCount = object.bootCount >>> 0;
        if (object.mainVersion != null) {
            if (typeof object.mainVersion !== "object")
                throw TypeError(".ProtobufEventPayloadOta.mainVersion: object expected");
            message.mainVersion = $root.ProtobufEventPayloadOta.Version.fromObject(object.mainVersion);
        }
        if (object.bootloaderVersion != null) {
            if (typeof object.bootloaderVersion !== "object")
                throw TypeError(".ProtobufEventPayloadOta.bootloaderVersion: object expected");
            message.bootloaderVersion = $root.ProtobufEventPayloadOta.Version.fromObject(object.bootloaderVersion);
        }
        if (object.coprocessorVersion != null) {
            if (typeof object.coprocessorVersion !== "object")
                throw TypeError(".ProtobufEventPayloadOta.coprocessorVersion: object expected");
            message.coprocessorVersion = $root.ProtobufEventPayloadOta.Version.fromObject(object.coprocessorVersion);
        }
        if (object.downloadedVersion != null) {
            if (typeof object.downloadedVersion !== "object")
                throw TypeError(".ProtobufEventPayloadOta.downloadedVersion: object expected");
            message.downloadedVersion = $root.ProtobufEventPayloadOta.Version.fromObject(object.downloadedVersion);
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadOta message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {ProtobufEventPayloadOta} message ProtobufEventPayloadOta
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadOta.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.state = options.enums === String ? "kBootingImages" : 0;
            object.bootCount = 0;
            object.mainVersion = null;
            object.bootloaderVersion = null;
            object.coprocessorVersion = null;
            object.downloadedVersion = null;
        }
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.ProtobufEventPayloadOta.State[message.state] === undefined ? message.state : $root.ProtobufEventPayloadOta.State[message.state] : message.state;
        if (message.bootCount != null && message.hasOwnProperty("bootCount"))
            object.bootCount = message.bootCount;
        if (message.mainVersion != null && message.hasOwnProperty("mainVersion"))
            object.mainVersion = $root.ProtobufEventPayloadOta.Version.toObject(message.mainVersion, options);
        if (message.bootloaderVersion != null && message.hasOwnProperty("bootloaderVersion"))
            object.bootloaderVersion = $root.ProtobufEventPayloadOta.Version.toObject(message.bootloaderVersion, options);
        if (message.coprocessorVersion != null && message.hasOwnProperty("coprocessorVersion"))
            object.coprocessorVersion = $root.ProtobufEventPayloadOta.Version.toObject(message.coprocessorVersion, options);
        if (message.downloadedVersion != null && message.hasOwnProperty("downloadedVersion"))
            object.downloadedVersion = $root.ProtobufEventPayloadOta.Version.toObject(message.downloadedVersion, options);
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadOta to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadOta
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadOta.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadOta
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadOta
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadOta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadOta";
    };

    /**
     * State enum.
     * @name ProtobufEventPayloadOta.State
     * @enum {number}
     * @property {number} kBootingImages=0 kBootingImages value
     * @property {number} kAwaitingNetwork=1 kAwaitingNetwork value
     * @property {number} kAwaitingJob=2 kAwaitingJob value
     * @property {number} kDownloadingImage=3 kDownloadingImage value
     * @property {number} kIdle=4 kIdle value
     * @property {number} kError=5 kError value
     */
    ProtobufEventPayloadOta.State = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kBootingImages"] = 0;
        values[valuesById[1] = "kAwaitingNetwork"] = 1;
        values[valuesById[2] = "kAwaitingJob"] = 2;
        values[valuesById[3] = "kDownloadingImage"] = 3;
        values[valuesById[4] = "kIdle"] = 4;
        values[valuesById[5] = "kError"] = 5;
        return values;
    })();

    ProtobufEventPayloadOta.Version = (function() {

        /**
         * Properties of a Version.
         * @memberof ProtobufEventPayloadOta
         * @interface IVersion
         * @property {ProtobufEventPayloadOta.Version.Type|null} [type] Version type
         * @property {boolean|null} [executableValid] Version executableValid
         * @property {number|null} [executableVersion] Version executableVersion
         * @property {boolean|null} [updateValid] Version updateValid
         * @property {number|null} [updateVersion] Version updateVersion
         * @property {number|null} [totalBlocks] Version totalBlocks
         * @property {number|null} [downloadedBlocks] Version downloadedBlocks
         */

        /**
         * Constructs a new Version.
         * @memberof ProtobufEventPayloadOta
         * @classdesc Represents a Version.
         * @implements IVersion
         * @constructor
         * @param {ProtobufEventPayloadOta.IVersion=} [properties] Properties to set
         */
        function Version(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Version type.
         * @member {ProtobufEventPayloadOta.Version.Type} type
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         */
        Version.prototype.type = 0;

        /**
         * Version executableValid.
         * @member {boolean} executableValid
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         */
        Version.prototype.executableValid = false;

        /**
         * Version executableVersion.
         * @member {number} executableVersion
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         */
        Version.prototype.executableVersion = 0;

        /**
         * Version updateValid.
         * @member {boolean} updateValid
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         */
        Version.prototype.updateValid = false;

        /**
         * Version updateVersion.
         * @member {number} updateVersion
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         */
        Version.prototype.updateVersion = 0;

        /**
         * Version totalBlocks.
         * @member {number} totalBlocks
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         */
        Version.prototype.totalBlocks = 0;

        /**
         * Version downloadedBlocks.
         * @member {number} downloadedBlocks
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         */
        Version.prototype.downloadedBlocks = 0;

        /**
         * Creates a new Version instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {ProtobufEventPayloadOta.IVersion=} [properties] Properties to set
         * @returns {ProtobufEventPayloadOta.Version} Version instance
         */
        Version.create = function create(properties) {
            return new Version(properties);
        };

        /**
         * Encodes the specified Version message. Does not implicitly {@link ProtobufEventPayloadOta.Version.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {ProtobufEventPayloadOta.IVersion} message Version message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Version.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.executableValid != null && Object.hasOwnProperty.call(message, "executableValid"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.executableValid);
            if (message.executableVersion != null && Object.hasOwnProperty.call(message, "executableVersion"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.executableVersion);
            if (message.updateValid != null && Object.hasOwnProperty.call(message, "updateValid"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.updateValid);
            if (message.updateVersion != null && Object.hasOwnProperty.call(message, "updateVersion"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.updateVersion);
            if (message.totalBlocks != null && Object.hasOwnProperty.call(message, "totalBlocks"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.totalBlocks);
            if (message.downloadedBlocks != null && Object.hasOwnProperty.call(message, "downloadedBlocks"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.downloadedBlocks);
            return writer;
        };

        /**
         * Encodes the specified Version message, length delimited. Does not implicitly {@link ProtobufEventPayloadOta.Version.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {ProtobufEventPayloadOta.IVersion} message Version message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Version.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Version message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadOta.Version} Version
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Version.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadOta.Version();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.executableValid = reader.bool();
                        break;
                    }
                case 3: {
                        message.executableVersion = reader.uint32();
                        break;
                    }
                case 4: {
                        message.updateValid = reader.bool();
                        break;
                    }
                case 5: {
                        message.updateVersion = reader.uint32();
                        break;
                    }
                case 6: {
                        message.totalBlocks = reader.uint32();
                        break;
                    }
                case 7: {
                        message.downloadedBlocks = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Version message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadOta.Version} Version
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Version.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Version message.
         * @function verify
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Version.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.executableValid != null && message.hasOwnProperty("executableValid"))
                if (typeof message.executableValid !== "boolean")
                    return "executableValid: boolean expected";
            if (message.executableVersion != null && message.hasOwnProperty("executableVersion"))
                if (!$util.isInteger(message.executableVersion))
                    return "executableVersion: integer expected";
            if (message.updateValid != null && message.hasOwnProperty("updateValid"))
                if (typeof message.updateValid !== "boolean")
                    return "updateValid: boolean expected";
            if (message.updateVersion != null && message.hasOwnProperty("updateVersion"))
                if (!$util.isInteger(message.updateVersion))
                    return "updateVersion: integer expected";
            if (message.totalBlocks != null && message.hasOwnProperty("totalBlocks"))
                if (!$util.isInteger(message.totalBlocks))
                    return "totalBlocks: integer expected";
            if (message.downloadedBlocks != null && message.hasOwnProperty("downloadedBlocks"))
                if (!$util.isInteger(message.downloadedBlocks))
                    return "downloadedBlocks: integer expected";
            return null;
        };

        /**
         * Creates a Version message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadOta.Version} Version
         */
        Version.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadOta.Version)
                return object;
            var message = new $root.ProtobufEventPayloadOta.Version();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "kUnknown":
            case 0:
                message.type = 0;
                break;
            case "kMain":
            case 1:
                message.type = 1;
                break;
            case "kBootloader":
            case 2:
                message.type = 2;
                break;
            case "kCoprocessor":
            case 3:
                message.type = 3;
                break;
            }
            if (object.executableValid != null)
                message.executableValid = Boolean(object.executableValid);
            if (object.executableVersion != null)
                message.executableVersion = object.executableVersion >>> 0;
            if (object.updateValid != null)
                message.updateValid = Boolean(object.updateValid);
            if (object.updateVersion != null)
                message.updateVersion = object.updateVersion >>> 0;
            if (object.totalBlocks != null)
                message.totalBlocks = object.totalBlocks >>> 0;
            if (object.downloadedBlocks != null)
                message.downloadedBlocks = object.downloadedBlocks >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Version message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {ProtobufEventPayloadOta.Version} message Version
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Version.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "kUnknown" : 0;
                object.executableValid = false;
                object.executableVersion = 0;
                object.updateValid = false;
                object.updateVersion = 0;
                object.totalBlocks = 0;
                object.downloadedBlocks = 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.ProtobufEventPayloadOta.Version.Type[message.type] === undefined ? message.type : $root.ProtobufEventPayloadOta.Version.Type[message.type] : message.type;
            if (message.executableValid != null && message.hasOwnProperty("executableValid"))
                object.executableValid = message.executableValid;
            if (message.executableVersion != null && message.hasOwnProperty("executableVersion"))
                object.executableVersion = message.executableVersion;
            if (message.updateValid != null && message.hasOwnProperty("updateValid"))
                object.updateValid = message.updateValid;
            if (message.updateVersion != null && message.hasOwnProperty("updateVersion"))
                object.updateVersion = message.updateVersion;
            if (message.totalBlocks != null && message.hasOwnProperty("totalBlocks"))
                object.totalBlocks = message.totalBlocks;
            if (message.downloadedBlocks != null && message.hasOwnProperty("downloadedBlocks"))
                object.downloadedBlocks = message.downloadedBlocks;
            return object;
        };

        /**
         * Converts this Version to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadOta.Version
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Version.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Version
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadOta.Version
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Version.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadOta.Version";
        };

        /**
         * Type enum.
         * @name ProtobufEventPayloadOta.Version.Type
         * @enum {number}
         * @property {number} kUnknown=0 kUnknown value
         * @property {number} kMain=1 kMain value
         * @property {number} kBootloader=2 kBootloader value
         * @property {number} kCoprocessor=3 kCoprocessor value
         */
        Version.Type = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "kUnknown"] = 0;
            values[valuesById[1] = "kMain"] = 1;
            values[valuesById[2] = "kBootloader"] = 2;
            values[valuesById[3] = "kCoprocessor"] = 3;
            return values;
        })();

        return Version;
    })();

    return ProtobufEventPayloadOta;
})();

$root.ProtobufEventPayloadButtonPress = (function() {

    /**
     * Properties of a ProtobufEventPayloadButtonPress.
     * @exports IProtobufEventPayloadButtonPress
     * @interface IProtobufEventPayloadButtonPress
     * @property {ProtobufEventPayloadButtonPress.Identifier|null} [identifier] ProtobufEventPayloadButtonPress identifier
     * @property {ProtobufEventPayloadButtonPress.Duration|null} [duration] ProtobufEventPayloadButtonPress duration
     * @property {number|null} [count] ProtobufEventPayloadButtonPress count
     */

    /**
     * Constructs a new ProtobufEventPayloadButtonPress.
     * @exports ProtobufEventPayloadButtonPress
     * @classdesc Represents a ProtobufEventPayloadButtonPress.
     * @implements IProtobufEventPayloadButtonPress
     * @constructor
     * @param {IProtobufEventPayloadButtonPress=} [properties] Properties to set
     */
    function ProtobufEventPayloadButtonPress(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadButtonPress identifier.
     * @member {ProtobufEventPayloadButtonPress.Identifier} identifier
     * @memberof ProtobufEventPayloadButtonPress
     * @instance
     */
    ProtobufEventPayloadButtonPress.prototype.identifier = 0;

    /**
     * ProtobufEventPayloadButtonPress duration.
     * @member {ProtobufEventPayloadButtonPress.Duration} duration
     * @memberof ProtobufEventPayloadButtonPress
     * @instance
     */
    ProtobufEventPayloadButtonPress.prototype.duration = 0;

    /**
     * ProtobufEventPayloadButtonPress count.
     * @member {number} count
     * @memberof ProtobufEventPayloadButtonPress
     * @instance
     */
    ProtobufEventPayloadButtonPress.prototype.count = 0;

    /**
     * Creates a new ProtobufEventPayloadButtonPress instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {IProtobufEventPayloadButtonPress=} [properties] Properties to set
     * @returns {ProtobufEventPayloadButtonPress} ProtobufEventPayloadButtonPress instance
     */
    ProtobufEventPayloadButtonPress.create = function create(properties) {
        return new ProtobufEventPayloadButtonPress(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadButtonPress message. Does not implicitly {@link ProtobufEventPayloadButtonPress.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {IProtobufEventPayloadButtonPress} message ProtobufEventPayloadButtonPress message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadButtonPress.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.identifier);
        if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.duration);
        if (message.count != null && Object.hasOwnProperty.call(message, "count"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.count);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadButtonPress message, length delimited. Does not implicitly {@link ProtobufEventPayloadButtonPress.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {IProtobufEventPayloadButtonPress} message ProtobufEventPayloadButtonPress message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadButtonPress.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadButtonPress message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadButtonPress} ProtobufEventPayloadButtonPress
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadButtonPress.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadButtonPress();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.identifier = reader.int32();
                    break;
                }
            case 2: {
                    message.duration = reader.int32();
                    break;
                }
            case 3: {
                    message.count = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadButtonPress message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadButtonPress} ProtobufEventPayloadButtonPress
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadButtonPress.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadButtonPress message.
     * @function verify
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadButtonPress.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.identifier != null && message.hasOwnProperty("identifier"))
            switch (message.identifier) {
            default:
                return "identifier: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.duration != null && message.hasOwnProperty("duration"))
            switch (message.duration) {
            default:
                return "duration: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.count != null && message.hasOwnProperty("count"))
            if (!$util.isInteger(message.count))
                return "count: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadButtonPress message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadButtonPress} ProtobufEventPayloadButtonPress
     */
    ProtobufEventPayloadButtonPress.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadButtonPress)
            return object;
        var message = new $root.ProtobufEventPayloadButtonPress();
        switch (object.identifier) {
        default:
            if (typeof object.identifier === "number") {
                message.identifier = object.identifier;
                break;
            }
            break;
        case "kNoIdentifier":
        case 0:
            message.identifier = 0;
            break;
        case "kSos":
        case 1:
            message.identifier = 1;
            break;
        case "kWatch":
        case 2:
            message.identifier = 2;
            break;
        case "kMark":
        case 3:
            message.identifier = 3;
            break;
        }
        switch (object.duration) {
        default:
            if (typeof object.duration === "number") {
                message.duration = object.duration;
                break;
            }
            break;
        case "kNoDuration":
        case 0:
            message.duration = 0;
            break;
        case "kShort":
        case 1:
            message.duration = 1;
            break;
        case "kLong":
        case 2:
            message.duration = 2;
            break;
        case "kRepeat":
        case 3:
            message.duration = 3;
            break;
        case "kCustom":
        case 4:
            message.duration = 4;
            break;
        }
        if (object.count != null)
            message.count = object.count >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadButtonPress message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {ProtobufEventPayloadButtonPress} message ProtobufEventPayloadButtonPress
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadButtonPress.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.identifier = options.enums === String ? "kNoIdentifier" : 0;
            object.duration = options.enums === String ? "kNoDuration" : 0;
            object.count = 0;
        }
        if (message.identifier != null && message.hasOwnProperty("identifier"))
            object.identifier = options.enums === String ? $root.ProtobufEventPayloadButtonPress.Identifier[message.identifier] === undefined ? message.identifier : $root.ProtobufEventPayloadButtonPress.Identifier[message.identifier] : message.identifier;
        if (message.duration != null && message.hasOwnProperty("duration"))
            object.duration = options.enums === String ? $root.ProtobufEventPayloadButtonPress.Duration[message.duration] === undefined ? message.duration : $root.ProtobufEventPayloadButtonPress.Duration[message.duration] : message.duration;
        if (message.count != null && message.hasOwnProperty("count"))
            object.count = message.count;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadButtonPress to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadButtonPress
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadButtonPress.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadButtonPress
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadButtonPress
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadButtonPress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadButtonPress";
    };

    /**
     * Identifier enum.
     * @name ProtobufEventPayloadButtonPress.Identifier
     * @enum {number}
     * @property {number} kNoIdentifier=0 kNoIdentifier value
     * @property {number} kSos=1 kSos value
     * @property {number} kWatch=2 kWatch value
     * @property {number} kMark=3 kMark value
     */
    ProtobufEventPayloadButtonPress.Identifier = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoIdentifier"] = 0;
        values[valuesById[1] = "kSos"] = 1;
        values[valuesById[2] = "kWatch"] = 2;
        values[valuesById[3] = "kMark"] = 3;
        return values;
    })();

    /**
     * Duration enum.
     * @name ProtobufEventPayloadButtonPress.Duration
     * @enum {number}
     * @property {number} kNoDuration=0 kNoDuration value
     * @property {number} kShort=1 kShort value
     * @property {number} kLong=2 kLong value
     * @property {number} kRepeat=3 kRepeat value
     * @property {number} kCustom=4 kCustom value
     */
    ProtobufEventPayloadButtonPress.Duration = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoDuration"] = 0;
        values[valuesById[1] = "kShort"] = 1;
        values[valuesById[2] = "kLong"] = 2;
        values[valuesById[3] = "kRepeat"] = 3;
        values[valuesById[4] = "kCustom"] = 4;
        return values;
    })();

    return ProtobufEventPayloadButtonPress;
})();

$root.ProtobufEventPayloadPeripheral = (function() {

    /**
     * Properties of a ProtobufEventPayloadPeripheral.
     * @exports IProtobufEventPayloadPeripheral
     * @interface IProtobufEventPayloadPeripheral
     * @property {ProtobufEventPayloadPeripheral.Type|null} [type] ProtobufEventPayloadPeripheral type
     * @property {string|null} [serialNumber] ProtobufEventPayloadPeripheral serialNumber
     * @property {number|null} [id] ProtobufEventPayloadPeripheral id
     * @property {number|null} [pcbVersion] ProtobufEventPayloadPeripheral pcbVersion
     * @property {number|null} [pcaVariant] ProtobufEventPayloadPeripheral pcaVariant
     * @property {number|null} [firmwareVersion] ProtobufEventPayloadPeripheral firmwareVersion
     * @property {ProtobufEventPayloadPeripheral.ResetReason|null} [resetReason] ProtobufEventPayloadPeripheral resetReason
     * @property {number|null} [bootCount] ProtobufEventPayloadPeripheral bootCount
     * @property {number|null} [runtimeS] ProtobufEventPayloadPeripheral runtimeS
     * @property {number|null} [currentVersionBootCount] ProtobufEventPayloadPeripheral currentVersionBootCount
     * @property {number|null} [currentVersionRuntimeS] ProtobufEventPayloadPeripheral currentVersionRuntimeS
     */

    /**
     * Constructs a new ProtobufEventPayloadPeripheral.
     * @exports ProtobufEventPayloadPeripheral
     * @classdesc Represents a ProtobufEventPayloadPeripheral.
     * @implements IProtobufEventPayloadPeripheral
     * @constructor
     * @param {IProtobufEventPayloadPeripheral=} [properties] Properties to set
     */
    function ProtobufEventPayloadPeripheral(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadPeripheral type.
     * @member {ProtobufEventPayloadPeripheral.Type} type
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.type = 0;

    /**
     * ProtobufEventPayloadPeripheral serialNumber.
     * @member {string} serialNumber
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.serialNumber = "";

    /**
     * ProtobufEventPayloadPeripheral id.
     * @member {number} id
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.id = 0;

    /**
     * ProtobufEventPayloadPeripheral pcbVersion.
     * @member {number} pcbVersion
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.pcbVersion = 0;

    /**
     * ProtobufEventPayloadPeripheral pcaVariant.
     * @member {number} pcaVariant
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.pcaVariant = 0;

    /**
     * ProtobufEventPayloadPeripheral firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.firmwareVersion = 0;

    /**
     * ProtobufEventPayloadPeripheral resetReason.
     * @member {ProtobufEventPayloadPeripheral.ResetReason} resetReason
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.resetReason = 0;

    /**
     * ProtobufEventPayloadPeripheral bootCount.
     * @member {number} bootCount
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.bootCount = 0;

    /**
     * ProtobufEventPayloadPeripheral runtimeS.
     * @member {number} runtimeS
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.runtimeS = 0;

    /**
     * ProtobufEventPayloadPeripheral currentVersionBootCount.
     * @member {number} currentVersionBootCount
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.currentVersionBootCount = 0;

    /**
     * ProtobufEventPayloadPeripheral currentVersionRuntimeS.
     * @member {number} currentVersionRuntimeS
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     */
    ProtobufEventPayloadPeripheral.prototype.currentVersionRuntimeS = 0;

    /**
     * Creates a new ProtobufEventPayloadPeripheral instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {IProtobufEventPayloadPeripheral=} [properties] Properties to set
     * @returns {ProtobufEventPayloadPeripheral} ProtobufEventPayloadPeripheral instance
     */
    ProtobufEventPayloadPeripheral.create = function create(properties) {
        return new ProtobufEventPayloadPeripheral(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadPeripheral message. Does not implicitly {@link ProtobufEventPayloadPeripheral.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {IProtobufEventPayloadPeripheral} message ProtobufEventPayloadPeripheral message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadPeripheral.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        if (message.serialNumber != null && Object.hasOwnProperty.call(message, "serialNumber"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.serialNumber);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.id);
        if (message.pcbVersion != null && Object.hasOwnProperty.call(message, "pcbVersion"))
            writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.pcbVersion);
        if (message.pcaVariant != null && Object.hasOwnProperty.call(message, "pcaVariant"))
            writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.pcaVariant);
        if (message.firmwareVersion != null && Object.hasOwnProperty.call(message, "firmwareVersion"))
            writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.firmwareVersion);
        if (message.resetReason != null && Object.hasOwnProperty.call(message, "resetReason"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.resetReason);
        if (message.bootCount != null && Object.hasOwnProperty.call(message, "bootCount"))
            writer.uint32(/* id 21, wireType 0 =*/168).uint32(message.bootCount);
        if (message.runtimeS != null && Object.hasOwnProperty.call(message, "runtimeS"))
            writer.uint32(/* id 22, wireType 0 =*/176).uint32(message.runtimeS);
        if (message.currentVersionBootCount != null && Object.hasOwnProperty.call(message, "currentVersionBootCount"))
            writer.uint32(/* id 23, wireType 0 =*/184).uint32(message.currentVersionBootCount);
        if (message.currentVersionRuntimeS != null && Object.hasOwnProperty.call(message, "currentVersionRuntimeS"))
            writer.uint32(/* id 24, wireType 0 =*/192).uint32(message.currentVersionRuntimeS);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadPeripheral message, length delimited. Does not implicitly {@link ProtobufEventPayloadPeripheral.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {IProtobufEventPayloadPeripheral} message ProtobufEventPayloadPeripheral message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadPeripheral.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadPeripheral message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadPeripheral} ProtobufEventPayloadPeripheral
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadPeripheral.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadPeripheral();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.int32();
                    break;
                }
            case 2: {
                    message.serialNumber = reader.string();
                    break;
                }
            case 3: {
                    message.id = reader.uint32();
                    break;
                }
            case 10: {
                    message.pcbVersion = reader.uint32();
                    break;
                }
            case 11: {
                    message.pcaVariant = reader.uint32();
                    break;
                }
            case 12: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 20: {
                    message.resetReason = reader.int32();
                    break;
                }
            case 21: {
                    message.bootCount = reader.uint32();
                    break;
                }
            case 22: {
                    message.runtimeS = reader.uint32();
                    break;
                }
            case 23: {
                    message.currentVersionBootCount = reader.uint32();
                    break;
                }
            case 24: {
                    message.currentVersionRuntimeS = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadPeripheral message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadPeripheral} ProtobufEventPayloadPeripheral
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadPeripheral.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadPeripheral message.
     * @function verify
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadPeripheral.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
            if (!$util.isString(message.serialNumber))
                return "serialNumber: string expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id))
                return "id: integer expected";
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            if (!$util.isInteger(message.pcbVersion))
                return "pcbVersion: integer expected";
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            if (!$util.isInteger(message.pcaVariant))
                return "pcaVariant: integer expected";
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            if (!$util.isInteger(message.firmwareVersion))
                return "firmwareVersion: integer expected";
        if (message.resetReason != null && message.hasOwnProperty("resetReason"))
            switch (message.resetReason) {
            default:
                return "resetReason: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                break;
            }
        if (message.bootCount != null && message.hasOwnProperty("bootCount"))
            if (!$util.isInteger(message.bootCount))
                return "bootCount: integer expected";
        if (message.runtimeS != null && message.hasOwnProperty("runtimeS"))
            if (!$util.isInteger(message.runtimeS))
                return "runtimeS: integer expected";
        if (message.currentVersionBootCount != null && message.hasOwnProperty("currentVersionBootCount"))
            if (!$util.isInteger(message.currentVersionBootCount))
                return "currentVersionBootCount: integer expected";
        if (message.currentVersionRuntimeS != null && message.hasOwnProperty("currentVersionRuntimeS"))
            if (!$util.isInteger(message.currentVersionRuntimeS))
                return "currentVersionRuntimeS: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadPeripheral message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadPeripheral} ProtobufEventPayloadPeripheral
     */
    ProtobufEventPayloadPeripheral.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadPeripheral)
            return object;
        var message = new $root.ProtobufEventPayloadPeripheral();
        switch (object.type) {
        default:
            if (typeof object.type === "number") {
                message.type = object.type;
                break;
            }
            break;
        case "kNoType":
        case 0:
            message.type = 0;
            break;
        case "kKeypad":
        case 1:
            message.type = 1;
            break;
        case "kGpi":
        case 2:
            message.type = 2;
            break;
        }
        if (object.serialNumber != null)
            message.serialNumber = String(object.serialNumber);
        if (object.id != null)
            message.id = object.id >>> 0;
        if (object.pcbVersion != null)
            message.pcbVersion = object.pcbVersion >>> 0;
        if (object.pcaVariant != null)
            message.pcaVariant = object.pcaVariant >>> 0;
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        switch (object.resetReason) {
        default:
            if (typeof object.resetReason === "number") {
                message.resetReason = object.resetReason;
                break;
            }
            break;
        case "kUnknown":
        case 0:
            message.resetReason = 0;
            break;
        case "kPowerOn":
        case 1:
            message.resetReason = 1;
            break;
        case "kPin":
        case 2:
            message.resetReason = 2;
            break;
        case "kBrownout":
        case 3:
            message.resetReason = 3;
            break;
        case "kSystem":
        case 4:
            message.resetReason = 4;
            break;
        case "kStandby":
        case 5:
            message.resetReason = 5;
            break;
        case "kFault":
        case 6:
            message.resetReason = 6;
            break;
        case "kWatchdog":
        case 7:
            message.resetReason = 7;
            break;
        }
        if (object.bootCount != null)
            message.bootCount = object.bootCount >>> 0;
        if (object.runtimeS != null)
            message.runtimeS = object.runtimeS >>> 0;
        if (object.currentVersionBootCount != null)
            message.currentVersionBootCount = object.currentVersionBootCount >>> 0;
        if (object.currentVersionRuntimeS != null)
            message.currentVersionRuntimeS = object.currentVersionRuntimeS >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadPeripheral message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {ProtobufEventPayloadPeripheral} message ProtobufEventPayloadPeripheral
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadPeripheral.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "kNoType" : 0;
            object.serialNumber = "";
            object.id = 0;
            object.pcbVersion = 0;
            object.pcaVariant = 0;
            object.firmwareVersion = 0;
            object.resetReason = options.enums === String ? "kUnknown" : 0;
            object.bootCount = 0;
            object.runtimeS = 0;
            object.currentVersionBootCount = 0;
            object.currentVersionRuntimeS = 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ProtobufEventPayloadPeripheral.Type[message.type] === undefined ? message.type : $root.ProtobufEventPayloadPeripheral.Type[message.type] : message.type;
        if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
            object.serialNumber = message.serialNumber;
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            object.pcbVersion = message.pcbVersion;
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            object.pcaVariant = message.pcaVariant;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.resetReason != null && message.hasOwnProperty("resetReason"))
            object.resetReason = options.enums === String ? $root.ProtobufEventPayloadPeripheral.ResetReason[message.resetReason] === undefined ? message.resetReason : $root.ProtobufEventPayloadPeripheral.ResetReason[message.resetReason] : message.resetReason;
        if (message.bootCount != null && message.hasOwnProperty("bootCount"))
            object.bootCount = message.bootCount;
        if (message.runtimeS != null && message.hasOwnProperty("runtimeS"))
            object.runtimeS = message.runtimeS;
        if (message.currentVersionBootCount != null && message.hasOwnProperty("currentVersionBootCount"))
            object.currentVersionBootCount = message.currentVersionBootCount;
        if (message.currentVersionRuntimeS != null && message.hasOwnProperty("currentVersionRuntimeS"))
            object.currentVersionRuntimeS = message.currentVersionRuntimeS;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadPeripheral to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadPeripheral
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadPeripheral.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadPeripheral
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadPeripheral
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadPeripheral.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadPeripheral";
    };

    /**
     * Type enum.
     * @name ProtobufEventPayloadPeripheral.Type
     * @enum {number}
     * @property {number} kNoType=0 kNoType value
     * @property {number} kKeypad=1 kKeypad value
     * @property {number} kGpi=2 kGpi value
     */
    ProtobufEventPayloadPeripheral.Type = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoType"] = 0;
        values[valuesById[1] = "kKeypad"] = 1;
        values[valuesById[2] = "kGpi"] = 2;
        return values;
    })();

    /**
     * ResetReason enum.
     * @name ProtobufEventPayloadPeripheral.ResetReason
     * @enum {number}
     * @property {number} kUnknown=0 kUnknown value
     * @property {number} kPowerOn=1 kPowerOn value
     * @property {number} kPin=2 kPin value
     * @property {number} kBrownout=3 kBrownout value
     * @property {number} kSystem=4 kSystem value
     * @property {number} kStandby=5 kStandby value
     * @property {number} kFault=6 kFault value
     * @property {number} kWatchdog=7 kWatchdog value
     */
    ProtobufEventPayloadPeripheral.ResetReason = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kUnknown"] = 0;
        values[valuesById[1] = "kPowerOn"] = 1;
        values[valuesById[2] = "kPin"] = 2;
        values[valuesById[3] = "kBrownout"] = 3;
        values[valuesById[4] = "kSystem"] = 4;
        values[valuesById[5] = "kStandby"] = 5;
        values[valuesById[6] = "kFault"] = 6;
        values[valuesById[7] = "kWatchdog"] = 7;
        return values;
    })();

    return ProtobufEventPayloadPeripheral;
})();

$root.ProtobufEventPayloadImu = (function() {

    /**
     * Properties of a ProtobufEventPayloadImu.
     * @exports IProtobufEventPayloadImu
     * @interface IProtobufEventPayloadImu
     * @property {ProtobufEventPayloadImu.Sensor|null} [sensor] ProtobufEventPayloadImu sensor
     * @property {number|null} [uptime] ProtobufEventPayloadImu uptime
     * @property {number|null} [sequence] ProtobufEventPayloadImu sequence
     * @property {number|null} [timestamp] ProtobufEventPayloadImu timestamp
     * @property {number|null} [sensorRawX] ProtobufEventPayloadImu sensorRawX
     * @property {number|null} [sensorRawY] ProtobufEventPayloadImu sensorRawY
     * @property {number|null} [sensorRawZ] ProtobufEventPayloadImu sensorRawZ
     * @property {number|null} [sensorScaled] ProtobufEventPayloadImu sensorScaled
     * @property {number|null} [north] ProtobufEventPayloadImu north
     * @property {number|null} [east] ProtobufEventPayloadImu east
     * @property {number|null} [down] ProtobufEventPayloadImu down
     * @property {number|null} [magnitudePeak] ProtobufEventPayloadImu magnitudePeak
     * @property {number|null} [magnitudeRms] ProtobufEventPayloadImu magnitudeRms
     * @property {IProtobufEventPayloadNavigation|null} [navigationPvt] ProtobufEventPayloadImu navigationPvt
     * @property {number|null} [pcbVersion] ProtobufEventPayloadImu pcbVersion
     * @property {number|null} [pcaVariant] ProtobufEventPayloadImu pcaVariant
     * @property {number|null} [firmwareVersion] ProtobufEventPayloadImu firmwareVersion
     * @property {number|null} [mcuDeviceId] ProtobufEventPayloadImu mcuDeviceId
     * @property {number|null} [mcuRevisionId] ProtobufEventPayloadImu mcuRevisionId
     * @property {Array.<number>|null} [mcuUniqueId] ProtobufEventPayloadImu mcuUniqueId
     * @property {number|null} [inertialGyroscopeUpdateRateHz] ProtobufEventPayloadImu inertialGyroscopeUpdateRateHz
     * @property {number|null} [inertialGyroscopeRangeDps] ProtobufEventPayloadImu inertialGyroscopeRangeDps
     * @property {number|null} [inertialGyroscopeScaleDps] ProtobufEventPayloadImu inertialGyroscopeScaleDps
     * @property {number|null} [inertialAccelerometerUpdateRateHz] ProtobufEventPayloadImu inertialAccelerometerUpdateRateHz
     * @property {number|null} [inertialAccelerometerRangeG] ProtobufEventPayloadImu inertialAccelerometerRangeG
     * @property {number|null} [inertialAccelerometerScaleG] ProtobufEventPayloadImu inertialAccelerometerScaleG
     * @property {number|null} [ecompassMagnetometerUpdateRateHz] ProtobufEventPayloadImu ecompassMagnetometerUpdateRateHz
     * @property {number|null} [ecompassMagnetometerRangeGs] ProtobufEventPayloadImu ecompassMagnetometerRangeGs
     * @property {number|null} [ecompassMagnetometerScaleGs] ProtobufEventPayloadImu ecompassMagnetometerScaleGs
     * @property {number|null} [ecompassAccelerometerUpdateRateHz] ProtobufEventPayloadImu ecompassAccelerometerUpdateRateHz
     * @property {number|null} [ecompassAccelerometerRangeG] ProtobufEventPayloadImu ecompassAccelerometerRangeG
     * @property {number|null} [ecompassAccelerometerScaleG] ProtobufEventPayloadImu ecompassAccelerometerScaleG
     * @property {number|null} [barometerUpdateRateHz] ProtobufEventPayloadImu barometerUpdateRateHz
     * @property {boolean|null} [gpio_1] ProtobufEventPayloadImu gpio_1
     * @property {boolean|null} [gpio_2] ProtobufEventPayloadImu gpio_2
     * @property {number|null} [adc_1v8Mv] ProtobufEventPayloadImu adc_1v8Mv
     * @property {number|null} [adc_1v8Sensor_1Mv] ProtobufEventPayloadImu adc_1v8Sensor_1Mv
     * @property {number|null} [adc_1v8Sensor_2Mv] ProtobufEventPayloadImu adc_1v8Sensor_2Mv
     */

    /**
     * Constructs a new ProtobufEventPayloadImu.
     * @exports ProtobufEventPayloadImu
     * @classdesc Represents a ProtobufEventPayloadImu.
     * @implements IProtobufEventPayloadImu
     * @constructor
     * @param {IProtobufEventPayloadImu=} [properties] Properties to set
     */
    function ProtobufEventPayloadImu(properties) {
        this.mcuUniqueId = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadImu sensor.
     * @member {ProtobufEventPayloadImu.Sensor} sensor
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.sensor = 0;

    /**
     * ProtobufEventPayloadImu uptime.
     * @member {number} uptime
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.uptime = 0;

    /**
     * ProtobufEventPayloadImu sequence.
     * @member {number} sequence
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.sequence = 0;

    /**
     * ProtobufEventPayloadImu timestamp.
     * @member {number} timestamp
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.timestamp = 0;

    /**
     * ProtobufEventPayloadImu sensorRawX.
     * @member {number} sensorRawX
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.sensorRawX = 0;

    /**
     * ProtobufEventPayloadImu sensorRawY.
     * @member {number} sensorRawY
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.sensorRawY = 0;

    /**
     * ProtobufEventPayloadImu sensorRawZ.
     * @member {number} sensorRawZ
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.sensorRawZ = 0;

    /**
     * ProtobufEventPayloadImu sensorScaled.
     * @member {number} sensorScaled
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.sensorScaled = 0;

    /**
     * ProtobufEventPayloadImu north.
     * @member {number} north
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.north = 0;

    /**
     * ProtobufEventPayloadImu east.
     * @member {number} east
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.east = 0;

    /**
     * ProtobufEventPayloadImu down.
     * @member {number} down
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.down = 0;

    /**
     * ProtobufEventPayloadImu magnitudePeak.
     * @member {number} magnitudePeak
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.magnitudePeak = 0;

    /**
     * ProtobufEventPayloadImu magnitudeRms.
     * @member {number} magnitudeRms
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.magnitudeRms = 0;

    /**
     * ProtobufEventPayloadImu navigationPvt.
     * @member {IProtobufEventPayloadNavigation|null|undefined} navigationPvt
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.navigationPvt = null;

    /**
     * ProtobufEventPayloadImu pcbVersion.
     * @member {number} pcbVersion
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.pcbVersion = 0;

    /**
     * ProtobufEventPayloadImu pcaVariant.
     * @member {number} pcaVariant
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.pcaVariant = 0;

    /**
     * ProtobufEventPayloadImu firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.firmwareVersion = 0;

    /**
     * ProtobufEventPayloadImu mcuDeviceId.
     * @member {number} mcuDeviceId
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.mcuDeviceId = 0;

    /**
     * ProtobufEventPayloadImu mcuRevisionId.
     * @member {number} mcuRevisionId
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.mcuRevisionId = 0;

    /**
     * ProtobufEventPayloadImu mcuUniqueId.
     * @member {Array.<number>} mcuUniqueId
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.mcuUniqueId = $util.emptyArray;

    /**
     * ProtobufEventPayloadImu inertialGyroscopeUpdateRateHz.
     * @member {number} inertialGyroscopeUpdateRateHz
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.inertialGyroscopeUpdateRateHz = 0;

    /**
     * ProtobufEventPayloadImu inertialGyroscopeRangeDps.
     * @member {number} inertialGyroscopeRangeDps
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.inertialGyroscopeRangeDps = 0;

    /**
     * ProtobufEventPayloadImu inertialGyroscopeScaleDps.
     * @member {number} inertialGyroscopeScaleDps
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.inertialGyroscopeScaleDps = 0;

    /**
     * ProtobufEventPayloadImu inertialAccelerometerUpdateRateHz.
     * @member {number} inertialAccelerometerUpdateRateHz
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.inertialAccelerometerUpdateRateHz = 0;

    /**
     * ProtobufEventPayloadImu inertialAccelerometerRangeG.
     * @member {number} inertialAccelerometerRangeG
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.inertialAccelerometerRangeG = 0;

    /**
     * ProtobufEventPayloadImu inertialAccelerometerScaleG.
     * @member {number} inertialAccelerometerScaleG
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.inertialAccelerometerScaleG = 0;

    /**
     * ProtobufEventPayloadImu ecompassMagnetometerUpdateRateHz.
     * @member {number} ecompassMagnetometerUpdateRateHz
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.ecompassMagnetometerUpdateRateHz = 0;

    /**
     * ProtobufEventPayloadImu ecompassMagnetometerRangeGs.
     * @member {number} ecompassMagnetometerRangeGs
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.ecompassMagnetometerRangeGs = 0;

    /**
     * ProtobufEventPayloadImu ecompassMagnetometerScaleGs.
     * @member {number} ecompassMagnetometerScaleGs
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.ecompassMagnetometerScaleGs = 0;

    /**
     * ProtobufEventPayloadImu ecompassAccelerometerUpdateRateHz.
     * @member {number} ecompassAccelerometerUpdateRateHz
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.ecompassAccelerometerUpdateRateHz = 0;

    /**
     * ProtobufEventPayloadImu ecompassAccelerometerRangeG.
     * @member {number} ecompassAccelerometerRangeG
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.ecompassAccelerometerRangeG = 0;

    /**
     * ProtobufEventPayloadImu ecompassAccelerometerScaleG.
     * @member {number} ecompassAccelerometerScaleG
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.ecompassAccelerometerScaleG = 0;

    /**
     * ProtobufEventPayloadImu barometerUpdateRateHz.
     * @member {number} barometerUpdateRateHz
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.barometerUpdateRateHz = 0;

    /**
     * ProtobufEventPayloadImu gpio_1.
     * @member {boolean} gpio_1
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.gpio_1 = false;

    /**
     * ProtobufEventPayloadImu gpio_2.
     * @member {boolean} gpio_2
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.gpio_2 = false;

    /**
     * ProtobufEventPayloadImu adc_1v8Mv.
     * @member {number} adc_1v8Mv
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.adc_1v8Mv = 0;

    /**
     * ProtobufEventPayloadImu adc_1v8Sensor_1Mv.
     * @member {number} adc_1v8Sensor_1Mv
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.adc_1v8Sensor_1Mv = 0;

    /**
     * ProtobufEventPayloadImu adc_1v8Sensor_2Mv.
     * @member {number} adc_1v8Sensor_2Mv
     * @memberof ProtobufEventPayloadImu
     * @instance
     */
    ProtobufEventPayloadImu.prototype.adc_1v8Sensor_2Mv = 0;

    /**
     * Creates a new ProtobufEventPayloadImu instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {IProtobufEventPayloadImu=} [properties] Properties to set
     * @returns {ProtobufEventPayloadImu} ProtobufEventPayloadImu instance
     */
    ProtobufEventPayloadImu.create = function create(properties) {
        return new ProtobufEventPayloadImu(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadImu message. Does not implicitly {@link ProtobufEventPayloadImu.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {IProtobufEventPayloadImu} message ProtobufEventPayloadImu message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadImu.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sensor != null && Object.hasOwnProperty.call(message, "sensor"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.sensor);
        if (message.uptime != null && Object.hasOwnProperty.call(message, "uptime"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.uptime);
        if (message.sequence != null && Object.hasOwnProperty.call(message, "sequence"))
            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.sequence);
        if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.timestamp);
        if (message.sensorRawX != null && Object.hasOwnProperty.call(message, "sensorRawX"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.sensorRawX);
        if (message.sensorRawY != null && Object.hasOwnProperty.call(message, "sensorRawY"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.sensorRawY);
        if (message.sensorRawZ != null && Object.hasOwnProperty.call(message, "sensorRawZ"))
            writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.sensorRawZ);
        if (message.sensorScaled != null && Object.hasOwnProperty.call(message, "sensorScaled"))
            writer.uint32(/* id 10, wireType 5 =*/85).float(message.sensorScaled);
        if (message.north != null && Object.hasOwnProperty.call(message, "north"))
            writer.uint32(/* id 11, wireType 5 =*/93).float(message.north);
        if (message.east != null && Object.hasOwnProperty.call(message, "east"))
            writer.uint32(/* id 12, wireType 5 =*/101).float(message.east);
        if (message.down != null && Object.hasOwnProperty.call(message, "down"))
            writer.uint32(/* id 13, wireType 5 =*/109).float(message.down);
        if (message.magnitudePeak != null && Object.hasOwnProperty.call(message, "magnitudePeak"))
            writer.uint32(/* id 14, wireType 5 =*/117).float(message.magnitudePeak);
        if (message.navigationPvt != null && Object.hasOwnProperty.call(message, "navigationPvt"))
            $root.ProtobufEventPayloadNavigation.encode(message.navigationPvt, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.magnitudeRms != null && Object.hasOwnProperty.call(message, "magnitudeRms"))
            writer.uint32(/* id 16, wireType 5 =*/133).float(message.magnitudeRms);
        if (message.pcbVersion != null && Object.hasOwnProperty.call(message, "pcbVersion"))
            writer.uint32(/* id 20, wireType 0 =*/160).uint32(message.pcbVersion);
        if (message.pcaVariant != null && Object.hasOwnProperty.call(message, "pcaVariant"))
            writer.uint32(/* id 21, wireType 0 =*/168).uint32(message.pcaVariant);
        if (message.firmwareVersion != null && Object.hasOwnProperty.call(message, "firmwareVersion"))
            writer.uint32(/* id 22, wireType 0 =*/176).uint32(message.firmwareVersion);
        if (message.mcuDeviceId != null && Object.hasOwnProperty.call(message, "mcuDeviceId"))
            writer.uint32(/* id 25, wireType 0 =*/200).uint32(message.mcuDeviceId);
        if (message.mcuRevisionId != null && Object.hasOwnProperty.call(message, "mcuRevisionId"))
            writer.uint32(/* id 26, wireType 0 =*/208).uint32(message.mcuRevisionId);
        if (message.mcuUniqueId != null && message.mcuUniqueId.length)
            for (var i = 0; i < message.mcuUniqueId.length; ++i)
                writer.uint32(/* id 27, wireType 0 =*/216).uint32(message.mcuUniqueId[i]);
        if (message.inertialGyroscopeUpdateRateHz != null && Object.hasOwnProperty.call(message, "inertialGyroscopeUpdateRateHz"))
            writer.uint32(/* id 30, wireType 0 =*/240).uint32(message.inertialGyroscopeUpdateRateHz);
        if (message.inertialGyroscopeRangeDps != null && Object.hasOwnProperty.call(message, "inertialGyroscopeRangeDps"))
            writer.uint32(/* id 31, wireType 0 =*/248).uint32(message.inertialGyroscopeRangeDps);
        if (message.inertialGyroscopeScaleDps != null && Object.hasOwnProperty.call(message, "inertialGyroscopeScaleDps"))
            writer.uint32(/* id 32, wireType 5 =*/261).float(message.inertialGyroscopeScaleDps);
        if (message.inertialAccelerometerUpdateRateHz != null && Object.hasOwnProperty.call(message, "inertialAccelerometerUpdateRateHz"))
            writer.uint32(/* id 40, wireType 0 =*/320).uint32(message.inertialAccelerometerUpdateRateHz);
        if (message.inertialAccelerometerRangeG != null && Object.hasOwnProperty.call(message, "inertialAccelerometerRangeG"))
            writer.uint32(/* id 41, wireType 0 =*/328).uint32(message.inertialAccelerometerRangeG);
        if (message.inertialAccelerometerScaleG != null && Object.hasOwnProperty.call(message, "inertialAccelerometerScaleG"))
            writer.uint32(/* id 42, wireType 5 =*/341).float(message.inertialAccelerometerScaleG);
        if (message.ecompassMagnetometerUpdateRateHz != null && Object.hasOwnProperty.call(message, "ecompassMagnetometerUpdateRateHz"))
            writer.uint32(/* id 50, wireType 0 =*/400).uint32(message.ecompassMagnetometerUpdateRateHz);
        if (message.ecompassMagnetometerRangeGs != null && Object.hasOwnProperty.call(message, "ecompassMagnetometerRangeGs"))
            writer.uint32(/* id 51, wireType 0 =*/408).uint32(message.ecompassMagnetometerRangeGs);
        if (message.ecompassMagnetometerScaleGs != null && Object.hasOwnProperty.call(message, "ecompassMagnetometerScaleGs"))
            writer.uint32(/* id 52, wireType 5 =*/421).float(message.ecompassMagnetometerScaleGs);
        if (message.ecompassAccelerometerUpdateRateHz != null && Object.hasOwnProperty.call(message, "ecompassAccelerometerUpdateRateHz"))
            writer.uint32(/* id 60, wireType 0 =*/480).uint32(message.ecompassAccelerometerUpdateRateHz);
        if (message.ecompassAccelerometerRangeG != null && Object.hasOwnProperty.call(message, "ecompassAccelerometerRangeG"))
            writer.uint32(/* id 61, wireType 0 =*/488).uint32(message.ecompassAccelerometerRangeG);
        if (message.ecompassAccelerometerScaleG != null && Object.hasOwnProperty.call(message, "ecompassAccelerometerScaleG"))
            writer.uint32(/* id 62, wireType 5 =*/501).float(message.ecompassAccelerometerScaleG);
        if (message.barometerUpdateRateHz != null && Object.hasOwnProperty.call(message, "barometerUpdateRateHz"))
            writer.uint32(/* id 70, wireType 0 =*/560).uint32(message.barometerUpdateRateHz);
        if (message.gpio_1 != null && Object.hasOwnProperty.call(message, "gpio_1"))
            writer.uint32(/* id 80, wireType 0 =*/640).bool(message.gpio_1);
        if (message.gpio_2 != null && Object.hasOwnProperty.call(message, "gpio_2"))
            writer.uint32(/* id 81, wireType 0 =*/648).bool(message.gpio_2);
        if (message.adc_1v8Mv != null && Object.hasOwnProperty.call(message, "adc_1v8Mv"))
            writer.uint32(/* id 82, wireType 0 =*/656).uint32(message.adc_1v8Mv);
        if (message.adc_1v8Sensor_1Mv != null && Object.hasOwnProperty.call(message, "adc_1v8Sensor_1Mv"))
            writer.uint32(/* id 83, wireType 0 =*/664).uint32(message.adc_1v8Sensor_1Mv);
        if (message.adc_1v8Sensor_2Mv != null && Object.hasOwnProperty.call(message, "adc_1v8Sensor_2Mv"))
            writer.uint32(/* id 84, wireType 0 =*/672).uint32(message.adc_1v8Sensor_2Mv);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadImu message, length delimited. Does not implicitly {@link ProtobufEventPayloadImu.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {IProtobufEventPayloadImu} message ProtobufEventPayloadImu message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadImu.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadImu message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadImu} ProtobufEventPayloadImu
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadImu.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadImu();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sensor = reader.int32();
                    break;
                }
            case 2: {
                    message.uptime = reader.uint32();
                    break;
                }
            case 3: {
                    message.sequence = reader.uint32();
                    break;
                }
            case 4: {
                    message.timestamp = reader.uint32();
                    break;
                }
            case 5: {
                    message.sensorRawX = reader.uint32();
                    break;
                }
            case 6: {
                    message.sensorRawY = reader.uint32();
                    break;
                }
            case 7: {
                    message.sensorRawZ = reader.uint32();
                    break;
                }
            case 10: {
                    message.sensorScaled = reader.float();
                    break;
                }
            case 11: {
                    message.north = reader.float();
                    break;
                }
            case 12: {
                    message.east = reader.float();
                    break;
                }
            case 13: {
                    message.down = reader.float();
                    break;
                }
            case 14: {
                    message.magnitudePeak = reader.float();
                    break;
                }
            case 16: {
                    message.magnitudeRms = reader.float();
                    break;
                }
            case 15: {
                    message.navigationPvt = $root.ProtobufEventPayloadNavigation.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.pcbVersion = reader.uint32();
                    break;
                }
            case 21: {
                    message.pcaVariant = reader.uint32();
                    break;
                }
            case 22: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 25: {
                    message.mcuDeviceId = reader.uint32();
                    break;
                }
            case 26: {
                    message.mcuRevisionId = reader.uint32();
                    break;
                }
            case 27: {
                    if (!(message.mcuUniqueId && message.mcuUniqueId.length))
                        message.mcuUniqueId = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.mcuUniqueId.push(reader.uint32());
                    } else
                        message.mcuUniqueId.push(reader.uint32());
                    break;
                }
            case 30: {
                    message.inertialGyroscopeUpdateRateHz = reader.uint32();
                    break;
                }
            case 31: {
                    message.inertialGyroscopeRangeDps = reader.uint32();
                    break;
                }
            case 32: {
                    message.inertialGyroscopeScaleDps = reader.float();
                    break;
                }
            case 40: {
                    message.inertialAccelerometerUpdateRateHz = reader.uint32();
                    break;
                }
            case 41: {
                    message.inertialAccelerometerRangeG = reader.uint32();
                    break;
                }
            case 42: {
                    message.inertialAccelerometerScaleG = reader.float();
                    break;
                }
            case 50: {
                    message.ecompassMagnetometerUpdateRateHz = reader.uint32();
                    break;
                }
            case 51: {
                    message.ecompassMagnetometerRangeGs = reader.uint32();
                    break;
                }
            case 52: {
                    message.ecompassMagnetometerScaleGs = reader.float();
                    break;
                }
            case 60: {
                    message.ecompassAccelerometerUpdateRateHz = reader.uint32();
                    break;
                }
            case 61: {
                    message.ecompassAccelerometerRangeG = reader.uint32();
                    break;
                }
            case 62: {
                    message.ecompassAccelerometerScaleG = reader.float();
                    break;
                }
            case 70: {
                    message.barometerUpdateRateHz = reader.uint32();
                    break;
                }
            case 80: {
                    message.gpio_1 = reader.bool();
                    break;
                }
            case 81: {
                    message.gpio_2 = reader.bool();
                    break;
                }
            case 82: {
                    message.adc_1v8Mv = reader.uint32();
                    break;
                }
            case 83: {
                    message.adc_1v8Sensor_1Mv = reader.uint32();
                    break;
                }
            case 84: {
                    message.adc_1v8Sensor_2Mv = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadImu message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadImu} ProtobufEventPayloadImu
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadImu.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadImu message.
     * @function verify
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadImu.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sensor != null && message.hasOwnProperty("sensor"))
            switch (message.sensor) {
            default:
                return "sensor: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.uptime != null && message.hasOwnProperty("uptime"))
            if (!$util.isInteger(message.uptime))
                return "uptime: integer expected";
        if (message.sequence != null && message.hasOwnProperty("sequence"))
            if (!$util.isInteger(message.sequence))
                return "sequence: integer expected";
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (!$util.isInteger(message.timestamp))
                return "timestamp: integer expected";
        if (message.sensorRawX != null && message.hasOwnProperty("sensorRawX"))
            if (!$util.isInteger(message.sensorRawX))
                return "sensorRawX: integer expected";
        if (message.sensorRawY != null && message.hasOwnProperty("sensorRawY"))
            if (!$util.isInteger(message.sensorRawY))
                return "sensorRawY: integer expected";
        if (message.sensorRawZ != null && message.hasOwnProperty("sensorRawZ"))
            if (!$util.isInteger(message.sensorRawZ))
                return "sensorRawZ: integer expected";
        if (message.sensorScaled != null && message.hasOwnProperty("sensorScaled"))
            if (typeof message.sensorScaled !== "number")
                return "sensorScaled: number expected";
        if (message.north != null && message.hasOwnProperty("north"))
            if (typeof message.north !== "number")
                return "north: number expected";
        if (message.east != null && message.hasOwnProperty("east"))
            if (typeof message.east !== "number")
                return "east: number expected";
        if (message.down != null && message.hasOwnProperty("down"))
            if (typeof message.down !== "number")
                return "down: number expected";
        if (message.magnitudePeak != null && message.hasOwnProperty("magnitudePeak"))
            if (typeof message.magnitudePeak !== "number")
                return "magnitudePeak: number expected";
        if (message.magnitudeRms != null && message.hasOwnProperty("magnitudeRms"))
            if (typeof message.magnitudeRms !== "number")
                return "magnitudeRms: number expected";
        if (message.navigationPvt != null && message.hasOwnProperty("navigationPvt")) {
            var error = $root.ProtobufEventPayloadNavigation.verify(message.navigationPvt);
            if (error)
                return "navigationPvt." + error;
        }
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            if (!$util.isInteger(message.pcbVersion))
                return "pcbVersion: integer expected";
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            if (!$util.isInteger(message.pcaVariant))
                return "pcaVariant: integer expected";
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            if (!$util.isInteger(message.firmwareVersion))
                return "firmwareVersion: integer expected";
        if (message.mcuDeviceId != null && message.hasOwnProperty("mcuDeviceId"))
            if (!$util.isInteger(message.mcuDeviceId))
                return "mcuDeviceId: integer expected";
        if (message.mcuRevisionId != null && message.hasOwnProperty("mcuRevisionId"))
            if (!$util.isInteger(message.mcuRevisionId))
                return "mcuRevisionId: integer expected";
        if (message.mcuUniqueId != null && message.hasOwnProperty("mcuUniqueId")) {
            if (!Array.isArray(message.mcuUniqueId))
                return "mcuUniqueId: array expected";
            for (var i = 0; i < message.mcuUniqueId.length; ++i)
                if (!$util.isInteger(message.mcuUniqueId[i]))
                    return "mcuUniqueId: integer[] expected";
        }
        if (message.inertialGyroscopeUpdateRateHz != null && message.hasOwnProperty("inertialGyroscopeUpdateRateHz"))
            if (!$util.isInteger(message.inertialGyroscopeUpdateRateHz))
                return "inertialGyroscopeUpdateRateHz: integer expected";
        if (message.inertialGyroscopeRangeDps != null && message.hasOwnProperty("inertialGyroscopeRangeDps"))
            if (!$util.isInteger(message.inertialGyroscopeRangeDps))
                return "inertialGyroscopeRangeDps: integer expected";
        if (message.inertialGyroscopeScaleDps != null && message.hasOwnProperty("inertialGyroscopeScaleDps"))
            if (typeof message.inertialGyroscopeScaleDps !== "number")
                return "inertialGyroscopeScaleDps: number expected";
        if (message.inertialAccelerometerUpdateRateHz != null && message.hasOwnProperty("inertialAccelerometerUpdateRateHz"))
            if (!$util.isInteger(message.inertialAccelerometerUpdateRateHz))
                return "inertialAccelerometerUpdateRateHz: integer expected";
        if (message.inertialAccelerometerRangeG != null && message.hasOwnProperty("inertialAccelerometerRangeG"))
            if (!$util.isInteger(message.inertialAccelerometerRangeG))
                return "inertialAccelerometerRangeG: integer expected";
        if (message.inertialAccelerometerScaleG != null && message.hasOwnProperty("inertialAccelerometerScaleG"))
            if (typeof message.inertialAccelerometerScaleG !== "number")
                return "inertialAccelerometerScaleG: number expected";
        if (message.ecompassMagnetometerUpdateRateHz != null && message.hasOwnProperty("ecompassMagnetometerUpdateRateHz"))
            if (!$util.isInteger(message.ecompassMagnetometerUpdateRateHz))
                return "ecompassMagnetometerUpdateRateHz: integer expected";
        if (message.ecompassMagnetometerRangeGs != null && message.hasOwnProperty("ecompassMagnetometerRangeGs"))
            if (!$util.isInteger(message.ecompassMagnetometerRangeGs))
                return "ecompassMagnetometerRangeGs: integer expected";
        if (message.ecompassMagnetometerScaleGs != null && message.hasOwnProperty("ecompassMagnetometerScaleGs"))
            if (typeof message.ecompassMagnetometerScaleGs !== "number")
                return "ecompassMagnetometerScaleGs: number expected";
        if (message.ecompassAccelerometerUpdateRateHz != null && message.hasOwnProperty("ecompassAccelerometerUpdateRateHz"))
            if (!$util.isInteger(message.ecompassAccelerometerUpdateRateHz))
                return "ecompassAccelerometerUpdateRateHz: integer expected";
        if (message.ecompassAccelerometerRangeG != null && message.hasOwnProperty("ecompassAccelerometerRangeG"))
            if (!$util.isInteger(message.ecompassAccelerometerRangeG))
                return "ecompassAccelerometerRangeG: integer expected";
        if (message.ecompassAccelerometerScaleG != null && message.hasOwnProperty("ecompassAccelerometerScaleG"))
            if (typeof message.ecompassAccelerometerScaleG !== "number")
                return "ecompassAccelerometerScaleG: number expected";
        if (message.barometerUpdateRateHz != null && message.hasOwnProperty("barometerUpdateRateHz"))
            if (!$util.isInteger(message.barometerUpdateRateHz))
                return "barometerUpdateRateHz: integer expected";
        if (message.gpio_1 != null && message.hasOwnProperty("gpio_1"))
            if (typeof message.gpio_1 !== "boolean")
                return "gpio_1: boolean expected";
        if (message.gpio_2 != null && message.hasOwnProperty("gpio_2"))
            if (typeof message.gpio_2 !== "boolean")
                return "gpio_2: boolean expected";
        if (message.adc_1v8Mv != null && message.hasOwnProperty("adc_1v8Mv"))
            if (!$util.isInteger(message.adc_1v8Mv))
                return "adc_1v8Mv: integer expected";
        if (message.adc_1v8Sensor_1Mv != null && message.hasOwnProperty("adc_1v8Sensor_1Mv"))
            if (!$util.isInteger(message.adc_1v8Sensor_1Mv))
                return "adc_1v8Sensor_1Mv: integer expected";
        if (message.adc_1v8Sensor_2Mv != null && message.hasOwnProperty("adc_1v8Sensor_2Mv"))
            if (!$util.isInteger(message.adc_1v8Sensor_2Mv))
                return "adc_1v8Sensor_2Mv: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadImu message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadImu} ProtobufEventPayloadImu
     */
    ProtobufEventPayloadImu.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadImu)
            return object;
        var message = new $root.ProtobufEventPayloadImu();
        switch (object.sensor) {
        default:
            if (typeof object.sensor === "number") {
                message.sensor = object.sensor;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.sensor = 0;
            break;
        case "kInertial":
        case 1:
            message.sensor = 1;
            break;
        case "kEcompass":
        case 2:
            message.sensor = 2;
            break;
        case "kBarometer":
        case 3:
            message.sensor = 3;
            break;
        case "kGnss":
        case 4:
            message.sensor = 4;
            break;
        case "kMicrophone":
        case 5:
            message.sensor = 5;
            break;
        }
        if (object.uptime != null)
            message.uptime = object.uptime >>> 0;
        if (object.sequence != null)
            message.sequence = object.sequence >>> 0;
        if (object.timestamp != null)
            message.timestamp = object.timestamp >>> 0;
        if (object.sensorRawX != null)
            message.sensorRawX = object.sensorRawX >>> 0;
        if (object.sensorRawY != null)
            message.sensorRawY = object.sensorRawY >>> 0;
        if (object.sensorRawZ != null)
            message.sensorRawZ = object.sensorRawZ >>> 0;
        if (object.sensorScaled != null)
            message.sensorScaled = Number(object.sensorScaled);
        if (object.north != null)
            message.north = Number(object.north);
        if (object.east != null)
            message.east = Number(object.east);
        if (object.down != null)
            message.down = Number(object.down);
        if (object.magnitudePeak != null)
            message.magnitudePeak = Number(object.magnitudePeak);
        if (object.magnitudeRms != null)
            message.magnitudeRms = Number(object.magnitudeRms);
        if (object.navigationPvt != null) {
            if (typeof object.navigationPvt !== "object")
                throw TypeError(".ProtobufEventPayloadImu.navigationPvt: object expected");
            message.navigationPvt = $root.ProtobufEventPayloadNavigation.fromObject(object.navigationPvt);
        }
        if (object.pcbVersion != null)
            message.pcbVersion = object.pcbVersion >>> 0;
        if (object.pcaVariant != null)
            message.pcaVariant = object.pcaVariant >>> 0;
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        if (object.mcuDeviceId != null)
            message.mcuDeviceId = object.mcuDeviceId >>> 0;
        if (object.mcuRevisionId != null)
            message.mcuRevisionId = object.mcuRevisionId >>> 0;
        if (object.mcuUniqueId) {
            if (!Array.isArray(object.mcuUniqueId))
                throw TypeError(".ProtobufEventPayloadImu.mcuUniqueId: array expected");
            message.mcuUniqueId = [];
            for (var i = 0; i < object.mcuUniqueId.length; ++i)
                message.mcuUniqueId[i] = object.mcuUniqueId[i] >>> 0;
        }
        if (object.inertialGyroscopeUpdateRateHz != null)
            message.inertialGyroscopeUpdateRateHz = object.inertialGyroscopeUpdateRateHz >>> 0;
        if (object.inertialGyroscopeRangeDps != null)
            message.inertialGyroscopeRangeDps = object.inertialGyroscopeRangeDps >>> 0;
        if (object.inertialGyroscopeScaleDps != null)
            message.inertialGyroscopeScaleDps = Number(object.inertialGyroscopeScaleDps);
        if (object.inertialAccelerometerUpdateRateHz != null)
            message.inertialAccelerometerUpdateRateHz = object.inertialAccelerometerUpdateRateHz >>> 0;
        if (object.inertialAccelerometerRangeG != null)
            message.inertialAccelerometerRangeG = object.inertialAccelerometerRangeG >>> 0;
        if (object.inertialAccelerometerScaleG != null)
            message.inertialAccelerometerScaleG = Number(object.inertialAccelerometerScaleG);
        if (object.ecompassMagnetometerUpdateRateHz != null)
            message.ecompassMagnetometerUpdateRateHz = object.ecompassMagnetometerUpdateRateHz >>> 0;
        if (object.ecompassMagnetometerRangeGs != null)
            message.ecompassMagnetometerRangeGs = object.ecompassMagnetometerRangeGs >>> 0;
        if (object.ecompassMagnetometerScaleGs != null)
            message.ecompassMagnetometerScaleGs = Number(object.ecompassMagnetometerScaleGs);
        if (object.ecompassAccelerometerUpdateRateHz != null)
            message.ecompassAccelerometerUpdateRateHz = object.ecompassAccelerometerUpdateRateHz >>> 0;
        if (object.ecompassAccelerometerRangeG != null)
            message.ecompassAccelerometerRangeG = object.ecompassAccelerometerRangeG >>> 0;
        if (object.ecompassAccelerometerScaleG != null)
            message.ecompassAccelerometerScaleG = Number(object.ecompassAccelerometerScaleG);
        if (object.barometerUpdateRateHz != null)
            message.barometerUpdateRateHz = object.barometerUpdateRateHz >>> 0;
        if (object.gpio_1 != null)
            message.gpio_1 = Boolean(object.gpio_1);
        if (object.gpio_2 != null)
            message.gpio_2 = Boolean(object.gpio_2);
        if (object.adc_1v8Mv != null)
            message.adc_1v8Mv = object.adc_1v8Mv >>> 0;
        if (object.adc_1v8Sensor_1Mv != null)
            message.adc_1v8Sensor_1Mv = object.adc_1v8Sensor_1Mv >>> 0;
        if (object.adc_1v8Sensor_2Mv != null)
            message.adc_1v8Sensor_2Mv = object.adc_1v8Sensor_2Mv >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadImu message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {ProtobufEventPayloadImu} message ProtobufEventPayloadImu
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadImu.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.mcuUniqueId = [];
        if (options.defaults) {
            object.sensor = options.enums === String ? "kNone" : 0;
            object.uptime = 0;
            object.sequence = 0;
            object.timestamp = 0;
            object.sensorRawX = 0;
            object.sensorRawY = 0;
            object.sensorRawZ = 0;
            object.sensorScaled = 0;
            object.north = 0;
            object.east = 0;
            object.down = 0;
            object.magnitudePeak = 0;
            object.navigationPvt = null;
            object.magnitudeRms = 0;
            object.pcbVersion = 0;
            object.pcaVariant = 0;
            object.firmwareVersion = 0;
            object.mcuDeviceId = 0;
            object.mcuRevisionId = 0;
            object.inertialGyroscopeUpdateRateHz = 0;
            object.inertialGyroscopeRangeDps = 0;
            object.inertialGyroscopeScaleDps = 0;
            object.inertialAccelerometerUpdateRateHz = 0;
            object.inertialAccelerometerRangeG = 0;
            object.inertialAccelerometerScaleG = 0;
            object.ecompassMagnetometerUpdateRateHz = 0;
            object.ecompassMagnetometerRangeGs = 0;
            object.ecompassMagnetometerScaleGs = 0;
            object.ecompassAccelerometerUpdateRateHz = 0;
            object.ecompassAccelerometerRangeG = 0;
            object.ecompassAccelerometerScaleG = 0;
            object.barometerUpdateRateHz = 0;
            object.gpio_1 = false;
            object.gpio_2 = false;
            object.adc_1v8Mv = 0;
            object.adc_1v8Sensor_1Mv = 0;
            object.adc_1v8Sensor_2Mv = 0;
        }
        if (message.sensor != null && message.hasOwnProperty("sensor"))
            object.sensor = options.enums === String ? $root.ProtobufEventPayloadImu.Sensor[message.sensor] === undefined ? message.sensor : $root.ProtobufEventPayloadImu.Sensor[message.sensor] : message.sensor;
        if (message.uptime != null && message.hasOwnProperty("uptime"))
            object.uptime = message.uptime;
        if (message.sequence != null && message.hasOwnProperty("sequence"))
            object.sequence = message.sequence;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            object.timestamp = message.timestamp;
        if (message.sensorRawX != null && message.hasOwnProperty("sensorRawX"))
            object.sensorRawX = message.sensorRawX;
        if (message.sensorRawY != null && message.hasOwnProperty("sensorRawY"))
            object.sensorRawY = message.sensorRawY;
        if (message.sensorRawZ != null && message.hasOwnProperty("sensorRawZ"))
            object.sensorRawZ = message.sensorRawZ;
        if (message.sensorScaled != null && message.hasOwnProperty("sensorScaled"))
            object.sensorScaled = options.json && !isFinite(message.sensorScaled) ? String(message.sensorScaled) : message.sensorScaled;
        if (message.north != null && message.hasOwnProperty("north"))
            object.north = options.json && !isFinite(message.north) ? String(message.north) : message.north;
        if (message.east != null && message.hasOwnProperty("east"))
            object.east = options.json && !isFinite(message.east) ? String(message.east) : message.east;
        if (message.down != null && message.hasOwnProperty("down"))
            object.down = options.json && !isFinite(message.down) ? String(message.down) : message.down;
        if (message.magnitudePeak != null && message.hasOwnProperty("magnitudePeak"))
            object.magnitudePeak = options.json && !isFinite(message.magnitudePeak) ? String(message.magnitudePeak) : message.magnitudePeak;
        if (message.navigationPvt != null && message.hasOwnProperty("navigationPvt"))
            object.navigationPvt = $root.ProtobufEventPayloadNavigation.toObject(message.navigationPvt, options);
        if (message.magnitudeRms != null && message.hasOwnProperty("magnitudeRms"))
            object.magnitudeRms = options.json && !isFinite(message.magnitudeRms) ? String(message.magnitudeRms) : message.magnitudeRms;
        if (message.pcbVersion != null && message.hasOwnProperty("pcbVersion"))
            object.pcbVersion = message.pcbVersion;
        if (message.pcaVariant != null && message.hasOwnProperty("pcaVariant"))
            object.pcaVariant = message.pcaVariant;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.mcuDeviceId != null && message.hasOwnProperty("mcuDeviceId"))
            object.mcuDeviceId = message.mcuDeviceId;
        if (message.mcuRevisionId != null && message.hasOwnProperty("mcuRevisionId"))
            object.mcuRevisionId = message.mcuRevisionId;
        if (message.mcuUniqueId && message.mcuUniqueId.length) {
            object.mcuUniqueId = [];
            for (var j = 0; j < message.mcuUniqueId.length; ++j)
                object.mcuUniqueId[j] = message.mcuUniqueId[j];
        }
        if (message.inertialGyroscopeUpdateRateHz != null && message.hasOwnProperty("inertialGyroscopeUpdateRateHz"))
            object.inertialGyroscopeUpdateRateHz = message.inertialGyroscopeUpdateRateHz;
        if (message.inertialGyroscopeRangeDps != null && message.hasOwnProperty("inertialGyroscopeRangeDps"))
            object.inertialGyroscopeRangeDps = message.inertialGyroscopeRangeDps;
        if (message.inertialGyroscopeScaleDps != null && message.hasOwnProperty("inertialGyroscopeScaleDps"))
            object.inertialGyroscopeScaleDps = options.json && !isFinite(message.inertialGyroscopeScaleDps) ? String(message.inertialGyroscopeScaleDps) : message.inertialGyroscopeScaleDps;
        if (message.inertialAccelerometerUpdateRateHz != null && message.hasOwnProperty("inertialAccelerometerUpdateRateHz"))
            object.inertialAccelerometerUpdateRateHz = message.inertialAccelerometerUpdateRateHz;
        if (message.inertialAccelerometerRangeG != null && message.hasOwnProperty("inertialAccelerometerRangeG"))
            object.inertialAccelerometerRangeG = message.inertialAccelerometerRangeG;
        if (message.inertialAccelerometerScaleG != null && message.hasOwnProperty("inertialAccelerometerScaleG"))
            object.inertialAccelerometerScaleG = options.json && !isFinite(message.inertialAccelerometerScaleG) ? String(message.inertialAccelerometerScaleG) : message.inertialAccelerometerScaleG;
        if (message.ecompassMagnetometerUpdateRateHz != null && message.hasOwnProperty("ecompassMagnetometerUpdateRateHz"))
            object.ecompassMagnetometerUpdateRateHz = message.ecompassMagnetometerUpdateRateHz;
        if (message.ecompassMagnetometerRangeGs != null && message.hasOwnProperty("ecompassMagnetometerRangeGs"))
            object.ecompassMagnetometerRangeGs = message.ecompassMagnetometerRangeGs;
        if (message.ecompassMagnetometerScaleGs != null && message.hasOwnProperty("ecompassMagnetometerScaleGs"))
            object.ecompassMagnetometerScaleGs = options.json && !isFinite(message.ecompassMagnetometerScaleGs) ? String(message.ecompassMagnetometerScaleGs) : message.ecompassMagnetometerScaleGs;
        if (message.ecompassAccelerometerUpdateRateHz != null && message.hasOwnProperty("ecompassAccelerometerUpdateRateHz"))
            object.ecompassAccelerometerUpdateRateHz = message.ecompassAccelerometerUpdateRateHz;
        if (message.ecompassAccelerometerRangeG != null && message.hasOwnProperty("ecompassAccelerometerRangeG"))
            object.ecompassAccelerometerRangeG = message.ecompassAccelerometerRangeG;
        if (message.ecompassAccelerometerScaleG != null && message.hasOwnProperty("ecompassAccelerometerScaleG"))
            object.ecompassAccelerometerScaleG = options.json && !isFinite(message.ecompassAccelerometerScaleG) ? String(message.ecompassAccelerometerScaleG) : message.ecompassAccelerometerScaleG;
        if (message.barometerUpdateRateHz != null && message.hasOwnProperty("barometerUpdateRateHz"))
            object.barometerUpdateRateHz = message.barometerUpdateRateHz;
        if (message.gpio_1 != null && message.hasOwnProperty("gpio_1"))
            object.gpio_1 = message.gpio_1;
        if (message.gpio_2 != null && message.hasOwnProperty("gpio_2"))
            object.gpio_2 = message.gpio_2;
        if (message.adc_1v8Mv != null && message.hasOwnProperty("adc_1v8Mv"))
            object.adc_1v8Mv = message.adc_1v8Mv;
        if (message.adc_1v8Sensor_1Mv != null && message.hasOwnProperty("adc_1v8Sensor_1Mv"))
            object.adc_1v8Sensor_1Mv = message.adc_1v8Sensor_1Mv;
        if (message.adc_1v8Sensor_2Mv != null && message.hasOwnProperty("adc_1v8Sensor_2Mv"))
            object.adc_1v8Sensor_2Mv = message.adc_1v8Sensor_2Mv;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadImu to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadImu
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadImu.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadImu
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadImu
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadImu.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadImu";
    };

    /**
     * Sensor enum.
     * @name ProtobufEventPayloadImu.Sensor
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kInertial=1 kInertial value
     * @property {number} kEcompass=2 kEcompass value
     * @property {number} kBarometer=3 kBarometer value
     * @property {number} kGnss=4 kGnss value
     * @property {number} kMicrophone=5 kMicrophone value
     */
    ProtobufEventPayloadImu.Sensor = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kInertial"] = 1;
        values[valuesById[2] = "kEcompass"] = 2;
        values[valuesById[3] = "kBarometer"] = 3;
        values[valuesById[4] = "kGnss"] = 4;
        values[valuesById[5] = "kMicrophone"] = 5;
        return values;
    })();

    return ProtobufEventPayloadImu;
})();

$root.ProtobufEventPayloadNavigation = (function() {

    /**
     * Properties of a ProtobufEventPayloadNavigation.
     * @exports IProtobufEventPayloadNavigation
     * @interface IProtobufEventPayloadNavigation
     * @property {ProtobufEventPayloadNavigation.FixType|null} [fixType] ProtobufEventPayloadNavigation fixType
     * @property {boolean|null} [invalid] ProtobufEventPayloadNavigation invalid
     * @property {number|null} [latDegrees] ProtobufEventPayloadNavigation latDegrees
     * @property {number|null} [lngDegrees] ProtobufEventPayloadNavigation lngDegrees
     * @property {number|null} [altitudeM] ProtobufEventPayloadNavigation altitudeM
     * @property {number|null} [altitudeMslM] ProtobufEventPayloadNavigation altitudeMslM
     * @property {number|null} [velocityNorthMps] ProtobufEventPayloadNavigation velocityNorthMps
     * @property {number|null} [velocityEastMps] ProtobufEventPayloadNavigation velocityEastMps
     * @property {number|null} [velocityDownMps] ProtobufEventPayloadNavigation velocityDownMps
     * @property {number|null} [groundSpeedMps] ProtobufEventPayloadNavigation groundSpeedMps
     * @property {number|null} [headingDegrees] ProtobufEventPayloadNavigation headingDegrees
     * @property {number|null} [utc] ProtobufEventPayloadNavigation utc
     * @property {number|null} [pdop] ProtobufEventPayloadNavigation pdop
     * @property {number|null} [horizontalAccuracyEstimateM] ProtobufEventPayloadNavigation horizontalAccuracyEstimateM
     * @property {number|null} [verticalAccuracyEstimateM] ProtobufEventPayloadNavigation verticalAccuracyEstimateM
     * @property {number|null} [numSatellites] ProtobufEventPayloadNavigation numSatellites
     * @property {ProtobufEventPayloadNavigation.IDiagnosticRequest|null} [diagnosticRequest] ProtobufEventPayloadNavigation diagnosticRequest
     * @property {ProtobufEventPayloadNavigation.IDiagnosticResponse|null} [diagnosticResponse] ProtobufEventPayloadNavigation diagnosticResponse
     */

    /**
     * Constructs a new ProtobufEventPayloadNavigation.
     * @exports ProtobufEventPayloadNavigation
     * @classdesc Represents a ProtobufEventPayloadNavigation.
     * @implements IProtobufEventPayloadNavigation
     * @constructor
     * @param {IProtobufEventPayloadNavigation=} [properties] Properties to set
     */
    function ProtobufEventPayloadNavigation(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadNavigation fixType.
     * @member {ProtobufEventPayloadNavigation.FixType} fixType
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.fixType = 0;

    /**
     * ProtobufEventPayloadNavigation invalid.
     * @member {boolean} invalid
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.invalid = false;

    /**
     * ProtobufEventPayloadNavigation latDegrees.
     * @member {number} latDegrees
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.latDegrees = 0;

    /**
     * ProtobufEventPayloadNavigation lngDegrees.
     * @member {number} lngDegrees
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.lngDegrees = 0;

    /**
     * ProtobufEventPayloadNavigation altitudeM.
     * @member {number} altitudeM
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.altitudeM = 0;

    /**
     * ProtobufEventPayloadNavigation altitudeMslM.
     * @member {number} altitudeMslM
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.altitudeMslM = 0;

    /**
     * ProtobufEventPayloadNavigation velocityNorthMps.
     * @member {number} velocityNorthMps
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.velocityNorthMps = 0;

    /**
     * ProtobufEventPayloadNavigation velocityEastMps.
     * @member {number} velocityEastMps
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.velocityEastMps = 0;

    /**
     * ProtobufEventPayloadNavigation velocityDownMps.
     * @member {number} velocityDownMps
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.velocityDownMps = 0;

    /**
     * ProtobufEventPayloadNavigation groundSpeedMps.
     * @member {number} groundSpeedMps
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.groundSpeedMps = 0;

    /**
     * ProtobufEventPayloadNavigation headingDegrees.
     * @member {number} headingDegrees
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.headingDegrees = 0;

    /**
     * ProtobufEventPayloadNavigation utc.
     * @member {number} utc
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.utc = 0;

    /**
     * ProtobufEventPayloadNavigation pdop.
     * @member {number} pdop
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.pdop = 0;

    /**
     * ProtobufEventPayloadNavigation horizontalAccuracyEstimateM.
     * @member {number} horizontalAccuracyEstimateM
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.horizontalAccuracyEstimateM = 0;

    /**
     * ProtobufEventPayloadNavigation verticalAccuracyEstimateM.
     * @member {number} verticalAccuracyEstimateM
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.verticalAccuracyEstimateM = 0;

    /**
     * ProtobufEventPayloadNavigation numSatellites.
     * @member {number} numSatellites
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.numSatellites = 0;

    /**
     * ProtobufEventPayloadNavigation diagnosticRequest.
     * @member {ProtobufEventPayloadNavigation.IDiagnosticRequest|null|undefined} diagnosticRequest
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.diagnosticRequest = null;

    /**
     * ProtobufEventPayloadNavigation diagnosticResponse.
     * @member {ProtobufEventPayloadNavigation.IDiagnosticResponse|null|undefined} diagnosticResponse
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     */
    ProtobufEventPayloadNavigation.prototype.diagnosticResponse = null;

    /**
     * Creates a new ProtobufEventPayloadNavigation instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {IProtobufEventPayloadNavigation=} [properties] Properties to set
     * @returns {ProtobufEventPayloadNavigation} ProtobufEventPayloadNavigation instance
     */
    ProtobufEventPayloadNavigation.create = function create(properties) {
        return new ProtobufEventPayloadNavigation(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadNavigation message. Does not implicitly {@link ProtobufEventPayloadNavigation.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {IProtobufEventPayloadNavigation} message ProtobufEventPayloadNavigation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadNavigation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.fixType != null && Object.hasOwnProperty.call(message, "fixType"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.fixType);
        if (message.latDegrees != null && Object.hasOwnProperty.call(message, "latDegrees"))
            writer.uint32(/* id 2, wireType 5 =*/21).float(message.latDegrees);
        if (message.lngDegrees != null && Object.hasOwnProperty.call(message, "lngDegrees"))
            writer.uint32(/* id 3, wireType 5 =*/29).float(message.lngDegrees);
        if (message.altitudeM != null && Object.hasOwnProperty.call(message, "altitudeM"))
            writer.uint32(/* id 4, wireType 5 =*/37).float(message.altitudeM);
        if (message.altitudeMslM != null && Object.hasOwnProperty.call(message, "altitudeMslM"))
            writer.uint32(/* id 5, wireType 5 =*/45).float(message.altitudeMslM);
        if (message.velocityNorthMps != null && Object.hasOwnProperty.call(message, "velocityNorthMps"))
            writer.uint32(/* id 6, wireType 5 =*/53).float(message.velocityNorthMps);
        if (message.velocityEastMps != null && Object.hasOwnProperty.call(message, "velocityEastMps"))
            writer.uint32(/* id 7, wireType 5 =*/61).float(message.velocityEastMps);
        if (message.velocityDownMps != null && Object.hasOwnProperty.call(message, "velocityDownMps"))
            writer.uint32(/* id 8, wireType 5 =*/69).float(message.velocityDownMps);
        if (message.groundSpeedMps != null && Object.hasOwnProperty.call(message, "groundSpeedMps"))
            writer.uint32(/* id 9, wireType 5 =*/77).float(message.groundSpeedMps);
        if (message.headingDegrees != null && Object.hasOwnProperty.call(message, "headingDegrees"))
            writer.uint32(/* id 10, wireType 5 =*/85).float(message.headingDegrees);
        if (message.utc != null && Object.hasOwnProperty.call(message, "utc"))
            writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.utc);
        if (message.pdop != null && Object.hasOwnProperty.call(message, "pdop"))
            writer.uint32(/* id 12, wireType 5 =*/101).float(message.pdop);
        if (message.horizontalAccuracyEstimateM != null && Object.hasOwnProperty.call(message, "horizontalAccuracyEstimateM"))
            writer.uint32(/* id 13, wireType 5 =*/109).float(message.horizontalAccuracyEstimateM);
        if (message.verticalAccuracyEstimateM != null && Object.hasOwnProperty.call(message, "verticalAccuracyEstimateM"))
            writer.uint32(/* id 14, wireType 5 =*/117).float(message.verticalAccuracyEstimateM);
        if (message.numSatellites != null && Object.hasOwnProperty.call(message, "numSatellites"))
            writer.uint32(/* id 15, wireType 0 =*/120).uint32(message.numSatellites);
        if (message.invalid != null && Object.hasOwnProperty.call(message, "invalid"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.invalid);
        if (message.diagnosticRequest != null && Object.hasOwnProperty.call(message, "diagnosticRequest"))
            $root.ProtobufEventPayloadNavigation.DiagnosticRequest.encode(message.diagnosticRequest, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.diagnosticResponse != null && Object.hasOwnProperty.call(message, "diagnosticResponse"))
            $root.ProtobufEventPayloadNavigation.DiagnosticResponse.encode(message.diagnosticResponse, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadNavigation message, length delimited. Does not implicitly {@link ProtobufEventPayloadNavigation.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {IProtobufEventPayloadNavigation} message ProtobufEventPayloadNavigation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadNavigation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadNavigation message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadNavigation} ProtobufEventPayloadNavigation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadNavigation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadNavigation();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.fixType = reader.int32();
                    break;
                }
            case 16: {
                    message.invalid = reader.bool();
                    break;
                }
            case 2: {
                    message.latDegrees = reader.float();
                    break;
                }
            case 3: {
                    message.lngDegrees = reader.float();
                    break;
                }
            case 4: {
                    message.altitudeM = reader.float();
                    break;
                }
            case 5: {
                    message.altitudeMslM = reader.float();
                    break;
                }
            case 6: {
                    message.velocityNorthMps = reader.float();
                    break;
                }
            case 7: {
                    message.velocityEastMps = reader.float();
                    break;
                }
            case 8: {
                    message.velocityDownMps = reader.float();
                    break;
                }
            case 9: {
                    message.groundSpeedMps = reader.float();
                    break;
                }
            case 10: {
                    message.headingDegrees = reader.float();
                    break;
                }
            case 11: {
                    message.utc = reader.uint32();
                    break;
                }
            case 12: {
                    message.pdop = reader.float();
                    break;
                }
            case 13: {
                    message.horizontalAccuracyEstimateM = reader.float();
                    break;
                }
            case 14: {
                    message.verticalAccuracyEstimateM = reader.float();
                    break;
                }
            case 15: {
                    message.numSatellites = reader.uint32();
                    break;
                }
            case 20: {
                    message.diagnosticRequest = $root.ProtobufEventPayloadNavigation.DiagnosticRequest.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.diagnosticResponse = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadNavigation message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadNavigation} ProtobufEventPayloadNavigation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadNavigation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadNavigation message.
     * @function verify
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadNavigation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.fixType != null && message.hasOwnProperty("fixType"))
            switch (message.fixType) {
            default:
                return "fixType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.invalid != null && message.hasOwnProperty("invalid"))
            if (typeof message.invalid !== "boolean")
                return "invalid: boolean expected";
        if (message.latDegrees != null && message.hasOwnProperty("latDegrees"))
            if (typeof message.latDegrees !== "number")
                return "latDegrees: number expected";
        if (message.lngDegrees != null && message.hasOwnProperty("lngDegrees"))
            if (typeof message.lngDegrees !== "number")
                return "lngDegrees: number expected";
        if (message.altitudeM != null && message.hasOwnProperty("altitudeM"))
            if (typeof message.altitudeM !== "number")
                return "altitudeM: number expected";
        if (message.altitudeMslM != null && message.hasOwnProperty("altitudeMslM"))
            if (typeof message.altitudeMslM !== "number")
                return "altitudeMslM: number expected";
        if (message.velocityNorthMps != null && message.hasOwnProperty("velocityNorthMps"))
            if (typeof message.velocityNorthMps !== "number")
                return "velocityNorthMps: number expected";
        if (message.velocityEastMps != null && message.hasOwnProperty("velocityEastMps"))
            if (typeof message.velocityEastMps !== "number")
                return "velocityEastMps: number expected";
        if (message.velocityDownMps != null && message.hasOwnProperty("velocityDownMps"))
            if (typeof message.velocityDownMps !== "number")
                return "velocityDownMps: number expected";
        if (message.groundSpeedMps != null && message.hasOwnProperty("groundSpeedMps"))
            if (typeof message.groundSpeedMps !== "number")
                return "groundSpeedMps: number expected";
        if (message.headingDegrees != null && message.hasOwnProperty("headingDegrees"))
            if (typeof message.headingDegrees !== "number")
                return "headingDegrees: number expected";
        if (message.utc != null && message.hasOwnProperty("utc"))
            if (!$util.isInteger(message.utc))
                return "utc: integer expected";
        if (message.pdop != null && message.hasOwnProperty("pdop"))
            if (typeof message.pdop !== "number")
                return "pdop: number expected";
        if (message.horizontalAccuracyEstimateM != null && message.hasOwnProperty("horizontalAccuracyEstimateM"))
            if (typeof message.horizontalAccuracyEstimateM !== "number")
                return "horizontalAccuracyEstimateM: number expected";
        if (message.verticalAccuracyEstimateM != null && message.hasOwnProperty("verticalAccuracyEstimateM"))
            if (typeof message.verticalAccuracyEstimateM !== "number")
                return "verticalAccuracyEstimateM: number expected";
        if (message.numSatellites != null && message.hasOwnProperty("numSatellites"))
            if (!$util.isInteger(message.numSatellites))
                return "numSatellites: integer expected";
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest")) {
            var error = $root.ProtobufEventPayloadNavigation.DiagnosticRequest.verify(message.diagnosticRequest);
            if (error)
                return "diagnosticRequest." + error;
        }
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse")) {
            var error = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.verify(message.diagnosticResponse);
            if (error)
                return "diagnosticResponse." + error;
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadNavigation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadNavigation} ProtobufEventPayloadNavigation
     */
    ProtobufEventPayloadNavigation.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadNavigation)
            return object;
        var message = new $root.ProtobufEventPayloadNavigation();
        switch (object.fixType) {
        default:
            if (typeof object.fixType === "number") {
                message.fixType = object.fixType;
                break;
            }
            break;
        case "kInvalid":
        case 0:
            message.fixType = 0;
            break;
        case "kTimeOnly":
        case 1:
            message.fixType = 1;
            break;
        case "k2D":
        case 2:
            message.fixType = 2;
            break;
        case "k3D":
        case 3:
            message.fixType = 3;
            break;
        }
        if (object.invalid != null)
            message.invalid = Boolean(object.invalid);
        if (object.latDegrees != null)
            message.latDegrees = Number(object.latDegrees);
        if (object.lngDegrees != null)
            message.lngDegrees = Number(object.lngDegrees);
        if (object.altitudeM != null)
            message.altitudeM = Number(object.altitudeM);
        if (object.altitudeMslM != null)
            message.altitudeMslM = Number(object.altitudeMslM);
        if (object.velocityNorthMps != null)
            message.velocityNorthMps = Number(object.velocityNorthMps);
        if (object.velocityEastMps != null)
            message.velocityEastMps = Number(object.velocityEastMps);
        if (object.velocityDownMps != null)
            message.velocityDownMps = Number(object.velocityDownMps);
        if (object.groundSpeedMps != null)
            message.groundSpeedMps = Number(object.groundSpeedMps);
        if (object.headingDegrees != null)
            message.headingDegrees = Number(object.headingDegrees);
        if (object.utc != null)
            message.utc = object.utc >>> 0;
        if (object.pdop != null)
            message.pdop = Number(object.pdop);
        if (object.horizontalAccuracyEstimateM != null)
            message.horizontalAccuracyEstimateM = Number(object.horizontalAccuracyEstimateM);
        if (object.verticalAccuracyEstimateM != null)
            message.verticalAccuracyEstimateM = Number(object.verticalAccuracyEstimateM);
        if (object.numSatellites != null)
            message.numSatellites = object.numSatellites >>> 0;
        if (object.diagnosticRequest != null) {
            if (typeof object.diagnosticRequest !== "object")
                throw TypeError(".ProtobufEventPayloadNavigation.diagnosticRequest: object expected");
            message.diagnosticRequest = $root.ProtobufEventPayloadNavigation.DiagnosticRequest.fromObject(object.diagnosticRequest);
        }
        if (object.diagnosticResponse != null) {
            if (typeof object.diagnosticResponse !== "object")
                throw TypeError(".ProtobufEventPayloadNavigation.diagnosticResponse: object expected");
            message.diagnosticResponse = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.fromObject(object.diagnosticResponse);
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadNavigation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {ProtobufEventPayloadNavigation} message ProtobufEventPayloadNavigation
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadNavigation.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.fixType = options.enums === String ? "kInvalid" : 0;
            object.latDegrees = 0;
            object.lngDegrees = 0;
            object.altitudeM = 0;
            object.altitudeMslM = 0;
            object.velocityNorthMps = 0;
            object.velocityEastMps = 0;
            object.velocityDownMps = 0;
            object.groundSpeedMps = 0;
            object.headingDegrees = 0;
            object.utc = 0;
            object.pdop = 0;
            object.horizontalAccuracyEstimateM = 0;
            object.verticalAccuracyEstimateM = 0;
            object.numSatellites = 0;
            object.invalid = false;
            object.diagnosticRequest = null;
            object.diagnosticResponse = null;
        }
        if (message.fixType != null && message.hasOwnProperty("fixType"))
            object.fixType = options.enums === String ? $root.ProtobufEventPayloadNavigation.FixType[message.fixType] === undefined ? message.fixType : $root.ProtobufEventPayloadNavigation.FixType[message.fixType] : message.fixType;
        if (message.latDegrees != null && message.hasOwnProperty("latDegrees"))
            object.latDegrees = options.json && !isFinite(message.latDegrees) ? String(message.latDegrees) : message.latDegrees;
        if (message.lngDegrees != null && message.hasOwnProperty("lngDegrees"))
            object.lngDegrees = options.json && !isFinite(message.lngDegrees) ? String(message.lngDegrees) : message.lngDegrees;
        if (message.altitudeM != null && message.hasOwnProperty("altitudeM"))
            object.altitudeM = options.json && !isFinite(message.altitudeM) ? String(message.altitudeM) : message.altitudeM;
        if (message.altitudeMslM != null && message.hasOwnProperty("altitudeMslM"))
            object.altitudeMslM = options.json && !isFinite(message.altitudeMslM) ? String(message.altitudeMslM) : message.altitudeMslM;
        if (message.velocityNorthMps != null && message.hasOwnProperty("velocityNorthMps"))
            object.velocityNorthMps = options.json && !isFinite(message.velocityNorthMps) ? String(message.velocityNorthMps) : message.velocityNorthMps;
        if (message.velocityEastMps != null && message.hasOwnProperty("velocityEastMps"))
            object.velocityEastMps = options.json && !isFinite(message.velocityEastMps) ? String(message.velocityEastMps) : message.velocityEastMps;
        if (message.velocityDownMps != null && message.hasOwnProperty("velocityDownMps"))
            object.velocityDownMps = options.json && !isFinite(message.velocityDownMps) ? String(message.velocityDownMps) : message.velocityDownMps;
        if (message.groundSpeedMps != null && message.hasOwnProperty("groundSpeedMps"))
            object.groundSpeedMps = options.json && !isFinite(message.groundSpeedMps) ? String(message.groundSpeedMps) : message.groundSpeedMps;
        if (message.headingDegrees != null && message.hasOwnProperty("headingDegrees"))
            object.headingDegrees = options.json && !isFinite(message.headingDegrees) ? String(message.headingDegrees) : message.headingDegrees;
        if (message.utc != null && message.hasOwnProperty("utc"))
            object.utc = message.utc;
        if (message.pdop != null && message.hasOwnProperty("pdop"))
            object.pdop = options.json && !isFinite(message.pdop) ? String(message.pdop) : message.pdop;
        if (message.horizontalAccuracyEstimateM != null && message.hasOwnProperty("horizontalAccuracyEstimateM"))
            object.horizontalAccuracyEstimateM = options.json && !isFinite(message.horizontalAccuracyEstimateM) ? String(message.horizontalAccuracyEstimateM) : message.horizontalAccuracyEstimateM;
        if (message.verticalAccuracyEstimateM != null && message.hasOwnProperty("verticalAccuracyEstimateM"))
            object.verticalAccuracyEstimateM = options.json && !isFinite(message.verticalAccuracyEstimateM) ? String(message.verticalAccuracyEstimateM) : message.verticalAccuracyEstimateM;
        if (message.numSatellites != null && message.hasOwnProperty("numSatellites"))
            object.numSatellites = message.numSatellites;
        if (message.invalid != null && message.hasOwnProperty("invalid"))
            object.invalid = message.invalid;
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest"))
            object.diagnosticRequest = $root.ProtobufEventPayloadNavigation.DiagnosticRequest.toObject(message.diagnosticRequest, options);
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse"))
            object.diagnosticResponse = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.toObject(message.diagnosticResponse, options);
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadNavigation to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadNavigation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadNavigation.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadNavigation
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadNavigation
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadNavigation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadNavigation";
    };

    /**
     * FixType enum.
     * @name ProtobufEventPayloadNavigation.FixType
     * @enum {number}
     * @property {number} kInvalid=0 kInvalid value
     * @property {number} kTimeOnly=1 kTimeOnly value
     * @property {number} k2D=2 k2D value
     * @property {number} k3D=3 k3D value
     */
    ProtobufEventPayloadNavigation.FixType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kInvalid"] = 0;
        values[valuesById[1] = "kTimeOnly"] = 1;
        values[valuesById[2] = "k2D"] = 2;
        values[valuesById[3] = "k3D"] = 3;
        return values;
    })();

    ProtobufEventPayloadNavigation.DiagnosticRequest = (function() {

        /**
         * Properties of a DiagnosticRequest.
         * @memberof ProtobufEventPayloadNavigation
         * @interface IDiagnosticRequest
         * @property {number|null} [maxSummaries] DiagnosticRequest maxSummaries
         * @property {boolean|null} [fixes] DiagnosticRequest fixes
         * @property {boolean|null} [constellations] DiagnosticRequest constellations
         */

        /**
         * Constructs a new DiagnosticRequest.
         * @memberof ProtobufEventPayloadNavigation
         * @classdesc Represents a DiagnosticRequest.
         * @implements IDiagnosticRequest
         * @constructor
         * @param {ProtobufEventPayloadNavigation.IDiagnosticRequest=} [properties] Properties to set
         */
        function DiagnosticRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticRequest maxSummaries.
         * @member {number} maxSummaries
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.maxSummaries = 0;

        /**
         * DiagnosticRequest fixes.
         * @member {boolean} fixes
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.fixes = false;

        /**
         * DiagnosticRequest constellations.
         * @member {boolean} constellations
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.constellations = false;

        /**
         * Creates a new DiagnosticRequest instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadNavigation.IDiagnosticRequest=} [properties] Properties to set
         * @returns {ProtobufEventPayloadNavigation.DiagnosticRequest} DiagnosticRequest instance
         */
        DiagnosticRequest.create = function create(properties) {
            return new DiagnosticRequest(properties);
        };

        /**
         * Encodes the specified DiagnosticRequest message. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticRequest.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadNavigation.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maxSummaries != null && Object.hasOwnProperty.call(message, "maxSummaries"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.maxSummaries);
            if (message.fixes != null && Object.hasOwnProperty.call(message, "fixes"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.fixes);
            if (message.constellations != null && Object.hasOwnProperty.call(message, "constellations"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.constellations);
            return writer;
        };

        /**
         * Encodes the specified DiagnosticRequest message, length delimited. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadNavigation.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadNavigation.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadNavigation.DiagnosticRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxSummaries = reader.uint32();
                        break;
                    }
                case 2: {
                        message.fixes = reader.bool();
                        break;
                    }
                case 3: {
                        message.constellations = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadNavigation.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticRequest message.
         * @function verify
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                if (!$util.isInteger(message.maxSummaries))
                    return "maxSummaries: integer expected";
            if (message.fixes != null && message.hasOwnProperty("fixes"))
                if (typeof message.fixes !== "boolean")
                    return "fixes: boolean expected";
            if (message.constellations != null && message.hasOwnProperty("constellations"))
                if (typeof message.constellations !== "boolean")
                    return "constellations: boolean expected";
            return null;
        };

        /**
         * Creates a DiagnosticRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadNavigation.DiagnosticRequest} DiagnosticRequest
         */
        DiagnosticRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadNavigation.DiagnosticRequest)
                return object;
            var message = new $root.ProtobufEventPayloadNavigation.DiagnosticRequest();
            if (object.maxSummaries != null)
                message.maxSummaries = object.maxSummaries >>> 0;
            if (object.fixes != null)
                message.fixes = Boolean(object.fixes);
            if (object.constellations != null)
                message.constellations = Boolean(object.constellations);
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadNavigation.DiagnosticRequest} message DiagnosticRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.maxSummaries = 0;
                object.fixes = false;
                object.constellations = false;
            }
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                object.maxSummaries = message.maxSummaries;
            if (message.fixes != null && message.hasOwnProperty("fixes"))
                object.fixes = message.fixes;
            if (message.constellations != null && message.hasOwnProperty("constellations"))
                object.constellations = message.constellations;
            return object;
        };

        /**
         * Converts this DiagnosticRequest to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticRequest
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadNavigation.DiagnosticRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadNavigation.DiagnosticRequest";
        };

        return DiagnosticRequest;
    })();

    ProtobufEventPayloadNavigation.DiagnosticResponse = (function() {

        /**
         * Properties of a DiagnosticResponse.
         * @memberof ProtobufEventPayloadNavigation
         * @interface IDiagnosticResponse
         * @property {number|null} [currentUptimeMs] DiagnosticResponse currentUptimeMs
         * @property {number|null} [summaryUptimeMs] DiagnosticResponse summaryUptimeMs
         * @property {number|null} [summaryPeriodS] DiagnosticResponse summaryPeriodS
         * @property {number|null} [numSummaries] DiagnosticResponse numSummaries
         * @property {Uint8Array|null} [summaries] DiagnosticResponse summaries
         * @property {ProtobufEventPayloadNavigation.DiagnosticResponse.IFixes|null} [fixes] DiagnosticResponse fixes
         * @property {ProtobufEventPayloadNavigation.DiagnosticResponse.IConstellations|null} [constellations] DiagnosticResponse constellations
         */

        /**
         * Constructs a new DiagnosticResponse.
         * @memberof ProtobufEventPayloadNavigation
         * @classdesc Represents a DiagnosticResponse.
         * @implements IDiagnosticResponse
         * @constructor
         * @param {ProtobufEventPayloadNavigation.IDiagnosticResponse=} [properties] Properties to set
         */
        function DiagnosticResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticResponse currentUptimeMs.
         * @member {number} currentUptimeMs
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.currentUptimeMs = 0;

        /**
         * DiagnosticResponse summaryUptimeMs.
         * @member {number} summaryUptimeMs
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryUptimeMs = 0;

        /**
         * DiagnosticResponse summaryPeriodS.
         * @member {number} summaryPeriodS
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryPeriodS = 0;

        /**
         * DiagnosticResponse numSummaries.
         * @member {number} numSummaries
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.numSummaries = 0;

        /**
         * DiagnosticResponse summaries.
         * @member {Uint8Array} summaries
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaries = $util.newBuffer([]);

        /**
         * DiagnosticResponse fixes.
         * @member {ProtobufEventPayloadNavigation.DiagnosticResponse.IFixes|null|undefined} fixes
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.fixes = null;

        /**
         * DiagnosticResponse constellations.
         * @member {ProtobufEventPayloadNavigation.DiagnosticResponse.IConstellations|null|undefined} constellations
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.constellations = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DiagnosticResponse type.
         * @member {"fixes"|"constellations"|undefined} type
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         */
        Object.defineProperty(DiagnosticResponse.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["fixes", "constellations"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DiagnosticResponse instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadNavigation.IDiagnosticResponse=} [properties] Properties to set
         * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse} DiagnosticResponse instance
         */
        DiagnosticResponse.create = function create(properties) {
            return new DiagnosticResponse(properties);
        };

        /**
         * Encodes the specified DiagnosticResponse message. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticResponse.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadNavigation.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentUptimeMs != null && Object.hasOwnProperty.call(message, "currentUptimeMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentUptimeMs);
            if (message.summaryUptimeMs != null && Object.hasOwnProperty.call(message, "summaryUptimeMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.summaryUptimeMs);
            if (message.summaryPeriodS != null && Object.hasOwnProperty.call(message, "summaryPeriodS"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.summaryPeriodS);
            if (message.numSummaries != null && Object.hasOwnProperty.call(message, "numSummaries"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numSummaries);
            if (message.summaries != null && Object.hasOwnProperty.call(message, "summaries"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.summaries);
            if (message.fixes != null && Object.hasOwnProperty.call(message, "fixes"))
                $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes.encode(message.fixes, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.constellations != null && Object.hasOwnProperty.call(message, "constellations"))
                $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations.encode(message.constellations, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DiagnosticResponse message, length delimited. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadNavigation.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadNavigation.DiagnosticResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentUptimeMs = reader.uint32();
                        break;
                    }
                case 2: {
                        message.summaryUptimeMs = reader.uint32();
                        break;
                    }
                case 3: {
                        message.summaryPeriodS = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numSummaries = reader.uint32();
                        break;
                    }
                case 5: {
                        message.summaries = reader.bytes();
                        break;
                    }
                case 6: {
                        message.fixes = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.constellations = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticResponse message.
         * @function verify
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                if (!$util.isInteger(message.currentUptimeMs))
                    return "currentUptimeMs: integer expected";
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                if (!$util.isInteger(message.summaryUptimeMs))
                    return "summaryUptimeMs: integer expected";
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                if (!$util.isInteger(message.summaryPeriodS))
                    return "summaryPeriodS: integer expected";
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                if (!$util.isInteger(message.numSummaries))
                    return "numSummaries: integer expected";
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                if (!(message.summaries && typeof message.summaries.length === "number" || $util.isString(message.summaries)))
                    return "summaries: buffer expected";
            if (message.fixes != null && message.hasOwnProperty("fixes")) {
                properties.type = 1;
                {
                    var error = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes.verify(message.fixes);
                    if (error)
                        return "fixes." + error;
                }
            }
            if (message.constellations != null && message.hasOwnProperty("constellations")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    var error = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations.verify(message.constellations);
                    if (error)
                        return "constellations." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DiagnosticResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse} DiagnosticResponse
         */
        DiagnosticResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadNavigation.DiagnosticResponse)
                return object;
            var message = new $root.ProtobufEventPayloadNavigation.DiagnosticResponse();
            if (object.currentUptimeMs != null)
                message.currentUptimeMs = object.currentUptimeMs >>> 0;
            if (object.summaryUptimeMs != null)
                message.summaryUptimeMs = object.summaryUptimeMs >>> 0;
            if (object.summaryPeriodS != null)
                message.summaryPeriodS = object.summaryPeriodS >>> 0;
            if (object.numSummaries != null)
                message.numSummaries = object.numSummaries >>> 0;
            if (object.summaries != null)
                if (typeof object.summaries === "string")
                    $util.base64.decode(object.summaries, message.summaries = $util.newBuffer($util.base64.length(object.summaries)), 0);
                else if (object.summaries.length >= 0)
                    message.summaries = object.summaries;
            if (object.fixes != null) {
                if (typeof object.fixes !== "object")
                    throw TypeError(".ProtobufEventPayloadNavigation.DiagnosticResponse.fixes: object expected");
                message.fixes = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes.fromObject(object.fixes);
            }
            if (object.constellations != null) {
                if (typeof object.constellations !== "object")
                    throw TypeError(".ProtobufEventPayloadNavigation.DiagnosticResponse.constellations: object expected");
                message.constellations = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations.fromObject(object.constellations);
            }
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadNavigation.DiagnosticResponse} message DiagnosticResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currentUptimeMs = 0;
                object.summaryUptimeMs = 0;
                object.summaryPeriodS = 0;
                object.numSummaries = 0;
                if (options.bytes === String)
                    object.summaries = "";
                else {
                    object.summaries = [];
                    if (options.bytes !== Array)
                        object.summaries = $util.newBuffer(object.summaries);
                }
            }
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                object.currentUptimeMs = message.currentUptimeMs;
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                object.summaryUptimeMs = message.summaryUptimeMs;
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                object.summaryPeriodS = message.summaryPeriodS;
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                object.numSummaries = message.numSummaries;
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                object.summaries = options.bytes === String ? $util.base64.encode(message.summaries, 0, message.summaries.length) : options.bytes === Array ? Array.prototype.slice.call(message.summaries) : message.summaries;
            if (message.fixes != null && message.hasOwnProperty("fixes")) {
                object.fixes = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes.toObject(message.fixes, options);
                if (options.oneofs)
                    object.type = "fixes";
            }
            if (message.constellations != null && message.hasOwnProperty("constellations")) {
                object.constellations = $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations.toObject(message.constellations, options);
                if (options.oneofs)
                    object.type = "constellations";
            }
            return object;
        };

        /**
         * Converts this DiagnosticResponse to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticResponse
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadNavigation.DiagnosticResponse";
        };

        DiagnosticResponse.Fixes = (function() {

            /**
             * Properties of a Fixes.
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
             * @interface IFixes
             * @property {ProtobufEventPayloadNavigation.FixType|null} [currentFixType] Fixes currentFixType
             */

            /**
             * Constructs a new Fixes.
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
             * @classdesc Represents a Fixes.
             * @implements IFixes
             * @constructor
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IFixes=} [properties] Properties to set
             */
            function Fixes(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Fixes currentFixType.
             * @member {ProtobufEventPayloadNavigation.FixType} currentFixType
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @instance
             */
            Fixes.prototype.currentFixType = 0;

            /**
             * Creates a new Fixes instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IFixes=} [properties] Properties to set
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes} Fixes instance
             */
            Fixes.create = function create(properties) {
                return new Fixes(properties);
            };

            /**
             * Encodes the specified Fixes message. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IFixes} message Fixes message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Fixes.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentFixType != null && Object.hasOwnProperty.call(message, "currentFixType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currentFixType);
                return writer;
            };

            /**
             * Encodes the specified Fixes message, length delimited. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IFixes} message Fixes message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Fixes.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Fixes message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes} Fixes
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Fixes.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currentFixType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Fixes message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes} Fixes
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Fixes.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Fixes message.
             * @function verify
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Fixes.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentFixType != null && message.hasOwnProperty("currentFixType"))
                    switch (message.currentFixType) {
                    default:
                        return "currentFixType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a Fixes message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes} Fixes
             */
            Fixes.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes)
                    return object;
                var message = new $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes();
                switch (object.currentFixType) {
                default:
                    if (typeof object.currentFixType === "number") {
                        message.currentFixType = object.currentFixType;
                        break;
                    }
                    break;
                case "kInvalid":
                case 0:
                    message.currentFixType = 0;
                    break;
                case "kTimeOnly":
                case 1:
                    message.currentFixType = 1;
                    break;
                case "k2D":
                case 2:
                    message.currentFixType = 2;
                    break;
                case "k3D":
                case 3:
                    message.currentFixType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a Fixes message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes} message Fixes
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Fixes.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.currentFixType = options.enums === String ? "kInvalid" : 0;
                if (message.currentFixType != null && message.hasOwnProperty("currentFixType"))
                    object.currentFixType = options.enums === String ? $root.ProtobufEventPayloadNavigation.FixType[message.currentFixType] === undefined ? message.currentFixType : $root.ProtobufEventPayloadNavigation.FixType[message.currentFixType] : message.currentFixType;
                return object;
            };

            /**
             * Converts this Fixes to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Fixes.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Fixes
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Fixes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadNavigation.DiagnosticResponse.Fixes";
            };

            return Fixes;
        })();

        DiagnosticResponse.Constellations = (function() {

            /**
             * Properties of a Constellations.
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
             * @interface IConstellations
             * @property {number|null} [currentNumSatellites] Constellations currentNumSatellites
             * @property {number|null} [currentLockedSignals] Constellations currentLockedSignals
             * @property {number|null} [currentBestSignal] Constellations currentBestSignal
             */

            /**
             * Constructs a new Constellations.
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse
             * @classdesc Represents a Constellations.
             * @implements IConstellations
             * @constructor
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IConstellations=} [properties] Properties to set
             */
            function Constellations(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Constellations currentNumSatellites.
             * @member {number} currentNumSatellites
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @instance
             */
            Constellations.prototype.currentNumSatellites = 0;

            /**
             * Constellations currentLockedSignals.
             * @member {number} currentLockedSignals
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @instance
             */
            Constellations.prototype.currentLockedSignals = 0;

            /**
             * Constellations currentBestSignal.
             * @member {number} currentBestSignal
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @instance
             */
            Constellations.prototype.currentBestSignal = 0;

            /**
             * Creates a new Constellations instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IConstellations=} [properties] Properties to set
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations} Constellations instance
             */
            Constellations.create = function create(properties) {
                return new Constellations(properties);
            };

            /**
             * Encodes the specified Constellations message. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IConstellations} message Constellations message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Constellations.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentNumSatellites != null && Object.hasOwnProperty.call(message, "currentNumSatellites"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentNumSatellites);
                if (message.currentLockedSignals != null && Object.hasOwnProperty.call(message, "currentLockedSignals"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.currentLockedSignals);
                if (message.currentBestSignal != null && Object.hasOwnProperty.call(message, "currentBestSignal"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.currentBestSignal);
                return writer;
            };

            /**
             * Encodes the specified Constellations message, length delimited. Does not implicitly {@link ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.IConstellations} message Constellations message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Constellations.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Constellations message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations} Constellations
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Constellations.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currentNumSatellites = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.currentLockedSignals = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.currentBestSignal = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Constellations message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations} Constellations
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Constellations.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Constellations message.
             * @function verify
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Constellations.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentNumSatellites != null && message.hasOwnProperty("currentNumSatellites"))
                    if (!$util.isInteger(message.currentNumSatellites))
                        return "currentNumSatellites: integer expected";
                if (message.currentLockedSignals != null && message.hasOwnProperty("currentLockedSignals"))
                    if (!$util.isInteger(message.currentLockedSignals))
                        return "currentLockedSignals: integer expected";
                if (message.currentBestSignal != null && message.hasOwnProperty("currentBestSignal"))
                    if (!$util.isInteger(message.currentBestSignal))
                        return "currentBestSignal: integer expected";
                return null;
            };

            /**
             * Creates a Constellations message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations} Constellations
             */
            Constellations.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations)
                    return object;
                var message = new $root.ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations();
                if (object.currentNumSatellites != null)
                    message.currentNumSatellites = object.currentNumSatellites >>> 0;
                if (object.currentLockedSignals != null)
                    message.currentLockedSignals = object.currentLockedSignals >>> 0;
                if (object.currentBestSignal != null)
                    message.currentBestSignal = object.currentBestSignal >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Constellations message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations} message Constellations
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Constellations.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currentNumSatellites = 0;
                    object.currentLockedSignals = 0;
                    object.currentBestSignal = 0;
                }
                if (message.currentNumSatellites != null && message.hasOwnProperty("currentNumSatellites"))
                    object.currentNumSatellites = message.currentNumSatellites;
                if (message.currentLockedSignals != null && message.hasOwnProperty("currentLockedSignals"))
                    object.currentLockedSignals = message.currentLockedSignals;
                if (message.currentBestSignal != null && message.hasOwnProperty("currentBestSignal"))
                    object.currentBestSignal = message.currentBestSignal;
                return object;
            };

            /**
             * Converts this Constellations to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Constellations.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Constellations
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Constellations.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadNavigation.DiagnosticResponse.Constellations";
            };

            return Constellations;
        })();

        return DiagnosticResponse;
    })();

    return ProtobufEventPayloadNavigation;
})();

$root.ProtobufEventPayloadIns = (function() {

    /**
     * Properties of a ProtobufEventPayloadIns.
     * @exports IProtobufEventPayloadIns
     * @interface IProtobufEventPayloadIns
     * @property {number|null} [uptime] ProtobufEventPayloadIns uptime
     * @property {number|null} [utc] ProtobufEventPayloadIns utc
     * @property {number|null} [qW] ProtobufEventPayloadIns qW
     * @property {number|null} [qX] ProtobufEventPayloadIns qX
     * @property {number|null} [qY] ProtobufEventPayloadIns qY
     * @property {number|null} [qZ] ProtobufEventPayloadIns qZ
     */

    /**
     * Constructs a new ProtobufEventPayloadIns.
     * @exports ProtobufEventPayloadIns
     * @classdesc Represents a ProtobufEventPayloadIns.
     * @implements IProtobufEventPayloadIns
     * @constructor
     * @param {IProtobufEventPayloadIns=} [properties] Properties to set
     */
    function ProtobufEventPayloadIns(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadIns uptime.
     * @member {number} uptime
     * @memberof ProtobufEventPayloadIns
     * @instance
     */
    ProtobufEventPayloadIns.prototype.uptime = 0;

    /**
     * ProtobufEventPayloadIns utc.
     * @member {number} utc
     * @memberof ProtobufEventPayloadIns
     * @instance
     */
    ProtobufEventPayloadIns.prototype.utc = 0;

    /**
     * ProtobufEventPayloadIns qW.
     * @member {number} qW
     * @memberof ProtobufEventPayloadIns
     * @instance
     */
    ProtobufEventPayloadIns.prototype.qW = 0;

    /**
     * ProtobufEventPayloadIns qX.
     * @member {number} qX
     * @memberof ProtobufEventPayloadIns
     * @instance
     */
    ProtobufEventPayloadIns.prototype.qX = 0;

    /**
     * ProtobufEventPayloadIns qY.
     * @member {number} qY
     * @memberof ProtobufEventPayloadIns
     * @instance
     */
    ProtobufEventPayloadIns.prototype.qY = 0;

    /**
     * ProtobufEventPayloadIns qZ.
     * @member {number} qZ
     * @memberof ProtobufEventPayloadIns
     * @instance
     */
    ProtobufEventPayloadIns.prototype.qZ = 0;

    /**
     * Creates a new ProtobufEventPayloadIns instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {IProtobufEventPayloadIns=} [properties] Properties to set
     * @returns {ProtobufEventPayloadIns} ProtobufEventPayloadIns instance
     */
    ProtobufEventPayloadIns.create = function create(properties) {
        return new ProtobufEventPayloadIns(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadIns message. Does not implicitly {@link ProtobufEventPayloadIns.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {IProtobufEventPayloadIns} message ProtobufEventPayloadIns message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadIns.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.uptime != null && Object.hasOwnProperty.call(message, "uptime"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uptime);
        if (message.utc != null && Object.hasOwnProperty.call(message, "utc"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.utc);
        if (message.qW != null && Object.hasOwnProperty.call(message, "qW"))
            writer.uint32(/* id 3, wireType 5 =*/29).float(message.qW);
        if (message.qX != null && Object.hasOwnProperty.call(message, "qX"))
            writer.uint32(/* id 4, wireType 5 =*/37).float(message.qX);
        if (message.qY != null && Object.hasOwnProperty.call(message, "qY"))
            writer.uint32(/* id 5, wireType 5 =*/45).float(message.qY);
        if (message.qZ != null && Object.hasOwnProperty.call(message, "qZ"))
            writer.uint32(/* id 6, wireType 5 =*/53).float(message.qZ);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadIns message, length delimited. Does not implicitly {@link ProtobufEventPayloadIns.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {IProtobufEventPayloadIns} message ProtobufEventPayloadIns message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadIns.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadIns message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadIns} ProtobufEventPayloadIns
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadIns.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadIns();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.uptime = reader.uint32();
                    break;
                }
            case 2: {
                    message.utc = reader.uint32();
                    break;
                }
            case 3: {
                    message.qW = reader.float();
                    break;
                }
            case 4: {
                    message.qX = reader.float();
                    break;
                }
            case 5: {
                    message.qY = reader.float();
                    break;
                }
            case 6: {
                    message.qZ = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadIns message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadIns} ProtobufEventPayloadIns
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadIns.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadIns message.
     * @function verify
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadIns.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.uptime != null && message.hasOwnProperty("uptime"))
            if (!$util.isInteger(message.uptime))
                return "uptime: integer expected";
        if (message.utc != null && message.hasOwnProperty("utc"))
            if (!$util.isInteger(message.utc))
                return "utc: integer expected";
        if (message.qW != null && message.hasOwnProperty("qW"))
            if (typeof message.qW !== "number")
                return "qW: number expected";
        if (message.qX != null && message.hasOwnProperty("qX"))
            if (typeof message.qX !== "number")
                return "qX: number expected";
        if (message.qY != null && message.hasOwnProperty("qY"))
            if (typeof message.qY !== "number")
                return "qY: number expected";
        if (message.qZ != null && message.hasOwnProperty("qZ"))
            if (typeof message.qZ !== "number")
                return "qZ: number expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadIns message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadIns} ProtobufEventPayloadIns
     */
    ProtobufEventPayloadIns.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadIns)
            return object;
        var message = new $root.ProtobufEventPayloadIns();
        if (object.uptime != null)
            message.uptime = object.uptime >>> 0;
        if (object.utc != null)
            message.utc = object.utc >>> 0;
        if (object.qW != null)
            message.qW = Number(object.qW);
        if (object.qX != null)
            message.qX = Number(object.qX);
        if (object.qY != null)
            message.qY = Number(object.qY);
        if (object.qZ != null)
            message.qZ = Number(object.qZ);
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadIns message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {ProtobufEventPayloadIns} message ProtobufEventPayloadIns
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadIns.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.uptime = 0;
            object.utc = 0;
            object.qW = 0;
            object.qX = 0;
            object.qY = 0;
            object.qZ = 0;
        }
        if (message.uptime != null && message.hasOwnProperty("uptime"))
            object.uptime = message.uptime;
        if (message.utc != null && message.hasOwnProperty("utc"))
            object.utc = message.utc;
        if (message.qW != null && message.hasOwnProperty("qW"))
            object.qW = options.json && !isFinite(message.qW) ? String(message.qW) : message.qW;
        if (message.qX != null && message.hasOwnProperty("qX"))
            object.qX = options.json && !isFinite(message.qX) ? String(message.qX) : message.qX;
        if (message.qY != null && message.hasOwnProperty("qY"))
            object.qY = options.json && !isFinite(message.qY) ? String(message.qY) : message.qY;
        if (message.qZ != null && message.hasOwnProperty("qZ"))
            object.qZ = options.json && !isFinite(message.qZ) ? String(message.qZ) : message.qZ;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadIns to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadIns
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadIns.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadIns
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadIns
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadIns.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadIns";
    };

    return ProtobufEventPayloadIns;
})();

$root.ProtobufEventPayloadSnifferData = (function() {

    /**
     * Properties of a ProtobufEventPayloadSnifferData.
     * @exports IProtobufEventPayloadSnifferData
     * @interface IProtobufEventPayloadSnifferData
     * @property {ProtobufEventPayloadSnifferData.Interface|null} ["interface"] ProtobufEventPayloadSnifferData interface
     * @property {ProtobufEventPayloadSnifferData.Direction|null} [direction] ProtobufEventPayloadSnifferData direction
     * @property {Uint8Array|null} [data] ProtobufEventPayloadSnifferData data
     */

    /**
     * Constructs a new ProtobufEventPayloadSnifferData.
     * @exports ProtobufEventPayloadSnifferData
     * @classdesc Represents a ProtobufEventPayloadSnifferData.
     * @implements IProtobufEventPayloadSnifferData
     * @constructor
     * @param {IProtobufEventPayloadSnifferData=} [properties] Properties to set
     */
    function ProtobufEventPayloadSnifferData(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadSnifferData interface.
     * @member {ProtobufEventPayloadSnifferData.Interface} interface
     * @memberof ProtobufEventPayloadSnifferData
     * @instance
     */
    ProtobufEventPayloadSnifferData.prototype["interface"] = 0;

    /**
     * ProtobufEventPayloadSnifferData direction.
     * @member {ProtobufEventPayloadSnifferData.Direction} direction
     * @memberof ProtobufEventPayloadSnifferData
     * @instance
     */
    ProtobufEventPayloadSnifferData.prototype.direction = 0;

    /**
     * ProtobufEventPayloadSnifferData data.
     * @member {Uint8Array} data
     * @memberof ProtobufEventPayloadSnifferData
     * @instance
     */
    ProtobufEventPayloadSnifferData.prototype.data = $util.newBuffer([]);

    /**
     * Creates a new ProtobufEventPayloadSnifferData instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {IProtobufEventPayloadSnifferData=} [properties] Properties to set
     * @returns {ProtobufEventPayloadSnifferData} ProtobufEventPayloadSnifferData instance
     */
    ProtobufEventPayloadSnifferData.create = function create(properties) {
        return new ProtobufEventPayloadSnifferData(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadSnifferData message. Does not implicitly {@link ProtobufEventPayloadSnifferData.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {IProtobufEventPayloadSnifferData} message ProtobufEventPayloadSnifferData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSnifferData.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message["interface"] != null && Object.hasOwnProperty.call(message, "interface"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message["interface"]);
        if (message.direction != null && Object.hasOwnProperty.call(message, "direction"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.direction);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadSnifferData message, length delimited. Does not implicitly {@link ProtobufEventPayloadSnifferData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {IProtobufEventPayloadSnifferData} message ProtobufEventPayloadSnifferData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSnifferData.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadSnifferData message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadSnifferData} ProtobufEventPayloadSnifferData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSnifferData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSnifferData();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message["interface"] = reader.int32();
                    break;
                }
            case 2: {
                    message.direction = reader.int32();
                    break;
                }
            case 3: {
                    message.data = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadSnifferData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadSnifferData} ProtobufEventPayloadSnifferData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSnifferData.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadSnifferData message.
     * @function verify
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadSnifferData.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message["interface"] != null && message.hasOwnProperty("interface"))
            switch (message["interface"]) {
            default:
                return "interface: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.direction != null && message.hasOwnProperty("direction"))
            switch (message.direction) {
            default:
                return "direction: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.data != null && message.hasOwnProperty("data"))
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadSnifferData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadSnifferData} ProtobufEventPayloadSnifferData
     */
    ProtobufEventPayloadSnifferData.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadSnifferData)
            return object;
        var message = new $root.ProtobufEventPayloadSnifferData();
        switch (object["interface"]) {
        default:
            if (typeof object["interface"] === "number") {
                message["interface"] = object["interface"];
                break;
            }
            break;
        case "kNoInterface":
        case 0:
            message["interface"] = 0;
            break;
        case "kSerial":
        case 1:
            message["interface"] = 1;
            break;
        }
        switch (object.direction) {
        default:
            if (typeof object.direction === "number") {
                message.direction = object.direction;
                break;
            }
            break;
        case "kNoDirection":
        case 0:
            message.direction = 0;
            break;
        case "kInbound":
        case 1:
            message.direction = 1;
            break;
        case "kOutbound":
        case 2:
            message.direction = 2;
            break;
        }
        if (object.data != null)
            if (typeof object.data === "string")
                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
            else if (object.data.length >= 0)
                message.data = object.data;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadSnifferData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {ProtobufEventPayloadSnifferData} message ProtobufEventPayloadSnifferData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadSnifferData.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object["interface"] = options.enums === String ? "kNoInterface" : 0;
            object.direction = options.enums === String ? "kNoDirection" : 0;
            if (options.bytes === String)
                object.data = "";
            else {
                object.data = [];
                if (options.bytes !== Array)
                    object.data = $util.newBuffer(object.data);
            }
        }
        if (message["interface"] != null && message.hasOwnProperty("interface"))
            object["interface"] = options.enums === String ? $root.ProtobufEventPayloadSnifferData.Interface[message["interface"]] === undefined ? message["interface"] : $root.ProtobufEventPayloadSnifferData.Interface[message["interface"]] : message["interface"];
        if (message.direction != null && message.hasOwnProperty("direction"))
            object.direction = options.enums === String ? $root.ProtobufEventPayloadSnifferData.Direction[message.direction] === undefined ? message.direction : $root.ProtobufEventPayloadSnifferData.Direction[message.direction] : message.direction;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadSnifferData to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadSnifferData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadSnifferData.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadSnifferData
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadSnifferData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadSnifferData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadSnifferData";
    };

    /**
     * Interface enum.
     * @name ProtobufEventPayloadSnifferData.Interface
     * @enum {number}
     * @property {number} kNoInterface=0 kNoInterface value
     * @property {number} kSerial=1 kSerial value
     */
    ProtobufEventPayloadSnifferData.Interface = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoInterface"] = 0;
        values[valuesById[1] = "kSerial"] = 1;
        return values;
    })();

    /**
     * Direction enum.
     * @name ProtobufEventPayloadSnifferData.Direction
     * @enum {number}
     * @property {number} kNoDirection=0 kNoDirection value
     * @property {number} kInbound=1 kInbound value
     * @property {number} kOutbound=2 kOutbound value
     */
    ProtobufEventPayloadSnifferData.Direction = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoDirection"] = 0;
        values[valuesById[1] = "kInbound"] = 1;
        values[valuesById[2] = "kOutbound"] = 2;
        return values;
    })();

    return ProtobufEventPayloadSnifferData;
})();

$root.ProtobufEventPayloadExternalDeviceState = (function() {

    /**
     * Properties of a ProtobufEventPayloadExternalDeviceState.
     * @exports IProtobufEventPayloadExternalDeviceState
     * @interface IProtobufEventPayloadExternalDeviceState
     * @property {number|null} [device] ProtobufEventPayloadExternalDeviceState device
     * @property {ProtobufEventPayloadExternalDeviceState.Channel|null} [channel] ProtobufEventPayloadExternalDeviceState channel
     * @property {ProtobufEventPayloadExternalDeviceState.State|null} [state] ProtobufEventPayloadExternalDeviceState state
     */

    /**
     * Constructs a new ProtobufEventPayloadExternalDeviceState.
     * @exports ProtobufEventPayloadExternalDeviceState
     * @classdesc Represents a ProtobufEventPayloadExternalDeviceState.
     * @implements IProtobufEventPayloadExternalDeviceState
     * @constructor
     * @param {IProtobufEventPayloadExternalDeviceState=} [properties] Properties to set
     */
    function ProtobufEventPayloadExternalDeviceState(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadExternalDeviceState device.
     * @member {number} device
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @instance
     */
    ProtobufEventPayloadExternalDeviceState.prototype.device = 0;

    /**
     * ProtobufEventPayloadExternalDeviceState channel.
     * @member {ProtobufEventPayloadExternalDeviceState.Channel} channel
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @instance
     */
    ProtobufEventPayloadExternalDeviceState.prototype.channel = 0;

    /**
     * ProtobufEventPayloadExternalDeviceState state.
     * @member {ProtobufEventPayloadExternalDeviceState.State} state
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @instance
     */
    ProtobufEventPayloadExternalDeviceState.prototype.state = 0;

    /**
     * Creates a new ProtobufEventPayloadExternalDeviceState instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {IProtobufEventPayloadExternalDeviceState=} [properties] Properties to set
     * @returns {ProtobufEventPayloadExternalDeviceState} ProtobufEventPayloadExternalDeviceState instance
     */
    ProtobufEventPayloadExternalDeviceState.create = function create(properties) {
        return new ProtobufEventPayloadExternalDeviceState(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadExternalDeviceState message. Does not implicitly {@link ProtobufEventPayloadExternalDeviceState.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {IProtobufEventPayloadExternalDeviceState} message ProtobufEventPayloadExternalDeviceState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadExternalDeviceState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.device != null && Object.hasOwnProperty.call(message, "device"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.device);
        if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.channel);
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadExternalDeviceState message, length delimited. Does not implicitly {@link ProtobufEventPayloadExternalDeviceState.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {IProtobufEventPayloadExternalDeviceState} message ProtobufEventPayloadExternalDeviceState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadExternalDeviceState.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadExternalDeviceState message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadExternalDeviceState} ProtobufEventPayloadExternalDeviceState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadExternalDeviceState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadExternalDeviceState();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.device = reader.uint32();
                    break;
                }
            case 2: {
                    message.channel = reader.int32();
                    break;
                }
            case 3: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadExternalDeviceState message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadExternalDeviceState} ProtobufEventPayloadExternalDeviceState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadExternalDeviceState.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadExternalDeviceState message.
     * @function verify
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadExternalDeviceState.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.device != null && message.hasOwnProperty("device"))
            if (!$util.isInteger(message.device))
                return "device: integer expected";
        if (message.channel != null && message.hasOwnProperty("channel"))
            switch (message.channel) {
            default:
                return "channel: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        if (message.state != null && message.hasOwnProperty("state"))
            switch (message.state) {
            default:
                return "state: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadExternalDeviceState message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadExternalDeviceState} ProtobufEventPayloadExternalDeviceState
     */
    ProtobufEventPayloadExternalDeviceState.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadExternalDeviceState)
            return object;
        var message = new $root.ProtobufEventPayloadExternalDeviceState();
        if (object.device != null)
            message.device = object.device >>> 0;
        switch (object.channel) {
        default:
            if (typeof object.channel === "number") {
                message.channel = object.channel;
                break;
            }
            break;
        case "kNoChannel":
        case 0:
            message.channel = 0;
            break;
        case "kSerial":
        case 1:
            message.channel = 1;
            break;
        case "kUsb":
        case 2:
            message.channel = 2;
            break;
        case "kBluetooth":
        case 3:
            message.channel = 3;
            break;
        }
        switch (object.state) {
        default:
            if (typeof object.state === "number") {
                message.state = object.state;
                break;
            }
            break;
        case "kNoState":
        case 0:
            message.state = 0;
            break;
        case "kInactive":
        case 1:
            message.state = 1;
            break;
        case "kReceiving":
        case 2:
            message.state = 2;
            break;
        case "kDecoding":
        case 3:
            message.state = 3;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadExternalDeviceState message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {ProtobufEventPayloadExternalDeviceState} message ProtobufEventPayloadExternalDeviceState
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadExternalDeviceState.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.device = 0;
            object.channel = options.enums === String ? "kNoChannel" : 0;
            object.state = options.enums === String ? "kNoState" : 0;
        }
        if (message.device != null && message.hasOwnProperty("device"))
            object.device = message.device;
        if (message.channel != null && message.hasOwnProperty("channel"))
            object.channel = options.enums === String ? $root.ProtobufEventPayloadExternalDeviceState.Channel[message.channel] === undefined ? message.channel : $root.ProtobufEventPayloadExternalDeviceState.Channel[message.channel] : message.channel;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = options.enums === String ? $root.ProtobufEventPayloadExternalDeviceState.State[message.state] === undefined ? message.state : $root.ProtobufEventPayloadExternalDeviceState.State[message.state] : message.state;
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadExternalDeviceState to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadExternalDeviceState.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadExternalDeviceState
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadExternalDeviceState
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadExternalDeviceState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadExternalDeviceState";
    };

    /**
     * Channel enum.
     * @name ProtobufEventPayloadExternalDeviceState.Channel
     * @enum {number}
     * @property {number} kNoChannel=0 kNoChannel value
     * @property {number} kSerial=1 kSerial value
     * @property {number} kUsb=2 kUsb value
     * @property {number} kBluetooth=3 kBluetooth value
     */
    ProtobufEventPayloadExternalDeviceState.Channel = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoChannel"] = 0;
        values[valuesById[1] = "kSerial"] = 1;
        values[valuesById[2] = "kUsb"] = 2;
        values[valuesById[3] = "kBluetooth"] = 3;
        return values;
    })();

    /**
     * State enum.
     * @name ProtobufEventPayloadExternalDeviceState.State
     * @enum {number}
     * @property {number} kNoState=0 kNoState value
     * @property {number} kInactive=1 kInactive value
     * @property {number} kReceiving=2 kReceiving value
     * @property {number} kDecoding=3 kDecoding value
     */
    ProtobufEventPayloadExternalDeviceState.State = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoState"] = 0;
        values[valuesById[1] = "kInactive"] = 1;
        values[valuesById[2] = "kReceiving"] = 2;
        values[valuesById[3] = "kDecoding"] = 3;
        return values;
    })();

    return ProtobufEventPayloadExternalDeviceState;
})();

$root.ProtobufEventPayloadGpi = (function() {

    /**
     * Properties of a ProtobufEventPayloadGpi.
     * @exports IProtobufEventPayloadGpi
     * @interface IProtobufEventPayloadGpi
     * @property {Array.<ProtobufEventPayloadGpi.IInput>|null} [inputs] ProtobufEventPayloadGpi inputs
     */

    /**
     * Constructs a new ProtobufEventPayloadGpi.
     * @exports ProtobufEventPayloadGpi
     * @classdesc Represents a ProtobufEventPayloadGpi.
     * @implements IProtobufEventPayloadGpi
     * @constructor
     * @param {IProtobufEventPayloadGpi=} [properties] Properties to set
     */
    function ProtobufEventPayloadGpi(properties) {
        this.inputs = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadGpi inputs.
     * @member {Array.<ProtobufEventPayloadGpi.IInput>} inputs
     * @memberof ProtobufEventPayloadGpi
     * @instance
     */
    ProtobufEventPayloadGpi.prototype.inputs = $util.emptyArray;

    /**
     * Creates a new ProtobufEventPayloadGpi instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {IProtobufEventPayloadGpi=} [properties] Properties to set
     * @returns {ProtobufEventPayloadGpi} ProtobufEventPayloadGpi instance
     */
    ProtobufEventPayloadGpi.create = function create(properties) {
        return new ProtobufEventPayloadGpi(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadGpi message. Does not implicitly {@link ProtobufEventPayloadGpi.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {IProtobufEventPayloadGpi} message ProtobufEventPayloadGpi message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadGpi.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inputs != null && message.inputs.length)
            for (var i = 0; i < message.inputs.length; ++i)
                $root.ProtobufEventPayloadGpi.Input.encode(message.inputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadGpi message, length delimited. Does not implicitly {@link ProtobufEventPayloadGpi.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {IProtobufEventPayloadGpi} message ProtobufEventPayloadGpi message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadGpi.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadGpi message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadGpi} ProtobufEventPayloadGpi
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadGpi.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadGpi();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.inputs && message.inputs.length))
                        message.inputs = [];
                    message.inputs.push($root.ProtobufEventPayloadGpi.Input.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadGpi message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadGpi} ProtobufEventPayloadGpi
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadGpi.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadGpi message.
     * @function verify
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadGpi.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inputs != null && message.hasOwnProperty("inputs")) {
            if (!Array.isArray(message.inputs))
                return "inputs: array expected";
            for (var i = 0; i < message.inputs.length; ++i) {
                var error = $root.ProtobufEventPayloadGpi.Input.verify(message.inputs[i]);
                if (error)
                    return "inputs." + error;
            }
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadGpi message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadGpi} ProtobufEventPayloadGpi
     */
    ProtobufEventPayloadGpi.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadGpi)
            return object;
        var message = new $root.ProtobufEventPayloadGpi();
        if (object.inputs) {
            if (!Array.isArray(object.inputs))
                throw TypeError(".ProtobufEventPayloadGpi.inputs: array expected");
            message.inputs = [];
            for (var i = 0; i < object.inputs.length; ++i) {
                if (typeof object.inputs[i] !== "object")
                    throw TypeError(".ProtobufEventPayloadGpi.inputs: object expected");
                message.inputs[i] = $root.ProtobufEventPayloadGpi.Input.fromObject(object.inputs[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadGpi message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {ProtobufEventPayloadGpi} message ProtobufEventPayloadGpi
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadGpi.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.inputs = [];
        if (message.inputs && message.inputs.length) {
            object.inputs = [];
            for (var j = 0; j < message.inputs.length; ++j)
                object.inputs[j] = $root.ProtobufEventPayloadGpi.Input.toObject(message.inputs[j], options);
        }
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadGpi to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadGpi
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadGpi.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadGpi
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadGpi
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadGpi.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadGpi";
    };

    ProtobufEventPayloadGpi.Input = (function() {

        /**
         * Properties of an Input.
         * @memberof ProtobufEventPayloadGpi
         * @interface IInput
         * @property {number|null} [id] Input id
         * @property {boolean|null} [activeState] Input activeState
         * @property {boolean|null} [state] Input state
         * @property {number|null} [stateTransitions] Input stateTransitions
         * @property {number|null} [stateDurationMs] Input stateDurationMs
         * @property {ProtobufSettingsUserGpiV1.InputType|null} [type] Input type
         * @property {boolean|null} [externalPower] Input externalPower
         */

        /**
         * Constructs a new Input.
         * @memberof ProtobufEventPayloadGpi
         * @classdesc Represents an Input.
         * @implements IInput
         * @constructor
         * @param {ProtobufEventPayloadGpi.IInput=} [properties] Properties to set
         */
        function Input(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Input id.
         * @member {number} id
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         */
        Input.prototype.id = 0;

        /**
         * Input activeState.
         * @member {boolean} activeState
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         */
        Input.prototype.activeState = false;

        /**
         * Input state.
         * @member {boolean} state
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         */
        Input.prototype.state = false;

        /**
         * Input stateTransitions.
         * @member {number} stateTransitions
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         */
        Input.prototype.stateTransitions = 0;

        /**
         * Input stateDurationMs.
         * @member {number} stateDurationMs
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         */
        Input.prototype.stateDurationMs = 0;

        /**
         * Input type.
         * @member {ProtobufSettingsUserGpiV1.InputType} type
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         */
        Input.prototype.type = 0;

        /**
         * Input externalPower.
         * @member {boolean} externalPower
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         */
        Input.prototype.externalPower = false;

        /**
         * Creates a new Input instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {ProtobufEventPayloadGpi.IInput=} [properties] Properties to set
         * @returns {ProtobufEventPayloadGpi.Input} Input instance
         */
        Input.create = function create(properties) {
            return new Input(properties);
        };

        /**
         * Encodes the specified Input message. Does not implicitly {@link ProtobufEventPayloadGpi.Input.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {ProtobufEventPayloadGpi.IInput} message Input message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Input.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.activeState != null && Object.hasOwnProperty.call(message, "activeState"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.activeState);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.state);
            if (message.stateTransitions != null && Object.hasOwnProperty.call(message, "stateTransitions"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.stateTransitions);
            if (message.stateDurationMs != null && Object.hasOwnProperty.call(message, "stateDurationMs"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.stateDurationMs);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.type);
            if (message.externalPower != null && Object.hasOwnProperty.call(message, "externalPower"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.externalPower);
            return writer;
        };

        /**
         * Encodes the specified Input message, length delimited. Does not implicitly {@link ProtobufEventPayloadGpi.Input.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {ProtobufEventPayloadGpi.IInput} message Input message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Input.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Input message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadGpi.Input} Input
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Input.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadGpi.Input();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 3: {
                        message.activeState = reader.bool();
                        break;
                    }
                case 4: {
                        message.state = reader.bool();
                        break;
                    }
                case 5: {
                        message.stateTransitions = reader.uint32();
                        break;
                    }
                case 6: {
                        message.stateDurationMs = reader.uint32();
                        break;
                    }
                case 7: {
                        message.type = reader.int32();
                        break;
                    }
                case 8: {
                        message.externalPower = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Input message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadGpi.Input} Input
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Input.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Input message.
         * @function verify
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Input.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.activeState != null && message.hasOwnProperty("activeState"))
                if (typeof message.activeState !== "boolean")
                    return "activeState: boolean expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (typeof message.state !== "boolean")
                    return "state: boolean expected";
            if (message.stateTransitions != null && message.hasOwnProperty("stateTransitions"))
                if (!$util.isInteger(message.stateTransitions))
                    return "stateTransitions: integer expected";
            if (message.stateDurationMs != null && message.hasOwnProperty("stateDurationMs"))
                if (!$util.isInteger(message.stateDurationMs))
                    return "stateDurationMs: integer expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.externalPower != null && message.hasOwnProperty("externalPower"))
                if (typeof message.externalPower !== "boolean")
                    return "externalPower: boolean expected";
            return null;
        };

        /**
         * Creates an Input message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadGpi.Input} Input
         */
        Input.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadGpi.Input)
                return object;
            var message = new $root.ProtobufEventPayloadGpi.Input();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.activeState != null)
                message.activeState = Boolean(object.activeState);
            if (object.state != null)
                message.state = Boolean(object.state);
            if (object.stateTransitions != null)
                message.stateTransitions = object.stateTransitions >>> 0;
            if (object.stateDurationMs != null)
                message.stateDurationMs = object.stateDurationMs >>> 0;
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "kInputTypeUnused":
            case 0:
                message.type = 0;
                break;
            case "kInputTypeEngineOilPressure1":
            case 1:
                message.type = 1;
                break;
            case "kInputTypeEngineOilPressure2":
            case 2:
                message.type = 2;
                break;
            case "kInputTypeMainGearboxOilPressure":
            case 3:
                message.type = 3;
                break;
            case "kInputTypeCollectiveRaised":
            case 4:
                message.type = 4;
                break;
            case "kInputTypeWeightOnWheels":
            case 5:
                message.type = 5;
                break;
            case "kInputTypeLandingGearRetracted":
            case 6:
                message.type = 6;
                break;
            }
            if (object.externalPower != null)
                message.externalPower = Boolean(object.externalPower);
            return message;
        };

        /**
         * Creates a plain object from an Input message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {ProtobufEventPayloadGpi.Input} message Input
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Input.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.id = 0;
                object.activeState = false;
                object.state = false;
                object.stateTransitions = 0;
                object.stateDurationMs = 0;
                object.type = options.enums === String ? "kInputTypeUnused" : 0;
                object.externalPower = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.activeState != null && message.hasOwnProperty("activeState"))
                object.activeState = message.activeState;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            if (message.stateTransitions != null && message.hasOwnProperty("stateTransitions"))
                object.stateTransitions = message.stateTransitions;
            if (message.stateDurationMs != null && message.hasOwnProperty("stateDurationMs"))
                object.stateDurationMs = message.stateDurationMs;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.ProtobufSettingsUserGpiV1.InputType[message.type] === undefined ? message.type : $root.ProtobufSettingsUserGpiV1.InputType[message.type] : message.type;
            if (message.externalPower != null && message.hasOwnProperty("externalPower"))
                object.externalPower = message.externalPower;
            return object;
        };

        /**
         * Converts this Input to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadGpi.Input
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Input.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Input
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadGpi.Input
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Input.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadGpi.Input";
        };

        return Input;
    })();

    return ProtobufEventPayloadGpi;
})();

$root.ProtobufEventPayloadDataBlock = (function() {

    /**
     * Properties of a ProtobufEventPayloadDataBlock.
     * @exports IProtobufEventPayloadDataBlock
     * @interface IProtobufEventPayloadDataBlock
     * @property {ProtobufEventPayloadDataBlock.DataType|null} [dataType] ProtobufEventPayloadDataBlock dataType
     * @property {ProtobufEventPayloadDataBlock.IDiagnosticRequest|null} [diagnosticRequest] ProtobufEventPayloadDataBlock diagnosticRequest
     * @property {ProtobufEventPayloadDataBlock.IDiagnosticResponse|null} [diagnosticResponse] ProtobufEventPayloadDataBlock diagnosticResponse
     */

    /**
     * Constructs a new ProtobufEventPayloadDataBlock.
     * @exports ProtobufEventPayloadDataBlock
     * @classdesc Represents a ProtobufEventPayloadDataBlock.
     * @implements IProtobufEventPayloadDataBlock
     * @constructor
     * @param {IProtobufEventPayloadDataBlock=} [properties] Properties to set
     */
    function ProtobufEventPayloadDataBlock(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadDataBlock dataType.
     * @member {ProtobufEventPayloadDataBlock.DataType} dataType
     * @memberof ProtobufEventPayloadDataBlock
     * @instance
     */
    ProtobufEventPayloadDataBlock.prototype.dataType = 0;

    /**
     * ProtobufEventPayloadDataBlock diagnosticRequest.
     * @member {ProtobufEventPayloadDataBlock.IDiagnosticRequest|null|undefined} diagnosticRequest
     * @memberof ProtobufEventPayloadDataBlock
     * @instance
     */
    ProtobufEventPayloadDataBlock.prototype.diagnosticRequest = null;

    /**
     * ProtobufEventPayloadDataBlock diagnosticResponse.
     * @member {ProtobufEventPayloadDataBlock.IDiagnosticResponse|null|undefined} diagnosticResponse
     * @memberof ProtobufEventPayloadDataBlock
     * @instance
     */
    ProtobufEventPayloadDataBlock.prototype.diagnosticResponse = null;

    /**
     * Creates a new ProtobufEventPayloadDataBlock instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {IProtobufEventPayloadDataBlock=} [properties] Properties to set
     * @returns {ProtobufEventPayloadDataBlock} ProtobufEventPayloadDataBlock instance
     */
    ProtobufEventPayloadDataBlock.create = function create(properties) {
        return new ProtobufEventPayloadDataBlock(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadDataBlock message. Does not implicitly {@link ProtobufEventPayloadDataBlock.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {IProtobufEventPayloadDataBlock} message ProtobufEventPayloadDataBlock message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadDataBlock.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dataType);
        if (message.diagnosticRequest != null && Object.hasOwnProperty.call(message, "diagnosticRequest"))
            $root.ProtobufEventPayloadDataBlock.DiagnosticRequest.encode(message.diagnosticRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.diagnosticResponse != null && Object.hasOwnProperty.call(message, "diagnosticResponse"))
            $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.encode(message.diagnosticResponse, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadDataBlock message, length delimited. Does not implicitly {@link ProtobufEventPayloadDataBlock.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {IProtobufEventPayloadDataBlock} message ProtobufEventPayloadDataBlock message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadDataBlock.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadDataBlock message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadDataBlock} ProtobufEventPayloadDataBlock
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadDataBlock.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadDataBlock();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.dataType = reader.int32();
                    break;
                }
            case 2: {
                    message.diagnosticRequest = $root.ProtobufEventPayloadDataBlock.DiagnosticRequest.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.diagnosticResponse = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadDataBlock message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadDataBlock} ProtobufEventPayloadDataBlock
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadDataBlock.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadDataBlock message.
     * @function verify
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadDataBlock.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.dataType != null && message.hasOwnProperty("dataType"))
            switch (message.dataType) {
            default:
                return "dataType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest")) {
            var error = $root.ProtobufEventPayloadDataBlock.DiagnosticRequest.verify(message.diagnosticRequest);
            if (error)
                return "diagnosticRequest." + error;
        }
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse")) {
            var error = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.verify(message.diagnosticResponse);
            if (error)
                return "diagnosticResponse." + error;
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadDataBlock message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadDataBlock} ProtobufEventPayloadDataBlock
     */
    ProtobufEventPayloadDataBlock.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadDataBlock)
            return object;
        var message = new $root.ProtobufEventPayloadDataBlock();
        switch (object.dataType) {
        default:
            if (typeof object.dataType === "number") {
                message.dataType = object.dataType;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.dataType = 0;
            break;
        case "kLog":
        case 1:
            message.dataType = 1;
            break;
        case "kImu":
        case 2:
            message.dataType = 2;
            break;
        case "kSerial":
        case 3:
            message.dataType = 3;
            break;
        case "kFlight":
        case 4:
            message.dataType = 4;
            break;
        }
        if (object.diagnosticRequest != null) {
            if (typeof object.diagnosticRequest !== "object")
                throw TypeError(".ProtobufEventPayloadDataBlock.diagnosticRequest: object expected");
            message.diagnosticRequest = $root.ProtobufEventPayloadDataBlock.DiagnosticRequest.fromObject(object.diagnosticRequest);
        }
        if (object.diagnosticResponse != null) {
            if (typeof object.diagnosticResponse !== "object")
                throw TypeError(".ProtobufEventPayloadDataBlock.diagnosticResponse: object expected");
            message.diagnosticResponse = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.fromObject(object.diagnosticResponse);
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadDataBlock message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {ProtobufEventPayloadDataBlock} message ProtobufEventPayloadDataBlock
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadDataBlock.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.dataType = options.enums === String ? "kNone" : 0;
            object.diagnosticRequest = null;
            object.diagnosticResponse = null;
        }
        if (message.dataType != null && message.hasOwnProperty("dataType"))
            object.dataType = options.enums === String ? $root.ProtobufEventPayloadDataBlock.DataType[message.dataType] === undefined ? message.dataType : $root.ProtobufEventPayloadDataBlock.DataType[message.dataType] : message.dataType;
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest"))
            object.diagnosticRequest = $root.ProtobufEventPayloadDataBlock.DiagnosticRequest.toObject(message.diagnosticRequest, options);
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse"))
            object.diagnosticResponse = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.toObject(message.diagnosticResponse, options);
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadDataBlock to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadDataBlock
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadDataBlock.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadDataBlock
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadDataBlock
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadDataBlock.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadDataBlock";
    };

    /**
     * DataType enum.
     * @name ProtobufEventPayloadDataBlock.DataType
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kLog=1 kLog value
     * @property {number} kImu=2 kImu value
     * @property {number} kSerial=3 kSerial value
     * @property {number} kFlight=4 kFlight value
     */
    ProtobufEventPayloadDataBlock.DataType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kLog"] = 1;
        values[valuesById[2] = "kImu"] = 2;
        values[valuesById[3] = "kSerial"] = 3;
        values[valuesById[4] = "kFlight"] = 4;
        return values;
    })();

    ProtobufEventPayloadDataBlock.DiagnosticRequest = (function() {

        /**
         * Properties of a DiagnosticRequest.
         * @memberof ProtobufEventPayloadDataBlock
         * @interface IDiagnosticRequest
         * @property {number|null} [maxSummaries] DiagnosticRequest maxSummaries
         * @property {boolean|null} [storage] DiagnosticRequest storage
         */

        /**
         * Constructs a new DiagnosticRequest.
         * @memberof ProtobufEventPayloadDataBlock
         * @classdesc Represents a DiagnosticRequest.
         * @implements IDiagnosticRequest
         * @constructor
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticRequest=} [properties] Properties to set
         */
        function DiagnosticRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticRequest maxSummaries.
         * @member {number} maxSummaries
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.maxSummaries = 0;

        /**
         * DiagnosticRequest storage.
         * @member {boolean} storage
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.storage = false;

        /**
         * Creates a new DiagnosticRequest instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticRequest=} [properties] Properties to set
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticRequest} DiagnosticRequest instance
         */
        DiagnosticRequest.create = function create(properties) {
            return new DiagnosticRequest(properties);
        };

        /**
         * Encodes the specified DiagnosticRequest message. Does not implicitly {@link ProtobufEventPayloadDataBlock.DiagnosticRequest.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maxSummaries != null && Object.hasOwnProperty.call(message, "maxSummaries"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.maxSummaries);
            if (message.storage != null && Object.hasOwnProperty.call(message, "storage"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.storage);
            return writer;
        };

        /**
         * Encodes the specified DiagnosticRequest message, length delimited. Does not implicitly {@link ProtobufEventPayloadDataBlock.DiagnosticRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadDataBlock.DiagnosticRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxSummaries = reader.uint32();
                        break;
                    }
                case 2: {
                        message.storage = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticRequest message.
         * @function verify
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                if (!$util.isInteger(message.maxSummaries))
                    return "maxSummaries: integer expected";
            if (message.storage != null && message.hasOwnProperty("storage"))
                if (typeof message.storage !== "boolean")
                    return "storage: boolean expected";
            return null;
        };

        /**
         * Creates a DiagnosticRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticRequest} DiagnosticRequest
         */
        DiagnosticRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadDataBlock.DiagnosticRequest)
                return object;
            var message = new $root.ProtobufEventPayloadDataBlock.DiagnosticRequest();
            if (object.maxSummaries != null)
                message.maxSummaries = object.maxSummaries >>> 0;
            if (object.storage != null)
                message.storage = Boolean(object.storage);
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadDataBlock.DiagnosticRequest} message DiagnosticRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.maxSummaries = 0;
                object.storage = false;
            }
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                object.maxSummaries = message.maxSummaries;
            if (message.storage != null && message.hasOwnProperty("storage"))
                object.storage = message.storage;
            return object;
        };

        /**
         * Converts this DiagnosticRequest to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticRequest
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadDataBlock.DiagnosticRequest";
        };

        return DiagnosticRequest;
    })();

    ProtobufEventPayloadDataBlock.DiagnosticResponse = (function() {

        /**
         * Properties of a DiagnosticResponse.
         * @memberof ProtobufEventPayloadDataBlock
         * @interface IDiagnosticResponse
         * @property {number|null} [currentUptimeMs] DiagnosticResponse currentUptimeMs
         * @property {number|null} [summaryUptimeMs] DiagnosticResponse summaryUptimeMs
         * @property {number|null} [summaryPeriodS] DiagnosticResponse summaryPeriodS
         * @property {number|null} [numSummaries] DiagnosticResponse numSummaries
         * @property {Uint8Array|null} [summaries] DiagnosticResponse summaries
         * @property {ProtobufEventPayloadDataBlock.DiagnosticResponse.IStorage|null} [storage] DiagnosticResponse storage
         */

        /**
         * Constructs a new DiagnosticResponse.
         * @memberof ProtobufEventPayloadDataBlock
         * @classdesc Represents a DiagnosticResponse.
         * @implements IDiagnosticResponse
         * @constructor
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticResponse=} [properties] Properties to set
         */
        function DiagnosticResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticResponse currentUptimeMs.
         * @member {number} currentUptimeMs
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.currentUptimeMs = 0;

        /**
         * DiagnosticResponse summaryUptimeMs.
         * @member {number} summaryUptimeMs
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryUptimeMs = 0;

        /**
         * DiagnosticResponse summaryPeriodS.
         * @member {number} summaryPeriodS
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryPeriodS = 0;

        /**
         * DiagnosticResponse numSummaries.
         * @member {number} numSummaries
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.numSummaries = 0;

        /**
         * DiagnosticResponse summaries.
         * @member {Uint8Array} summaries
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaries = $util.newBuffer([]);

        /**
         * DiagnosticResponse storage.
         * @member {ProtobufEventPayloadDataBlock.DiagnosticResponse.IStorage|null|undefined} storage
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.storage = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DiagnosticResponse type.
         * @member {"storage"|undefined} type
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         */
        Object.defineProperty(DiagnosticResponse.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["storage"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DiagnosticResponse instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticResponse=} [properties] Properties to set
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse} DiagnosticResponse instance
         */
        DiagnosticResponse.create = function create(properties) {
            return new DiagnosticResponse(properties);
        };

        /**
         * Encodes the specified DiagnosticResponse message. Does not implicitly {@link ProtobufEventPayloadDataBlock.DiagnosticResponse.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentUptimeMs != null && Object.hasOwnProperty.call(message, "currentUptimeMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentUptimeMs);
            if (message.summaryUptimeMs != null && Object.hasOwnProperty.call(message, "summaryUptimeMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.summaryUptimeMs);
            if (message.summaryPeriodS != null && Object.hasOwnProperty.call(message, "summaryPeriodS"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.summaryPeriodS);
            if (message.numSummaries != null && Object.hasOwnProperty.call(message, "numSummaries"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numSummaries);
            if (message.summaries != null && Object.hasOwnProperty.call(message, "summaries"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.summaries);
            if (message.storage != null && Object.hasOwnProperty.call(message, "storage"))
                $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage.encode(message.storage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DiagnosticResponse message, length delimited. Does not implicitly {@link ProtobufEventPayloadDataBlock.DiagnosticResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadDataBlock.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadDataBlock.DiagnosticResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentUptimeMs = reader.uint32();
                        break;
                    }
                case 2: {
                        message.summaryUptimeMs = reader.uint32();
                        break;
                    }
                case 3: {
                        message.summaryPeriodS = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numSummaries = reader.uint32();
                        break;
                    }
                case 5: {
                        message.summaries = reader.bytes();
                        break;
                    }
                case 6: {
                        message.storage = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticResponse message.
         * @function verify
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                if (!$util.isInteger(message.currentUptimeMs))
                    return "currentUptimeMs: integer expected";
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                if (!$util.isInteger(message.summaryUptimeMs))
                    return "summaryUptimeMs: integer expected";
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                if (!$util.isInteger(message.summaryPeriodS))
                    return "summaryPeriodS: integer expected";
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                if (!$util.isInteger(message.numSummaries))
                    return "numSummaries: integer expected";
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                if (!(message.summaries && typeof message.summaries.length === "number" || $util.isString(message.summaries)))
                    return "summaries: buffer expected";
            if (message.storage != null && message.hasOwnProperty("storage")) {
                properties.type = 1;
                {
                    var error = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage.verify(message.storage);
                    if (error)
                        return "storage." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DiagnosticResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse} DiagnosticResponse
         */
        DiagnosticResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadDataBlock.DiagnosticResponse)
                return object;
            var message = new $root.ProtobufEventPayloadDataBlock.DiagnosticResponse();
            if (object.currentUptimeMs != null)
                message.currentUptimeMs = object.currentUptimeMs >>> 0;
            if (object.summaryUptimeMs != null)
                message.summaryUptimeMs = object.summaryUptimeMs >>> 0;
            if (object.summaryPeriodS != null)
                message.summaryPeriodS = object.summaryPeriodS >>> 0;
            if (object.numSummaries != null)
                message.numSummaries = object.numSummaries >>> 0;
            if (object.summaries != null)
                if (typeof object.summaries === "string")
                    $util.base64.decode(object.summaries, message.summaries = $util.newBuffer($util.base64.length(object.summaries)), 0);
                else if (object.summaries.length >= 0)
                    message.summaries = object.summaries;
            if (object.storage != null) {
                if (typeof object.storage !== "object")
                    throw TypeError(".ProtobufEventPayloadDataBlock.DiagnosticResponse.storage: object expected");
                message.storage = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage.fromObject(object.storage);
            }
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadDataBlock.DiagnosticResponse} message DiagnosticResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currentUptimeMs = 0;
                object.summaryUptimeMs = 0;
                object.summaryPeriodS = 0;
                object.numSummaries = 0;
                if (options.bytes === String)
                    object.summaries = "";
                else {
                    object.summaries = [];
                    if (options.bytes !== Array)
                        object.summaries = $util.newBuffer(object.summaries);
                }
            }
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                object.currentUptimeMs = message.currentUptimeMs;
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                object.summaryUptimeMs = message.summaryUptimeMs;
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                object.summaryPeriodS = message.summaryPeriodS;
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                object.numSummaries = message.numSummaries;
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                object.summaries = options.bytes === String ? $util.base64.encode(message.summaries, 0, message.summaries.length) : options.bytes === Array ? Array.prototype.slice.call(message.summaries) : message.summaries;
            if (message.storage != null && message.hasOwnProperty("storage")) {
                object.storage = $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage.toObject(message.storage, options);
                if (options.oneofs)
                    object.type = "storage";
            }
            return object;
        };

        /**
         * Converts this DiagnosticResponse to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticResponse
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadDataBlock.DiagnosticResponse";
        };

        DiagnosticResponse.Storage = (function() {

            /**
             * Properties of a Storage.
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
             * @interface IStorage
             * @property {number|null} [capacity] Storage capacity
             * @property {number|null} [currentSize] Storage currentSize
             * @property {number|null} [currentWritten] Storage currentWritten
             * @property {number|null} [currentConsumed] Storage currentConsumed
             * @property {number|null} [currentDiscarded] Storage currentDiscarded
             */

            /**
             * Constructs a new Storage.
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse
             * @classdesc Represents a Storage.
             * @implements IStorage
             * @constructor
             * @param {ProtobufEventPayloadDataBlock.DiagnosticResponse.IStorage=} [properties] Properties to set
             */
            function Storage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Storage capacity.
             * @member {number} capacity
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @instance
             */
            Storage.prototype.capacity = 0;

            /**
             * Storage currentSize.
             * @member {number} currentSize
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @instance
             */
            Storage.prototype.currentSize = 0;

            /**
             * Storage currentWritten.
             * @member {number} currentWritten
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @instance
             */
            Storage.prototype.currentWritten = 0;

            /**
             * Storage currentConsumed.
             * @member {number} currentConsumed
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @instance
             */
            Storage.prototype.currentConsumed = 0;

            /**
             * Storage currentDiscarded.
             * @member {number} currentDiscarded
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @instance
             */
            Storage.prototype.currentDiscarded = 0;

            /**
             * Creates a new Storage instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {ProtobufEventPayloadDataBlock.DiagnosticResponse.IStorage=} [properties] Properties to set
             * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage} Storage instance
             */
            Storage.create = function create(properties) {
                return new Storage(properties);
            };

            /**
             * Encodes the specified Storage message. Does not implicitly {@link ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {ProtobufEventPayloadDataBlock.DiagnosticResponse.IStorage} message Storage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Storage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.capacity);
                if (message.currentSize != null && Object.hasOwnProperty.call(message, "currentSize"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.currentSize);
                if (message.currentWritten != null && Object.hasOwnProperty.call(message, "currentWritten"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.currentWritten);
                if (message.currentConsumed != null && Object.hasOwnProperty.call(message, "currentConsumed"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.currentConsumed);
                if (message.currentDiscarded != null && Object.hasOwnProperty.call(message, "currentDiscarded"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.currentDiscarded);
                return writer;
            };

            /**
             * Encodes the specified Storage message, length delimited. Does not implicitly {@link ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {ProtobufEventPayloadDataBlock.DiagnosticResponse.IStorage} message Storage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Storage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Storage message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage} Storage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Storage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.capacity = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.currentSize = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.currentWritten = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.currentConsumed = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.currentDiscarded = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Storage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage} Storage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Storage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Storage message.
             * @function verify
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Storage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.capacity != null && message.hasOwnProperty("capacity"))
                    if (!$util.isInteger(message.capacity))
                        return "capacity: integer expected";
                if (message.currentSize != null && message.hasOwnProperty("currentSize"))
                    if (!$util.isInteger(message.currentSize))
                        return "currentSize: integer expected";
                if (message.currentWritten != null && message.hasOwnProperty("currentWritten"))
                    if (!$util.isInteger(message.currentWritten))
                        return "currentWritten: integer expected";
                if (message.currentConsumed != null && message.hasOwnProperty("currentConsumed"))
                    if (!$util.isInteger(message.currentConsumed))
                        return "currentConsumed: integer expected";
                if (message.currentDiscarded != null && message.hasOwnProperty("currentDiscarded"))
                    if (!$util.isInteger(message.currentDiscarded))
                        return "currentDiscarded: integer expected";
                return null;
            };

            /**
             * Creates a Storage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage} Storage
             */
            Storage.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage)
                    return object;
                var message = new $root.ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage();
                if (object.capacity != null)
                    message.capacity = object.capacity >>> 0;
                if (object.currentSize != null)
                    message.currentSize = object.currentSize >>> 0;
                if (object.currentWritten != null)
                    message.currentWritten = object.currentWritten >>> 0;
                if (object.currentConsumed != null)
                    message.currentConsumed = object.currentConsumed >>> 0;
                if (object.currentDiscarded != null)
                    message.currentDiscarded = object.currentDiscarded >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Storage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage} message Storage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Storage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.capacity = 0;
                    object.currentSize = 0;
                    object.currentWritten = 0;
                    object.currentConsumed = 0;
                    object.currentDiscarded = 0;
                }
                if (message.capacity != null && message.hasOwnProperty("capacity"))
                    object.capacity = message.capacity;
                if (message.currentSize != null && message.hasOwnProperty("currentSize"))
                    object.currentSize = message.currentSize;
                if (message.currentWritten != null && message.hasOwnProperty("currentWritten"))
                    object.currentWritten = message.currentWritten;
                if (message.currentConsumed != null && message.hasOwnProperty("currentConsumed"))
                    object.currentConsumed = message.currentConsumed;
                if (message.currentDiscarded != null && message.hasOwnProperty("currentDiscarded"))
                    object.currentDiscarded = message.currentDiscarded;
                return object;
            };

            /**
             * Converts this Storage to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Storage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Storage
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Storage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadDataBlock.DiagnosticResponse.Storage";
            };

            return Storage;
        })();

        return DiagnosticResponse;
    })();

    return ProtobufEventPayloadDataBlock;
})();

$root.ProtobufEventPayloadSatelliteNetwork = (function() {

    /**
     * Properties of a ProtobufEventPayloadSatelliteNetwork.
     * @exports IProtobufEventPayloadSatelliteNetwork
     * @interface IProtobufEventPayloadSatelliteNetwork
     * @property {ProtobufEventPayloadSatelliteNetwork.IDiagnosticRequest|null} [diagnosticRequest] ProtobufEventPayloadSatelliteNetwork diagnosticRequest
     * @property {ProtobufEventPayloadSatelliteNetwork.IDiagnosticResponse|null} [diagnosticResponse] ProtobufEventPayloadSatelliteNetwork diagnosticResponse
     */

    /**
     * Constructs a new ProtobufEventPayloadSatelliteNetwork.
     * @exports ProtobufEventPayloadSatelliteNetwork
     * @classdesc Represents a ProtobufEventPayloadSatelliteNetwork.
     * @implements IProtobufEventPayloadSatelliteNetwork
     * @constructor
     * @param {IProtobufEventPayloadSatelliteNetwork=} [properties] Properties to set
     */
    function ProtobufEventPayloadSatelliteNetwork(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadSatelliteNetwork diagnosticRequest.
     * @member {ProtobufEventPayloadSatelliteNetwork.IDiagnosticRequest|null|undefined} diagnosticRequest
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @instance
     */
    ProtobufEventPayloadSatelliteNetwork.prototype.diagnosticRequest = null;

    /**
     * ProtobufEventPayloadSatelliteNetwork diagnosticResponse.
     * @member {ProtobufEventPayloadSatelliteNetwork.IDiagnosticResponse|null|undefined} diagnosticResponse
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @instance
     */
    ProtobufEventPayloadSatelliteNetwork.prototype.diagnosticResponse = null;

    /**
     * Creates a new ProtobufEventPayloadSatelliteNetwork instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {IProtobufEventPayloadSatelliteNetwork=} [properties] Properties to set
     * @returns {ProtobufEventPayloadSatelliteNetwork} ProtobufEventPayloadSatelliteNetwork instance
     */
    ProtobufEventPayloadSatelliteNetwork.create = function create(properties) {
        return new ProtobufEventPayloadSatelliteNetwork(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadSatelliteNetwork message. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {IProtobufEventPayloadSatelliteNetwork} message ProtobufEventPayloadSatelliteNetwork message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSatelliteNetwork.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.diagnosticRequest != null && Object.hasOwnProperty.call(message, "diagnosticRequest"))
            $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest.encode(message.diagnosticRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.diagnosticResponse != null && Object.hasOwnProperty.call(message, "diagnosticResponse"))
            $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.encode(message.diagnosticResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadSatelliteNetwork message, length delimited. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {IProtobufEventPayloadSatelliteNetwork} message ProtobufEventPayloadSatelliteNetwork message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadSatelliteNetwork.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadSatelliteNetwork message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadSatelliteNetwork} ProtobufEventPayloadSatelliteNetwork
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSatelliteNetwork.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSatelliteNetwork();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.diagnosticRequest = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.diagnosticResponse = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadSatelliteNetwork message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadSatelliteNetwork} ProtobufEventPayloadSatelliteNetwork
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadSatelliteNetwork.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadSatelliteNetwork message.
     * @function verify
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadSatelliteNetwork.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest")) {
            var error = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest.verify(message.diagnosticRequest);
            if (error)
                return "diagnosticRequest." + error;
        }
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse")) {
            var error = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.verify(message.diagnosticResponse);
            if (error)
                return "diagnosticResponse." + error;
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadSatelliteNetwork message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadSatelliteNetwork} ProtobufEventPayloadSatelliteNetwork
     */
    ProtobufEventPayloadSatelliteNetwork.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadSatelliteNetwork)
            return object;
        var message = new $root.ProtobufEventPayloadSatelliteNetwork();
        if (object.diagnosticRequest != null) {
            if (typeof object.diagnosticRequest !== "object")
                throw TypeError(".ProtobufEventPayloadSatelliteNetwork.diagnosticRequest: object expected");
            message.diagnosticRequest = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest.fromObject(object.diagnosticRequest);
        }
        if (object.diagnosticResponse != null) {
            if (typeof object.diagnosticResponse !== "object")
                throw TypeError(".ProtobufEventPayloadSatelliteNetwork.diagnosticResponse: object expected");
            message.diagnosticResponse = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.fromObject(object.diagnosticResponse);
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadSatelliteNetwork message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {ProtobufEventPayloadSatelliteNetwork} message ProtobufEventPayloadSatelliteNetwork
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadSatelliteNetwork.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.diagnosticRequest = null;
            object.diagnosticResponse = null;
        }
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest"))
            object.diagnosticRequest = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest.toObject(message.diagnosticRequest, options);
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse"))
            object.diagnosticResponse = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.toObject(message.diagnosticResponse, options);
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadSatelliteNetwork to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadSatelliteNetwork.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadSatelliteNetwork
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadSatelliteNetwork
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadSatelliteNetwork.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadSatelliteNetwork";
    };

    ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest = (function() {

        /**
         * Properties of a DiagnosticRequest.
         * @memberof ProtobufEventPayloadSatelliteNetwork
         * @interface IDiagnosticRequest
         * @property {number|null} [maxSummaries] DiagnosticRequest maxSummaries
         * @property {boolean|null} [signalQuality] DiagnosticRequest signalQuality
         * @property {boolean|null} [transmissions] DiagnosticRequest transmissions
         */

        /**
         * Constructs a new DiagnosticRequest.
         * @memberof ProtobufEventPayloadSatelliteNetwork
         * @classdesc Represents a DiagnosticRequest.
         * @implements IDiagnosticRequest
         * @constructor
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticRequest=} [properties] Properties to set
         */
        function DiagnosticRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticRequest maxSummaries.
         * @member {number} maxSummaries
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.maxSummaries = 0;

        /**
         * DiagnosticRequest signalQuality.
         * @member {boolean} signalQuality
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.signalQuality = false;

        /**
         * DiagnosticRequest transmissions.
         * @member {boolean} transmissions
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.transmissions = false;

        /**
         * Creates a new DiagnosticRequest instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticRequest=} [properties] Properties to set
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest} DiagnosticRequest instance
         */
        DiagnosticRequest.create = function create(properties) {
            return new DiagnosticRequest(properties);
        };

        /**
         * Encodes the specified DiagnosticRequest message. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maxSummaries != null && Object.hasOwnProperty.call(message, "maxSummaries"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.maxSummaries);
            if (message.signalQuality != null && Object.hasOwnProperty.call(message, "signalQuality"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.signalQuality);
            if (message.transmissions != null && Object.hasOwnProperty.call(message, "transmissions"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.transmissions);
            return writer;
        };

        /**
         * Encodes the specified DiagnosticRequest message, length delimited. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxSummaries = reader.uint32();
                        break;
                    }
                case 2: {
                        message.signalQuality = reader.bool();
                        break;
                    }
                case 3: {
                        message.transmissions = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticRequest message.
         * @function verify
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                if (!$util.isInteger(message.maxSummaries))
                    return "maxSummaries: integer expected";
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality"))
                if (typeof message.signalQuality !== "boolean")
                    return "signalQuality: boolean expected";
            if (message.transmissions != null && message.hasOwnProperty("transmissions"))
                if (typeof message.transmissions !== "boolean")
                    return "transmissions: boolean expected";
            return null;
        };

        /**
         * Creates a DiagnosticRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest} DiagnosticRequest
         */
        DiagnosticRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest)
                return object;
            var message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest();
            if (object.maxSummaries != null)
                message.maxSummaries = object.maxSummaries >>> 0;
            if (object.signalQuality != null)
                message.signalQuality = Boolean(object.signalQuality);
            if (object.transmissions != null)
                message.transmissions = Boolean(object.transmissions);
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest} message DiagnosticRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.maxSummaries = 0;
                object.signalQuality = false;
                object.transmissions = false;
            }
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                object.maxSummaries = message.maxSummaries;
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality"))
                object.signalQuality = message.signalQuality;
            if (message.transmissions != null && message.hasOwnProperty("transmissions"))
                object.transmissions = message.transmissions;
            return object;
        };

        /**
         * Converts this DiagnosticRequest to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticRequest
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadSatelliteNetwork.DiagnosticRequest";
        };

        return DiagnosticRequest;
    })();

    ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse = (function() {

        /**
         * Properties of a DiagnosticResponse.
         * @memberof ProtobufEventPayloadSatelliteNetwork
         * @interface IDiagnosticResponse
         * @property {number|null} [currentUptimeMs] DiagnosticResponse currentUptimeMs
         * @property {number|null} [summaryUptimeMs] DiagnosticResponse summaryUptimeMs
         * @property {number|null} [summaryPeriodS] DiagnosticResponse summaryPeriodS
         * @property {number|null} [numSummaries] DiagnosticResponse numSummaries
         * @property {Uint8Array|null} [summaries] DiagnosticResponse summaries
         * @property {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ISignalQuality|null} [signalQuality] DiagnosticResponse signalQuality
         * @property {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ITransmissions|null} [transmissions] DiagnosticResponse transmissions
         * @property {Array.<number>|null} [internalAntennaUptimeMs] DiagnosticResponse internalAntennaUptimeMs
         * @property {Array.<number>|null} [externalAntennaUptimeMs] DiagnosticResponse externalAntennaUptimeMs
         */

        /**
         * Constructs a new DiagnosticResponse.
         * @memberof ProtobufEventPayloadSatelliteNetwork
         * @classdesc Represents a DiagnosticResponse.
         * @implements IDiagnosticResponse
         * @constructor
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticResponse=} [properties] Properties to set
         */
        function DiagnosticResponse(properties) {
            this.internalAntennaUptimeMs = [];
            this.externalAntennaUptimeMs = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticResponse currentUptimeMs.
         * @member {number} currentUptimeMs
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.currentUptimeMs = 0;

        /**
         * DiagnosticResponse summaryUptimeMs.
         * @member {number} summaryUptimeMs
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryUptimeMs = 0;

        /**
         * DiagnosticResponse summaryPeriodS.
         * @member {number} summaryPeriodS
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryPeriodS = 0;

        /**
         * DiagnosticResponse numSummaries.
         * @member {number} numSummaries
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.numSummaries = 0;

        /**
         * DiagnosticResponse summaries.
         * @member {Uint8Array} summaries
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaries = $util.newBuffer([]);

        /**
         * DiagnosticResponse signalQuality.
         * @member {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ISignalQuality|null|undefined} signalQuality
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.signalQuality = null;

        /**
         * DiagnosticResponse transmissions.
         * @member {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ITransmissions|null|undefined} transmissions
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.transmissions = null;

        /**
         * DiagnosticResponse internalAntennaUptimeMs.
         * @member {Array.<number>} internalAntennaUptimeMs
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.internalAntennaUptimeMs = $util.emptyArray;

        /**
         * DiagnosticResponse externalAntennaUptimeMs.
         * @member {Array.<number>} externalAntennaUptimeMs
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.externalAntennaUptimeMs = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DiagnosticResponse type.
         * @member {"signalQuality"|"transmissions"|undefined} type
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         */
        Object.defineProperty(DiagnosticResponse.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["signalQuality", "transmissions"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DiagnosticResponse instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticResponse=} [properties] Properties to set
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse} DiagnosticResponse instance
         */
        DiagnosticResponse.create = function create(properties) {
            return new DiagnosticResponse(properties);
        };

        /**
         * Encodes the specified DiagnosticResponse message. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentUptimeMs != null && Object.hasOwnProperty.call(message, "currentUptimeMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentUptimeMs);
            if (message.summaryUptimeMs != null && Object.hasOwnProperty.call(message, "summaryUptimeMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.summaryUptimeMs);
            if (message.summaryPeriodS != null && Object.hasOwnProperty.call(message, "summaryPeriodS"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.summaryPeriodS);
            if (message.numSummaries != null && Object.hasOwnProperty.call(message, "numSummaries"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numSummaries);
            if (message.summaries != null && Object.hasOwnProperty.call(message, "summaries"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.summaries);
            if (message.signalQuality != null && Object.hasOwnProperty.call(message, "signalQuality"))
                $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality.encode(message.signalQuality, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.transmissions != null && Object.hasOwnProperty.call(message, "transmissions"))
                $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions.encode(message.transmissions, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.internalAntennaUptimeMs != null && message.internalAntennaUptimeMs.length)
                for (var i = 0; i < message.internalAntennaUptimeMs.length; ++i)
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.internalAntennaUptimeMs[i]);
            if (message.externalAntennaUptimeMs != null && message.externalAntennaUptimeMs.length)
                for (var i = 0; i < message.externalAntennaUptimeMs.length; ++i)
                    writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.externalAntennaUptimeMs[i]);
            return writer;
        };

        /**
         * Encodes the specified DiagnosticResponse message, length delimited. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentUptimeMs = reader.uint32();
                        break;
                    }
                case 2: {
                        message.summaryUptimeMs = reader.uint32();
                        break;
                    }
                case 3: {
                        message.summaryPeriodS = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numSummaries = reader.uint32();
                        break;
                    }
                case 5: {
                        message.summaries = reader.bytes();
                        break;
                    }
                case 6: {
                        message.signalQuality = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.transmissions = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        if (!(message.internalAntennaUptimeMs && message.internalAntennaUptimeMs.length))
                            message.internalAntennaUptimeMs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.internalAntennaUptimeMs.push(reader.uint32());
                        } else
                            message.internalAntennaUptimeMs.push(reader.uint32());
                        break;
                    }
                case 11: {
                        if (!(message.externalAntennaUptimeMs && message.externalAntennaUptimeMs.length))
                            message.externalAntennaUptimeMs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.externalAntennaUptimeMs.push(reader.uint32());
                        } else
                            message.externalAntennaUptimeMs.push(reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticResponse message.
         * @function verify
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                if (!$util.isInteger(message.currentUptimeMs))
                    return "currentUptimeMs: integer expected";
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                if (!$util.isInteger(message.summaryUptimeMs))
                    return "summaryUptimeMs: integer expected";
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                if (!$util.isInteger(message.summaryPeriodS))
                    return "summaryPeriodS: integer expected";
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                if (!$util.isInteger(message.numSummaries))
                    return "numSummaries: integer expected";
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                if (!(message.summaries && typeof message.summaries.length === "number" || $util.isString(message.summaries)))
                    return "summaries: buffer expected";
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality")) {
                properties.type = 1;
                {
                    var error = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality.verify(message.signalQuality);
                    if (error)
                        return "signalQuality." + error;
                }
            }
            if (message.transmissions != null && message.hasOwnProperty("transmissions")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    var error = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions.verify(message.transmissions);
                    if (error)
                        return "transmissions." + error;
                }
            }
            if (message.internalAntennaUptimeMs != null && message.hasOwnProperty("internalAntennaUptimeMs")) {
                if (!Array.isArray(message.internalAntennaUptimeMs))
                    return "internalAntennaUptimeMs: array expected";
                for (var i = 0; i < message.internalAntennaUptimeMs.length; ++i)
                    if (!$util.isInteger(message.internalAntennaUptimeMs[i]))
                        return "internalAntennaUptimeMs: integer[] expected";
            }
            if (message.externalAntennaUptimeMs != null && message.hasOwnProperty("externalAntennaUptimeMs")) {
                if (!Array.isArray(message.externalAntennaUptimeMs))
                    return "externalAntennaUptimeMs: array expected";
                for (var i = 0; i < message.externalAntennaUptimeMs.length; ++i)
                    if (!$util.isInteger(message.externalAntennaUptimeMs[i]))
                        return "externalAntennaUptimeMs: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a DiagnosticResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse} DiagnosticResponse
         */
        DiagnosticResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse)
                return object;
            var message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse();
            if (object.currentUptimeMs != null)
                message.currentUptimeMs = object.currentUptimeMs >>> 0;
            if (object.summaryUptimeMs != null)
                message.summaryUptimeMs = object.summaryUptimeMs >>> 0;
            if (object.summaryPeriodS != null)
                message.summaryPeriodS = object.summaryPeriodS >>> 0;
            if (object.numSummaries != null)
                message.numSummaries = object.numSummaries >>> 0;
            if (object.summaries != null)
                if (typeof object.summaries === "string")
                    $util.base64.decode(object.summaries, message.summaries = $util.newBuffer($util.base64.length(object.summaries)), 0);
                else if (object.summaries.length >= 0)
                    message.summaries = object.summaries;
            if (object.signalQuality != null) {
                if (typeof object.signalQuality !== "object")
                    throw TypeError(".ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.signalQuality: object expected");
                message.signalQuality = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality.fromObject(object.signalQuality);
            }
            if (object.transmissions != null) {
                if (typeof object.transmissions !== "object")
                    throw TypeError(".ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.transmissions: object expected");
                message.transmissions = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions.fromObject(object.transmissions);
            }
            if (object.internalAntennaUptimeMs) {
                if (!Array.isArray(object.internalAntennaUptimeMs))
                    throw TypeError(".ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.internalAntennaUptimeMs: array expected");
                message.internalAntennaUptimeMs = [];
                for (var i = 0; i < object.internalAntennaUptimeMs.length; ++i)
                    message.internalAntennaUptimeMs[i] = object.internalAntennaUptimeMs[i] >>> 0;
            }
            if (object.externalAntennaUptimeMs) {
                if (!Array.isArray(object.externalAntennaUptimeMs))
                    throw TypeError(".ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.externalAntennaUptimeMs: array expected");
                message.externalAntennaUptimeMs = [];
                for (var i = 0; i < object.externalAntennaUptimeMs.length; ++i)
                    message.externalAntennaUptimeMs[i] = object.externalAntennaUptimeMs[i] >>> 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse} message DiagnosticResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.internalAntennaUptimeMs = [];
                object.externalAntennaUptimeMs = [];
            }
            if (options.defaults) {
                object.currentUptimeMs = 0;
                object.summaryUptimeMs = 0;
                object.summaryPeriodS = 0;
                object.numSummaries = 0;
                if (options.bytes === String)
                    object.summaries = "";
                else {
                    object.summaries = [];
                    if (options.bytes !== Array)
                        object.summaries = $util.newBuffer(object.summaries);
                }
            }
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                object.currentUptimeMs = message.currentUptimeMs;
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                object.summaryUptimeMs = message.summaryUptimeMs;
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                object.summaryPeriodS = message.summaryPeriodS;
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                object.numSummaries = message.numSummaries;
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                object.summaries = options.bytes === String ? $util.base64.encode(message.summaries, 0, message.summaries.length) : options.bytes === Array ? Array.prototype.slice.call(message.summaries) : message.summaries;
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality")) {
                object.signalQuality = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality.toObject(message.signalQuality, options);
                if (options.oneofs)
                    object.type = "signalQuality";
            }
            if (message.transmissions != null && message.hasOwnProperty("transmissions")) {
                object.transmissions = $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions.toObject(message.transmissions, options);
                if (options.oneofs)
                    object.type = "transmissions";
            }
            if (message.internalAntennaUptimeMs && message.internalAntennaUptimeMs.length) {
                object.internalAntennaUptimeMs = [];
                for (var j = 0; j < message.internalAntennaUptimeMs.length; ++j)
                    object.internalAntennaUptimeMs[j] = message.internalAntennaUptimeMs[j];
            }
            if (message.externalAntennaUptimeMs && message.externalAntennaUptimeMs.length) {
                object.externalAntennaUptimeMs = [];
                for (var j = 0; j < message.externalAntennaUptimeMs.length; ++j)
                    object.externalAntennaUptimeMs[j] = message.externalAntennaUptimeMs[j];
            }
            return object;
        };

        /**
         * Converts this DiagnosticResponse to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticResponse
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse";
        };

        DiagnosticResponse.SignalQuality = (function() {

            /**
             * Properties of a SignalQuality.
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
             * @interface ISignalQuality
             * @property {number|null} [currentSignalQuality] SignalQuality currentSignalQuality
             */

            /**
             * Constructs a new SignalQuality.
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
             * @classdesc Represents a SignalQuality.
             * @implements ISignalQuality
             * @constructor
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ISignalQuality=} [properties] Properties to set
             */
            function SignalQuality(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SignalQuality currentSignalQuality.
             * @member {number} currentSignalQuality
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @instance
             */
            SignalQuality.prototype.currentSignalQuality = 0;

            /**
             * Creates a new SignalQuality instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ISignalQuality=} [properties] Properties to set
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality} SignalQuality instance
             */
            SignalQuality.create = function create(properties) {
                return new SignalQuality(properties);
            };

            /**
             * Encodes the specified SignalQuality message. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ISignalQuality} message SignalQuality message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignalQuality.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentSignalQuality != null && Object.hasOwnProperty.call(message, "currentSignalQuality"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentSignalQuality);
                return writer;
            };

            /**
             * Encodes the specified SignalQuality message, length delimited. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ISignalQuality} message SignalQuality message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignalQuality.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SignalQuality message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality} SignalQuality
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignalQuality.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currentSignalQuality = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SignalQuality message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality} SignalQuality
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignalQuality.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SignalQuality message.
             * @function verify
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SignalQuality.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentSignalQuality != null && message.hasOwnProperty("currentSignalQuality"))
                    if (!$util.isInteger(message.currentSignalQuality))
                        return "currentSignalQuality: integer expected";
                return null;
            };

            /**
             * Creates a SignalQuality message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality} SignalQuality
             */
            SignalQuality.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality)
                    return object;
                var message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality();
                if (object.currentSignalQuality != null)
                    message.currentSignalQuality = object.currentSignalQuality >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a SignalQuality message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality} message SignalQuality
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SignalQuality.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.currentSignalQuality = 0;
                if (message.currentSignalQuality != null && message.hasOwnProperty("currentSignalQuality"))
                    object.currentSignalQuality = message.currentSignalQuality;
                return object;
            };

            /**
             * Converts this SignalQuality to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SignalQuality.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SignalQuality
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SignalQuality.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.SignalQuality";
            };

            return SignalQuality;
        })();

        DiagnosticResponse.Transmissions = (function() {

            /**
             * Properties of a Transmissions.
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
             * @interface ITransmissions
             * @property {number|null} [currentSuccess] Transmissions currentSuccess
             * @property {number|null} [currentFailure] Transmissions currentFailure
             * @property {number|null} [currentError] Transmissions currentError
             */

            /**
             * Constructs a new Transmissions.
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse
             * @classdesc Represents a Transmissions.
             * @implements ITransmissions
             * @constructor
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ITransmissions=} [properties] Properties to set
             */
            function Transmissions(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Transmissions currentSuccess.
             * @member {number} currentSuccess
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @instance
             */
            Transmissions.prototype.currentSuccess = 0;

            /**
             * Transmissions currentFailure.
             * @member {number} currentFailure
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @instance
             */
            Transmissions.prototype.currentFailure = 0;

            /**
             * Transmissions currentError.
             * @member {number} currentError
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @instance
             */
            Transmissions.prototype.currentError = 0;

            /**
             * Creates a new Transmissions instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ITransmissions=} [properties] Properties to set
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions} Transmissions instance
             */
            Transmissions.create = function create(properties) {
                return new Transmissions(properties);
            };

            /**
             * Encodes the specified Transmissions message. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ITransmissions} message Transmissions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Transmissions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentSuccess != null && Object.hasOwnProperty.call(message, "currentSuccess"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentSuccess);
                if (message.currentFailure != null && Object.hasOwnProperty.call(message, "currentFailure"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.currentFailure);
                if (message.currentError != null && Object.hasOwnProperty.call(message, "currentError"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.currentError);
                return writer;
            };

            /**
             * Encodes the specified Transmissions message, length delimited. Does not implicitly {@link ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.ITransmissions} message Transmissions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Transmissions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Transmissions message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions} Transmissions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Transmissions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currentSuccess = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.currentFailure = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.currentError = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Transmissions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions} Transmissions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Transmissions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Transmissions message.
             * @function verify
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Transmissions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentSuccess != null && message.hasOwnProperty("currentSuccess"))
                    if (!$util.isInteger(message.currentSuccess))
                        return "currentSuccess: integer expected";
                if (message.currentFailure != null && message.hasOwnProperty("currentFailure"))
                    if (!$util.isInteger(message.currentFailure))
                        return "currentFailure: integer expected";
                if (message.currentError != null && message.hasOwnProperty("currentError"))
                    if (!$util.isInteger(message.currentError))
                        return "currentError: integer expected";
                return null;
            };

            /**
             * Creates a Transmissions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions} Transmissions
             */
            Transmissions.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions)
                    return object;
                var message = new $root.ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions();
                if (object.currentSuccess != null)
                    message.currentSuccess = object.currentSuccess >>> 0;
                if (object.currentFailure != null)
                    message.currentFailure = object.currentFailure >>> 0;
                if (object.currentError != null)
                    message.currentError = object.currentError >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Transmissions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions} message Transmissions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Transmissions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currentSuccess = 0;
                    object.currentFailure = 0;
                    object.currentError = 0;
                }
                if (message.currentSuccess != null && message.hasOwnProperty("currentSuccess"))
                    object.currentSuccess = message.currentSuccess;
                if (message.currentFailure != null && message.hasOwnProperty("currentFailure"))
                    object.currentFailure = message.currentFailure;
                if (message.currentError != null && message.hasOwnProperty("currentError"))
                    object.currentError = message.currentError;
                return object;
            };

            /**
             * Converts this Transmissions to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Transmissions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Transmissions
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Transmissions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadSatelliteNetwork.DiagnosticResponse.Transmissions";
            };

            return Transmissions;
        })();

        return DiagnosticResponse;
    })();

    return ProtobufEventPayloadSatelliteNetwork;
})();

$root.ProtobufEventPayloadCellularNetwork = (function() {

    /**
     * Properties of a ProtobufEventPayloadCellularNetwork.
     * @exports IProtobufEventPayloadCellularNetwork
     * @interface IProtobufEventPayloadCellularNetwork
     * @property {ProtobufEventPayloadCellularNetwork.IDiagnosticRequest|null} [diagnosticRequest] ProtobufEventPayloadCellularNetwork diagnosticRequest
     * @property {ProtobufEventPayloadCellularNetwork.IDiagnosticResponse|null} [diagnosticResponse] ProtobufEventPayloadCellularNetwork diagnosticResponse
     */

    /**
     * Constructs a new ProtobufEventPayloadCellularNetwork.
     * @exports ProtobufEventPayloadCellularNetwork
     * @classdesc Represents a ProtobufEventPayloadCellularNetwork.
     * @implements IProtobufEventPayloadCellularNetwork
     * @constructor
     * @param {IProtobufEventPayloadCellularNetwork=} [properties] Properties to set
     */
    function ProtobufEventPayloadCellularNetwork(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadCellularNetwork diagnosticRequest.
     * @member {ProtobufEventPayloadCellularNetwork.IDiagnosticRequest|null|undefined} diagnosticRequest
     * @memberof ProtobufEventPayloadCellularNetwork
     * @instance
     */
    ProtobufEventPayloadCellularNetwork.prototype.diagnosticRequest = null;

    /**
     * ProtobufEventPayloadCellularNetwork diagnosticResponse.
     * @member {ProtobufEventPayloadCellularNetwork.IDiagnosticResponse|null|undefined} diagnosticResponse
     * @memberof ProtobufEventPayloadCellularNetwork
     * @instance
     */
    ProtobufEventPayloadCellularNetwork.prototype.diagnosticResponse = null;

    /**
     * Creates a new ProtobufEventPayloadCellularNetwork instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {IProtobufEventPayloadCellularNetwork=} [properties] Properties to set
     * @returns {ProtobufEventPayloadCellularNetwork} ProtobufEventPayloadCellularNetwork instance
     */
    ProtobufEventPayloadCellularNetwork.create = function create(properties) {
        return new ProtobufEventPayloadCellularNetwork(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadCellularNetwork message. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {IProtobufEventPayloadCellularNetwork} message ProtobufEventPayloadCellularNetwork message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadCellularNetwork.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.diagnosticRequest != null && Object.hasOwnProperty.call(message, "diagnosticRequest"))
            $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest.encode(message.diagnosticRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.diagnosticResponse != null && Object.hasOwnProperty.call(message, "diagnosticResponse"))
            $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.encode(message.diagnosticResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadCellularNetwork message, length delimited. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {IProtobufEventPayloadCellularNetwork} message ProtobufEventPayloadCellularNetwork message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadCellularNetwork.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadCellularNetwork message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadCellularNetwork} ProtobufEventPayloadCellularNetwork
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadCellularNetwork.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadCellularNetwork();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.diagnosticRequest = $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.diagnosticResponse = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadCellularNetwork message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadCellularNetwork} ProtobufEventPayloadCellularNetwork
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadCellularNetwork.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadCellularNetwork message.
     * @function verify
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadCellularNetwork.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest")) {
            var error = $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest.verify(message.diagnosticRequest);
            if (error)
                return "diagnosticRequest." + error;
        }
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse")) {
            var error = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.verify(message.diagnosticResponse);
            if (error)
                return "diagnosticResponse." + error;
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadCellularNetwork message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadCellularNetwork} ProtobufEventPayloadCellularNetwork
     */
    ProtobufEventPayloadCellularNetwork.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadCellularNetwork)
            return object;
        var message = new $root.ProtobufEventPayloadCellularNetwork();
        if (object.diagnosticRequest != null) {
            if (typeof object.diagnosticRequest !== "object")
                throw TypeError(".ProtobufEventPayloadCellularNetwork.diagnosticRequest: object expected");
            message.diagnosticRequest = $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest.fromObject(object.diagnosticRequest);
        }
        if (object.diagnosticResponse != null) {
            if (typeof object.diagnosticResponse !== "object")
                throw TypeError(".ProtobufEventPayloadCellularNetwork.diagnosticResponse: object expected");
            message.diagnosticResponse = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.fromObject(object.diagnosticResponse);
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadCellularNetwork message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {ProtobufEventPayloadCellularNetwork} message ProtobufEventPayloadCellularNetwork
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadCellularNetwork.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.diagnosticRequest = null;
            object.diagnosticResponse = null;
        }
        if (message.diagnosticRequest != null && message.hasOwnProperty("diagnosticRequest"))
            object.diagnosticRequest = $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest.toObject(message.diagnosticRequest, options);
        if (message.diagnosticResponse != null && message.hasOwnProperty("diagnosticResponse"))
            object.diagnosticResponse = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.toObject(message.diagnosticResponse, options);
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadCellularNetwork to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadCellularNetwork
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadCellularNetwork.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadCellularNetwork
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadCellularNetwork
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadCellularNetwork.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadCellularNetwork";
    };

    /**
     * RadioAccessTechnology enum.
     * @name ProtobufEventPayloadCellularNetwork.RadioAccessTechnology
     * @enum {number}
     * @property {number} kNoTechnology=0 kNoTechnology value
     * @property {number} k2G=1 k2G value
     * @property {number} k3G=2 k3G value
     * @property {number} k4G=3 k4G value
     */
    ProtobufEventPayloadCellularNetwork.RadioAccessTechnology = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNoTechnology"] = 0;
        values[valuesById[1] = "k2G"] = 1;
        values[valuesById[2] = "k3G"] = 2;
        values[valuesById[3] = "k4G"] = 3;
        return values;
    })();

    /**
     * RegistrationState enum.
     * @name ProtobufEventPayloadCellularNetwork.RegistrationState
     * @enum {number}
     * @property {number} kNotRegistered=0 kNotRegistered value
     * @property {number} kRegistered=1 kRegistered value
     * @property {number} kSearching=2 kSearching value
     * @property {number} kDenied=3 kDenied value
     * @property {number} kOutOfCoverage=4 kOutOfCoverage value
     * @property {number} kRoaming=5 kRoaming value
     * @property {number} kEmergencyOnly=8 kEmergencyOnly value
     */
    ProtobufEventPayloadCellularNetwork.RegistrationState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNotRegistered"] = 0;
        values[valuesById[1] = "kRegistered"] = 1;
        values[valuesById[2] = "kSearching"] = 2;
        values[valuesById[3] = "kDenied"] = 3;
        values[valuesById[4] = "kOutOfCoverage"] = 4;
        values[valuesById[5] = "kRoaming"] = 5;
        values[valuesById[8] = "kEmergencyOnly"] = 8;
        return values;
    })();

    /**
     * NetworkState enum.
     * @name ProtobufEventPayloadCellularNetwork.NetworkState
     * @enum {number}
     * @property {number} kSearch=0 kSearch value
     * @property {number} kIdle=1 kIdle value
     * @property {number} kConnected=2 kConnected value
     * @property {number} kLimitedService=3 kLimitedService value
     */
    ProtobufEventPayloadCellularNetwork.NetworkState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kSearch"] = 0;
        values[valuesById[1] = "kIdle"] = 1;
        values[valuesById[2] = "kConnected"] = 2;
        values[valuesById[3] = "kLimitedService"] = 3;
        return values;
    })();

    ProtobufEventPayloadCellularNetwork.DiagnosticRequest = (function() {

        /**
         * Properties of a DiagnosticRequest.
         * @memberof ProtobufEventPayloadCellularNetwork
         * @interface IDiagnosticRequest
         * @property {number|null} [maxSummaries] DiagnosticRequest maxSummaries
         * @property {boolean|null} [signalQuality] DiagnosticRequest signalQuality
         * @property {boolean|null} [traffic] DiagnosticRequest traffic
         */

        /**
         * Constructs a new DiagnosticRequest.
         * @memberof ProtobufEventPayloadCellularNetwork
         * @classdesc Represents a DiagnosticRequest.
         * @implements IDiagnosticRequest
         * @constructor
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticRequest=} [properties] Properties to set
         */
        function DiagnosticRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticRequest maxSummaries.
         * @member {number} maxSummaries
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.maxSummaries = 0;

        /**
         * DiagnosticRequest signalQuality.
         * @member {boolean} signalQuality
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.signalQuality = false;

        /**
         * DiagnosticRequest traffic.
         * @member {boolean} traffic
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @instance
         */
        DiagnosticRequest.prototype.traffic = false;

        /**
         * Creates a new DiagnosticRequest instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticRequest=} [properties] Properties to set
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticRequest} DiagnosticRequest instance
         */
        DiagnosticRequest.create = function create(properties) {
            return new DiagnosticRequest(properties);
        };

        /**
         * Encodes the specified DiagnosticRequest message. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticRequest.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maxSummaries != null && Object.hasOwnProperty.call(message, "maxSummaries"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.maxSummaries);
            if (message.signalQuality != null && Object.hasOwnProperty.call(message, "signalQuality"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.signalQuality);
            if (message.traffic != null && Object.hasOwnProperty.call(message, "traffic"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.traffic);
            return writer;
        };

        /**
         * Encodes the specified DiagnosticRequest message, length delimited. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticRequest} message DiagnosticRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxSummaries = reader.uint32();
                        break;
                    }
                case 2: {
                        message.signalQuality = reader.bool();
                        break;
                    }
                case 3: {
                        message.traffic = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticRequest} DiagnosticRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticRequest message.
         * @function verify
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                if (!$util.isInteger(message.maxSummaries))
                    return "maxSummaries: integer expected";
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality"))
                if (typeof message.signalQuality !== "boolean")
                    return "signalQuality: boolean expected";
            if (message.traffic != null && message.hasOwnProperty("traffic"))
                if (typeof message.traffic !== "boolean")
                    return "traffic: boolean expected";
            return null;
        };

        /**
         * Creates a DiagnosticRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticRequest} DiagnosticRequest
         */
        DiagnosticRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest)
                return object;
            var message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticRequest();
            if (object.maxSummaries != null)
                message.maxSummaries = object.maxSummaries >>> 0;
            if (object.signalQuality != null)
                message.signalQuality = Boolean(object.signalQuality);
            if (object.traffic != null)
                message.traffic = Boolean(object.traffic);
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.DiagnosticRequest} message DiagnosticRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.maxSummaries = 0;
                object.signalQuality = false;
                object.traffic = false;
            }
            if (message.maxSummaries != null && message.hasOwnProperty("maxSummaries"))
                object.maxSummaries = message.maxSummaries;
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality"))
                object.signalQuality = message.signalQuality;
            if (message.traffic != null && message.hasOwnProperty("traffic"))
                object.traffic = message.traffic;
            return object;
        };

        /**
         * Converts this DiagnosticRequest to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticRequest
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadCellularNetwork.DiagnosticRequest";
        };

        return DiagnosticRequest;
    })();

    ProtobufEventPayloadCellularNetwork.DiagnosticResponse = (function() {

        /**
         * Properties of a DiagnosticResponse.
         * @memberof ProtobufEventPayloadCellularNetwork
         * @interface IDiagnosticResponse
         * @property {number|null} [currentUptimeMs] DiagnosticResponse currentUptimeMs
         * @property {number|null} [summaryUptimeMs] DiagnosticResponse summaryUptimeMs
         * @property {number|null} [summaryPeriodS] DiagnosticResponse summaryPeriodS
         * @property {number|null} [numSummaries] DiagnosticResponse numSummaries
         * @property {Uint8Array|null} [summaries] DiagnosticResponse summaries
         * @property {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ISignalQuality|null} [signalQuality] DiagnosticResponse signalQuality
         * @property {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ITraffic|null} [traffic] DiagnosticResponse traffic
         * @property {Array.<number>|null} [internalAntennaUptimeMs] DiagnosticResponse internalAntennaUptimeMs
         * @property {Array.<number>|null} [externalAntennaUptimeMs] DiagnosticResponse externalAntennaUptimeMs
         * @property {Array.<ProtobufEventPayloadCellularNetwork.DiagnosticResponse.IRegistrationChanges>|null} [registrationChanges] DiagnosticResponse registrationChanges
         * @property {Array.<ProtobufEventPayloadCellularNetwork.DiagnosticResponse.INetworkChanges>|null} [networkChanges] DiagnosticResponse networkChanges
         */

        /**
         * Constructs a new DiagnosticResponse.
         * @memberof ProtobufEventPayloadCellularNetwork
         * @classdesc Represents a DiagnosticResponse.
         * @implements IDiagnosticResponse
         * @constructor
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticResponse=} [properties] Properties to set
         */
        function DiagnosticResponse(properties) {
            this.internalAntennaUptimeMs = [];
            this.externalAntennaUptimeMs = [];
            this.registrationChanges = [];
            this.networkChanges = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DiagnosticResponse currentUptimeMs.
         * @member {number} currentUptimeMs
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.currentUptimeMs = 0;

        /**
         * DiagnosticResponse summaryUptimeMs.
         * @member {number} summaryUptimeMs
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryUptimeMs = 0;

        /**
         * DiagnosticResponse summaryPeriodS.
         * @member {number} summaryPeriodS
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaryPeriodS = 0;

        /**
         * DiagnosticResponse numSummaries.
         * @member {number} numSummaries
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.numSummaries = 0;

        /**
         * DiagnosticResponse summaries.
         * @member {Uint8Array} summaries
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.summaries = $util.newBuffer([]);

        /**
         * DiagnosticResponse signalQuality.
         * @member {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ISignalQuality|null|undefined} signalQuality
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.signalQuality = null;

        /**
         * DiagnosticResponse traffic.
         * @member {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ITraffic|null|undefined} traffic
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.traffic = null;

        /**
         * DiagnosticResponse internalAntennaUptimeMs.
         * @member {Array.<number>} internalAntennaUptimeMs
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.internalAntennaUptimeMs = $util.emptyArray;

        /**
         * DiagnosticResponse externalAntennaUptimeMs.
         * @member {Array.<number>} externalAntennaUptimeMs
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.externalAntennaUptimeMs = $util.emptyArray;

        /**
         * DiagnosticResponse registrationChanges.
         * @member {Array.<ProtobufEventPayloadCellularNetwork.DiagnosticResponse.IRegistrationChanges>} registrationChanges
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.registrationChanges = $util.emptyArray;

        /**
         * DiagnosticResponse networkChanges.
         * @member {Array.<ProtobufEventPayloadCellularNetwork.DiagnosticResponse.INetworkChanges>} networkChanges
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        DiagnosticResponse.prototype.networkChanges = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DiagnosticResponse type.
         * @member {"signalQuality"|"traffic"|undefined} type
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         */
        Object.defineProperty(DiagnosticResponse.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["signalQuality", "traffic"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DiagnosticResponse instance using the specified properties.
         * @function create
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticResponse=} [properties] Properties to set
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse} DiagnosticResponse instance
         */
        DiagnosticResponse.create = function create(properties) {
            return new DiagnosticResponse(properties);
        };

        /**
         * Encodes the specified DiagnosticResponse message. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.verify|verify} messages.
         * @function encode
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentUptimeMs != null && Object.hasOwnProperty.call(message, "currentUptimeMs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentUptimeMs);
            if (message.summaryUptimeMs != null && Object.hasOwnProperty.call(message, "summaryUptimeMs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.summaryUptimeMs);
            if (message.summaryPeriodS != null && Object.hasOwnProperty.call(message, "summaryPeriodS"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.summaryPeriodS);
            if (message.numSummaries != null && Object.hasOwnProperty.call(message, "numSummaries"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numSummaries);
            if (message.summaries != null && Object.hasOwnProperty.call(message, "summaries"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.summaries);
            if (message.signalQuality != null && Object.hasOwnProperty.call(message, "signalQuality"))
                $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality.encode(message.signalQuality, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.traffic != null && Object.hasOwnProperty.call(message, "traffic"))
                $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic.encode(message.traffic, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.internalAntennaUptimeMs != null && message.internalAntennaUptimeMs.length)
                for (var i = 0; i < message.internalAntennaUptimeMs.length; ++i)
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.internalAntennaUptimeMs[i]);
            if (message.externalAntennaUptimeMs != null && message.externalAntennaUptimeMs.length)
                for (var i = 0; i < message.externalAntennaUptimeMs.length; ++i)
                    writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.externalAntennaUptimeMs[i]);
            if (message.registrationChanges != null && message.registrationChanges.length)
                for (var i = 0; i < message.registrationChanges.length; ++i)
                    $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges.encode(message.registrationChanges[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.networkChanges != null && message.networkChanges.length)
                for (var i = 0; i < message.networkChanges.length; ++i)
                    $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges.encode(message.networkChanges[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DiagnosticResponse message, length delimited. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.IDiagnosticResponse} message DiagnosticResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DiagnosticResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer.
         * @function decode
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentUptimeMs = reader.uint32();
                        break;
                    }
                case 2: {
                        message.summaryUptimeMs = reader.uint32();
                        break;
                    }
                case 3: {
                        message.summaryPeriodS = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numSummaries = reader.uint32();
                        break;
                    }
                case 5: {
                        message.summaries = reader.bytes();
                        break;
                    }
                case 6: {
                        message.signalQuality = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.traffic = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        if (!(message.internalAntennaUptimeMs && message.internalAntennaUptimeMs.length))
                            message.internalAntennaUptimeMs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.internalAntennaUptimeMs.push(reader.uint32());
                        } else
                            message.internalAntennaUptimeMs.push(reader.uint32());
                        break;
                    }
                case 11: {
                        if (!(message.externalAntennaUptimeMs && message.externalAntennaUptimeMs.length))
                            message.externalAntennaUptimeMs = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.externalAntennaUptimeMs.push(reader.uint32());
                        } else
                            message.externalAntennaUptimeMs.push(reader.uint32());
                        break;
                    }
                case 12: {
                        if (!(message.registrationChanges && message.registrationChanges.length))
                            message.registrationChanges = [];
                        message.registrationChanges.push($root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges.decode(reader, reader.uint32()));
                        break;
                    }
                case 13: {
                        if (!(message.networkChanges && message.networkChanges.length))
                            message.networkChanges = [];
                        message.networkChanges.push($root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DiagnosticResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse} DiagnosticResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DiagnosticResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DiagnosticResponse message.
         * @function verify
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DiagnosticResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                if (!$util.isInteger(message.currentUptimeMs))
                    return "currentUptimeMs: integer expected";
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                if (!$util.isInteger(message.summaryUptimeMs))
                    return "summaryUptimeMs: integer expected";
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                if (!$util.isInteger(message.summaryPeriodS))
                    return "summaryPeriodS: integer expected";
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                if (!$util.isInteger(message.numSummaries))
                    return "numSummaries: integer expected";
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                if (!(message.summaries && typeof message.summaries.length === "number" || $util.isString(message.summaries)))
                    return "summaries: buffer expected";
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality")) {
                properties.type = 1;
                {
                    var error = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality.verify(message.signalQuality);
                    if (error)
                        return "signalQuality." + error;
                }
            }
            if (message.traffic != null && message.hasOwnProperty("traffic")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    var error = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic.verify(message.traffic);
                    if (error)
                        return "traffic." + error;
                }
            }
            if (message.internalAntennaUptimeMs != null && message.hasOwnProperty("internalAntennaUptimeMs")) {
                if (!Array.isArray(message.internalAntennaUptimeMs))
                    return "internalAntennaUptimeMs: array expected";
                for (var i = 0; i < message.internalAntennaUptimeMs.length; ++i)
                    if (!$util.isInteger(message.internalAntennaUptimeMs[i]))
                        return "internalAntennaUptimeMs: integer[] expected";
            }
            if (message.externalAntennaUptimeMs != null && message.hasOwnProperty("externalAntennaUptimeMs")) {
                if (!Array.isArray(message.externalAntennaUptimeMs))
                    return "externalAntennaUptimeMs: array expected";
                for (var i = 0; i < message.externalAntennaUptimeMs.length; ++i)
                    if (!$util.isInteger(message.externalAntennaUptimeMs[i]))
                        return "externalAntennaUptimeMs: integer[] expected";
            }
            if (message.registrationChanges != null && message.hasOwnProperty("registrationChanges")) {
                if (!Array.isArray(message.registrationChanges))
                    return "registrationChanges: array expected";
                for (var i = 0; i < message.registrationChanges.length; ++i) {
                    var error = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges.verify(message.registrationChanges[i]);
                    if (error)
                        return "registrationChanges." + error;
                }
            }
            if (message.networkChanges != null && message.hasOwnProperty("networkChanges")) {
                if (!Array.isArray(message.networkChanges))
                    return "networkChanges: array expected";
                for (var i = 0; i < message.networkChanges.length; ++i) {
                    var error = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges.verify(message.networkChanges[i]);
                    if (error)
                        return "networkChanges." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DiagnosticResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse} DiagnosticResponse
         */
        DiagnosticResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse)
                return object;
            var message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse();
            if (object.currentUptimeMs != null)
                message.currentUptimeMs = object.currentUptimeMs >>> 0;
            if (object.summaryUptimeMs != null)
                message.summaryUptimeMs = object.summaryUptimeMs >>> 0;
            if (object.summaryPeriodS != null)
                message.summaryPeriodS = object.summaryPeriodS >>> 0;
            if (object.numSummaries != null)
                message.numSummaries = object.numSummaries >>> 0;
            if (object.summaries != null)
                if (typeof object.summaries === "string")
                    $util.base64.decode(object.summaries, message.summaries = $util.newBuffer($util.base64.length(object.summaries)), 0);
                else if (object.summaries.length >= 0)
                    message.summaries = object.summaries;
            if (object.signalQuality != null) {
                if (typeof object.signalQuality !== "object")
                    throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.signalQuality: object expected");
                message.signalQuality = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality.fromObject(object.signalQuality);
            }
            if (object.traffic != null) {
                if (typeof object.traffic !== "object")
                    throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.traffic: object expected");
                message.traffic = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic.fromObject(object.traffic);
            }
            if (object.internalAntennaUptimeMs) {
                if (!Array.isArray(object.internalAntennaUptimeMs))
                    throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.internalAntennaUptimeMs: array expected");
                message.internalAntennaUptimeMs = [];
                for (var i = 0; i < object.internalAntennaUptimeMs.length; ++i)
                    message.internalAntennaUptimeMs[i] = object.internalAntennaUptimeMs[i] >>> 0;
            }
            if (object.externalAntennaUptimeMs) {
                if (!Array.isArray(object.externalAntennaUptimeMs))
                    throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.externalAntennaUptimeMs: array expected");
                message.externalAntennaUptimeMs = [];
                for (var i = 0; i < object.externalAntennaUptimeMs.length; ++i)
                    message.externalAntennaUptimeMs[i] = object.externalAntennaUptimeMs[i] >>> 0;
            }
            if (object.registrationChanges) {
                if (!Array.isArray(object.registrationChanges))
                    throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.registrationChanges: array expected");
                message.registrationChanges = [];
                for (var i = 0; i < object.registrationChanges.length; ++i) {
                    if (typeof object.registrationChanges[i] !== "object")
                        throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.registrationChanges: object expected");
                    message.registrationChanges[i] = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges.fromObject(object.registrationChanges[i]);
                }
            }
            if (object.networkChanges) {
                if (!Array.isArray(object.networkChanges))
                    throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.networkChanges: array expected");
                message.networkChanges = [];
                for (var i = 0; i < object.networkChanges.length; ++i) {
                    if (typeof object.networkChanges[i] !== "object")
                        throw TypeError(".ProtobufEventPayloadCellularNetwork.DiagnosticResponse.networkChanges: object expected");
                    message.networkChanges[i] = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges.fromObject(object.networkChanges[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a DiagnosticResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse} message DiagnosticResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DiagnosticResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.internalAntennaUptimeMs = [];
                object.externalAntennaUptimeMs = [];
                object.registrationChanges = [];
                object.networkChanges = [];
            }
            if (options.defaults) {
                object.currentUptimeMs = 0;
                object.summaryUptimeMs = 0;
                object.summaryPeriodS = 0;
                object.numSummaries = 0;
                if (options.bytes === String)
                    object.summaries = "";
                else {
                    object.summaries = [];
                    if (options.bytes !== Array)
                        object.summaries = $util.newBuffer(object.summaries);
                }
            }
            if (message.currentUptimeMs != null && message.hasOwnProperty("currentUptimeMs"))
                object.currentUptimeMs = message.currentUptimeMs;
            if (message.summaryUptimeMs != null && message.hasOwnProperty("summaryUptimeMs"))
                object.summaryUptimeMs = message.summaryUptimeMs;
            if (message.summaryPeriodS != null && message.hasOwnProperty("summaryPeriodS"))
                object.summaryPeriodS = message.summaryPeriodS;
            if (message.numSummaries != null && message.hasOwnProperty("numSummaries"))
                object.numSummaries = message.numSummaries;
            if (message.summaries != null && message.hasOwnProperty("summaries"))
                object.summaries = options.bytes === String ? $util.base64.encode(message.summaries, 0, message.summaries.length) : options.bytes === Array ? Array.prototype.slice.call(message.summaries) : message.summaries;
            if (message.signalQuality != null && message.hasOwnProperty("signalQuality")) {
                object.signalQuality = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality.toObject(message.signalQuality, options);
                if (options.oneofs)
                    object.type = "signalQuality";
            }
            if (message.traffic != null && message.hasOwnProperty("traffic")) {
                object.traffic = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic.toObject(message.traffic, options);
                if (options.oneofs)
                    object.type = "traffic";
            }
            if (message.internalAntennaUptimeMs && message.internalAntennaUptimeMs.length) {
                object.internalAntennaUptimeMs = [];
                for (var j = 0; j < message.internalAntennaUptimeMs.length; ++j)
                    object.internalAntennaUptimeMs[j] = message.internalAntennaUptimeMs[j];
            }
            if (message.externalAntennaUptimeMs && message.externalAntennaUptimeMs.length) {
                object.externalAntennaUptimeMs = [];
                for (var j = 0; j < message.externalAntennaUptimeMs.length; ++j)
                    object.externalAntennaUptimeMs[j] = message.externalAntennaUptimeMs[j];
            }
            if (message.registrationChanges && message.registrationChanges.length) {
                object.registrationChanges = [];
                for (var j = 0; j < message.registrationChanges.length; ++j)
                    object.registrationChanges[j] = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges.toObject(message.registrationChanges[j], options);
            }
            if (message.networkChanges && message.networkChanges.length) {
                object.networkChanges = [];
                for (var j = 0; j < message.networkChanges.length; ++j)
                    object.networkChanges[j] = $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges.toObject(message.networkChanges[j], options);
            }
            return object;
        };

        /**
         * Converts this DiagnosticResponse to JSON.
         * @function toJSON
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DiagnosticResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DiagnosticResponse
         * @function getTypeUrl
         * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DiagnosticResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ProtobufEventPayloadCellularNetwork.DiagnosticResponse";
        };

        DiagnosticResponse.SignalQuality = (function() {

            /**
             * Properties of a SignalQuality.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @interface ISignalQuality
             * @property {number|null} [currentSignalQuality] SignalQuality currentSignalQuality
             */

            /**
             * Constructs a new SignalQuality.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @classdesc Represents a SignalQuality.
             * @implements ISignalQuality
             * @constructor
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ISignalQuality=} [properties] Properties to set
             */
            function SignalQuality(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SignalQuality currentSignalQuality.
             * @member {number} currentSignalQuality
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @instance
             */
            SignalQuality.prototype.currentSignalQuality = 0;

            /**
             * Creates a new SignalQuality instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ISignalQuality=} [properties] Properties to set
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality} SignalQuality instance
             */
            SignalQuality.create = function create(properties) {
                return new SignalQuality(properties);
            };

            /**
             * Encodes the specified SignalQuality message. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ISignalQuality} message SignalQuality message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignalQuality.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentSignalQuality != null && Object.hasOwnProperty.call(message, "currentSignalQuality"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentSignalQuality);
                return writer;
            };

            /**
             * Encodes the specified SignalQuality message, length delimited. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ISignalQuality} message SignalQuality message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignalQuality.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SignalQuality message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality} SignalQuality
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignalQuality.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currentSignalQuality = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SignalQuality message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality} SignalQuality
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignalQuality.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SignalQuality message.
             * @function verify
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SignalQuality.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentSignalQuality != null && message.hasOwnProperty("currentSignalQuality"))
                    if (!$util.isInteger(message.currentSignalQuality))
                        return "currentSignalQuality: integer expected";
                return null;
            };

            /**
             * Creates a SignalQuality message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality} SignalQuality
             */
            SignalQuality.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality)
                    return object;
                var message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality();
                if (object.currentSignalQuality != null)
                    message.currentSignalQuality = object.currentSignalQuality >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a SignalQuality message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality} message SignalQuality
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SignalQuality.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.currentSignalQuality = 0;
                if (message.currentSignalQuality != null && message.hasOwnProperty("currentSignalQuality"))
                    object.currentSignalQuality = message.currentSignalQuality;
                return object;
            };

            /**
             * Converts this SignalQuality to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SignalQuality.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SignalQuality
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SignalQuality.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadCellularNetwork.DiagnosticResponse.SignalQuality";
            };

            return SignalQuality;
        })();

        DiagnosticResponse.Traffic = (function() {

            /**
             * Properties of a Traffic.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @interface ITraffic
             * @property {number|null} [currentConnectionsMade] Traffic currentConnectionsMade
             * @property {number|null} [currentBytesSent] Traffic currentBytesSent
             * @property {number|null} [currentBytesReceived] Traffic currentBytesReceived
             */

            /**
             * Constructs a new Traffic.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @classdesc Represents a Traffic.
             * @implements ITraffic
             * @constructor
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ITraffic=} [properties] Properties to set
             */
            function Traffic(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Traffic currentConnectionsMade.
             * @member {number} currentConnectionsMade
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @instance
             */
            Traffic.prototype.currentConnectionsMade = 0;

            /**
             * Traffic currentBytesSent.
             * @member {number} currentBytesSent
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @instance
             */
            Traffic.prototype.currentBytesSent = 0;

            /**
             * Traffic currentBytesReceived.
             * @member {number} currentBytesReceived
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @instance
             */
            Traffic.prototype.currentBytesReceived = 0;

            /**
             * Creates a new Traffic instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ITraffic=} [properties] Properties to set
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic} Traffic instance
             */
            Traffic.create = function create(properties) {
                return new Traffic(properties);
            };

            /**
             * Encodes the specified Traffic message. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ITraffic} message Traffic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Traffic.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentConnectionsMade != null && Object.hasOwnProperty.call(message, "currentConnectionsMade"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.currentConnectionsMade);
                if (message.currentBytesSent != null && Object.hasOwnProperty.call(message, "currentBytesSent"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.currentBytesSent);
                if (message.currentBytesReceived != null && Object.hasOwnProperty.call(message, "currentBytesReceived"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.currentBytesReceived);
                return writer;
            };

            /**
             * Encodes the specified Traffic message, length delimited. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.ITraffic} message Traffic message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Traffic.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Traffic message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic} Traffic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Traffic.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currentConnectionsMade = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.currentBytesSent = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.currentBytesReceived = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Traffic message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic} Traffic
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Traffic.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Traffic message.
             * @function verify
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Traffic.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentConnectionsMade != null && message.hasOwnProperty("currentConnectionsMade"))
                    if (!$util.isInteger(message.currentConnectionsMade))
                        return "currentConnectionsMade: integer expected";
                if (message.currentBytesSent != null && message.hasOwnProperty("currentBytesSent"))
                    if (!$util.isInteger(message.currentBytesSent))
                        return "currentBytesSent: integer expected";
                if (message.currentBytesReceived != null && message.hasOwnProperty("currentBytesReceived"))
                    if (!$util.isInteger(message.currentBytesReceived))
                        return "currentBytesReceived: integer expected";
                return null;
            };

            /**
             * Creates a Traffic message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic} Traffic
             */
            Traffic.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic)
                    return object;
                var message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic();
                if (object.currentConnectionsMade != null)
                    message.currentConnectionsMade = object.currentConnectionsMade >>> 0;
                if (object.currentBytesSent != null)
                    message.currentBytesSent = object.currentBytesSent >>> 0;
                if (object.currentBytesReceived != null)
                    message.currentBytesReceived = object.currentBytesReceived >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Traffic message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic} message Traffic
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Traffic.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currentConnectionsMade = 0;
                    object.currentBytesSent = 0;
                    object.currentBytesReceived = 0;
                }
                if (message.currentConnectionsMade != null && message.hasOwnProperty("currentConnectionsMade"))
                    object.currentConnectionsMade = message.currentConnectionsMade;
                if (message.currentBytesSent != null && message.hasOwnProperty("currentBytesSent"))
                    object.currentBytesSent = message.currentBytesSent;
                if (message.currentBytesReceived != null && message.hasOwnProperty("currentBytesReceived"))
                    object.currentBytesReceived = message.currentBytesReceived;
                return object;
            };

            /**
             * Converts this Traffic to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Traffic.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Traffic
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Traffic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadCellularNetwork.DiagnosticResponse.Traffic";
            };

            return Traffic;
        })();

        DiagnosticResponse.RegistrationChanges = (function() {

            /**
             * Properties of a RegistrationChanges.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @interface IRegistrationChanges
             * @property {number|null} [uptimeMs] RegistrationChanges uptimeMs
             * @property {ProtobufEventPayloadCellularNetwork.RadioAccessTechnology|null} [radioAccessTechnology] RegistrationChanges radioAccessTechnology
             * @property {ProtobufEventPayloadCellularNetwork.RegistrationState|null} [registrationState] RegistrationChanges registrationState
             */

            /**
             * Constructs a new RegistrationChanges.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @classdesc Represents a RegistrationChanges.
             * @implements IRegistrationChanges
             * @constructor
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.IRegistrationChanges=} [properties] Properties to set
             */
            function RegistrationChanges(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RegistrationChanges uptimeMs.
             * @member {number} uptimeMs
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @instance
             */
            RegistrationChanges.prototype.uptimeMs = 0;

            /**
             * RegistrationChanges radioAccessTechnology.
             * @member {ProtobufEventPayloadCellularNetwork.RadioAccessTechnology} radioAccessTechnology
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @instance
             */
            RegistrationChanges.prototype.radioAccessTechnology = 0;

            /**
             * RegistrationChanges registrationState.
             * @member {ProtobufEventPayloadCellularNetwork.RegistrationState} registrationState
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @instance
             */
            RegistrationChanges.prototype.registrationState = 0;

            /**
             * Creates a new RegistrationChanges instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.IRegistrationChanges=} [properties] Properties to set
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges} RegistrationChanges instance
             */
            RegistrationChanges.create = function create(properties) {
                return new RegistrationChanges(properties);
            };

            /**
             * Encodes the specified RegistrationChanges message. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.IRegistrationChanges} message RegistrationChanges message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegistrationChanges.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uptimeMs != null && Object.hasOwnProperty.call(message, "uptimeMs"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uptimeMs);
                if (message.radioAccessTechnology != null && Object.hasOwnProperty.call(message, "radioAccessTechnology"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.radioAccessTechnology);
                if (message.registrationState != null && Object.hasOwnProperty.call(message, "registrationState"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.registrationState);
                return writer;
            };

            /**
             * Encodes the specified RegistrationChanges message, length delimited. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.IRegistrationChanges} message RegistrationChanges message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegistrationChanges.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RegistrationChanges message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges} RegistrationChanges
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegistrationChanges.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uptimeMs = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.radioAccessTechnology = reader.int32();
                            break;
                        }
                    case 3: {
                            message.registrationState = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RegistrationChanges message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges} RegistrationChanges
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegistrationChanges.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RegistrationChanges message.
             * @function verify
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegistrationChanges.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                    if (!$util.isInteger(message.uptimeMs))
                        return "uptimeMs: integer expected";
                if (message.radioAccessTechnology != null && message.hasOwnProperty("radioAccessTechnology"))
                    switch (message.radioAccessTechnology) {
                    default:
                        return "radioAccessTechnology: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.registrationState != null && message.hasOwnProperty("registrationState"))
                    switch (message.registrationState) {
                    default:
                        return "registrationState: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 8:
                        break;
                    }
                return null;
            };

            /**
             * Creates a RegistrationChanges message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges} RegistrationChanges
             */
            RegistrationChanges.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges)
                    return object;
                var message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges();
                if (object.uptimeMs != null)
                    message.uptimeMs = object.uptimeMs >>> 0;
                switch (object.radioAccessTechnology) {
                default:
                    if (typeof object.radioAccessTechnology === "number") {
                        message.radioAccessTechnology = object.radioAccessTechnology;
                        break;
                    }
                    break;
                case "kNoTechnology":
                case 0:
                    message.radioAccessTechnology = 0;
                    break;
                case "k2G":
                case 1:
                    message.radioAccessTechnology = 1;
                    break;
                case "k3G":
                case 2:
                    message.radioAccessTechnology = 2;
                    break;
                case "k4G":
                case 3:
                    message.radioAccessTechnology = 3;
                    break;
                }
                switch (object.registrationState) {
                default:
                    if (typeof object.registrationState === "number") {
                        message.registrationState = object.registrationState;
                        break;
                    }
                    break;
                case "kNotRegistered":
                case 0:
                    message.registrationState = 0;
                    break;
                case "kRegistered":
                case 1:
                    message.registrationState = 1;
                    break;
                case "kSearching":
                case 2:
                    message.registrationState = 2;
                    break;
                case "kDenied":
                case 3:
                    message.registrationState = 3;
                    break;
                case "kOutOfCoverage":
                case 4:
                    message.registrationState = 4;
                    break;
                case "kRoaming":
                case 5:
                    message.registrationState = 5;
                    break;
                case "kEmergencyOnly":
                case 8:
                    message.registrationState = 8;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a RegistrationChanges message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges} message RegistrationChanges
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegistrationChanges.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.uptimeMs = 0;
                    object.radioAccessTechnology = options.enums === String ? "kNoTechnology" : 0;
                    object.registrationState = options.enums === String ? "kNotRegistered" : 0;
                }
                if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                    object.uptimeMs = message.uptimeMs;
                if (message.radioAccessTechnology != null && message.hasOwnProperty("radioAccessTechnology"))
                    object.radioAccessTechnology = options.enums === String ? $root.ProtobufEventPayloadCellularNetwork.RadioAccessTechnology[message.radioAccessTechnology] === undefined ? message.radioAccessTechnology : $root.ProtobufEventPayloadCellularNetwork.RadioAccessTechnology[message.radioAccessTechnology] : message.radioAccessTechnology;
                if (message.registrationState != null && message.hasOwnProperty("registrationState"))
                    object.registrationState = options.enums === String ? $root.ProtobufEventPayloadCellularNetwork.RegistrationState[message.registrationState] === undefined ? message.registrationState : $root.ProtobufEventPayloadCellularNetwork.RegistrationState[message.registrationState] : message.registrationState;
                return object;
            };

            /**
             * Converts this RegistrationChanges to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegistrationChanges.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RegistrationChanges
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RegistrationChanges.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadCellularNetwork.DiagnosticResponse.RegistrationChanges";
            };

            return RegistrationChanges;
        })();

        DiagnosticResponse.NetworkChanges = (function() {

            /**
             * Properties of a NetworkChanges.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @interface INetworkChanges
             * @property {number|null} [uptimeMs] NetworkChanges uptimeMs
             * @property {number|null} [mcc] NetworkChanges mcc
             * @property {number|null} [mnc] NetworkChanges mnc
             * @property {ProtobufEventPayloadCellularNetwork.NetworkState|null} [networkState] NetworkChanges networkState
             */

            /**
             * Constructs a new NetworkChanges.
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse
             * @classdesc Represents a NetworkChanges.
             * @implements INetworkChanges
             * @constructor
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.INetworkChanges=} [properties] Properties to set
             */
            function NetworkChanges(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NetworkChanges uptimeMs.
             * @member {number} uptimeMs
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @instance
             */
            NetworkChanges.prototype.uptimeMs = 0;

            /**
             * NetworkChanges mcc.
             * @member {number} mcc
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @instance
             */
            NetworkChanges.prototype.mcc = 0;

            /**
             * NetworkChanges mnc.
             * @member {number} mnc
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @instance
             */
            NetworkChanges.prototype.mnc = 0;

            /**
             * NetworkChanges networkState.
             * @member {ProtobufEventPayloadCellularNetwork.NetworkState} networkState
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @instance
             */
            NetworkChanges.prototype.networkState = 0;

            /**
             * Creates a new NetworkChanges instance using the specified properties.
             * @function create
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.INetworkChanges=} [properties] Properties to set
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges} NetworkChanges instance
             */
            NetworkChanges.create = function create(properties) {
                return new NetworkChanges(properties);
            };

            /**
             * Encodes the specified NetworkChanges message. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges.verify|verify} messages.
             * @function encode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.INetworkChanges} message NetworkChanges message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NetworkChanges.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uptimeMs != null && Object.hasOwnProperty.call(message, "uptimeMs"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uptimeMs);
                if (message.mcc != null && Object.hasOwnProperty.call(message, "mcc"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.mcc);
                if (message.mnc != null && Object.hasOwnProperty.call(message, "mnc"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.mnc);
                if (message.networkState != null && Object.hasOwnProperty.call(message, "networkState"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.networkState);
                return writer;
            };

            /**
             * Encodes the specified NetworkChanges message, length delimited. Does not implicitly {@link ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges.verify|verify} messages.
             * @function encodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.INetworkChanges} message NetworkChanges message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NetworkChanges.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NetworkChanges message from the specified reader or buffer.
             * @function decode
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges} NetworkChanges
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NetworkChanges.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uptimeMs = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.mcc = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.mnc = reader.uint32();
                            break;
                        }
                    case 4: {
                            message.networkState = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NetworkChanges message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges} NetworkChanges
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NetworkChanges.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NetworkChanges message.
             * @function verify
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NetworkChanges.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                    if (!$util.isInteger(message.uptimeMs))
                        return "uptimeMs: integer expected";
                if (message.mcc != null && message.hasOwnProperty("mcc"))
                    if (!$util.isInteger(message.mcc))
                        return "mcc: integer expected";
                if (message.mnc != null && message.hasOwnProperty("mnc"))
                    if (!$util.isInteger(message.mnc))
                        return "mnc: integer expected";
                if (message.networkState != null && message.hasOwnProperty("networkState"))
                    switch (message.networkState) {
                    default:
                        return "networkState: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a NetworkChanges message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges} NetworkChanges
             */
            NetworkChanges.fromObject = function fromObject(object) {
                if (object instanceof $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges)
                    return object;
                var message = new $root.ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges();
                if (object.uptimeMs != null)
                    message.uptimeMs = object.uptimeMs >>> 0;
                if (object.mcc != null)
                    message.mcc = object.mcc >>> 0;
                if (object.mnc != null)
                    message.mnc = object.mnc >>> 0;
                switch (object.networkState) {
                default:
                    if (typeof object.networkState === "number") {
                        message.networkState = object.networkState;
                        break;
                    }
                    break;
                case "kSearch":
                case 0:
                    message.networkState = 0;
                    break;
                case "kIdle":
                case 1:
                    message.networkState = 1;
                    break;
                case "kConnected":
                case 2:
                    message.networkState = 2;
                    break;
                case "kLimitedService":
                case 3:
                    message.networkState = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a NetworkChanges message. Also converts values to other types if specified.
             * @function toObject
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges} message NetworkChanges
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NetworkChanges.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.uptimeMs = 0;
                    object.mcc = 0;
                    object.mnc = 0;
                    object.networkState = options.enums === String ? "kSearch" : 0;
                }
                if (message.uptimeMs != null && message.hasOwnProperty("uptimeMs"))
                    object.uptimeMs = message.uptimeMs;
                if (message.mcc != null && message.hasOwnProperty("mcc"))
                    object.mcc = message.mcc;
                if (message.mnc != null && message.hasOwnProperty("mnc"))
                    object.mnc = message.mnc;
                if (message.networkState != null && message.hasOwnProperty("networkState"))
                    object.networkState = options.enums === String ? $root.ProtobufEventPayloadCellularNetwork.NetworkState[message.networkState] === undefined ? message.networkState : $root.ProtobufEventPayloadCellularNetwork.NetworkState[message.networkState] : message.networkState;
                return object;
            };

            /**
             * Converts this NetworkChanges to JSON.
             * @function toJSON
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NetworkChanges.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NetworkChanges
             * @function getTypeUrl
             * @memberof ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NetworkChanges.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/ProtobufEventPayloadCellularNetwork.DiagnosticResponse.NetworkChanges";
            };

            return NetworkChanges;
        })();

        return DiagnosticResponse;
    })();

    return ProtobufEventPayloadCellularNetwork;
})();

$root.MadgwickDiagnostics = (function() {

    /**
     * Properties of a MadgwickDiagnostics.
     * @exports IMadgwickDiagnostics
     * @interface IMadgwickDiagnostics
     * @property {number|null} [accelerationError] MadgwickDiagnostics accelerationError
     * @property {boolean|null} [accelerometerIgnored] MadgwickDiagnostics accelerometerIgnored
     * @property {number|null} [accelerationRecoveryTrigger] MadgwickDiagnostics accelerationRecoveryTrigger
     * @property {number|null} [magneticError] MadgwickDiagnostics magneticError
     * @property {boolean|null} [magnetometerIgnored] MadgwickDiagnostics magnetometerIgnored
     * @property {number|null} [magneticRecoveryTrigger] MadgwickDiagnostics magneticRecoveryTrigger
     * @property {boolean|null} [initialising] MadgwickDiagnostics initialising
     * @property {boolean|null} [angularRateRecovery] MadgwickDiagnostics angularRateRecovery
     * @property {boolean|null} [accelerationRecovery] MadgwickDiagnostics accelerationRecovery
     * @property {boolean|null} [magneticRecovery] MadgwickDiagnostics magneticRecovery
     */

    /**
     * Constructs a new MadgwickDiagnostics.
     * @exports MadgwickDiagnostics
     * @classdesc Represents a MadgwickDiagnostics.
     * @implements IMadgwickDiagnostics
     * @constructor
     * @param {IMadgwickDiagnostics=} [properties] Properties to set
     */
    function MadgwickDiagnostics(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MadgwickDiagnostics accelerationError.
     * @member {number} accelerationError
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.accelerationError = 0;

    /**
     * MadgwickDiagnostics accelerometerIgnored.
     * @member {boolean} accelerometerIgnored
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.accelerometerIgnored = false;

    /**
     * MadgwickDiagnostics accelerationRecoveryTrigger.
     * @member {number} accelerationRecoveryTrigger
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.accelerationRecoveryTrigger = 0;

    /**
     * MadgwickDiagnostics magneticError.
     * @member {number} magneticError
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.magneticError = 0;

    /**
     * MadgwickDiagnostics magnetometerIgnored.
     * @member {boolean} magnetometerIgnored
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.magnetometerIgnored = false;

    /**
     * MadgwickDiagnostics magneticRecoveryTrigger.
     * @member {number} magneticRecoveryTrigger
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.magneticRecoveryTrigger = 0;

    /**
     * MadgwickDiagnostics initialising.
     * @member {boolean} initialising
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.initialising = false;

    /**
     * MadgwickDiagnostics angularRateRecovery.
     * @member {boolean} angularRateRecovery
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.angularRateRecovery = false;

    /**
     * MadgwickDiagnostics accelerationRecovery.
     * @member {boolean} accelerationRecovery
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.accelerationRecovery = false;

    /**
     * MadgwickDiagnostics magneticRecovery.
     * @member {boolean} magneticRecovery
     * @memberof MadgwickDiagnostics
     * @instance
     */
    MadgwickDiagnostics.prototype.magneticRecovery = false;

    /**
     * Creates a new MadgwickDiagnostics instance using the specified properties.
     * @function create
     * @memberof MadgwickDiagnostics
     * @static
     * @param {IMadgwickDiagnostics=} [properties] Properties to set
     * @returns {MadgwickDiagnostics} MadgwickDiagnostics instance
     */
    MadgwickDiagnostics.create = function create(properties) {
        return new MadgwickDiagnostics(properties);
    };

    /**
     * Encodes the specified MadgwickDiagnostics message. Does not implicitly {@link MadgwickDiagnostics.verify|verify} messages.
     * @function encode
     * @memberof MadgwickDiagnostics
     * @static
     * @param {IMadgwickDiagnostics} message MadgwickDiagnostics message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MadgwickDiagnostics.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.accelerationError != null && Object.hasOwnProperty.call(message, "accelerationError"))
            writer.uint32(/* id 1, wireType 5 =*/13).float(message.accelerationError);
        if (message.accelerometerIgnored != null && Object.hasOwnProperty.call(message, "accelerometerIgnored"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.accelerometerIgnored);
        if (message.accelerationRecoveryTrigger != null && Object.hasOwnProperty.call(message, "accelerationRecoveryTrigger"))
            writer.uint32(/* id 3, wireType 5 =*/29).float(message.accelerationRecoveryTrigger);
        if (message.magneticError != null && Object.hasOwnProperty.call(message, "magneticError"))
            writer.uint32(/* id 4, wireType 5 =*/37).float(message.magneticError);
        if (message.magnetometerIgnored != null && Object.hasOwnProperty.call(message, "magnetometerIgnored"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.magnetometerIgnored);
        if (message.magneticRecoveryTrigger != null && Object.hasOwnProperty.call(message, "magneticRecoveryTrigger"))
            writer.uint32(/* id 6, wireType 5 =*/53).float(message.magneticRecoveryTrigger);
        if (message.initialising != null && Object.hasOwnProperty.call(message, "initialising"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.initialising);
        if (message.angularRateRecovery != null && Object.hasOwnProperty.call(message, "angularRateRecovery"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.angularRateRecovery);
        if (message.accelerationRecovery != null && Object.hasOwnProperty.call(message, "accelerationRecovery"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.accelerationRecovery);
        if (message.magneticRecovery != null && Object.hasOwnProperty.call(message, "magneticRecovery"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.magneticRecovery);
        return writer;
    };

    /**
     * Encodes the specified MadgwickDiagnostics message, length delimited. Does not implicitly {@link MadgwickDiagnostics.verify|verify} messages.
     * @function encodeDelimited
     * @memberof MadgwickDiagnostics
     * @static
     * @param {IMadgwickDiagnostics} message MadgwickDiagnostics message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MadgwickDiagnostics.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MadgwickDiagnostics message from the specified reader or buffer.
     * @function decode
     * @memberof MadgwickDiagnostics
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MadgwickDiagnostics} MadgwickDiagnostics
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MadgwickDiagnostics.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MadgwickDiagnostics();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.accelerationError = reader.float();
                    break;
                }
            case 2: {
                    message.accelerometerIgnored = reader.bool();
                    break;
                }
            case 3: {
                    message.accelerationRecoveryTrigger = reader.float();
                    break;
                }
            case 4: {
                    message.magneticError = reader.float();
                    break;
                }
            case 5: {
                    message.magnetometerIgnored = reader.bool();
                    break;
                }
            case 6: {
                    message.magneticRecoveryTrigger = reader.float();
                    break;
                }
            case 7: {
                    message.initialising = reader.bool();
                    break;
                }
            case 8: {
                    message.angularRateRecovery = reader.bool();
                    break;
                }
            case 9: {
                    message.accelerationRecovery = reader.bool();
                    break;
                }
            case 10: {
                    message.magneticRecovery = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a MadgwickDiagnostics message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof MadgwickDiagnostics
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {MadgwickDiagnostics} MadgwickDiagnostics
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MadgwickDiagnostics.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MadgwickDiagnostics message.
     * @function verify
     * @memberof MadgwickDiagnostics
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MadgwickDiagnostics.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.accelerationError != null && message.hasOwnProperty("accelerationError"))
            if (typeof message.accelerationError !== "number")
                return "accelerationError: number expected";
        if (message.accelerometerIgnored != null && message.hasOwnProperty("accelerometerIgnored"))
            if (typeof message.accelerometerIgnored !== "boolean")
                return "accelerometerIgnored: boolean expected";
        if (message.accelerationRecoveryTrigger != null && message.hasOwnProperty("accelerationRecoveryTrigger"))
            if (typeof message.accelerationRecoveryTrigger !== "number")
                return "accelerationRecoveryTrigger: number expected";
        if (message.magneticError != null && message.hasOwnProperty("magneticError"))
            if (typeof message.magneticError !== "number")
                return "magneticError: number expected";
        if (message.magnetometerIgnored != null && message.hasOwnProperty("magnetometerIgnored"))
            if (typeof message.magnetometerIgnored !== "boolean")
                return "magnetometerIgnored: boolean expected";
        if (message.magneticRecoveryTrigger != null && message.hasOwnProperty("magneticRecoveryTrigger"))
            if (typeof message.magneticRecoveryTrigger !== "number")
                return "magneticRecoveryTrigger: number expected";
        if (message.initialising != null && message.hasOwnProperty("initialising"))
            if (typeof message.initialising !== "boolean")
                return "initialising: boolean expected";
        if (message.angularRateRecovery != null && message.hasOwnProperty("angularRateRecovery"))
            if (typeof message.angularRateRecovery !== "boolean")
                return "angularRateRecovery: boolean expected";
        if (message.accelerationRecovery != null && message.hasOwnProperty("accelerationRecovery"))
            if (typeof message.accelerationRecovery !== "boolean")
                return "accelerationRecovery: boolean expected";
        if (message.magneticRecovery != null && message.hasOwnProperty("magneticRecovery"))
            if (typeof message.magneticRecovery !== "boolean")
                return "magneticRecovery: boolean expected";
        return null;
    };

    /**
     * Creates a MadgwickDiagnostics message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof MadgwickDiagnostics
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {MadgwickDiagnostics} MadgwickDiagnostics
     */
    MadgwickDiagnostics.fromObject = function fromObject(object) {
        if (object instanceof $root.MadgwickDiagnostics)
            return object;
        var message = new $root.MadgwickDiagnostics();
        if (object.accelerationError != null)
            message.accelerationError = Number(object.accelerationError);
        if (object.accelerometerIgnored != null)
            message.accelerometerIgnored = Boolean(object.accelerometerIgnored);
        if (object.accelerationRecoveryTrigger != null)
            message.accelerationRecoveryTrigger = Number(object.accelerationRecoveryTrigger);
        if (object.magneticError != null)
            message.magneticError = Number(object.magneticError);
        if (object.magnetometerIgnored != null)
            message.magnetometerIgnored = Boolean(object.magnetometerIgnored);
        if (object.magneticRecoveryTrigger != null)
            message.magneticRecoveryTrigger = Number(object.magneticRecoveryTrigger);
        if (object.initialising != null)
            message.initialising = Boolean(object.initialising);
        if (object.angularRateRecovery != null)
            message.angularRateRecovery = Boolean(object.angularRateRecovery);
        if (object.accelerationRecovery != null)
            message.accelerationRecovery = Boolean(object.accelerationRecovery);
        if (object.magneticRecovery != null)
            message.magneticRecovery = Boolean(object.magneticRecovery);
        return message;
    };

    /**
     * Creates a plain object from a MadgwickDiagnostics message. Also converts values to other types if specified.
     * @function toObject
     * @memberof MadgwickDiagnostics
     * @static
     * @param {MadgwickDiagnostics} message MadgwickDiagnostics
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MadgwickDiagnostics.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.accelerationError = 0;
            object.accelerometerIgnored = false;
            object.accelerationRecoveryTrigger = 0;
            object.magneticError = 0;
            object.magnetometerIgnored = false;
            object.magneticRecoveryTrigger = 0;
            object.initialising = false;
            object.angularRateRecovery = false;
            object.accelerationRecovery = false;
            object.magneticRecovery = false;
        }
        if (message.accelerationError != null && message.hasOwnProperty("accelerationError"))
            object.accelerationError = options.json && !isFinite(message.accelerationError) ? String(message.accelerationError) : message.accelerationError;
        if (message.accelerometerIgnored != null && message.hasOwnProperty("accelerometerIgnored"))
            object.accelerometerIgnored = message.accelerometerIgnored;
        if (message.accelerationRecoveryTrigger != null && message.hasOwnProperty("accelerationRecoveryTrigger"))
            object.accelerationRecoveryTrigger = options.json && !isFinite(message.accelerationRecoveryTrigger) ? String(message.accelerationRecoveryTrigger) : message.accelerationRecoveryTrigger;
        if (message.magneticError != null && message.hasOwnProperty("magneticError"))
            object.magneticError = options.json && !isFinite(message.magneticError) ? String(message.magneticError) : message.magneticError;
        if (message.magnetometerIgnored != null && message.hasOwnProperty("magnetometerIgnored"))
            object.magnetometerIgnored = message.magnetometerIgnored;
        if (message.magneticRecoveryTrigger != null && message.hasOwnProperty("magneticRecoveryTrigger"))
            object.magneticRecoveryTrigger = options.json && !isFinite(message.magneticRecoveryTrigger) ? String(message.magneticRecoveryTrigger) : message.magneticRecoveryTrigger;
        if (message.initialising != null && message.hasOwnProperty("initialising"))
            object.initialising = message.initialising;
        if (message.angularRateRecovery != null && message.hasOwnProperty("angularRateRecovery"))
            object.angularRateRecovery = message.angularRateRecovery;
        if (message.accelerationRecovery != null && message.hasOwnProperty("accelerationRecovery"))
            object.accelerationRecovery = message.accelerationRecovery;
        if (message.magneticRecovery != null && message.hasOwnProperty("magneticRecovery"))
            object.magneticRecovery = message.magneticRecovery;
        return object;
    };

    /**
     * Converts this MadgwickDiagnostics to JSON.
     * @function toJSON
     * @memberof MadgwickDiagnostics
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MadgwickDiagnostics.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MadgwickDiagnostics
     * @function getTypeUrl
     * @memberof MadgwickDiagnostics
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MadgwickDiagnostics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/MadgwickDiagnostics";
    };

    return MadgwickDiagnostics;
})();

$root.ProtobufEventPayloadAhrsDiagnostic = (function() {

    /**
     * Properties of a ProtobufEventPayloadAhrsDiagnostic.
     * @exports IProtobufEventPayloadAhrsDiagnostic
     * @interface IProtobufEventPayloadAhrsDiagnostic
     * @property {number|null} [uptime] ProtobufEventPayloadAhrsDiagnostic uptime
     * @property {number|null} [utc] ProtobufEventPayloadAhrsDiagnostic utc
     * @property {IMadgwickDiagnostics|null} [madgwickDiagnostics] ProtobufEventPayloadAhrsDiagnostic madgwickDiagnostics
     */

    /**
     * Constructs a new ProtobufEventPayloadAhrsDiagnostic.
     * @exports ProtobufEventPayloadAhrsDiagnostic
     * @classdesc Represents a ProtobufEventPayloadAhrsDiagnostic.
     * @implements IProtobufEventPayloadAhrsDiagnostic
     * @constructor
     * @param {IProtobufEventPayloadAhrsDiagnostic=} [properties] Properties to set
     */
    function ProtobufEventPayloadAhrsDiagnostic(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufEventPayloadAhrsDiagnostic uptime.
     * @member {number} uptime
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @instance
     */
    ProtobufEventPayloadAhrsDiagnostic.prototype.uptime = 0;

    /**
     * ProtobufEventPayloadAhrsDiagnostic utc.
     * @member {number} utc
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @instance
     */
    ProtobufEventPayloadAhrsDiagnostic.prototype.utc = 0;

    /**
     * ProtobufEventPayloadAhrsDiagnostic madgwickDiagnostics.
     * @member {IMadgwickDiagnostics|null|undefined} madgwickDiagnostics
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @instance
     */
    ProtobufEventPayloadAhrsDiagnostic.prototype.madgwickDiagnostics = null;

    /**
     * Creates a new ProtobufEventPayloadAhrsDiagnostic instance using the specified properties.
     * @function create
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {IProtobufEventPayloadAhrsDiagnostic=} [properties] Properties to set
     * @returns {ProtobufEventPayloadAhrsDiagnostic} ProtobufEventPayloadAhrsDiagnostic instance
     */
    ProtobufEventPayloadAhrsDiagnostic.create = function create(properties) {
        return new ProtobufEventPayloadAhrsDiagnostic(properties);
    };

    /**
     * Encodes the specified ProtobufEventPayloadAhrsDiagnostic message. Does not implicitly {@link ProtobufEventPayloadAhrsDiagnostic.verify|verify} messages.
     * @function encode
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {IProtobufEventPayloadAhrsDiagnostic} message ProtobufEventPayloadAhrsDiagnostic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadAhrsDiagnostic.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.uptime != null && Object.hasOwnProperty.call(message, "uptime"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.uptime);
        if (message.utc != null && Object.hasOwnProperty.call(message, "utc"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.utc);
        if (message.madgwickDiagnostics != null && Object.hasOwnProperty.call(message, "madgwickDiagnostics"))
            $root.MadgwickDiagnostics.encode(message.madgwickDiagnostics, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProtobufEventPayloadAhrsDiagnostic message, length delimited. Does not implicitly {@link ProtobufEventPayloadAhrsDiagnostic.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {IProtobufEventPayloadAhrsDiagnostic} message ProtobufEventPayloadAhrsDiagnostic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufEventPayloadAhrsDiagnostic.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufEventPayloadAhrsDiagnostic message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufEventPayloadAhrsDiagnostic} ProtobufEventPayloadAhrsDiagnostic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadAhrsDiagnostic.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufEventPayloadAhrsDiagnostic();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.uptime = reader.uint32();
                    break;
                }
            case 2: {
                    message.utc = reader.uint32();
                    break;
                }
            case 3: {
                    message.madgwickDiagnostics = $root.MadgwickDiagnostics.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufEventPayloadAhrsDiagnostic message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufEventPayloadAhrsDiagnostic} ProtobufEventPayloadAhrsDiagnostic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufEventPayloadAhrsDiagnostic.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufEventPayloadAhrsDiagnostic message.
     * @function verify
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufEventPayloadAhrsDiagnostic.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.uptime != null && message.hasOwnProperty("uptime"))
            if (!$util.isInteger(message.uptime))
                return "uptime: integer expected";
        if (message.utc != null && message.hasOwnProperty("utc"))
            if (!$util.isInteger(message.utc))
                return "utc: integer expected";
        if (message.madgwickDiagnostics != null && message.hasOwnProperty("madgwickDiagnostics")) {
            var error = $root.MadgwickDiagnostics.verify(message.madgwickDiagnostics);
            if (error)
                return "madgwickDiagnostics." + error;
        }
        return null;
    };

    /**
     * Creates a ProtobufEventPayloadAhrsDiagnostic message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufEventPayloadAhrsDiagnostic} ProtobufEventPayloadAhrsDiagnostic
     */
    ProtobufEventPayloadAhrsDiagnostic.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufEventPayloadAhrsDiagnostic)
            return object;
        var message = new $root.ProtobufEventPayloadAhrsDiagnostic();
        if (object.uptime != null)
            message.uptime = object.uptime >>> 0;
        if (object.utc != null)
            message.utc = object.utc >>> 0;
        if (object.madgwickDiagnostics != null) {
            if (typeof object.madgwickDiagnostics !== "object")
                throw TypeError(".ProtobufEventPayloadAhrsDiagnostic.madgwickDiagnostics: object expected");
            message.madgwickDiagnostics = $root.MadgwickDiagnostics.fromObject(object.madgwickDiagnostics);
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufEventPayloadAhrsDiagnostic message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {ProtobufEventPayloadAhrsDiagnostic} message ProtobufEventPayloadAhrsDiagnostic
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufEventPayloadAhrsDiagnostic.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.uptime = 0;
            object.utc = 0;
            object.madgwickDiagnostics = null;
        }
        if (message.uptime != null && message.hasOwnProperty("uptime"))
            object.uptime = message.uptime;
        if (message.utc != null && message.hasOwnProperty("utc"))
            object.utc = message.utc;
        if (message.madgwickDiagnostics != null && message.hasOwnProperty("madgwickDiagnostics"))
            object.madgwickDiagnostics = $root.MadgwickDiagnostics.toObject(message.madgwickDiagnostics, options);
        return object;
    };

    /**
     * Converts this ProtobufEventPayloadAhrsDiagnostic to JSON.
     * @function toJSON
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufEventPayloadAhrsDiagnostic.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufEventPayloadAhrsDiagnostic
     * @function getTypeUrl
     * @memberof ProtobufEventPayloadAhrsDiagnostic
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufEventPayloadAhrsDiagnostic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufEventPayloadAhrsDiagnostic";
    };

    return ProtobufEventPayloadAhrsDiagnostic;
})();

$root.ProtobufSession = (function() {

    /**
     * Properties of a ProtobufSession.
     * @exports IProtobufSession
     * @interface IProtobufSession
     */

    /**
     * Constructs a new ProtobufSession.
     * @exports ProtobufSession
     * @classdesc Represents a ProtobufSession.
     * @implements IProtobufSession
     * @constructor
     * @param {IProtobufSession=} [properties] Properties to set
     */
    function ProtobufSession(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new ProtobufSession instance using the specified properties.
     * @function create
     * @memberof ProtobufSession
     * @static
     * @param {IProtobufSession=} [properties] Properties to set
     * @returns {ProtobufSession} ProtobufSession instance
     */
    ProtobufSession.create = function create(properties) {
        return new ProtobufSession(properties);
    };

    /**
     * Encodes the specified ProtobufSession message. Does not implicitly {@link ProtobufSession.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSession
     * @static
     * @param {IProtobufSession} message ProtobufSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSession.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified ProtobufSession message, length delimited. Does not implicitly {@link ProtobufSession.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSession
     * @static
     * @param {IProtobufSession} message ProtobufSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSession.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSession message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSession} ProtobufSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSession.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSession();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProtobufSession message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSession} ProtobufSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSession.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSession message.
     * @function verify
     * @memberof ProtobufSession
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSession.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a ProtobufSession message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSession
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSession} ProtobufSession
     */
    ProtobufSession.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSession)
            return object;
        return new $root.ProtobufSession();
    };

    /**
     * Creates a plain object from a ProtobufSession message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSession
     * @static
     * @param {ProtobufSession} message ProtobufSession
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSession.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this ProtobufSession to JSON.
     * @function toJSON
     * @memberof ProtobufSession
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSession.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSession
     * @function getTypeUrl
     * @memberof ProtobufSession
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSession";
    };

    /**
     * SettingsState enum.
     * @name ProtobufSession.SettingsState
     * @enum {number}
     * @property {number} kNone=0 kNone value
     * @property {number} kReadFailed=1 kReadFailed value
     * @property {number} kLengthInvalid=2 kLengthInvalid value
     * @property {number} kCrcInvalid=3 kCrcInvalid value
     * @property {number} kDecoded=4 kDecoded value
     * @property {number} kDecodeFailed=5 kDecodeFailed value
     * @property {number} kEncoded=6 kEncoded value
     * @property {number} kEncodeFailed=7 kEncodeFailed value
     * @property {number} kWritten=8 kWritten value
     * @property {number} kWriteFailed=9 kWriteFailed value
     */
    ProtobufSession.SettingsState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kNone"] = 0;
        values[valuesById[1] = "kReadFailed"] = 1;
        values[valuesById[2] = "kLengthInvalid"] = 2;
        values[valuesById[3] = "kCrcInvalid"] = 3;
        values[valuesById[4] = "kDecoded"] = 4;
        values[valuesById[5] = "kDecodeFailed"] = 5;
        values[valuesById[6] = "kEncoded"] = 6;
        values[valuesById[7] = "kEncodeFailed"] = 7;
        values[valuesById[8] = "kWritten"] = 8;
        values[valuesById[9] = "kWriteFailed"] = 9;
        return values;
    })();

    /**
     * Environment enum.
     * @name ProtobufSession.Environment
     * @enum {number}
     * @property {number} kDev=0 kDev value
     * @property {number} kTest=1 kTest value
     * @property {number} kProd=2 kProd value
     */
    ProtobufSession.Environment = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kDev"] = 0;
        values[valuesById[1] = "kTest"] = 1;
        values[valuesById[2] = "kProd"] = 2;
        return values;
    })();

    /**
     * Flags enum.
     * @name ProtobufSession.Flags
     * @enum {number}
     * @property {number} kMqttCredentialsMissing=1 kMqttCredentialsMissing value
     */
    ProtobufSession.Flags = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "kMqttCredentialsMissing"] = 1;
        return values;
    })();

    return ProtobufSession;
})();

$root.ProtobufSessionRequestV3 = (function() {

    /**
     * Properties of a ProtobufSessionRequestV3.
     * @exports IProtobufSessionRequestV3
     * @interface IProtobufSessionRequestV3
     * @property {number} sessionId ProtobufSessionRequestV3 sessionId
     * @property {string} serialNumber ProtobufSessionRequestV3 serialNumber
     * @property {number} firmwareVersion ProtobufSessionRequestV3 firmwareVersion
     * @property {number} settingsServerVersion ProtobufSessionRequestV3 settingsServerVersion
     * @property {ProtobufSession.SettingsState} settingsServerReadState ProtobufSessionRequestV3 settingsServerReadState
     * @property {ProtobufSession.SettingsState} settingsServerUpdateState ProtobufSessionRequestV3 settingsServerUpdateState
     * @property {number} settingServerCrc ProtobufSessionRequestV3 settingServerCrc
     */

    /**
     * Constructs a new ProtobufSessionRequestV3.
     * @exports ProtobufSessionRequestV3
     * @classdesc Represents a ProtobufSessionRequestV3.
     * @implements IProtobufSessionRequestV3
     * @constructor
     * @param {IProtobufSessionRequestV3=} [properties] Properties to set
     */
    function ProtobufSessionRequestV3(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSessionRequestV3 sessionId.
     * @member {number} sessionId
     * @memberof ProtobufSessionRequestV3
     * @instance
     */
    ProtobufSessionRequestV3.prototype.sessionId = 0;

    /**
     * ProtobufSessionRequestV3 serialNumber.
     * @member {string} serialNumber
     * @memberof ProtobufSessionRequestV3
     * @instance
     */
    ProtobufSessionRequestV3.prototype.serialNumber = "";

    /**
     * ProtobufSessionRequestV3 firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufSessionRequestV3
     * @instance
     */
    ProtobufSessionRequestV3.prototype.firmwareVersion = 0;

    /**
     * ProtobufSessionRequestV3 settingsServerVersion.
     * @member {number} settingsServerVersion
     * @memberof ProtobufSessionRequestV3
     * @instance
     */
    ProtobufSessionRequestV3.prototype.settingsServerVersion = 0;

    /**
     * ProtobufSessionRequestV3 settingsServerReadState.
     * @member {ProtobufSession.SettingsState} settingsServerReadState
     * @memberof ProtobufSessionRequestV3
     * @instance
     */
    ProtobufSessionRequestV3.prototype.settingsServerReadState = 0;

    /**
     * ProtobufSessionRequestV3 settingsServerUpdateState.
     * @member {ProtobufSession.SettingsState} settingsServerUpdateState
     * @memberof ProtobufSessionRequestV3
     * @instance
     */
    ProtobufSessionRequestV3.prototype.settingsServerUpdateState = 0;

    /**
     * ProtobufSessionRequestV3 settingServerCrc.
     * @member {number} settingServerCrc
     * @memberof ProtobufSessionRequestV3
     * @instance
     */
    ProtobufSessionRequestV3.prototype.settingServerCrc = 0;

    /**
     * Creates a new ProtobufSessionRequestV3 instance using the specified properties.
     * @function create
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {IProtobufSessionRequestV3=} [properties] Properties to set
     * @returns {ProtobufSessionRequestV3} ProtobufSessionRequestV3 instance
     */
    ProtobufSessionRequestV3.create = function create(properties) {
        return new ProtobufSessionRequestV3(properties);
    };

    /**
     * Encodes the specified ProtobufSessionRequestV3 message. Does not implicitly {@link ProtobufSessionRequestV3.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {IProtobufSessionRequestV3} message ProtobufSessionRequestV3 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionRequestV3.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sessionId);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.serialNumber);
        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.firmwareVersion);
        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.settingsServerVersion);
        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.settingsServerReadState);
        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.settingsServerUpdateState);
        writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.settingServerCrc);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSessionRequestV3 message, length delimited. Does not implicitly {@link ProtobufSessionRequestV3.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {IProtobufSessionRequestV3} message ProtobufSessionRequestV3 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionRequestV3.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSessionRequestV3 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSessionRequestV3} ProtobufSessionRequestV3
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionRequestV3.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSessionRequestV3();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sessionId = reader.uint32();
                    break;
                }
            case 2: {
                    message.serialNumber = reader.string();
                    break;
                }
            case 3: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 4: {
                    message.settingsServerVersion = reader.uint32();
                    break;
                }
            case 5: {
                    message.settingsServerReadState = reader.int32();
                    break;
                }
            case 6: {
                    message.settingsServerUpdateState = reader.int32();
                    break;
                }
            case 7: {
                    message.settingServerCrc = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("sessionId"))
            throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
        if (!message.hasOwnProperty("serialNumber"))
            throw $util.ProtocolError("missing required 'serialNumber'", { instance: message });
        if (!message.hasOwnProperty("firmwareVersion"))
            throw $util.ProtocolError("missing required 'firmwareVersion'", { instance: message });
        if (!message.hasOwnProperty("settingsServerVersion"))
            throw $util.ProtocolError("missing required 'settingsServerVersion'", { instance: message });
        if (!message.hasOwnProperty("settingsServerReadState"))
            throw $util.ProtocolError("missing required 'settingsServerReadState'", { instance: message });
        if (!message.hasOwnProperty("settingsServerUpdateState"))
            throw $util.ProtocolError("missing required 'settingsServerUpdateState'", { instance: message });
        if (!message.hasOwnProperty("settingServerCrc"))
            throw $util.ProtocolError("missing required 'settingServerCrc'", { instance: message });
        return message;
    };

    /**
     * Decodes a ProtobufSessionRequestV3 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSessionRequestV3} ProtobufSessionRequestV3
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionRequestV3.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSessionRequestV3 message.
     * @function verify
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSessionRequestV3.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isInteger(message.sessionId))
            return "sessionId: integer expected";
        if (!$util.isString(message.serialNumber))
            return "serialNumber: string expected";
        if (!$util.isInteger(message.firmwareVersion))
            return "firmwareVersion: integer expected";
        if (!$util.isInteger(message.settingsServerVersion))
            return "settingsServerVersion: integer expected";
        switch (message.settingsServerReadState) {
        default:
            return "settingsServerReadState: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            break;
        }
        switch (message.settingsServerUpdateState) {
        default:
            return "settingsServerUpdateState: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            break;
        }
        if (!$util.isInteger(message.settingServerCrc))
            return "settingServerCrc: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufSessionRequestV3 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSessionRequestV3} ProtobufSessionRequestV3
     */
    ProtobufSessionRequestV3.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSessionRequestV3)
            return object;
        var message = new $root.ProtobufSessionRequestV3();
        if (object.sessionId != null)
            message.sessionId = object.sessionId >>> 0;
        if (object.serialNumber != null)
            message.serialNumber = String(object.serialNumber);
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        if (object.settingsServerVersion != null)
            message.settingsServerVersion = object.settingsServerVersion >>> 0;
        switch (object.settingsServerReadState) {
        default:
            if (typeof object.settingsServerReadState === "number") {
                message.settingsServerReadState = object.settingsServerReadState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.settingsServerReadState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.settingsServerReadState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.settingsServerReadState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.settingsServerReadState = 3;
            break;
        case "kDecoded":
        case 4:
            message.settingsServerReadState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.settingsServerReadState = 5;
            break;
        case "kEncoded":
        case 6:
            message.settingsServerReadState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.settingsServerReadState = 7;
            break;
        case "kWritten":
        case 8:
            message.settingsServerReadState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.settingsServerReadState = 9;
            break;
        }
        switch (object.settingsServerUpdateState) {
        default:
            if (typeof object.settingsServerUpdateState === "number") {
                message.settingsServerUpdateState = object.settingsServerUpdateState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.settingsServerUpdateState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.settingsServerUpdateState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.settingsServerUpdateState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.settingsServerUpdateState = 3;
            break;
        case "kDecoded":
        case 4:
            message.settingsServerUpdateState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.settingsServerUpdateState = 5;
            break;
        case "kEncoded":
        case 6:
            message.settingsServerUpdateState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.settingsServerUpdateState = 7;
            break;
        case "kWritten":
        case 8:
            message.settingsServerUpdateState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.settingsServerUpdateState = 9;
            break;
        }
        if (object.settingServerCrc != null)
            message.settingServerCrc = object.settingServerCrc >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSessionRequestV3 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {ProtobufSessionRequestV3} message ProtobufSessionRequestV3
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSessionRequestV3.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sessionId = 0;
            object.serialNumber = "";
            object.firmwareVersion = 0;
            object.settingsServerVersion = 0;
            object.settingsServerReadState = options.enums === String ? "kNone" : 0;
            object.settingsServerUpdateState = options.enums === String ? "kNone" : 0;
            object.settingServerCrc = 0;
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
            object.serialNumber = message.serialNumber;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.settingsServerVersion != null && message.hasOwnProperty("settingsServerVersion"))
            object.settingsServerVersion = message.settingsServerVersion;
        if (message.settingsServerReadState != null && message.hasOwnProperty("settingsServerReadState"))
            object.settingsServerReadState = options.enums === String ? $root.ProtobufSession.SettingsState[message.settingsServerReadState] === undefined ? message.settingsServerReadState : $root.ProtobufSession.SettingsState[message.settingsServerReadState] : message.settingsServerReadState;
        if (message.settingsServerUpdateState != null && message.hasOwnProperty("settingsServerUpdateState"))
            object.settingsServerUpdateState = options.enums === String ? $root.ProtobufSession.SettingsState[message.settingsServerUpdateState] === undefined ? message.settingsServerUpdateState : $root.ProtobufSession.SettingsState[message.settingsServerUpdateState] : message.settingsServerUpdateState;
        if (message.settingServerCrc != null && message.hasOwnProperty("settingServerCrc"))
            object.settingServerCrc = message.settingServerCrc;
        return object;
    };

    /**
     * Converts this ProtobufSessionRequestV3 to JSON.
     * @function toJSON
     * @memberof ProtobufSessionRequestV3
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSessionRequestV3.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSessionRequestV3
     * @function getTypeUrl
     * @memberof ProtobufSessionRequestV3
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSessionRequestV3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSessionRequestV3";
    };

    return ProtobufSessionRequestV3;
})();

$root.ProtobufSessionResponseV3 = (function() {

    /**
     * Properties of a ProtobufSessionResponseV3.
     * @exports IProtobufSessionResponseV3
     * @interface IProtobufSessionResponseV3
     * @property {number} sessionId ProtobufSessionResponseV3 sessionId
     * @property {number} settingServerCrc ProtobufSessionResponseV3 settingServerCrc
     * @property {ProtobufSession.Environment} environment ProtobufSessionResponseV3 environment
     */

    /**
     * Constructs a new ProtobufSessionResponseV3.
     * @exports ProtobufSessionResponseV3
     * @classdesc Represents a ProtobufSessionResponseV3.
     * @implements IProtobufSessionResponseV3
     * @constructor
     * @param {IProtobufSessionResponseV3=} [properties] Properties to set
     */
    function ProtobufSessionResponseV3(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSessionResponseV3 sessionId.
     * @member {number} sessionId
     * @memberof ProtobufSessionResponseV3
     * @instance
     */
    ProtobufSessionResponseV3.prototype.sessionId = 0;

    /**
     * ProtobufSessionResponseV3 settingServerCrc.
     * @member {number} settingServerCrc
     * @memberof ProtobufSessionResponseV3
     * @instance
     */
    ProtobufSessionResponseV3.prototype.settingServerCrc = 0;

    /**
     * ProtobufSessionResponseV3 environment.
     * @member {ProtobufSession.Environment} environment
     * @memberof ProtobufSessionResponseV3
     * @instance
     */
    ProtobufSessionResponseV3.prototype.environment = 0;

    /**
     * Creates a new ProtobufSessionResponseV3 instance using the specified properties.
     * @function create
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {IProtobufSessionResponseV3=} [properties] Properties to set
     * @returns {ProtobufSessionResponseV3} ProtobufSessionResponseV3 instance
     */
    ProtobufSessionResponseV3.create = function create(properties) {
        return new ProtobufSessionResponseV3(properties);
    };

    /**
     * Encodes the specified ProtobufSessionResponseV3 message. Does not implicitly {@link ProtobufSessionResponseV3.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {IProtobufSessionResponseV3} message ProtobufSessionResponseV3 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionResponseV3.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sessionId);
        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.settingServerCrc);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.environment);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSessionResponseV3 message, length delimited. Does not implicitly {@link ProtobufSessionResponseV3.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {IProtobufSessionResponseV3} message ProtobufSessionResponseV3 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionResponseV3.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSessionResponseV3 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSessionResponseV3} ProtobufSessionResponseV3
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionResponseV3.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSessionResponseV3();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sessionId = reader.uint32();
                    break;
                }
            case 2: {
                    message.settingServerCrc = reader.uint32();
                    break;
                }
            case 3: {
                    message.environment = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("sessionId"))
            throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
        if (!message.hasOwnProperty("settingServerCrc"))
            throw $util.ProtocolError("missing required 'settingServerCrc'", { instance: message });
        if (!message.hasOwnProperty("environment"))
            throw $util.ProtocolError("missing required 'environment'", { instance: message });
        return message;
    };

    /**
     * Decodes a ProtobufSessionResponseV3 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSessionResponseV3} ProtobufSessionResponseV3
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionResponseV3.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSessionResponseV3 message.
     * @function verify
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSessionResponseV3.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isInteger(message.sessionId))
            return "sessionId: integer expected";
        if (!$util.isInteger(message.settingServerCrc))
            return "settingServerCrc: integer expected";
        switch (message.environment) {
        default:
            return "environment: enum value expected";
        case 0:
        case 1:
        case 2:
            break;
        }
        return null;
    };

    /**
     * Creates a ProtobufSessionResponseV3 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSessionResponseV3} ProtobufSessionResponseV3
     */
    ProtobufSessionResponseV3.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSessionResponseV3)
            return object;
        var message = new $root.ProtobufSessionResponseV3();
        if (object.sessionId != null)
            message.sessionId = object.sessionId >>> 0;
        if (object.settingServerCrc != null)
            message.settingServerCrc = object.settingServerCrc >>> 0;
        switch (object.environment) {
        default:
            if (typeof object.environment === "number") {
                message.environment = object.environment;
                break;
            }
            break;
        case "kDev":
        case 0:
            message.environment = 0;
            break;
        case "kTest":
        case 1:
            message.environment = 1;
            break;
        case "kProd":
        case 2:
            message.environment = 2;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSessionResponseV3 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {ProtobufSessionResponseV3} message ProtobufSessionResponseV3
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSessionResponseV3.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sessionId = 0;
            object.settingServerCrc = 0;
            object.environment = options.enums === String ? "kDev" : 0;
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.settingServerCrc != null && message.hasOwnProperty("settingServerCrc"))
            object.settingServerCrc = message.settingServerCrc;
        if (message.environment != null && message.hasOwnProperty("environment"))
            object.environment = options.enums === String ? $root.ProtobufSession.Environment[message.environment] === undefined ? message.environment : $root.ProtobufSession.Environment[message.environment] : message.environment;
        return object;
    };

    /**
     * Converts this ProtobufSessionResponseV3 to JSON.
     * @function toJSON
     * @memberof ProtobufSessionResponseV3
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSessionResponseV3.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSessionResponseV3
     * @function getTypeUrl
     * @memberof ProtobufSessionResponseV3
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSessionResponseV3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSessionResponseV3";
    };

    return ProtobufSessionResponseV3;
})();

$root.ProtobufSessionRequestV4 = (function() {

    /**
     * Properties of a ProtobufSessionRequestV4.
     * @exports IProtobufSessionRequestV4
     * @interface IProtobufSessionRequestV4
     * @property {number} sessionId ProtobufSessionRequestV4 sessionId
     * @property {string} serialNumber ProtobufSessionRequestV4 serialNumber
     * @property {number} firmwareVersion ProtobufSessionRequestV4 firmwareVersion
     * @property {number} settingsServerVersion ProtobufSessionRequestV4 settingsServerVersion
     * @property {ProtobufSession.SettingsState} settingsServerReadState ProtobufSessionRequestV4 settingsServerReadState
     * @property {ProtobufSession.SettingsState} settingsServerUpdateState ProtobufSessionRequestV4 settingsServerUpdateState
     * @property {number} settingServerCrc ProtobufSessionRequestV4 settingServerCrc
     * @property {number} flags ProtobufSessionRequestV4 flags
     */

    /**
     * Constructs a new ProtobufSessionRequestV4.
     * @exports ProtobufSessionRequestV4
     * @classdesc Represents a ProtobufSessionRequestV4.
     * @implements IProtobufSessionRequestV4
     * @constructor
     * @param {IProtobufSessionRequestV4=} [properties] Properties to set
     */
    function ProtobufSessionRequestV4(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSessionRequestV4 sessionId.
     * @member {number} sessionId
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.sessionId = 0;

    /**
     * ProtobufSessionRequestV4 serialNumber.
     * @member {string} serialNumber
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.serialNumber = "";

    /**
     * ProtobufSessionRequestV4 firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.firmwareVersion = 0;

    /**
     * ProtobufSessionRequestV4 settingsServerVersion.
     * @member {number} settingsServerVersion
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.settingsServerVersion = 0;

    /**
     * ProtobufSessionRequestV4 settingsServerReadState.
     * @member {ProtobufSession.SettingsState} settingsServerReadState
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.settingsServerReadState = 0;

    /**
     * ProtobufSessionRequestV4 settingsServerUpdateState.
     * @member {ProtobufSession.SettingsState} settingsServerUpdateState
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.settingsServerUpdateState = 0;

    /**
     * ProtobufSessionRequestV4 settingServerCrc.
     * @member {number} settingServerCrc
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.settingServerCrc = 0;

    /**
     * ProtobufSessionRequestV4 flags.
     * @member {number} flags
     * @memberof ProtobufSessionRequestV4
     * @instance
     */
    ProtobufSessionRequestV4.prototype.flags = 0;

    /**
     * Creates a new ProtobufSessionRequestV4 instance using the specified properties.
     * @function create
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {IProtobufSessionRequestV4=} [properties] Properties to set
     * @returns {ProtobufSessionRequestV4} ProtobufSessionRequestV4 instance
     */
    ProtobufSessionRequestV4.create = function create(properties) {
        return new ProtobufSessionRequestV4(properties);
    };

    /**
     * Encodes the specified ProtobufSessionRequestV4 message. Does not implicitly {@link ProtobufSessionRequestV4.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {IProtobufSessionRequestV4} message ProtobufSessionRequestV4 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionRequestV4.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sessionId);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.serialNumber);
        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.firmwareVersion);
        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.settingsServerVersion);
        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.settingsServerReadState);
        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.settingsServerUpdateState);
        writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.settingServerCrc);
        writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.flags);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSessionRequestV4 message, length delimited. Does not implicitly {@link ProtobufSessionRequestV4.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {IProtobufSessionRequestV4} message ProtobufSessionRequestV4 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionRequestV4.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSessionRequestV4 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSessionRequestV4} ProtobufSessionRequestV4
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionRequestV4.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSessionRequestV4();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sessionId = reader.uint32();
                    break;
                }
            case 2: {
                    message.serialNumber = reader.string();
                    break;
                }
            case 3: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 4: {
                    message.settingsServerVersion = reader.uint32();
                    break;
                }
            case 5: {
                    message.settingsServerReadState = reader.int32();
                    break;
                }
            case 6: {
                    message.settingsServerUpdateState = reader.int32();
                    break;
                }
            case 7: {
                    message.settingServerCrc = reader.uint32();
                    break;
                }
            case 8: {
                    message.flags = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("sessionId"))
            throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
        if (!message.hasOwnProperty("serialNumber"))
            throw $util.ProtocolError("missing required 'serialNumber'", { instance: message });
        if (!message.hasOwnProperty("firmwareVersion"))
            throw $util.ProtocolError("missing required 'firmwareVersion'", { instance: message });
        if (!message.hasOwnProperty("settingsServerVersion"))
            throw $util.ProtocolError("missing required 'settingsServerVersion'", { instance: message });
        if (!message.hasOwnProperty("settingsServerReadState"))
            throw $util.ProtocolError("missing required 'settingsServerReadState'", { instance: message });
        if (!message.hasOwnProperty("settingsServerUpdateState"))
            throw $util.ProtocolError("missing required 'settingsServerUpdateState'", { instance: message });
        if (!message.hasOwnProperty("settingServerCrc"))
            throw $util.ProtocolError("missing required 'settingServerCrc'", { instance: message });
        if (!message.hasOwnProperty("flags"))
            throw $util.ProtocolError("missing required 'flags'", { instance: message });
        return message;
    };

    /**
     * Decodes a ProtobufSessionRequestV4 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSessionRequestV4} ProtobufSessionRequestV4
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionRequestV4.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSessionRequestV4 message.
     * @function verify
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSessionRequestV4.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isInteger(message.sessionId))
            return "sessionId: integer expected";
        if (!$util.isString(message.serialNumber))
            return "serialNumber: string expected";
        if (!$util.isInteger(message.firmwareVersion))
            return "firmwareVersion: integer expected";
        if (!$util.isInteger(message.settingsServerVersion))
            return "settingsServerVersion: integer expected";
        switch (message.settingsServerReadState) {
        default:
            return "settingsServerReadState: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            break;
        }
        switch (message.settingsServerUpdateState) {
        default:
            return "settingsServerUpdateState: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            break;
        }
        if (!$util.isInteger(message.settingServerCrc))
            return "settingServerCrc: integer expected";
        if (!$util.isInteger(message.flags))
            return "flags: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufSessionRequestV4 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSessionRequestV4} ProtobufSessionRequestV4
     */
    ProtobufSessionRequestV4.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSessionRequestV4)
            return object;
        var message = new $root.ProtobufSessionRequestV4();
        if (object.sessionId != null)
            message.sessionId = object.sessionId >>> 0;
        if (object.serialNumber != null)
            message.serialNumber = String(object.serialNumber);
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        if (object.settingsServerVersion != null)
            message.settingsServerVersion = object.settingsServerVersion >>> 0;
        switch (object.settingsServerReadState) {
        default:
            if (typeof object.settingsServerReadState === "number") {
                message.settingsServerReadState = object.settingsServerReadState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.settingsServerReadState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.settingsServerReadState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.settingsServerReadState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.settingsServerReadState = 3;
            break;
        case "kDecoded":
        case 4:
            message.settingsServerReadState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.settingsServerReadState = 5;
            break;
        case "kEncoded":
        case 6:
            message.settingsServerReadState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.settingsServerReadState = 7;
            break;
        case "kWritten":
        case 8:
            message.settingsServerReadState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.settingsServerReadState = 9;
            break;
        }
        switch (object.settingsServerUpdateState) {
        default:
            if (typeof object.settingsServerUpdateState === "number") {
                message.settingsServerUpdateState = object.settingsServerUpdateState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.settingsServerUpdateState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.settingsServerUpdateState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.settingsServerUpdateState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.settingsServerUpdateState = 3;
            break;
        case "kDecoded":
        case 4:
            message.settingsServerUpdateState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.settingsServerUpdateState = 5;
            break;
        case "kEncoded":
        case 6:
            message.settingsServerUpdateState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.settingsServerUpdateState = 7;
            break;
        case "kWritten":
        case 8:
            message.settingsServerUpdateState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.settingsServerUpdateState = 9;
            break;
        }
        if (object.settingServerCrc != null)
            message.settingServerCrc = object.settingServerCrc >>> 0;
        if (object.flags != null)
            message.flags = object.flags >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSessionRequestV4 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {ProtobufSessionRequestV4} message ProtobufSessionRequestV4
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSessionRequestV4.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sessionId = 0;
            object.serialNumber = "";
            object.firmwareVersion = 0;
            object.settingsServerVersion = 0;
            object.settingsServerReadState = options.enums === String ? "kNone" : 0;
            object.settingsServerUpdateState = options.enums === String ? "kNone" : 0;
            object.settingServerCrc = 0;
            object.flags = 0;
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
            object.serialNumber = message.serialNumber;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.settingsServerVersion != null && message.hasOwnProperty("settingsServerVersion"))
            object.settingsServerVersion = message.settingsServerVersion;
        if (message.settingsServerReadState != null && message.hasOwnProperty("settingsServerReadState"))
            object.settingsServerReadState = options.enums === String ? $root.ProtobufSession.SettingsState[message.settingsServerReadState] === undefined ? message.settingsServerReadState : $root.ProtobufSession.SettingsState[message.settingsServerReadState] : message.settingsServerReadState;
        if (message.settingsServerUpdateState != null && message.hasOwnProperty("settingsServerUpdateState"))
            object.settingsServerUpdateState = options.enums === String ? $root.ProtobufSession.SettingsState[message.settingsServerUpdateState] === undefined ? message.settingsServerUpdateState : $root.ProtobufSession.SettingsState[message.settingsServerUpdateState] : message.settingsServerUpdateState;
        if (message.settingServerCrc != null && message.hasOwnProperty("settingServerCrc"))
            object.settingServerCrc = message.settingServerCrc;
        if (message.flags != null && message.hasOwnProperty("flags"))
            object.flags = message.flags;
        return object;
    };

    /**
     * Converts this ProtobufSessionRequestV4 to JSON.
     * @function toJSON
     * @memberof ProtobufSessionRequestV4
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSessionRequestV4.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSessionRequestV4
     * @function getTypeUrl
     * @memberof ProtobufSessionRequestV4
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSessionRequestV4.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSessionRequestV4";
    };

    return ProtobufSessionRequestV4;
})();

$root.ProtobufSessionResponseV4 = (function() {

    /**
     * Properties of a ProtobufSessionResponseV4.
     * @exports IProtobufSessionResponseV4
     * @interface IProtobufSessionResponseV4
     * @property {number} sessionId ProtobufSessionResponseV4 sessionId
     * @property {number} settingServerCrc ProtobufSessionResponseV4 settingServerCrc
     * @property {ProtobufSession.Environment} environment ProtobufSessionResponseV4 environment
     */

    /**
     * Constructs a new ProtobufSessionResponseV4.
     * @exports ProtobufSessionResponseV4
     * @classdesc Represents a ProtobufSessionResponseV4.
     * @implements IProtobufSessionResponseV4
     * @constructor
     * @param {IProtobufSessionResponseV4=} [properties] Properties to set
     */
    function ProtobufSessionResponseV4(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSessionResponseV4 sessionId.
     * @member {number} sessionId
     * @memberof ProtobufSessionResponseV4
     * @instance
     */
    ProtobufSessionResponseV4.prototype.sessionId = 0;

    /**
     * ProtobufSessionResponseV4 settingServerCrc.
     * @member {number} settingServerCrc
     * @memberof ProtobufSessionResponseV4
     * @instance
     */
    ProtobufSessionResponseV4.prototype.settingServerCrc = 0;

    /**
     * ProtobufSessionResponseV4 environment.
     * @member {ProtobufSession.Environment} environment
     * @memberof ProtobufSessionResponseV4
     * @instance
     */
    ProtobufSessionResponseV4.prototype.environment = 0;

    /**
     * Creates a new ProtobufSessionResponseV4 instance using the specified properties.
     * @function create
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {IProtobufSessionResponseV4=} [properties] Properties to set
     * @returns {ProtobufSessionResponseV4} ProtobufSessionResponseV4 instance
     */
    ProtobufSessionResponseV4.create = function create(properties) {
        return new ProtobufSessionResponseV4(properties);
    };

    /**
     * Encodes the specified ProtobufSessionResponseV4 message. Does not implicitly {@link ProtobufSessionResponseV4.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {IProtobufSessionResponseV4} message ProtobufSessionResponseV4 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionResponseV4.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sessionId);
        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.settingServerCrc);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.environment);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSessionResponseV4 message, length delimited. Does not implicitly {@link ProtobufSessionResponseV4.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {IProtobufSessionResponseV4} message ProtobufSessionResponseV4 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionResponseV4.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSessionResponseV4 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSessionResponseV4} ProtobufSessionResponseV4
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionResponseV4.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSessionResponseV4();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sessionId = reader.uint32();
                    break;
                }
            case 2: {
                    message.settingServerCrc = reader.uint32();
                    break;
                }
            case 3: {
                    message.environment = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("sessionId"))
            throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
        if (!message.hasOwnProperty("settingServerCrc"))
            throw $util.ProtocolError("missing required 'settingServerCrc'", { instance: message });
        if (!message.hasOwnProperty("environment"))
            throw $util.ProtocolError("missing required 'environment'", { instance: message });
        return message;
    };

    /**
     * Decodes a ProtobufSessionResponseV4 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSessionResponseV4} ProtobufSessionResponseV4
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionResponseV4.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSessionResponseV4 message.
     * @function verify
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSessionResponseV4.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isInteger(message.sessionId))
            return "sessionId: integer expected";
        if (!$util.isInteger(message.settingServerCrc))
            return "settingServerCrc: integer expected";
        switch (message.environment) {
        default:
            return "environment: enum value expected";
        case 0:
        case 1:
        case 2:
            break;
        }
        return null;
    };

    /**
     * Creates a ProtobufSessionResponseV4 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSessionResponseV4} ProtobufSessionResponseV4
     */
    ProtobufSessionResponseV4.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSessionResponseV4)
            return object;
        var message = new $root.ProtobufSessionResponseV4();
        if (object.sessionId != null)
            message.sessionId = object.sessionId >>> 0;
        if (object.settingServerCrc != null)
            message.settingServerCrc = object.settingServerCrc >>> 0;
        switch (object.environment) {
        default:
            if (typeof object.environment === "number") {
                message.environment = object.environment;
                break;
            }
            break;
        case "kDev":
        case 0:
            message.environment = 0;
            break;
        case "kTest":
        case 1:
            message.environment = 1;
            break;
        case "kProd":
        case 2:
            message.environment = 2;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSessionResponseV4 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {ProtobufSessionResponseV4} message ProtobufSessionResponseV4
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSessionResponseV4.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sessionId = 0;
            object.settingServerCrc = 0;
            object.environment = options.enums === String ? "kDev" : 0;
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.settingServerCrc != null && message.hasOwnProperty("settingServerCrc"))
            object.settingServerCrc = message.settingServerCrc;
        if (message.environment != null && message.hasOwnProperty("environment"))
            object.environment = options.enums === String ? $root.ProtobufSession.Environment[message.environment] === undefined ? message.environment : $root.ProtobufSession.Environment[message.environment] : message.environment;
        return object;
    };

    /**
     * Converts this ProtobufSessionResponseV4 to JSON.
     * @function toJSON
     * @memberof ProtobufSessionResponseV4
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSessionResponseV4.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSessionResponseV4
     * @function getTypeUrl
     * @memberof ProtobufSessionResponseV4
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSessionResponseV4.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSessionResponseV4";
    };

    return ProtobufSessionResponseV4;
})();

$root.ProtobufSessionRequestV5 = (function() {

    /**
     * Properties of a ProtobufSessionRequestV5.
     * @exports IProtobufSessionRequestV5
     * @interface IProtobufSessionRequestV5
     * @property {number} sessionId ProtobufSessionRequestV5 sessionId
     * @property {string} serialNumber ProtobufSessionRequestV5 serialNumber
     * @property {number} firmwareVersion ProtobufSessionRequestV5 firmwareVersion
     * @property {number} settingsServerVersion ProtobufSessionRequestV5 settingsServerVersion
     * @property {ProtobufSession.SettingsState} settingsServerReadState ProtobufSessionRequestV5 settingsServerReadState
     * @property {ProtobufSession.SettingsState} settingsServerUpdateState ProtobufSessionRequestV5 settingsServerUpdateState
     * @property {number} settingServerCrc ProtobufSessionRequestV5 settingServerCrc
     * @property {number} flags ProtobufSessionRequestV5 flags
     * @property {number|null} [commandExecutedCrc] ProtobufSessionRequestV5 commandExecutedCrc
     */

    /**
     * Constructs a new ProtobufSessionRequestV5.
     * @exports ProtobufSessionRequestV5
     * @classdesc Represents a ProtobufSessionRequestV5.
     * @implements IProtobufSessionRequestV5
     * @constructor
     * @param {IProtobufSessionRequestV5=} [properties] Properties to set
     */
    function ProtobufSessionRequestV5(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSessionRequestV5 sessionId.
     * @member {number} sessionId
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.sessionId = 0;

    /**
     * ProtobufSessionRequestV5 serialNumber.
     * @member {string} serialNumber
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.serialNumber = "";

    /**
     * ProtobufSessionRequestV5 firmwareVersion.
     * @member {number} firmwareVersion
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.firmwareVersion = 0;

    /**
     * ProtobufSessionRequestV5 settingsServerVersion.
     * @member {number} settingsServerVersion
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.settingsServerVersion = 0;

    /**
     * ProtobufSessionRequestV5 settingsServerReadState.
     * @member {ProtobufSession.SettingsState} settingsServerReadState
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.settingsServerReadState = 0;

    /**
     * ProtobufSessionRequestV5 settingsServerUpdateState.
     * @member {ProtobufSession.SettingsState} settingsServerUpdateState
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.settingsServerUpdateState = 0;

    /**
     * ProtobufSessionRequestV5 settingServerCrc.
     * @member {number} settingServerCrc
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.settingServerCrc = 0;

    /**
     * ProtobufSessionRequestV5 flags.
     * @member {number} flags
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.flags = 0;

    /**
     * ProtobufSessionRequestV5 commandExecutedCrc.
     * @member {number} commandExecutedCrc
     * @memberof ProtobufSessionRequestV5
     * @instance
     */
    ProtobufSessionRequestV5.prototype.commandExecutedCrc = 0;

    /**
     * Creates a new ProtobufSessionRequestV5 instance using the specified properties.
     * @function create
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {IProtobufSessionRequestV5=} [properties] Properties to set
     * @returns {ProtobufSessionRequestV5} ProtobufSessionRequestV5 instance
     */
    ProtobufSessionRequestV5.create = function create(properties) {
        return new ProtobufSessionRequestV5(properties);
    };

    /**
     * Encodes the specified ProtobufSessionRequestV5 message. Does not implicitly {@link ProtobufSessionRequestV5.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {IProtobufSessionRequestV5} message ProtobufSessionRequestV5 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionRequestV5.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sessionId);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.serialNumber);
        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.firmwareVersion);
        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.settingsServerVersion);
        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.settingsServerReadState);
        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.settingsServerUpdateState);
        writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.settingServerCrc);
        writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.flags);
        if (message.commandExecutedCrc != null && Object.hasOwnProperty.call(message, "commandExecutedCrc"))
            writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.commandExecutedCrc);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSessionRequestV5 message, length delimited. Does not implicitly {@link ProtobufSessionRequestV5.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {IProtobufSessionRequestV5} message ProtobufSessionRequestV5 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionRequestV5.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSessionRequestV5 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSessionRequestV5} ProtobufSessionRequestV5
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionRequestV5.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSessionRequestV5();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sessionId = reader.uint32();
                    break;
                }
            case 2: {
                    message.serialNumber = reader.string();
                    break;
                }
            case 3: {
                    message.firmwareVersion = reader.uint32();
                    break;
                }
            case 4: {
                    message.settingsServerVersion = reader.uint32();
                    break;
                }
            case 5: {
                    message.settingsServerReadState = reader.int32();
                    break;
                }
            case 6: {
                    message.settingsServerUpdateState = reader.int32();
                    break;
                }
            case 7: {
                    message.settingServerCrc = reader.uint32();
                    break;
                }
            case 8: {
                    message.flags = reader.uint32();
                    break;
                }
            case 9: {
                    message.commandExecutedCrc = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("sessionId"))
            throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
        if (!message.hasOwnProperty("serialNumber"))
            throw $util.ProtocolError("missing required 'serialNumber'", { instance: message });
        if (!message.hasOwnProperty("firmwareVersion"))
            throw $util.ProtocolError("missing required 'firmwareVersion'", { instance: message });
        if (!message.hasOwnProperty("settingsServerVersion"))
            throw $util.ProtocolError("missing required 'settingsServerVersion'", { instance: message });
        if (!message.hasOwnProperty("settingsServerReadState"))
            throw $util.ProtocolError("missing required 'settingsServerReadState'", { instance: message });
        if (!message.hasOwnProperty("settingsServerUpdateState"))
            throw $util.ProtocolError("missing required 'settingsServerUpdateState'", { instance: message });
        if (!message.hasOwnProperty("settingServerCrc"))
            throw $util.ProtocolError("missing required 'settingServerCrc'", { instance: message });
        if (!message.hasOwnProperty("flags"))
            throw $util.ProtocolError("missing required 'flags'", { instance: message });
        return message;
    };

    /**
     * Decodes a ProtobufSessionRequestV5 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSessionRequestV5} ProtobufSessionRequestV5
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionRequestV5.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSessionRequestV5 message.
     * @function verify
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSessionRequestV5.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isInteger(message.sessionId))
            return "sessionId: integer expected";
        if (!$util.isString(message.serialNumber))
            return "serialNumber: string expected";
        if (!$util.isInteger(message.firmwareVersion))
            return "firmwareVersion: integer expected";
        if (!$util.isInteger(message.settingsServerVersion))
            return "settingsServerVersion: integer expected";
        switch (message.settingsServerReadState) {
        default:
            return "settingsServerReadState: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            break;
        }
        switch (message.settingsServerUpdateState) {
        default:
            return "settingsServerUpdateState: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            break;
        }
        if (!$util.isInteger(message.settingServerCrc))
            return "settingServerCrc: integer expected";
        if (!$util.isInteger(message.flags))
            return "flags: integer expected";
        if (message.commandExecutedCrc != null && message.hasOwnProperty("commandExecutedCrc"))
            if (!$util.isInteger(message.commandExecutedCrc))
                return "commandExecutedCrc: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufSessionRequestV5 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSessionRequestV5} ProtobufSessionRequestV5
     */
    ProtobufSessionRequestV5.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSessionRequestV5)
            return object;
        var message = new $root.ProtobufSessionRequestV5();
        if (object.sessionId != null)
            message.sessionId = object.sessionId >>> 0;
        if (object.serialNumber != null)
            message.serialNumber = String(object.serialNumber);
        if (object.firmwareVersion != null)
            message.firmwareVersion = object.firmwareVersion >>> 0;
        if (object.settingsServerVersion != null)
            message.settingsServerVersion = object.settingsServerVersion >>> 0;
        switch (object.settingsServerReadState) {
        default:
            if (typeof object.settingsServerReadState === "number") {
                message.settingsServerReadState = object.settingsServerReadState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.settingsServerReadState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.settingsServerReadState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.settingsServerReadState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.settingsServerReadState = 3;
            break;
        case "kDecoded":
        case 4:
            message.settingsServerReadState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.settingsServerReadState = 5;
            break;
        case "kEncoded":
        case 6:
            message.settingsServerReadState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.settingsServerReadState = 7;
            break;
        case "kWritten":
        case 8:
            message.settingsServerReadState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.settingsServerReadState = 9;
            break;
        }
        switch (object.settingsServerUpdateState) {
        default:
            if (typeof object.settingsServerUpdateState === "number") {
                message.settingsServerUpdateState = object.settingsServerUpdateState;
                break;
            }
            break;
        case "kNone":
        case 0:
            message.settingsServerUpdateState = 0;
            break;
        case "kReadFailed":
        case 1:
            message.settingsServerUpdateState = 1;
            break;
        case "kLengthInvalid":
        case 2:
            message.settingsServerUpdateState = 2;
            break;
        case "kCrcInvalid":
        case 3:
            message.settingsServerUpdateState = 3;
            break;
        case "kDecoded":
        case 4:
            message.settingsServerUpdateState = 4;
            break;
        case "kDecodeFailed":
        case 5:
            message.settingsServerUpdateState = 5;
            break;
        case "kEncoded":
        case 6:
            message.settingsServerUpdateState = 6;
            break;
        case "kEncodeFailed":
        case 7:
            message.settingsServerUpdateState = 7;
            break;
        case "kWritten":
        case 8:
            message.settingsServerUpdateState = 8;
            break;
        case "kWriteFailed":
        case 9:
            message.settingsServerUpdateState = 9;
            break;
        }
        if (object.settingServerCrc != null)
            message.settingServerCrc = object.settingServerCrc >>> 0;
        if (object.flags != null)
            message.flags = object.flags >>> 0;
        if (object.commandExecutedCrc != null)
            message.commandExecutedCrc = object.commandExecutedCrc >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSessionRequestV5 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {ProtobufSessionRequestV5} message ProtobufSessionRequestV5
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSessionRequestV5.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sessionId = 0;
            object.serialNumber = "";
            object.firmwareVersion = 0;
            object.settingsServerVersion = 0;
            object.settingsServerReadState = options.enums === String ? "kNone" : 0;
            object.settingsServerUpdateState = options.enums === String ? "kNone" : 0;
            object.settingServerCrc = 0;
            object.flags = 0;
            object.commandExecutedCrc = 0;
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
            object.serialNumber = message.serialNumber;
        if (message.firmwareVersion != null && message.hasOwnProperty("firmwareVersion"))
            object.firmwareVersion = message.firmwareVersion;
        if (message.settingsServerVersion != null && message.hasOwnProperty("settingsServerVersion"))
            object.settingsServerVersion = message.settingsServerVersion;
        if (message.settingsServerReadState != null && message.hasOwnProperty("settingsServerReadState"))
            object.settingsServerReadState = options.enums === String ? $root.ProtobufSession.SettingsState[message.settingsServerReadState] === undefined ? message.settingsServerReadState : $root.ProtobufSession.SettingsState[message.settingsServerReadState] : message.settingsServerReadState;
        if (message.settingsServerUpdateState != null && message.hasOwnProperty("settingsServerUpdateState"))
            object.settingsServerUpdateState = options.enums === String ? $root.ProtobufSession.SettingsState[message.settingsServerUpdateState] === undefined ? message.settingsServerUpdateState : $root.ProtobufSession.SettingsState[message.settingsServerUpdateState] : message.settingsServerUpdateState;
        if (message.settingServerCrc != null && message.hasOwnProperty("settingServerCrc"))
            object.settingServerCrc = message.settingServerCrc;
        if (message.flags != null && message.hasOwnProperty("flags"))
            object.flags = message.flags;
        if (message.commandExecutedCrc != null && message.hasOwnProperty("commandExecutedCrc"))
            object.commandExecutedCrc = message.commandExecutedCrc;
        return object;
    };

    /**
     * Converts this ProtobufSessionRequestV5 to JSON.
     * @function toJSON
     * @memberof ProtobufSessionRequestV5
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSessionRequestV5.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSessionRequestV5
     * @function getTypeUrl
     * @memberof ProtobufSessionRequestV5
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSessionRequestV5.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSessionRequestV5";
    };

    return ProtobufSessionRequestV5;
})();

$root.ProtobufSessionResponseV5 = (function() {

    /**
     * Properties of a ProtobufSessionResponseV5.
     * @exports IProtobufSessionResponseV5
     * @interface IProtobufSessionResponseV5
     * @property {number} sessionId ProtobufSessionResponseV5 sessionId
     * @property {number} settingServerCrc ProtobufSessionResponseV5 settingServerCrc
     * @property {ProtobufSession.Environment} environment ProtobufSessionResponseV5 environment
     * @property {number|null} [maxCellularProprietaryProtocolVersion] ProtobufSessionResponseV5 maxCellularProprietaryProtocolVersion
     * @property {number|null} [maxSatelliteProprietaryProtocolVersion] ProtobufSessionResponseV5 maxSatelliteProprietaryProtocolVersion
     * @property {number|null} [settingUserCrc] ProtobufSessionResponseV5 settingUserCrc
     */

    /**
     * Constructs a new ProtobufSessionResponseV5.
     * @exports ProtobufSessionResponseV5
     * @classdesc Represents a ProtobufSessionResponseV5.
     * @implements IProtobufSessionResponseV5
     * @constructor
     * @param {IProtobufSessionResponseV5=} [properties] Properties to set
     */
    function ProtobufSessionResponseV5(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProtobufSessionResponseV5 sessionId.
     * @member {number} sessionId
     * @memberof ProtobufSessionResponseV5
     * @instance
     */
    ProtobufSessionResponseV5.prototype.sessionId = 0;

    /**
     * ProtobufSessionResponseV5 settingServerCrc.
     * @member {number} settingServerCrc
     * @memberof ProtobufSessionResponseV5
     * @instance
     */
    ProtobufSessionResponseV5.prototype.settingServerCrc = 0;

    /**
     * ProtobufSessionResponseV5 environment.
     * @member {ProtobufSession.Environment} environment
     * @memberof ProtobufSessionResponseV5
     * @instance
     */
    ProtobufSessionResponseV5.prototype.environment = 0;

    /**
     * ProtobufSessionResponseV5 maxCellularProprietaryProtocolVersion.
     * @member {number} maxCellularProprietaryProtocolVersion
     * @memberof ProtobufSessionResponseV5
     * @instance
     */
    ProtobufSessionResponseV5.prototype.maxCellularProprietaryProtocolVersion = 3;

    /**
     * ProtobufSessionResponseV5 maxSatelliteProprietaryProtocolVersion.
     * @member {number} maxSatelliteProprietaryProtocolVersion
     * @memberof ProtobufSessionResponseV5
     * @instance
     */
    ProtobufSessionResponseV5.prototype.maxSatelliteProprietaryProtocolVersion = 3;

    /**
     * ProtobufSessionResponseV5 settingUserCrc.
     * @member {number} settingUserCrc
     * @memberof ProtobufSessionResponseV5
     * @instance
     */
    ProtobufSessionResponseV5.prototype.settingUserCrc = 0;

    /**
     * Creates a new ProtobufSessionResponseV5 instance using the specified properties.
     * @function create
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {IProtobufSessionResponseV5=} [properties] Properties to set
     * @returns {ProtobufSessionResponseV5} ProtobufSessionResponseV5 instance
     */
    ProtobufSessionResponseV5.create = function create(properties) {
        return new ProtobufSessionResponseV5(properties);
    };

    /**
     * Encodes the specified ProtobufSessionResponseV5 message. Does not implicitly {@link ProtobufSessionResponseV5.verify|verify} messages.
     * @function encode
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {IProtobufSessionResponseV5} message ProtobufSessionResponseV5 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionResponseV5.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.sessionId);
        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.settingServerCrc);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.environment);
        if (message.maxCellularProprietaryProtocolVersion != null && Object.hasOwnProperty.call(message, "maxCellularProprietaryProtocolVersion"))
            writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maxCellularProprietaryProtocolVersion);
        if (message.maxSatelliteProprietaryProtocolVersion != null && Object.hasOwnProperty.call(message, "maxSatelliteProprietaryProtocolVersion"))
            writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.maxSatelliteProprietaryProtocolVersion);
        if (message.settingUserCrc != null && Object.hasOwnProperty.call(message, "settingUserCrc"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.settingUserCrc);
        return writer;
    };

    /**
     * Encodes the specified ProtobufSessionResponseV5 message, length delimited. Does not implicitly {@link ProtobufSessionResponseV5.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {IProtobufSessionResponseV5} message ProtobufSessionResponseV5 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProtobufSessionResponseV5.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProtobufSessionResponseV5 message from the specified reader or buffer.
     * @function decode
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProtobufSessionResponseV5} ProtobufSessionResponseV5
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionResponseV5.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProtobufSessionResponseV5();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sessionId = reader.uint32();
                    break;
                }
            case 2: {
                    message.settingServerCrc = reader.uint32();
                    break;
                }
            case 3: {
                    message.environment = reader.int32();
                    break;
                }
            case 4: {
                    message.maxCellularProprietaryProtocolVersion = reader.uint32();
                    break;
                }
            case 5: {
                    message.maxSatelliteProprietaryProtocolVersion = reader.uint32();
                    break;
                }
            case 6: {
                    message.settingUserCrc = reader.uint32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("sessionId"))
            throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
        if (!message.hasOwnProperty("settingServerCrc"))
            throw $util.ProtocolError("missing required 'settingServerCrc'", { instance: message });
        if (!message.hasOwnProperty("environment"))
            throw $util.ProtocolError("missing required 'environment'", { instance: message });
        return message;
    };

    /**
     * Decodes a ProtobufSessionResponseV5 message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProtobufSessionResponseV5} ProtobufSessionResponseV5
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProtobufSessionResponseV5.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProtobufSessionResponseV5 message.
     * @function verify
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProtobufSessionResponseV5.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isInteger(message.sessionId))
            return "sessionId: integer expected";
        if (!$util.isInteger(message.settingServerCrc))
            return "settingServerCrc: integer expected";
        switch (message.environment) {
        default:
            return "environment: enum value expected";
        case 0:
        case 1:
        case 2:
            break;
        }
        if (message.maxCellularProprietaryProtocolVersion != null && message.hasOwnProperty("maxCellularProprietaryProtocolVersion"))
            if (!$util.isInteger(message.maxCellularProprietaryProtocolVersion))
                return "maxCellularProprietaryProtocolVersion: integer expected";
        if (message.maxSatelliteProprietaryProtocolVersion != null && message.hasOwnProperty("maxSatelliteProprietaryProtocolVersion"))
            if (!$util.isInteger(message.maxSatelliteProprietaryProtocolVersion))
                return "maxSatelliteProprietaryProtocolVersion: integer expected";
        if (message.settingUserCrc != null && message.hasOwnProperty("settingUserCrc"))
            if (!$util.isInteger(message.settingUserCrc))
                return "settingUserCrc: integer expected";
        return null;
    };

    /**
     * Creates a ProtobufSessionResponseV5 message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProtobufSessionResponseV5} ProtobufSessionResponseV5
     */
    ProtobufSessionResponseV5.fromObject = function fromObject(object) {
        if (object instanceof $root.ProtobufSessionResponseV5)
            return object;
        var message = new $root.ProtobufSessionResponseV5();
        if (object.sessionId != null)
            message.sessionId = object.sessionId >>> 0;
        if (object.settingServerCrc != null)
            message.settingServerCrc = object.settingServerCrc >>> 0;
        switch (object.environment) {
        default:
            if (typeof object.environment === "number") {
                message.environment = object.environment;
                break;
            }
            break;
        case "kDev":
        case 0:
            message.environment = 0;
            break;
        case "kTest":
        case 1:
            message.environment = 1;
            break;
        case "kProd":
        case 2:
            message.environment = 2;
            break;
        }
        if (object.maxCellularProprietaryProtocolVersion != null)
            message.maxCellularProprietaryProtocolVersion = object.maxCellularProprietaryProtocolVersion >>> 0;
        if (object.maxSatelliteProprietaryProtocolVersion != null)
            message.maxSatelliteProprietaryProtocolVersion = object.maxSatelliteProprietaryProtocolVersion >>> 0;
        if (object.settingUserCrc != null)
            message.settingUserCrc = object.settingUserCrc >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a ProtobufSessionResponseV5 message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {ProtobufSessionResponseV5} message ProtobufSessionResponseV5
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProtobufSessionResponseV5.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sessionId = 0;
            object.settingServerCrc = 0;
            object.environment = options.enums === String ? "kDev" : 0;
            object.maxCellularProprietaryProtocolVersion = 3;
            object.maxSatelliteProprietaryProtocolVersion = 3;
            object.settingUserCrc = 0;
        }
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.settingServerCrc != null && message.hasOwnProperty("settingServerCrc"))
            object.settingServerCrc = message.settingServerCrc;
        if (message.environment != null && message.hasOwnProperty("environment"))
            object.environment = options.enums === String ? $root.ProtobufSession.Environment[message.environment] === undefined ? message.environment : $root.ProtobufSession.Environment[message.environment] : message.environment;
        if (message.maxCellularProprietaryProtocolVersion != null && message.hasOwnProperty("maxCellularProprietaryProtocolVersion"))
            object.maxCellularProprietaryProtocolVersion = message.maxCellularProprietaryProtocolVersion;
        if (message.maxSatelliteProprietaryProtocolVersion != null && message.hasOwnProperty("maxSatelliteProprietaryProtocolVersion"))
            object.maxSatelliteProprietaryProtocolVersion = message.maxSatelliteProprietaryProtocolVersion;
        if (message.settingUserCrc != null && message.hasOwnProperty("settingUserCrc"))
            object.settingUserCrc = message.settingUserCrc;
        return object;
    };

    /**
     * Converts this ProtobufSessionResponseV5 to JSON.
     * @function toJSON
     * @memberof ProtobufSessionResponseV5
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProtobufSessionResponseV5.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProtobufSessionResponseV5
     * @function getTypeUrl
     * @memberof ProtobufSessionResponseV5
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProtobufSessionResponseV5.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProtobufSessionResponseV5";
    };

    return ProtobufSessionResponseV5;
})();

module.exports = $root;
